import React, { FC, useState } from 'react'

import {
  SectionTitle,
  SectionTitleWrapper,
  Text,
  SectionListItem,
  DropdownPlus
} from './styles'

interface Props {
  item: { question: string; answer: any }
}

const FAQSpoiler: FC<Props> = ({ item: { question, answer } }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <SectionListItem>
      <SectionTitleWrapper onClick={toggle} isOpen={isOpen}>
        <SectionTitle>{question}</SectionTitle>
        <div>
          <DropdownPlus isOpen={isOpen} />
        </div>
      </SectionTitleWrapper>
      {isOpen && <Text>{answer}</Text>}
    </SectionListItem>
  )
}

export default FAQSpoiler
