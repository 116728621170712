import React from 'react'

interface PayPalIconProps {
  color?: string
  rest?: React.SVGProps<SVGElement>
}
export const PayPalIcon: React.FC<PayPalIconProps> = ({
  color = '#fff',
  ...rest
}): React.ReactElement => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M21.875 9C24 10.25 25 12.5 25 15C25 18.125 21.875 20.625 18.75 20.625H15.5L14.75 25.125C14.6916 25.4116 14.5345 25.6686 14.3061 25.8513C14.0777 26.0341 13.7924 26.1309 13.5 26.125H10.125C10.0313 26.1269 9.9383 26.1077 9.85299 26.0688C9.76769 26.0299 9.69223 25.9723 9.63221 25.9002C9.57219 25.8282 9.52914 25.7436 9.50625 25.6527C9.48337 25.5618 9.48123 25.4669 9.5 25.375L9.75 23.625M12.5 16.25H15.625C18.75 16.25 21.875 13.125 21.875 10C21.875 6.25 19.5 3.75 15.625 3.75H8.75C8.125 3.75 7.5 4.375 7.5 5L5 22.5C5 23.125 5.625 23.75 6.25 23.75H9.75L11.25 17.5C11.375 16.75 11.75 16.25 12.5 16.25Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
