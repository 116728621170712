import React from 'react'

const SocialShareIcon = styles => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="19"
    viewBox="0 0 21 19"
    {...styles}
  >
    <path
      fill="#fff"
      d="M20.453 9.808L12.081.628v5.477h-1.779c-5.52 0-9.994 4.474-9.994 9.993V19l.791-.866a14.189 14.189 0 0110.48-4.623h.502v5.477z"
    />
  </svg>
)

export default SocialShareIcon
