import React, { FC, useEffect, useState } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { IRegisterPayload } from 'redux/auth/types'
import { IErrors } from 'redux/commonTypes'
import { routePath } from 'routes'
import {
  useDevice,
  hasNumbers,
  isSpecialCharacter,
  isValidEmail,
  MIN_PASSWORD_LENGTH,
  parseBetablockSearchParams
} from 'utils/helpers'
import { consentAgreement, LINKS, WEBSITE } from 'utils/constants'
import { SignUpLink } from 'components/auth/SignInForm/styles'
import TextInput from 'src/components/UI/TextInput'
import Button from 'src/components/UI/Button'
import Link from 'src/components/UI/Link'
import Checkbox, { CheckboxVariant } from 'components/UI/Checkbox'

import { ButtonWrapper, CheckBoxWrapper, FieldsWrapper } from './styles'

interface IFormProps {
  onSubmit: (form: IRegisterPayload, params?: any) => void
  clearErrors: () => void
  serverErrors: any
  isClicked?: boolean
}

export const checkboxLabel = (
  <>
    I confirm that I am over 18 years old and accept the{' '}
    {
      <Link
        target={'_blank'}
        to={WEBSITE + LINKS.TERMS}
        style={{ textDecoration: 'underline' }}
      >
        Terms & Conditions
      </Link>
    }
  </>
)

const SignUpForm: FC<IFormProps> = ({
  onSubmit,
  isClicked,
  serverErrors,
  clearErrors
}) => {
  const userDevice = useDevice()
  const location = useLocation<Location>()
  const isBetaBlock = location.pathname === routePath.SIGN_UP_BETABLOCK
  const { clientParams } = parseBetablockSearchParams(location, isBetaBlock)
  const [form, setForm] = useState<IRegisterPayload>({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    password_confirmation: '',
    terms: 0,
    provider: '',
    provider_id: '',
    user_photo_url: '',
    consent: 0,
    device_id: userDevice.browserId,
    device_model: userDevice.browser,
    device_os: userDevice.os
  })
  const [errors, setErrors] = useState<IErrors<IRegisterPayload>>({} as IErrors<
    IRegisterPayload
  >)
  const {
    first_name,
    last_name,
    email,
    password,
    password_confirmation,
    terms,
    consent
  } = form

  useEffect(() => {
    if (serverErrors) {
      clearErrors()
    }
    // eslint-disable-next-line
  }, [form, errors])

  const onCheckboxUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = event.target

    setForm({
      ...form,
      [name]: checked ? 1 : 0
    })
    setErrors({
      ...errors,
      [name]: ''
    })
  }

  const isValid = (): boolean => {
    const validationErrors = {} as IErrors<IRegisterPayload>

    if (isSpecialCharacter(first_name) || hasNumbers(first_name)) {
      validationErrors.first_name =
        'First name should not contain numbers or special characters'
    }

    if (isSpecialCharacter(last_name) || hasNumbers(last_name)) {
      validationErrors.last_name =
        'Last name should not contain numbers or special characters'
    }

    if (!isValidEmail(email)) {
      validationErrors.email = 'You must enter valid email'
    }

    if (password.length < MIN_PASSWORD_LENGTH) {
      validationErrors.password = `The password must be at least ${MIN_PASSWORD_LENGTH} characters long`
    }

    if (password.includes(' ')) {
      validationErrors.password = 'Spaces are not allowed in the password'
    }

    if (password_confirmation !== password) {
      validationErrors.password_confirmation = "The passwords don't match"
    }

    for (const field of Object.keys(form) as Array<keyof IRegisterPayload>) {
      if (['provider', 'provider_id', 'user_photo_url'].includes(field)) {
        continue
      }

      if (!form[field]) {
        validationErrors[field] = 'This field is required'
      }
    }

    const nError = serverErrors
      ? {
          ...validationErrors,
          email: serverErrors
        }
      : validationErrors

    setErrors(nError)

    return !Object.keys(nError).length
  }

  const onUpdate = (name: string, value: string) => {
    setForm({
      ...form,
      [name]: value
    })
    setErrors({
      ...errors,
      [name]: ''
    })
  }

  const handleSubmit = async () => {
    const { consent, ...rest } = form
    if (isValid()) {
      try {
        if (isBetaBlock) {
          await onSubmit(rest, clientParams)
        } else {
          await onSubmit(rest)
        }
      } catch (e) {
        console.error('Login fail')
      }
    }
  }

  const nError = serverErrors
    ? {
        ...errors,
        email: serverErrors
      }
    : errors

  const emptyFields = Object.values({
    first_name,
    last_name,
    password,
    password_confirmation,
    email,
    consent,
    terms
  }).some(field => !field)

  if (isBetaBlock && !location.search) {
    return <Redirect to={routePath.INVEST_PAGE} />
  }

  return (
    <>
      <FieldsWrapper>
        <TextInput
          name={'first_name'}
          type={'text'}
          value={first_name}
          label={'First Name'}
          placeholder={'First Name'}
          isLabelHidden={!first_name}
          errorLabel={errors.first_name || ''}
          handleChange={onUpdate}
          wrapperClassName={'form__first-field'}
        />
        <TextInput
          name={'last_name'}
          type={'text'}
          value={last_name}
          label={'Last Name'}
          placeholder={'Last Name'}
          isLabelHidden={!last_name}
          errorLabel={errors.last_name || ''}
          handleChange={onUpdate}
          wrapperClassName={'form__last-field'}
        />
        <TextInput
          name={'email'}
          errorLabel={nError.email || ''}
          value={email}
          label={'Email'}
          placeholder={'Email'}
          isLabelHidden={!email}
          handleChange={onUpdate}
          wrapperClassName={'form__first-field'}
        />
        <TextInput
          name={'password'}
          type={'password'}
          value={password}
          label={'Password'}
          placeholder={'Password'}
          isLabelHidden={!password}
          errorLabel={errors.password || ''}
          handleChange={onUpdate}
        />
        <TextInput
          name={'password_confirmation'}
          type={'password'}
          value={password_confirmation}
          label={'Confirm Password'}
          placeholder={'Confirm Password'}
          isLabelHidden={!password_confirmation}
          errorLabel={errors.password_confirmation || ''}
          handleChange={onUpdate}
          wrapperClassName={'form__last-field'}
        />

        <CheckBoxWrapper>
          <Checkbox
            name={'terms'}
            checked={!!terms}
            variant={CheckboxVariant.Blue}
            label={checkboxLabel}
            errorLabel={errors.terms || ''}
            handleChange={onCheckboxUpdate}
          />
        </CheckBoxWrapper>
        <CheckBoxWrapper>
          <Checkbox
            name={'consent'}
            checked={!!consent}
            label={consentAgreement}
            variant={CheckboxVariant.Blue}
            errorLabel={errors.consent || ''}
            handleChange={onCheckboxUpdate}
          />
        </CheckBoxWrapper>
      </FieldsWrapper>
      <ButtonWrapper>
        <Button
          variant={'default'}
          type={'submit'}
          onClick={handleSubmit}
          disabled={emptyFields || isClicked}
          isLoading={isClicked}
        >
          Next
        </Button>
      </ButtonWrapper>
      <SignUpLink>
        Have an account?{' '}
        <Link
          to={
            isBetaBlock
              ? {
                  pathname: routePath.SIGN_IN_BETABLOCK,
                  search: location.search
                }
              : { pathname: routePath.SIGN_IN }
          }
          variant={'clear'}
        >
          Log in
        </Link>
      </SignUpLink>
    </>
  )
}

export default SignUpForm
