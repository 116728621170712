import React, {
  Component,
  MouseEvent,
  ChangeEvent,
  createRef,
  RefObject
} from 'react'
import Button from 'components/UI/Button'

import { Form } from './styles'

interface State {
  text: string
}

interface Props {
  userName: string
  post: (content: string) => void
  isFetching: boolean
}

class ReplyField extends Component<Props, State> {
  state = {
    text: ''
  }

  ref: RefObject<HTMLTextAreaElement> = createRef()

  componentDidMount() {
    const elem = this.ref.current
    const { userName } = this.props

    if (elem) {
      elem.value = `@${userName}`
      elem.focus()
      elem.setSelectionRange(elem.value.length, elem.value.length)
    }
  }

  changeText = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    const { value } = e.currentTarget

    this.setState({ text: value })
  }

  handlePost = (event: MouseEvent) => {
    event.preventDefault()

    const { text } = this.state
    const { post } = this.props

    this.setState({ text: '' }, () => post(text))
  }

  render() {
    const { text } = this.state
    const { isFetching, userName } = this.props
    const taggedUsername = `@${userName}`
    const hasOnlyUsername =
      text.includes(taggedUsername) &&
      text.trim().length === taggedUsername.length

    return (
      <Form>
        <textarea
          placeholder={'Add new reply'}
          value={text}
          onChange={this.changeText}
          ref={this.ref}
        />
        <Button
          type={'submit'}
          onClick={this.handlePost}
          disabled={hasOnlyUsername || !text.trim() || isFetching}
        >
          Post
        </Button>
      </Form>
    )
  }
}

export default ReplyField
