import React from 'react'
import { Container } from 'styled-bootstrap-grid'
import { EventSection } from 'redux/events/types'
import {
  MainContent,
  SectionWrapper
} from 'containers/invest/InvestDetailPage/styles'
import { theme } from 'styled/theme'
import {
  ContentDescription,
  ContentTitle,
  IncomingHTMLLayout,
  SectionTitle
} from './styles'

const Disclosures: React.FC<{
  section: EventSection | undefined
}> = ({ section }) => {
  return (
    <>
      {section && (
        <MainContent sectionBackground={'#f8f8f8'}>
          <Container className={'content-container'}>
            <SectionWrapper id={'disclosures'}>
              <SectionTitle color={theme.colors.black}>
                {section.name}
              </SectionTitle>
              <IncomingHTMLLayout>
                {section.items.map(({ title, description }) => {
                  return (
                    <div key={title}>
                      {title && <ContentTitle>{title}</ContentTitle>}
                      {description && (
                        <ContentDescription
                          dangerouslySetInnerHTML={{ __html: description }}
                        />
                      )}
                    </div>
                  )
                })}
              </IncomingHTMLLayout>
            </SectionWrapper>
          </Container>
        </MainContent>
      )}
    </>
  )
}

export default Disclosures
