import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ICartItem, ICartItemSets } from 'redux/checkout/types'
import {
  decreaseCartItemCount,
  deleteItemFromCart,
  increaseCartItemCount
} from 'redux/checkout/operations'
import {
  decreaseQuantityBuyNowItemAction,
  deleteQuantityBuyNowItemAction,
  increaseQuantityBuyNowItemAction
} from 'redux/checkout/actions'
import { formatPrice } from 'utils/dateFormats'
import Button from 'components/UI/Button'
import { ItemsCounter } from 'components/UI/ItemsCounter'
import { TrashIcon } from './TrashIcon'
import {
  ShoppingCartItemWrapper,
  ShoppingCartItemContent,
  ShoppingCartItemContentHeader,
  ShoppingCartItemContentFooter,
  ShoppingCartItemPrice,
  TrashIconArea,
  ButtonArea,
  PriceText,
  Line,
  IconInCircle,
  ImgEvent,
  EventPhotoWrap,
  ColorCircle,
  Params
} from './styles'

export interface ShoppingCartItemProps {
  item: ICartItem
  count: number
  isShopping?: boolean
  maxItemCount?: number
  set?: ICartItemSets
  withoutCounter?: boolean
}

export const ShoppingCartItem: React.FC<ShoppingCartItemProps> = ({
  item,
  set,
  count,
  withoutCounter,
  maxItemCount,
  isShopping = true
}): React.ReactElement => {
  const {
    tier_image: imageUrl,
    tier_name: productName,
    tier_influencer: sellerName,
    tier_price: price
  } = item
  const {
    color,
    color_name,
    size,
    quantity: setQuantity,
    left: setItemsLeft,
    tier_set_id,
    shopping_cart_id
  } = set || ({} as ICartItemSets)

  const dispatch = useDispatch()
  const history = useHistory()
  const [isCounterChange, setIsCounterChange] = useState(false)

  const maxCount = maxItemCount || setQuantity + (setItemsLeft || 0)

  const handleIncrease = async () => {
    try {
      setIsCounterChange(true)
      if (count >= maxCount) {
        return
      }
      if (isShopping) {
        await dispatch(increaseCartItemCount(tier_set_id, shopping_cart_id))
      } else {
        await dispatch(increaseQuantityBuyNowItemAction())
      }
    } finally {
      setIsCounterChange(false)
    }
  }

  const handleDecrease = async () => {
    try {
      setIsCounterChange(true)

      if (isShopping) {
        dispatch(decreaseCartItemCount(tier_set_id, shopping_cart_id))
      } else {
        dispatch(decreaseQuantityBuyNowItemAction())
      }
    } finally {
      setIsCounterChange(false)
    }
  }

  const handleDelete = () => {
    if (isCounterChange) {
      return
    }
    if (isShopping) {
      dispatch(deleteItemFromCart(shopping_cart_id))
    } else {
      dispatch(deleteQuantityBuyNowItemAction())
      history.goBack()
    }
  }

  return (
    <ShoppingCartItemWrapper>
      <EventPhotoWrap>
        <ImgEvent src={imageUrl} alt="Cart item logo" />
      </EventPhotoWrap>
      <ShoppingCartItemContent>
        <ShoppingCartItemContentHeader>
          <div style={{ flex: 1 }}>
            <h5>{sellerName}</h5>
            <h5>{productName}</h5>
          </div>
          <TrashIconArea>
            <ButtonArea>
              <Button variant="icon" onClick={handleDelete}>
                <IconInCircle width="40px">
                  <TrashIcon />
                </IconInCircle>
              </Button>
            </ButtonArea>
          </TrashIconArea>
        </ShoppingCartItemContentHeader>
        <Params>
          {color_name && <span>{color_name}</span>}
          {!color_name && color && <ColorCircle hex={color} />}
          {color_name && size && ', '}
          {size && <span>{size}</span>}
        </Params>
        <ShoppingCartItemContentFooter isCounter={withoutCounter}>
          {!withoutCounter && (
            <ItemsCounter
              size="small"
              variant="dark"
              value={count}
              onIncrease={handleIncrease}
              onDecrease={handleDecrease}
              disabled={isCounterChange}
            />
          )}
          <ShoppingCartItemPrice>
            <PriceText>${price}</PriceText>
            <Line>/</Line>
            <PriceText bold={true}>{formatPrice(price * count)}</PriceText>
          </ShoppingCartItemPrice>
        </ShoppingCartItemContentFooter>
      </ShoppingCartItemContent>
    </ShoppingCartItemWrapper>
  )
}
