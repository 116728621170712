import styled, { css } from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { TextInputProps } from './index'
import ErrorLabel from '../ErrorLabel'
import { ReactNode } from 'react'

const fontSize = css`
  font-size: 1rem;
  line-height: 1.5rem;

  ${media.lg`
      font-size: 1.25rem;
 `}
`

export const Wrapper = styled.div`
  width: auto;
  position: relative;
  margin-bottom: 2rem;
`
export const Input = styled.input.attrs({ className: 'input' })<TextInputProps>`
  display: block;
  border: none;
  border-bottom: 0.0625rem solid ${p => p.theme.colors.black};
  background-color: transparent;
  width: ${p => (p.rightSideIcon ? '90%' : '100%')};
  padding: 0.5rem 0;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1rem;
  color: #000000;
  background-clip: padding-box;
  transition: border-color 0.15s;
  box-sizing: border-box;

  ::placeholder {
    color: ${p => p.theme.colors.black};
  }

  :focus {
    border-color: ${p => (p.errorLabel ? '#D82C2C' : p.theme.colors.black)};
    outline: 0;
  }

  border-color: ${props => props.errorLabel && '#D82C2C'};

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 2rem ${p => p.theme.colors.darkenGold} inset !important;
  }

  :disabled {
    color: #6f6f6f;
    letter-spacing: 0.0625rem;
    ${() => fontSize}
  }

  ${props =>
    props.as === 'textarea' &&
    css`
      min-height: 9.5rem;

      ${media.md`
        height: 12.5rem;
      `}
    `};
`
export const LabelWrap = styled.div`
  display: flex;
  align-content: space-between;
  width: 100%;
`
export const SErrorLabel = styled(ErrorLabel)`
  position: absolute;
`
export const IconWrapper = styled.div`
  width: 3.625rem;
  position: absolute;
  top: 50%;
  transform: translate(0, calc(-50% + 15px));
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    z-index: 1;
  }
`
export const InfoIconWrapper = styled(IconWrapper)<{
  withoutAlert?: boolean
}>`
  display: ${p => p.withoutAlert && 'none'};
  right: 0;
`
export const LightInput = styled(Input)`
  ~ ${InfoIconWrapper} {
    transform: translate(0, -50%);
  }
`
export const DarkInput = styled(Input)<{ icon?: ReactNode }>`
  border: none;
  border-bottom: 0.0625rem solid ${p => p.theme.colors.lightGrey};
  background-color: transparent;
  color: #ffffff;
  padding: 1rem 0;
  caret-color: ${p => p.theme.colors.white};
  ${() => fontSize}

  ::placeholder {
    color: #808080;
    opacity: 0.4;
    ${() => fontSize}
  }

  ${p =>
    p.as === 'textarea' &&
    css`
      padding: 2.75rem 2.875rem;
      border: 0.125rem solid;
      border-radius: 0.5rem;
      font-size: 1.25rem;
      font-weight: 400;

      ::placeholder {
        max-width: 40.375rem;
        color: ${p => p.theme.colors.white};
      }
    `}

  :focus {
    border-color: ${p => (p.errorLabel ? '#D82C2C' : p.theme.colors.white)};
  }

  :-internal-autofill-selected,
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: ${p => p.theme.colors.white} !important;
    -webkit-box-shadow: 0 0 0 34px ${p => p.theme.colors.darkSmoky} inset !important;
  }

  ${p =>
    p.icon &&
    `padding-left: 3.625rem; 
    `}

  :disabled {
    border-color: #8c8c8c;
    color: #8c8c8c;
    ${() => fontSize}
  }
`

export const DarkInputCheckbox = styled(DarkInput)`
  border-bottom: 0.0625rem solid #666666;
  padding: 9px 0 8px;
  line-height: 1.8125rem;
  font-size: 1rem;

  :disabled {
    letter-spacing: 0;
    font-size: 1rem;

    ${media.md`
    font-size: 1.25rem;
    `}

    ${media.lg`
    font-size: 1.5rem;
    `}
  }

  ${media.md`
    font-size: 1.25rem;
  `}

  ${media.lg`
    font-size: 1.5rem;
  `}
`
