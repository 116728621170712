import React from 'react'

const ReplyIcon = props => (
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.73109 8.5176C5.975 8.76185 5.97473 9.15758 5.73048 9.40149C5.48624 9.6454 5.09051 9.64513 4.8466 9.40088L0.682756 5.23134C0.438981 4.98724 0.439096 4.59178 0.683013 4.34781L4.84686 0.183104C5.09091 -0.0609989 5.48664 -0.0610399 5.73074 0.183013C5.97484 0.427065 5.97488 0.822793 5.73083 1.0669L2.63083 4.16667H9.16564C10.4946 4.16667 11.5082 4.36831 12.4065 4.80372L12.6119 4.90837C13.5356 5.40238 14.2643 6.13107 14.7583 7.0548C15.2661 8.00439 15.5 9.06979 15.5 10.501C15.5 10.8462 15.2202 11.126 14.875 11.126C14.5298 11.126 14.25 10.8462 14.25 10.501C14.25 9.26448 14.0603 8.40019 13.656 7.6443C13.2785 6.93841 12.7283 6.38815 12.0224 6.01063C11.3246 5.63747 10.5345 5.44712 9.44451 5.42005L9.16564 5.41667H2.635L5.73109 8.5176Z"
      fill="white"
      {...props}
    />
  </svg>
)

export default ReplyIcon
