import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Col, Row } from 'styled-bootstrap-grid'
import { EntityType, ICompany } from 'redux/investment/types'
import { selectCurrentStep, selectIdInfo } from 'redux/investment/selectors'
import {
  AddressForm,
  CompanyForm,
  ContactForm,
  EntityAddressForm,
  EntityContactForm,
  IRAForm,
  PaymentSection,
  Review,
  TrustForm,
  UploadDocument,
  VerificationForm
} from 'src/components/investment/steps'
import { useInvestNowContext } from './context'
import AnnualIncomeForm from 'src/components/investment/steps/AnnualIncomeForm'

interface Props {
  matches: { small: boolean }
}

const InvestmentSteps: FC<Props> = props => {
  const { matches } = props
  const currentStep = useSelector(selectCurrentStep)
  const { investor_id } = useSelector(selectIdInfo)

  const {
    selectedEntity: entity,
    verificationInfo,
    setVerificationInfo,
    eventId,
    amount,
    selectedContactForm,
    setSelectedContactForm,
    tierId,
    subscriptionAgreement,
    setSubscriptionAgreement,
    setAmount,
    selectedAnnualIncome,
    setSelectedAnnualIncome,
    selectedEntityInfo,
    selectedEntityDocument,
    setSelectedEntityDocument,
    selectedEntityContactInfo,
    setSelectedEntityContactInfo,
    setSelectedEntityInfo,
    setIsOpenModal,
    setIP,
    isOpenModal
  } = useInvestNowContext()

  const { changed_id } = selectedEntityInfo as ICompany

  const renderSecondStep = () => {
    switch (entity) {
      case EntityType.INDIVIDUAL:
        return (
          <PaymentSection
            entity={entity}
            step={2}
            smallScreen={matches.small}
          />
        )
      case EntityType.ENTITY:
        return (
          <CompanyForm
            selectedEntityContactInfo={selectedEntityContactInfo}
            setSelectedEntityContactInfo={setSelectedEntityContactInfo}
            selectedEntityInfo={selectedEntityInfo}
            setSelectedEntityInfo={setSelectedEntityInfo}
          />
        )
      case EntityType.TRUST:
        return (
          <TrustForm
            selectedEntityInfo={selectedEntityInfo}
            setSelectedEntityInfo={setSelectedEntityInfo}
            selectedEntityContactInfo={selectedEntityContactInfo}
          />
        )
      case EntityType.IRA:
        return (
          <IRAForm
            selectedEntityInfo={selectedEntityInfo}
            setSelectedEntityInfo={setSelectedEntityInfo}
            selectedEntityContactInfo={selectedEntityContactInfo}
          />
        )
      default:
        return <PaymentSection entity={entity} smallScreen={matches.small} />
    }
  }

  if (matches.small) {
    if (entity !== EntityType.INDIVIDUAL) {
      switch (currentStep) {
        case 1:
          return (
            <>
              <AnnualIncomeForm
                setAmount={setAmount}
                selectedAnnualIncome={selectedAnnualIncome}
                setSelectedAnnualIncome={setSelectedAnnualIncome}
              />
              <ContactForm
                setSelectedContactForm={setSelectedContactForm}
                selectedContactForm={selectedContactForm}
                setSubscriptionAgreement={setSubscriptionAgreement}
                subscriptionAgreement={subscriptionAgreement}
              />
              <AddressForm
                selectedContactForm={selectedContactForm}
                selectedAnnualIncome={selectedAnnualIncome}
              />
            </>
          )
        case 2:
          return (
            <>
              {renderSecondStep()}
              <EntityAddressForm selectedEntity={entity} />
              <EntityContactForm
                selectedEntityContactInfo={selectedEntityContactInfo}
                setSelectedEntityContactInfo={setSelectedEntityContactInfo}
                selectedEntity={entity}
                selectedEntityInfo={selectedEntityInfo}
              />
              {entity !== EntityType.IRA && (
                <UploadDocument
                  selectedEntity={entity}
                  selectedEntityInfo={selectedEntityInfo}
                  selectedEntityDocument={selectedEntityDocument}
                  setSelectedEntityDocument={setSelectedEntityDocument}
                  selectedEntityContactInfo={selectedEntityContactInfo}
                  isAlreadyInvested={
                    entity === EntityType.TRUST ? investor_id : changed_id
                  }
                />
              )}
            </>
          )
        case 3:
          return <PaymentSection entity={entity} smallScreen={matches.small} />
        case 4:
          return (
            <VerificationForm
              verificationInfo={verificationInfo}
              setVerificationInfo={setVerificationInfo}
              selectedEntity={entity}
              eventId={eventId}
              amount={amount}
              selectedContactForm={selectedContactForm}
              tierId={tierId}
              subscriptionAgreement={subscriptionAgreement}
              setSubscriptionAgreement={setSubscriptionAgreement}
            />
          )
        case 5:
          return (
            <Review
              setIsOpenModal={setIsOpenModal}
              setIP={setIP}
              isOpenModal={isOpenModal}
              step={currentStep}
            />
          )
        default:
          return (
            <ContactForm
              setSelectedContactForm={setSelectedContactForm}
              selectedContactForm={selectedContactForm}
              setSubscriptionAgreement={setSubscriptionAgreement}
              subscriptionAgreement={subscriptionAgreement}
            />
          )
      }
    }

    switch (currentStep) {
      case 1:
        return (
          <>
            <AnnualIncomeForm
              setAmount={setAmount}
              selectedAnnualIncome={selectedAnnualIncome}
              setSelectedAnnualIncome={setSelectedAnnualIncome}
            />
            <ContactForm
              setSelectedContactForm={setSelectedContactForm}
              selectedContactForm={selectedContactForm}
              setSubscriptionAgreement={setSubscriptionAgreement}
              subscriptionAgreement={subscriptionAgreement}
            />
            <AddressForm
              selectedContactForm={selectedContactForm}
              selectedAnnualIncome={selectedAnnualIncome}
            />
          </>
        )
      case 2:
        return renderSecondStep()
      case 3:
        return (
          <VerificationForm
            verificationInfo={verificationInfo}
            setVerificationInfo={setVerificationInfo}
            selectedEntity={entity}
            eventId={eventId}
            amount={amount}
            selectedContactForm={selectedContactForm}
            tierId={tierId}
            subscriptionAgreement={subscriptionAgreement}
            setSubscriptionAgreement={setSubscriptionAgreement}
          />
        )
      case 4:
        return (
          <Review
            setIsOpenModal={setIsOpenModal}
            setIP={setIP}
            isOpenModal={isOpenModal}
            step={currentStep}
          />
        )
      default:
        return (
          <ContactForm
            setSelectedContactForm={setSelectedContactForm}
            selectedContactForm={selectedContactForm}
            setSubscriptionAgreement={setSubscriptionAgreement}
            subscriptionAgreement={subscriptionAgreement}
          />
        )
    }
  }

  return (
    <Row>
      <Col>
        <AnnualIncomeForm
          setAmount={setAmount}
          selectedAnnualIncome={selectedAnnualIncome}
          setSelectedAnnualIncome={setSelectedAnnualIncome}
        />
      </Col>
      <Col lg={6} style={{ alignSelf: 'flex-end' }}>
        <ContactForm
          setSelectedContactForm={setSelectedContactForm}
          selectedContactForm={selectedContactForm}
          setSubscriptionAgreement={setSubscriptionAgreement}
          subscriptionAgreement={subscriptionAgreement}
        />
      </Col>
      <Col lg={6} style={{ alignSelf: 'flex-end' }}>
        <AddressForm
          selectedContactForm={selectedContactForm}
          selectedAnnualIncome={selectedAnnualIncome}
        />
      </Col>
      <Col>{renderSecondStep()}</Col>
      {entity !== EntityType.INDIVIDUAL && (
        <>
          <Col lg={6}>
            <EntityAddressForm selectedEntity={entity} />
          </Col>
          <Col lg={6}>
            <EntityContactForm
              selectedEntityContactInfo={selectedEntityContactInfo}
              setSelectedEntityContactInfo={setSelectedEntityContactInfo}
              selectedEntity={entity}
              selectedEntityInfo={selectedEntityInfo}
            />
          </Col>
          {entity !== EntityType.IRA && (
            <Col>
              <UploadDocument
                selectedEntity={entity}
                selectedEntityInfo={selectedEntityInfo}
                selectedEntityDocument={selectedEntityDocument}
                setSelectedEntityDocument={setSelectedEntityDocument}
                selectedEntityContactInfo={selectedEntityContactInfo}
                isAlreadyInvested={
                  entity === EntityType.TRUST ? investor_id : changed_id
                }
              />
            </Col>
          )}
          <Col>
            <PaymentSection
              entity={entity}
              step={3}
              smallScreen={matches.small}
            />
          </Col>
        </>
      )}
      <Col>
        <VerificationForm
          verificationInfo={verificationInfo}
          setVerificationInfo={setVerificationInfo}
          selectedEntity={entity}
          eventId={eventId}
          amount={amount}
          selectedContactForm={selectedContactForm}
          tierId={tierId}
          subscriptionAgreement={subscriptionAgreement}
          setSubscriptionAgreement={setSubscriptionAgreement}
          step={entity === EntityType.INDIVIDUAL ? 3 : 4}
        />
        <Review
          setIsOpenModal={setIsOpenModal}
          setIP={setIP}
          isOpenModal={isOpenModal}
          step={entity === EntityType.INDIVIDUAL ? 4 : 5}
        />
      </Col>
    </Row>
  )
}

export default React.memo(InvestmentSteps)
