import { createReducer } from 'typesafe-actions'
import {
  VotesState,
  VotesAction,
  VotesActionTypes,
  IVotesChecking
} from './types'

export const initialState: VotesState = {
  checking: {} as IVotesChecking
}

export const votesReducer = createReducer<VotesState, VotesAction>(
  initialState,
  {
    [VotesActionTypes.VOTES_CHECKING_SUCCESS]: (state, action) => ({
      ...state,
      checking: action.payload
    })
  }
)
