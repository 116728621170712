import React from 'react'
import { generatePath, useHistory } from 'react-router-dom'

import AnalyticsManager from 'services/analyticService'
import { routePath } from 'routes'

import { EventTierSub2 } from './EventTierSub2/EventTierSub2'
import { EventTierSub1 } from './EventTierSub1/EventTierSub1'
import { EventSubType, IEventTier } from 'redux/events/types'
import { useDispatch, useSelector } from 'react-redux'
import {
  changeQuantityBuyNowItemAction,
  setGuestBuyNow
} from 'redux/checkout/actions'
import { selectIsAuth } from 'redux/auth/selectors'

interface EventTierProps {
  tier: IEventTier
  subType: EventSubType
  eventId: number
  isActivePayment: boolean
  isBrand: boolean
  onAddToCart: (tier: IEventTier, tierSetId: number, count: number) => void
  tiersCartQuantity: any
  isDonate: boolean
}

const EventTier: React.FC<EventTierProps> = ({
  tier,
  subType,
  eventId,
  isActivePayment,
  isBrand,
  onAddToCart,
  tiersCartQuantity,
  isDonate
}): React.ReactElement => {
  const history = useHistory()
  const dispatch = useDispatch()
  const isAuthenticated = useSelector(selectIsAuth)
  const handleBuyNowShopping = (tierSetId: number, count: number) => {
    dispatch(changeQuantityBuyNowItemAction(count))
    AnalyticsManager.trackTierCheckoutFromEvent(tier.id)
    if (isAuthenticated) {
      history.push({
        pathname: generatePath(routePath.CHECKOUT, {
          id: eventId,
          tier: tier.id
        }),
        state: {
          tierSetId
        }
      })
    } else {
      dispatch(setGuestBuyNow({ tierSetId, eventId, tierId: tier.id }))
      history.push({
        pathname: routePath.SIGN_IN_GUEST,
        state: {
          from: history.location.pathname
        }
      })
    }
  }

  return subType === EventSubType.SWEEPSTAKES ? (
    <EventTierSub1
      eventId={eventId}
      tier={tier}
      subType={subType}
      isActivePayment={isActivePayment}
      isBrand={isBrand}
    />
  ) : (
    <EventTierSub2
      isDonate={isDonate}
      tier={tier}
      tiersCartQuantity={tiersCartQuantity}
      onAddToCart={onAddToCart}
      onBuyNow={handleBuyNowShopping}
    />
  )
}

export default EventTier
