import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 45px;

  ${media.md`
    align-items: center;
  `}
`
export const UploadText = styled.div`
  font-family: Inter;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.6875rem;
  color: ${p => p.theme.colors.white};
  margin-left: 22px;
`

export const EmailWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 22px;
`
