import React, { FC, useEffect } from 'react'
import { setInvestmentStep } from 'redux/investment/actions'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentStep } from 'redux/investment/selectors'
import { showModal } from 'redux/auth/actions'
import { IPaymentLimit } from 'redux/investment/types'
import { convertValueToPrice } from 'utils/helpers'
import { Controls } from 'src/components/investment/steps'
import { useInvestNowContext } from 'src/pages/investment/InvestNowPage'

import { PaymentWrapper } from 'src/components/investment/steps/styles'
import { PaymentTextWrapper } from 'src/components/investment/steps/payment/USBank/styles'

const WireTransfer: FC = () => {
  const dispatch = useDispatch()
  const currentStep = useSelector(selectCurrentStep)
  const { amount } = useInvestNowContext()

  const onClick = () => {
    dispatch(setInvestmentStep(currentStep + 1))
  }

  useEffect(() => {
    if (amount < IPaymentLimit.WIRE_TRANSFER) {
      dispatch(
        showModal(
          `The minimum investment allowed via Wire Transfer is ${convertValueToPrice(
            String(IPaymentLimit.WIRE_TRANSFER)
          )}.`
        )
      )
    }
  }, [amount, dispatch])

  return (
    <PaymentWrapper>
      <PaymentTextWrapper>
        <p>
          Wire transfer instructions will be sent to you via email. Please note
          that most banks will charge a fee for a wire transfer and sender (you)
          will bear this additional cost. The minimum investment allowed via
          transfer is $750.
        </p>
      </PaymentTextWrapper>
      <Controls
        onClick={onClick}
        disabled={amount < IPaymentLimit.WIRE_TRANSFER}
      />
    </PaymentWrapper>
  )
}

export default WireTransfer
