import styled from 'styled-components'

export const Label = styled.label`
  display: inline-block;
  color: #fff;
  opacity: 0.6;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.1875rem;
  margin-bottom: 0;
`

export default Label
