import React from 'react'

import { generatePath, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectBuyNowGuestCheckout } from 'redux/checkout/selectors'
import { routePath } from 'routes'

import Button from 'components/UI/Button'
import Spin from 'components/UI/Loader'
import Link from 'components/UI/Link'
import Logo from 'components/UI/Logo'
import {
  AuthWrapper,
  Buttons,
  Wrapper,
  WrapperSpin,
  LogoWrapper,
  FormWrapper,
  SignUpWrapper
} from './styles'

const SignInGuestPage: React.FC = () => {
  const guestCheckout = useSelector(selectBuyNowGuestCheckout)
  const history = useHistory()

  const handleClick = () => {
    history.push({
      pathname: generatePath(routePath.CHECKOUT, {
        id: guestCheckout.eventId,
        tier: guestCheckout.tierId
      })
    })
  }

  if (!guestCheckout.eventId) {
    history.goBack()
  }
  return (
    <>
      {!guestCheckout.eventId ? (
        <WrapperSpin>
          <Spin />
        </WrapperSpin>
      ) : (
        <AuthWrapper>
          <FormWrapper>
            <Wrapper>
              <LogoWrapper>
                <Logo width={158} height={121} isLightSkeletonLogo={false} />
              </LogoWrapper>
              <Buttons>
                <Button onClick={handleClick} variant={'clear'}>
                  Guest Checkout
                </Button>
                <Link
                  to={{
                    pathname: routePath.SIGN_IN,
                    state: { ...history.location.state }
                  }}
                  variant={'shop-black-contained'}
                >
                  Log In
                </Link>
              </Buttons>
              <SignUpWrapper>
                <p>Don’t have an account?</p>
                <Link
                  to={{
                    pathname: routePath.SIGN_UP,
                    state: { ...history.location.state }
                  }}
                  variant={'clear'}
                >
                  Create one
                </Link>
              </SignUpWrapper>
            </Wrapper>
          </FormWrapper>
        </AuthWrapper>
      )}
    </>
  )
}

export default SignInGuestPage
