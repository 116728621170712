import React, { useState, useEffect, useRef, FC } from 'react'
import { IEventDetail } from 'redux/events/types'
import { useLocation } from 'react-router-dom'
import { generateMenu } from './genearateMenu'
import MenuItem from './MenuItem'
import Slider from 'components/UI/Slider'
import settings from './settings'
import { SiteMap, Wrapper } from './styles'

const Sitemap: FC<{
  event: IEventDetail
  firstSection?: any
}> = ({ event, firstSection }) => {
  const [activeItem, setActiveItem] = useState<string>('null')
  const [isStickySitemap, setStickySiteMap] = useState<boolean>(false)
  const [sliderOffset, setSliderOffset] = useState<number | null>(null)
  const location = useLocation()

  const slider: any = useRef<typeof Slider | null>(null)
  const sliderRef = useRef<HTMLDivElement | null>(null)

  const menuItems = generateMenu(event)

  useEffect(() => {
    const observer = new MutationObserver(getAnchorPoints)
    // @ts-ignore
    observer.observe(document.getElementById('root'), {
      childList: true,
      subtree: true
    })

    // Set initial offset slider
    const sliderCurrent = sliderRef.current as HTMLDivElement
    setSliderOffset(sliderCurrent.offsetTop)

    const { hash } = location
    if (!hash) {
      slider.current.slickGoTo(0)
      setActiveItem('null')
    }

    window.addEventListener('scroll', handleScroll)
    window.addEventListener('resize', getAnchorPoints)

    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', getAnchorPoints)

      observer.disconnect()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', trackDistanceToHeader, false)
    return () => {
      window.removeEventListener('scroll', trackDistanceToHeader, false)
    }
    // eslint-disable-next-line
  }, [sliderOffset])

  useEffect(() => {
    if (isStickySitemap) {
      slider.current.slickGoTo(-1)
    }
    // eslint-disable-next-line
  }, [location])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isStickySitemap) {
        const curId = menuItems.findIndex(item => item.id === activeItem)
        slider.current.slickGoTo(curId)
      }
    }, 500)

    return () => {
      clearTimeout(timer)
    }

    // eslint-disable-next-line
  }, [menuItems, activeItem])

  const getAnchorPoints = () => {
    const curScroll = window.scrollY - 150

    // tslint:disable-next-line:forin
    for (const key in menuItems) {
      const { id } = menuItems[key]
      const elem = document.getElementById(id)

      // @ts-ignore
      menuItems[key].position =
        elem && elem.getBoundingClientRect().top + curScroll
    }

    handleScroll()
  }

  const trackDistanceToHeader = () => {
    if (sliderOffset) {
      if (window.scrollY + 75 > sliderOffset) {
        firstSection.current.style.paddingTop = '75px'
        setStickySiteMap(true)
      } else if (window.scrollY < sliderOffset) {
        setStickySiteMap(false)
        firstSection.current.style.paddingTop = '0'
      }
    }
  }

  const handleScroll = () => {
    const curPos = window.scrollY
    let curSection = 'null'

    // tslint:disable-next-line:forin
    for (const section in menuItems) {
      if (menuItems[section].enabled && menuItems[section].position) {
        curSection =
          // @ts-ignore
          menuItems[section].position <= curPos + 90
            ? menuItems[section].id
            : curSection
      }

      if (!menuItems[section].id && curSection !== menuItems[section].id) {
        break
      }
    }

    if (curSection !== activeItem) {
      setActiveItem(curSection)
    }

    if (curSection === 'null' && isStickySitemap) {
      slider.current.slickGoTo(-1)
      setActiveItem('-1')
    }
  }

  const menuList = menuItems.map(
    (e, i) =>
      e.enabled && (
        <MenuItem
          title={e.title}
          itemName={e.id}
          key={`menuitem_${i}`}
          active={e.id === activeItem}
        />
      )
  )

  return (
    <SiteMap ref={sliderRef} isStickySitemap={isStickySitemap}>
      <Wrapper>
        <Slider settings={settings} setRef={slider}>
          {menuList}
        </Slider>
      </Wrapper>
    </SiteMap>
  )
}

export default Sitemap
