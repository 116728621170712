import moment from 'moment'
import { ThunkAction } from 'store/types'
import * as actions from './actions'
import {
  AddressFields,
  CardFields,
  CompanyFields,
  ContactFields,
  USBankFields,
  IAddress,
  ICompany,
  IContact,
  ICreditCard,
  IIRA,
  IRAFields,
  IUSBank,
  IVerification,
  VerificationFields,
  ITrust,
  TrustFields,
  EntityType,
  IEntityDocument,
  IPersonalInfo,
  ITrustInfo,
  IEntityInfo,
  IIRAInfo,
  ISubscriptionAgreement,
  ICreditCardToken,
  IPaymentACH,
  IPayment,
  IVerificationInfo,
  IIncome,
  IncomeFields,
  IProhibitedCountry
} from './types'
import {
  checkLuhnAlgorithm,
  convertValueToSSN,
  isValidEmail,
  isValidFullName,
  isValidPhone
} from 'utils/helpers'
import { DEFAULT_DATE_FORMAT, isAdult } from 'utils/dateFormats'
import { InvestmentApi } from 'api/investment.api'

export const fetchTierDetails = (tierId: number): ThunkAction => {
  return async dispatch => {
    dispatch(actions.fetchTierDetailsAsync.request())

    try {
      const { data } = await InvestmentApi.getTierDetails(tierId)
      dispatch(actions.fetchTierDetailsAsync.success(data.data))
    } catch (e) {
      dispatch(actions.fetchTierDetailsAsync.failure(e))
    }
  }
}

export const sendPersonalInfo = (personalInfo: IPersonalInfo): ThunkAction => {
  return async dispatch => {
    dispatch(actions.sendPersonalInformationAsync.request())
    try {
      const { data } = await InvestmentApi.postPersonalInfo(personalInfo)
      dispatch(actions.sendPersonalInformationAsync.success(data))
      return Promise.resolve(data)
    } catch (err) {
      dispatch(actions.sendPersonalInformationAsync.failure(err.response.data))
      return Promise.reject(err)
    }
  }
}

export const checkProhibitedCountry = (
  params: IProhibitedCountry,
  isEntity: boolean
): ThunkAction => {
  return async dispatch => {
    dispatch(actions.checkProhibitedCountryForInvestment.request())
    try {
      const { data } = await InvestmentApi.checkProhibitedCountryForInvesting(
        params
      )

      dispatch(
        actions.checkProhibitedCountryForInvestment.success({
          ...data,
          isEntity
        })
      )
      return Promise.resolve(data)
    } catch (err) {
      return dispatch(
        actions.checkProhibitedCountryForInvestment.failure(err.response.data)
      )
    }
  }
}

export const fetchPersonalInfo = (): ThunkAction => {
  return async dispatch => {
    dispatch(actions.fetchPersonalInformationAsync.request())
    try {
      const { data } = await InvestmentApi.getPersonalInfo()

      dispatch(actions.fetchPersonalInformationAsync.success(data))
    } catch (err) {
      return dispatch(
        actions.fetchPersonalInformationAsync.failure(err.response.data)
      )
    }
  }
}

export const fetchTrustInfo = (): ThunkAction => {
  return async dispatch => {
    dispatch(actions.fetchTrustInformationAsync.request())
    try {
      const { data } = await InvestmentApi.getTrustInfo()

      dispatch(actions.fetchTrustInformationAsync.success(data))
      return
    } catch (err) {
      return dispatch(
        actions.fetchTrustInformationAsync.failure(err.response.data)
      )
    }
  }
}

export const sendVerificationInfo = (
  verificationInfo: IVerificationInfo
): ThunkAction => {
  return async dispatch => {
    dispatch(actions.sendVerificationAsync.request())
    try {
      const resp = await InvestmentApi.postVerificationInfo(verificationInfo)

      dispatch(actions.sendVerificationAsync.success(resp.data))
    } catch (err) {
      dispatch(actions.sendVerificationAsync.failure(err.response.data))
      return Promise.reject(err)
    }
  }
}

export const sendTrustInfo = (trustInfo: ITrustInfo): ThunkAction => {
  return async dispatch => {
    dispatch(actions.sendTrustInformationAsync.request())
    try {
      const { data } = await InvestmentApi.postTrustInfo(trustInfo)

      dispatch(actions.sendTrustInformationAsync.success(data))
      return Promise.resolve(data)
    } catch (err) {
      dispatch(actions.sendTrustInformationAsync.failure(err.response.data))
      return Promise.reject(err)
    }
  }
}

export const fetchEntityInfo = (): ThunkAction => {
  return async dispatch => {
    dispatch(actions.fetchEntityInformationAsync.request())
    try {
      const { data } = await InvestmentApi.getEntityInfo()

      dispatch(actions.fetchEntityInformationAsync.success(data.data))
      return
    } catch (err) {
      return dispatch(
        actions.fetchEntityInformationAsync.failure(err.response.data)
      )
    }
  }
}
export const fetchEntityDetails = (id: string): ThunkAction => {
  return async dispatch => {
    dispatch(actions.fetchEntityInfoDetailsAsync.request())
    try {
      const { data } = await InvestmentApi.getEntityInfoDetails(id)

      dispatch(actions.fetchEntityInfoDetailsAsync.success(data.data))
      return
    } catch (err) {
      return dispatch(
        actions.fetchEntityInfoDetailsAsync.failure(err.response.data)
      )
    }
  }
}

export const sendEntityInfo = (entityInfo: IEntityInfo): ThunkAction => {
  return async dispatch => {
    dispatch(actions.sendEntityInformationAsync.request())
    try {
      const { data } = await InvestmentApi.postEntityInfo(entityInfo)

      dispatch(actions.sendEntityInformationAsync.success(data))
      return Promise.resolve(data)
    } catch (err) {
      dispatch(actions.sendEntityInformationAsync.failure(err.response.data))
      return Promise.reject(err)
    }
  }
}

export const fetchIRAInfo = (): ThunkAction => {
  return async dispatch => {
    dispatch(actions.fetchIraInformationAsync.request())
    try {
      const { data } = await InvestmentApi.getIRAInfo()

      dispatch(actions.fetchIraInformationAsync.success(data))
      return
    } catch (err) {
      return dispatch(
        actions.fetchIraInformationAsync.failure(err.response.data)
      )
    }
  }
}

export const sendIRAInfo = (iraInfo: IIRAInfo): ThunkAction => {
  return async dispatch => {
    dispatch(actions.sendIraInformationAsync.request())
    try {
      const { data } = await InvestmentApi.postIRAInfo(iraInfo)

      dispatch(actions.sendIraInformationAsync.success(data))
      return Promise.resolve(data)
    } catch (err) {
      dispatch(actions.sendIraInformationAsync.failure(err.response.data))
      return Promise.reject(err)
    }
  }
}

export const createSubscriptionAgreement = (
  data: ISubscriptionAgreement
): ThunkAction => {
  return async dispatch => {
    dispatch(actions.createSubscriptionAgreementAsync.request())
    try {
      const resp = await InvestmentApi.createSubscriptionAgreement(data)

      dispatch(actions.createSubscriptionAgreementAsync.success(resp.data))
      return Promise.resolve(resp.data)
    } catch (e) {
      dispatch(
        actions.createSubscriptionAgreementAsync.failure(e.response.data)
      )
    }
  }
}

export const sendEntityPhoto = (data: {
  doc: File
  entity_id: string
  type?: string
}): ThunkAction => {
  return async dispatch => {
    dispatch(actions.sendEntityDocAsync.request())

    try {
      const resp = await InvestmentApi.sendEntityPhoto(data)

      dispatch(actions.sendEntityDocAsync.success(resp.data))
    } catch (e) {
      dispatch(actions.sendEntityDocAsync.failure(e.response.data))
    }
  }
}

export const investPaymentCreditCard = (
  cardData: ICreditCardToken
): ThunkAction => {
  return async dispatch => {
    dispatch(actions.investPaymentCreditCardAsync.request())
    try {
      const data = await InvestmentApi.investPaymentCreditCard(cardData)

      dispatch(actions.investPaymentCreditCardAsync.success(data.data))
      return
    } catch (err) {
      return dispatch(
        actions.investPaymentCreditCardAsync.failure(err.response.data)
      )
    }
  }
}

export const investPaymentACH = (bankData: IPaymentACH): ThunkAction => {
  return async dispatch => {
    dispatch(actions.investPaymentACHAsync.request())
    try {
      const data = await InvestmentApi.investPaymentACH(bankData)

      dispatch(actions.investPaymentACHAsync.success(data.data))
      return
    } catch (err) {
      return dispatch(actions.investPaymentACHAsync.failure(err.response.data))
    }
  }
}

export const investPaymentWire = (wireData: IPayment): ThunkAction => {
  return async dispatch => {
    dispatch(actions.investPaymentWireAsync.request())
    try {
      const data = await InvestmentApi.investPaymentWireTransfer(wireData)

      dispatch(actions.investPaymentWireAsync.success(data.data))
      return
    } catch (err) {
      return dispatch(actions.investPaymentWireAsync.failure(err.response.data))
    }
  }
}

export const getCreditCardErrors = (creditCard: ICreditCard): ICreditCard => {
  const errors: ICreditCard = {} as ICreditCard
  const universalCardRegex = /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/
  const allowedCards = /^[4-5][0-9]*$/
  Object.keys(creditCard).forEach(item => {
    const field = item as keyof ICreditCard
    const fieldValue = creditCard[field]

    if (field === 'token') {
      return
    }
    if (!fieldValue && field === 'cvv') {
      errors[field] = `Card Security code is required`
    }
    if (!fieldValue && field === 'zip') {
      errors[field] = `Postal code is required`
    }
    if (!fieldValue && field !== 'cvv' && field !== 'zip') {
      errors[field] = `${CardFields[field]} is required`
    }

    if (fieldValue) {
      if (field === 'card_number') {
        if (
          !universalCardRegex.test(fieldValue) ||
          !checkLuhnAlgorithm(fieldValue)
        ) {
          errors[field] = `Invalid Credit ${CardFields[field]}`
        }
        if (!allowedCards.test(fieldValue)) {
          errors[field] = `Credit card must be a Visa or MasterCard`
        }
        if (fieldValue.length < 13) {
          errors[field] = `Invalid Credit ${CardFields[field]}`
        }
      }

      if (field === 'expiration_date') {
        const date = new Date()
        const currentYear = date.getFullYear()
        const currentMonth = date.getMonth() + 1
        const currentYearString = currentYear.toString()
        const dateArray = fieldValue.split('/')
        const month = Number(dateArray[0])
        const year = dateArray[1]
        const fullExpYear = year
          ? Number(
              currentYearString.slice(
                0,
                currentYearString.length - year.length
              ) + year
            )
          : ''

        if (
          fieldValue.length < 5 ||
          (fullExpYear === currentYear &&
            (currentMonth > month || currentMonth === month))
        ) {
          errors[field] = `Invalid ${CardFields[field]}`
        }
        if (month > 12 || month === 0) {
          errors[field] = `Invalid ${CardFields[field]}`
        }

        if (fullExpYear < currentYear) {
          errors[field] = `Invalid ${CardFields[field]}`
        }
      }

      if (field === 'cvv') {
        if (fieldValue.length < 3) {
          errors[field] = `Invalid Security code`
        }
      }

      if (field === 'cardholder') {
        if (!isValidFullName(fieldValue)) {
          errors[field] = `Invalid Card holder name`
        }
      }
    }
  })

  return errors
}

export const getUSBankErrors = (USBankInfo: IUSBank): IUSBank => {
  const errors: IUSBank = {} as IUSBank

  Object.keys(USBankInfo).forEach(item => {
    const field = item as keyof IUSBank
    const fieldValue = USBankInfo[field]

    if (!fieldValue) {
      // @ts-ignore
      errors[field] = `${USBankFields[field]} is required`
    }

    if (fieldValue && typeof fieldValue === 'string') {
      if (field === 'routing_number') {
        if (fieldValue.length < 9) {
          errors[field] = `Invalid ${USBankFields[field]}`
        }
      }
      if (field === 'account_number') {
        if (fieldValue.length < 6) {
          errors[
            field
          ] = `${USBankFields[field]} cannot be ${fieldValue.length} characters long`
        }
      }

      if (field === 'name_on_account') {
        if (!isValidFullName(fieldValue.trim())) {
          errors[field] = `Invalid ${USBankFields[field]}`
        }
      }
    }
  })

  return errors
}

export const getContactErrors = (
  contact: IContact,
  entityName: string = ''
): IContact => {
  const errors: IContact = {} as IContact
  const userNameRegex = /^[a-zA-Z][a-zA-Z.`'‘’-]+$/
  const phoneCodeRegex = /^[+][0-9]+$/

  Object.keys(contact).forEach(field => {
    const fieldValue = contact[field]

    if (field === 'id') {
      return
    }

    if (!fieldValue && field !== 'phone') {
      errors[field] = `${entityName} ${ContactFields[field]} is required`
    }
    if (!fieldValue && field === 'phone') {
      errors[field] = `${entityName} ${ContactFields[field]} Number is required`
    }
    if (fieldValue && typeof fieldValue === 'string') {
      if (['first_name', 'last_name'].includes(field)) {
        if (!userNameRegex.test(fieldValue)) {
          errors[field] = `Invalid ${entityName} ${ContactFields[field]}`
        }
      }
      if (field === 'email') {
        if (!isValidEmail(fieldValue)) {
          errors[field] = `Invalid ${entityName} ${ContactFields[field]}`
        }
      }
      if (field === 'phone') {
        if (!isValidPhone(contact.phone_code + fieldValue)) {
          errors[field] = `Invalid ${entityName} ${ContactFields[field]} Number`
        }
      }
      if (field === 'phone_code') {
        if (!phoneCodeRegex.test(fieldValue)) {
          errors[field] = `Invalid ${entityName} ${ContactFields[field]}`
        }
      }
    }
  })

  return errors
}

export const getAddressErrors = (
  address: IAddress,
  entityName: string = ''
): IAddress => {
  const errors: IAddress = {} as IAddress

  Object.keys(address).forEach(field => {
    const fieldValue = address[field]

    if (
      [
        'additional_address',
        'entity_additional_address',
        'is_country_restricted',
        'country_label'
      ].includes(field)
    ) {
      return
    }

    if (!fieldValue && field === 'street') {
      errors[field] = `${entityName} Address is required`
    }
    if (
      !fieldValue &&
      address.address &&
      !['additional_address', 'entity_additional_address'].includes(field)
    ) {
      errors[field] = `${AddressFields[field]} is required`
    }
    if (!fieldValue && address.street && field !== 'postal_code') {
      errors[field] = `${AddressFields[field]} is required`
    }
  })

  return errors
}

export const getVerificationErrors = (
  verification: IVerification,
  isAlreadyInvested: boolean
): IVerification => {
  const ssnRegex = /^(?!999\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/
  const errors: IVerification = {} as IVerification
  const maxSize = 30 * 1024 * 1024
  const isValidPassport =
    verification.passport &&
    verification.passport.some((f: any) => f.size > maxSize)

  Object.keys(verification).forEach(item => {
    const field = item as keyof IVerification
    const fieldValue = verification[field]

    if (!fieldValue) {
      if (
        field === 'passport' &&
        !(!verification.citizenUSA && isAlreadyInvested)
      ) {
        if (!verification.citizenUSA && !verification.passport) {
          errors.passport = 'Must upload photo of your passport'
        }
      }
      if (field === 'ssn') {
        if (verification.citizenUSA && !verification.ssn) {
          errors.ssn = `${VerificationFields.ssn} is required`
        }
      }
      if (field === 'date_of_birth') {
        errors[field] = `${VerificationFields[field]} is required`
      }
    }
    if (fieldValue && field === 'passport') {
      if (isValidPassport) {
        errors.passport = 'File too large. Must be less then 30MB'
      }
      if (verification.passport && verification.passport.length > 3) {
        errors.passport = 'Too much files, maximum is 3 files'
      }
    }
    if (fieldValue && typeof fieldValue === 'string') {
      if (field === 'ssn' && !ssnRegex.test(convertValueToSSN(fieldValue))) {
        errors[field] = `Invalid ${VerificationFields[field]}`
      }

      if (field === 'date_of_birth') {
        const date = moment(fieldValue, DEFAULT_DATE_FORMAT, true)

        if (!isAdult(fieldValue)) {
          errors[field] = 'You must be at least 18 years old'
        }

        if (!date.isValid() || moment().diff(date, 'years') >= 100) {
          errors[field] = `Invalid ${VerificationFields[field]}`
        }
      }
    }
  })

  return errors
}

export const getAnnualIncomeErrors = (
  incomeInfo: Required<IIncome>
): IIncome => {
  const errors: IIncome = {} as IIncome

  Object.keys(incomeInfo).forEach(field => {
    const fieldValue = incomeInfo[field]
    const cleanValue = fieldValue.replace(/[^0-9]/g, '')
    if (cleanValue <= 0) {
      errors[field] = `${IncomeFields[field]} cannot be $0`
    }
    if (!fieldValue) {
      errors[field] = `${IncomeFields[field]} is required`
    }
  })

  return errors
}

export const getTrustErrors = (trustInfo: Required<ITrust>): ITrust => {
  const errors: ITrust = {} as ITrust
  const { trust_name, trust_tax_id } = trustInfo

  Object.keys({ trust_name, trust_tax_id }).forEach(item => {
    const field = item as keyof ITrust
    const fieldValue = trustInfo[field]

    if (!fieldValue) {
      errors[field] = `${TrustFields[field]} is required`
    }
    if (field === 'trust_tax_id') {
      const cleanValue = fieldValue.replace(/[^0-9]/g, '')
      if (cleanValue.length !== 9) {
        errors[field] = `Invalid ${TrustFields[field]}`
      }
    }
  })

  return errors
}

export const getIraErrors = (iraInfo: IIRA): IIRA => {
  const errors: IIRA = {} as IIRA

  Object.keys(iraInfo).forEach(field => {
    const fieldValue = iraInfo[field]
    if (field === 'isFetching') {
      return
    }

    if (!fieldValue) {
      errors[field] = `${IRAFields[field]} is required`
    }

    if (field === 'ira_tax_id') {
      const cleanValue = fieldValue.replace(/[^0-9]/g, '')
      if (cleanValue.length !== 9) {
        errors[field] = `Invalid ${IRAFields[field]}`
      }
    }
  })

  return errors
}

export const getCompanyInfoErrors = (company: ICompany): ICompany => {
  const errors: ICompany = {} as ICompany
  Object.keys(company).forEach(item => {
    const field = item as keyof ICompany
    const fieldValue = company[field]

    if (!fieldValue && field !== 'state') {
      errors[field] = `${CompanyFields[field]} is required`
    }

    if (field === 'entity_tax_id') {
      const cleanValue = fieldValue.replace(/[^0-9]/g, '')
      if (cleanValue.length !== 9) {
        errors[field] = `Invalid ${CompanyFields[field]}`
      }
    }
  })

  return errors
}

export const getUserSignatureErrors = (userSignature: string) => {
  const errors: { [k: string]: string } = {}

  if (userSignature.length <= 2) {
    errors.userSignatory = 'Please Sign Your Full Name'
  }

  return errors
}

export const getEntityDocumentErrors = (
  file: IEntityDocument,
  entityType: EntityType,
  isAlreadyInvested: boolean
) => {
  const errors: { [k: string]: any } = {}

  if (isAlreadyInvested && !file.entity_document) {
    return errors
  }

  const maxSize = 30 * 1024 * 1024
  if (!file.entity_document) {
    errors.entity_document = `Document for ${entityType} must be uploaded`
  }

  if (file.entity_document && file.entity_document.size > maxSize) {
    errors.entity_document = 'File too large. Must be less then 30MB'
  }

  return errors
}
