import React from 'react'

const CartIcon = props => (
  <svg
    viewBox="0 0 13 12"
    width={13}
    height={13}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.73 6.6c.45 0 .846-.246 1.05-.618l2.148-3.894a.598.598 0 00-.522-.888h-8.88L1.962 0H0v1.2h1.2l2.16 4.554-.81 1.464C2.112 8.022 2.688 9 3.6 9h7.2V7.8H3.6l.66-1.2h4.47zM3.096 2.4h7.29l-1.656 3H4.518l-1.422-3zM3.6 9.6c-.66 0-1.194.54-1.194 1.2 0 .66.534 1.2 1.194 1.2.66 0 1.2-.54 1.2-1.2 0-.66-.54-1.2-1.2-1.2zm6 0c-.66 0-1.194.54-1.194 1.2 0 .66.534 1.2 1.194 1.2.66 0 1.2-.54 1.2-1.2 0-.66-.54-1.2-1.2-1.2z"
      fill="#fff"
    />
  </svg>
)

export default CartIcon
