import styled from 'styled-components'
import { media, Row } from 'styled-bootstrap-grid'
import Button from 'components/UI/Button'

export const CheckoutPageContainer = styled.div`
  background: #000;
  padding: 2.875rem 1rem 4rem 1rem;

  ${media.sm`
    padding: 2.875rem 5.25rem 8rem 5.25rem;
  `}

  ${media.lg`
    padding: 2.875rem calc(5vw - 2.25rem) 8rem calc(5vw - 2.25rem);
  `}

  ${media.xl`
    padding: 2.875rem 5.25rem 8rem 6.25rem;
  `}
`

export const ContentRow = styled(Row)`
  flex-direction: column-reverse;

  ${media.lg`
    flex-direction: row;
  `}
`

export const TermsConditionText = styled.p`
  max-width: 600px;
  padding-bottom: 1rem;
  text-align: left;

  a {
    color: ${p => p.theme.colors.gold};
    text-decoration: underline;
  }
  ${media.lg` 
     text-align: left;
  `}
`

export const BackButton = styled(Button).attrs(() => ({
  variant: 'transparent',
  children: 'Back'
}))`
  padding: 1.25rem 2.25rem;
  margin-right: 1.25rem;

  ${media.md` 
    margin-right: 7.5rem;
  `}
`

export const PageTitle = styled.h2`
  font-weight: 700;
  font-size: 3rem;
  font-family: 'Roboto';
  line-height: 56px;
  color: #ffffff;
  letter-spacing: 1.76px;
  margin-bottom: 2.0625rem;
`

export const MainRow = styled.div`
  padding-left: 0;
  position: relative;
  margin: 2.5rem 0;
  // overflow: hidden;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${media.sm`
    padding-left: 72px;
  `}
`
export const WrapperSpin = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25vh;
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 100px;
`
