import React from 'react'
import { routePath } from 'routes'
import { ProfileSettingTypes } from 'redux/profile/types'
import { IUser, ROLES } from 'redux/auth/types'
import { UserIcon, SupportIcon } from 'components/icons/index'
import { generatePath } from 'react-router-dom'
import DetailsForm from 'components/profile/settings/DetailsForm'
import AddressForm from 'components/profile/settings/AddressForm'
import PasswordForm from 'components/profile/settings/PasswordForm'
import ReportForm from 'components/profile/settings/ReportForm'
import FAQBlock from 'components/profile/settings/faq/FAQBlock'

const tabs = (user: IUser, socialAccount: boolean) => {
  const t: any = {
    [ProfileSettingTypes.DETAILS]: {
      title: 'Basic Details',
      icon: <UserIcon />,
      url: generatePath(routePath.PROFILE_SETTINGS_OPTION, {
        type: ProfileSettingTypes.DETAILS
      }),
      resources: {
        [ProfileSettingTypes.ACCOUNT]: {
          title: 'Profile',
          url: generatePath(routePath.PROFILE_SETTINGS_SUBOPTION, {
            type: ProfileSettingTypes.DETAILS,
            id: ProfileSettingTypes.ACCOUNT
          }),
          component: <DetailsForm />
        },
        [ProfileSettingTypes.ADDRESS]: {
          title: 'Address',
          url: generatePath(routePath.PROFILE_SETTINGS_SUBOPTION, {
            type: ProfileSettingTypes.DETAILS,
            id: ProfileSettingTypes.ADDRESS
          }),
          hidden: user.role === ROLES.BRAND,
          component: <AddressForm />
        },
        [ProfileSettingTypes.PASSWORD]: {
          title: 'Password',
          url: generatePath(routePath.PROFILE_SETTINGS_SUBOPTION, {
            type: ProfileSettingTypes.DETAILS,
            id: ProfileSettingTypes.PASSWORD
          }),
          hidden: socialAccount,
          component: <PasswordForm />
        }
      }
    },
    [ProfileSettingTypes.HELP]: {
      title: 'Help Center',
      icon: <SupportIcon />,
      url: generatePath(routePath.PROFILE_SETTINGS_OPTION, {
        type: ProfileSettingTypes.HELP
      }),
      resources: {
        [ProfileSettingTypes.CONTACT_SUPPORT]: {
          title: 'Support',
          url: generatePath(routePath.PROFILE_SETTINGS_SUBOPTION, {
            type: ProfileSettingTypes.HELP,
            id: ProfileSettingTypes.CONTACT_SUPPORT
          }),
          component: <ReportForm />
        },
        [ProfileSettingTypes.FAQ]: {
          title: 'Frequently Asked Questions',
          url: generatePath(routePath.PROFILE_SETTINGS_FAQ_TYPE, {
            type: ProfileSettingTypes.HELP,
            id: ProfileSettingTypes.FAQ,
            faq: 'about_fanvestor'
          }),
          hidden: user.role === ROLES.BRAND,
          component: <FAQBlock i={0} />
        },
        about: {
          title: 'About Fanvestor',
          url: generatePath(routePath.PROFILE_SETTINGS_FAQ_TYPE, {
            type: ProfileSettingTypes.HELP,
            id: ProfileSettingTypes.FAQ,
            faq: 'about_fanvestor'
          }),
          isSubTab: true,
          hidden: user.role === ROLES.BRAND,
          component: <FAQBlock i={0} />
        },
        platform: {
          title: 'Platform Projects',
          url: generatePath(routePath.PROFILE_SETTINGS_FAQ_TYPE, {
            type: ProfileSettingTypes.HELP,
            id: ProfileSettingTypes.FAQ,
            faq: 'platform_projects'
          }),
          isSubTab: true,
          hidden: user.role === ROLES.BRAND,
          component: <FAQBlock i={1} />
        },
        app: {
          title: 'FanVestor App',
          url: generatePath(routePath.PROFILE_SETTINGS_FAQ_TYPE, {
            type: ProfileSettingTypes.HELP,
            id: ProfileSettingTypes.FAQ,
            faq: 'fanvestor_app'
          }),
          isSubTab: true,
          hidden: user.role === ROLES.BRAND,
          component: <FAQBlock i={2} />
        },
        payment: {
          title: 'Payment Processing',
          url: generatePath(routePath.PROFILE_SETTINGS_FAQ_TYPE, {
            type: ProfileSettingTypes.HELP,
            id: ProfileSettingTypes.FAQ,
            faq: 'payment_processing'
          }),
          isSubTab: true,
          hidden: user.role === ROLES.BRAND,
          component: <FAQBlock i={3} />
        }
      }
    }
  }

  const main = Object.keys(t).reduce((mainAcc, section) => {
    const subTabs = t[section].resources

    const filteredsubTabs = Object.keys(subTabs).reduce((acc, key) => {
      return !subTabs[key].hidden ? { ...acc, [key]: subTabs[key] } : acc
    }, {})

    return {
      ...mainAcc,
      [section]: { ...t[section], resources: filteredsubTabs }
    }
  }, {})

  return {
    main
  }
}
export default tabs
