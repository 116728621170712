import React, { useEffect, useState } from 'react'
import { Col } from 'styled-bootstrap-grid'
import { useDispatch, useSelector } from 'react-redux'
import {
  generatePath,
  Redirect,
  useHistory,
  useParams,
  useRouteMatch
} from 'react-router-dom'

import { routePath } from 'routes'
import AnalyticsManager from 'services/analyticService'
import {
  fetchCheckoutInfo,
  fetchTier,
  setShipmentFromProfile
} from 'redux/checkout/operations'
import { fetchUserAddress } from 'redux/profile/operations'
import { setInitialCheckout } from 'redux/checkout/actions'
import { isBrandSelector, selectIsAuth } from 'redux/auth/selectors'
import { fetchEvent } from 'redux/events/operations'
import {
  selectEventsDetail,
  selectEventsIsFetching,
  selectEventSubtype
} from 'redux/events/selectors'
import {
  selectBuyNowGuestCheckout,
  selectBuyNowTier,
  selectBuyNowTierQuantity,
  selectCart,
  selectCartInfo,
  selectCartItemsCount,
  selectCartTiersQuantity,
  selectCheckoutFetching,
  selectCheckoutProcessing,
  selectShipping
} from 'redux/checkout/selectors'
import Spin from 'components/UI/Loader'
import ComingSoon from 'components/common/ComingSoon'
import Link from 'components/UI/Link'
import { PaymentMethods, ShippingForm } from 'components/checkout'

import { AppState } from 'store/types'
import { Statuses } from 'redux/commonTypes'

import {
  CheckoutPageContainer,
  ContentRow,
  PageTitle,
  TermsConditionText,
  WrapperSpin
} from './styles'
import { WEBSITE } from 'utils/constants'
import { isValidEmail, isValidFullName } from 'utils/helpers'
import { OrderList } from './OrderList/OrderList'
import { EventSubType } from 'redux/events/types'
import { ICartItem } from 'redux/checkout/types'

interface MatchParams {
  id: string
  tier: string
}

export const CheckoutPage: React.FC = (): React.ReactElement => {
  const params = useParams<MatchParams>()
  const isShopping = !!useRouteMatch(routePath.CHECKOUT_CART)
  const dispatch = useDispatch()
  const history = useHistory<{ tierSetId: number }>()
  const tierSetId =
    history.location &&
    history.location.state &&
    Number(history.location.state.tierSetId)
  const [isPageLoaded, setIsPageLoaded] = useState(false)

  const cartInfo = useSelector(selectCartInfo)
  const isAuthenticated = useSelector(selectIsAuth)
  const cartQuantities = useSelector(selectCartTiersQuantity)
  const shippingInfo = useSelector(selectShipping)
  const event = useSelector(selectEventsDetail)
  const eventSubType = useSelector(selectEventSubtype)
  const activeTier = useSelector(selectBuyNowTier)
  const isFetching = useSelector(selectCheckoutFetching)
  const activeTierQuantity = useSelector(selectBuyNowTierQuantity)
  const guestCheckout = useSelector(selectBuyNowGuestCheckout)
  const eventsLoading = useSelector(selectEventsIsFetching)
  const tierLoading = useSelector(
    (state: AppState) => state.events.isFetchingTier
  )
  const isBrand = useSelector(isBrandSelector)
  const cart = useSelector(selectCart)
  const totalQuantity = useSelector(selectCartItemsCount)
  const paymentProcessing = useSelector(selectCheckoutProcessing)
  const issetTier =
    eventSubType !== EventSubType.SWEEPSTAKES ? !!tierSetId : true
  const isBlockedPayments =
    (isShopping || eventSubType !== EventSubType.SWEEPSTAKES) &&
    cartInfo.isBlocked

  const checkShipping =
    eventSubType === EventSubType.SWEEPSTAKES
      ? false // For SWEEPSTAKES no need to check the shipping.
      : !isValidFullName(shippingInfo.recipient_name) ||
        !shippingInfo.street.label

  const isValidShipping = isAuthenticated // If not type SWEEPSTAKES we need to check authenticated user.
    ? checkShipping // If authenticated and not a SWEEPSTAKES - check the address and name
    : checkShipping || !isValidEmail(shippingInfo.email || '') // if not - check the address, name and email.

  useEffect(() => {
    const { id, tier } = params

    const initOperations = async () => {
      if (isShopping) {
        await dispatch(fetchUserAddress())
        await dispatch(setShipmentFromProfile())

        setIsPageLoaded(true)
      } else {
        dispatch(fetchEvent(Number(id)))
        await dispatch(fetchTier(id, tier))
        if (isAuthenticated) {
          await dispatch(fetchUserAddress())
          await dispatch(setShipmentFromProfile())
        }
        setIsPageLoaded(true)
      }
    }

    initOperations()

    return () => {
      setIsPageLoaded(false)
      dispatch(setInitialCheckout())
    }
  }, [params, dispatch, isShopping, tierSetId, isAuthenticated])

  useEffect(() => {
    if (
      !(
        isBrand ||
        !Object.keys(activeTier).length ||
        !Object.keys(event).length ||
        !event.payment_active ||
        activeTier.left <= 0
      )
    ) {
      AnalyticsManager.trackCheckoutScreen(true, activeTier.id)
    }
  }, [activeTier, isBrand, event])

  useEffect(() => {
    if (isAuthenticated && isPageLoaded && !paymentProcessing) {
      if (
        !isShopping &&
        issetTier &&
        event.sub_type !== EventSubType.SWEEPSTAKES &&
        Object.keys(activeTier).length
      ) {
        const [activeSet] = activeTier.sets.filter(
          s => s.tier_set_id === tierSetId
        )
        dispatch(fetchCheckoutInfo(activeSet.tier_set_id))
      } else if (
        isShopping &&
        !Object.keys(activeTier).length &&
        totalQuantity > 0
      ) {
        dispatch(fetchCheckoutInfo())
      }
    } else {
      if (
        shippingInfo.street.value &&
        event.sub_type !== EventSubType.SWEEPSTAKES &&
        Object.keys(activeTier).length &&
        !isShopping &&
        !isAuthenticated
      ) {
        dispatch(fetchCheckoutInfo(guestCheckout.tierSetId))
      }
    }
    // eslint-disable-next-line
  }, [
    shippingInfo.street.value,
    totalQuantity,
    cartQuantities,
    activeTierQuantity,
    isPageLoaded
  ])

  useEffect(() => {
    const renderNotFound = () => {
      if (isShopping && cart.items.length < 1 && !isFetching && isPageLoaded) {
        return <ComingSoon text="Your cart is empty" />
      } else {
        if (
          isBrand ||
          !Object.keys(activeTier).length ||
          !Object.keys(event).length ||
          !event.payment_active ||
          activeTier.status !== Statuses.LIVE ||
          activeTier.left <= 0
        ) {
          return <ComingSoon text="Not found" />
        }
      }
    }
    renderNotFound()
    // eslint-disable-next-line
  }, [])

  const renderOrderList = () => {
    const setId = isAuthenticated ? tierSetId : guestCheckout.tierSetId
    const order: ICartItem[] = [
      {
        id: tierSetId || activeTier.id,
        tier_name: activeTier.title,
        tier_id: activeTier.id,
        tier_price: activeTier.value,
        tier_image: activeTier.picture,
        tier_influencer: activeTier.influencer_nickname,
        // @ts-ignore
        sets:
          activeTier.sets &&
          activeTier.sets.filter(tierSet => tierSet.tier_set_id === setId),
        quantity: activeTierQuantity
      }
    ]
    return (
      <OrderList
        withoutCounter={
          !isShopping && eventSubType === EventSubType.SWEEPSTAKES
        }
        fromShopping={isShopping}
        order={isShopping ? cart.items : order}
        isFetching={isFetching}
        isPageLoaded={isPageLoaded}
        cartInfo={cartInfo}
        isBlocked={isBlockedPayments}
      />
    )
  }
  if (
    eventSubType &&
    eventSubType === EventSubType.SWEEPSTAKES &&
    !isAuthenticated
  ) {
    return <Redirect to={routePath.SIGN_IN} />
  }
  if (
    (eventSubType &&
      eventSubType !== EventSubType.SWEEPSTAKES &&
      !isShopping &&
      !issetTier &&
      !guestCheckout.tierSetId) ||
    (!isAuthenticated && !guestCheckout.tierSetId)
  ) {
    return (
      <Redirect
        to={generatePath(routePath.EVENT, {
          id: params.id
        })}
      />
    )
  }

  if (isShopping && totalQuantity < 1 && !isFetching && isPageLoaded) {
    return <Redirect to={routePath.EVENTS_PAGE} />
  }
  if (eventsLoading || tierLoading || !isPageLoaded) {
    return (
      <WrapperSpin>
        <Spin />
      </WrapperSpin>
    )
  }

  return (
    <CheckoutPageContainer>
      <PageTitle>Checkout</PageTitle>
      <ContentRow>
        <Col lg={7} md={12} sm={12} col={12}>
          {((activeTier && activeTier.required_shipping_address) ||
            isShopping) && (
            <ShippingForm isBlockedPayments={isBlockedPayments} />
          )}
          <PaymentMethods
            isShopping={isShopping}
            isBlocked={isBlockedPayments || isValidShipping}
          />
          {eventSubType === EventSubType.SWEEPSTAKES && (
            <TermsConditionText>
              By continuing, you agree to our{' '}
              <Link
                target={'_blank'}
                variant={'clear'}
                to={WEBSITE + 'terms-of-use'}
              >
                Terms & Conditions
              </Link>{' '}
              and our sweepstakes{' '}
              <Link
                target={'_blank'}
                variant={'clear'}
                to={WEBSITE + `rules-${event.event_id}`}
              >
                Official Rules
              </Link>
              . No donation required to enter or win.
            </TermsConditionText>
          )}
        </Col>
        <Col lg={5} md={12} sm={12} col={12}>
          {renderOrderList()}
        </Col>
      </ContentRow>
    </CheckoutPageContainer>
  )
}
