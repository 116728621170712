import { LINKS, WEBSITE } from 'utils/constants'
import { routePath } from 'routes'

const menuList = (isAuthenticated: boolean) => {
  return [
    {
      title: 'Company',
      list: [
        { link: WEBSITE + 'team', subtitle: 'About Us' },
        {
          link: WEBSITE + 'press',
          subtitle: 'Press'
        },
        {
          link: '#',
          subtitle: 'Careers'
        },
        {
          link: WEBSITE + 'blog',
          subtitle: 'Blog'
        },
        {
          link: WEBSITE + LINKS.CONTACT_US,
          subtitle: 'Contact Us'
        }
      ]
    },
    {
      title: 'FAQ',
      list: [
        {
          link: `${
            isAuthenticated
              ? routePath.PROFILE_SETTINGS_FAQ
              : WEBSITE + LINKS.FAQ
          }`,
          subtitle: 'FanVestor'
        },
        {
          link: `${
            isAuthenticated
              ? routePath.PROFILE_SETTINGS_FAQ
              : WEBSITE + LINKS.REG_CF
          }`,
          subtitle: 'Reg CF'
        },
        {
          link: `${
            isAuthenticated
              ? routePath.PROFILE_SETTINGS_FAQ
              : WEBSITE + LINKS.EXPERIENCES
          }`,
          subtitle: 'Experiences'
        }
      ]
    },
    {
      title: 'How It Works',
      list: [
        {
          link: WEBSITE + LINKS.LEARN,
          subtitle: 'invest'
        },
        {
          link: WEBSITE + LINKS.RISK,
          subtitle: 'risk factors'
        }
      ]
    },
    {
      title: 'Profile',
      hidden: isAuthenticated,
      list: [
        {
          link: routePath.SIGN_IN,
          subtitle: 'Log In'
        },
        {
          link: routePath.SIGN_UP,
          subtitle: 'Register'
        }
      ]
    },
    {
      title: 'Legal',
      list: [
        {
          link: WEBSITE + LINKS.TERMS,
          subtitle: 'terms & conditions'
        },
        {
          link: WEBSITE + LINKS.PRIVACY,
          subtitle: 'privacy policy'
        },
        {
          link: WEBSITE + LINKS.COOKIE,
          subtitle: 'cookie notice'
        },
        {
          link: WEBSITE + LINKS.ACCESSIBILITY,
          subtitle: 'accessibility'
        },
        {
          link: WEBSITE + LINKS.FORM_S_P,
          subtitle: 'Form S-P'
        }
      ]
    }
  ]
}

export default menuList
