import React from 'react'
import styled, { CSSObject } from 'styled-components'

const Label = styled.label`
  display: inline-block;
  font-family: Inter;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.75rem;
  text-align: center;
  color: ${p => p.theme.colors.white};
`

interface Props {
  label?: string | string[]
  style?: CSSObject
}

const ErrorLabel = (props: Props) => {
  const { label, ...restProps } = props

  if (Array.isArray(label) && label.length > 0) {
    return <Label {...restProps}>{label[0]}</Label>
  }
  return <Label {...restProps}>{label}</Label>
}

export default ErrorLabel
