import { EventType } from 'redux/events/types'

export const configTabs = (invEvent: any, expEvent: any, NFTsEvent: any) => {
  const isInvEventNotEmpty = invEvent.hasOwnProperty('data')
  const isExpEventNotEmpty = expEvent.hasOwnProperty('data')
  const isNFTEventNotEmpty = NFTsEvent.hasOwnProperty('data')

  const totalInv =
    isInvEventNotEmpty &&
    invEvent.pagination &&
    invEvent.pagination.total_results
  const totalExp =
    isExpEventNotEmpty &&
    expEvent.pagination &&
    expEvent.pagination.total_results
  const totalNFT =
    isNFTEventNotEmpty &&
    NFTsEvent.pagination &&
    NFTsEvent.pagination.total_results

  return {
    [EventType.INVESTMENT]: {
      title:
        totalInv < 1
          ? ''
          : totalInv > 1
          ? `${totalInv} <span>INVESTMENT OFFERINGS</span>`
          : `${totalInv} <span>INVESTMENT OFFERING</span>`
    },
    [EventType.EXPERIENCES]: {
      title:
        totalExp > 0 ? `${totalExp} <span>CHARITY PRODUCT LAUNCHES</span>` : ''
    },
    [EventType.NFT]: {
      title:
        totalNFT < 1
          ? ''
          : totalNFT > 1
          ? `${totalNFT} <span>NFTs OFFERINGS</span>`
          : `${totalNFT} <span>NFT OFFERING</span>`
    }
  }
}
