import { AxiosRequestConfig } from 'axios'
import jwtDecode from 'jwt-decode'
import { logout } from 'redux/auth/operations'
import { store } from '../App'
import FingerprintJS from '@fingerprintjs/fingerprintjs'

export class TokenStorage {
  public static isAuthenticated(): boolean {
    const token = this.getToken()
    return !!token && !this.isTokenExpired(token)
  }

  public static getAuthentication(): AxiosRequestConfig {
    return {
      headers: { Authorization: 'Bearer ' + this.getToken() }
    }
  }

  public static storeDeviceId(id: string): void {
    localStorage.setItem(TokenStorage.LOCAL_STORAGE_DEVICE_ID, id)
  }

  public static initDeviceId(): void {
    FingerprintJS.load()
      .then(fp => fp.get())
      .then(result => {
        if (!TokenStorage.getDevice()) {
          TokenStorage.storeDeviceId(result.visitorId)
        }
      })
  }

  public static getFingerPrint(): string | null {
    const device = localStorage.getItem(TokenStorage.LOCAL_STORAGE_FINGERPRINT)
    const deviceId = device && JSON.parse(device)
    return deviceId ? deviceId.browser_fingerprint_id : ''
  }

  public static getDevice(): string | null {
    return localStorage.getItem(TokenStorage.LOCAL_STORAGE_DEVICE_ID)
  }

  public static storeToken(token: string): void {
    localStorage.setItem(TokenStorage.LOCAL_STORAGE_TOKEN, token)
  }

  public static storeSocialToken(token: string): void {
    localStorage.setItem(TokenStorage.LOCAL_STORAGE_SOCIAL_TOKEN, token)
  }

  public static clear(): void {
    localStorage.removeItem(TokenStorage.LOCAL_STORAGE_TOKEN)
    localStorage.removeItem(TokenStorage.LOCAL_STORAGE_SOCIAL_TOKEN)
  }

  public static getToken(): string | null {
    return localStorage.getItem(TokenStorage.LOCAL_STORAGE_TOKEN)
  }

  public static getSocialToken(): string | null {
    return localStorage.getItem(TokenStorage.LOCAL_STORAGE_SOCIAL_TOKEN)
  }

  public static isTokenVerified(): boolean {
    const token: string | null = this.getToken()
    return this.isVerified(token)
  }

  public static isVerified(token: string | null): boolean {
    const decoded: any = token ? jwtDecode(token) : {}

    return decoded && decoded.scopes
      ? !decoded.scopes.includes('verification')
      : false
  }

  private static readonly LOCAL_STORAGE_FINGERPRINT = 'branch_session_first'
  private static readonly LOCAL_STORAGE_DEVICE_ID = 'deviceId'
  private static readonly LOCAL_STORAGE_TOKEN = 'token'
  private static readonly LOCAL_STORAGE_SOCIAL_TOKEN = 'social-token'

  private static isTokenExpired(token: string): boolean {
    try {
      const decoded = jwtDecode(token)
      // @ts-ignore
      return Date.now() / 1000 >= decoded.exp
    } catch (err) {
      // @ts-ignore
      store.dispatch(logout())
      TokenStorage.clear()
      return false
    }
  }
}
