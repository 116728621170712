import React from 'react'

const GoBackArrowIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="14"
    viewBox="0 0 20 14"
  >
    <defs />
    <path
      fill="#D4AB72"
      d="M6.537.256A.712.712 0 117.54 1.258L2.427 6.376h16.897a.713.713 0 010 1.425H2.427l5.12 5.112a.725.725 0 010 1.001.708.708 0 01-1.002 0L.213 7.582a.695.695 0 010-1.002z"
    />
  </svg>
)

export default GoBackArrowIcon
