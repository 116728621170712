import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export const ParamsSection = styled.div`
  padding: 1.5625rem;
`
export const ParameterBlock = styled.div<{ error?: boolean }>`
  margin-bottom: 1.375rem;
  :nth-last-child(1) {
    margin-bottom: 0;
  }

  h6 {
    font-family: 'Acumin Pro';
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.25rem;
    color: ${p => (p.error ? p.theme.colors.red : p.theme.colors.darkenGold)};
    margin-bottom: 0.4375rem;
    text-transform: capitalize;
    &::after {
      display: ${p => (p.error ? 'inline-block' : 'none')};
      content: '?';
      padding-left: 10px;
      width: 20px;
      height: 20px;
      font-size: 1rem;
    }
    span {
      color: inherit;
      font-size: inherit;
      ${media.md`
        display: none;
      `}
    }
  }
`

export const OptionRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`
export const OptionBlock = styled.button<{
  variant: 'size' | 'color'
  hex?: any
  selected?: boolean
  disabled?: boolean
  hideLabel?: boolean
}>`
  min-width: 68px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: ${p =>
    p.variant === 'size' ? '0.8125rem 1.375rem' : '0.9375rem 0.8125rem'};
  margin-right: 0.375rem;
  margin-bottom: 0.375rem;
  border: 1px solid #272722;

  color: ${p => p.theme.colors.darkenGold};
  font-family: 'Acumin Pro';
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.5rem;
  border: 1px solid
    ${p =>
      p.selected
        ? p.theme.colors.darkenGold
        : p.disabled
        ? '#554734'
        : '#272722'};
  background-color: ${p =>
    p.selected ? '#3E3E33' : p.selected && p.disabled ? '#272722' : '#272722'};
  cursor: ${p => (p.disabled ? 'default' : 'pointer')};
  pointer-event: ${p => (p.disabled ? 'none' : 'auto')};
  opacity: ${p => (p.disabled ? 0.4 : 1)};

  :nth-last-child(1) {
    margin-right: 0;
  }
  svg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  &:before {
    display: ${p => (p.variant === 'color' ? 'inline-block' : 'none')};
    content: ' ';
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: ${p => (p.hex ? p.hex : '#fff')};
  }
  span {
    display: ${p => (p.hideLabel ? 'none' : 'inline')};
    color: inherit;
    font-size: inherit;
    margin: 0 0.5rem;
    ${media.md`
      display: inline;
    `}
  }
`
export const OptionBlock2 = styled.div<{
  variant: 'size' | 'color'
  selected?: boolean
  disabled?: boolean
}>`
  min-width: 68px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: ${p =>
    p.variant === 'size' ? '0.8125rem 1.375rem' : '0.9375rem 0.8125rem'};
  text-transform: ${p => (p.variant === 'color' ? 'capitalize' : 'uppercase')};
  border: 1px solid
    ${p =>
      p.selected
        ? p.theme.colors.darkenGold
        : p.disabled
        ? '#554734'
        : '#272722'};
  background-color: ${p =>
    p.selected ? '#3E3E33' : p.selected && p.disabled ? '#272722' : '#272722'};
  margin-right: 0.375rem;
  margin-bottom: 0.375rem;
  text-transform: ${p => (p.variant === 'color' ? 'capitalize' : 'uppercase')};
  cursor: ${p => (p.disabled ? 'default' : 'pointer')};
  pointer-event: ${p => (p.disabled ? 'none' : 'auto')};
  opacity: ${p => (p.disabled ? 0.4 : 1)};
  :nth-last-child(1) {
    margin-right: 0;
  }
  span {
    color: ${p => p.theme.colors.darkenGold};
    font-family: 'Acumin Pro';
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
  svg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`

export const ColorCircle = styled.div<{ hex: string }>`
  width: 14px;
  height: 14px;
  margin-right: 8px;
  border-radius: 50%;
  background-color: ${p => (p.hex ? p.hex : '#fff')};
  margin-bottom: 5px;
`
