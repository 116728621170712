import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export const AuthWrapper = styled.div`
  background: #000;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  padding-top: 74px;

  input {
    font-size: 0.9375rem !important;
    ${media.md`
      font-size: 1.125rem !important;
  `}
    &::placeholder {
      font-size: 0.9375rem;
      font-weight: 300;
      color: ${p => p.theme.colors.graphite};
      opacity: 0.9;
      ${media.md`
      font-size: 1.125rem;
  `}
    }
  }

  ${media.md`
  padding: 1.75rem;
  `}
`

export const LogoWrapper = styled.div`
  margin-bottom: 1.375rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
`

export const Wrapper = styled.div`
  background-color: ${p => p.theme.colors.gold};
  padding: 50px 4.25rem;
  margin: 0 auto;
  ${media.lg`
    padding: 76px 165px 143px;
  `}
`

export const WrapperSpin = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const SignUpWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  p,
  a {
    font-family: Inter;
    font-size: 0.875rem;
    ${media.md`
      font-size: 1rem;
  `}
  }

  p {
    margin-right: 0.25rem;
    font-weight: 400;
  }

  a {
    font-weight: bold;
  }
`

export const Buttons = styled.div`
  a,
  button {
    padding: 18px;
    width: 100%;
    display: block;
    margin-bottom: 12px;
    text-align: center;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 24px;
  }
  button {
    background-color: ${p => p.theme.colors.white};
    color: ${p => p.theme.colors.fadedBlack};
  }
  a {
    color: ${p => p.theme.colors.darkenGold};
  }
`

export const FormWrapper = styled.div`
  width: 100%;

  a,
  p,
  span,
  h1 {
    color: ${p => p.theme.colors.white};
  }

  a:hover {
    transition: all 0.2s ease-out;
  }

  ${media.md`
    max-width: 35.25rem;
    margin: 0 auto 1.75rem;
  `}
  ${media.lg`
    max-width: 660px;
  `}
`
