import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { EntityType } from 'redux/investment/types'
import { selectCurrentStep } from 'redux/investment/selectors'
import { useInvestNowContext } from 'src/pages/investment/InvestNowPage/context'
import Button, { ButtonVariant } from 'src/components/UI/Button'

import { ControlsWrapper, Dot, DotsWrapper } from './styles'

const Controls: FC<{ onClick: () => void; disabled?: boolean }> = ({
  onClick,
  disabled
}) => {
  const { selectedEntity } = useInvestNowContext()
  const currentStep = useSelector(selectCurrentStep)

  const count = selectedEntity === EntityType.INDIVIDUAL ? 5 : 8

  const steps: number[] = [...Array(count)].reduce(
    (acc, _, idx) => [...acc, idx + 1],
    []
  )

  return (
    <ControlsWrapper>
      <DotsWrapper>
        {steps.map(step => {
          return <Dot key={step} active={step === currentStep} />
        })}
      </DotsWrapper>
      <Button
        variant={ButtonVariant.Default}
        onClick={onClick}
        disabled={disabled}
      >
        next
      </Button>
    </ControlsWrapper>
  )
}

export default Controls
