import React, { FC, useEffect, useState } from 'react'
import { routePath } from 'routes'
import {
  RouteComponentProps,
  useHistory,
  withRouter,
  useLocation,
  Redirect
} from 'react-router-dom'
import { ILoginPayload } from 'redux/auth/types'
import { IErrors } from 'redux/commonTypes'
import { EMAILS } from 'utils/constants'
import TextInput from 'src/components/UI/TextInput'
import Button, { ButtonVariant } from 'src/components/UI/Button'
import Link from 'src/components/UI/Link'
import ErrorLabel from 'components/UI/ErrorLabel'
import {
  isValidEmail,
  MIN_PASSWORD_LENGTH,
  parseBetablockSearchParams,
  useDevice
} from 'utils/helpers'

import {
  ErrorWrapper,
  ForgotLink,
  SignUpLink,
  SuspendedError,
  Wrapper
} from './styles'
import * as queryString from 'querystring'
import { useSelector } from 'react-redux'
import { selectBetablockParams } from 'redux/auth/selectors'

interface IFormProps extends RouteComponentProps {
  onSubmit: (form: ILoginPayload, params?: any) => {}
  clearErrors: () => void
  serverErrors?: any
  isFetching: boolean
}

const SignInForm: FC<IFormProps> = ({
  onSubmit,
  clearErrors,
  serverErrors,
  isFetching
}) => {
  const userDevice = useDevice()
  const {
    location: { state }
  } = useHistory()

  const location = useLocation()
  const userParams = useSelector(selectBetablockParams)
  const isBetaBlock = location.pathname.includes(routePath.SIGN_IN_BETABLOCK)
  const { clientParams, redirectURI } = parseBetablockSearchParams(
    location,
    isBetaBlock
  )

  const [form, setForm] = useState<ILoginPayload>({
    email: '',
    password: '',
    device_id: userDevice.browserId,
    device_model: userDevice.browser,
    device_os: userDevice.os
  })

  const [errors, setErrors] = useState<IErrors<ILoginPayload>>({} as IErrors<
    ILoginPayload
  >)

  useEffect(() => {
    if (isBetaBlock && Object.keys(userParams).length) {
      const params = {
        ...userParams,
        ...clientParams
      }
      const stringifiedParams = queryString.stringify(params)
      // @ts-ignore
      window.location.replace(redirectURI + '?' + stringifiedParams)
    }
    // eslint-disable-next-line
  }, [userParams])
  useEffect(() => {
    if (serverErrors) {
      clearErrors()
    }
    // eslint-disable-next-line
  }, [errors])

  const isValid = (): boolean => {
    const validationErrors = {} as { email: string; password: string }

    if (!isValidEmail(form.email)) {
      validationErrors.email = 'You must enter valid email'
    }

    if (!form.email) {
      validationErrors.email = 'You must enter an email'
    }

    if (form.password.length < MIN_PASSWORD_LENGTH) {
      validationErrors.password = `The password must be at least ${MIN_PASSWORD_LENGTH} characters long`
    }

    if (!form.password) {
      validationErrors.password = 'You must enter a password'
    }

    setErrors(validationErrors)
    return !Object.keys(validationErrors).length
  }

  const onUpdate = (name: string, value: string) => {
    setForm({
      ...form,
      [name]: value.trim()
    })
    setErrors({
      ...errors,
      [name]: ''
    })
  }

  const handleSubmit = async () => {
    if (isValid()) {
      try {
        if (isBetaBlock) {
          await onSubmit(form, clientParams)
        } else {
          await onSubmit(form)
        }
      } catch (e) {
        console.error('Login fail')
      }
    }
  }

  if (isBetaBlock && !location.search) {
    return <Redirect to={routePath.INVEST_PAGE} />
  }

  const { email, password } = form
  const emptyFields = !email.length && !password.length
  return (
    <>
      <Wrapper>
        <TextInput
          name={'email'}
          errorLabel={errors.email || ''}
          value={email}
          label={'Email'}
          placeholder={'Email'}
          isLabelHidden={!email}
          handleChange={onUpdate}
          wrapperClassName={'form__first-field'}
        />
        <TextInput
          name={'password'}
          type={'password'}
          value={password}
          label={'Password'}
          placeholder={'Password'}
          isLabelHidden={!password}
          errorLabel={errors.password || ''}
          handleChange={onUpdate}
          wrapperClassName={'form__last-field'}
        />
        <ForgotLink
          to={
            isBetaBlock
              ? {
                  pathname: routePath.FORGOT_BETABLOCK,
                  search: location.search
                }
              : { pathname: routePath.FORGOT }
          }
        />

        <ErrorWrapper>
          {serverErrors && (
            <>
              {serverErrors === 'Forbidden' ? (
                <SuspendedError>
                  <p>
                    We’re sorry, your account has been temporarily suspended.{' '}
                    <br />
                    Please contact support for additional questions at{' '}
                    <Link variant={'clear'} to={`mailto:${EMAILS.SUPPORT}`}>
                      {EMAILS.SUPPORT}
                    </Link>
                  </p>
                </SuspendedError>
              ) : (
                <ErrorLabel label={serverErrors} />
              )}
            </>
          )}
        </ErrorWrapper>
        <Button
          variant={ButtonVariant.Default}
          type={'submit'}
          onClick={handleSubmit}
          disabled={emptyFields || isFetching}
          isLoading={isFetching}
        >
          Log In
        </Button>
      </Wrapper>

      <SignUpLink>
        Don’t have an account?{' '}
        <Link
          to={
            isBetaBlock
              ? {
                  pathname: routePath.SIGN_UP_BETABLOCK,
                  search: location.search
                }
              : { pathname: routePath.SIGN_UP, state }
          }
          variant={'clear'}
        >
          Sign Up
        </Link>
      </SignUpLink>
    </>
  )
}

export default withRouter(SignInForm)
