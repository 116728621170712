import React, { useRef } from 'react'
import { Container } from 'styled-bootstrap-grid'
import { EventSection } from 'redux/events/types'
import {
  MainContent,
  SectionWrapper,
  SectionTitle
} from 'containers/invest/InvestDetailPage/styles'
import Slider from 'components/UI/Slider'
import { dotsSettings, gallerySettings } from './settings'
import { GalleryArrowIcon } from 'components/icons'
import { Slide } from '../Gallery/styles'
import { DotsWrapper, Wrapper, configArrowStyles } from './styles'
import { theme } from 'styled/theme'

const PhotoGallery: React.FC<{ data: EventSection | undefined }> = ({
  data
}) => {
  const gallerySlider = useRef<typeof Slider | null>(null)
  const dotsSlider = useRef<typeof Slider | null>(null)

  return (
    <MainContent sectionBackground={theme.colors.navy}>
      <Container className={'content-container'}>
        <SectionWrapper id={'photo-gallery'}>
          <SectionTitle color={theme.colors.white}>Photo Gallery</SectionTitle>
          {/* Dots Pagination Slider */}
          <DotsWrapper>
            <Slider
              className="dots-pagination-slider"
              setRef={dotsSlider}
              asNavFor={gallerySlider.current}
              settings={dotsSettings}
            >
              {data &&
                data.items.map((el, index) => <div key={el.id}>{index}</div>)}
            </Slider>
          </DotsWrapper>
          {/* Photo Gallery Slider */}
          <Wrapper>
            <Slider
              className="photo-gallery-slider"
              setRef={gallerySlider}
              asNavFor={dotsSlider.current}
              settings={gallerySettings}
              customArrow={GalleryArrowIcon}
              arrowStyles={configArrowStyles}
            >
              {data &&
                data.items.map(image => (
                  <Slide src={image.file_url} key={image.id} />
                ))}
            </Slider>
          </Wrapper>
        </SectionWrapper>
      </Container>
    </MainContent>
  )
}

export default PhotoGallery
