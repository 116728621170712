import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectInvestmentErrors } from 'redux/investment/selectors'
import debounce from 'utils/debounce'
import { setErrors } from 'redux/investment/actions'
import { ContactFields, IContact } from 'redux/investment/types'
import { noSpaceRegex, validNameRegex } from 'utils/helpers'
import TextInput from 'src/components/UI/TextInput'

import { DropDownWrapper, Form } from 'src/components/investment/steps/styles'

interface ContactFormProps {
  [key: string]: any
}

const ContactForm: FC<ContactFormProps> = props => {
  const dispatch = useDispatch()
  const errors = useSelector(selectInvestmentErrors)
  const { setSelectedContactForm, selectedContactForm } = props
  const [contactForm, setContactForm] = useState<IContact>({} as IContact)

  useEffect(() => {
    setContactForm(selectedContactForm)
    // eslint-disable-next-line
  }, [selectedContactForm])

  useEffect(() => {
    setToContext(contactForm)
    // eslint-disable-next-line
  }, [contactForm])

  const setToContext = useCallback(debounce(setSelectedContactForm, 500), [])

  const onChange = (name: string, value: string) => {
    let currentValue = value

    if (['first_name', 'last_name'].includes(name)) {
      currentValue = value
        .replace(validNameRegex, '')
        .replace(noSpaceRegex, '')
        .trim()
    }

    if (name === 'email') {
      currentValue = value.replace(noSpaceRegex, '').trim()
    }

    const contactInfo = { ...selectedContactForm, [name]: currentValue }

    setContactForm({ ...contactInfo })

    if (errors[name]) {
      dispatch(setErrors({ ...errors, [name]: '' }))
    }
  }

  const { first_name, last_name, email, phone, phone_code } = contactForm

  return (
    <Form>
      <TextInput
        name={'first_name'}
        value={first_name}
        label={`Legal ${ContactFields.first_name}`}
        handleChange={onChange}
        wrapperClassName={'form__first-field'}
        errorLabel={errors.first_name}
        maxLength={64}
      />
      <TextInput
        name={'last_name'}
        value={last_name}
        label={`Legal ${ContactFields.last_name}`}
        handleChange={onChange}
        errorLabel={errors.last_name}
        maxLength={64}
      />
      <TextInput
        type={'text'}
        name={'email'}
        value={email}
        label={ContactFields.email}
        handleChange={onChange}
        errorLabel={errors.email}
        maxLength={128}
      />
      <DropDownWrapper>
        <TextInput
          name={'phone'}
          type={'phone'}
          value={`${phone_code}${phone}`}
          label={ContactFields.phone}
          handleChange={onChange}
          wrapperClassName={'contact-form__phone form__last-field'}
          errorLabel={errors.phone}
          maskConfig={{
            mask: '999-999-9999',
            maskChar: null
          }}
        />
      </DropDownWrapper>
    </Form>
  )
}

export default React.memo(ContactForm)
