import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import AnalyticsManager from 'services/analyticService'
import { loadMoreEventsByInfluencer } from 'redux/events/operations'
import {
  EventType,
  IEvent,
  IEventsByInfluencer,
  IEventsByInfluencerState
} from 'redux/events/types'
import { selectEventsByInfluencer } from 'redux/events/selectors'

import { Tabs } from 'components/UI/Tabs'
import InfiniteScroll from 'components/common/InfiniteScroll'
import Loader from 'src/components/UI/Loader'
import { configTabs } from './tabs'

import EventInvestCard from 'components/invest/InvestmentCard/InvestmentCardList'
import ExperiencesCard from 'components/experiences/ExperienceCard/ExperiencesCardList'
import NFTsCard from 'components/NFTs/NFTsCard/NFTsCardList'
import { Wrapper } from './styles'

interface Props {
  influencerId: string
}

const EventTabs: FC<Props> = ({ influencerId }) => {
  const dispatch = useDispatch()
  const influencerEvents = useSelector(selectEventsByInfluencer)
  const eventsInv = influencerEvents[
    `${influencerId}|${IEventsByInfluencer.ALL_EVENTS}|${EventType.INVESTMENT}`
  ] || { data: [], isFetching: true }
  const eventsExp = influencerEvents[
    `${influencerId}|${IEventsByInfluencer.ALL_EVENTS}|${EventType.EXPERIENCES}`
  ] || { data: [], isFetching: true }
  const eventsNFT = influencerEvents[
    `${influencerId}|${IEventsByInfluencer.ALL_EVENTS}|${EventType.NFT}`
  ] || { data: [], isFetching: true }

  const { data: investmentList, isFetching: investmentsLoading } = eventsInv
  const { data: experienceList, isFetching: experienceLoading } = eventsExp
  const { data: NFTList, isFetching: NFTLoading } = eventsNFT

  const renderCardList = (events: IEvent[], eventType: EventType = 2) => {
    return (
      <>
        {events.map((event: any) => {
          return (
            <div key={event.event_id}>
              {eventType === 1 && eventsExp.data.length && (
                <ExperiencesCard event={event} showType={true} />
              )}
              {eventType === 2 && investmentList.length && (
                <EventInvestCard event={event} showType={true} />
              )}
              {eventType === 3 && eventsNFT.data.length && (
                <NFTsCard event={event} showType={true} />
              )}
            </div>
          )
        })}
      </>
    )
  }

  const renderTabContent = (
    events: IEventsByInfluencerState,
    status: IEventsByInfluencer,
    type: EventType
  ) => {
    const { isFetching, data } = events

    const onLoadMore = () => {
      dispatch(loadMoreEventsByInfluencer(Number(influencerId), status, type))
    }

    return (
      <>
        <InfiniteScroll onLoadMore={onLoadMore}>
          {renderCardList(data, type)}
        </InfiniteScroll>

        {isFetching && <Loader />}
      </>
    )
  }

  const onClickEventsTab = (tab: IEventsByInfluencer) => {
    AnalyticsManager.trackLiveHistoryEvents(tab, Number(influencerId))
  }

  const renderTab = (
    eventData: IEventsByInfluencerState,
    eventType: EventType
  ) => (
    <div key={eventType}>
      {renderTabContent(eventData, IEventsByInfluencer.ALL_EVENTS, eventType)}
    </div>
  )

  const activeTab = () => {
    if (investmentList.length) {
      return String(EventType.INVESTMENT)
    }
    if (
      (experienceList.length && !investmentList.length) ||
      (experienceList.length && !investmentList.length && !NFTList.length)
    ) {
      return String(EventType.EXPERIENCES)
    }
    if (NFTList.length && !investmentList.length && !experienceList.length) {
      return String(EventType.NFT)
    }
  }

  const tabs = configTabs(eventsInv, eventsExp, eventsNFT)
  const initialInvestLoad = investmentsLoading && !investmentList.length
  const initialNFTLoad = NFTLoading && !NFTList.length
  const initialExperienceLoad = experienceLoading && !experienceList.length
  return (
    <Wrapper>
      {!initialInvestLoad && !initialNFTLoad && !initialExperienceLoad ? (
        <Tabs tabs={tabs} activeTab={activeTab()} onClickTab={onClickEventsTab}>
          {renderTab(eventsInv, EventType.INVESTMENT)}
          {renderTab(eventsExp, EventType.EXPERIENCES)}
          {renderTab(eventsNFT, EventType.NFT)}
        </Tabs>
      ) : (
        <Loader />
      )}
    </Wrapper>
  )
}

export default EventTabs
