import React from 'react'

const LetterIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="16"
    viewBox="0 0 20 15"
  >
    <path
      fill="#ecc576"
      d="M16.193 11.79a.32.32 0 01-.22-.086l-3.711-3.46a.319.319 0 01-.015-.452.322.322 0 01.456-.014l3.711 3.46c.13.12.136.323.014.452a.323.323 0 01-.235.1z"
    />
    <path
      fill="#ecc576"
      d="M12.262 8.244l3.71 3.46a.323.323 0 00.456-.014.319.319 0 00-.014-.453l-3.71-3.46a.323.323 0 00-.456.014.319.319 0 00.014.453zM3.803 11.79a.324.324 0 01-.236-.101.319.319 0 01.015-.453l3.712-3.46a.324.324 0 01.456.015.319.319 0 01-.015.452l-3.712 3.46a.32.32 0 01-.22.087z"
    />
    <path
      fill="#ecc576"
      d="M6.862 8.175a.677.677 0 00.436.472L4.46 11.29a.678.678 0 00-.165-.296l-.726.687.725-.688a.674.674 0 00-.271-.174zm.872.065l-.001.002-.437-.472z"
    />
    <path
      fill="#ecc576"
      d="M1.612 15A1.61 1.61 0 010 13.398V2.472A1.61 1.61 0 011.612.87h16.776A1.61 1.61 0 0120 2.472v10.926A1.61 1.61 0 0118.388 15zm0-13.49a.966.966 0 00-.967.962v10.926c0 .53.434.961.967.961h16.776a.966.966 0 00.967-.961V2.472a.966.966 0 00-.967-.961z"
    />
    <g>
      <path
        fill="#ecc576"
        d="M1.612 15A1.61 1.61 0 010 13.398V2.472A1.61 1.61 0 011.612.87h16.776A1.61 1.61 0 0120 2.472v10.926A1.61 1.61 0 0118.388 15zm-.967-1.602c0 .53.434.961.967.961h16.776a.966.966 0 00.967-.961V2.472a.966.966 0 00-.967-.961H1.612a.966.966 0 00-.967.961z"
      />
    </g>
    <g>
      <path
        fill="#ecc576"
        d="M9.995 9.545a1.81 1.81 0 01-1.184-.425L.531 1.942a.32.32 0 01-.032-.453.326.326 0 01.455-.03l8.281 7.177c.412.357 1.109.357 1.52 0l8.27-7.166a.324.324 0 01.456.03.32.32 0 01-.031.453L11.18 9.12a1.809 1.809 0 01-1.185.425z"
      />
    </g>
    <g>
      <path
        fill="#ecc576"
        d="M.53 1.941l8.281 7.18.003.001c.326.281.754.422 1.181.422.428 0 .855-.14 1.181-.422l.003-.003 8.268-7.163.003-.003a.32.32 0 00.03-.453.324.324 0 00-.451-.033h-.001l-.003.003-8.267 7.163-.003.003a1.162 1.162 0 01-.76.268c-.277 0-.554-.09-.76-.268l-.002-.002L.954 1.458l-.002-.002a.327.327 0 00-.453.033.32.32 0 00.032.452z"
      />
    </g>
  </svg>
)

export default LetterIcon
