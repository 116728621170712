import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import TextInput from 'components/UI/TextInput'

export const SearchBox = styled.div`
  width: 100%;
  min-height: 2.625rem;
  border-radius: 0.375rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.625rem 0 0.875rem;
  padding-left: 0.875rem;
  border: 1px solid #888;
  background-color: #eee;
  transition: border-color 0.15s;

  :focus-within {
    border: 1px solid #888;
  }
`
export const SearchClear = styled.button`
  cursor: pointer;
  outline: 0;
  margin: 0;
  border-style: none;
  border-radius: 50%;
  height: 26px;
  width: 26px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const LightSearchBox = styled(SearchBox)`
  padding: 0.75rem 2rem;
  border-radius: 12px;
  border: none;

  ${media.xs`
    padding: 0.75rem 1.875rem;
  `}

  :focus-within {
    border: none;
  }

  input {
    border: none;
    font-family: Inter;

    :-internal-autofill-selected,
    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px #fff inset !important;
    }
  }

  input::placeholder,
  svg path {
    font-size: 1.125rem;
    font-weight: 300;
    color: #8a8a8a;
    fill: #8a8a8a;
  }

  :hover input::placeholder,
  :hover svg path {
    color: #8a8a8a;
    fill: #8a8a8a;
  }

  div > div:last-child {
    display: none;
  }

  :focus-within input::placeholder {
    color: #6f6f6f;
  }
`

export const SearchBar = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div:first-of-type {
    margin: 0;
    width: 100%;
  }
`

export const SearchIconWrapper = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 1.375rem;
`

// @ts-ignore
export const SearchInput = styled(TextInput)`
  border-style: none;
  padding: 0.25rem;
`

export const SearchClearWrapper = styled.div`
  width: 26px;
  height: 26px;
`
export const DarkSearchBox = styled(SearchBox)`
  padding: 0.25rem 2rem;
  background-color: #313131;
  border: none;

  ${media.xs`
    padding: 0.25rem 1.875rem;
  `}

  :focus-within {
    border: none;
  }

  input {
    background-color: #313131;
    border: none;
    border-radius: unset;
    color: #fff;

    :-internal-autofill-selected,
    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px #313131 inset !important;
    }
  }

  :hover input::placeholder,
  :hover svg path {
    color: #b4b4b4;
    fill: #b4b4b4;
  }

  :focus-within div:last-child {
    display: inherit;
  }

  svg path {
    fill: #6f6f6f;
  }

  div > div:last-child {
    display: none;
  }

  :focus-within input::placeholder,
  :focus-within svg > path {
    color: #6f6f6f;
    fill: inherit;
  }
`
