import { createAsyncAction, action } from 'typesafe-actions'
import { IInfluencer, InfluencerActionTypes } from './types'
import { IStatus, IVideo } from '../commonTypes'

export const followInfluencerAsync = createAsyncAction(
  InfluencerActionTypes.FOLLOW_INFLUENCER_REQUEST,
  InfluencerActionTypes.FOLLOW_INFLUENCER_SUCCESS,
  InfluencerActionTypes.FOLLOW_INFLUENCER_FAIL
)<void, IStatus, Error>()

export const unfollowInfluencerAsync = createAsyncAction(
  InfluencerActionTypes.UNFOLLOW_INFLUENCER_REQUEST,
  InfluencerActionTypes.UNFOLLOW_INFLUENCER_SUCCESS,
  InfluencerActionTypes.UNFOLLOW_INFLUENCER_FAIL
)<void, IStatus, Error>()

export const fetchInfluencerAsync = createAsyncAction(
  InfluencerActionTypes.FETCH_INFLUENCER_REQUEST,
  InfluencerActionTypes.FETCH_INFLUENCER_SUCCESS,
  InfluencerActionTypes.FETCH_INFLUENCER_FAIL
)<void, { data: IInfluencer }, Error>()

export const updateInfluencerAsync = createAsyncAction(
  InfluencerActionTypes.UPDATE_INFLUENCER_REQUEST,
  InfluencerActionTypes.UPDATE_INFLUENCER_SUCCESS,
  InfluencerActionTypes.UPDATE_INFLUENCER_FAIL
)<void, { status: boolean }, Error>()

export const uploadYoutubeVideoAsync = createAsyncAction(
  InfluencerActionTypes.UPLOAD_YOUTUBE_VIDEO_REQUEST,
  InfluencerActionTypes.UPLOAD_YOUTUBE_VIDEO_SUCCESS,
  InfluencerActionTypes.UPLOAD_YOUTUBE_VIDEO_FAIL
)<void, { data: IVideo }, Error>()

export const deleteYoutubeVideoAsync = createAsyncAction(
  InfluencerActionTypes.DELETE_YOUTUBE_VIDEO_REQUEST,
  InfluencerActionTypes.DELETE_YOUTUBE_VIDEO_SUCCESS,
  InfluencerActionTypes.DELETE_YOUTUBE_VIDEO_FAIL
)<void, { video_id: number }, Error>()

export const clearInfluencer = () =>
  action(InfluencerActionTypes.CLEAR_INFLUENCER)

export const clearErrors = () => action(InfluencerActionTypes.CLEAR_ERRORS)
