import React, { Component } from 'react'
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom'
import { Col, Container, Row } from 'styled-bootstrap-grid'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'

import { Statuses } from 'redux/commonTypes'
import { routePath } from 'routes'
import { AppState, Dispatch } from 'store/types'
import AnalyticsManager from 'services/analyticService'
import { isBrandSelector, selectIsAuth } from 'redux/auth/selectors'
import { fetchComments, fetchEvent } from 'redux/events/operations'
import {
  selectCommentList,
  selectEventsDetail,
  selectEventsIsFetching,
  selectEventsIsLoaded
} from 'redux/events/selectors'
import { clearComments } from 'redux/events/actions'
import {
  EventSection,
  EventSectionType,
  EventSubType,
  EventType,
  IEventTier,
  PreferencesType
} from 'redux/events/types'
import {
  addItemToCart,
  decreaseCartItemCount,
  increaseCartItemCount
} from 'redux/checkout/operations'
import { selectCartTiersQuantity } from 'redux/checkout/selectors'

import EventHead from 'components/events/EventHead'
import EventTier from 'components/events/EventTierDonate'
import Spin from 'components/UI/Loader'
import { Conversation, EventHeader } from 'components/invest'

import ComingSoon from 'components/common/ComingSoon'
import { toDashCase } from 'utils/helpers'
import EventVideo from 'components/events/EventVideo'

import {
  BlockWrapper,
  ConversationTitle,
  Description,
  EventLogo,
  EventPhotoWrap,
  ImgEvent,
  MainTierBlock,
  TierSection,
  TierTitle,
  Wrapper,
  WrapperLogo,
  WrapperSpin
} from './styles'
import { SectionWrapper } from '../invest/InvestDetailPage/styles'
import EventDetailDescription from './Description'

class EventPage extends Component<Props> {
  async componentDidMount() {
    const {
      fetchEvent,
      fetchComments,
      event,
      match: {
        params: { id }
      },
      location: { hash }
    } = this.props

    await fetchEvent(Number(id))
    fetchComments(Number(id))

    if (!hash) {
      window.scrollTo({
        top: 0
      })
    }

    if (hash && event) {
      const targetElement = document.querySelector(hash)

      if (targetElement) {
        targetElement.scrollIntoView()
      }
    }
  }

  handleAddToCart = (tier: IEventTier, tierSetId: number, count: number) => {
    const {
      isAuth,
      history,
      addItemToCart,
      event: { event_title }
    } = this.props
    if (!isAuth) {
      return history.push({
        pathname: routePath.SIGN_IN,
        state: { from: history.location.pathname }
      })
    }
    addItemToCart(tier, tierSetId, count, event_title)
  }

  componentWillUnmount() {
    this.props.clearComments()
  }

  trackInfluencerAnalytics = () => {
    const {
      event: { event_influencer, event_id }
    } = this.props
    const [influencer] = event_influencer
    AnalyticsManager.trackInfluencerScreenOpen(
      event_id,
      influencer.influencer_id
    )
  }

  render() {
    const {
      event,
      isBrand,
      eventsLoading,
      eventsLoaded,
      comments,
      tiersCartQuantity
    } = this.props

    if (!eventsLoaded && (eventsLoading || !Object.keys(event).length)) {
      return (
        <WrapperSpin>
          <Spin />
        </WrapperSpin>
      )
    }

    if (
      (!eventsLoading && eventsLoaded && !Object.keys(event).length) ||
      [Statuses.ENDED, Statuses.COMING_SOON].includes(event.status)
    ) {
      return <Redirect to={routePath.EVENTS_PAGE} />
    }

    if (
      (!eventsLoading && eventsLoaded && !Object.keys(event).length) ||
      event.type !== EventType.EXPERIENCES
    ) {
      return <ComingSoon text={'Not found'} />
    }

    const [mainTier] = event.tiers

    const descriptionSections: EventSection[] | undefined =
      event.sections.filter(
        section => section.type === EventSectionType.DESCRIPTION
      ) || []

    const isDonate = event.preferences.some(
      // if PreferencesType is Classical the button for Checkout method 'Buy Now' should be 'Donate Now'
      pref => pref.name === PreferencesType.CLASSICAL
    )

    return (
      <>
        <EventHead event={event} />

        <EventHeader event={event} />

        {event.event_logo && (
          <div style={{ background: '#fff' }}>
            <Wrapper>
              <Row>
                <Col xs={12} md={6} xl={3}>
                  <WrapperLogo>
                    <EventLogo
                      src={event.event_logo}
                      alt={toDashCase(event.event_title) + '-logo'}
                    />
                  </WrapperLogo>
                </Col>
              </Row>
            </Wrapper>
          </div>
        )}

        {event.event_description && (
          <BlockWrapper>
            <Wrapper style={{ paddingBottom: '3vmax' }}>
              <Description
                dangerouslySetInnerHTML={{
                  __html: event.event_description || ''
                }}
              />
            </Wrapper>
          </BlockWrapper>
        )}

        {event.video_url ? (
          <div>
            <EventVideo
              preview={event.event_picture}
              videoUrl={event.video_url}
            />
          </div>
        ) : (
          <EventPhotoWrap>
            <ImgEvent src={event.event_picture} alt={event.event_title} />
          </EventPhotoWrap>
        )}

        {event.tiers.length > 0 && (
          <TierSection>
            <Wrapper>
              <Row>
                <Col>
                  <TierTitle>
                    {event.sub_type === EventSubType.SWEEPSTAKES
                      ? 'Entry Levels'
                      : 'Shop'}
                  </TierTitle>
                </Col>
              </Row>
              <Row>
                {event.tiers.length > 0 &&
                  event.tiers.map(tier => (
                    <Col key={tier.id} xs={12} md={12} lg={6}>
                      <EventTier
                        isDonate={isDonate}
                        tiersCartQuantity={tiersCartQuantity}
                        eventId={event.event_id}
                        tier={tier}
                        subType={event.sub_type}
                        isActivePayment={event.payment_active}
                        isBrand={isBrand}
                        onAddToCart={this.handleAddToCart}
                      />
                    </Col>
                  ))}
              </Row>
            </Wrapper>
          </TierSection>
        )}

        {descriptionSections.length > 0 && (
          <BlockWrapper>
            <Container style={{ paddingBottom: '3vmax' }}>
              <EventDetailDescription
                descriptionSections={descriptionSections}
              />
            </Container>
          </BlockWrapper>
        )}

        {mainTier && (
          <MainTierBlock>
            <EventPhotoWrap>
              <ImgEvent src={mainTier.picture} />
            </EventPhotoWrap>
          </MainTierBlock>
        )}

        <div style={{ padding: '0 4vw' }}>
          <SectionWrapper id={'conversation'} style={{ maxWidth: 'none' }}>
            <ConversationTitle>
              {' '}
              {!this.props.isAuth
                ? 'Join the conversation'
                : event.comments_count
                ? `Comments ${event.comments_count}`
                : 'Comments'}
            </ConversationTitle>
            <Conversation data={comments} />
          </SectionWrapper>
        </div>
      </>
    )
  }
}

interface MatchParams {
  id: string
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & RouteComponentProps<MatchParams>

const mapStateToProps = (state: AppState) => ({
  tiersCartQuantity: selectCartTiersQuantity(state),
  event: selectEventsDetail(state),
  eventsLoading: selectEventsIsFetching(state),
  eventsLoaded: selectEventsIsLoaded(state),
  isBrand: isBrandSelector(state),
  comments: selectCommentList(state),
  isAuth: selectIsAuth(state)
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      fetchEvent,
      fetchComments,
      clearComments,
      increaseCartItemCount,
      decreaseCartItemCount,
      addItemToCart
    },
    dispatch
  )

export default compose<React.ComponentType>(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EventPage)
