import styled from 'styled-components'

export const OptionsBlock = styled.div`
  > div {
    padding: 10px 0;
    border-bottom: 1px solid #3c3c3c;

    label span {
      color: #ffffff;
    }

    &:last-child {
      border-bottom: none;
    }
  }
`
