import React from 'react'

const HeartIconLiked = () => (
  <svg
    width="21"
    height="18"
    viewBox="0 0 21 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.8199 1.57961L9.99919 2.40211L9.17594 1.57886C7.07688 -0.520204 3.67361 -0.520204 1.57455 1.57886C-0.524523 3.67793 -0.524523 7.08119 1.57455 9.18026L9.46991 17.0756C9.76281 17.3685 10.2377 17.3685 10.5306 17.0756L18.432 9.1788C20.5264 7.07277 20.53 3.67906 18.4306 1.57961C16.3277 -0.523282 12.9228 -0.523279 10.8199 1.57961Z"
      fill="#EB78FF"
    />
  </svg>
)

export default HeartIconLiked
