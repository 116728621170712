import { AppState } from 'store'
import { createSelector } from 'reselect'
import { parsePhone } from 'utils/helpers'

export const selectInvestmentState = (state: AppState) => state.investment

export const selectIdInfo = createSelector(
  selectInvestmentState,
  state => {
    return {
      investor_id: state.investor_id,
      entity_id: state.entity_id
    }
  }
)
export const selectInvestmentPayment = (state: AppState) =>
  selectInvestmentState(state).payment

export const selectActiveTierId = (state: AppState) =>
  selectInvestmentState(state).tierId

export const selectInvestmentAmount = (state: AppState) =>
  selectInvestmentState(state).investmentAmount

export const selectTierDetails = (state: AppState) =>
  selectInvestmentState(state).tierDetails

export const selectCurrentStep = (state: AppState) =>
  selectInvestmentState(state).currentStep

export const selectPaymentMethod = (state: AppState) =>
  selectInvestmentPayment(state).selectedMethod

export const selectCardInfo = (state: AppState) =>
  selectInvestmentPayment(state).creditCard

export const selectUSBankInfo = (state: AppState) =>
  selectInvestmentPayment(state).USBank

export const selectContactInfo = (state: AppState) =>
  selectInvestmentState(state).contact

export const selectCompaniesList = (state: AppState) =>
  selectInvestmentState(state).companiesList

export const selectInvestmentPaymentError = (state: AppState) =>
  selectInvestmentState(state).payment_error

export const selectPersonInfo = createSelector(
  selectContactInfo,
  state => {
    const parsedPhone = parsePhone(state.phone)
    // tslint:disable-next-line:variable-name
    const phone_code = parsedPhone ? `+${parsedPhone.countryCallingCode}` : ''
    const phone = parsedPhone
      ? parsedPhone.number.substr(phone_code.length)
      : ''
    const name = (state.name && state.name.split(' ')) || []
    const [firstName, lastName] = name

    return {
      phone,
      phone_code,
      email: state.email,
      first_name: firstName || '',
      last_name: lastName || '',
      id: state.id,
      investor_id: state.investor_id
    }
  }
)

export const selectAddressInfo = (state: AppState) =>
  selectInvestmentState(state).address

export const selectVerificationInfo = (state: AppState) =>
  selectInvestmentState(state).verification

export const selectTrustInfo = (state: AppState) =>
  selectInvestmentState(state).trustInfo

export const selectIraInfo = (state: AppState) =>
  selectInvestmentState(state).iraInfo

export const selectCompanyInfo = (state: AppState) =>
  selectInvestmentState(state).companyInfo

export const selectEntityContactInfo = (state: AppState) =>
  selectInvestmentState(state).entityContactInfo

export const selectEntityAddressInfo = (state: AppState) =>
  selectInvestmentState(state).entityAddressInfo

export const selectEntityContact = createSelector(
  selectEntityContactInfo,
  state => {
    const { phone: p, email, full_name, id } = state

    const parsedPhone = parsePhone(p)
    // tslint:disable-next-line:variable-name
    const phone_code = parsedPhone ? `+${parsedPhone.countryCallingCode}` : ''
    const phone = parsedPhone
      ? parsedPhone.number.substr(phone_code.length)
      : ''
    const name = (full_name && full_name.split(' ')) || []
    const [firstName, lastName] = name

    return {
      phone,
      phone_code,
      email,
      first_name: firstName || '',
      last_name: lastName || '',
      id
    }
  }
)

export const selectInvestmentErrors = (state: AppState) =>
  selectInvestmentState(state).errors

export const selectUserSignature = (state: AppState) =>
  selectInvestmentState(state).userSignature

export const selectViewMode = (state: AppState) =>
  selectInvestmentState(state).viewMode

export const selectEventType = (state: AppState) =>
  selectInvestmentState(state).eventType

export const selectSubscriptionAgreement = (state: AppState) =>
  selectInvestmentState(state).subscriptionAgreement
