import styled from 'styled-components'
import ReactModal from 'react-modal'

export const WrapperModal = styled(ReactModal)<{ id?: string }>`
  outline: none;
  overflow-y: none;
  border-radius: 12px;
  width: 90%;
  max-width: 500px;
  font-family: Inter;
`
