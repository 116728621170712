import React, { FC } from 'react'
import { formatMoney, timeRemaining, showHoursLeft } from 'utils/dateFormats'
import { displayStatus } from 'utils/helpers'
import { Statuses } from 'redux/commonTypes'
import { CheckMarkIcon, ClockIcon } from 'components/icons'

import {
  CheckMarkWrapper,
  DateWrapper,
  FundCard,
  PriceBlock,
  PriceText,
  ProgressLine,
  TotalCollected
} from './styles'

export interface Props {
  total: number
  collected: number
  variant?: 'default' | 'secondary'
  textVariant?: 'default' | 'secondary'
  eventStatus: Statuses
  hoursLeft?: number
}

const showPrice = (total: number, collected: number, variant: string) =>
  variant === 'default' ? (
    <p>
      $ {formatMoney(collected)} / $ {formatMoney(total)}
    </p>
  ) : (
    <>
      <p>$ {formatMoney(collected)}</p>
      <TotalCollected max={total} value={collected}>
        $ {formatMoney(total)}
      </TotalCollected>
    </>
  )

const EventFund: FC<Props> = ({
  total,
  collected,
  variant,
  textVariant = 'default',
  eventStatus,
  hoursLeft
}) => {
  const isLive = eventStatus === Statuses.LIVE
  const showEndDate =
    hoursLeft &&
    (hoursLeft > 24 ? timeRemaining(hoursLeft) : showHoursLeft(hoursLeft))

  return (
    <FundCard variant={variant}>
      {variant === 'secondary' && collected !== 0 && collected >= total && (
        <CheckMarkWrapper>
          <CheckMarkIcon />
        </CheckMarkWrapper>
      )}
      <ProgressLine
        max={total}
        value={collected}
        variant={variant}
        data-testid={'progress-line'}
      />
      <PriceBlock>
        <PriceText variant={variant} textVariant={textVariant}>
          {total === 0 ? (
            <span>TBD</span>
          ) : (
            showPrice(total, collected, textVariant)
          )}
        </PriceText>
        {hoursLeft && (
          <DateWrapper>
            <ClockIcon />
            {isLive ? showEndDate : displayStatus(eventStatus)}
          </DateWrapper>
        )}
      </PriceBlock>
    </FundCard>
  )
}

export default EventFund
