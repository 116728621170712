import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export const PlayButton = styled.button`
  width: 7rem;
  height: 7rem;
  border-radius: 50%;
  border: 0.25rem solid rgba(255, 255, 255, 0.6);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.36);
  transition: all 0.2s ease-in;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: transparent;
  cursor: pointer;
  outline: none;

  :before {
    content: '';
    border-left: 2rem solid rgba(255, 255, 255, 0.6);
    border-top: 1rem solid transparent;
    border-right: 1rem solid transparent;
    border-bottom: 1rem solid transparent;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-25%, -50%);
    transition: all 0.2s ease-in;
  }

  :hover {
    border-color: ${p => p.theme.button.default.hover};

    :before {
      border-left: 2rem solid ${p => p.theme.button.default.hover};
    }
  }

  ${media.xs`
      width: 5rem;
      height: 5rem;
      
      :before, :hover:before {
          border-left-width: 1.5rem;
          border-top-width: 0.75rem;
          border-right-width: 0.75rem;
          border-bottom-width: 0.75rem;
      }
  `}
`

export const VideoPreview = styled.div<{ img: string }>`
  width: 100%;
  height: 100%;
  background: url(${p => p.img}) no-repeat center;
  background-size: cover;
  position: relative;
`

export const PlayerWrapper = styled.div`
  width: 100%;
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${media.sm`
 height: 400px;
 `}
  ${media.lg`
    min-height: 600px;
    height: 65vh;
 `}
   ${media.xl`
    min-height: 700px;
    height: 95vh;
 `}
`

export const Player = styled.video<{ isLoading: boolean }>`
  width: 100%;
  height: 100%;
  display: ${p => (p.isLoading ? 'none' : 'block')};
  outline: none;
`
