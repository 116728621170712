import React from 'react'

const ListIcon = props => (
  <svg
    width={18}
    height={18}
    {...props}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.337.889C0 1.393 0 2.096 0 3.5c0 1.404 0 2.107.337 2.611a2 2 0 00.552.552C1.393 7 2.096 7 3.5 7h11c1.405 0 2.107 0 2.611-.337a2 2 0 00.552-.552C18 5.607 18 4.904 18 3.5c0-1.404 0-2.107-.337-2.611a2 2 0 00-.552-.552C16.607 0 15.904 0 14.5 0h-11C2.096 0 1.393 0 .889.337A2 2 0 00.337.89zm0 11C0 12.393 0 13.096 0 14.5c0 1.405 0 2.107.337 2.611a2 2 0 00.552.552C1.393 18 2.096 18 3.5 18h11c1.405 0 2.107 0 2.611-.337.218-.146.406-.334.552-.552C18 16.607 18 15.904 18 14.5c0-1.405 0-2.107-.337-2.611a1.999 1.999 0 00-.552-.552C16.607 11 15.904 11 14.5 11h-11c-1.404 0-2.107 0-2.611.337a2 2 0 00-.552.552z"
      fill="#C4C4C4"
    />
  </svg>
)

export default ListIcon
