import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Media from 'react-media'
import { selectIsAuth } from 'redux/auth/selectors'
import { ItemsCounter } from 'components/UI/ItemsCounter'
import Button from 'components/UI/Button'
import { TierParams } from './TierParams'
import { IEventTier, IEventTierSets } from 'redux/events/types'
import ImageZoom from './ImageZoom/ImageZoom'
import {
  Card,
  DescText,
  TierInfo,
  TierTitle,
  TitleWrapper,
  WrapperBlock,
  CardBlockContent,
  CardBlockSoldOut,
  CardBlockFooter,
  Actions,
  TierImageWrapper
} from './styles'

interface EventTierSub2Props {
  tier: IEventTier
  tiersCartQuantity: any
  onAddToCart: (tier: IEventTier, tierSetId: number, count: number) => void
  onBuyNow: (tierSetId: number, count: number) => void
  isDonate: boolean
}

export const EventTierSub2: FC<EventTierSub2Props> = ({
  tier,
  onAddToCart,
  tiersCartQuantity,
  onBuyNow,
  isDonate
}): React.ReactElement => {
  const isAuthenticated = useSelector(selectIsAuth)
  const [selectedTierSet, setSelectedTierSet] = useState<IEventTierSets>(
    tier.sets && tier.sets.filter(s => s.quantity > 0)[0]
  )
  const [counter, setCounter] = useState<number>(1)
  useEffect(() => {
    setCounter(1)
  }, [selectedTierSet])

  useEffect(() => {
    if (
      tiersCartQuantity &&
      selectedTierSet &&
      tiersCartQuantity[selectedTierSet.tier_set_id] < counter
    ) {
      setCounter(tiersCartQuantity[selectedTierSet.tier_set_id])
    }
  }, [tiersCartQuantity, counter, selectedTierSet])

  const handleChoose = (newTierSet: IEventTierSets) => {
    setSelectedTierSet({ ...newTierSet })
  }

  const handleAddToCart = () => {
    onAddToCart(tier, selectedTierSet.tier_set_id, counter)
  }
  const handleBuyNow = () => {
    onBuyNow(selectedTierSet.tier_set_id, counter)
  }

  const handleIncrease = () => {
    setCounter(
      counter < selectedTierSet.quantity
        ? counter + 1
        : selectedTierSet.quantity
    )
  }

  const handleDecrease = () => {
    setCounter(counter <= 1 ? counter : counter - 1)
  }

  return (
    <WrapperBlock>
      <Card>
        <Media
          queries={{
            large: '(max-width: 1344px)',
            extra: '(min-width: 1345px)'
          }}
        >
          {matches => (
            <CardBlockContent>
              {matches.extra && (
                <>
                  <TierInfo>
                    <TierTitle>{tier.title}</TierTitle>
                    <DescText>{tier.description}</DescText>
                  </TierInfo>
                  <TierImageWrapper>
                    <ImageZoom
                      src={tier.picture}
                      alt={tier.title}
                      className={'tier__image'}
                    />
                  </TierImageWrapper>
                </>
              )}
              {matches.large && (
                <>
                  <TierInfo>
                    <TitleWrapper>
                      <TierTitle>{tier.title}</TierTitle>
                    </TitleWrapper>
                    <TierImageWrapper>
                      <ImageZoom
                        src={tier.picture}
                        alt={tier.title}
                        className={'tier__image'}
                      />
                    </TierImageWrapper>
                  </TierInfo>
                  <DescText>{tier.description}</DescText>
                </>
              )}
            </CardBlockContent>
          )}
        </Media>
        <TierParams
          sets={tier.sets}
          selectedTierSet={selectedTierSet}
          handleSelect={handleChoose}
        />
        {selectedTierSet && counter > 0 ? (
          <CardBlockFooter footer={true}>
            <ItemsCounter
              variant="light"
              size="medium"
              value={counter}
              onIncrease={handleIncrease}
              onDecrease={handleDecrease}
            />
            <Actions>
              {isAuthenticated && (
                <Button
                  variant="shop-black-outlined"
                  disabled={!selectedTierSet}
                  onClick={handleAddToCart}
                >
                  Add to Cart
                </Button>
              )}
              <Button
                variant="shop-gold-contained"
                disabled={!selectedTierSet}
                onClick={handleBuyNow}
              >
                {isDonate ? 'Donate Now' : 'Buy Now'}
              </Button>
            </Actions>
          </CardBlockFooter>
        ) : (
          <CardBlockSoldOut footer={true} soldOut={counter <= 0}>
            <h4>Sold out</h4>
          </CardBlockSoldOut>
        )}
      </Card>
    </WrapperBlock>
  )
}
