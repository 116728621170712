import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { formatMoney } from 'utils/dateFormats'

import {
  ShoppingCartItem,
  ShoppingCartItemProps
} from 'components/checkout/ShoppingCart/ShoppingCartItem'

import {
  DetailsItem,
  OrderListBody,
  OrderListDetails,
  OrderListWrapper,
  Value
} from './styles'

import { ICartItem } from 'redux/checkout/types'

import Spin from 'components/UI/Loader'
import { selectIsAuth } from 'redux/auth/selectors'

export interface ExtendedICartItem extends ICartItem {
  fee?: number
  tax_amount?: number
  shipping_charge?: number
}

interface OrderListProps {
  order: ICartItem[] | ExtendedICartItem[]
  isFetching: boolean
  withoutCounter: boolean
  fromShopping: boolean
  isPageLoaded: boolean
  cartInfo?: any
  isBlocked?: boolean
}
export const OrderList: React.FC<OrderListProps> = ({
  order,
  isFetching,
  withoutCounter = false,
  fromShopping,
  isPageLoaded,
  cartInfo,
  isBlocked = false
}): React.ReactElement => {
  const isAuthenticated = useSelector(selectIsAuth)

  const { taxes, shipping, subtotal, total } = useMemo(() => {
    if (!withoutCounter && !isBlocked) {
      const { shipping_charge, subtotal, tax_amount, total } = cartInfo
      return {
        taxes: tax_amount,
        shipping: shipping_charge,
        subtotal,
        total
      }
    }

    let taxes = 0
    let shipping = 0
    let subtotal = 0
    let total = 0
    if (fromShopping) {
      for (const product of order) {
        if (product.sets) {
          for (const set of product.sets) {
            subtotal += product.tier_price * set.quantity
            total = taxes + shipping + subtotal
          }
        }
      }
    } else {
      for (const product of order) {
        const taxAmount = product.tax_amount || 0
        const fee = product.fee || 0
        const shippingTax = product.shipping_charge || 0
        taxes += (taxAmount + fee) * product.quantity
        shipping += shippingTax * product.quantity
        subtotal += product.tier_price * product.quantity
        total = taxes + shipping + subtotal
      }
    }

    return { taxes, shipping, subtotal, total }
  }, [order, fromShopping, withoutCounter, cartInfo, isBlocked])

  const Cart = (props: ShoppingCartItemProps) => (
    <ShoppingCartItem
      withoutCounter={withoutCounter}
      isShopping={fromShopping}
      {...props}
    />
  )

  const orderBuyNow = order.map(item => item.tier_price * item.quantity)

  return (
    <OrderListWrapper
      className={`sticky ${isAuthenticated ? 'authenticated' : ''}`}
    >
      <OrderListBody>
        {isFetching && !isPageLoaded ? (
          <Spin />
        ) : (
          <>
            {order.map((item, idx) => {
              if (item.sets && !withoutCounter) {
                return item.sets.map(set => (
                  <Cart
                    key={`${set.tier_set_id}/${idx}`}
                    set={set}
                    item={item}
                    count={!fromShopping ? item.quantity : set.quantity}
                  />
                ))
              } else {
                return (
                  <Cart
                    key={`${item.id}/${idx}`}
                    item={item}
                    maxItemCount={1}
                    count={item.quantity}
                  />
                )
              }
            })}
          </>
        )}
      </OrderListBody>
      {!withoutCounter && (
        <OrderListDetails>
          <DetailsItem>
            <h4>Subtotal:</h4>
            <Value>${formatMoney(subtotal || Number(orderBuyNow), 2)}</Value>
          </DetailsItem>
          <DetailsItem>
            <h4>Shipping:</h4>
            <Value>${formatMoney(shipping, 2)}</Value>
          </DetailsItem>
          <DetailsItem>
            <h4>Taxes:</h4>
            <Value>${formatMoney(taxes, 2)}</Value>
          </DetailsItem>
        </OrderListDetails>
      )}
      <OrderListDetails total={true}>
        <DetailsItem>
          <h3>Total:</h3>
          <Value bold={true}>
            ${formatMoney(total || Number(orderBuyNow), 2)}
          </Value>
        </DetailsItem>
      </OrderListDetails>
    </OrderListWrapper>
  )
}
