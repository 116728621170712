import React, { FC } from 'react'
import { IPaymentMethods, paymentMethods } from 'redux/investment/types'
import { ButtonVariant } from 'src/components/UI/Button'

import { List, ListItem, Option } from './styles'

interface Props {
  list: string[]
  selectedMethod: string
  onSelect: (method: IPaymentMethods) => void
  centred?: boolean
}

const PaymentSelector: FC<Props> = props => {
  const { list, onSelect, selectedMethod, centred } = props

  return (
    <List count={list.length}>
      {list.map((method: typeof paymentMethods[number]) => {
        const selectMethod = () => {
          onSelect(method)
        }

        return (
          <ListItem key={method}>
            <Option
              centred={centred}
              variant={ButtonVariant.Icon}
              onClick={selectMethod}
              isActive={method === selectedMethod}
            >
              {method}
            </Option>
          </ListItem>
        )
      })}
    </List>
  )
}

export default PaymentSelector
