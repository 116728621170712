import { ActionType } from 'typesafe-actions'
import * as actions from './actions'
import { IPagination, IVideo, Statuses, MetaAndLinks } from '../commonTypes'
import { IUser } from '../auth/types'
import {
  CommentsAction,
  CommentsActionTypes,
  ICommentData,
  ReplyList
} from './comments/types'

export enum IEventsByInfluencer {
  LIVE_EVENTS,
  HISTORY_EVENTS,
  ALL_EVENTS
}

export enum EventCategories {
  ALL = 0,
  MUSIC = 1,
  SPORT = 2,
  E_SPORTS = 3,
  TV_FILMS = 4
}

export enum EventCategoryFolder {
  RECOMMENDED = 'recommended',
  TRENDED = 'trended'
}

// @ts-ignore
export enum EventFilterBy {
  POPULAR = 1,
  RECENT = 2,
  ALPHABET = 3
}

export enum EventType {
  EXPERIENCES = 1,
  INVESTMENT = 2,
  NFT = 3
}

export enum EventSubType {
  SWEEPSTAKES = 1,
  POS = 2
}

export type EventsAction =
  | ActionType<typeof actions.fetchEventsAsync>
  | ActionType<typeof actions.fetchEventsMenuAsync>
  | ActionType<typeof actions.fetchEventsHomeAsync>
  | ActionType<typeof actions.fetchDetailEventsAsync>
  | ActionType<typeof actions.clearDetailEvent>
  | ActionType<typeof actions.fetchEventsByInfluencerAsync>
  | ActionType<typeof actions.changeAllEventsHomePage>
  | ActionType<typeof actions.clearEventsByInfluencer>
  | ActionType<typeof actions.fetchPurchasedAsync>
  | ActionType<typeof actions.searchPurchasedAsync>
  | ActionType<typeof actions.searchInfluencersAsync>
  | ActionType<typeof actions.changeEventsHomePage>
  | ActionType<typeof actions.likeEventAsync>
  | ActionType<typeof actions.clearEventsHomePage>
  | ActionType<typeof actions.clearPurchasedItems>
  | ActionType<typeof actions.clearSearchedItems>
  | ActionType<typeof actions.clearInfluencerList>
  | ActionType<typeof actions.clearErrors>
  | ActionType<typeof actions.changeInfluencerSearchField>
  | ActionType<typeof actions.clearInfluencerSearchField>
  | CommentsAction

export interface IEventsByInfluencerState extends IInfluencerEventList {
  isFetching: boolean
}

export interface EventsState {
  isFetching: boolean
  isFetchingTier: boolean
  isLoaded: boolean
  homepage: {
    [EventCategories.ALL]: IEventRecommendData
    [EventCategories.MUSIC]: IEventRecommendData
    [EventCategories.SPORT]: IEventRecommendData
    [EventCategories.E_SPORTS]: IEventRecommendData
    [EventCategories.TV_FILMS]: IEventRecommendData
  }
  list: IEventsData
  investments: IEventsDataOffer
  experiences: IEventsDataOffer
  nft: IEventsDataOffer
  eventsCount: IEventsTab[]
  detail: IEventDetail
  tierDetail: { data: ITier }
  eventsByInfluencer: {
    [key: string]: IEventsByInfluencerState
  }
  purchased: IPurchased
  searchedItems: IPurchased
  influencers: IInfluencerListExtended
  isFetchingInfluencers: boolean
  comments: ICommentData
  replies: ReplyList
  isFetchingReplies: boolean
  errors: any
}

export enum EventActionTypes {
  FETCH_EVENTS_REQUEST = '@@events/FETCH_EVENTS_REQUEST',
  FETCH_EVENTS_SUCCESS = '@@events/FETCH_EVENTS_SUCCESS',
  FETCH_EVENTS_FAIL = '@@events/FETCH_EVENTS_FAIL',
  FETCH_EVENTS_MENU_REQUEST = '@@events/FETCH_EVENTS_MENU_REQUEST',
  FETCH_EVENTS_MENU_SUCCESS = '@@events/FETCH_EVENTS_MENU_SUCCESS',
  FETCH_EVENTS_MENU_FAIL = '@@events/FETCH_EVENTS_MENU_FAIL',
  FETCH_EVENTS_BY_INFLUENCER_REQUEST = '@@events/FETCH_EVENTS_BY_INFLUENCER_REQUEST',
  FETCH_EVENTS_BY_INFLUENCER_SUCCESS = '@@events/FETCH_EVENTS_BY_INFLUENCER_SUCCESS',
  FETCH_EVENTS_BY_INFLUENCER_FAIL = '@@events/FETCH_EVENTS_BY_INFLUENCER_FAIL',
  CLEAR_EVENTS_BY_INFLUENCER = '@@events/CLEAR_EVENTS_BY_INFLUENCER',
  FETCH_EVENTS_HOME_REQUEST = '@@events/FETCH_EVENTS_HOME_REQUEST',
  FETCH_EVENTS_HOME_SUCCESS = '@@events/FETCH_EVENTS_HOME_SUCCESS',
  FETCH_EVENTS_HOME_FAIL = '@@events/FETCH_EVENTS_HOME_FAIL',
  CHANGE_PAGE_EVENTS_HOME = '@@events/CHANGE_PAGE_EVENTS_HOME',
  CHANGE_PAGE_ALL_EVENTS_HOME = '@@events/CHANGE_PAGE_ALL_EVENTS_HOME',
  CLEAR_EVENTS_HOME_PAGE = '@@events/CLEAR_EVENTS_HOME_PAGE',
  FETCH_DETAIL_EVENT_REQUEST = '@@events/FETCH_DETAIL_EVENT_REQUEST',
  FETCH_DETAIL_EVENT_SUCCESS = '@@events/FETCH_DETAIL_EVENT_SUCCESS',
  FETCH_DETAIL_EVENT_FAIL = '@@events/FETCH_DETAIL_EVENT_FAIL',
  CLEAR_DETAIL_EVENT = '@@events/CLEAR_DETAIL_EVENT',
  FETCH_PURCHASED_REQUEST = '@@events/FETCH_PURCHASED_REQUEST',
  FETCH_PURCHASED_SUCCESS = '@@events/FETCH_PURCHASED_SUCCESS',
  FETCH_PURCHASED_FAIL = '@@events/FETCH_PURCHASED_FAIL',
  CLEAR_PURCHASED = '@@events/CLEAR_PURCHASED',
  SEARCH_PURCHASED_REQUEST = '@@events/SEARCH_PURCHASED_REQUEST',
  SEARCH_PURCHASED_SUCCESS = '@@events/SEARCH_PURCHASED_SUCCESS',
  SEARCH_PURCHASED_FAIL = '@@events/SEARCH_PURCHASED_FAIL',
  CLEAR_SEARCHED = '@@events/CLEAR_SEARCHED',
  SEARCH_INFLUENCERS_REQUEST = '@@events/SEARCH_INFLUENCERS_REQUEST',
  SEARCH_INFLUENCERS_SUCCESS = '@@events/SEARCH_INFLUENCERS_SUCCESS',
  SEARCH_INFLUENCERS_FAIL = '@@events/SEARCH_INFLUENCERS_FAIL',
  CLEAR_INFLUENCER_LIST = '@@events/CLEAR_INFLUENCER_LIST',
  LIKE_EVENT_REQUEST = '@@events/LIKE_EVENT_REQUEST',
  LIKE_EVENT_SUCCESS = '@@events/LIKE_EVENT_SUCCESS',
  LIKE_EVENT_FAIL = '@@events/LIKE_EVENT_FAIL',
  CLEAR_ERRORS = '@@events/CLEAR_ERRORS',
  CHANGE_INFLUENCER_SEARCH_FIELD = '@@events/CHANGE_INFLUENCER_SEARCH_FIELD',
  CLEAR_INFLUENCER_SEARCH_FIELD = '@@events/CLEAR_INFLUENCER_SEARCH_FIELD'
}

export const EventsActionTypes = { ...EventActionTypes, ...CommentsActionTypes }

export interface IEventRecommendActionData {
  category?: EventCategories
  data: {
    recommended: IEventsData
    trended: IEventsData
  }
}

export interface IEventRecommendData {
  isFetching: boolean
  recommended: IEventsData
  trended: IEventsData
}

export interface IEventsData extends MetaAndLinks {
  data: IEventDetail[]
  tabs: Array<{ [k: string]: string }>
}

export interface IEventsTab {
  type: EventType
  count: number
}

export interface IEventsDataOffer extends IEventsData {
  isFetching: boolean
}

export interface IEventsDataWithType extends IEventsData {
  type: EventType
}

export interface IInfluencer {
  influencer_id: number
  influencer_link: string
  influencer_name: string
  influencer_profile_photo: string
}

export interface IInfluencerList {
  data: IUser[]
  pagination: IPagination
}

export interface IInfluencerListExtended extends IInfluencerList {
  influencersSearchField: string
}

export interface IInfluencerEventList {
  data: IEvent[]
  pagination: IPagination
}

export interface IEvent {
  event_description: string
  event_end_at: string
  event_end_in_hours: number
  event_finished: boolean
  event_id: number
  event_influencer: IInfluencer[]
  event_logo: string
  event_picture: string
  event_supporters: number
  event_title: string
  payment_active: boolean
  tiers_collected: number
  tiers_total: number
  url: string
  status: Statuses
  type: EventType
  investmentTerms: InvestmentTerms | null
}

export interface IPurchased {
  data: ITier[]
  links: { [k: string]: string }
  meta: { [k: string]: string }
}

export interface IEventTier {
  id: number
  title: string
  picture: string
  description: string
  value: number
  quantity: number
  left: number
  status: Statuses
  sets: IEventTierSets[]
}
export interface IEventTierSets {
  tier_set_id: number
  color: string | null
  color_name: string | null
  size: string | null
  quantity: number
  left: number
}

export interface ITier extends IEventTier {
  required_shipping_address: boolean
  event_title: string
  influencer_id: number
  influencer_picture: string | undefined
  influencer_nickname: string
  custom_fields: []
  purchased: number
  tax_rate: number
  tax_amount: number
  fee: number
  shipping_charge: number
  event_id: number
  event_type: EventType
  investments: Investments[]
}
export interface ITierDetails extends ITier {
  influencer: string
  date: string
  amount: number
  amount_without_taxes: number
  payment_system: string
  shipping_fee: number
  user_name: string
  items: TierDetails[]
  shipping_address: ShippingAddress
  payment_status: string
}

export interface ShippingAddress extends ITier {
  city: string
  country: string
  place_id: string
  room: string | null
  state: string
  street: string
  zip: string
}
export interface TierDetails extends ITier {
  tier_name: string
  tier_image: string
  tier_price: number
  tier_influencer: string
  sets: IEventTierSets[]
}
export interface Investments {
  amount: string
  created_at: string
  external_investment_id: string
  internal_investment_id: number
  share_count: string
  share_price: string
  status: string
  updated_at: string
  user_id: number
}

export interface IEventDetail extends IEvent {
  superTiers: null
  tiers: IEventTier[]
  videos: IVideo[]
  sections: EventSection[]
  faqs: FAQ[]
  data_room: DataRoomItem[]
  preferences: IPreferences[]
  investmentTerms: InvestmentTerms
  likes_count: number
  sub_type: EventSubType
  is_liked: boolean
  comments_count: number
  video_url: null | string
  collection_url: string
}

export interface InvestmentTerms {
  [k: string]: any

  display_settings: [string]
}

export enum ITLabels {
  TOTAL = 'total_offering',
  PRICE_PER_SHARE = 'price_per_share',
  MIN_INVESTMENT = 'min_investment',
  MIN_RAISE = 'minimum_raise',
  VALUATION = 'valuation',
  DISCOUNT = 'discount',
  SECURITY_TYPE = 'security_type',
  AMOUNT_RAISED = 'amount_raised',
  FUNDED = 'founded',
  INVESTORS = 'investors',
  INTEREST_RATE = 'interest_rate'
}

export const InvestmentTermsItems: {
  [k: string]: { title: string; tooltip: string }
} = {
  [ITLabels.AMOUNT_RAISED]: {
    title: 'Amount Raised',
    tooltip: 'The total amount raised to date for the offering.'
  },
  [ITLabels.PRICE_PER_SHARE]: {
    title: 'Price Per Share',
    tooltip:
      'The price for each share sold in the offering. For example, if the issuer sets each share price at $1 and a $100 investment is made, the investor will receive 100 shares.'
  },
  [ITLabels.TOTAL]: {
    title: 'Total Offering',
    tooltip: 'The total available fundraise amount for the offering.'
  },
  [ITLabels.MIN_INVESTMENT]: {
    title: 'Min. Investment',
    tooltip:
      'The minimum investment required by the issuer to participate in the offering.'
  },
  [ITLabels.FUNDED]: {
    title: 'Funded',
    tooltip: 'The total percent funded to date for the offering.'
  },
  [ITLabels.VALUATION]: {
    title: 'Valuation',
    tooltip:
      "The market value of the company set by the issuer. This is usually derived by taking into account the company's management team, composition of capital structure, prospect of future earnings and market value of all assets."
  },
  [ITLabels.INVESTORS]: {
    title: 'Investors',
    tooltip: 'The total number of investors to date for the offering.'
  },
  [ITLabels.DISCOUNT]: {
    title: 'Discount',
    tooltip:
      'The discount rate for which an investor can convert the principal amount of their loan (plus any accrued interest) into shares of stock at a discount to the purchase. For example, a convertible note with a 20% discount allows convertible debt holders to convert to stock at 80%, or “20% off” the share price paid in the subsequent funding round.'
  },
  [ITLabels.SECURITY_TYPE]: {
    title: 'Security Type',
    tooltip:
      'The method of security offered to investors. This can include debt (assets that require a fixed payment to the investor), equity (direct shares in a company for a set price), convertible note (hybrid debt instrument that the investor can convert into shares of common stock or receive cash plus interest) or SAFE note (convertible security that allows the investor to buy shares at an agreed upon price).'
  },
  [ITLabels.MIN_RAISE]: {
    title: 'Minimum Raise',
    tooltip:
      'The minimum amount required to be raised to break escrow. Once the minimum raise is met, the issuer is able to withdraw funds and use it for business purposes.'
  },
  [ITLabels.INTEREST_RATE]: {
    title: 'Interest Rate',
    tooltip:
      "The interest rate of a convertible note or debt instrument indicates how much interest accrues to the investor prior to the note's conversion to equity or maturity of the debt."
  }
}

export interface FAQ {
  question: string
  answer: string
}

export interface EventSection {
  id: number
  name: string
  type: EventSectionType
  items: EventSectionItems[]
}

export enum EventSectionType {
  DESCRIPTION = 1,
  GALLERY = 2,
  TEAM = 3
}

export interface EventSectionItems {
  title?: string
  description?: string
  id?: number
  file_url?: string
  picture?: string
  direction?: 'left' | 'right' | 'above'
}

export interface DataRoomItem {
  id: number
  type: number
  icon: string
  name: string
  value: string
}

export const DataRoomLabels: { [k: string]: string } = {
  INVESTOR: 'investor deck',
  WEBSITE: 'website',
  OFFER: 'offering circular'
}

export interface IPreferences {
  id: number
  key: string
  name: PreferencesType
  type: 1
}

export enum PreferencesType {
  ALTERNATIVE = 'Alternative',
  CLASSICAL = 'Classical',
  COUNTRY = 'Country',
  ELECTRONIC = 'Electronic',
  HIP_HOP = 'Hip Hop',
  HOCKEY = 'Hockey',
  JAZZ = 'Jazz',
  R_AND_B = 'R&B',
  RAP = 'Rap',
  ROCK = 'Rock'
}

export enum SecurityType {
  DEBT = 1,
  EQUITY = 2,
  CONVERTIBLE = 3,
  SAFE = 4
}

export const SecurityLabels: { [k: string]: string } = {
  [SecurityType.DEBT]: 'Debt',
  [SecurityType.EQUITY]: 'Equity',
  [SecurityType.CONVERTIBLE]: 'Convertible Note',
  [SecurityType.SAFE]: 'SAFE Note'
}

export interface InvestmentEvent extends IEventTier {
  influencer: { influencer_data: IInfluencer }
}

export interface InvestmentTier extends ITier {
  event: InvestmentEvent
}
