import styled from 'styled-components'

export const ImageBlock = styled.div`
  position: relative;
  max-width: 23.125rem;
  height: 23.125rem;
  border-radius: 0.4rem;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  margin: 0 auto 4rem;

  img {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0.4rem;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`
export const TierTitle = styled.p`
  font-size: 1.125rem;
  font-weight: 500;
`
export const TierPrice = styled.p``
export const InfoBlock = styled.div`
  min-height: 6.25rem;
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  background: ${p => p.theme.colors.black};
  color: #fff;
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
`
