import React, { ReactNode } from 'react'
import { IEventDetail } from 'redux/events/types'
import { routePath } from 'routes'
import DaysLeft from '../DaysLeft'
import SocialButtons from 'src/components/UI/SocialButtons'
import {
  CardBottom,
  CardInfo,
  DaysLeftContainer,
  Description,
  InfoWrapper,
  InvestNowWrapper,
  Name,
  SocialButtonsWrapper,
  SocialWrapper,
  Title,
  TitleWrapper,
  Wrapper
} from '../styles'
import { LearnMore } from 'components/NFTs/NFTsCard/NFTsCardList/styles'

interface Props {
  event: IEventDetail
  showHours?: boolean
  isPlaying: boolean
  children: ReactNode
}

const NFTsHeaderContent: React.FC<Props> = ({
  event,
  showHours,
  isPlaying,
  children
}): React.ReactElement => {
  const {
    event_description,
    event_end_in_hours,
    event_influencer,
    event_end_at,
    event_title,
    collection_url,
    status
  } = event
  const hideContent = isPlaying ? 'none' : 'flex'
  return (
    <Wrapper style={{ display: hideContent }}>
      <DaysLeftContainer>
        <DaysLeft
          endDate={event_end_at}
          eventStatus={status}
          hoursLeft={event_end_in_hours}
          showHours={showHours}
        />
      </DaysLeftContainer>
      <InfoWrapper>
        <CardInfo>
          <TitleWrapper>
            <Name>{event_influencer[0].influencer_name}</Name>
            <Title>{event_title}</Title>
            <Description
              dangerouslySetInnerHTML={{
                __html: event_description
              }}
            />
          </TitleWrapper>
          {children}
        </CardInfo>
        <CardBottom>
          <SocialWrapper>
            <InvestNowWrapper>
              <LearnMore to={collection_url}>Learn More</LearnMore>
            </InvestNowWrapper>
            <SocialButtonsWrapper>
              <SocialButtons
                isShareHidden={true}
                path={routePath.NFT_DETAIL_PAGE}
                event={event}
              />
            </SocialButtonsWrapper>
          </SocialWrapper>
        </CardBottom>
      </InfoWrapper>
    </Wrapper>
  )
}

export default NFTsHeaderContent
