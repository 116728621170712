import React, { FC } from 'react'
import { theme } from 'styled/theme'
import { TierDetails } from 'redux/events/types'
import { formatMoney } from 'utils/dateFormats'
import { ImgEvent } from 'components/checkout/ShoppingCart/ShoppingCartItem/styles'
import {
  EventPhotoWrap,
  InfoTitle,
  Price,
  PriceBlock,
  Set,
  SetsInfo,
  SetTitle,
  TitleAndPrice,
  Wrapper
} from 'components/profile/dashboard/styles'

const SetList: FC<{
  tier: TierDetails
}> = ({ tier }) => {
  const {
    sets,
    id: tierId,
    tier_image,
    tier_influencer,
    tier_price,
    tier_name
  } = tier

  return (
    <>
      {sets.map(set => {
        const { tier_set_id, quantity, size, color_name } = set

        return (
          <Set key={`${tier_set_id}/${tierId}`}>
            <Wrapper>
              <EventPhotoWrap>
                <ImgEvent src={tier_image} alt={tier_name} />
              </EventPhotoWrap>
              <TitleAndPrice>
                <SetTitle>
                  <InfoTitle>{tier_influencer}</InfoTitle>
                  <InfoTitle id={'influencer_name'}>
                    {tier_name} ({quantity}x)
                  </InfoTitle>
                  {size && (
                    <SetsInfo>
                      {size}
                      {color_name && `, `}
                    </SetsInfo>
                  )}
                  {color_name && <SetsInfo>{color_name}</SetsInfo>}
                </SetTitle>
                <PriceBlock>
                  <Price style={{ color: theme.colors.grey }}>
                    ${formatMoney(tier_price, 2)} /{' '}
                  </Price>
                  <Price>${formatMoney(tier_price * quantity, 2)}</Price>
                </PriceBlock>
              </TitleAndPrice>
            </Wrapper>
          </Set>
        )
      })}
    </>
  )
}

export default SetList
