import React, { FC } from 'react'
import Slider from 'components/UI/Slider'
import { EventSectionItems } from 'redux/events/types'
import settings from './settings'
import { GalleryArrowIcon } from 'components/icons/index'

import { configArrowStyles, Wrapper, Slide } from './styles'

interface Props {
  data: EventSectionItems[]
}

const Gallery: FC<Props> = ({ data }) => {
  return (
    <Wrapper>
      <Slider
        settings={settings}
        customArrow={GalleryArrowIcon}
        arrowStyles={configArrowStyles}
      >
        {data.map(image => (
          <Slide src={image.file_url} key={image.id} />
        ))}
      </Slider>
    </Wrapper>
  )
}

export default Gallery
