import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export const Wrapper = styled.div`
  .content-container {
    ${media.lg`
      padding: 0 1rem;
    `}

    ${media.xl`
      max-width: none;   
      padding: 0 2.5rem;
    `}
  }
`

export const MainContent = styled.div<{
  sectionBackground?: string
  investorDeck?: boolean
}>`
  background: ${p => p.sectionBackground};
  padding: 0 0 3.125rem;

  > div {
    ${media.md`
      max-width: 960px;
    `}
  }

  .full-container {
    flex: 0 0 90%;
    max-width: 90%;
  }

  .content-container {
    padding-left: 3vw;
    padding-right: 3vw;
    max-width: unset !important;
  }
  &.investor-deck {
    margin-bottom: -145px;
    &__empty {
      height: 90px;
    }
    ${media.md`
        margin-bottom: -325px;
        &__empty {
          height: 325px;
        }
      `}
  }
`

export const SectionWrapper = styled.div<{ id?: string }>`
  margin: 0 auto;
  max-width: 1500px;
  padding-top: 2.5rem;

  ${p => {
    switch (p.id) {
      case 'story': {
        return `
          display: flex;

          div:last-child {
            display: flex;
          }

          div:last-child p {
            flex: 1 1 33.333%;
            margin-left: 25px;
          }

          div:last-child p span {
            color: #000;
          }   
        `
      }

      case 'reasons-to-invest': {
        return ``
      }
    }
  }}

  > div div div.tiers-block {
    padding: 0;
  }

  ${media.lg`    
    > div div:nth-last-child(-n + 2) [data-testid='tier-block'] {
      margin-bottom: 0;
    }
  `}
  #conversation {
    padding-top: 39px;
    ${media.md`    
        padding-top: 64px;    
  `}
  }
`

export const SectionTitle = styled.h2<{ color?: string }>`
  width: 100%;
  font-size: 1.5rem;
  font-family: Inter;
  margin-bottom: 1rem;
  color: ${p => p.color || p.theme.colors.black};
  font-weight: 800;

  ${media.md`
     font-size: 2.25rem;
     margin-bottom: 41px;
   `}
`

export const Description = styled.div`
  width: 100%;

  div {
    text-align: justify;
    p {
      text-align: justify;
      span {
        font-family: Inter;
        font-size: 0.9375rem !important;
        line-height: 1.5rem;
        font-weight: 300;
        text-align: justify;
        color: ${p => p.theme.colors.black};
      }
    }
  }

  ${media.md`
    width: 65%;
    
    div { 
      p { 
        span { 
          font-size: 1.125rem !important;
          line-height: 1.75rem;
        }
      }
    }
  `}

  span {
    display: block;
    margin-top: 10px;
    text-align: justify;
  }

  div:not(:last-child) {
    margin-bottom: 1.875rem;
  }

  ul,
  ol {
    margin-left: 1.875rem;
    li {
      span {
        margin-top: 0;
      }
    }
  }

  h1 {
    margin: 1rem 0;
    font-size: 1.125rem;
    line-height: 1.625rem;
    text-align: left;

    ${media.md`
      font-size: 40px;
    `}

    ${media.lg`
      font-size: 1.375rem; 
      line-height: 2rem;
    `}
  }

  h2 {
    text-align: left;
  }

  h3 {
    font-size: 2rem;
    text-align: left;
  }

  h4 {
    font-weight: bold;
    text-align: left;
  }

  a {
    color: ${p => p.theme.colors.gold};
  }

  table {
    width: 100% !important;
    table-layout: fixed;
    border: 0.0625rem solid;
    span {
      margin-top: 0;
      text-align: center;
    }

    td {
      padding: 0.625rem;
      border: 0.0625rem solid;
    }
    th {
      text-align: center;
      vertical-align: center;
      span {
        margin-top: 0;
        text-align: center;
      }
    }
  }

  ${media.xs`
  table {
    span {
      font-size: 0.75rem !important;
    }
  }
`}

  ${media.md`
  table {
    span {
      font-size: 0.875rem !important;
    }
  }
`}
`
