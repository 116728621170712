import React from 'react'

const GridIcon = props => (
  <svg
    width={18}
    height={18}
    {...props}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.337.889C0 1.393 0 2.096 0 3.5c0 1.404 0 2.107.337 2.611a2 2 0 00.552.552C1.393 7 2.096 7 3.5 7c1.404 0 2.107 0 2.611-.337a2 2 0 00.552-.552C7 5.607 7 4.904 7 3.5 7 2.096 7 1.393 6.663.889A2 2 0 006.11.337C5.607 0 4.904 0 3.5 0 2.096 0 1.393 0 .889.337A2 2 0 00.337.89zm11 0C11 1.393 11 2.096 11 3.5c0 1.404 0 2.107.337 2.611a2 2 0 00.552.552C12.393 7 13.096 7 14.5 7c1.405 0 2.107 0 2.611-.337a2 2 0 00.552-.552C18 5.607 18 4.904 18 3.5c0-1.404 0-2.107-.337-2.611a2 2 0 00-.552-.552C16.607 0 15.904 0 14.5 0c-1.405 0-2.107 0-2.611.337a2 2 0 00-.552.552zM0 14.5c0-1.405 0-2.107.337-2.611a2 2 0 01.552-.552C1.393 11 2.096 11 3.5 11c1.404 0 2.107 0 2.611.337a2 2 0 01.552.552C7 12.393 7 13.096 7 14.5c0 1.405 0 2.107-.337 2.611a2 2 0 01-.552.552C5.607 18 4.904 18 3.5 18c-1.404 0-2.107 0-2.611-.337a2 2 0 01-.552-.552C0 16.607 0 15.904 0 14.5zm11.337-2.611C11 12.393 11 13.096 11 14.5c0 1.405 0 2.107.337 2.611.146.218.334.406.552.552.504.337 1.207.337 2.611.337 1.405 0 2.107 0 2.611-.337.218-.146.406-.334.552-.552C18 16.607 18 15.904 18 14.5c0-1.405 0-2.107-.337-2.611a1.999 1.999 0 00-.552-.552C16.607 11 15.904 11 14.5 11c-1.405 0-2.107 0-2.611.337a1.999 1.999 0 00-.552.552z"
      fill="#C4C4C4"
    />
  </svg>
)

export default GridIcon
