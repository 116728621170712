import React from 'react'
import { BrandProfileSectionTypes } from 'redux/profile/types'
import { routePath } from 'routes'
import {
  UserIcon,
  AnalyticsIcon,
  PencilIcon,
  LetterIcon
} from 'components/icons/index'
import { generatePath } from 'react-router-dom'

const tabs = {
  [BrandProfileSectionTypes.FAN_VIEW]: {
    title: 'Fan view',
    icon: <UserIcon />,
    url: generatePath(routePath.PROFILE_SECTION, {
      type: BrandProfileSectionTypes.FAN_VIEW
    })
  },
  [BrandProfileSectionTypes.EDIT_PROFILE]: {
    title: 'Edit Profile',
    icon: <PencilIcon />,
    url: generatePath(routePath.PROFILE_SECTION, {
      type: BrandProfileSectionTypes.EDIT_PROFILE
    })
  },
  [BrandProfileSectionTypes.ANALYTICS]: {
    title: 'Analytics',
    icon: <AnalyticsIcon />,
    url: generatePath(routePath.PROFILE_SECTION, {
      type: BrandProfileSectionTypes.ANALYTICS
    })
  },
  [BrandProfileSectionTypes.MESSAGING]: {
    title: 'Messaging',
    icon: <LetterIcon />,
    url: generatePath(routePath.PROFILE_SECTION, {
      type: BrandProfileSectionTypes.MESSAGING
    })
  }
}

export default tabs
