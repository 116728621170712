import React, { FC, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCompaniesList,
  selectEntityAddressInfo,
  selectInvestmentErrors
} from 'redux/investment/selectors'
import { fetchEntityDetails } from 'redux/investment/operations'
import { setEntityAddressInfo } from 'redux/investment/actions'
import { CompanyFields, ICompany, IContact } from 'redux/investment/types'
import { setErrors } from 'redux/investment/actions'
import { DEFAULT_TAX_ID } from 'utils/dateFormats'
import {
  convertValueToTaxId,
  noFirstSpaceRegex,
  noTwoSpacesRegex,
  parsePhone
} from 'utils/helpers'
import { BackButton } from 'src/components/investment/steps'
import TextInput from 'src/components/UI/TextInput'
import Dropdown, { Option } from 'src/components/UI/Dropdown'
import {
  BackButtonWrapper,
  Form,
  Navigation,
  Step,
  Title
} from 'src/components/investment/steps/styles'

function usePrevious(value: any) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const CompanyForm: FC<{ [key: string]: any }> = props => {
  const dispatch = useDispatch()
  const address = useSelector(selectEntityAddressInfo)
  const companies = useSelector(selectCompaniesList)
  const errors = useSelector(selectInvestmentErrors)

  const {
    selectedEntityContactInfo,
    setSelectedEntityContactInfo: setContactForm,
    selectedEntityInfo,
    setSelectedEntityInfo: setCompany
  } = props

  const company = selectedEntityInfo as ICompany
  const contactForm = selectedEntityContactInfo as IContact

  useEffect(() => {
    // setCompany({ entity_name: '', entity_tax_id: '' })
    // eslint-disable-next-line
  }, [])

  const prevChangedId = usePrevious(company.changed_id)

  useEffect(() => {
    const currentCompany = companies[company.changed_id]

    if (!company.changed_id && prevChangedId) {
      setContactForm({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        phone_code: '+1'
      })
      dispatch(
        setEntityAddressInfo({
          street: '',
          additional_address: '',
          country: '',
          city: '',
          state: '',
          postal_code: '',
          changed_fields: []
        })
      )
    }

    if (company.changed_id && currentCompany) {
      setCompany({ ...company, ...currentCompany.details })

      if (currentCompany.details && currentCompany.details.contact) {
        // tslint:disable-next-line:variable-name
        const [first_name, last_name] =
          (currentCompany.details.contact.full_name &&
            currentCompany.details.contact.full_name.split(' ')) ||
          []

        const parsedPhone = parsePhone(currentCompany.details.contact.phone)
        // tslint:disable-next-line:variable-name
        const phone_code = parsedPhone
          ? `+${parsedPhone.countryCallingCode}`
          : ''

        const phone = parsedPhone
          ? parsedPhone.number.substr(phone_code.length)
          : ''

        setContactForm({
          ...contactForm,
          ...currentCompany.details.contact,
          first_name,
          last_name,
          phone,
          phone_code
        })
      }

      if (currentCompany.details && currentCompany.details.contact) {
        dispatch(
          setEntityAddressInfo({
            ...address,
            ...currentCompany.details.entityAddress,
            street: currentCompany.details.entityAddress.address
          })
        )
      }
    }
    // eslint-disable-next-line
  }, [company.changed_id])

  const onChange = (name: string, value: string) => {
    if (name === 'entity_tax_id') {
      setCompany({ ...company, [name]: convertValueToTaxId(value) })
    } else {
      const currentValue = value
        .replace(noFirstSpaceRegex, '')
        .replace(noTwoSpacesRegex, ' ')
      setCompany({ ...company, [name]: currentValue })
    }
    if (errors[name]) {
      dispatch(setErrors({ ...errors, [name]: '' }))
    }
  }

  const onChangeCompany = async (
    name: string,
    option: Option & { __isNew__: boolean }
  ) => {
    const { value, label, __isNew__ } = option
    if (!__isNew__ && value) {
      await dispatch(fetchEntityDetails(value))
      setCompany({ ...company, changed_id: value, [name]: label })
    } else {
      setCompany({
        [name]: label,
        entity_tax_id: ''
      })
    }

    if (errors[name]) {
      dispatch(
        setErrors({
          ...errors,
          [name]: '',
          entity_email: '',
          entity_first_name: '',
          entity_last_name: '',
          entity_phone: '',
          entity_tax_id: '',
          state: '',
          entity_street: ''
        })
      )
    }
  }

  return (
    <>
      <Navigation>
        <BackButtonWrapper>
          <Step>2</Step>
          <BackButton />
          <Title>Entity Information</Title>
        </BackButtonWrapper>
      </Navigation>
      <Form>
        {Object.keys(companies).length > 0 ? (
          <Dropdown
            label={CompanyFields.entity_name}
            name={'entity_name'}
            options={Object.keys(companies).map(k => ({
              label: companies[k].name,
              value: companies[k].id
            }))}
            value={{
              label: selectedEntityInfo.entity_name,
              value: selectedEntityInfo.id
            }}
            placeholder={CompanyFields.entity_name}
            onSelect={onChangeCompany}
            // onInputChange={handleChangeInputAddress}
            isCreatable={true}
            isAsync={true}
            wrapperClassName={'address-form__street'}
            forceUpdate={true}
            buttonClassName={'address-form__select'}
            customStyles={{
              control: { background: '#fff' },
              input: { background: '#fff' },
              firstOption: { borderRadius: '0' },
              option: { backgroundColor: '#fff' }
            }}
            valid={!errors.entity_name}
            errorLabel={errors.entity_name}
          />
        ) : (
          <TextInput
            name={'entity_name'}
            value={company.entity_name}
            label={CompanyFields.entity_name}
            handleChange={onChange}
            wrapperClassName={'form__first-field'}
            errorLabel={errors.entity_name}
            maxLength={128}
          />
        )}
        <TextInput
          type={'tel'}
          maskConfig={{ mask: '99-9999999', maskChar: null }}
          name={'entity_tax_id'}
          value={company.entity_tax_id}
          maxLength={10}
          label={CompanyFields.entity_tax_id}
          handleChange={onChange}
          placeholder={DEFAULT_TAX_ID}
          wrapperClassName={'form__last-field'}
          errorLabel={errors.entity_tax_id}
        />
      </Form>
    </>
  )
}

export default React.memo(CompanyForm)
