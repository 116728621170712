import React from 'react'
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps
} from 'react-router-dom'
import { Button, ButtonProps, ButtonVariant } from 'components/UI/Button'
import { isUrl, isMailTo } from 'utils/helpers'

export type RouterLinkWithButtonProps = ButtonProps &
  RouterLinkProps & { target?: string }

// tslint:disable-next-line:no-empty-interface
export interface LinkProps extends RouterLinkWithButtonProps {}

const Link: React.FC<LinkProps> = props => {
  const { to, onClick = () => {}, variant = ButtonVariant.Clear } = props

  const onClickLink = (e: React.FormEvent<HTMLInputElement>): void => {
    if (to === '#') {
      e.preventDefault()
      e.nativeEvent.stopImmediatePropagation()
    }

    onClick(e)
  }

  const newProps = {
    as: isUrl(to) || isMailTo(to) ? 'a' : RouterLink,
    variant,
    ...props
  }
  if (typeof to === 'string' && (isMailTo(to) || isUrl(to))) {
    newProps.href = to
    delete newProps.to
  }

  return <Button {...newProps} onClick={onClickLink} />
}

export default Link

export { Link }
