import React from 'react'

import { GoldSearchIcon, CrossIcon } from 'components/icons'
import {
  LightSearchBox,
  DarkSearchBox,
  SearchInput,
  SearchClear,
  SearchBar,
  SearchIconWrapper,
  SearchClearWrapper
} from './styles'

export enum SearchVariant {
  Light = 'light',
  Dark = 'dark'
}

interface SearchProps {
  variant?: 'light' | 'dark'
  placeholder: string
  value: string

  onClick?: () => void
  onChange: (name: string, value: string) => void
  onClear: () => void
}

const Search: React.FC<SearchProps> = ({
  variant = 'light',
  onClick,
  placeholder = 'Search',
  onChange,
  onClear,
  value
}): React.ReactElement => {
  const components = {
    [SearchVariant.Light]: LightSearchBox,
    [SearchVariant.Dark]: DarkSearchBox
  }

  const SearchComponent = components[variant]
  return (
    <SearchComponent>
      <SearchIconWrapper>
        <GoldSearchIcon />
      </SearchIconWrapper>

      <SearchBar>
        <SearchInput
          placeholder={placeholder}
          name="search"
          handleChange={onChange}
          value={value}
          variant={variant}
          onClick={onClick}
        />
        <SearchClearWrapper>
          <SearchClear onClick={onClear}>
            <CrossIcon />
          </SearchClear>
        </SearchClearWrapper>
      </SearchBar>
    </SearchComponent>
  )
}

export default Search
export { Search }
