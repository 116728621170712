import { createGlobalStyle } from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { theme } from './theme'
import './fonts/stylesheet.css'
import zoomIcon from 'images/magnifying-glass.svg'

const GlobalStyle = createGlobalStyle`  

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Inter, sans-serif;
  }
  
  #root {
    height: 100%;
  }
  
  h1, h2, h3, h4, h5, h6 {
    color: ${theme.colors.white};
    font-weight: 500;
    line-height: 1.4em;
  }
  
  h1 {
    font-size: 48px;
    line-height: normal;
  }
  
  h2 {
    font-size: 36px;
  }
  
  h3 {
    font-size: 21px;
  }
  
  h4 {
    font-size: 18px;
  } 
  
  h5 {
    font-size: 15px;
  }
  
  h6 {
    font-size: 14px;
  }
  
  p, span {
    color: #fff;
    font-size: 14px;
  }
  
  html,
  body {  
    height: 100%;
    background-color: ${theme.colors.black};
    scroll-behavior: smooth;
  }
  
  .navbar {
    width: 100%;
    display: flex;
    align-items: center;
  }
  
  .wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
  
  .content {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
  }
  
  .footer {
    flex: 0 0 auto;
    background: ${theme.colors.darkNavy};
  }
  
  .google-login,
  .facebook-login {
    padding: 0.5rem;
    cursor: pointer;
    outline: 0;
    background: ${theme.colors.darkGrey};
    border: 1px solid rgba(255,255,255,0.36);
    border-radius: 1rem;
    color: ${theme.colors.white};
    display: flex;
    align-items: center;
    transition: 0.1s opacity linear;
    font-size: 0.875rem;
    font-weight: 700;
    
    ${media.md`
    padding: 1rem;
    `}
    
    &:hover {
      opacity: 0.8;
    }
    
    svg {
      width: 2rem;
      height: auto;
      margin-right: 1.25rem;
      
      ${media.md`
      margin-right: 1.875rem;
      `}
    
      path {
        fill: ${theme.colors.white};
      }
    }
  }
  .tab-content-container {
   > div { 
        margin-top: 41px !important;  
        ${media.lg`
            margin-top: 56px !important;  
        `} 
   }
   }
  .alert-enter-done {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1 auto;
  }

  .alert-enter {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1 auto;
    top: 0;
    width: 100%;
    opacity: 0;
    transform: scale(0.9);
  }
  
  .alert-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 400ms, transform 400ms;
  }
  .alert-exit {
    opacity: 1;
    display: none;
  }
  .alert-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 400ms, transform 400ms;
  }
  
  #file,
  #file_1 {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  
  .desktop-purchased-tier {
    display: none;
    
    ${media.lg`
    display: block;
    `}
  }
  
    .tablet-purchased-tier {
        display: none;
        
        ${media.md`
        display: block;
        `}
    }
    
    .mobile-purchased-tier {
        display: block;
    }
    
    .react-hint {
      padding: 5px;
      position: absolute;
      z-index: 9999;
      cursor: default;
      animation: 0.5s fadeIn;
    }
    
    .react-hint__content {
      padding: 20px;
      border-radius: 5px;
      background: #000;
      color: #fff;
      font-size: 14px;
      max-width: 220px;
      min-height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 170px;
    }
    
    .react-hint:after {
      content: '';
      width: 0;
      height: 0;
      margin: auto;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 5px solid transparent;
    }
    
    .react-hint--top:after {
      top: auto;
      border-bottom: none;
      border-top-color: #000;
    }
    
    .react-hint--left:after {
      left: auto;
      border-right: none;
      border-left-color: #000;
    }
    
    .react-hint--right:after {
      right: auto;
      border-left: none;
      border-right-color: #000;
    }
    
    .react-hint--bottom:after {
      bottom: auto;
      border-top: none;
      border-bottom-color: #000;
    }
    
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
    
      to {
        opacity: 1;
      }
    }
    
    .ReactModal__Content#fa-form {
        ${media.xs`
            padding: 0.75rem 0.25rem !important;
        `}
    }
    
    .ReactModal__Body--open {
      overflow: hidden;
    }
    
    .tier__image {
      display: flex;
      width: 100%;
      height: 100%;
      object-fit: contain;
    
      ${media.md`
        width: 150px;
        height: 100%;
        margin: 0;
      `}
    
      ${media.xl`
        width: 250px;
        height: 100%;
      `}
    }
    
    .zoom {
      &__cursor {
          width: 100%;
          height: 100%;
          position: absolute;
          cursor: url(${() => zoomIcon}), auto;
      }
    
      &__modal.ReactModal__Overlay {
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        opacity: 0;
        transform: scale(0);
        transition: all 500ms ease-in-out;
      }
        
      &__modal.ReactModal__Overlay--after-open {
        opacity: 1;
        transform: scale(1.1);
      }
        
      &__modal.ReactModal__Overlay--before-close {
        opacity: 0;
        transform: scale(0);
      }
    }
    
    .sticky {
      position: sticky;
      top: calc(4vw + 4rem);
    }
    .sticky.authenticated {
      ${media.xl`
        top: calc(3vw + 3.125rem);
      `}
    }
    
    .credit-card {
      &__first-field {
        border-radius: 12px 12px 0 0;
        
        ${media.md`
            flex: 3 3 50%;
            border-right: 1px solid rgba(0, 0, 0, 0.15);
            border-top-right-radius: 0;
        `}
      }
      
      &__second-field {
        flex: 3 3 65%;
        border-right: 1px solid rgba(0, 0, 0, 0.15);
        
        ${media.md`
            flex: 2 2 30%;
        `}
      }
      
      &__third-field {
        flex: 1 1 35%;
        
        ${media.md`
            flex-basis: 20%;
            border-top-right-radius: 12px;
        `}
      }
      
      &__zip-field {
        flex: 1 1 35%;
         border-bottom-right-radius: 12px;
         
        ${media.md`
            flex-basis: 20%;
        `}
      }
    }
    .address-form {
        &__street {
            background-color: ${theme.colors.white};
            border-radius: 0.75rem 0.75rem 0 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);
            padding: 0.375rem 0.625rem;
        
            label {
              color: ${theme.colors.black};
              opacity: 0.75;
              font-size: 0.875rem;
              font-weight: 400;
              line-height: 1.1875rem;
            }
          }
        &__city {
            border-right: 1px solid rgba(0, 0, 0, 0.15);
            flex: 1 1 50%;
        }
        &__state {
            flex: 1 1 50%;
        }
        &__country {
          border-right: 1px solid rgba(0, 0, 0, 0.15);
            border-bottom-left-radius: 12px;
            flex: 1 1 50%;
        }
        &__zip {
            border-bottom-right-radius: 12px;
            flex: 1 1 50%;
        }
        
        &__select {
            padding: 0;
            position: relative;
            background-color: ${theme.colors.white};
            text-transform: capitalize;
        
            div {
              &:first-of-type {
                width: 90%;
              }
              &:last-of-type {
                position: absolute;
                right: 0;
              }
            }
          }
    }
    
    .form {
        &__first-field {
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
    }
        &__last-field {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
        }
        &__single-field {
            border-radius: 12px;
        }
    }
    
    .us-bank {
      &__account-type {
        background-color: ${theme.colors.white};
        border-top-right-radius: 12px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        padding: 0.375rem 0.625rem;
      }
    } 
    
    .profile {
       &-user {
            &__first-field {
             border-radius: 12px 12px 0 0;
             
             ${media.md`
                 flex: 2 2 50%;
                 border-right: 1px solid rgba(0, 0, 0, 0.15);
                 border-top-right-radius: 0;
                 max-width: 50%;
             `}
            }
            &__second-field {
            
             ${media.md`
                 border-radius: 0 12px 0 0;
                 flex: 1 1 50%;
                 max-width: 50%;
             `}
             }
        }
        &-address {
            &__first-field {
              ${media.md`
                 flex: 2 2 50%;
                 max-width: 50%;
                 border-right: 1px solid rgba(0, 0, 0, 0.15);
                 border-top-right-radius: 0;
             `}
            }
            &__second-field {
            
             ${media.md`
                 border-radius: 0 12px 0 0;
                 flex: 1 1 50%;
             `}
             }
           &__third-field {
              ${media.md`
                 flex: 2 2 50%;
                 max-width: 50%;
                 border-right: 1px solid rgba(0, 0, 0, 0.15);
             `}
            }
           &__fourth-field {
             ${media.md`
                 flex: 1 1 50%;
                 max-width: 50%;
                 border-radius: 0;
             `}
             }
           &__fifth-field {
            flex: 1 1 50%;
            max-width: 50%;
            border-radius: 0 0 0 12px;
            border-right: 1px solid rgba(0, 0, 0, 0.15);
            }
           &__sixth-field {
            flex: 2 2 50%;
            max-width: 50%; 
            border-radius: 0 0 12px 0;
           }
        }
       }
`

export default GlobalStyle
