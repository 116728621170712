import React, { FC } from 'react'
import { Progress, ProgressWrapper } from './styles'

interface IProps {
  total: number
  raised: number
  isEndedEvent?: boolean
}
const ProgressBar: FC<IProps> = ({ total, raised, isEndedEvent }) => {
  const progress = (raised / total) * 100

  return (
    <ProgressWrapper>
      <Progress progress={progress} isEndedEvent={isEndedEvent} />
    </ProgressWrapper>
  )
}

export default ProgressBar
