import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { routePath } from 'routes'
import { fetchInvestments, loadMoreInvestments } from 'redux/profile/operations'
import { clearInvestments } from 'redux/profile/actions'
import {
  selectAllInvestments,
  selectFetchingInvestments
} from 'redux/profile/selectors'
import Loader from 'src/components/UI/Loader'
import InfiniteScroll from 'components/common/InfiniteScroll'
import InvestListItem from '../InvestListItem'

import {
  EventsLink,
  Text,
  TextWrapper
} from 'components/profile/dashboard/styles'

const InvestList: FC = () => {
  const dispatch = useDispatch()
  const isFetching = useSelector(selectFetchingInvestments)
  const { data } = useSelector(selectAllInvestments)

  useEffect(() => {
    if (!data.length) {
      dispatch(fetchInvestments())
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    return () => {
      dispatch(clearInvestments())
    }
  }, [dispatch])

  const loadMore = () => dispatch(loadMoreInvestments())

  const renderInvestment = (investments: any) =>
    investments &&
    investments.map((item: any) => (
      <div key={item.id}>
        <InvestListItem investment={item} />
      </div>
    ))

  const renderSelectedItems = () => {
    if (!data.length) {
      return noItems()
    }
    return renderInvestment(data)
  }

  const noItems = () => (
    <TextWrapper>
      <Text>You have not invested in any events. Let's pick something.</Text>
      <EventsLink to={routePath.START_PAGE}>See events</EventsLink>
    </TextWrapper>
  )

  return (
    <InfiniteScroll onLoadMore={loadMore}>
      {!data.length && isFetching ? <Loader /> : renderSelectedItems()}
      {data.length && isFetching ? <Loader /> : ''}
    </InfiniteScroll>
  )
}

export default InvestList
