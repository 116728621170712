import React, { createRef, RefObject, useEffect, useState } from 'react'
import { PlayButton, Player, PlayerWrapper, VideoPreview } from './styles'
import Loader from 'components/UI/Loader'

interface Props {
  videoUrl: string
  preview: string
}
const EventVideo: React.FC<Props> = ({ videoUrl, preview }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isPlaying, setIsPlaying] = useState<boolean>(false)
  const ref: RefObject<HTMLVideoElement> = createRef()

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    window.addEventListener('click', handleClick)

    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('click', handleClick)
    }
  })

  const handleClick = (e: any) => {
    const element = e.target.getBoundingClientRect()
    const clickY = element.top
    const clickX = element.left
    const playerRect = ref.current
      ? ref.current.getBoundingClientRect()
      : ({} as ClientRect)
    const playerRectY = playerRect.top
    if (playerRect && clickY !== playerRectY) {
      handleStop()
    }
    if (
      playerRect &&
      !isPlaying &&
      !clickY &&
      !clickX &&
      e.target.getAttribute('aria-label') === 'Play'
    ) {
      handlePlay()
    }
  }

  const handleScroll = () => {
    const playerRect = ref.current
      ? ref.current.getBoundingClientRect()
      : ({} as ClientRect)
    if (
      playerRect.top <= -playerRect.height ||
      playerRect.top >= window.innerHeight ||
      window.scrollY === 0
    ) {
      handleStop()
    }
  }

  const handleCanPlay = () => {
    setIsLoading(false)
  }

  const handlePlay = () => {
    setIsPlaying(true)
    if (ref.current) {
      ref.current.play()
    }
  }
  const handleStop = () => {
    setIsPlaying(false)
    setIsLoading(true)
    if (ref.current) {
      ref.current.pause()
    }
  }

  const handleTap = () =>
    ref.current &&
    (ref.current.paused ? ref.current.play() : ref.current.pause())

  return (
    <PlayerWrapper id={'player-wrapper'}>
      {isPlaying && isLoading && <Loader />}
      <Player
        id={'event-video'}
        src={videoUrl}
        isLoading={isLoading}
        preload="auto"
        controls={true}
        onPlaying={handleCanPlay}
        ref={ref}
        onEnded={handleStop}
        onTouchEnd={handleTap}
      />
      {!isPlaying && (
        <VideoPreview img={preview}>
          <PlayButton aria-label="Play" onClick={handlePlay} />
        </VideoPreview>
      )}
    </PlayerWrapper>
  )
}
export default EventVideo
