import React, { ReactNode } from 'react'
import { IInfluencer } from 'redux/influencer/types'
import {
  CardInfo,
  InfluencerDescriptionColumn,
  InfoWrapper,
  Name,
  TitleWrapper,
  Wrapper
} from '../styles'

interface Props {
  event: IInfluencer
  isPlaying: boolean
  children: ReactNode
}

const InfluencerHeaderContent: React.FC<Props> = ({
  event,
  isPlaying,
  children
}): React.ReactElement => {
  const { influencer_description, influencer_name } = event
  const hideContent = isPlaying ? 'none' : 'flex'
  return (
    <Wrapper style={{ display: hideContent }}>
      <InfoWrapper>
        {children}
        <CardInfo>
          <TitleWrapper>
            <Name>{influencer_name}</Name>
            {/*<Description*/}
            {/*  dangerouslySetInnerHTML={{*/}
            {/*    __html: influencer_description!*/}
            {/*  }}*/}
            {/*/>*/}
          </TitleWrapper>
        </CardInfo>
        <InfluencerDescriptionColumn>
          <p>{influencer_description!}</p>
        </InfluencerDescriptionColumn>
      </InfoWrapper>
    </Wrapper>
  )
}

export default InfluencerHeaderContent
