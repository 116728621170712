import React, { FC } from 'react'
import {
  timeRemaining,
  showHoursLeft,
  formatDateWithMonthAbbr
} from 'utils/dateFormats'
import { displayStatus } from 'utils/helpers'
import { Statuses } from 'redux/commonTypes'
import { EventDate, EventStatus, Text, TimeLeft } from './styles'

const DaysLeft: FC<{
  eventStatus: Statuses
  hoursLeft: number
  endDate: string
  showHours?: boolean
}> = ({ eventStatus, hoursLeft, showHours = false, endDate }) => {
  const isLive = eventStatus === Statuses.LIVE
  const isEnded = eventStatus === Statuses.ENDED
  const isComingSoon = eventStatus === Statuses.COMING_SOON
  const comingSoonStatus = () => {
    if (isComingSoon) {
      const comingSoon = String(displayStatus(eventStatus)).split(' ')
      return (
        <>
          {comingSoon[0]} <br /> {comingSoon[1]}
        </>
      )
    }
  }
  const end =
    showHours && hoursLeft < 24
      ? showHoursLeft(hoursLeft)
      : timeRemaining(hoursLeft)
  const showEndDate = end.split(' ')

  return (
    <>
      {!isComingSoon && end && (
        <TimeLeft>
          <Text>
            {isLive && (
              <>
                <EventDate>{showEndDate[0]}</EventDate>
                <EventDate>
                  {showEndDate[1]} <br /> left
                </EventDate>
              </>
            )}

            {isEnded && (
              <EventStatus>
                {displayStatus(eventStatus)} on <br />
                {formatDateWithMonthAbbr(endDate)}
              </EventStatus>
            )}
          </Text>
        </TimeLeft>
      )}
      {isComingSoon && (
        <TimeLeft>
          <Text>
            <EventStatus>{comingSoonStatus()} </EventStatus>
          </Text>
        </TimeLeft>
      )}
    </>
  )
}

export default DaysLeft
