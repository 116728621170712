import { createReducer } from 'typesafe-actions'
import {
  EventsState,
  EventsAction,
  EventCategories,
  IEventDetail,
  IPurchased,
  EventCategoryFolder,
  EventsActionTypes,
  ITier,
  IEvent,
  EventType
} from './types'
import {
  IEventComments,
  IComment,
  ReplyList,
  ICommentThread
} from './comments/types'
import { IPagination, Meta } from '../commonTypes'
import { IUser } from 'redux/auth/types'

const generateCategory = () => ({
  isFetching: false,
  [EventCategoryFolder.RECOMMENDED]: {
    data: [],
    meta: {} as Meta,
    links: {},
    tabs: []
  },
  [EventCategoryFolder.TRENDED]: {
    data: [],
    meta: {} as Meta,
    links: {},
    tabs: []
  }
})

export const initialState: EventsState = {
  isFetching: false,
  isFetchingTier: false,
  isLoaded: false,
  homepage: {
    [EventCategories.ALL]: generateCategory(),
    [EventCategories.MUSIC]: generateCategory(),
    [EventCategories.SPORT]: generateCategory(),
    [EventCategories.E_SPORTS]: generateCategory(),
    [EventCategories.TV_FILMS]: generateCategory()
  },
  list: {
    data: [],
    meta: {} as Meta,
    links: {},
    tabs: []
  },
  investments: {
    isFetching: false,
    data: [],
    meta: {} as Meta,
    links: {},
    tabs: []
  },
  experiences: {
    isFetching: false,
    data: [],
    meta: {} as Meta,
    links: {},
    tabs: []
  },
  nft: {
    isFetching: false,
    data: [],
    meta: {} as Meta,
    links: {},
    tabs: []
  },
  eventsCount: [],
  eventsByInfluencer: {},
  detail: {} as IEventDetail,
  tierDetail: { data: {} as ITier },
  purchased: { data: [] as ITier[] } as IPurchased,
  searchedItems: {} as IPurchased,
  influencers: {
    data: [] as IUser[],
    pagination: {} as IPagination,
    influencersSearchField: ''
  },
  isFetchingInfluencers: false,
  comments: {
    isFetching: false,
    isSending: false,
    data: { data: [] as IComment[] } as IEventComments,
    activeCommentId: null,
    shownComments: [],
    singleCommentThread: {} as ICommentThread
  },
  errors: null,
  replies: {} as ReplyList,
  isFetchingReplies: false
}

export const eventsReducer = createReducer<EventsState, EventsAction>(
  initialState,
  {
    [EventsActionTypes.FETCH_EVENTS_HOME_REQUEST]: (state, action) => {
      const category = action.payload.category as EventCategories
      return {
        ...state,
        homepage: {
          ...state.homepage,
          [category]: {
            ...state.homepage[category],
            isFetching: true
          }
        }
      }
    },
    [EventsActionTypes.FETCH_EVENTS_HOME_SUCCESS]: (state, action) => {
      const category = action.payload.category as EventCategories

      return {
        ...state,
        homepage: {
          ...state.homepage,
          [category]: {
            ...state.homepage[category],
            isFetching: false,
            [EventCategoryFolder.RECOMMENDED]: {
              ...state.homepage[category][EventCategoryFolder.RECOMMENDED],
              data: [
                ...state.homepage[category][EventCategoryFolder.RECOMMENDED]
                  .data,
                ...action.payload.data[EventCategoryFolder.RECOMMENDED].data
              ],
              meta: action.payload.data[EventCategoryFolder.RECOMMENDED].meta,
              links: action.payload.data[EventCategoryFolder.RECOMMENDED].links,
              tabs: action.payload.data[EventCategoryFolder.RECOMMENDED].tabs
            },

            [EventCategoryFolder.TRENDED]: {
              ...state.homepage[category][EventCategoryFolder.TRENDED],
              data: [
                ...state.homepage[category][EventCategoryFolder.TRENDED].data,
                ...action.payload.data[EventCategoryFolder.TRENDED].data
              ],
              meta: action.payload.data[EventCategoryFolder.TRENDED].meta,
              links: action.payload.data[EventCategoryFolder.TRENDED].links,
              tabs: action.payload.data[EventCategoryFolder.TRENDED].tabs
            }
          }
        }
      }
    },
    [EventsActionTypes.FETCH_EVENTS_HOME_FAIL]: (state, action) => {
      const category = action.payload.category as EventCategories
      return {
        ...state,
        homepage: {
          ...state.homepage,
          [category]: {
            ...state.homepage[category],
            isFetching: false
          }
        }
      }
    },
    [EventsActionTypes.CLEAR_EVENTS_HOME_PAGE]: (state, action) => ({
      ...state,
      homepage: initialState.homepage,
      investments:
        action.payload === EventType.INVESTMENT
          ? initialState.investments
          : { ...state.investments },
      experiences:
        action.payload === EventType.EXPERIENCES
          ? initialState.experiences
          : { ...state.experiences },
      nft:
        action.payload === EventType.NFT ? initialState.nft : { ...state.nft }
    }),
    [EventsActionTypes.CHANGE_PAGE_EVENTS_HOME]: state => ({
      ...state,
      homepage: initialState.homepage
    }),
    [EventsActionTypes.FETCH_EVENTS_REQUEST]: (state, action) => ({
      ...state,
      investments: {
        ...state.investments,
        isFetching: action.payload === EventType.INVESTMENT
      },
      experiences: {
        ...state.experiences,
        isFetching: action.payload === EventType.EXPERIENCES
      },
      nft: {
        ...state.nft,
        isFetching: action.payload === EventType.NFT
      }
    }),
    [EventsActionTypes.FETCH_EVENTS_SUCCESS]: (state, action) => ({
      ...state,
      investments:
        action.payload.type === EventType.INVESTMENT
          ? {
              isFetching: false,
              data: [...state.investments.data, ...action.payload.data],
              meta: action.payload.meta,
              links: action.payload.links,
              tabs: action.payload.tabs
            }
          : { ...state.investments },
      experiences:
        action.payload.type === EventType.EXPERIENCES
          ? {
              isFetching: false,
              data: [...state.experiences.data, ...action.payload.data],
              meta: action.payload.meta,
              links: action.payload.links,
              tabs: action.payload.tabs
            }
          : { ...state.experiences },
      nft:
        action.payload.type === EventType.NFT
          ? {
              isFetching: false,
              data: [...state.nft.data, ...action.payload.data],
              meta: action.payload.meta,
              links: action.payload.links,
              tabs: action.payload.tabs
            }
          : { ...state.nft }
    }),
    [EventsActionTypes.FETCH_EVENTS_MENU_SUCCESS]: (state, action) => ({
      ...state,
      eventsCount: action.payload.tabs
    }),
    [EventsActionTypes.FETCH_PURCHASED_REQUEST]: state => ({
      ...state,
      isFetching: true
    }),
    [EventsActionTypes.FETCH_PURCHASED_SUCCESS]: (state, action) => ({
      ...state,
      isFetching: false,
      purchased: {
        data: [...state.purchased.data, ...action.payload.data],
        links: action.payload.links,
        meta: action.payload.meta
      }
    }),
    [EventsActionTypes.SEARCH_PURCHASED_REQUEST]: state => ({
      ...state,
      isFetching: true
    }),
    [EventsActionTypes.SEARCH_PURCHASED_SUCCESS]: (state, action) => ({
      ...state,
      isFetching: false,
      searchedItems: action.payload
    }),
    [EventsActionTypes.SEARCH_INFLUENCERS_REQUEST]: state => ({
      ...state,
      isFetchingInfluencers: true
    }),
    [EventsActionTypes.SEARCH_INFLUENCERS_SUCCESS]: (state, action) => ({
      ...state,
      isFetchingInfluencers: false,
      influencers: {
        ...state.influencers,
        data: [...state.influencers.data, ...action.payload.data],
        pagination: action.payload.pagination
      }
    }),
    [EventsActionTypes.SEARCH_INFLUENCERS_FAIL]: (state, action) => ({
      ...state,
      isFetchingInfluencers: false,
      influencers: {
        ...initialState.influencers,
        influencersSearchField: state.influencers.influencersSearchField
      }
    }),
    [EventsActionTypes.CLEAR_PURCHASED]: state => ({
      ...state,
      purchased: initialState.purchased
    }),
    [EventsActionTypes.CLEAR_SEARCHED]: state => ({
      ...state,
      searchedItems: initialState.searchedItems
    }),
    [EventsActionTypes.CLEAR_SEARCHED]: state => ({
      ...state,
      searchedItems: initialState.searchedItems
    }),
    [EventsActionTypes.CLEAR_INFLUENCER_LIST]: state => ({
      ...state,
      influencers: {
        ...initialState.influencers,
        influencersSearchField: state.influencers.influencersSearchField
      }
    }),
    [EventsActionTypes.FETCH_DETAIL_EVENT_REQUEST]: state => ({
      ...state,
      isFetching: true,
      isLoaded: false,
      detail: initialState.detail
    }),
    [EventsActionTypes.FETCH_DETAIL_EVENT_SUCCESS]: (state, action) => ({
      ...state,
      isFetching: false,
      isLoaded: true,
      detail: action.payload.event
    }),
    [EventsActionTypes.FETCH_DETAIL_EVENT_FAIL]: state => ({
      ...state,
      isFetching: false,
      isLoaded: true
    }),
    [EventsActionTypes.CLEAR_DETAIL_EVENT]: state => ({
      ...state,
      isFetching: true,
      isLoaded: false,
      detail: initialState.detail
    }),
    [EventsActionTypes.CHANGE_PAGE_EVENTS_HOME]: (state, action) => {
      const category = action.payload.category as EventCategories
      const type = action.payload.type as EventCategoryFolder
      const currentPagination = state.homepage[category][type].meta

      return {
        ...state,

        homepage: {
          ...state.homepage,
          [category]: {
            ...state.homepage[category],
            [type]: {
              ...state.homepage[category][type],
              pagination: {
                current_page: currentPagination.current_page + 1
              }
            }
          }
        }
      }
    },
    [EventsActionTypes.CHANGE_PAGE_ALL_EVENTS_HOME]: (state, action) => ({
      ...state,
      investments:
        action.payload === EventType.INVESTMENT
          ? {
              ...state.investments,
              meta: {
                ...state.investments.meta,
                current_page: state.investments.meta.current_page + 1
              }
            }
          : { ...state.investments },
      experiences:
        action.payload === EventType.EXPERIENCES
          ? {
              ...state.experiences,
              meta: {
                ...state.experiences.meta,
                current_page: state.experiences.meta.current_page + 1
              }
            }
          : { ...state.experiences },
      nft:
        action.payload === EventType.NFT
          ? {
              ...state.nft,
              meta: {
                ...state.nft.meta,
                current_page: state.nft.meta.current_page + 1
              }
            }
          : { ...state.nft }
    }),
    [EventsActionTypes.CLEAR_EVENTS_BY_INFLUENCER]: (state, action) => ({
      ...state,
      eventsByInfluencer: {
        ...state.eventsByInfluencer,
        [action.payload.key]: {
          data: [] as IEvent[],
          pagination: {} as IPagination,
          isFetching: true
        }
      }
    }),
    [EventsActionTypes.FETCH_EVENTS_BY_INFLUENCER_REQUEST]: (
      state,
      action
    ) => ({
      ...state,
      eventsByInfluencer: {
        ...state.eventsByInfluencer,
        [action.payload.key]: {
          ...state.eventsByInfluencer[action.payload.key],
          isFetching: true
        }
      }
    }),
    [EventsActionTypes.FETCH_EVENTS_BY_INFLUENCER_SUCCESS]: (
      state,
      action
    ) => ({
      ...state,
      eventsByInfluencer: {
        ...state.eventsByInfluencer,
        [action.payload.key]: {
          isFetching: false,
          ...action.payload.events,
          data: [
            ...state.eventsByInfluencer[action.payload.key].data,
            ...action.payload.events.data
          ]
        }
      }
    }),
    [EventsActionTypes.FETCH_EVENTS_BY_INFLUENCER_FAIL]: (state, action) => ({
      ...state,
      eventsByInfluencer: {
        ...state.eventsByInfluencer,
        [action.payload.key]: {
          ...state.eventsByInfluencer[action.payload.key],
          isFetching: false
        }
      }
    }),
    [EventsActionTypes.FETCH_COMMENTS_REQUEST]: state => ({
      ...state,
      comments: {
        ...state.comments,
        isFetching: true
      }
    }),
    [EventsActionTypes.FETCH_COMMENTS_SUCCESS]: (state, action) => ({
      ...state,
      comments: {
        ...state.comments,
        isFetching: false,
        data: {
          ...state.comments.data,
          data: [
            ...state.comments.data.data,
            ...action.payload.data.map(comment => ({
              ...comment,
              replies: comment.replies.reverse()
            }))
          ],
          links: action.payload.links,
          meta: action.payload.meta
        },
        shownComments:
          state.comments.shownComments.length === 0
            ? action.payload.data.slice(0, 3)
            : state.comments.shownComments
      }
    }),
    [EventsActionTypes.POST_COMMENT_REQUEST]: state => ({
      ...state,
      comments: {
        ...state.comments,
        isSending: true
      }
    }),
    [EventsActionTypes.POST_COMMENT_SUCCESS]: (state, action) => ({
      ...state,
      comments: {
        ...state.comments,
        isSending: false,
        data: {
          ...state.comments.data,
          data: [action.payload.data, ...state.comments.data.data]
        }
      },
      detail: {
        ...state.detail,
        comments_count: state.detail.comments_count + 1
      }
    }),
    [EventsActionTypes.POST_COMMENT_FAIL]: (state, action) => ({
      ...state,
      comments: {
        ...state.comments,
        isSending: false
      },
      errors: action.payload
    }),
    [EventsActionTypes.CLEAR_COMMENTS]: state => ({
      ...state,
      comments: { ...initialState.comments },
      errors: initialState.errors
    }),
    [EventsActionTypes.REPLY_TO_COMMENT_REQUEST]: state => ({
      ...state,
      isFetchingReplies: true
    }),
    [EventsActionTypes.REPLY_TO_COMMENT_SUCCESS]: (state, action) => {
      const commentThread = state.comments.singleCommentThread.data
        ? {
            ...state.comments.singleCommentThread,
            data: {
              ...state.comments.singleCommentThread.data,
              replies:
                state.comments.singleCommentThread.data.id ===
                action.payload.commentId
                  ? [
                      ...state.comments.singleCommentThread.data.replies,
                      action.payload.data
                    ]
                  : state.comments.singleCommentThread.data.replies,
              total_replies:
                state.comments.singleCommentThread.data.total_replies + 1
            }
          }
        : initialState.comments.singleCommentThread

      return {
        ...state,
        isFetchingReplies: false,
        comments: {
          ...state.comments,
          data: {
            ...state.comments.data,
            data: state.comments.data.data.map(comment => {
              if (comment.id === action.payload.commentId) {
                return {
                  ...comment,
                  replies: [...comment.replies, action.payload.data],
                  total_replies: comment.total_replies + 1
                }
              } else {
                return comment
              }
            })
          },
          singleCommentThread: commentThread
        }
      }
    },
    [EventsActionTypes.REPLY_TO_COMMENT_FAIL]: state => ({
      ...state,
      isFetchingReplies: false
    }),
    [EventsActionTypes.CLEAR_ERRORS]: state => ({
      ...state,
      errors: initialState.errors
    }),
    [EventsActionTypes.SET_ACTIVE_COMMENT]: (state, action) => ({
      ...state,
      comments: {
        ...state.comments,
        activeCommentId: action.payload.id
      }
    }),
    [EventsActionTypes.FETCH_REPLIES_REQUEST]: state => ({
      ...state,
      isFetchingReplies: true
    }),
    [EventsActionTypes.FETCH_REPLIES_SUCCESS]: (state, action) => {
      const commentThread = state.comments.singleCommentThread.data
        ? {
            ...state.comments.singleCommentThread,
            data: {
              ...state.comments.singleCommentThread.data,
              replies:
                state.comments.singleCommentThread.data.id === action.payload.id
                  ? [
                      ...action.payload.data,
                      ...state.comments.singleCommentThread.data.replies
                    ]
                  : state.comments.singleCommentThread.data.replies.reverse()
            }
          }
        : initialState.comments.singleCommentThread

      return {
        ...state,
        isFetchingReplies: false,
        replies: action.payload,
        comments: {
          ...state.comments,
          data: {
            ...state.comments.data,
            data: state.comments.data.data.map(comment => {
              if (comment.id === action.payload.id) {
                return {
                  ...comment,
                  replies: [
                    ...action.payload.data.reverse(),
                    ...comment.replies
                  ]
                }
              } else {
                return comment
              }
            })
          },
          singleCommentThread: commentThread
        }
      }
    },
    [EventsActionTypes.FETCH_REPLIES_FAIL]: state => ({
      ...state,
      isFetchingReplies: false
    }),
    [EventsActionTypes.UPDATE_SHOWN_COMMENTS]: state => ({
      ...state,
      comments: {
        ...state.comments,
        shownComments: state.comments.data.data.slice(
          0,
          state.comments.shownComments.length > 3
            ? state.comments.shownComments.length
            : 3
        )
      }
    }),
    [EventsActionTypes.SHOW_MORE_COMMENTS_REQUEST]: state => ({
      ...state,
      comments: {
        ...state.comments,
        isFetching: true
      }
    }),
    [EventsActionTypes.SHOW_MORE_COMMENTS_SUCCESS]: state => ({
      ...state,
      comments: {
        ...state.comments,
        isFetching: false,
        shownComments: state.comments.data.data.slice(
          0,
          state.comments.shownComments.length + 3
        )
      }
    }),
    [EventsActionTypes.FETCH_COMMENT_THREAD_REQUEST]: state => ({
      ...state,
      comments: {
        ...state.comments,
        isFetching: true
      }
    }),
    [EventsActionTypes.FETCH_COMMENT_THREAD_SUCCESS]: (state, action) => ({
      ...state,
      comments: {
        ...state.comments,
        isFetching: false,
        singleCommentThread: {
          data: {
            ...action.payload.data,
            replies: action.payload.data.replies.reverse()
          },
          reply_id: action.payload.reply_id
        }
      }
    }),
    [EventsActionTypes.FETCH_COMMENT_THREAD_FAIL]: (state, action) => ({
      ...state,
      comments: {
        ...state.comments,
        isFetching: false
      },
      errors: action.payload
    }),
    [EventsActionTypes.CHANGE_INFLUENCER_SEARCH_FIELD]: (state, action) => ({
      ...state,
      influencers: {
        ...state.influencers,
        influencersSearchField: action.payload
      }
    }),
    [EventsActionTypes.CLEAR_INFLUENCER_SEARCH_FIELD]: state => ({
      ...state,
      influencers: {
        ...state.influencers,
        influencersSearchField: ''
      }
    })
  }
)
