import styled from 'styled-components'
import flag from './flags.png'

export default styled.div<{ standalone: boolean }>`
  /*!
 * Generated with CSS Flag Sprite generator (https://www.flag-sprites.com/)
 */

  display: inline-block;
  width: 32px;
  min-width: 2rem;
  height: 32px;
  background: url('${flag}') no-repeat;
  transform: scale(0.65);
  margin-right: ${({ standalone }) => (standalone ? '0 0.5rem' : 0)};

  &.flag-ad {
    background-position: -3px -0px;
    width: 30px;
    height: 30px;
  }
  &.flag-ae {
    background-position: -36px -0px;
    width: 30px;
    height: 30px;
  }
  &.flag-af {
    background-position: -69px -0px;
    width: 30px;
    height: 30px;
  }
  &.flag-ag {
    background-position: -102px -0px;
    width: 30px;
    height: 30px;
  }
  &.flag-ai {
    background-position: -135px -0px;
    width: 30px;
    height: 30px;
  }
  &.flag-al {
    background-position: -168px -0px;
    width: 30px;
    height: 30px;
  }
  &.flag-am {
    background-position: -201px -0px;
    width: 30px;
    height: 30px;
  }
  &.flag-ao {
    background-position: -234px -0px;
    width: 30px;
    height: 30px;
  }
  &.flag-aq {
    background-position: -267px -0px;
    width: 30px;
    height: 30px;
  }
  &.flag-ar {
    background-position: -300px -0px;
    width: 30px;
    height: 30px;
  }
  &.flag-as {
    background-position: -333px -0px;
    width: 30px;
    height: 30px;
  }
  &.flag-at {
    background-position: -366px -0px;
    width: 30px;
    height: 30px;
  }
  &.flag-au {
    background-position: -399px -0px;
    width: 30px;
    height: 30px;
  }
  &.flag-aw {
    background-position: -432px -0px;
    width: 30px;
    height: 30px;
  }
  &.flag-ax {
    background-position: -465px -0px;
    width: 30px;
    height: 30px;
  }
  &.flag-az {
    background-position: -498px -0px;
    width: 30px;
    height: 30px;
  }
  &.flag-ba {
    background-position: -3px -33px;
    width: 30px;
    height: 30px;
  }
  &.flag-bb {
    background-position: -36px -33px;
    width: 30px;
    height: 30px;
  }
  &.flag-bd {
    background-position: -69px -33px;
    width: 30px;
    height: 30px;
  }
  &.flag-be {
    background-position: -102px -33px;
    width: 30px;
    height: 30px;
  }
  &.flag-bf {
    background-position: -135px -33px;
    width: 30px;
    height: 30px;
  }
  &.flag-bg {
    background-position: -168px -33px;
    width: 30px;
    height: 30px;
  }
  &.flag-bh {
    background-position: -201px -33px;
    width: 30px;
    height: 30px;
  }
  &.flag-bi {
    background-position: -234px -33px;
    width: 30px;
    height: 30px;
  }
  &.flag-bj {
    background-position: -267px -33px;
    width: 30px;
    height: 30px;
  }
  &.flag-bl {
    background-position: -300px -33px;
    width: 30px;
    height: 30px;
  }
  &.flag-bm {
    background-position: -333px -33px;
    width: 30px;
    height: 30px;
  }
  &.flag-bn {
    background-position: -366px -33px;
    width: 30px;
    height: 30px;
  }
  &.flag-bo {
    background-position: -399px -33px;
    width: 30px;
    height: 30px;
  }
  &.flag-bq {
    background-position: -432px -33px;
    width: 30px;
    height: 30px;
  }
  &.flag-br {
    background-position: -465px -33px;
    width: 30px;
    height: 30px;
  }
  &.flag-bs {
    background-position: -498px -33px;
    width: 30px;
    height: 30px;
  }
  &.flag-bt {
    background-position: -3px -66px;
    width: 30px;
    height: 30px;
  }
  &.flag-bv {
    background-position: -36px -66px;
    width: 30px;
    height: 30px;
  }
  &.flag-bw {
    background-position: -69px -66px;
    width: 30px;
    height: 30px;
  }
  &.flag-by {
    background-position: -102px -66px;
    width: 30px;
    height: 30px;
  }
  &.flag-bz {
    background-position: -135px -66px;
    width: 30px;
    height: 30px;
  }
  &.flag-ca {
    background-position: -168px -66px;
    width: 30px;
    height: 30px;
  }
  &.flag-cc {
    background-position: -201px -66px;
    width: 30px;
    height: 30px;
  }
  &.flag-cd {
    background-position: -234px -66px;
    width: 30px;
    height: 30px;
  }
  &.flag-cf {
    background-position: -267px -66px;
    width: 30px;
    height: 30px;
  }
  &.flag-cg {
    background-position: -300px -66px;
    width: 30px;
    height: 30px;
  }
  &.flag-ch {
    background-position: -333px -66px;
    width: 30px;
    height: 30px;
  }
  &.flag-ci {
    background-position: -366px -66px;
    width: 30px;
    height: 30px;
  }
  &.flag-ck {
    background-position: -399px -66px;
    width: 30px;
    height: 30px;
  }
  &.flag-cl {
    background-position: -432px -66px;
    width: 30px;
    height: 30px;
  }
  &.flag-cm {
    background-position: -465px -66px;
    width: 30px;
    height: 30px;
  }
  &.flag-cn {
    background-position: -498px -66px;
    width: 30px;
    height: 30px;
  }
  &.flag-co {
    background-position: -3px -99px;
    width: 30px;
    height: 30px;
  }
  &.flag-cr {
    background-position: -36px -99px;
    width: 30px;
    height: 30px;
  }
  &.flag-cu {
    background-position: -69px -99px;
    width: 30px;
    height: 30px;
  }
  &.flag-cv {
    background-position: -102px -99px;
    width: 30px;
    height: 30px;
  }
  &.flag-cw {
    background-position: -135px -99px;
    width: 30px;
    height: 30px;
  }
  &.flag-cx {
    background-position: -168px -99px;
    width: 30px;
    height: 30px;
  }
  &.flag-cy {
    background-position: -201px -99px;
    width: 30px;
    height: 30px;
  }
  &.flag-cz {
    background-position: -234px -99px;
    width: 30px;
    height: 30px;
  }
  &.flag-de {
    background-position: -267px -99px;
    width: 30px;
    height: 30px;
  }
  &.flag-dj {
    background-position: -300px -99px;
    width: 30px;
    height: 30px;
  }
  &.flag-dk {
    background-position: -333px -99px;
    width: 30px;
    height: 30px;
  }
  &.flag-dm {
    background-position: -366px -99px;
    width: 30px;
    height: 30px;
  }
  &.flag-do {
    background-position: -399px -99px;
    width: 30px;
    height: 30px;
  }
  &.flag-dz {
    background-position: -432px -99px;
    width: 30px;
    height: 30px;
  }
  &.flag-ec {
    background-position: -465px -99px;
    width: 30px;
    height: 30px;
  }
  &.flag-ee {
    background-position: -498px -99px;
    width: 30px;
    height: 30px;
  }
  &.flag-eg {
    background-position: -3px -132px;
    width: 30px;
    height: 30px;
  }
  &.flag-eh {
    background-position: -36px -132px;
    width: 30px;
    height: 30px;
  }
  &.flag-er {
    background-position: -69px -132px;
    width: 30px;
    height: 30px;
  }
  &.flag-es {
    background-position: -102px -132px;
    width: 30px;
    height: 30px;
  }
  &.flag-et {
    background-position: -135px -132px;
    width: 30px;
    height: 30px;
  }
  &.flag-fi {
    background-position: -168px -132px;
    width: 30px;
    height: 30px;
  }
  &.flag-fj {
    background-position: -201px -132px;
    width: 30px;
    height: 30px;
  }
  &.flag-fk {
    background-position: -234px -132px;
    width: 30px;
    height: 30px;
  }
  &.flag-fm {
    background-position: -267px -132px;
    width: 30px;
    height: 30px;
  }
  &.flag-fo {
    background-position: -300px -132px;
    width: 30px;
    height: 30px;
  }
  &.flag-fr {
    background-position: -333px -132px;
    width: 30px;
    height: 30px;
  }
  &.flag-ga {
    background-position: -366px -132px;
    width: 30px;
    height: 30px;
  }
  &.flag-gb {
    background-position: -399px -132px;
    width: 30px;
    height: 30px;
  }
  &.flag-gd {
    background-position: -432px -132px;
    width: 30px;
    height: 30px;
  }
  &.flag-ge {
    background-position: -465px -132px;
    width: 30px;
    height: 30px;
  }
  &.flag-gf {
    background-position: -498px -132px;
    width: 30px;
    height: 30px;
  }
  &.flag-gg {
    background-position: -3px -165px;
    width: 30px;
    height: 30px;
  }
  &.flag-gh {
    background-position: -36px -165px;
    width: 30px;
    height: 30px;
  }
  &.flag-gi {
    background-position: -69px -165px;
    width: 30px;
    height: 30px;
  }
  &.flag-gl {
    background-position: -102px -165px;
    width: 30px;
    height: 30px;
  }
  &.flag-gm {
    background-position: -135px -165px;
    width: 30px;
    height: 30px;
  }
  &.flag-gn {
    background-position: -168px -165px;
    width: 30px;
    height: 30px;
  }
  &.flag-gp {
    background-position: -201px -165px;
    width: 30px;
    height: 30px;
  }
  &.flag-gq {
    background-position: -234px -165px;
    width: 30px;
    height: 30px;
  }
  &.flag-gr {
    background-position: -267px -165px;
    width: 30px;
    height: 30px;
  }
  &.flag-gs {
    background-position: -300px -165px;
    width: 30px;
    height: 30px;
  }
  &.flag-gt {
    background-position: -333px -165px;
    width: 30px;
    height: 30px;
  }
  &.flag-gu {
    background-position: -366px -165px;
    width: 30px;
    height: 30px;
  }
  &.flag-gw {
    background-position: -399px -165px;
    width: 30px;
    height: 30px;
  }
  &.flag-gy {
    background-position: -432px -165px;
    width: 30px;
    height: 30px;
  }
  &.flag-hk {
    background-position: -465px -165px;
    width: 30px;
    height: 30px;
  }
  &.flag-hm {
    background-position: -498px -165px;
    width: 30px;
    height: 30px;
  }
  &.flag-hn {
    background-position: -3px -198px;
    width: 30px;
    height: 30px;
  }
  &.flag-hr {
    background-position: -36px -198px;
    width: 30px;
    height: 30px;
  }
  &.flag-ht {
    background-position: -69px -198px;
    width: 30px;
    height: 30px;
  }
  &.flag-hu {
    background-position: -102px -198px;
    width: 30px;
    height: 30px;
  }
  &.flag-id {
    background-position: -135px -198px;
    width: 30px;
    height: 30px;
  }
  &.flag-ie {
    background-position: -168px -198px;
    width: 30px;
    height: 30px;
  }
  &.flag-il {
    background-position: -201px -198px;
    width: 30px;
    height: 30px;
  }
  &.flag-im {
    background-position: -234px -198px;
    width: 30px;
    height: 30px;
  }
  &.flag-in {
    background-position: -267px -198px;
    width: 30px;
    height: 30px;
  }
  &.flag-io {
    background-position: -300px -198px;
    width: 30px;
    height: 30px;
  }
  &.flag-iq {
    background-position: -333px -198px;
    width: 30px;
    height: 30px;
  }
  &.flag-ir {
    background-position: -366px -198px;
    width: 30px;
    height: 30px;
  }
  &.flag-is {
    background-position: -399px -198px;
    width: 30px;
    height: 30px;
  }
  &.flag-it {
    background-position: -432px -198px;
    width: 30px;
    height: 30px;
  }
  &.flag-je {
    background-position: -465px -198px;
    width: 30px;
    height: 30px;
  }
  &.flag-jm {
    background-position: -498px -198px;
    width: 30px;
    height: 30px;
  }
  &.flag-jo {
    background-position: -3px -231px;
    width: 30px;
    height: 30px;
  }
  &.flag-jp {
    background-position: -36px -231px;
    width: 30px;
    height: 30px;
  }
  &.flag-ke {
    background-position: -69px -231px;
    width: 30px;
    height: 30px;
  }
  &.flag-kg {
    background-position: -102px -231px;
    width: 30px;
    height: 30px;
  }
  &.flag-kh {
    background-position: -135px -231px;
    width: 30px;
    height: 30px;
  }
  &.flag-ki {
    background-position: -168px -231px;
    width: 30px;
    height: 30px;
  }
  &.flag-km {
    background-position: -201px -231px;
    width: 30px;
    height: 30px;
  }
  &.flag-kn {
    background-position: -234px -231px;
    width: 30px;
    height: 30px;
  }
  &.flag-kp {
    background-position: -267px -231px;
    width: 30px;
    height: 30px;
  }
  &.flag-kr {
    background-position: -300px -231px;
    width: 30px;
    height: 30px;
  }
  &.flag-kw {
    background-position: -333px -231px;
    width: 30px;
    height: 30px;
  }
  &.flag-ky {
    background-position: -366px -231px;
    width: 30px;
    height: 30px;
  }
  &.flag-kz {
    background-position: -399px -231px;
    width: 30px;
    height: 30px;
  }
  &.flag-la {
    background-position: -432px -231px;
    width: 30px;
    height: 30px;
  }
  &.flag-lb {
    background-position: -465px -231px;
    width: 30px;
    height: 30px;
  }
  &.flag-lc {
    background-position: -498px -231px;
    width: 30px;
    height: 30px;
  }
  &.flag-li {
    background-position: -3px -264px;
    width: 30px;
    height: 30px;
  }
  &.flag-lk {
    background-position: -36px -264px;
    width: 30px;
    height: 30px;
  }
  &.flag-lr {
    background-position: -69px -264px;
    width: 30px;
    height: 30px;
  }
  &.flag-ls {
    background-position: -102px -264px;
    width: 30px;
    height: 30px;
  }
  &.flag-lt {
    background-position: -135px -264px;
    width: 30px;
    height: 30px;
  }
  &.flag-lu {
    background-position: -168px -264px;
    width: 30px;
    height: 30px;
  }
  &.flag-lv {
    background-position: -201px -264px;
    width: 30px;
    height: 30px;
  }
  &.flag-ly {
    background-position: -234px -264px;
    width: 30px;
    height: 30px;
  }
  &.flag-ma {
    background-position: -267px -264px;
    width: 30px;
    height: 30px;
  }
  &.flag-mc {
    background-position: -300px -264px;
    width: 30px;
    height: 30px;
  }
  &.flag-md {
    background-position: -333px -264px;
    width: 30px;
    height: 30px;
  }
  &.flag-me {
    background-position: -366px -264px;
    width: 30px;
    height: 30px;
  }
  &.flag-mf {
    background-position: -399px -264px;
    width: 30px;
    height: 30px;
  }
  &.flag-mg {
    background-position: -432px -264px;
    width: 30px;
    height: 30px;
  }
  &.flag-mh {
    background-position: -465px -264px;
    width: 30px;
    height: 30px;
  }
  &.flag-mk {
    background-position: -498px -264px;
    width: 30px;
    height: 30px;
  }
  &.flag-ml {
    background-position: -3px -297px;
    width: 30px;
    height: 30px;
  }
  &.flag-mm {
    background-position: -36px -297px;
    width: 30px;
    height: 30px;
  }
  &.flag-mn {
    background-position: -69px -297px;
    width: 30px;
    height: 30px;
  }
  &.flag-mo {
    background-position: -102px -297px;
    width: 30px;
    height: 30px;
  }
  &.flag-mp {
    background-position: -135px -297px;
    width: 30px;
    height: 30px;
  }
  &.flag-mq {
    background-position: -168px -297px;
    width: 30px;
    height: 30px;
  }
  &.flag-mr {
    background-position: -201px -297px;
    width: 30px;
    height: 30px;
  }
  &.flag-ms {
    background-position: -234px -297px;
    width: 30px;
    height: 30px;
  }
  &.flag-mt {
    background-position: -267px -297px;
    width: 30px;
    height: 30px;
  }
  &.flag-mu {
    background-position: -300px -297px;
    width: 30px;
    height: 30px;
  }
  &.flag-mv {
    background-position: -333px -297px;
    width: 30px;
    height: 30px;
  }
  &.flag-mw {
    background-position: -366px -297px;
    width: 30px;
    height: 30px;
  }
  &.flag-mx {
    background-position: -399px -297px;
    width: 30px;
    height: 30px;
  }
  &.flag-my {
    background-position: -432px -297px;
    width: 30px;
    height: 30px;
  }
  &.flag-mz {
    background-position: -465px -297px;
    width: 30px;
    height: 30px;
  }
  &.flag-na {
    background-position: -498px -297px;
    width: 30px;
    height: 30px;
  }
  &.flag-nc {
    background-position: -3px -330px;
    width: 30px;
    height: 30px;
  }
  &.flag-ne {
    background-position: -36px -330px;
    width: 30px;
    height: 30px;
  }
  &.flag-nf {
    background-position: -69px -330px;
    width: 30px;
    height: 30px;
  }
  &.flag-ng {
    background-position: -102px -330px;
    width: 30px;
    height: 30px;
  }
  &.flag-ni {
    background-position: -135px -330px;
    width: 30px;
    height: 30px;
  }
  &.flag-nl {
    background-position: -168px -330px;
    width: 30px;
    height: 30px;
  }
  &.flag-no {
    background-position: -201px -330px;
    width: 30px;
    height: 30px;
  }
  &.flag-np {
    background-position: -234px -330px;
    width: 30px;
    height: 30px;
  }
  &.flag-nr {
    background-position: -267px -330px;
    width: 30px;
    height: 30px;
  }
  &.flag-nu {
    background-position: -300px -330px;
    width: 30px;
    height: 30px;
  }
  &.flag-nz {
    background-position: -333px -330px;
    width: 30px;
    height: 30px;
  }
  &.flag-om {
    background-position: -366px -330px;
    width: 30px;
    height: 30px;
  }
  &.flag-pa {
    background-position: -399px -330px;
    width: 30px;
    height: 30px;
  }
  &.flag-pe {
    background-position: -432px -330px;
    width: 30px;
    height: 30px;
  }
  &.flag-pf {
    background-position: -465px -330px;
    width: 30px;
    height: 30px;
  }
  &.flag-pg {
    background-position: -498px -330px;
    width: 30px;
    height: 30px;
  }
  &.flag-ph {
    background-position: -3px -363px;
    width: 30px;
    height: 30px;
  }
  &.flag-pk {
    background-position: -36px -363px;
    width: 30px;
    height: 30px;
  }
  &.flag-pl {
    background-position: -69px -363px;
    width: 30px;
    height: 30px;
  }
  &.flag-pm {
    background-position: -102px -363px;
    width: 30px;
    height: 30px;
  }
  &.flag-pn {
    background-position: -135px -363px;
    width: 30px;
    height: 30px;
  }
  &.flag-pr {
    background-position: -168px -363px;
    width: 30px;
    height: 30px;
  }
  &.flag-ps {
    background-position: -201px -363px;
    width: 30px;
    height: 30px;
  }
  &.flag-pt {
    background-position: -234px -363px;
    width: 30px;
    height: 30px;
  }
  &.flag-pw {
    background-position: -267px -363px;
    width: 30px;
    height: 30px;
  }
  &.flag-py {
    background-position: -300px -363px;
    width: 30px;
    height: 30px;
  }
  &.flag-qa {
    background-position: -333px -363px;
    width: 30px;
    height: 30px;
  }
  &.flag-re {
    background-position: -366px -363px;
    width: 30px;
    height: 30px;
  }
  &.flag-ro {
    background-position: -399px -363px;
    width: 30px;
    height: 30px;
  }
  &.flag-rs {
    background-position: -432px -363px;
    width: 30px;
    height: 30px;
  }
  &.flag-ru {
    background-position: -465px -363px;
    width: 30px;
    height: 30px;
  }
  &.flag-rw {
    background-position: -498px -363px;
    width: 30px;
    height: 30px;
  }
  &.flag-sa {
    background-position: -3px -396px;
    width: 30px;
    height: 30px;
  }
  &.flag-sb {
    background-position: -36px -396px;
    width: 30px;
    height: 30px;
  }
  &.flag-sc {
    background-position: -69px -396px;
    width: 30px;
    height: 30px;
  }
  &.flag-sd {
    background-position: -102px -396px;
    width: 30px;
    height: 30px;
  }
  &.flag-se {
    background-position: -135px -396px;
    width: 30px;
    height: 30px;
  }
  &.flag-sg {
    background-position: -168px -396px;
    width: 30px;
    height: 30px;
  }
  &.flag-sh {
    background-position: -201px -396px;
    width: 30px;
    height: 30px;
  }
  &.flag-si {
    background-position: -234px -396px;
    width: 30px;
    height: 30px;
  }
  &.flag-sj {
    background-position: -267px -396px;
    width: 30px;
    height: 30px;
  }
  &.flag-sk {
    background-position: -300px -396px;
    width: 30px;
    height: 30px;
  }
  &.flag-sl {
    background-position: -333px -396px;
    width: 30px;
    height: 30px;
  }
  &.flag-sm {
    background-position: -366px -396px;
    width: 30px;
    height: 30px;
  }
  &.flag-sn {
    background-position: -399px -396px;
    width: 30px;
    height: 30px;
  }
  &.flag-so {
    background-position: -432px -396px;
    width: 30px;
    height: 30px;
  }
  &.flag-sr {
    background-position: -465px -396px;
    width: 30px;
    height: 30px;
  }
  &.flag-ss {
    background-position: -498px -396px;
    width: 30px;
    height: 30px;
  }
  &.flag-st {
    background-position: -3px -429px;
    width: 30px;
    height: 30px;
  }
  &.flag-sv {
    background-position: -36px -429px;
    width: 30px;
    height: 30px;
  }
  &.flag-sx {
    background-position: -69px -429px;
    width: 30px;
    height: 30px;
  }
  &.flag-sy {
    background-position: -102px -429px;
    width: 30px;
    height: 30px;
  }
  &.flag-sz {
    background-position: -135px -429px;
    width: 30px;
    height: 30px;
  }
  &.flag-tc {
    background-position: -168px -429px;
    width: 30px;
    height: 30px;
  }
  &.flag-td {
    background-position: -201px -429px;
    width: 30px;
    height: 30px;
  }
  &.flag-tf {
    background-position: -234px -429px;
    width: 30px;
    height: 30px;
  }
  &.flag-tg {
    background-position: -267px -429px;
    width: 30px;
    height: 30px;
  }
  &.flag-th {
    background-position: -300px -429px;
    width: 30px;
    height: 30px;
  }
  &.flag-tj {
    background-position: -333px -429px;
    width: 30px;
    height: 30px;
  }
  &.flag-tk {
    background-position: -366px -429px;
    width: 30px;
    height: 30px;
  }
  &.flag-tl {
    background-position: -399px -429px;
    width: 30px;
    height: 30px;
  }
  &.flag-tm {
    background-position: -432px -429px;
    width: 30px;
    height: 30px;
  }
  &.flag-tn {
    background-position: -465px -429px;
    width: 30px;
    height: 30px;
  }
  &.flag-to {
    background-position: -498px -429px;
    width: 30px;
    height: 30px;
  }
  &.flag-tr {
    background-position: -3px -462px;
    width: 30px;
    height: 30px;
  }
  &.flag-tt {
    background-position: -36px -462px;
    width: 30px;
    height: 30px;
  }
  &.flag-tv {
    background-position: -69px -462px;
    width: 30px;
    height: 30px;
  }
  &.flag-tw {
    background-position: -102px -462px;
    width: 30px;
    height: 30px;
  }
  &.flag-tz {
    background-position: -135px -462px;
    width: 30px;
    height: 30px;
  }
  &.flag-ua {
    background-position: -168px -462px;
    width: 30px;
    height: 30px;
  }
  &.flag-ug {
    background-position: -201px -462px;
    width: 30px;
    height: 30px;
  }
  &.flag-um {
    background-position: -234px -462px;
    width: 30px;
    height: 30px;
  }
  &.flag-us {
    background-position: -267px -462px;
    width: 30px;
    height: 30px;
  }
  &.flag-uy {
    background-position: -300px -462px;
    width: 30px;
    height: 30px;
  }
  &.flag-uz {
    background-position: -333px -462px;
    width: 30px;
    height: 30px;
  }
  &.flag-va {
    background-position: -366px -462px;
    width: 30px;
    height: 30px;
  }
  &.flag-vc {
    background-position: -399px -462px;
    width: 30px;
    height: 30px;
  }
  &.flag-ve {
    background-position: -432px -462px;
    width: 30px;
    height: 30px;
  }
  &.flag-vg {
    background-position: -465px -462px;
    width: 30px;
    height: 30px;
  }
  &.flag-vi {
    background-position: -498px -462px;
    width: 30px;
    height: 30px;
  }
  &.flag-vn {
    background-position: -3px -495px;
    width: 30px;
    height: 30px;
  }
  &.flag-vu {
    background-position: -36px -495px;
    width: 30px;
    height: 30px;
  }
  &.flag-wf {
    background-position: -69px -495px;
    width: 30px;
    height: 30px;
  }
  &.flag-ws {
    background-position: -102px -495px;
    width: 30px;
    height: 30px;
  }
  &.flag-xk {
    background-position: -135px -495px;
    width: 30px;
    height: 30px;
  }
  &.flag-ye {
    background-position: -168px -495px;
    width: 30px;
    height: 30px;
  }
  &.flag-yt {
    background-position: -201px -495px;
    width: 30px;
    height: 30px;
  }
  &.flag-za {
    background-position: -234px -495px;
    width: 30px;
    height: 30px;
  }
  &.flag-zm {
    background-position: -267px -495px;
    width: 30px;
    height: 30px;
  }
  &.flag-zw {
    background-position: -300px -495px;
    width: 30px;
    height: 30px;
  }
  .flag-icon {
    display: inline-block !important;
    vertical-align: middle;
    margin-right: 5px;
  }
  .flag-option {
    margin-right: 10px;
  }
  .menu-button {
    margin-right: 8px !important;
    padding-top: 3px !important;
    padding-bottom: 0px !important;
  }
  .menu-button-edit {
    padding: 0 !important;
    margin-right: 0px !important;
    border: 0 !important;
  }
  .flag-menu {
    width: 250px;
  }
`
