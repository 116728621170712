import React from 'react'

const GalleryArrowIcon = styles => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="16"
    viewBox="0 0 24 16"
    fill="#fff"
    {...styles}
  >
    <path
      fill="inherit"
      d="M9.05364 0.516025L8.94271 0.390427C8.46201 -0.0901688 7.7057 -0.127063 7.18269 0.279691L7.05709 0.390622L0.394938 7.05415C-0.0853901 7.53458 -0.122547 8.29038 0.283615 8.81341L0.39439 8.93902L7.05654 15.6103C7.57688 16.1313 8.4211 16.1319 8.94216 15.6116C9.42313 15.1313 9.46062 14.375 9.05428 13.8516L8.94345 13.726L4.55867 9.33333H22.6667C23.3504 9.33333 23.914 8.81861 23.991 8.15549L24 8C24 7.31622 23.4853 6.75266 22.8222 6.67564L22.6667 6.66667H4.55467L8.9429 2.27605C9.4235 1.79535 9.46039 1.03904 9.05364 0.516025L8.94271 0.390427L9.05364 0.516025Z"
    />
  </svg>
)

export default GalleryArrowIcon
