import styled, { css } from 'styled-components'

export const FundCard = styled.div<{
  variant?: 'default' | 'secondary'
}>`
  flex: 0 0 auto;
  position: relative;
  ${p =>
    p.variant === 'secondary' &&
    css`
      height: 1.5rem;
      margin-bottom: 0.5rem;
    `}
`
export const PriceBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
export const ProgressLine = styled.div<{
  value: number
  max: number
  variant?: 'default' | 'secondary'
}>`
  width: 100%;
  background: #fff;
  height: 3px;
  margin-bottom: 1rem;

  :before {
    content: '';
    display: block;
    background: ${p => p.max > 0 && p.theme.colors.gold};
    min-width: ${p => p.value && '0.8rem'};
    width: ${p => (p.value <= p.max ? (p.value * 100) / p.max : 100)}%;
    height: 100%;
  }

  ${p =>
    p.variant === 'secondary' &&
    css`
      height: 1.5rem;
      border-radius: 3.125rem;
      background: #fff;
      margin-bottom: 0;

      :before {
        border-radius: ${p.value >= p.max
          ? '3.125rem'
          : '3.125rem 0 0 3.125rem'};
        background: ${p.max !== 0
          ? p.value >= p.max
            ? 'linear-gradient(180deg, #ffd580 0%, #a18650 100%)'
            : p.theme.colors.gold
          : '#fff'};
      }
    `}
`
export const PriceText = styled.div<{ variant?: string; textVariant?: string }>`
  white-space: nowrap;

  span {
    margin: 0 auto;
    color: #fff;
  }

  ${p =>
    p.variant === 'secondary' &&
    css`
      width: 100%;
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 15%;
      left: 50%;
      transform: translateX(-50%);

      p,
      span {
        color: #333232;
        font-weight: 700;
      }
    `}

  ${p =>
    p.variant === 'secondary' &&
    p.textVariant === 'secondary' &&
    css`
      padding: 0 1.25rem;
      justify-content: space-between;
    `}
`
export const DateWrapper = styled.p`
  display: flex;

  svg {
    margin-right: 0.625rem;
  }
`
export const TotalCollected = styled.p<{ value: number; max: number }>`
  ${p =>
    p.value !== 0 && p.value >= p.max && 'position: absolute; right: 3.125rem;'}
`
export const CheckMarkWrapper = styled.div`
  width: 3.125rem;
  height: 3.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -1.125rem;
  top: -50%;
  z-index: 1;
  background: ${p => p.theme.colors.gold};
  border-radius: 50%;
`
