import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCardInfo,
  selectVerificationInfo,
  selectPersonInfo,
  selectEntityContact,
  selectTrustInfo,
  selectIraInfo,
  selectAddressInfo,
  selectContactInfo,
  selectCompanyInfo
} from 'redux/investment/selectors'
import {
  EntityType,
  IAddress,
  IContact,
  IEntityDocument,
  IIncome,
  ISubscriptionAgreement,
  IVerification
} from 'redux/investment/types'
import { createSubscriptionAgreement } from 'redux/investment/operations'
import { changeInvestmentAddress } from 'redux/investment/actions'
import { selectFormattedAddress } from 'redux/profile/selectors'
import { selectUser } from 'redux/auth/selectors'

const InvestNowContext = createContext({
  eventId: 0,
  setEventId: (arg: any) => {},
  tierId: 0,
  setTierId: (arg: any) => {},
  selectedEntity: EntityType.INDIVIDUAL,
  setSelectedEntity: (arg: EntityType) => {},
  amount: 0,
  setAmount: (arg: number) => {},
  selectedPaymentState: {},
  setSelectedPaymentState: (arg: any) => {},
  selectedContactForm: {} as IContact,
  setSelectedContactForm: (arg: IContact) => {},
  selectedEntityInfo: {},
  setSelectedEntityInfo: (arg: any) => {},
  selectedEntityContactInfo: {} as IContact,
  setSelectedEntityContactInfo: (arg: any) => {},
  isOpenModal: false,
  setIsOpenModal: (arg: boolean) => {},
  verificationInfo: {} as IVerification,
  setVerificationInfo: (arg: IVerification) => {},
  selectedEntityDocument: {} as IEntityDocument,
  setSelectedEntityDocument: (arg: IEntityDocument) => {},
  isACHConfirmed: false,
  setIsACHConfirmed: (arg: boolean) => {},
  isCreditCardConfirmed: false,
  setIsCreditCardConfirmed: (arg: boolean) => {},
  selectedAnnualIncome: {} as IIncome,
  setSelectedAnnualIncome: (arg: IIncome) => {},
  IP: '',
  setIP: (arg: any) => {},
  subscriptionAgreement: {} as ISubscriptionAgreement,
  setSubscriptionAgreement: (arg: any) => {}
})

const InvestNowProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const dispatch = useDispatch()
  const contact = useSelector(selectPersonInfo)
  const isFetchingContactPersonalInfo = useSelector(selectContactInfo)
    .isFetching
  const address = useSelector(selectAddressInfo)
  const currentAddress = useSelector(selectFormattedAddress)
  const entityContact = useSelector(selectEntityContact)
  const trustInfo = useSelector(selectTrustInfo)
  const IRAInfo = useSelector(selectIraInfo)
  const entityInfo = useSelector(selectCompanyInfo)
  const creditCard = useSelector(selectCardInfo)
  const user = useSelector(selectUser)
  const verification = useSelector(selectVerificationInfo)
  const [eventId, setEventId] = useState<number>(0)
  const [tierId, setTierId] = useState(0)
  const [selectedEntity, setSelectedEntity] = useState(EntityType.INDIVIDUAL)
  const [amount, setAmount] = useState<number>(0)
  const [IP, setIP] = useState<string>('')
  const [selectedPaymentState, setSelectedPaymentState] = useState(creditCard)
  const [selectedAnnualIncome, setSelectedAnnualIncome] = useState<IIncome>({
    annual_income: '',
    net_worth: ''
  } as IIncome)
  const [selectedContactForm, setSelectedContactForm] = useState<IContact>(
    contact
  )

  const [isOpenModal, setIsOpenModal] = useState(false)
  const [verificationInfo, setVerificationInfo] = useState(verification)
  // set initial state as company
  const [selectedEntityInfo, setSelectedEntityInfo] = useState({})
  const [selectedEntityDocument, setSelectedEntityDocument] = useState<
    IEntityDocument
  >({} as IEntityDocument)
  const [selectedEntityContactInfo, setSelectedEntityContactInfo] = useState(
    entityContact
  )
  const [isACHConfirmed, setIsACHConfirmed] = useState(false)
  const [isCreditCardConfirmed, setIsCreditCardConfirmed] = useState(false)
  const [subscriptionAgreement, setSubscriptionAgreement] = useState(
    {} as ISubscriptionAgreement
  )
  const isEmptyContext = (obj: { [key: string]: any } | {}) =>
    Object.values(obj).every(c => !c)

  useEffect(() => {
    // initial entity contact form
    const { phone_code, ...entityContactContext } = selectedEntityContactInfo
    if (isEmptyContext(entityContactContext)) {
      setSelectedEntityContactInfo({
        id: entityContact.id,
        first_name: entityContact.first_name,
        last_name: entityContact.last_name,
        email: entityContact.email,
        phone: entityContact.phone,
        phone_code: entityContact.phone_code || '+1'
      })
    }
    // eslint-disable-next-line
  }, [entityContact])

  useEffect(() => {
    setSelectedContactForm({
      // initialContactForm
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      phone: '',
      phone_code: '+1'
    })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (contact.id) {
      setSelectedContactForm({
        id: contact.id,
        first_name: contact.first_name,
        last_name: contact.last_name,
        email: contact.email,
        phone: contact.phone,
        phone_code: contact.phone_code
      })
    }
    // eslint-disable-next-line
  }, [contact])

  useEffect(() => {
    dispatch(createSubscriptionAgreement(subscriptionAgreement))
    // eslint-disable-next-line
  }, [subscriptionAgreement])

  useEffect(() => {
    const {
      place_id,
      // tslint:disable-next-line:variable-name
      room: additional_address,
      // tslint:disable-next-line:variable-name
      zip: postal_code,
      ...rest
    } = currentAddress

    if (place_id && !isFetchingContactPersonalInfo && !address.street) {
      dispatch(
        changeInvestmentAddress({
          ...rest,
          postal_code,
          additional_address
        } as Required<IAddress>)
      )
    }

    if (address.address) {
      dispatch(
        changeInvestmentAddress({
          ...address
        })
      )
    }
    // eslint-disable-next-line
  }, [user, currentAddress])

  useEffect(() => {
    const { trust_name, trust_tax_id } = trustInfo
    if (isEmptyContext(selectedEntityInfo)) {
      setSelectedEntityInfo({
        trust_name,
        trust_tax_id
      })
    }
    // eslint-disable-next-line
  }, [trustInfo])

  useEffect(() => {
    const { vesting_name, ira_tax_id } = IRAInfo
    if (isEmptyContext(selectedEntityInfo)) {
      setSelectedEntityInfo({
        ...IRAInfo,
        vesting_name,
        ira_tax_id
      })
    }
    // eslint-disable-next-line
  }, [IRAInfo])

  useEffect(() => {
    const { entity_name, entity_tax_id, state } = entityInfo
    if (isEmptyContext(selectedEntityInfo)) {
      setSelectedEntityInfo({
        entity_name,
        entity_tax_id,
        state
      })
    }
    // eslint-disable-next-line
  }, [entityInfo])

  return (
    <InvestNowContext.Provider
      value={{
        eventId,
        setEventId,
        selectedPaymentState,
        setSelectedPaymentState,
        selectedContactForm,
        setSelectedContactForm,
        isOpenModal,
        setIsOpenModal,
        verificationInfo,
        setVerificationInfo,
        selectedEntity,
        setSelectedEntity,
        selectedEntityInfo,
        setSelectedEntityInfo,
        selectedEntityContactInfo,
        setSelectedEntityContactInfo,
        selectedEntityDocument,
        setSelectedEntityDocument,
        amount,
        setAmount,
        isACHConfirmed,
        setIsACHConfirmed,
        isCreditCardConfirmed,
        setIsCreditCardConfirmed,
        tierId,
        setTierId,
        selectedAnnualIncome,
        setSelectedAnnualIncome,
        IP,
        setIP,
        subscriptionAgreement,
        setSubscriptionAgreement
      }}
    >
      {children}
    </InvestNowContext.Provider>
  )
}

function useInvestNowContext() {
  return useContext(InvestNowContext)
}

export { useInvestNowContext, InvestNowProvider }
