import React from 'react'

export const TrashIcon = () => {
  return (
    <svg
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 5.75H21"
        stroke="#211F1E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 10.75V18.25"
        stroke="#211F1E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 10.75V18.25"
        stroke="#211F1E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.25 5.75L3.5 20.75C3.5 21.413 3.76339 22.0489 4.23223 22.5178C4.70107 22.9866 5.33696 23.25 6 23.25H16C16.663 23.25 17.2989 22.9866 17.7678 22.5178C18.2366 22.0489 18.5 21.413 18.5 20.75L19.75 5.75"
        stroke="#211F1E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.25 5.75V2C7.25 1.66848 7.3817 1.35054 7.61612 1.11612C7.85054 0.881696 8.16848 0.75 8.5 0.75H13.5C13.8315 0.75 14.1495 0.881696 14.3839 1.11612C14.6183 1.35054 14.75 1.66848 14.75 2V5.75"
        stroke="#211F1E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
