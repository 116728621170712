import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import Link from 'components/UI/Link'

export const WrapperBlock = styled.div.attrs(() => ({
  'data-testid': 'tier-block',
  id: 'tier-block'
}))`
  width: 100%;
  min-height: 17.5rem;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  text-overflow: ellipsis;

  ${media.md`
    min-height: 20rem;
    padding-left: 1rem;
    padding-right: 1rem;
  `}

  ${media.lg`
    padding-left: 2rem;
    padding-right: 2rem;
  `}
`

export const DescText = styled.p`
  display: none;
  font-family: Inter;
  font-size: 0.875rem;
  font-weight: 300;
  color: ${p => p.theme.colors.black};
  height: 130px;
  overflow: hidden;
  ${media.lg`
    font-size: 1rem;
  `}
  ${media.xl`
    display: block;
  `}
`

export const DescTextMobile = styled(DescText)`
  display: block;
  ${media.xl`
    display: none;
  `}
`

export const TierInfo = styled.div.attrs(() => ({
  'data-testid': 'tier-info-block'
}))``

export const TierTitle = styled.h3`
  margin-bottom: 0.375rem;
  font-size: 1.125rem;
  font-weight: 900;
  font-family: Inter;
  min-height: 4.1875rem;
  overflow: hidden;
  color: ${p => p.theme.colors.black};
  line-height: 1.375rem;
  text-overflow: ellipsis;

  ${media.md`
    color: #353535;
    font-size: 1.5rem;
  `}
`

export const InvestNowLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 0.75rem;
  background: ${p => p.theme.colors.fuchsia};
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  color: ${p => p.theme.colors.white};
  text-align: center;
  text-decoration: none;
  border-radius: 0.4rem;
  width: 100%;
  height: 43px;
  margin: 0 auto;
  line-height: 1.1875rem;

  ${media.sm`
    width: 144px;
    margin: 0;
  `}
`
