import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export const LogoWrapper = styled.div`
  img {
    ${media.xs`
      width: 170px;
    `}

    ${media.md`
      width: 220px;
    `}
  }
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
  }
`
