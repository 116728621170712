import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import Button from 'components/UI/Button'

export const PopUpBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`
export const PopUpButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-bottom: 12px;

  button {
    margin: 0 0.5rem;
    min-width: 40%;
  }
  ${media.md`
      padding-bottom: 25px;
    `}
`
export const PopUpText = styled.p`
  margin-bottom: 2rem;
  font-size: 1rem;
  color: ${p => p.theme.colors.black};
`

export const CloseButton = styled(Button).attrs(() => ({
  variant: 'clear'
}))`
  position: absolute;
  top: -10%;
  right: 0;
`
