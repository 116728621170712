import { createStore, applyMiddleware } from 'redux'
import ReduxThunk from 'redux-thunk'
import { History } from 'history'
import { Store, AppState, createRootReducer } from 'store'

export default function configureStore(
  history: History,
  initialState?: AppState
): Store {
  const middleWares = [ReduxThunk]
  const enhancer = [applyMiddleware(...middleWares)]

  return createStore(createRootReducer(history), initialState, ...enhancer)
}
