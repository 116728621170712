import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

import { PaymentSystem } from 'redux/checkout/types'

export const PaymentMethodsWrapper = styled.div`
  position: relative;
  padding-top: 2.25rem;
  background: ${p => p.theme.colors.darkSmoky};
`
export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.3;
  z-index: 101;
`

export const FormTitle = styled.h3`
  font-family: 'Roboto';
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1.5rem;
  line-height: 2.5rem;
  padding: 0 calc(10vw - 1rem) 2.0625rem calc(10vw - 1rem);

  ${media.sm`
    padding: 0 2.5rem 2.0625rem 2.5rem;
    font-size: 1.875rem;
    line-height: 3.5rem;
  `}
`

export const FieldsHolder = styled.div`
  display: block;
`

export const Icon = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1.4375rem;
`
export const TabHeadContainer = styled.div`
  padding: 0 calc(10vw - 1rem) 84px calc(10vw - 1rem);

  ${media.sm`
    padding: 0 2.5rem 84px 2.5rem;
  `}
`

interface ITabProps {
  active?: boolean
  dataTab: PaymentSystem
}
export const TabButton = styled.button.attrs<ITabProps>(({ dataTab }) => ({
  'data-tab': dataTab
}))`
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 50px;
  background: transparent;
  outline: 0;
  max-width: 363px;
  width: 100%;
  border: ${(p: ITabProps) =>
    p.active ? '1px solid #D4AB72' : '1px solid #666666'};
  padding: 0.625rem 1.5625rem;
  margin-bottom: 1.25rem;

  span {
    display: inline-block;
    font-family: 'Acumin Pro';
    color: ${(p: ITabProps) => (p.active ? '#D4AB72' : '#fff')};
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
`
