import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export const ModalHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: inherit;
  padding: 17px 20px;
  ${media.md`
    padding: 25px 35px;
  `}
  border-radius: 12px 12px 0 0;
`
export const ModalBody = styled.section<{
  bodyOverflow?: boolean
}>`
  overflow: auto; //if overlay doesn't work
  overflow: ${p => (p.bodyOverflow ? 'initial' : 'overlay')};
  padding: 0 20px;
  scrollbar-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar,
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    background-color: rgba(0, 0, 0, 0);
  }
  ${media.md`
    padding: 0 35px;
  `}
`
export const ModalFooter = styled.section<{
  backgroundFooter?: string
}>`
  border-radius: 0 0 12px 12px;
  background-color: ${p => p.backgroundFooter && p.backgroundFooter};
`

export const CloseWrapper = styled.div<{
  hideControl?: boolean
  closeButtonColor?: string
}>`
  display: ${p => (p.hideControl ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
  cursor: pointer;

  height: 32px;
  max-width: 32px;
  width: 100%;
  background-color: ${p =>
    p.closeButtonColor ? p.closeButtonColor : p.theme.colors.white};
  border-radius: 50%;

  ${media.md`
    height: 36px;
    width: 100%;
    max-width: 36px;
  `}
`
