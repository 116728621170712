import React from 'react'
import { EventSectionItems, EventSectionType } from 'redux/events/types'
import { Gallery, Team } from 'components/invest'

import {
  Description,
  SectionSubtitle
} from 'containers/invest/InvestDetailPage/styles'

import {
  DescriptionSectionContentWithPicture,
  DescriptionSectionPicture
} from './styles'

const renderSection = (type: EventSectionType, items: any[]) => {
  switch (type) {
    case EventSectionType.DESCRIPTION:
      return (
        <Description>
          {items.map((p: EventSectionItems) => (
            <div key={p.title}>
              <SectionSubtitle>{p.title}</SectionSubtitle>
              {p.picture ? (
                <DescriptionSectionContentWithPicture reverse={p.direction}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: p.description || ''
                    }}
                  />
                  <DescriptionSectionPicture
                    size={p.direction}
                    src={p.picture}
                    alt="Description picture"
                  />
                </DescriptionSectionContentWithPicture>
              ) : (
                <div
                  dangerouslySetInnerHTML={{ __html: p.description || '' }}
                />
              )}
            </div>
          ))}
        </Description>
      )

    case EventSectionType.GALLERY:
      return <Gallery data={items} />

    case EventSectionType.TEAM:
      return <Team data={items} />

    default:
      return null
  }
}

export { renderSection }
