import React, { FC, ReactNode, useEffect, useState } from 'react'
import branch from 'branch-sdk'
import { routePath } from 'routes'
import { useHistory } from 'react-router-dom'
import AnalyticsManager from 'services/analyticService'
import { IEventDetail } from 'redux/events/types'
import SocialShareButton from 'src/components/UI/SocialButtons/ShareButton'

interface Props {
  event: IEventDetail
  children?: ReactNode
  isAuthenticated?: boolean
  userId?: number
}

const DetailShareButton: FC<Props> = ({
  event,
  children,
  isAuthenticated,
  userId
}) => {
  const [shareLink, setShareLink] = useState('')
  const history = useHistory()
  useEffect(() => {
    if (isAuthenticated) {
      branch.link(
        {
          data: {
            $canonical_identifier: String(event.event_id),
            $og_title: event.event_title,
            $og_description:
              "Hey, I've discovered a really cool project on FanVestor. Come check it out!",
            $og_image_url: event.event_picture,
            share_type: 'event',
            user_who_shared: userId
          }
        },
        (err, link) => {
          setShareLink(link || '')
        }
      )
    }
  }, [event, userId, isAuthenticated])

  const handleShareButton = () => {
    if (!isAuthenticated) {
      history.push({
        pathname: routePath.SIGN_IN,
        state: { from: history.location.pathname }
      })
    }
    return AnalyticsManager.trackShareEvent(event.event_id)
  }

  return (
    <SocialShareButton url={shareLink} onClick={handleShareButton}>
      {children}
    </SocialShareButton>
  )
}

export default DetailShareButton
