import React, { FC } from 'react'
import { daysRemaining, timeRemaining, showHoursLeft } from 'utils/dateFormats'
import { displayStatus } from 'utils/helpers'
import { Statuses } from 'redux/commonTypes'
import { CardIconsWrapper } from './../styles'

const DaysLeft: FC<{
  eventStatus: Statuses
  hoursLeft: number
  showHours?: boolean
}> = ({ eventStatus, hoursLeft, showHours = false }) => {
  const numberOfDays = daysRemaining(hoursLeft)
  const isLive = eventStatus === Statuses.LIVE
  const statusIndicator = isLive ? numberOfDays : -1
  const showEndDate =
    showHours && hoursLeft < 24
      ? showHoursLeft(hoursLeft)
      : timeRemaining(hoursLeft)

  return (
    <>
      {showEndDate && (
        <CardIconsWrapper num={statusIndicator}>
          <p>{isLive ? showEndDate + ' left' : displayStatus(eventStatus)}</p>
        </CardIconsWrapper>
      )}
    </>
  )
}

export default DaysLeft
