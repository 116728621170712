import { createReducer } from 'typesafe-actions'
import { AuthState, AuthAction, AuthActionTypes, IUser } from './types'

export const authInitialState: AuthState = {
  isFetchingLoggedIn: false,
  isAuthenticated: false,
  isRegistered: false,
  isForceChangePassword: false,
  user: {} as IUser,
  accessToken: '',
  preferences: [],
  errors: {} as Error,
  showModal: false,
  modalMessage: '',
  betablockAccess: false,
  betablockParams: {}
}

export const authReducer = createReducer<AuthState, AuthAction>(
  authInitialState,
  {
    [AuthActionTypes.LOGIN_REQUEST]: state => ({
      ...state,
      isFetchingLoggedIn: true,
      isAuthenticated: false
    }),
    [AuthActionTypes.LOGIN_SUCCESS]: (state, action) => ({
      ...state,
      isFetchingLoggedIn: false,
      isAuthenticated: true,
      user: action.payload.user
    }),
    [AuthActionTypes.REGISTER_REQUEST]: state => ({
      ...state,
      isFetchingLoggedIn: true,
      isAuthenticated: false,
      isRegistered: false
    }),
    [AuthActionTypes.REGISTER_SUCCESS]: (state, action) => ({
      ...state,
      accessToken: action.payload.accessToken,
      isFetchingLoggedIn: false,
      isAuthenticated: false,
      isRegistered: true
    }),
    [AuthActionTypes.REGISTER_FAIL]: (state, action) => ({
      ...state,
      isFetchingLoggedIn: false,
      isAuthenticated: false,
      isRegistered: false,
      errors: action.payload
    }),
    [AuthActionTypes.VERIFY_REQUEST]: state => ({
      ...state,
      isFetchingLoggedIn: true,
      isAuthenticated: false
    }),
    [AuthActionTypes.VERIFY_SUCCESS]: (state, action) => ({
      ...state,
      isFetchingLoggedIn: false,
      isAuthenticated: true,
      user: action.payload.user,
      accessToken: action.payload.accessToken,
      preferences: action.payload.preferences,
      isForceChangePassword: action.payload.forceChangePassword || false
    }),
    [AuthActionTypes.VERIFY_FAIL]: (state, action) => ({
      ...state,
      isFetchingLoggedIn: false,
      isAuthenticated: false,
      errors: action.payload
    }),
    [AuthActionTypes.RESEND_REQUEST]: state => ({
      ...state,
      isFetchingLoggedIn: true
    }),
    [AuthActionTypes.RESEND_SUCCESS]: state => ({
      ...state,
      isFetchingLoggedIn: false
    }),
    [AuthActionTypes.RESEND_FAIL]: state => ({
      ...state,
      isFetchingLoggedIn: false
    }),
    [AuthActionTypes.BETABLOCK_LOGIN_SUCCESS]: (state, action) => ({
      ...state,
      betablockAccess: action.payload
    }),
    [AuthActionTypes.FORGOT_REQUEST]: state => ({
      ...state,
      isFetchingLoggedIn: true
    }),
    [AuthActionTypes.FORGOT_SUCCESS]: state => ({
      ...state,
      isFetchingLoggedIn: false
    }),
    [AuthActionTypes.FORGOT_FAIL]: state => ({
      ...state,
      isFetchingLoggedIn: false
    }),
    [AuthActionTypes.SOCIAL_LOGIN_REQUEST]: state => ({
      ...state,
      isFetchingLoggedIn: true,
      isAuthenticated: false
    }),
    [AuthActionTypes.SOCIAL_LOGIN_SUCCESS]: (state, action) => ({
      ...state,
      isFetchingLoggedIn: false,
      isAuthenticated: true,
      user: action.payload.userData.user,
      accessToken: action.payload.userData.accessToken,
      isForceChangePassword: action.payload.isVerified
    }),
    [AuthActionTypes.SOCIAL_LOGIN_FAIL]: (state, action) => ({
      ...state,
      isFetchingLoggedIn: false,
      isAuthenticated: false,
      errors: action.payload
    }),
    [AuthActionTypes.PROFILE_SETTINGS_SUCCESS]: (state, action) => ({
      ...state,
      isAuthenticated: true,
      user: action.payload
    }),
    [AuthActionTypes.LOGIN_FAIL]: (state, action) => ({
      ...state,
      isFetchingLoggedIn: false,
      isAuthenticated: false,
      errors: action.payload
    }),
    [AuthActionTypes.LOGOUT]: state => ({
      ...state,
      isAuthenticated: false,
      user: authInitialState.user
    }),
    [AuthActionTypes.SET_AUTHENTICATED]: state => ({
      ...state,
      isAuthenticated: true
    }),
    [AuthActionTypes.CLEAR_ERRORS]: state => ({
      ...state,
      errors: authInitialState.errors
    }),
    [AuthActionTypes.FORGOT_FAIL]: (state, action) => ({
      ...state,
      isFetchingLoggedIn: false,
      isAuthenticated: false,
      errors: action.payload
    }),
    [AuthActionTypes.RECOVERY_PASSWORD_REQUEST]: state => ({
      ...state,
      isFetchingLoggedIn: true
    }),
    [AuthActionTypes.RECOVERY_PASSWORD_SUCCESS]: state => ({
      ...state,
      isFetchingLoggedIn: false,
      isAuthenticated: false,
      isForceChangePassword: false
    }),
    [AuthActionTypes.RECOVERY_PASSWORD_FAIL]: state => ({
      ...state,
      isFetchingLoggedIn: false
    }),
    [AuthActionTypes.CHANGE_PASSWORD_SUCCESS]: (state, action) => ({
      ...state,
      accessToken: action.payload.accessToken
    }),
    [AuthActionTypes.CHANGE_PASSWORD_FAIL]: (state, action) => ({
      ...state,
      errors: action.payload
    }),
    [AuthActionTypes.SET_PROFILE_PASSWORD_ERRORS]: (state, action) => ({
      ...state,
      errors: action.payload.fields
    }),
    [AuthActionTypes.SET_BETABLOCK_PARAMS]: (state, action) => ({
      ...state,
      betablockParams: action.payload
    }),
    [AuthActionTypes.SHOW_MODAL]: (state, action) => ({
      ...state,
      showModal: true,
      modalMessage: action.payload
    }),
    [AuthActionTypes.HIDE_MODAL]: state => ({
      ...state,
      showModal: false,
      modalMessage: ''
    }),
    [AuthActionTypes.SET_TOKEN]: (state, action) => ({
      ...state,
      accessToken: action.payload
    })
  }
)
