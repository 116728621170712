import React, { useState, useEffect, FC } from 'react'
import { Link } from './styles'

const MenuItem: FC<{ itemName: string; active: boolean; title: string }> = ({
  itemName,
  active,
  title
}) => {
  const [anchorTarget, setAnchorTarget] = useState<HTMLElement | null>(null)

  useEffect(() => {
    setAnchorTarget(document.getElementById(itemName))
  }, [itemName])

  const handleClick = (event: any) => {
    event.preventDefault()

    const coordTarget = anchorTarget!.getBoundingClientRect().top
    const topScroll = window.pageYOffset - 150

    window.scrollTo({
      top: coordTarget + topScroll,
      behavior: 'smooth'
    })
  }

  return (
    <Link href={`#${itemName}`} onClick={handleClick} active={active}>
      {title}
    </Link>
  )
}

export default MenuItem
