import React from 'react'

const TrashIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="20"
    viewBox="0 0 17 20"
  >
    <path
      fill="#fff"
      d="M13.888 2.396a2.287 2.287 0 012.285 2.285v.117c0 .963-.6 1.787-1.444 2.123v10.794A2.288 2.288 0 0112.444 20H3.73a2.288 2.288 0 01-2.285-2.285V6.921A2.287 2.287 0 010 4.798v-.117a2.287 2.287 0 012.285-2.285h3.113A2.71 2.71 0 018.087 0a2.71 2.71 0 012.688 2.396zm-7.395 0H9.68a1.626 1.626 0 00-1.593-1.312c-.79 0-1.448.565-1.594 1.312zm7.152 4.687H2.528v10.632c0 .662.538 1.201 1.2 1.201h8.716c.663 0 1.202-.539 1.202-1.201zm1.444-2.285v-.117c0-.662-.538-1.201-1.2-1.201H2.284c-.662 0-1.201.539-1.201 1.201v.117c0 .663.539 1.202 1.2 1.202h11.604c.662 0 1.201-.54 1.201-1.202z"
    />
    <path
      fill="#fff"
      d="M5.182 17.534a.542.542 0 00.542-.542v-6.1a.542.542 0 00-1.084 0v6.1c0 .3.243.542.542.542zM8.082 17.534a.542.542 0 00.542-.542v-6.1a.542.542 0 00-1.084 0v6.1c0 .3.243.542.542.542zM10.992 17.534a.542.542 0 00.542-.542v-6.1a.542.542 0 00-1.084 0v6.1c0 .3.243.542.542.542z"
    />
  </svg>
)

export default TrashIcon
