import React, { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Media from 'react-media'

import { routePath } from 'routes'

import { selectCart, selectCartItemsSubtotal } from 'redux/checkout/selectors'
import { formatMoney } from 'utils/dateFormats'
import Button from 'components/UI/Button'
import { EmptyCartIcon } from './EmptyCartIcon'
import { ShoppingCartItem } from './ShoppingCartItem'
import {
  ShoppingCartWrapper,
  ShoppingCartHeader,
  ShoppingCartBody,
  ShoppingCartFooter,
  EmptyCartContent,
  IconInCircle,
  Subtotal
} from './styles'

interface ShoppingCartProps {
  onClose: () => void
}
const ShoppingCart: React.FC<ShoppingCartProps> = ({
  onClose
}): React.ReactElement => {
  const history = useHistory()

  const cart = useSelector(selectCart)
  const subtotal = useSelector(selectCartItemsSubtotal)
  const isEmpty = subtotal === 0

  const cartRef = useRef() as React.MutableRefObject<HTMLInputElement>

  const onButtonClick = () => {
    if (isEmpty) {
      history.push(routePath.EVENTS_PAGE)
    } else {
      history.push(routePath.CHECKOUT_CART)
    }

    onClose()
  }

  useEffect(() => {
    const handleOnOut = (e: any) => {
      const path = e.path! || (e.composedPath && e.composedPath())
      if (!path.includes(cartRef.current)) {
        onClose()
      }
    }

    document.body.addEventListener('click', handleOnOut)
    return () => {
      document.body.removeEventListener('click', handleOnOut)
    }
  }, [cartRef, onClose])

  return (
    <ShoppingCartWrapper ref={cartRef}>
      <ShoppingCartHeader>
        <h2>Your Cart</h2>
        <Button variant="icon" onClick={onClose}>
          <IconInCircle width="40px">
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.445 1.55554L1.55615 10.4444M1.55615 1.55554L10.445 10.4444L1.55615 1.55554Z"
                stroke="#171716"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconInCircle>
        </Button>
      </ShoppingCartHeader>
      <ShoppingCartBody isEmpty={isEmpty}>
        {isEmpty ? (
          <Media
            queries={{
              small: '(max-width: 576px)',
              medium: '(min-width: 577px)'
            }}
          >
            {matches => (
              <EmptyCartContent>
                {matches.small && <EmptyCartIcon width={360} height={247} />}
                {matches.medium && <EmptyCartIcon />}
                <h5>Your cart is empty</h5>
              </EmptyCartContent>
            )}
          </Media>
        ) : (
          <>
            {cart.items.map(item =>
              item.sets.map(set => (
                <ShoppingCartItem
                  key={set.tier_set_id}
                  set={set}
                  item={item}
                  count={set.quantity}
                  maxItemCount={set.left + set.quantity}
                />
              ))
            )}
          </>
        )}
      </ShoppingCartBody>
      <ShoppingCartFooter isEmpty={isEmpty}>
        <Subtotal>
          Subtotal: <span>${formatMoney(subtotal, 2)}</span>
        </Subtotal>
        <Button variant="shop-black-contained" onClick={onButtonClick}>
          {isEmpty ? 'Continue Shopping' : 'Proceed to Checkout'}
        </Button>
      </ShoppingCartFooter>
    </ShoppingCartWrapper>
  )
}

export default ShoppingCart
