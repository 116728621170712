import React, { FC } from 'react'
import { generatePath } from 'react-router-dom'
import routePath from 'routes/routePath'
import { formatDateWithTime, formatMoney } from 'utils/dateFormats'
import { getBaseUrl } from 'utils/helpers'
import { UserInvestment } from 'redux/profile/types'
import Img from 'components/UI/Img'
import GenericModal from 'src/components/UI/GenericModal'
import CloseIcon from 'src/components/icons/CloseIcon'

import {
  ButtonWrapper,
  CloseButton,
  Date,
  Details,
  ImageWrapper,
  Info,
  InfoTitle,
  LinkDetails,
  Status,
  TierTitle,
  Wrapper,
  WrapperTitle
} from 'components/profile/dashboard/styles'

interface Props {
  investment: UserInvestment
  isOpen: boolean
  onClose: () => void
}

const InvestmentDetails: FC<Props> = ({ investment, isOpen, onClose }) => {
  const {
    id,
    created_at,
    amount,
    internal_status,
    tier: {
      description,
      event_id,
      event: {
        title: eventTitle = '',
        picture = '',
        influencer: { influencer_data: { influencer_name = '' } = {} } = {}
      } = {}
    }
  } = investment
  const investmentAmount = formatMoney(+amount, 2)
  const baseUrl = getBaseUrl()
  const imageSource = `${baseUrl}/storage/${picture}`

  return (
    <GenericModal
      isOpen={isOpen}
      overlayStyle={{ background: 'rgba(0,0,0,0.8)' }}
      style={{ maxWidth: '700px', background: 'none' }}
    >
      <Details>
        <WrapperTitle>
          <TierTitle>Transaction #{id}</TierTitle>
          <Date>Placed on {formatDateWithTime(created_at)}</Date>
        </WrapperTitle>
        <Status>
          <InfoTitle>Investment Status</InfoTitle>
          <Info>{internal_status}</Info>
        </Status>
        <InfoTitle>Investment Details</InfoTitle>
        <Wrapper id={'title-and-amount'}>
          <ImageWrapper>
            <Img src={imageSource} alt={eventTitle} />
            <div>
              <TierTitle>{influencer_name}</TierTitle>
              <LinkDetails
                to={generatePath(routePath.INVEST_TIER_PAGE, {
                  id: event_id,
                  item_id: id
                })}
              >
                {eventTitle}
              </LinkDetails>
            </div>
          </ImageWrapper>
          <TierTitle>${investmentAmount}</TierTitle>
        </Wrapper>
        <Date>
          <span>Perks:</span> {description}
        </Date>
        <ButtonWrapper>
          <CloseButton onClick={onClose}>
            <CloseIcon /> Close{' '}
          </CloseButton>
        </ButtonWrapper>
      </Details>
    </GenericModal>
  )
}

export default InvestmentDetails
