import React, { ReactNode, MouseEvent } from 'react'
import {
  ClearButton,
  DefaultButton,
  Loading,
  SecondaryButton,
  TextButton,
  IconButton
} from './styles'

export interface ButtonProps {
  children?: ReactNode
  variant?: 'default' | 'clear' | 'text' | 'icon'
  disabled?: boolean
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void
  type?: 'submit' | 'button'
  style?: React.CSSProperties
  isLoading?: boolean
}

export enum ButtonVariant {
  Default = 'default',
  Clear = 'clear',
  Secondary = 'secondary',
  Text = 'text',
  Icon = 'icon'
}

const Button: React.FC<ButtonProps> = ({
  variant = ButtonVariant.Default,
  type = 'button',
  children,
  disabled = false,
  onClick = () => {},
  isLoading = false,
  ...props
}): JSX.Element => {
  const onClickBtn = (e: MouseEvent<HTMLButtonElement>): void => {
    if (disabled) {
      e.preventDefault()
      return
    }

    onClick(e)
  }

  const components = {
    [ButtonVariant.Default]: DefaultButton,
    [ButtonVariant.Clear]: ClearButton,
    [ButtonVariant.Secondary]: SecondaryButton,
    [ButtonVariant.Text]: TextButton,
    [ButtonVariant.Icon]: IconButton
  }

  const Component = components[variant]

  return (
    <Component
      onClick={onClickBtn}
      disabled={disabled}
      variant={variant}
      type={type}
      {...props}
    >
      {!isLoading ? (
        <>{children}</>
      ) : (
        <Loading>
          <span>&bull;</span>
          <span>&bull;</span>
          <span>&bull;</span>
        </Loading>
      )}
    </Component>
  )
}

export default Button

export { Button, DefaultButton, ClearButton, SecondaryButton }
