import React, { FC } from 'react'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { routePath } from 'routes'
import { CogIcon } from 'components/icons'
import Logo from 'components/UI/Logo'

import { HeaderContent, Header, Option } from './styles'

const BrandHeader: FC<{ photo: string; isShown?: boolean; logout?: any }> = ({
  photo
}) => {
  return (
    <Header img={photo}>
      <Container>
        <Row>
          <Col>
            <HeaderContent>
              <Logo />
              <Option to={routePath.PROFILE_SETTINGS}>
                <CogIcon />
              </Option>
            </HeaderContent>
          </Col>
        </Row>
      </Container>
    </Header>
  )
}

export default BrandHeader
