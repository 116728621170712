import React, { FC, ReactNode } from 'react'
import { Container } from 'styled-bootstrap-grid'
import Header from '../Header'
import Footer from '../Footer'

import { HeaderWrapper, ContentWrapper, BodyWrapper } from './styles'

interface WrapperProps {
  header: ReactNode
  children: ReactNode
  inFlow?: boolean
}

const CustomTemplate: FC<WrapperProps> = props => {
  const { header, children, inFlow = true } = props

  return (
    <div className="wrapper">
      <div className="content">
        <ContentWrapper inFlow={inFlow}>
          <HeaderWrapper>
            <Header />
            <Container style={{ zIndex: 1 }}>{header}</Container>
          </HeaderWrapper>
          <BodyWrapper>{children}</BodyWrapper>
        </ContentWrapper>
      </div>
      <Footer />
    </div>
  )
}

export default CustomTemplate
