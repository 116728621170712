import React from 'react'
import { Col, Row } from 'styled-bootstrap-grid'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
// @ts-ignore
import { Braintree } from 'react-braintree-fields'

import AnalyticsManager from 'services/analyticService'
import { useDevice } from 'utils/helpers'
import {
  checkout,
  checkoutExperience,
  fetchBraintreeCartToken,
  isValidAddressInfo
} from 'redux/checkout/operations'
import {
  selectEventsDetail,
  selectEventsIsFetching
} from 'redux/events/selectors'
import {
  isValidProductSpecificSelector,
  selectBuyNowGuestCheckout,
  selectBuyNowTier,
  selectBuyNowTierQuantity,
  selectCheckoutBraintreeToken,
  selectCheckoutError,
  selectCheckoutProcessing,
  selectCustomFields,
  selectShipping
} from 'redux/checkout/selectors'
import {
  clearCheckoutErrors,
  finishPaymentProcessing,
  startPaymentProcessing
} from 'redux/checkout/actions'
import { selectIsAuth } from 'redux/auth/selectors'

import Button from 'components/UI/Button'
import CardSection from '../CardSection'

import {
  braintreeStyles,
  CreditCardForm,
  CustomErrorLabel,
  FooterCard
} from './styles'

import {
  Currency,
  ICustomField,
  Intent,
  PaymentSystem
} from 'redux/checkout/types'
import { EventSubType } from 'redux/events/types'
import { AppState, Dispatch } from 'store/types'
import { history, routePath } from 'routes'

interface MatchParams {
  id: string
  tier: string
}
export interface ICustomFields {
  name: string
}
interface State {
  customFields: ICustomFields
  customErrors: ICustomFields
  authorization: string
  cardInfo: any
  fields: {
    number: any
    expirationDate: any
    cvv: any
  }
  errorToken: any
  isShopping: string
  isBraintreeReady: boolean
}

interface OwnProps extends RouteComponentProps<MatchParams> {
  isShopping: boolean
}
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = OwnProps & StateProps & DispatchProps

const initialFields = {
  number: {
    isPotentiallyValid: true
  },
  expirationDate: {
    isPotentiallyValid: true
  },
  cvv: {
    isPotentiallyValid: true
  }
}

class CheckoutForm extends React.Component<Props, State> {
  private readonly braintree: React.RefObject<unknown>
  private tokenize: any
  private deviceData: any

  constructor(props: Props) {
    super(props)
    this.braintree = React.createRef()
    this.tokenize = null
    this.deviceData = null
    this.state = {
      errorToken: null,
      fields: initialFields,
      authorization: '',
      cardInfo: '',
      customFields: {
        name: ''
      },
      customErrors: {
        name: ''
      },
      isShopping: this.props.location.pathname,
      isBraintreeReady: false
    }
  }

  componentDidMount(): void {
    const { activeTier, fetchBraintreeCartToken } = this.props
    const { isShopping } = this.state
    if (
      (activeTier && activeTier.id) ||
      isShopping === routePath.CHECKOUT_CART
    ) {
      fetchBraintreeCartToken()
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    const { braintreeToken, activeTier, fetchBraintreeCartToken } = this.props
    if (braintreeToken !== prevProps.braintreeToken) {
      this.setState({ authorization: braintreeToken })
    }
    if (activeTier !== prevProps.activeTier) {
      fetchBraintreeCartToken()
    }
  }

  isValidCustomField = () => {
    const { customFields } = this.state
    const errors = {} as ICustomFields
    for (const field of Object.keys(customFields) as Array<
      keyof ICustomFields
    >) {
      if (!customFields[field]) {
        errors[field] = 'This field is required'
      }
    }
    this.setState({ customErrors: errors })
    return Object.keys(errors).length
  }

  handleChangeCustomField = (name: string, value: string): void => {
    this.setState(prevState => ({
      ...prevState,
      customFields: {
        ...prevState.customFields,
        [name]: value
      },
      customErrors: {
        ...prevState.customErrors,
        [name]: ''
      }
    }))
  }

  handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const {
      checkout,
      checkoutExperience,
      event,
      activeTier,
      isValidAddressInfo,
      startPaymentProcessing,
      isShopping
    } = this.props

    const {
      shipping: {
        street,
        state,
        zip,
        city,
        streetName,
        country,
        use_as_default,
        recipient_name,
        room,
        email
      },
      customFields,
      activeTierQuantity,
      isAuthenticated,
      guestCheckout
    } = this.props
    const userDevice = useDevice()
    const {
      customFields: { name: cardholderName }
    } = this.state

    AnalyticsManager.trackTierCheckoutIntent(
      activeTier.id,
      event.event_id,
      PaymentSystem.BRAINTREE
    )

    const isValidAddress = await isValidAddressInfo()
    // @ts-ignore
    if (this.isValidCustomField() || !isValidAddress) {
      return
    }

    let deliveryInfo = {}
    let billingAddress = {}

    if ((activeTier && activeTier.required_shipping_address) || isShopping) {
      billingAddress = {
        countryName: country,
        region: state || '',
        locality: city,
        streetAddress: streetName || street.label || '',
        postalCode: zip
      }

      deliveryInfo = {
        city,
        state: state || '',
        country: country || '',
        street: streetName || street.label || '',
        place_id: street.value || '',
        room: room || '',
        zip,
        use_as_default,
        recipient_name
      }
    }

    if (activeTier && activeTier.custom_fields) {
      deliveryInfo = {
        ...deliveryInfo,
        custom_fields: Object.values(customFields).reduce(
          (acc: ICustomField[], item: ICustomField[]) => [
            ...Object.values(acc),
            ...Object.values(item)
          ],
          [] as ICustomField[]
        )
      }
    }

    this.tokenize({
      cardholderName,
      billingAddress
    })
      .then((response: any) => {
        if (response && response.nonce) {
          startPaymentProcessing()
          if (isShopping) {
            // buy from cart order
            checkoutExperience({
              payment_system: PaymentSystem.BRAINTREE,
              device_data: this.deviceData,
              token: response.nonce,
              recipient_name,
              ...deliveryInfo
            })
          } else if (activeTier) {
            // buy from BuyNow

            if (event.sub_type !== EventSubType.SWEEPSTAKES) {
              let isAuthDelivery

              if (isAuthenticated) {
                // @ts-ignore
                const { tierSetId } = history.location.state
                const [activeSet] = activeTier.sets.filter(
                  s => s.tier_set_id === tierSetId
                )
                isAuthDelivery = { tier_set_id: activeSet.tier_set_id }
              } else {
                isAuthDelivery = {
                  tier_set_id: guestCheckout.tierSetId,
                  email,
                  device_id: userDevice.browserId
                }
              }

              checkoutExperience({
                payment_system: PaymentSystem.BRAINTREE,
                device_data: this.deviceData,
                token: response.nonce,
                quantity: activeTierQuantity,
                ...isAuthDelivery,
                ...deliveryInfo
              })
            } else {
              // buy from old way
              checkout({
                payment_system: PaymentSystem.BRAINTREE,
                intent: Intent.SALE,
                currency: Currency.USD,
                amount: activeTier.value,
                quantity: activeTierQuantity,
                event_id: event.event_id,
                event_title: event.event_title,
                tier_id: activeTier.id,
                tier_title: activeTier.title,
                tier_picture: activeTier.picture,
                device_data: this.deviceData,
                token: response.nonce,
                ...deliveryInfo
              })
            }
          }
        }
      })
      .catch((error: any) => this.onError(error))
      .finally(() => finishPaymentProcessing())
  }

  onError = (error: any) => {
    let erroredKeys = []

    if (error.code === 'HOSTED_FIELDS_FIELDS_EMPTY') {
      erroredKeys = Object.keys(this.state.fields)
    } else if (error.details && error.details.invalidFieldKeys) {
      erroredKeys = error.details.invalidFieldKeys
    }

    const erroredFields = erroredKeys.reduce(
      (acc: any, key: string) => ({
        ...acc,
        [key]: { isValid: false, isPotentiallyValid: false }
      }),
      {}
    )

    this.setState({
      fields: { ...initialFields, ...erroredFields },
      errorToken: error.message || String(error)
    })
  }

  onFocus = (data: any) => {
    const name = data.emittedBy
    data.fields[name].container.classList.remove('empty')
  }
  onBlur = (data: any) => {
    const name = data.emittedBy
    if (data.fields[name].isEmpty) {
      data.fields[name].container.classList.add('empty')
    }
  }

  onAuthorizationSuccess = () => this.setState({ isBraintreeReady: true })

  onDataCollectorInstanceReady = (err: any, dataCollectorInstance: any) => {
    if (!err) {
      this.deviceData = dataCollectorInstance.deviceData
    }
  }

  // @ts-ignore
  onCardTypeChange = ({ cards }: any) => {
    if (cards.length === 1) {
      const [cardInfo] = cards

      this.setState({ cardInfo })
    } else {
      // @ts-ignore
      this.setState({ cardInfo: '' })
    }
  }

  handleChange = (data: any) => {
    const name = data.emittedBy
    const field = data.fields[name]

    this.setState(
      {
        ...this.state,
        fields: {
          ...this.state.fields,
          [name]: field
        }
      },
      () => {
        this.props.clearCheckoutErrors()
      }
    )
  }

  render() {
    const {
      event,
      errors,
      activeTier,
      paymentProcessing,
      isValidProductSpecific,
      isShopping
    } = this.props

    const { customErrors, cardInfo, fields, isBraintreeReady } = this.state

    const isPaymentReady =
      !isBraintreeReady || paymentProcessing || isValidProductSpecific

    if ((!Object.keys(event).length || !activeTier) && !isShopping) {
      return null
    }

    return (
      <Braintree
        ref={this.braintree}
        authorization={this.state.authorization}
        onAuthorizationSuccess={this.onAuthorizationSuccess}
        onDataCollectorInstanceReady={this.onDataCollectorInstanceReady}
        onError={this.onError}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        onCardTypeChange={this.onCardTypeChange}
        onValidityChange={this.handleChange}
        /* tslint:disable-next-line:jsx-no-lambda */
        getTokenRef={(t: any) => (this.tokenize = t)}
        styles={braintreeStyles}
      >
        <CreditCardForm onSubmit={this.handleSubmit}>
          <Row>
            <Col sm={12} md={12} lg={6}>
              <div />
            </Col>
            <Col sm={12} md={12} lg={6}>
              <CardSection
                fields={fields}
                cardInfo={cardInfo}
                onChangeCustomField={this.handleChangeCustomField}
                errors={customErrors}
              />
            </Col>
            <Col>
              <FooterCard>
                {errors && (
                  <CustomErrorLabel
                    label={errors}
                    style={{ marginBottom: '1rem' }}
                  />
                )}
                <Button
                  variant="shop-gold-contained"
                  fullwidth={true}
                  type="submit"
                  disabled={isPaymentReady}
                >
                  Place Order
                </Button>
              </FooterCard>
            </Col>
          </Row>
        </CreditCardForm>
      </Braintree>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  event: selectEventsDetail(state),
  isValidProductSpecific: isValidProductSpecificSelector(state),
  isAuthenticated: selectIsAuth(state),
  activeTier: selectBuyNowTier(state),
  activeTierQuantity: selectBuyNowTierQuantity(state),
  eventsLoading: selectEventsIsFetching(state),
  paymentProcessing: selectCheckoutProcessing(state),
  errors: selectCheckoutError(state),
  shipping: selectShipping(state),
  customFields: selectCustomFields(state),
  braintreeToken: selectCheckoutBraintreeToken(state),
  guestCheckout: selectBuyNowGuestCheckout(state)
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      checkout,
      checkoutExperience,
      clearCheckoutErrors,
      fetchBraintreeCartToken,
      isValidAddressInfo,
      startPaymentProcessing,
      finishPaymentProcessing
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    {
      pure: false
    }
  )(CheckoutForm)
)
