import { createReducer } from 'typesafe-actions'
import { IErrors } from '../commonTypes'
import {
  AllUserInvestments,
  IBalance,
  ICurrentAddress,
  IFollowing,
  INotification,
  InvestmentStatus,
  IProfile,
  IProfileFollowing,
  IProfileNotifications,
  IToken,
  IUserEventDetails,
  UserAction,
  UserActionTypes,
  UserInvestment,
  UserState
} from './types'

export const userInitialState: UserState = {
  isFetching: false,
  following: { data: [] as IFollowing[] } as IProfileFollowing,
  balance: {
    data: [] as IToken[],
    coins: 0
  } as IBalance,
  notifications: { data: [] as INotification[] } as IProfileNotifications,
  currentAddress: {} as ICurrentAddress,
  isFetchingAddress: false,
  newAddress: {} as ICurrentAddress,
  addressErrors: {
    country: '',
    state: '',
    city: '',
    street: '',
    zip: '',
    place_id: ''
  },
  eventDetails: {} as { events: IUserEventDetails[] },
  about: {} as { info: string },
  profileDetailsErrors: {} as IErrors<IProfile>,
  errors: {} as Error,
  isSendingReport: false,
  investments: {
    isFetching: false,
    allInvestments: { data: [] as UserInvestment[] } as AllUserInvestments,
    investmentDetails: { data: {} as UserInvestment },
    errors: null
  }
}

export const userReducer = createReducer<UserState, UserAction>(
  userInitialState,
  {
    [UserActionTypes.PROFILE_SETTINGS_UPDATE_REQUEST]: state => ({
      ...state,
      profileDetailsErrors: {}
    }),
    [UserActionTypes.PROFILE_SETTINGS_UPDATE_SUCCESS]: state => ({
      ...state,
      profileDetailsErrors: {}
    }),
    [UserActionTypes.PROFILE_SETTINGS_UPDATE_FAIL]: (state, action) => ({
      ...state,
      profileDetailsErrors: {
        ...state.profileDetailsErrors,
        // @ts-ignore
        ...action.payload.errors
      }
    }),
    [UserActionTypes.PROFILE_PHOTO_UPDATE_FAIL]: (state, action) => ({
      ...state,
      errors: action.payload
    }),
    [UserActionTypes.CLEAR_ERRORS]: state => ({
      ...state,
      errors: userInitialState.errors,
      profileDetailsErrors: userInitialState.profileDetailsErrors
    }),
    [UserActionTypes.CLEAR_FOLLOWING]: state => ({
      ...state,
      following: userInitialState.following
    }),
    [UserActionTypes.PROFILE_FOLLOWING_REQUEST]: (state, action) => ({
      ...state,
      isFetching: true
    }),
    [UserActionTypes.PROFILE_FOLLOWING_SUCCESS]: (state, action) => ({
      ...state,
      isFetching: false,
      following: {
        data: [...state.following.data, ...action.payload.data],
        links: action.payload.links,
        meta: action.payload.meta
      }
    }),
    [UserActionTypes.PROFILE_FOLLOWING_FAIL]: (state, action) => ({
      ...state,
      errors: action.payload
    }),
    [UserActionTypes.PROFILE_BALANCE_SUCCESS]: (state, action) => ({
      ...state,
      balance: action.payload
    }),
    [UserActionTypes.PROFILE_BALANCE_FAIL]: (state, action) => ({
      ...state,
      errors: action.payload
    }),
    [UserActionTypes.PROFILE_NOTIFICATIONS_REQUEST]: (state, action) => ({
      ...state,
      isFetching: true
    }),
    [UserActionTypes.PROFILE_NOTIFICATIONS_SUCCESS]: (state, action) => ({
      ...state,
      isFetching: false,
      notifications: {
        data: [...state.notifications.data, ...action.payload.data],
        meta: action.payload.meta,
        links: action.payload.links
      }
    }),
    [UserActionTypes.PROFILE_NOTIFICATIONS_FAIL]: (state, action) => ({
      ...state,
      isFetching: false,
      errors: action.payload
    }),
    [UserActionTypes.PROFILE_NOTIFICATIONS_READ_FAIL]: (state, action) => ({
      ...state,
      errors: action.payload
    }),
    [UserActionTypes.PROFILE_NOTIFICATIONS_READ_SUCCESS]: (state, action) => ({
      ...state,
      notifications: {
        ...state.notifications,
        data: state.notifications.data.map(n =>
          action.payload.ids.includes(n.id) ? { ...n, status: 1 } : n
        )
      }
    }),
    [UserActionTypes.DELETE_NOTIFICATION_REQUEST]: (state, action) => ({
      ...state,
      isFetching: true
    }),
    [UserActionTypes.DELETE_NOTIFICATION_SUCCESS]: (state, action) => ({
      ...state,
      isFetching: false,
      notifications: {
        ...state.notifications,
        data: state.notifications.data.filter(n => n.id !== action.payload.id)
      }
    }),
    [UserActionTypes.DELETE_NOTIFICATION_FAIL]: (state, action) => ({
      ...state,
      isFetching: false
    }),
    [UserActionTypes.ABOUT_APPLICATION_SUCCESS]: (state, action) => ({
      ...state,
      about: action.payload
    }),
    [UserActionTypes.FETCH_USER_ADDRESS_REQUEST]: state => ({
      ...state,
      isFetchingAddress: true
    }),
    [UserActionTypes.FETCH_USER_ADDRESS_SUCCESS]: (state, action) => ({
      ...state,
      isFetchingAddress: false,
      currentAddress: action.payload,
      newAddress: { ...userInitialState.newAddress, ...action.payload }
    }),
    [UserActionTypes.FETCH_USER_ADDRESS_FAIL]: state => ({
      ...state,
      isFetchingAddress: false
    }),
    [UserActionTypes.UPDATE_USER_ADDRESS_REQUEST]: (state, action) => ({
      ...state,
      isFetchingAddress: true
    }),
    [UserActionTypes.UPDATE_USER_ADDRESS_SUCCESS]: (state, action) => ({
      ...state,
      currentAddress: { ...state.currentAddress, ...action.payload },
      isFetchingAddress: false
    }),
    [UserActionTypes.UPDATE_USER_ADDRESS_FAIL]: (state, action) => ({
      ...state,
      // @ts-ignore
      addressErrors: action.payload.errors,
      isFetchingAddress: false
    }),
    [UserActionTypes.CHANGE_ADDRESS]: (state, action) => {
      let addressErrors = {
        ...state.addressErrors
      }

      if (
        action.payload.name &&
        addressErrors &&
        // @ts-ignore
        addressErrors[action.payload.name]
      ) {
        addressErrors = {
          ...state.addressErrors
        }
        // @ts-ignore
        delete addressErrors[action.payload.name]
      }

      return {
        ...state,
        addressErrors
      }
    },
    [UserActionTypes.SEND_REPORT_REQUEST]: (state, action) => ({
      ...state,
      isSendingReport: true
    }),
    [UserActionTypes.SEND_REPORT_SUCCESS]: (state, action) => ({
      ...state,
      isSendingReport: false
    }),
    [UserActionTypes.SEND_REPORT_FAIL]: (state, action) => ({
      ...state,
      errors: action.payload,
      isSendingReport: false
    }),
    [UserActionTypes.FETCH_ANALYTICS_SUCCESS]: (state, action) => ({
      ...state,
      eventDetails: action.payload
    }),
    [UserActionTypes.SET_ADDRESS_ERRORS]: (state, action) => ({
      ...state,
      addressErrors: action.payload.fields
    }),
    [UserActionTypes.SET_PROFILE_DETAILS_ERRORS]: (state, action) => ({
      ...state,
      profileDetailsErrors: action.payload.fields
    }),
    [UserActionTypes.CLEAR_ADDRESS_ERRORS]: state => ({
      ...state,
      addressErrors: userInitialState.addressErrors,
      newAddress: userInitialState.newAddress
    }),
    [UserActionTypes.CLEAR_NOTIFICATIONS]: state => ({
      ...state,
      notifications: userInitialState.notifications
    }),
    [UserActionTypes.FETCH_INVESTMENTS_REQUEST]: state => ({
      ...state,
      investments: {
        ...state.investments,
        isFetching: true
      }
    }),
    [UserActionTypes.FETCH_INVESTMENTS_SUCCESS]: (state, action) => ({
      ...state,
      investments: {
        ...state.investments,
        isFetching: false,
        allInvestments: {
          ...action.payload,
          data: [
            ...state.investments.allInvestments.data,
            ...action.payload.data
          ]
        }
      }
    }),
    [UserActionTypes.FETCH_INVESTMENTS_FAIL]: (state, action) => ({
      ...state,
      investments: {
        ...state.investments,
        isFetching: false,
        errors: action.payload
      }
    }),
    [UserActionTypes.FETCH_INVESTMENT_DETAILS_REQUEST]: state => ({
      ...state,
      investments: {
        ...state.investments,
        isFetching: true
      }
    }),
    [UserActionTypes.FETCH_INVESTMENT_DETAILS_SUCCESS]: (state, action) => ({
      ...state,
      investments: {
        ...state.investments,
        isFetching: false,
        investmentDetails: action.payload
      }
    }),
    [UserActionTypes.FETCH_INVESTMENT_DETAILS_FAIL]: (state, action) => ({
      ...state,
      investments: {
        ...state.investments,
        isFetching: false,
        errors: action.payload
      }
    }),
    [UserActionTypes.CANCEL_INVESTMENT_REQUEST]: (state, action) => ({
      ...state,
      investments: {
        ...state.investments,
        isFetching: true
      }
    }),
    [UserActionTypes.CANCEL_INVESTMENT_SUCCESS]: (state, action) => ({
      ...state,
      investments: {
        ...state.investments,
        isFetching: false,
        allInvestments: {
          ...state.investments.allInvestments,
          data: state.investments.allInvestments.data.map(inv => {
            if (inv.id === action.payload.id) {
              return { ...inv, status: InvestmentStatus.VOIDED }
            } else {
              return inv
            }
          })
        },
        investmentDetails: {
          ...state.investments.investmentDetails,
          data: {
            ...state.investments.investmentDetails.data,
            status: InvestmentStatus.CANCELLED
          }
        }
      }
    }),
    [UserActionTypes.CANCEL_INVESTMENT_FAIL]: (state, action) => ({
      ...state,
      investments: {
        ...state.investments,
        isFetching: false,
        errors: action.payload
      }
    }),
    [UserActionTypes.CLEAR_INVESTMENTS]: state => ({
      ...state,
      investments: {
        ...userInitialState.investments
      }
    })
  }
)
