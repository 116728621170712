import React from 'react'
import { Container } from 'styled-bootstrap-grid'
import { EventSection } from 'redux/events/types'
import {
  MainContent,
  SectionWrapper,
  SectionTitle
} from 'containers/invest/InvestDetailPage/styles'
import { theme } from 'styled/theme'
import { IncomingHTMLLayout } from '../Disclosures/styles'
import { ContentTitle, ContentDescription } from './styles'

const DrivingInvestors: React.FC<{
  section: EventSection | undefined
}> = ({ section }) => {
  return (
    <>
      {section && (
        <MainContent sectionBackground={'#f8f8f8'}>
          <Container className={'content-container'}>
            <SectionWrapper id={'driving-investors'}>
              <SectionTitle color={theme.colors.black}>
                Driving Investors
              </SectionTitle>
              <IncomingHTMLLayout>
                {section.items.map(({ title, description }, index) => {
                  return (
                    <div key={title}>
                      {title && <ContentTitle>{title}</ContentTitle>}
                      {description && (
                        <>
                          {index === 0 ? (
                            <ContentDescription
                              firstdesc={true}
                              dangerouslySetInnerHTML={{ __html: description }}
                            />
                          ) : (
                            <ContentDescription
                              dangerouslySetInnerHTML={{ __html: description }}
                            />
                          )}
                        </>
                      )}
                    </div>
                  )
                })}
              </IncomingHTMLLayout>
            </SectionWrapper>
          </Container>
        </MainContent>
      )}
    </>
  )
}

export default DrivingInvestors
