import styled, { css } from 'styled-components'
import Img from 'components/UI/Img'
import { Col, media } from 'styled-bootstrap-grid'
import Link from 'src/components/UI/Link'
import { ButtonVariant } from 'src/components/UI/Button'

export const TopCard = styled.div`
  width: 100%;
  display: block;
  margin-bottom: 4rem;
  position: relative;

  ${media.lg`
    display: flex;
    margin-bottom: 2rem;
  `}
`

export const CardIconsWrapper = styled.div<{
  num?: number
  isSupported?: boolean
}>`
  opacity: 1;
  transform: matrix(1, 0, 0, 1, 0, 0);
  color: #000000;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.63);
  pointer-events: none;
  padding: 8%;

  display: flex;
  align-items: center;
  justify-content: center;
  p {
    color: #000000;
    text-transform: uppercase;
    line-height: 1.3328;
    font-size: calc(0.64vw + 1rem);
    font-family: 'Acumin Pro';
    padding-top: 0.5rem;
    white-space: nowrap;
  }
  ${media.xl`
    p {
      font-size: 1.6rem;
    }
  `}
`
export const SupportersWrapper = styled(CardIconsWrapper)`
  padding-right: ${p => !p.isSupported && 0};
`
export const BottomCard = styled.div`
  height: 100%;
  display: flex;
  justify-content: end;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: flex-start;

  a {
    font-weight: normal;
  }
`
export const Title = styled.div<{ fontControl?: boolean }>`
  color: #ffffff;
  text-align: left;
  font-size: 1.5rem;
  margin-bottom: 2rem;
  font-weight: 300;
  font-family: Inter;
  ${p =>
    css`
      ${media.md`
    font-size: ${p.fontControl ? '2.25rem' : '2.25rem'};
    font-weight: 800;
    line-height: 2.75rem;
    margin-bottom: 0.625rem;
  `}
    `}
  ${media.xs`
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin-bottom: 0.5rem;
  `}
`
export const Name = styled.h2`
  color: ${p => p.theme.colors.white};
  font-family: Inter;
  font-size: 1.125rem;
  font-weight: 900;
  line-height: 1.8125rem;
  margin-bottom: 0.438rem;
  ${media.md`
    font-size: 1.5rem;
    font-weight: 800;
    line-height: 2.75rem;
    margin-bottom: 0.625rem;
    `}
`

export const Description = styled.p`
  max-height: 80px;
  overflow: hidden;
  * {
    color: ${p => p.theme.colors.white};
    font-family: Inter !important;
    font-weight: 400 !important;
    font-size: 1.063rem !important;
    line-height: 1.625rem !important;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    ${media.lg`
      font-weight: 300 !important;
      font-size: 1.625rem !important;
      line-height: 2.375rem !important;
      max-width: 90% !important;
      -webkit-line-clamp: 2;
    `}
  }

  p,
  span {
    font-family: Inter !important;
  }

  ${media.xs`
    max-height: 65px;
    
    * {
      font-size: 0.9rem !important;
      line-height: 1.4rem !important;
    }
  `}
`
export const Type = styled.p`
  font-family: Inter;
  font-weight: 800;
  font-size: 1.125rem;
  line-height: 1.375rem;
  margin-bottom: 5px;
  ${media.lg`
      font-size: 1.5rem;
      line-height: 1.8125rem;
    `}
`
export const EventContent = styled.div`
  z-index: 2;
`

export const ImgEvent = styled(Img)`
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: -1;
`
export const Left = styled.div`
  position: relative;
  width: 90%;

  ${media.lg`
    width: 50%;
  `}
`
export const Right = styled.div`
  width: 60%;
  position: absolute;
  right: 0;
  top: 50%;
  z-index: 1;

  p {
    font-size: calc(0.94vw + 1rem);
    white-space: normal;
  }

  ${media.lg`
    width: 50%;
  `}
`
export const InvestCard = styled.div<{ img?: string }>`
  width: 100%;
  max-width: 1150px;
  height: 96vh;
  max-height: 850px;
  overflow: hidden;
  position: relative;
  margin: 0 auto 3.125rem;
  box-shadow: 0 35.92px 60px rgba(21, 21, 18, 0.5);
  border-radius: 15px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 1) 90%
  );

  &:before {
    content: '';
    display: block;
    position: absolute;
    background: ${p => p.theme.colors.greyPearl};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
  ${media.lg`
     max-height: 650px;
  `}

  @media only screen and (-webkit-min-device-pixel-ratio: 4),
    only screen and (min-height: 667px) and (min--moz-device-pixel-ratio: 4),
    only screen and (min-height: 667px) and (-o-min-device-pixel-ratio: 4/1),
    only screen and (min-height: 667px) and (min-device-pixel-ratio: 4),
    only screen and (min-height: 667px) and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min-height: 667px) and (min--moz-device-pixel-ratio: 2),
    only screen and (min-height: 667px) and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-height: 667px) and (min-device-pixel-ratio: 2),
    only screen and (min-height: 667px) and (min-resolution: 192dpi),
    only screen and (min-height: 667px) and (min-resolution: 2dppx) {
    height: 70vh;
    max-height: 570px;
  }

  @media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2),
    only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3),
    only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3),
    only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3),
    only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
    // This media queries is for: iPhone 11, XR, 11 Pro Max, Xs Max, 12 Pro, 12 Mini, 12 Pro Max
    height: 70vh;
    max-height: 570px;
  }
`

export const FundWrapper = styled.div`
  padding: 0 1.125rem;
  width: 100%;
  position: absolute;
  top: -0.75rem;
  left: 0;
`

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 5px;
  margin-bottom: 2.125rem;
  padding: 1rem 0;
  padding-right: 0;
  border: 0.0625rem solid ${p => p.theme.colors.lightGrey};

  ${media.sm`
    grid-template-columns: 1fr 1fr 1fr;
  `}

  @media (min-width: 768px) and (max-width: 968px) {
    grid-template-columns: 1fr 1fr;
  }

  ${Col} & {
    div {
      padding: 0 10px;
    }
    p {
      font-size: 1rem;
      text-align: left;
      font-weight: 500;

      &:last-of-type {
        padding-top: 4px;
        font-weight: 300;
      }
      @media (max-width: 339px) {
        font-size: 0.875rem;
      }
      ${media.sm`
         font-size: 1rem;
  `}
      ${media.xl`
         font-size: 1rem;
  `}
    }
  }
`
export const DaysLeftContainer = styled.div`
  max-height: 42%;
`

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

export const CardInfo = styled.div`
  display: flex;
  flex-direction: column-reverse;
  ${media.md`
    flex-direction: row;
   justify-content: space-between;
  `};
`

export const TitleWrapper = styled.div`
  width: 100%;
  ${media.md`
    width: 71%;
  `};
`
export const CardBottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${media.md`
   flex-direction: row;
  `};
`
export const OfferingWrapper = styled.div<{
  isEnded?: boolean
}>`
  margin-top: 25px;
  ${media.xs`
   margin-top: 1rem;
  `};

  ${p =>
    css`
      ${media.md`
   width: ${p.isEnded ? '100%' : '65%'};
  `};
      ${media.lg`
   width: ${p.isEnded ? '100%' : '73%'};
  `};
      ${media.xl`
   width: ${p.isEnded ? '100%' : '78%'};
  `};
    `}
`

export const SocialWrapper = styled.div<{
  withButton?: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: ${p => (p.withButton ? 'space-between' : 'flex-end')};
  ${media.md`
   flex-direction: column;
   justify-content: flex-end;
  `};
`

export const Amount = styled.p`
  font-family: Inter;
  font-weight: 300;
  font-size: 1.125rem;
  line-height: 1.375rem;
  color: ${p => p.theme.colors.fuchsia};
  ${media.md`
     font-size: 1.625rem;
  line-height: 3.75rem;
  `};
`
export const AmountStrong = styled(Amount)`
  font-weight: 900;
`
export const AmountTotal = styled(AmountStrong)`
  color: ${p => p.theme.colors.white};
  opacity: 0.8;
`
export const AmountTitle = styled.p`
  font-family: Inter;
  font-weight: 300;
  font-size: 0.75rem;
  letter-spacing: 0.125rem;
  line-height: 0.938rem;
  text-transform: uppercase;
  color: ${p => p.theme.colors.white};
  opacity: 0.7;
  max-width: 130px;
`
export const AmountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  :not(:last-of-type) {
    margin-right: 22px;
    ${media.md`
    margin-right: 42px;
  `}
  }
  ${media.md`
    margin-bottom: 0;
  `};

  ${media.xs`
    margin-bottom: 10px;
  `};
`
export const AmountRow = styled.div<{ isEnded?: boolean }>`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 1rem;
  > div {
    display: flex;
  }
  ${Amount} {
    color: ${p => p.isEnded && '#848484'};
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 15px;
  bottom: 15px;
  right: 15px;
  left: 15px;
  z-index: 2;
  ${media.md`
    top: 25px;
    bottom: 25px;
    right: 25px;
    left: 25px;
  `}
  ${media.lg`
    top: 50px;
    bottom: 50px;
    right: 50px;
    left: 50px;
  `}
`

export const FallbackImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
`

export const SocialButtonsWrapper = styled.div`
  ${media.md`
    margin-top: 33px;
  `}
`
export const LearnMore = styled(Link).attrs(() => ({
  variant: ButtonVariant.Default
}))`
  border-radius: 36px;
  font-size: 1rem;
  padding: 1rem;
  @media (max-width: 374px) {
    font-size: 0.8125rem;
    letter-spacing: 0.063rem;
    padding: 1rem 0.313rem;
  }
  ${media.md`
     letter-spacing: 0.125rem;
     font-size: 1.125rem;
     padding: 1rem 1.125rem;
  `};
`

// ***** VIDEO ***** //

export const PlayButton = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: none;
  letter-spacing: 2px;
  color: ${p => p.theme.colors.white};
  font-family: Inter;
  font-size: 0.8125rem;
  text-transform: uppercase;
  text-align: left;
  margin-bottom: 23px;
  max-width: 110px;
  svg {
    margin-right: 10px;
    pointer-events: none;
  }

  ${media.md`
    margin-bottom: 0;
    max-width: unset;
    font-size: 0.875rem;
       svg {
      margin-right: 12px;
    }
  `}

  ${media.xs`
     margin-bottom: 10px;
  `}
`

export const VideoPreview = styled.div<{ img: string }>`
  width: 100%;
  height: 100%;
  background: url(${p => p.img}),
    linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 1) 90%);
  background-blend-mode: overlay;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
`

export const PlayerWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 12rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Player = styled.video<{ isLoading: boolean }>`
  width: 100%;
  height: 100%;
  display: ${p => (p.isLoading ? 'none' : 'block')};
  outline: none;
`
