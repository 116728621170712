import { AppState } from 'store'
import { AuthState, ROLES } from './types'

export const selectAuth = (state: AppState): AuthState => state.auth

export const selectUser = (state: AppState) => selectAuth(state).user
export const selectIsAuth = (state: AppState) =>
  selectAuth(state).isAuthenticated
export const selectIsRegistered = (state: AppState) =>
  selectAuth(state).isRegistered
export const selectIsFetchingLoggedIn = (state: AppState) =>
  selectAuth(state).isFetchingLoggedIn
export const selectAccessToken = (state: AppState) =>
  selectAuth(state).accessToken

export const selectBetablockParams = (state: AppState) =>
  selectAuth(state).betablockParams

export const selectAuthErrors = (state: AppState) => selectAuth(state).errors

export const selectUserId = (state: AppState) => selectUser(state).id

export const isBrandSelector = (state: AppState): boolean =>
  selectAuth(state).user.role === ROLES.BRAND

export const userNameSelector = (state: AppState): string => {
  const { first_name, last_name } = selectUser(state)

  return first_name && last_name ? `${first_name} ${last_name}` : ''
}

export const getError = (errors: any): string => {
  if (errors.hasOwnProperty('errors')) {
    for (const key of Object.keys(errors.errors)) {
      return errors.errors[key][0]
    }
  }
  return errors.message || errors.error
}
