import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { routePath } from 'routes'
import { fetchTierDetails } from 'redux/investment/operations'
import {
  selectActiveTierId,
  selectInvestmentAmount,
  selectTierDetails
} from 'redux/investment/selectors'
import { EntityType, ITierAmountDetails } from 'redux/investment/types'
import { IEventTier, SecurityType } from 'redux/events/types'
import { setInvestmentTierId } from 'redux/investment/actions'
import AnalyticsManager from 'services/analyticService'
import { formatPrice } from 'utils/dateFormats'
import {
  convertValueToPrice,
  convertValueToPriceWithDecimal,
  convertValueToPriceWithDecimalInput,
  showEntityLabel
} from 'utils/helpers'

import { ButtonVariant } from 'src/components/UI/Button'
import DropdownSelect from 'src/components/UI/Dropdown'
import Link from 'src/components/UI/Link'
import Loader from 'src/components/UI/Loader'
import Modal from 'src/components/UI/Modal'
import TextInput, { InputVariant } from 'src/components/UI/TextInput'
import Slider from 'components/UI/Slider'
import {
  AmountButton,
  AmountDescription,
  AmountText,
  AmountWrapper,
  DropdownWrapper,
  ErrorMessage,
  FooterWrapper,
  InputPlaceholder,
  InputWrapper,
  Label,
  LoaderWrapper,
  Message,
  Title
} from './styles'

interface InvestNowProps {
  isOpen: boolean
  event: any
  activeTierId: number
  onClose?: () => void
  hideValue?: boolean
}

const InvestNowModal: React.FC<InvestNowProps> = ({
  isOpen,
  event,
  activeTierId,
  onClose,
  hideValue
}) => {
  const dispatch = useDispatch()
  const tierDetails = useSelector(selectTierDetails)
  const tierID = useSelector(selectActiveTierId)
  const values = event.tiers.map((t: ITierAmountDetails) => t.value)
  const tierSlider = useRef<any>(null)
  const {
    price_per_share,
    security_type,
    total_offering,
    amount_raised
  } = event.investmentTerms
  const investmentAmount = useSelector(selectInvestmentAmount)
  const isEquity = security_type === SecurityType.EQUITY
  const entityKeys = Object.keys(EntityType)
  const options = entityKeys.map(entity => {
    const entityType = EntityType[entity as keyof typeof EntityType]
    return {
      value: entityType,
      label: showEntityLabel(entityType)
    }
  })
  const [entity, setEntity] = useState(options[0])
  const [amount, setAmount] = useState({
    price: '',
    cleanValue: 0
  })

  useEffect(() => {
    if (activeTierId) {
      dispatch(fetchTierDetails(activeTierId))
    }
    window.addEventListener('resize', () => {
      const input = document.getElementById('amount-invest-now')
      if (input) {
        input.focus()
      }
    })
    // eslint-disable-next-line
  }, [])

  const { minimum_amount, isLoaded } = tierDetails

  const buttons: ITierAmountDetails[] = event.tiers

  const allowedAmount = total_offering - amount_raised

  const roundedAmount =
    amount.cleanValue <= minimum_amount
      ? Math.ceil(minimum_amount / Number(price_per_share)) *
        Number(price_per_share)
      : Math.floor(amount.cleanValue / Number(price_per_share)) *
        Number(price_per_share)

  const rounded = parseFloat(roundedAmount.toFixed(2))
  const renderHeader = () => <Title>Invest Now</Title>
  const selectEntity = (
    name: string,
    option: { label: string; value: EntityType }
  ) => setEntity(option)
  // const moreThanMaximum = amount.cleanValue > maximum_amount
  const lessThanMinimum = amount.cleanValue < minimum_amount
  const moreThanTotalOffering = amount.cleanValue > allowedAmount
  const investmentOver = amount_raised >= total_offering

  useEffect(() => {
    if (isOpen) {
      const activeTierValue = event.tiers.find(
        (tier: IEventTier) => tier.id === activeTierId
      )
      if (investmentAmount && !hideValue) {
        const price = convertValueToPriceWithDecimal(investmentAmount)
        setAmount({ price, cleanValue: investmentAmount })
      } else if (activeTierValue && !hideValue) {
        const formatted = convertValueToPrice(String(activeTierValue.value))
        setAmount({ price: formatted, cleanValue: activeTierValue.value })
      }
    } else {
      setAmount({ price: '', cleanValue: 0 })
      setInvestmentTierId(0)
    }
    // eslint-disable-next-line
  }, [isOpen])

  useEffect(() => {
    if (tierSlider && tierSlider.current && tierID && isOpen && isLoaded) {
      event.tiers.map((t: ITierAmountDetails, i: number) => {
        if (t.id === tierID) {
          tierSlider.current.slickGoTo(i)
        }
        return null
      })
    }
    // eslint-disable-next-line
  }, [tierID, isLoaded])

  const toggleActive = (i: number) => {
    const currentButton =
      buttons.find(b => b.id === i) || ({} as ITierAmountDetails)

    const cleanValue = currentButton.value
    const price = formatPrice(cleanValue)
    setAmount({ price, cleanValue })
    AnalyticsManager.trackNorthCapitalEvent('Open', i)
    dispatch(setInvestmentTierId(i))
  }

  const handleChange = (name: string, value: string) => {
    const cleanValue = value
      .replace(/[^0-9.]/g, '')
      .replace(/(\..*?)\..*/g, '$1')
      .replace(/(\.\d{2}).+/g, '$1')
    const price = convertValueToPriceWithDecimalInput(cleanValue)

    setAmount({ price, cleanValue: Number(cleanValue) })
    const closest = values.reduce((prev: number, curr: number) => {
      return Math.abs(curr - parseFloat(cleanValue)) <
        Math.abs(prev - parseFloat(cleanValue))
        ? curr
        : prev
    })
    const closetTierId = event.tiers.filter(
      (c: ITierAmountDetails) => c.value === closest
    )
    dispatch(setInvestmentTierId(closetTierId[0].id))
  }

  const renderFooter = () => {
    return (
      <FooterWrapper>
        <DropdownWrapper>
          <DropdownSelect
            options={options}
            name={'investmentRole'}
            onSelect={selectEntity}
            value={entity}
            hideControl={true}
          />
        </DropdownWrapper>
        <Message>
          {!!amount.cleanValue &&
            lessThanMinimum &&
            isEquity &&
            `The minimum investment amount in this offering is ${convertValueToPrice(
              String(minimum_amount)
            )}.`}
          {!!amount.cleanValue &&
            lessThanMinimum &&
            isEquity &&
            minimum_amount !== rounded &&
            `It will be rounded off to ${convertValueToPriceWithDecimal(
              rounded
            )} to be a multiple of the share price.`}
          {!!amount.cleanValue &&
            amount.cleanValue !== rounded &&
            isEquity &&
            !lessThanMinimum &&
            `The investment amount will be rounded off to ${convertValueToPriceWithDecimal(
              rounded
            )} to be a multiple of the share price.`}
        </Message>
        <Link
          disabled={!amount.cleanValue}
          variant={ButtonVariant.Default}
          to={{
            pathname: routePath.INVEST_NOW,
            state: {
              amount: isEquity ? rounded : amount.cleanValue,
              entity: entity.value,
              activeTierId,
              eventId: event.event_id,
              price_per_share,
              security_type,
              title: event.event_title
            }
          }}
          style={{
            display: 'block'
          }}
        >
          Next
        </Link>
      </FooterWrapper>
    )
  }
  const settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    infinite: false,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  }
  return (
    <Modal
      header={renderHeader()}
      isOpen={isOpen}
      onClose={onClose}
      footer={renderFooter()}
      backgroundFooter={'#fff'}
      overlayStyle={{ overflow: 'overlay', height: '100%' }}
    >
      {isLoaded ? (
        <>
          <AmountWrapper>
            <Slider settings={settings} setRef={tierSlider}>
              {buttons.map((btn: ITierAmountDetails) => {
                const { id, title, description } = btn
                const onClick = () => {
                  toggleActive(id)
                }
                return (
                  <AmountButton
                    onClick={onClick}
                    key={id}
                    isActive={id === tierID && amount.cleanValue > 0}
                  >
                    <AmountText>{title}</AmountText>
                    <AmountDescription>{description}</AmountDescription>
                  </AmountButton>
                )
              })}
            </Slider>
          </AmountWrapper>
          <InputWrapper isShown={amount.price}>
            <Label>Amount</Label>
            <InputPlaceholder>
              <span>$</span>
              <TextInput
                handleChange={handleChange}
                maxLength={16}
                variant={InputVariant.Secondary}
                value={amount.price}
                type={'text'}
                name={'amount'}
                id={'amount-invest-now'}
              />
            </InputPlaceholder>
            <ErrorMessage>
              {moreThanTotalOffering &&
                !investmentOver &&
                !isEquity &&
                `We’re sorry, the amount remaining to invest in this offering is ${convertValueToPrice(
                  String(allowedAmount)
                )}. `}
            </ErrorMessage>
          </InputWrapper>
        </>
      ) : (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      )}
    </Modal>
  )
}

export default InvestNowModal
