import { AnalyticsTabs } from 'redux/influencer/types'
import { Props } from './index'

const tabs = (props: Props) => ({
  [AnalyticsTabs.FOLLOWERS]: { title: 'Followers', amount: props.followers },
  [AnalyticsTabs.SUPPORTERS]: { title: 'Supporters', amount: props.supporters },
  [AnalyticsTabs.AMOUNT_RAISED]: {
    title: 'Amount Raised',
    amount: props.amountRaised
  },
  [AnalyticsTabs.REGIONS]: { title: 'Support by Region' }
})

export default tabs
