import styled from 'styled-components'
import Link from 'components/UI/Link'
import { DefaultButton } from 'src/components/UI/Button'

export const Wrapper = styled.div`
  margin-bottom: 3.875rem;
`

export const AuthMessage = styled.p`
  font-family: Inter;
  font-size: 1.125rem;
  margin-bottom: 25px;
`

export const CommentBlock = styled.div`
  display: flex;
`

export const LoadMoreButton = styled(DefaultButton)`
  padding: 12.5px 24px;
  text-transform: uppercase;
  color: ${p => p.theme.colors.fuchsia};
  font-size: 1rem;
  background: transparent;
  font-family: Inter;
  font-weight: 400;
  border: 1px solid rgba(188, 106, 202, 0.5);
  border-radius: 12px;
  letter-spacing: 2px;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
  margin-top: 25px;
  min-width: 225px;
  line-height: 19px;

  :hover {
    opacity: 0.8;
  }

  :disabled {
    color: ${p => p.theme.colors.fuchsia};
    :hover {
      background-color: inherit;
      color: ${p => p.theme.colors.fuchsia};
    }
  }
  span {
    font-family: Inter;
    font-size: 0.875rem;
    color: ${p => p.theme.colors.fuchsia};
  }
`

export const AuthLink = styled(Link).attrs(() => ({ variant: 'clear' }))`
  font-family: Inter;
  font-size: 1.125rem;
  font-weight: 300;
  letter-spacing: 0.91px;
  color: ${p => p.theme.colors.fuchsia};
`

export const ThreadWrapper = styled.div`
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 0.375rem;
  background: ${p => p.theme.colors.navy};
`
