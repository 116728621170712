import React, { FC, ReactNode } from 'react'

import {
  FormContainer,
  FormContent,
  FormTitle,
  FormWrapper,
  LogInWrapper
} from './styles'

export interface Props {
  title: ReactNode | string
  content: ReactNode
  footer?: ReactNode
  onSubmit?: any
  isActive?: boolean
}

const AuthForm: FC<Props> = ({
  title,
  content,
  footer,
  isActive,
  onSubmit
}) => {
  const onSubmitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (onSubmit) {
      onSubmit()
    }
  }

  return (
    <FormWrapper>
      <FormContainer onSubmit={onSubmitForm}>
        <FormTitle>{title}</FormTitle>
        <FormContent>{content}</FormContent>
        {isActive && <LogInWrapper>{footer}</LogInWrapper>}
      </FormContainer>
    </FormWrapper>
  )
}

export default AuthForm
