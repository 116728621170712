import React, { FC, CSSProperties } from 'react'
import { LogoIcon } from 'components/icons'
import { ComingSoonBlock } from './styles'

const ComingSoon: FC<{
  style?: CSSProperties
  text?: string
  textColor?: string
}> = ({ textColor = '#fff', ...props }) => (
  <ComingSoonBlock {...props} textColor={textColor}>
    <LogoIcon width={176} height={176} marginBottom="5rem" />
    <p>{props.text ? props.text : 'Coming Soon'}</p>
  </ComingSoonBlock>
)

export default ComingSoon
