import React from 'react'

const CrossIcon = props => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="#BCBCBC"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.89321 5.99999L11.815 1.07817C12.0617 0.831514 12.0617 0.431624 11.815 0.184992C11.5684 -0.0616639 11.1685 -0.0616639 10.9218 0.184992L5.99999 5.10681L1.07817 0.184992C0.831514 -0.0616639 0.431624 -0.0616639 0.184992 0.184992C-0.0616405 0.431647 -0.0616639 0.831537 0.184992 1.07817L5.10681 5.99999L0.184992 10.9218C-0.0616639 11.1685 -0.0616639 11.5683 0.184992 11.815C0.431647 12.0616 0.831537 12.0616 1.07817 11.815L5.99999 6.89316L10.9218 11.815C11.1684 12.0616 11.5683 12.0616 11.815 11.815C12.0616 11.5683 12.0616 11.1684 11.815 10.9218L6.89321 5.99999Z"
      fill="#BCBCBC"
    />
  </svg>
)

export default CrossIcon
