import moment, { duration } from 'moment-timezone'
import { plural } from './helpers'

export const EXP_DATE_FORMAT_SHORT = 'MM/YY'
export const EXP_DATE_FORMAT_LONG = 'MM/YYYY'
export const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY'
export const PAYLOAD_DATE_FORMAT = 'DD/MM/YYYY'
export const HYPHENED_DATE_FORMAT = 'YYYY-MM-DD'
export const DATE_WITH_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'
export const DOTTED_DATE_FORMAT = DEFAULT_DATE_FORMAT.replace(/[/]/g, '.')
export const DATE_WITH_TIMEZONE_FORMAT = `${DOTTED_DATE_FORMAT} LT z`
export const MONTH_ABBR = 'DD MMM YYYY'
export const DEFAULT_TAX_ID = 'XX-XXXXXXX'

export const getMask = () => DEFAULT_DATE_FORMAT.replace(/[^\d/-/.-]/g, '9')

export const dateMaskConfig = {
  mask: getMask(),
  maskChar: null
}

const timezone = moment.tz.guess()

export const formatDateFromString = (date: string) => {
  const isHyphened = moment(date, HYPHENED_DATE_FORMAT).isValid()

  if (isHyphened) {
    return moment(date, HYPHENED_DATE_FORMAT).format(DOTTED_DATE_FORMAT)
  }
  return moment(date, DEFAULT_DATE_FORMAT).format(PAYLOAD_DATE_FORMAT)
}

export const formatDateToString = (date: string) => {
  return moment(date).format(PAYLOAD_DATE_FORMAT)
}

export const formatDateWithTime = (date: string) => {
  return moment
    .utc(date)
    .tz(timezone)
    .format(DATE_WITH_TIME_FORMAT)
}
export const formatDateWithMonthAbbr = (date: string) => {
  return moment(date).format(MONTH_ABBR)
}
export const daysRemaining = (hours: number): number =>
  Math.floor(duration(hours, 'hours').asDays())

export const showHoursLeft = (hours: number): string =>
  `${hours} ${plural(['hour', 'hours'], hours)}`

export const timeRemaining = (hours: number): string => {
  const durationInHours = duration(hours, 'hours')

  const days = daysRemaining(hours)
  const weeks = Math.floor(durationInHours.asWeeks())
  const months = Math.floor(durationInHours.asMonths())

  if (durationInHours.asMonths() > 2) {
    return ''
  } else if (months) {
    return `${months} ${plural(['month', 'months'], months)}`
  } else if (weeks) {
    return `${weeks} ${plural(['week', 'weeks'], weeks)}`
  } else {
    return days ? `${days} ${plural(['day', 'days'], days)}` : '~1 day'
  }
}
export const changeDateFormatTo = (date: string) => {
  const [yyyy, mm, dd] = date.split('-')
  return `${dd}/${mm}/${yyyy}`
}

export const isAdult = (birthDate: string): boolean => {
  const years = moment().diff(birthDate, 'years')

  return years >= 18
}

export const formatMoney = (
  amount = 0,
  decimalCount = 0,
  decimal = '.',
  thousands = ','
) => {
  try {
    decimalCount = Math.abs(decimalCount)
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount

    const negativeSign = amount < 0 ? '-' : ''
    const absAmount = Math.abs(Math.round(amount * 100) / 100 || 0).toFixed(
      decimalCount
    )
    const i = parseInt(String(absAmount), 10).toString()
    const j = i.length > 3 ? i.length % 3 : 0

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(Number(absAmount) - Number(i))
            .toFixed(decimalCount)
            .slice(2)
        : '')
    )
  } catch (e) {
    return "Can't format number"
  }
}

export const formatNumbers = (value: number) => {
  return formatMoney(value, 0, ' ', ' ')
}

export const formatPrice = (value: number) => {
  const formattedValue: string | number = formatMoney(value, 2)
  const splitNumbers = formattedValue.split('.')
  const wholeNumber = splitNumbers[0]
  const decimal = Number(splitNumbers[1])

  if (decimal === 0) {
    return `$${wholeNumber}`
  }

  return `$${formattedValue}`
}

export const formatThousands = (
  amount = 0,
  decimalCount = 0,
  decimal = '.',
  thousands = 'K'
) => {
  try {
    decimalCount = Math.abs(decimalCount)
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount

    const absAmount = Math.abs(Number(amount) || 0).toFixed(decimalCount)
    const i = parseInt(String(absAmount), 10).toString()
    let j = i.length % 3 || 3

    if (i.length < 4) {
      return i
    }

    if (i.length > 9) {
      j = i.length - 6
    }

    thousands = absAmount.length > 6 ? 'M' : 'K'

    const numbersToShow = i.substr(0, j)
    const k = numbersToShow.length % 3 || 3

    return j
      ? numbersToShow.substr(0, k) +
          (numbersToShow.length > 3 ? decimal : '') +
          numbersToShow.substr(k).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
          thousands
      : ''
  } catch (e) {
    return "Can't format number"
  }
}

export const getDaysSincePosted = (date: string) =>
  moment(date)
    .local()
    .fromNow()
