import React, { FC } from 'react'
import { generatePath } from 'react-router-dom'
import AnalyticsManager from 'services/analyticService'
import { EventSubType, IEventTier } from 'redux/events/types'
import { Statuses } from 'redux/commonTypes'
import { displayStatus } from 'utils/helpers'
import { routePath } from 'routes'
import {
  Card,
  CheckoutLink,
  DescText,
  StatusButton,
  TierButton,
  TierInfo,
  TierTitle,
  WrapperBlock
} from './styles'

interface EventTierSub1Props {
  tier: IEventTier
  subType: EventSubType
  eventId?: number
  isActivePayment?: boolean
  showCheckoutBtn?: boolean
  isFanProfile?: boolean
  isBrand: boolean
}

const CheckoutButton = (
  tier: IEventTier,
  subType: EventSubType,
  id?: number
) => {
  const trackTierCheckout = () => {
    AnalyticsManager.trackTierCheckoutFromEvent(tier.id)
  }
  return tier.left > 0 ? (
    <CheckoutLink
      variant={'secondary'}
      to={generatePath(routePath.CHECKOUT, {
        id,
        tier: tier.id
      })}
      onClick={trackTierCheckout}
    >
      {subType === EventSubType.POS ? 'Purchase' : 'Contribute'}
    </CheckoutLink>
  ) : (
    <StatusButton variant={'secondary'}>Sold Out</StatusButton>
  )
}

export const EventTierSub1: FC<EventTierSub1Props> = ({
  tier,
  subType,
  eventId,
  isActivePayment,
  isBrand
}) => {
  return (
    <WrapperBlock>
      <Card>
        <TierInfo>
          <TierTitle>{tier.title}</TierTitle>
          <DescText>{tier.description}</DescText>
        </TierInfo>
        <TierButton>
          {!isBrand && isActivePayment && tier.status === Statuses.LIVE
            ? CheckoutButton(tier, subType, eventId)
            : tier.status !== Statuses.LIVE && (
                <StatusButton variant={'secondary'}>
                  {displayStatus(tier.status)}
                </StatusButton>
              )}
        </TierButton>
      </Card>
    </WrapperBlock>
  )
}
