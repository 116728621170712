import React, { RefObject, useEffect, useRef, useState } from 'react'
import { IInfluencer } from 'redux/influencer/types'
import { LogoIcon } from 'components/icons/index'
import InfluencerHeaderContent from './InfluencerHeaderContent'
import Loader from 'src/components/UI/Loader'
import PlayButtonIcon from 'src/components/icons/PlayButtonIcon'

import {
  ImgEvent,
  InfluencerCard,
  PlayButton,
  Player,
  PlayerWrapper,
  VideoPreview,
  FallbackImageWrapper
} from './styles'

interface Props {
  event: IInfluencer
}

export const fallbackImage = (
  <FallbackImageWrapper>
    <LogoIcon width={'50%'} height={'50%'} marginBottom={0} light={false} />
  </FallbackImageWrapper>
)

const InfluencerHeader: React.FC<Props> = ({ event }): React.ReactElement => {
  const { id, profile_photo, videos } = event
  const [isPlaying, setIsPlaying] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const ref: RefObject<HTMLVideoElement> = useRef(null)

  useEffect(() => {
    document.addEventListener('scroll', handleScroll)
    document.addEventListener('click', handleClick)
    document.addEventListener('touchend', handleClick)

    return () => {
      document.removeEventListener('scroll', handleScroll)
      document.removeEventListener('click', handleClick)
      document.removeEventListener('touchend', handleClick)
    }
  })

  const handleScroll = () => {
    const playerRect = ref.current
      ? ref.current.getBoundingClientRect()
      : ({} as ClientRect)
    if (
      playerRect.top <= -playerRect.height ||
      playerRect.top >= window.innerHeight ||
      window.scrollY === 0
    ) {
      handleStop()
    }
  }

  const handleClick = (e: any) => {
    const playerRect = ref.current
      ? ref.current.getBoundingClientRect()
      : ({} as ClientRect)
    const playerRectY = playerRect.top
    const element = e.target.getBoundingClientRect()
    const clickY = element.top
    const clickX = element.left

    if ((playerRect && clickY !== playerRectY) || clickY === playerRectY) {
      handleStop()
    }
    if (
      playerRect &&
      !isPlaying &&
      !clickY &&
      !clickX &&
      e.target.getAttribute('aria-label') === `Play-${id}`
    ) {
      handlePlay()
    }
  }

  const handleCanPlay = () => {
    setIsLoading(false)
  }

  const handlePlay = () => {
    setIsPlaying(true)
    if (ref.current) {
      ref.current.play()
    }
  }
  const handleStop = () => {
    if (ref.current) {
      setIsPlaying(false)
      setIsLoading(true)
      ref.current.pause()
    }
  }

  const handleTap = () =>
    ref.current &&
    (ref.current.paused ? ref.current.play() : ref.current.pause())

  return (
    <InfluencerCard>
      {videos && videos.length && videos[0].url ? (
        <>
          <PlayerWrapper id={'player-wrapper'}>
            {isPlaying && isLoading && <Loader />}
            <Player
              id={String(id)}
              src={videos[0].url}
              isLoading={isLoading}
              preload="auto"
              onPlaying={handleCanPlay}
              ref={ref}
              onEnded={handleStop}
              onTouchEnd={handleTap}
              playsInline={true}
              controls={isPlaying} // must be isPlaying instead of true/false for preventing bug
            />
            {!isPlaying && profile_photo && (
              <VideoPreview img={profile_photo} />
            )}
            {!isPlaying && !profile_photo && (
              <ImgEvent src={profile_photo} fallback={fallbackImage} />
            )}
          </PlayerWrapper>
        </>
      ) : (
        <ImgEvent src={profile_photo} fallback={fallbackImage} />
      )}
      <InfluencerHeaderContent
        isPlaying={ref.current !== null && isPlaying}
        event={event}
      >
        {videos && videos.length && videos[0] ? (
          <PlayButton aria-label={`Play-${id}`} onClick={handlePlay}>
            <PlayButtonIcon />
            Play Video
          </PlayButton>
        ) : (
          <></>
        )}
      </InfluencerHeaderContent>
    </InfluencerCard>
  )
}

export default InfluencerHeader
