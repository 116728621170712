import React, { FC, useState, useRef, useEffect } from 'react'
import { withRouter, useLocation } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import Media from 'react-media'
import { Col, Container, Row } from 'styled-bootstrap-grid'
import { selectCheckoutLastActivity } from 'redux/checkout/selectors'
import { logout } from 'redux/auth/operations'
import { fetchAllEvents } from 'redux/events/operations'
import { selectEventsCount } from 'redux/events/selectors'
import { selectIsAuth, selectUser } from 'redux/auth/selectors'
import { routePath } from 'routes'

import Avatar from 'components/common/Avatar'

import Burger from 'components/UI/Burger'
import Button from 'components/UI/Button'
import NavLink from 'components/UI/NavLink'

import { configUserLinks } from './links'
import fullWhiteLogo from 'components/icons/logo/FullWhiteLogo.png'
import Menu from './Menu'
import Link from 'components/UI/Link'
import ShopIcon from './ShopIcon'
import GenericModal from 'src/components/UI/GenericModal'

import {
  NavBar,
  UserMenuItem,
  NavWrapper,
  ContentWrapper,
  RightNavBlock,
  LoginLink,
  BurgerWrapper,
  CloseButton,
  UserMenuList,
  LogoWrapper
} from './styles'
import { WEBSITE } from 'utils/constants'

const Header: FC = () => {
  const dispatch = useDispatch()
  const isAuthenticated = useSelector(selectIsAuth)
  const lastActivity = useSelector(selectCheckoutLastActivity)
  const eventsCount = useSelector(selectEventsCount)
  const { first_name, last_name, email, profile_photo } = useSelector(
    selectUser
  )
  const [isShown, setIsShown] = useState(false)
  const [isCartOpened, setIsCartOpened] = useState(false)
  const [isShownUserMenu, setIsShownUserMenu] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false)
  const navbarRef = useRef<HTMLDivElement | null>(null)
  const userMenuRef = useRef<HTMLUListElement | null>(null)
  const location = useLocation()
  const initials = `${first_name} ${last_name}` || email

  useEffect(() => {
    dispatch(fetchAllEvents())
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', changeNavbarColor)
    window.addEventListener('mousedown', handleClickOutside)

    return () => {
      window.removeEventListener('scroll', changeNavbarColor)
      window.removeEventListener('mousedown', handleClickOutside)
    }
  })

  const handleClickOutside = (event: any) => {
    const userMenu = userMenuRef.current as HTMLUListElement
    if (userMenu && !userMenu.contains(event.target)) {
      closeUserMenu()
    }
  }

  const toggleMenu = () => setIsShown(!isShown)
  const hideMenu = () => setIsShown(false)

  const closeCart = () => {
    if (isCartOpened) {
      setIsCartOpened(false)
    }
  }

  const closeUserMenu = () => setIsShownUserMenu(false)
  const toggleUserMenu = () => {
    hideMenu()
    setIsShownUserMenu(!isShownUserMenu)
  }

  const handleLogout = () => dispatch(logout())

  const changeNavbarColor = () => {
    const navbar = navbarRef.current as HTMLDivElement

    setIsScrolled(document.documentElement.scrollTop > navbar.offsetHeight)
  }

  const closeMenus = () => {
    if (isShown) {
      hideMenu()
    }
    closeUserMenu()
  }

  const renderUserMenu = () => {
    const links = configUserLinks(lastActivity)

    return (
      <nav>
        <UserMenuList onMouseEnter={closeCart} ref={userMenuRef}>
          {links.map(link => (
            <UserMenuItem key={link.title}>
              <NavLink to={link.url} onClick={closeMenus}>
                {link.title}
              </NavLink>
            </UserMenuItem>
          ))}
          <UserMenuItem>
            <Link to={'#'} variant={'clear'} onClick={handleLogout}>
              Log Out
            </Link>
          </UserMenuItem>
        </UserMenuList>
      </nav>
    )
  }
  const renderAuthLink = () => {
    return (
      <>
        {location.pathname !== routePath.SIGN_IN ? (
          <LoginLink to={routePath.SIGN_IN}>log in</LoginLink>
        ) : (
          <LoginLink to={routePath.SIGN_UP}>sign up</LoginLink>
        )}
      </>
    )
  }
  return (
    <NavBar isOpen={isShown} ref={navbarRef} isScrolled={isScrolled}>
      <Container className={'head-container'}>
        <Media
          queries={{
            small: '(max-width: 767px)',
            large: '(min-width: 768px)'
          }}
        >
          {matches => (
            <ContentWrapper>
              <NavWrapper>
                <Row
                  alignItems={'center'}
                  style={{ width: '100%', margin: '0 auto' }}
                >
                  <Col xs={2} sm={3} md={4} style={{ padding: 0 }}>
                    <BurgerWrapper>
                      <Burger handleClick={toggleMenu} isClicked={isShown} />
                      {matches.large && (
                        <>
                          <CloseButton onClick={toggleMenu} variant={'clear'}>
                            {isShown ? 'close' : 'menu'}
                          </CloseButton>
                          {!isShown && (
                            <LoginLink
                              to={routePath.INFLUENCER}
                              style={{ marginLeft: 30 }}
                            >
                              creators
                            </LoginLink>
                          )}
                        </>
                      )}
                    </BurgerWrapper>
                  </Col>
                  <LogoWrapper xs={8} sm={6} md={4}>
                    <Link to={WEBSITE}>
                      <img
                        src={fullWhiteLogo}
                        alt={'fanvestor-logo'}
                        height={matches.small ? 20 : 30}
                      />
                    </Link>
                  </LogoWrapper>
                  <Col
                    xs={2}
                    sm={3}
                    md={4}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      padding: 0
                    }}
                  >
                    {isAuthenticated ? (
                      <RightNavBlock>
                        <ShopIcon
                          hideMenu={hideMenu}
                          isCartOpened={isCartOpened}
                          setIsCartOpened={setIsCartOpened}
                        />
                        <Button variant={'icon'} onClick={toggleUserMenu}>
                          <Avatar
                            src={profile_photo}
                            name={initials}
                            size={45}
                          />
                        </Button>
                        {matches.small ? (
                          <GenericModal
                            isOpen={isShownUserMenu}
                            onClose={closeUserMenu}
                            style={{
                              background: 'transparent',
                              top: '75%'
                            }}
                            overlayStyle={{
                              backgroundColor: 'rgba(9, 11, 22, 0.85)'
                            }}
                          >
                            {renderUserMenu()}
                          </GenericModal>
                        ) : (
                          isShownUserMenu && renderUserMenu()
                        )}
                      </RightNavBlock>
                    ) : (
                      <>{renderAuthLink()}</>
                    )}
                  </Col>
                </Row>
              </NavWrapper>
              {isShown && (
                <Menu eventsCount={eventsCount} hideMenu={hideMenu} />
              )}
            </ContentWrapper>
          )}
        </Media>
      </Container>
    </NavBar>
  )
}

export default withRouter(Header)
