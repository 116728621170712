import styled from 'styled-components'

export const ProgressWrapper = styled.div`
  width: 100%;
  height: 8px;
  background: #1c213c;
  position: relative;
  border-radius: 0.75rem;
  overflow: hidden;
`

export const Progress = styled.div<{
  progress: number
  isEndedEvent?: boolean
}>`
  position: absolute;
  height: 100%;
  width: ${p => `${p.progress}%`};
  border-radius: 0.75rem;
  background: ${p => (p.isEndedEvent ? '#848484' : p.theme.colors.fuchsia)};
`
