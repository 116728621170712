import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export const SummaryList = styled.ul`
  font-family: Inter;
  width: 100%;
  margin-bottom: 2.875rem;
  color: ${p => p.theme.colors.white};
  text-align: left;
  list-style: none;
`

export const ListItem = styled.li<{ rowAmount?: number }>`
  width: 100%;
  padding: 1rem 0;
  font-family: Inter;
  font-weight: 300;
  text-transform: capitalize;

  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;

  /* Description */
  div {
    color: ${p => p.theme.colors.black};
  }

  span {
    margin-top: 0.375rem;
    font-size: 0.825rem;
    font-weight: 400;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #e6e6e6;
  }

  ${media.md`
    display: block;
    font-size: 1rem;

    span {
      font-size: .825rem;
      padding-bottom: 1.75rem;
    }
  `}

  ${media.lg`
    font-size: 1.25rem;
  `}
`

export const AmountInvest = styled.div`
  width: auto;
  font-weight: 900;
  font-size: 1.125rem;
  font-family: Inter;
  margin-bottom: 0;
  color: ${p => p.theme.colors.black};

  ${media.md`
    color: #353535;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  `}
`

export const AmountTitle = styled.div`
  font-family: Inter;
  font-size: 0.875rem;
  color: ${p => p.theme.colors.black};

  ${media.md`
    font-size: 1rem;
  `}
`
