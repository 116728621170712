import styled from 'styled-components'
import Link from 'components/UI/Link'
import { media } from 'styled-bootstrap-grid'

export const LinkWrapper = styled(Link)`
  width: 100%;
  min-height: 310px;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 25rem;
  padding-top: 45px;

  ${media.md`
    min-height: 500px;
    margin-bottom: 40px;
    width: 50%;
  `}

  div:first-child,
  img {
    display: block;
    border-radius: 100px;
    object-fit: cover;
    width: 120px;
    height: 120px;
    margin: 0 auto;
    ${media.lg`
     width: 200px;
     height: 200px;
  `}
  }

  div {
    background: #eee;

    img {
      width: 70% !important;
      height: 80% !important;
      margin-top: 30px;
      ${media.md`
         width: 70% !important;
      height: 80% !important;
  `}
    }
  }

  :hover {
    box-shadow: 0 15px 115px rgba(0, 0, 0, 0.35);
    border-radius: 18px;

    p {
      display: -webkit-box;
    }
  }
`

export const InfluencerName = styled.p`
  max-width: 20rem;
  margin: 15px auto 10px;
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 1.813rem;
  text-align: center;
  color: ${p => p.theme.colors.purple};
  ${media.lg`
     margin: 30px auto 10px;
     font-size: 2.25rem;
     line-height: 2.723rem;
  `}
`

export const Status = styled.p`
  font-size: 0.938rem;
  font-weight: 300;
  color: #12152a;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 5px;
  opacity: 0.5;

  ${media.md`
    font-size: 1.125rem;
    margin-bottom: 10px;
  `}
`

export const Description = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  color: ${p => p.theme.colors.black};
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.625rem;
  text-align: center;
  max-width: 375px;
  height: 5rem;

  margin: 0 auto;

  ${media.md`
    font-size: 1.375rem;
    line-height: 2rem;
    height: 6rem;
  `}
`
