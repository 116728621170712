import React from 'react'
import { SectionTitle } from 'containers/invest/InvestDetailPage/styles'
import {
  DescripUnderTitle,
  PhotoManager,
  SectionWrapperAbsolute,
  TeamMembers,
  DescriptionColumn
} from './styles'
import { EventSection } from 'redux/events/types'
import { Col } from 'styled-bootstrap-grid'
import { theme } from 'styled/theme'

const ManagementTeam: React.FC<{
  section: EventSection | null | undefined
}> = ({ section }) => {
  return (
    <SectionWrapperAbsolute
      id={'management-team'}
      style={{ transform: 'translateY(-300px)', marginBottom: '-300px' }}
    >
      <SectionTitle color={theme.colors.black}>Management Team</SectionTitle>
      <DescripUnderTitle color={theme.colors.black}>
        To be successful, you must have the right team and disciplines to make
        the 3-legged stool stand.
      </DescripUnderTitle>
      <TeamMembers>
        {section &&
          section.items.map(el => {
            return (
              <Col key={el.id} md={4} className="manager-column">
                <PhotoManager>
                  <img src={el.file_url} alt="" />
                </PhotoManager>

                <DescriptionColumn
                  dangerouslySetInnerHTML={{
                    __html: el.description!
                  }}
                />
              </Col>
            )
          })}
      </TeamMembers>
    </SectionWrapperAbsolute>
  )
}

export default ManagementTeam
