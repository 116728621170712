import React, { FC } from 'react'
import moment from 'moment-timezone'
import { showHoursLeft, timeRemaining } from 'utils/dateFormats'
import { displayStatus } from 'utils/helpers'
import { Statuses } from 'redux/commonTypes'

import { TimeLeft, EventStatus } from './styles'

const DaysLeft: FC<{
  eventStatus: Statuses
  hoursLeft: number
  showHours?: boolean
  eventEndDate?: string
}> = ({ eventStatus, hoursLeft, showHours = false, eventEndDate }) => {
  const isLive = eventStatus === Statuses.LIVE
  const status = displayStatus(eventStatus)
  const endDate =
    showHours && hoursLeft < 24
      ? showHoursLeft(hoursLeft)
      : timeRemaining(hoursLeft)

  const showEventStatus = () => {
    switch (eventStatus) {
      case Statuses.LIVE:
        return `${endDate} left`
      case Statuses.COMING_SOON:
        return status
      case Statuses.ENDED:
        return `${status} on \n${moment(eventEndDate).format('DD MMM YYYY')}`
    }
  }

  if (isLive && !endDate) {
    // return div for styling reasons
    return <div />
  }

  return (
    <TimeLeft>
      <EventStatus>{showEventStatus()}</EventStatus>
    </TimeLeft>
  )
}

export default DaysLeft
