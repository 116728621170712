import axios from 'axios'
import { IPagination, IStatus, MetaAndLinks } from 'redux/commonTypes'
import {
  EventCategories,
  IEvent,
  IEventDetail,
  IEventsByInfluencer,
  IEventsData,
  ITier
} from 'redux/events/types'
import {
  IComment,
  IEventComments,
  IReply,
  ReportedComment,
  ReportHarassment,
  ReportReason,
  ReportReasonType,
  ReportViolence
} from 'redux/events/comments/types'

export const EventsApi = {
  getEvents: async (params: GetEventsRequest) =>
    await axios.get<GetEventsResponse>('/events', {
      params
    }),

  getAllEvents: async () => await axios.get<GetEventsResponse>('/events'),

  getEventsByInfluencer: async (obj: GetEventsByInfluencerRequest) => {
    const { influencerId, eventStatus, params } = obj

    return await axios.get<GetEventsByInfluencerResponse>(
      `/profile/${influencerId}/events/${eventStatus}`,
      { params }
    )
  },

  getHomeEvents: async (obj: GetHomeEventsRequest) => {
    const { params, category } = obj
    const url =
      category === EventCategories.ALL
        ? '/events/preferred'
        : `/events/user-recommended/${category}`
    return await axios.get<GetHomeEventsResponse>(url, { params })
  },

  getEvent: async (eventId: number) =>
    await axios.get<GetEventResponse>(`/events/${eventId}`),

  getTier: async (eventId: number | string, tierId: number | string) =>
    await axios.get<GetTierResponse>(`events/${eventId}/tiers/${tierId}`),

  likeEvent: async (eventId: number) =>
    await axios.post<LikeEventResponse>(`events/${eventId}/like`),

  getComments: async (obj: GetCommentsRequest) => {
    const { eventId, params } = obj
    return await axios.get<GetCommentsResponse>(`events/${eventId}/comments`, {
      params
    })
  },

  getCommentTread: async (route: string) => {
    const url = route.replace('https://stage.edenledger.com/api/v1/', '')
    return await axios.get<GetCommentTreadResponse>(url)
  },

  sendComment: async (eventId: number, content: string) =>
    await axios.post<SendCommentResponse>(`events/${eventId}/comments`, {
      content
    }),

  replyToComment: async (eventId: number, commentId: number, content: string) =>
    await axios.post<ReplyToCommentResponse>(
      `events/${eventId}/comments/${commentId}/reply`,
      { content }
    ),

  likeComment: async (eventId: number, commentId: number | null) =>
    await axios.post<IStatus>(`events/${eventId}/comments/${commentId}/like`),

  reportComment: async (obj: ReportCommentRequest) => {
    const { eventId, commentId, params } = obj
    return await axios.post<ReportCommentResponse>(
      `events/${eventId}/comments/${commentId}/report`,
      params
    )
  },

  getReplies: async (obj: GetRepliesRequest) => {
    const { eventId, commentId, params } = obj
    return await axios.get<IEventComments>(
      `/events/${eventId}/comments/${commentId}/replies`,
      { params }
    )
  }
}

interface GetEventsRequest {
  page: number
  type?: number
  include?: string
}

interface GetEventsResponse extends MetaAndLinks {
  data: IEventDetail[]
  tabs: Array<{ [k: string]: string }>
}

interface GetEventsByInfluencerRequest {
  influencerId: number
  eventStatus: IEventsByInfluencer
  params: { page?: number; type?: number }
}

interface GetEventsByInfluencerResponse {
  data: IEvent[]
  pagination: IPagination
}

interface GetHomeEventsRequest {
  params: { page?: number }
  category: EventCategories
}

interface GetHomeEventsResponse {
  isFetching: boolean
  recommended: IEventsData
  trended: IEventsData
}

interface GetEventResponse {
  data: IEventDetail
}

interface GetTierResponse {
  data: ITier
}

interface LikeEventResponse {
  status: boolean
}

interface GetCommentsRequest {
  eventId: number
  params: { page: number }
}
type GetCommentsResponse = IEventComments

interface GetCommentTreadResponse {
  data: IComment
  reply_id: number
}

interface SendCommentResponse {
  data: IComment
}

interface ReplyToCommentResponse {
  data: IReply
}

interface ReportCommentRequest {
  eventId: number
  commentId: number | null
  params: {
    reason: ReportReason
    reason_type?: ReportReasonType
    reason_type_details?: ReportViolence | ReportHarassment
  }
}

interface ReportCommentResponse {
  data: ReportedComment
}

interface GetRepliesRequest {
  eventId: number
  commentId: number
  params: {
    reply_id?: number
  }
}
