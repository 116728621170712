import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { Link } from 'src/components/UI/Link'
import { ClearButton } from 'src/components/UI/Button'

export const Details = styled.div`
  padding: 10px 10px;
  background-color: ${p => p.theme.colors.navy};
  border-radius: 12px;
  z-index: 10;
  ${media.md`
    padding: 15px 25px;
  `}
`

export const DetailsButton = styled(ClearButton)`
  font-size: 1rem;
  color: ${p => p.theme.colors.fuchsia};
  margin: 0.5rem 0 0;
  @media (max-width: 500px) {
    width: 100%;
    text-align: left;
  }

  ${media.md`
    margin: 0;
  `}
`

export const DetailsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 70%;

  ${media.md`
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
  `}

  > div {
    margin-top: 10px;

    ${media.md`
     margin-top: 0;
     margin-left: 20px;
  `}
  }
`

export const Status = styled.div`
  margin: 1.75rem 0;
  &:nth-child(2) {
    text-transform: capitalize;
  }
`
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
`

export const CloseButton = styled(ClearButton)`
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  color: ${p => p.theme.colors.fuchsia};
  opacity: 1;
  :hover {
    opacity: 0.7;
  }
  svg {
    margin-right: 5px;
  }
  path {
    fill: ${p => p.theme.colors.fuchsia};
    opacity: 0.8;
  }
`

export const WrapperBlock = styled.div.attrs(() => ({
  'data-testid': 'tier-block'
}))`
  margin-bottom: 10px;
  transition: margin-bottom 0.3s;
  padding: 10px 10px;
  background-color: ${p => p.theme.colors.navy};
  border-radius: 12px;
  ${media.md`
    padding: 15px 25px;
  `}
`
export const TopCard = styled.div`
  width: 100%;
  margin-bottom: 0.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const WrapperCard = styled.div`
  width: 100%;
  position: relative;
`

export const ItemsNumber = styled.p`
  color: ${p => p.theme.colors.white};
  font-size: 1rem;
  font-weight: 400;
  ${media.md`
    font-size: 1.25rem;
  `}
`
export const Date = styled.p`
  color: ${p => p.theme.colors.lightGrey};
  font-size: 0.75rem;
  margin: 0.5rem 0 0;
  span {
    font-size: 1rem;
    color: ${p => p.theme.colors.white};
    font-weight: 500;
  }
  ${media.sm`
    margin: 0;
    font-size: 1rem;
  `}

  &#status {
    ${media.md`
    width: 33%;
    text-align: center;
  `}
  }
`

export const TierTitle = styled(ItemsNumber).attrs(() => ({ as: 'h4' }))`
  font-size: 1rem;
  line-height: 19px;
  font-weight: 700;
  color: ${p => p.theme.colors.white};

  ${media.md`
    font-size: 1.25rem;
    line-height: 1.5rem;
  `}

  ${media.lg`
    max-width: 455px;
  `}
  span {
    font-size: 0.75rem;
    font-weight: 300;
    margin-left: 8px;
    color: ${p => p.theme.colors.wetWhite};
  }
`

export const LinkDetails = styled(Link)`
  padding-right: 1.125rem;
  font-size: 1rem;
  line-height: 19px;
  font-weight: 600;
  color: ${p => p.theme.colors.fuchsia};

  ${media.md`
    font-size: 1.25rem;
    line-height: 1.5rem;
  `}
  :hover {
    opacity: 0.8;
  }
`

export const InfoTitle = styled.p`
  margin: 5px 0;
  padding-right: 1.125rem;
  font-size: 1rem;
  line-height: 19px;
  font-weight: 700;
  color: ${p => p.theme.colors.white};

  ${media.md`
    line-height: 1.5rem;
  `}
  &#influencer_name {
    padding-right: 0;
  }
`

export const WrapperCol = styled.div`
  width: 100%;
  margin-bottom: 10px;
  ${media.md`
    width: unset;
  `}
  &:nth-of-type(2) {
    min-width: 242px;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0;
  width: 100%;

  &:last-of-type {
    margin-bottom: 0;
  }

  &#title-and-amount {
    margin-bottom: 15px;
    align-items: flex-end;
    ${media.md`
    align-items: center;
  `}
  }

  img {
    width: 185px;
    height: 110px;
    border-radius: 12px;
    object-fit: cover;
  }

  ${media.md`
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0;
  `}
`

export const WrapperTitle = styled(Wrapper)`
  flex-direction: column;
  align-items: start;
  ${media.md`
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0;
  `}
`

export const Block = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 400px;
  ${media.md`
   max-width: unset;
  `}
`

export const TitleAndPrice = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  ${media.md`
     width: 65%;
  `}
`

export const SetTitle = styled.div`
  ${media.md`
     width: 65%;
  `}
`

export const PriceBlock = styled.div`
  font-size: 1rem;
  min-width: 40%;
  text-align: right;
  ${media.md`
     width: 65%;
  `}
`

export const Info = styled.p`
  color: ${p => p.theme.colors.white};
  font-size: 0.875rem;
  text-transform: capitalize;
  line-height: 1.25rem;

  ${media.sm`
    font-size: 1rem;
    line-height: 1.5rem;
  `}
`

export const InfoDetails = styled(Info)`
  font-size: 1rem;
  color: ${p => p.theme.colors.white};
`

export const InfoPrice = styled(InfoDetails)`
  font-family: Lato, sans-serif;
  * {
    font-family: Lato, sans-serif;
  }
`

export const Price = styled(Info).attrs(() => ({ as: 'span' }))`
  font-family: Lato, sans-serif;
  font-size: 1rem;
`

export const SetsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 200px;
  scrollbar-color: rgba(255, 255, 255, 0.4) ${p => p.theme.colors.navy};
  scrollbar-width: thin;
  width: -moz-max-content;
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: ${p => p.theme.colors.navy};
  }

  &::-webkit-scrollbar {
    width: 4px;
    background-color: rgba(255, 255, 255, 0.4);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.4);
  }
`
export const Set = styled.div`
  display: flex;
  #influencer_name {
    @media (max-width: 372px) {
      font-size: 13px;
    }
  }
  > div {
    margin-top: 10px;
  }
`
export const EventPhotoWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 12px;
  position: relative;
  width: 185px;
  height: 110px;
  
  &:before {
    content: '';
    display: block;
    position: absolute;
    background-color: ${p => p.theme.colors.wetWhite};
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  > div {
    z-index: 1;
    background: none;
    > img {
      width: 20px !important;
      height: 20px !important;
      ${media.sm`
        width: 60px !important;
        height: 60px !important;
      `}
  }
`

export const SetsInfo = styled.span`
  color: #777777;
  font-size: 0.825rem;
  padding-right: 6px;
`

export const TextWrapper = styled.div`
  padding: 5.875rem 1rem;
  text-align: center;
`
export const Text = styled.p`
  margin: 0 auto 2rem;
  color: ${p => p.theme.colors.lightGrey};
  font-size: 1.25rem;
  letter-spacing: 0.2px;
  line-height: 2rem;
`
export const EventsLink = styled(Link)`
  padding-right: 1.625rem;
  color: ${p => p.theme.colors.fuchsia};
  font-size: 1.125rem;
  transition: all 0.2s ease-out;
  position: relative;

  :after {
    content: '';
    width: 0.625rem;
    height: 0.625rem;
    border-top: 0.125rem solid ${p => p.theme.colors.fuchsia};
    border-right: 0.125rem solid ${p => p.theme.colors.fuchsia};
    position: absolute;
    right: 0;
    transform: translate(0, 50%) rotate(45deg);
    bottom: 50%;
  }

  :hover {
    color: ${p => p.theme.colors.white};

    :after {
      border-top: 0.125rem solid ${p => p.theme.colors.white};
      border-right: 0.125rem solid ${p => p.theme.colors.white};
    }
  }
`
