import styled from 'styled-components'
import { media, Row } from 'styled-bootstrap-grid'

export const ShippingFormWrapper = styled.div`
  padding: 2.25rem calc(10vw - 1rem) 3.125rem calc(10vw - 1rem);
  margin-bottom: 30px;
  background: ${p => p.theme.colors.darkSmoky};

  ${media.sm`
    padding: 2.25rem 2.5rem 3.125rem 2.5rem;
  `}
`

export const FormTitle = styled.h3`
  font-family: Poppins;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1.5rem;
  line-height: 2.5rem;
  padding-bottom: 33px;
  ${media.sm`
    font-size: 1.875rem;
    line-height: 3.5rem;
  `}
`

export const FormTitleEmail = styled(FormTitle)`
  ${media.md`
    padding-bottom: 20px;
  `}
`

export const BlockedPayments = styled.div`
  padding: 2.5rem 2.25rem 0;
  background: ${p => p.theme.colors.darkSmoky};
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    color: #ff0f00;
    margin-bottom: 5px;
  }
`

export const Login = styled.div`
  width: 100%;
  div {
    ${media.md`
     margin: 0 !important;
  `}
  }
  ${Row} {
    align-items: flex-end;
  }
  span {
    font-size: 1rem;
    color: #a4a4a4;
  }
  button {
    display: block;
    margin-top: 12px;
    width: 100%;
    color: ${p => p.theme.colors.darkenGold};
    border-color: 1px solid ${p => p.theme.colors.darkenGold};
    ${media.sm`
    max-width: 300px;
  `}
  }
  ${media.md`
    flex-direction: row;
    align-items: flex-end;
  `}
`
