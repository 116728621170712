import styled, { css } from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import Link from 'components/UI/Link'

export const Wrapper = styled.div<{ isFanProfile?: boolean }>`
  padding: ${p => !p.isFanProfile && '4rem 0'};
  overflow: hidden;

  a {
    text-decoration: underline;
  }

  b {
    font-size: 0.75rem;
  }

  ${media.md`
    b {
    font-size: 1rem;
  }
  `}

  ${p =>
    p.isFanProfile &&
    css`
      padding: 1.625rem 1rem 1.875rem;
      border: 0.125rem solid ${p.theme.colors.white};
      border-radius: 0.5rem;

      ${media.md`
      padding-left: 2.25rem;
      padding-right: 2.25rem;
      `}
    `}
`
export const Title = styled.h1`
  margin-bottom: 1rem;
  font-size: 1.375rem;

  ${media.md`
    margin-bottom: 1.75rem;
    font-size: 1.5rem;
    font-weight: bold;
  `}
`
export const Text = styled.p`
  margin-bottom: 1.75rem;
  font-weight: 500;
  line-height: 1.25rem;

  ${media.lg`
    font-size: 1.25rem;
    line-height: 1.5rem;
  `}

  :last-child {
    margin-bottom: 0;
  }
`
export const List = styled.ul`
  list-style: none;
  color: ${p => p.theme.colors.white};
`
export const ListItem = styled(Text).attrs(() => ({ as: 'li' }))`
  font-size: 0.875rem;

  :before {
    content: '\\2014\\a0';
    width: 10px;
  }
`
export const TableListItem = styled(Text).attrs(() => ({ as: 'div' }))`
  margin: 0;
  padding: 0.5rem 0;
  font-size: 0.875rem;
  border-top: 1px solid #252525;

  h3 {
    margin-top: 0;
  }

  ${media.md`
    width: 35%;
    border-top: none;
    border-right: 1px solid #252525;
  
    h3 {    
      padding-left: 0.5rem;
    }
  
    p, ul {
      padding-left: 0.5rem;
      padding-bottom: 1rem;
    }
    
    :first-child {
      p, ul, h3 {
        padding: 0;
      }
    }

    :last-child {
      width: 65%;
      border-right: none;
    }
  `}
`
export const SubTitle = styled.h2`
  margin: 1rem 0 0.5rem;
  font-size: 1.125rem;
  font-weight: bold;
  text-transform: capitalize;

  ${media.lg`
    font-size: 1.25rem;
  `}
`
export const Heading = styled(SubTitle).attrs(() => ({ as: 'h3' }))`
  color: #fff;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;

  ${media.md`
    line-height: 1.5rem;
  `}
`
export const SubHeading = styled(Heading).attrs(() => ({ as: 'h4' }))`
  height: auto;
  margin-top: 0;
  font-style: italic;
`
export const TextLink = styled(Link)`
  color: ${p => p.theme.colors.fuchsia};

  ${media.lg`
    font-size: 1.25rem;
    line-height: 1.5rem;
  `}
`
export const Table = styled.div`
  display: flex;
  flex-direction: column;

  ${media.md` 
  h3 {
    border-bottom: 1px solid #252525;
  }
  
  > div:nth-child(n + 2) h3 {
    display: none;
  }
  
  >div {
      border-bottom: 1px solid #252525;
  }

  h4, > div:last-child {
    border-bottom: none;
  }
  
  .small-section {
    width: 33%;
  }
  
  .medium-section {
    width: 35%;
  }
  
  .large-section {
    width: 65%;
  }
`}
`
export const TableItemWrapper = styled.div`
  ${media.md`
    display:flex;
  `}
`

export const HeaderWrapper = styled.div`
  padding-bottom: 1.875rem;
  background-color: ${p => p.theme.colors.black};
`
