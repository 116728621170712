import React from 'react'
import { Route, RouteProps, Switch } from 'react-router-dom'
import { routePath } from 'routes'
import { AppState } from 'store'
import { connect } from 'react-redux'
import InfluencerPage from 'containers/influencer/InfluencerPage'
import InfluencerDetailPage from 'containers/influencer/InfluencerDetailPage'
import CommonTemplate from '../components/content/CommonTemplate'

const InfluencerRoutes = (props: Props) => (
  <Switch>
    <CommonTemplate background={'#fff'}>
      <Route
        exact={true}
        path={routePath.INFLUENCER}
        component={InfluencerPage}
      />
      <Route
        exact={true}
        path={routePath.INFLUENCER_PAGE}
        component={InfluencerDetailPage}
      />
    </CommonTemplate>
  </Switch>
)

type StateProps = ReturnType<typeof mapStateToProps>
export type Props = StateProps & RouteProps

const mapStateToProps = (state: AppState) => ({
  isAuthenticated:
    state.auth.isAuthenticated && !state.auth.isForceChangePassword
})

export default connect(mapStateToProps)(InfluencerRoutes)
