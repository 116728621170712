import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  ContactFields,
  EntityType,
  ICompany,
  IContact,
  IIRA,
  ITrust
} from 'redux/investment/types'
import {
  selectCurrentStep,
  selectEntityAddressInfo,
  selectInvestmentErrors
} from 'redux/investment/selectors'
import { setErrors, setInvestmentStep } from 'redux/investment/actions'
import {
  checkProhibitedCountry,
  getAddressErrors,
  getCompanyInfoErrors,
  getContactErrors,
  getIraErrors,
  getTrustErrors
} from 'redux/investment/operations'
import { noSpaceRegex, validNameRegex } from 'utils/helpers'
import debounce from 'utils/debounce'
import { Controls } from 'src/components/investment/steps'
import TextInput from 'src/components/UI/TextInput'

import { Form } from 'src/components/investment/steps/styles'

const EntityContactForm: FC<{ [key: string]: any }> = props => {
  const dispatch = useDispatch()
  const currentStep = useSelector(selectCurrentStep)
  const entityContactAddress = useSelector(selectEntityAddressInfo)
  const errors = useSelector(selectInvestmentErrors)
  const {
    selectedEntityContactInfo,
    setSelectedEntityContactInfo: setContactForm,
    selectedEntity,
    selectedEntityInfo
  } = props

  const contactForm = selectedEntityContactInfo as IContact
  const representative =
    selectedEntity === EntityType.TRUST ? 'Custodian' : 'Contact'
  const [entityContactForm, setEntityContactForm] = useState<IContact>(
    {} as IContact
  )

  useEffect(() => {
    setEntityContactForm(contactForm)
    // eslint-disable-next-line
  }, [contactForm])

  useEffect(() => {
    setToContext(entityContactForm)
    // eslint-disable-next-line
  }, [entityContactForm])

  const setToContext = useCallback(debounce(setContactForm, 300), [])
  const onChange = async (name: string, value: string) => {
    let currentValue = value

    if (['first_name', 'last_name'].includes(name)) {
      currentValue = value
        .replace(validNameRegex, '')
        .replace(noSpaceRegex, '')
        .trim()
    }

    if (name === 'email') {
      currentValue = value.replace(noSpaceRegex, '').trim()
    }

    setEntityContactForm({
      ...entityContactForm,
      [name]: currentValue
    })

    if (errors[`entity_${name}`]) {
      dispatch(setErrors({ ...errors, [`entity_${name}`]: '' }))
    }
  }

  const checkRestrictedCountry = async (
    country: string,
    cityName: string,
    countryLabel: string,
    isEntity: boolean
  ) => {
    let data
    let countryError
    let field
    try {
      data = await dispatch(
        checkProhibitedCountry(
          { iso_code: country, city_name: cityName },
          isEntity
        )
      )
      // @ts-ignore
      const fieldName = data.type === 'city' ? 'state' : 'country'
      field = isEntity ? `entity_${fieldName}` : fieldName
    } catch (e) {
      console.error(e)
    } finally {
      countryError = {
        // @ts-ignore
        [field]: data.restricted
          ? `We are sorry, we cannot accept investments from ${
              // @ts-ignore
              data.type === 'country' ? countryLabel : cityName
            }`
          : ''
      }
    }
    return countryError
  }

  const onClick = async () => {
    const contactErrors = getContactErrors(contactForm, representative)
    const updatedEntityContactErrors: object = Object.keys(
      contactErrors
    ).reduce(
      (acc, fieldName) => ({
        ...acc,
        [`entity_${fieldName}`]: contactErrors[fieldName]
      }),
      {}
    )
    const restrictedCountry = await checkRestrictedCountry(
      entityContactAddress.country,
      entityContactAddress.state_label || entityContactAddress.state,
      entityContactAddress.country_label,
      true
    )

    const addressErrors = getAddressErrors(entityContactAddress)
    const updatedEntityAddressErrors: object = Object.keys(
      addressErrors
    ).reduce(
      (acc, fieldName) => ({
        ...acc,
        [`entity_${fieldName}`]: addressErrors[fieldName]
      }),
      {}
    )
    let entityErrors = {}
    switch (selectedEntity) {
      case EntityType.TRUST:
        entityErrors = getTrustErrors(selectedEntityInfo as ITrust)
        break
      case EntityType.IRA:
        entityErrors = getIraErrors(selectedEntityInfo as IIRA)
        break
      case EntityType.ENTITY:
        entityErrors = getCompanyInfoErrors(selectedEntityInfo as ICompany)
        break
      default:
        return null
    }

    dispatch(
      setErrors({
        ...restrictedCountry,
        ...(entityErrors as ITrust | IIRA | ICompany),
        ...updatedEntityContactErrors,
        ...updatedEntityAddressErrors
      })
    )
    const isValid =
      !Object.keys({
        ...contactErrors,
        ...addressErrors,
        ...entityErrors
      }).length && !Object.values(restrictedCountry).some(r => r)

    if (isValid) {
      dispatch(setInvestmentStep(currentStep + 1))
    }
  }

  const { first_name, last_name, email, phone, phone_code } = entityContactForm

  return (
    <>
      <Form>
        <TextInput
          name={'first_name'}
          value={first_name}
          label={`${representative} ${ContactFields.first_name}`}
          handleChange={onChange}
          wrapperClassName={'form__first-field'}
          errorLabel={errors.entity_first_name}
          maxLength={64}
        />
        <TextInput
          name={'last_name'}
          value={last_name}
          label={`${representative} ${ContactFields.last_name}`}
          handleChange={onChange}
          errorLabel={errors.entity_last_name}
          maxLength={64}
        />
        <TextInput
          type={'text'}
          name={'email'}
          value={email}
          label={`${representative} ${ContactFields.email}`}
          handleChange={onChange}
          errorLabel={errors.entity_email}
          maxLength={128}
        />
        <TextInput
          name={'phone'}
          type={'phone'}
          value={`${phone_code}${phone}`}
          label={`${representative} ${ContactFields.phone}`}
          handleChange={onChange}
          maskConfig={{
            mask: '999-999-9999',
            maskChar: null
          }}
          wrapperClassName={'contact-form__phone form__last-field'}
          errorLabel={errors.entity_phone}
        />
      </Form>
      {selectedEntity === EntityType.IRA && <Controls onClick={onClick} />}
    </>
  )
}

export default React.memo(EntityContactForm)
