import React, { Component } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import Link from 'components/UI/Link'
import { InfluencerTab } from './Tab'
import { Icon, InfluencerTabs } from './styles'

export interface INavTab {
  id: string | number
  active: boolean
  index: number
  onClickTab: any
  num: number
}

interface Props {
  tabContainer?: React.ReactNode
  children: React.ReactNode
  tabs: any
  activeTab?: string
  onClickTab?: any
}

class Tabs extends Component<Props, { activeTab?: number | string }> {
  constructor(props: Props) {
    super(props)

    this.state = {
      activeTab: this.props.activeTab
    }
  }

  onClickTabItem = (tab: any) => {
    const { onClickTab } = this.props

    this.setState({ activeTab: tab.currentTarget.id }, () => {
      if (onClickTab) {
        onClickTab(this.state.activeTab)
      }
    })
  }

  render() {
    const {
      props: { children, tabContainer, tabs },
      state: { activeTab }
    } = this

    const TabNav: any = tabContainer ? tabContainer : InfluencerTab
    const tabNames = Object.keys(tabs)

    return (
      <div className="tabs">
        <InfluencerTabs>
          {React.Children.map(children, (child: React.ReactNode) => {
            if (!child) {
              return
            }

            // @ts-ignore
            const { restProps } = child.props

            // @ts-ignore
            const currentId = child.key

            // @ts-ignore
            const currentTab = tabs[currentId] || tabs[Number(currentId)]

            if (!currentTab) {
              return
            }

            const index = tabNames.indexOf(currentId)

            return (
              <TabNav
                {...restProps}
                as={Link}
                active={activeTab === String(currentId)}
                key={currentId}
                to={currentTab.url || '#'}
                id={currentId}
                index={index}
                num={Object.keys(tabs).length}
                onClick={this.onClickTabItem}
              >
                <Icon>{currentTab.icon}</Icon>
                <p dangerouslySetInnerHTML={{ __html: currentTab.title }} />
              </TabNav>
            )
          })}
        </InfluencerTabs>
        <div className="tab-content">
          <TransitionGroup className={'tab-content-container'}>
            {React.Children.map(children, (child: React.ReactNode, i) => {
              // @ts-ignore
              if (!child || (child && child.key !== activeTab)) {
                return null
              }

              return (
                <CSSTransition
                  // @ts-ignore
                  in={child.key === activeTab}
                  key={i}
                  timeout={500}
                  classNames="alert"
                >
                  <>{child}</>
                </CSSTransition>
              )
            })}
          </TransitionGroup>
        </div>
      </div>
    )
  }
}

export default Tabs

export { Tabs }
