import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { TokenStorage } from 'services/tokenService'
import {
  selectProfileDetailsErrors,
  selectProfileErrors
} from 'redux/profile/selectors'
import {
  clearProfileErrors,
  setProfileDetailsErrors
} from 'redux/profile/actions'
import {
  updateProfilePhoto,
  updateProfileSettings
} from 'redux/profile/operations'
import { selectUser } from 'redux/auth/selectors'
import { getUserName } from 'utils/helpers'
import { formatDateToString } from 'utils/dateFormats'

import TextInput from 'src/components/UI/TextInput'
import Button from 'src/components/UI/Button'
import ErrorLabel from 'components/UI/ErrorLabel'

import {
  ContentWrapper,
  Form
} from 'components/profile/settings/AddressForm/styles'
import { EmailWrapper, UploadText, Wrapper } from './styles'
import { HeaderIcon } from 'containers/UserSettingsPage/styles'

import { SocialProviders } from 'redux/commonTypes'
import { IProfile } from 'redux/profile/types'
import { UploadPhotoIcon } from 'components/icons/profile'
import { Title } from 'components/UI/Tabs/styles'
import Avatar from '../../../common/Avatar'

const DetailsForm: FC = () => {
  const user = useSelector(selectUser)
  const errors = useSelector(selectProfileDetailsErrors)
  const socialAccount = !!TokenStorage.getSocialToken()
  const serverError = useSelector(selectProfileErrors)
  const dispatch = useDispatch()
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const [form, setForm] = useState<IProfile>({
    first_name: user.first_name,
    last_name: user.last_name,
    birth_date: user.birth_date ? formatDateToString(user.birth_date) : '',
    phone: user.phone || '',
    email: user.email,
    social: user.social_links
      ? user.social_links.reduce(
          (acc, social) => ({
            ...acc,
            [social.provider_id]: social.link
          }),
          {}
        )
      : {}
  })
  useEffect(() => {
    if (errors) {
      dispatch(clearProfileErrors())
    }
    return () => {
      dispatch(clearProfileErrors())
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (errors) {
      dispatch(clearProfileErrors())
    }
    checkChanges()
    // eslint-disable-next-line
  }, [form])

  const onBlurAction = (e: any) => {
    const link = form.social[e.target.name] || ''

    return (e.target.value = getUserName(link, e.target.name))
  }

  const checkChanges = (): void => {
    const userDataList = Object.keys(form)

    const isSame = userDataList.every((item: string) => {
      if (item === 'social') {
        return ['1', '2', '3'].every((provider: any, idx: number) =>
          user.social_links[idx]
            ? form.social[provider] === user.social_links[idx].link
            : !form.social[provider]
        )
      }
      if (['birth_date', 'phone'].includes(item)) {
        return true
      }
      // @ts-ignore
      return user[item] === form[item]
    })

    setIsDisabled(isSame)
  }

  const handleChange = (name: string, value: string) => {
    setForm({
      ...form,
      [name]: value
    })

    dispatch(setProfileDetailsErrors({ ...errors, [name]: '' }))
  }

  const handleSocialLinksChange = (name: string, value: string) => {
    const clone = { ...form.social }
    delete clone[name]

    setForm({
      ...form,
      social: value ? { ...form.social, [name]: value } : { ...clone }
    })
    dispatch(
      setProfileDetailsErrors({
        ...errors,
        // @ts-ignore
        social: { ...errors.social, [name]: '' }
      })
    )
  }

  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      dispatch(updateProfileSettings(form))
      setIsDisabled(true)
    } catch (e) {}
  }

  const handleUpload = (e: any) => {
    dispatch(clearProfileErrors())
    dispatch(updateProfilePhoto(e.target.files[0]))
  }

  const { first_name, social, email, last_name } = form

  return (
    <Form onSubmit={submitForm}>
      <ContentWrapper>
        <Title>User Photo</Title>
        <Wrapper>
          <Avatar
            src={user.profile_photo}
            name={first_name + ' ' + last_name}
            size={80}
          />
          <UploadText>
            You can upload a JPG or PNG image. The image will be resized as
            required to fit the screen.
            <HeaderIcon>
              <UploadPhotoIcon />
              <span>Upload Photo</span>
              <input type={'file'} id={'file'} onChange={handleUpload} />
            </HeaderIcon>
          </UploadText>
        </Wrapper>
        {serverError && (
          <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
            <ErrorLabel label={serverError.message} />
          </div>
        )}
        <Title>Name & Email</Title>
        <EmailWrapper>
          <TextInput
            type={'text'}
            label={'First Name'}
            name={'first_name'}
            value={first_name}
            handleChange={handleChange}
            errorLabel={errors.first_name || ''}
            disabled={socialAccount}
            wrapperClassName={'profile-user__first-field'}
          />
          <TextInput
            type={'text'}
            label={'Last Name'}
            name={'last_name'}
            value={last_name}
            handleChange={handleChange}
            errorLabel={errors.last_name || ''}
            disabled={socialAccount}
            wrapperClassName={'profile-user__second-field'}
          />
          <TextInput
            type={'text'}
            label={'E-mail'}
            name={'email'}
            value={email}
            handleChange={handleChange}
            errorLabel={errors.email || ''}
            disabled={true}
            wrapperClassName={'form__last-field'}
          />
        </EmailWrapper>
        <Title>Social Networks</Title>
        <TextInput
          type={'text'}
          label={'Instagram'}
          labelOptional={'(Optional)'}
          name={SocialProviders.Instagram}
          value={
            Object.keys(social).length > 0
              ? social[SocialProviders.Instagram]
              : ''
          }
          handleChange={handleSocialLinksChange}
          errorLabel={errors.social && errors.social[2]}
          placeholder={'https://instagram.com/'}
          onBlur={onBlurAction}
          wrapperClassName={'form__first-field'}
        />
        <TextInput
          type={'text'}
          label={'Twitter'}
          labelOptional={'(Optional)'}
          name={SocialProviders.Twitter}
          value={
            Object.keys(social).length > 0
              ? social[SocialProviders.Twitter]
              : ''
          }
          handleChange={handleSocialLinksChange}
          errorLabel={(errors.social && errors.social[3]) || ''}
          placeholder={'https://twitter.com/'}
          onBlur={onBlurAction}
        />
        <TextInput
          type={'text'}
          label={'Facebook'}
          labelOptional={'(Optional)'}
          name={SocialProviders.Facebook}
          value={
            Object.keys(social).length > 0
              ? social[SocialProviders.Facebook]
              : ''
          }
          handleChange={handleSocialLinksChange}
          errorLabel={errors.social && errors.social[1]}
          placeholder={'https://facebook.com/'}
          onBlur={onBlurAction}
          wrapperClassName={'form__last-field'}
        />
      </ContentWrapper>
      <Button variant={'default'} type={'submit'} disabled={isDisabled}>
        Update
      </Button>
    </Form>
  )
}
export default DetailsForm
