import axios from 'axios'
import { AuthProviders, IUser, IUserPreference } from 'redux/auth/types'

export const AuthApi = {
  register: async (data: RegisterRequest) =>
    await axios.post<RegisterResponse>('/register', data),

  verify: async (token: string, authToken: string) =>
    await axios.post<VerifyUserResponse>(
      '/verify/email',
      { token },
      { headers: { Authorization: `Bearer ${authToken}` } }
    ),

  resend: async () => await axios.post<ResendResponse>('/resend/email'),

  betablockAccess: async (accessToken: string) =>
    await axios.get('/betablock/access', {
      headers: { Authorization: `Bearer ${accessToken}` }
    }),

  oauthAuthorize: async <D>(accessToken: string, params?: D) =>
    await axios.get('/oauth/authorize', {
      headers: { Authorization: `Bearer ${accessToken}` },
      params
    }),

  resendWithAccessToken: async (accessToken: string) =>
    await axios.post<ResendResponse>('/resend/email', null, {
      headers: { Authorization: `Bearer ${accessToken}` }
    }),

  login: async (data: LoginRequest) =>
    await axios.post<LoginResponse>('/login', data),

  socialLogin: async (data: SocialLoginRequest) =>
    await axios.post<LoginResponse>('/social-login', data),

  forgotPassword: async (email: string) =>
    await axios.post<ForgotPasswordResponse>('/forgot', { email }),

  verifyRecovery: async (email: string, token: string) =>
    await axios.post<VerifyUserResponse>('/recovery/verify', {
      email,
      token
    }),

  recoveryPassword: async (data: RecoveryPasswordRequest, token: string) =>
    await axios.post<RecoveryPasswordResponse>('/recovery', data, {
      headers: { Authorization: `Bearer ${token}` }
    }),

  changePassword: async (newPassword: ChangePasswordRequest) =>
    await axios.patch<ChangePasswordResponse>('/profile/password', newPassword)
}

interface RegisterResponse {
  accessToken: string
  votedToday: boolean
}

interface RegisterRequest {
  first_name: string
  last_name: string
  email: string
  birth_date?: string
  password: string
  password_confirmation: string
  terms: number
  provider: string
  provider_id: string
  user_photo_url: string
  consent?: number
  device_id: string
  device_model: string
  device_os: string
}

interface VerifyUserResponse {
  accessToken: string
  user: IUser
  preferences: IUserPreference[]
  forceChangePassword?: boolean
}

interface ResendResponse {
  status: boolean
}

interface LoginRequest {
  email: string
  password: string
}

interface LoginResponse {
  accessToken: string
  shouldChoosePreferences: boolean
  votedToday: boolean
  user: IUser
}

interface SocialLoginRequest {
  provider: AuthProviders
  token: string
  email?: string
}

interface ForgotPasswordResponse {
  accessToken: string
  votedToday: boolean
}

interface RecoveryPasswordResponse {
  accessToken: string
  votedToday: boolean
}

interface RecoveryPasswordRequest {
  password: string
  password_confirmation: string
}

interface ChangePasswordRequest extends RecoveryPasswordRequest {
  current_password: string
}

interface ChangePasswordResponse {
  accessToken: string
  status: boolean
}
