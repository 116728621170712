import React, { ReactNode } from 'react'
import {
  ClearButton,
  DefaultButton,
  IconButton,
  PlayVideoButton,
  SecondaryButton,
  ShopBlackContainedButton,
  ShopBlackOutlinedButton,
  ShopGoldContainedButton,
  TextButton,
  TextDangerButton,
  TransparentButton
} from './styles'

export interface ButtonProps {
  children?: ReactNode
  variant?:
    | 'default'
    | 'secondary'
    | 'transparent'
    | 'text'
    | 'clear'
    | 'text-danger'
    | 'play-video'
    | 'icon'
    | 'shop-black-outlined'
    | 'shop-black-contained'
    | 'shop-gold-contained'
  disabled?: boolean
  onClick?: any
  type?: 'submit' | 'button'
  fullwidth?: boolean
  style?: React.CSSProperties
}

export enum ButtonVariant {
  Default = 'default',
  Secondary = 'secondary',
  Transparent = 'transparent',
  Text = 'text',
  Clear = 'clear',
  TextDanger = 'text-danger',
  PlayVideo = 'play-video',
  Icon = 'icon',
  ShopBlackOutlined = 'shop-black-outlined',
  ShopBlackContained = 'shop-black-contained',
  ShopGoldContained = 'shop-gold-contained'
}

const Button: React.FC<ButtonProps> = ({
  variant = ButtonVariant.Default,
  type = 'button',
  children,
  disabled = false,
  onClick = () => {},
  fullwidth = false,
  ...props
}): JSX.Element => {
  const onClickBtn = (e: React.FormEvent<HTMLInputElement>): void => {
    if (disabled) {
      e.preventDefault()
      return
    }

    onClick(e)
  }

  const components = {
    [ButtonVariant.Default]: DefaultButton,
    [ButtonVariant.Secondary]: SecondaryButton,
    [ButtonVariant.Transparent]: TransparentButton,
    [ButtonVariant.Text]: TextButton,
    [ButtonVariant.Clear]: ClearButton,
    [ButtonVariant.TextDanger]: TextDangerButton,
    [ButtonVariant.PlayVideo]: PlayVideoButton,
    [ButtonVariant.Icon]: IconButton,
    [ButtonVariant.ShopBlackOutlined]: ShopBlackOutlinedButton,
    [ButtonVariant.ShopBlackContained]: ShopBlackContainedButton,
    [ButtonVariant.ShopGoldContained]: ShopGoldContainedButton
  }

  const Component = components[variant]

  return (
    <Component
      onClick={onClickBtn}
      disabled={disabled}
      variant={variant}
      type={type}
      fullwidth={fullwidth ? fullwidth : undefined}
      {...props}
    >
      {children}
    </Component>
  )
}

export default Button

export {
  Button,
  DefaultButton,
  SecondaryButton,
  TransparentButton,
  TextButton,
  ClearButton,
  TextDangerButton,
  IconButton,
  ShopBlackOutlinedButton,
  ShopBlackContainedButton,
  ShopGoldContainedButton
}
