const colors = {
  black: '#000',
  fadedBlack: '#171716',
  gold: '#D4AB73',
  darkGrey: '#231f20',
  lightGold: '#F5D99F',
  brightGold: '#E7A725',
  darkenGold: '#B19062',
  lightGrey: '#909293',
  darkSmoky: '#1C1B1B',
  red: '#FF5353',
  wetWhite: '#C8C8C8',
  white: '#fff',
  darkNavy: '#090B16',
  navy: '#111529',
  blue: '#1C2244',
  lightBlue: '#242C58',
  fuchsia: '#BC6ACA',
  brightFuchsia: '#EB78FF',
  greyPearl: '#eee',
  grey: '#848484',
  graphite: '#3B3B3B',
  purple: '#55295D',
  green: '#78CA6A'
}

const button = {
  default: {
    active: colors.brightGold,
    hover: colors.lightGold,
    disabled: colors.lightGrey
  }
}

export interface StyleClosetTheme {
  colors: { [key in keyof typeof colors]: string }
  button: { [key in keyof typeof button]: any }
}

const theme: StyleClosetTheme = {
  colors,
  button
}

const gridTheme = {
  breakpoints: {
    xl: 1345,
    lg: 992,
    md: 768,
    sm: 576,
    xs: 575
  },
  container: {
    maxWidth: {
      xl: 1465,
      lg: 1199.98,
      md: 991.98,
      sm: 767.98,
      xs: 575.98
    }
  }
}

export { theme, gridTheme }
