import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export const Icon = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  color: #fff;
  margin: 0 auto 0.5rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  ${media.xs`
    width: 1.4rem;
    height: 1.4rem;
    
      svg {
    width: 1.4rem;
    height: 1.4rem;
  }
  `}
`
export const WrapBlock = styled.div`
  box-shadow: 0 1rem 0.6rem -1rem rgba(0, 0, 0, 0.25);
`

export const TabsNavigation = styled.div.attrs(() => ({
  className: 'tab-nav'
}))`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  flex-wrap: wrap;

  ${media.lg`
    flex-direction: column;
  `}
`

export const Headline = styled.h1`
  padding: 0.875rem 0 1rem;
  line-height: 3.5rem;
`

export const ContentWrapper = styled.div<{ index?: number; allTabs?: number }>`
  background-color: ${p => p.theme.colors.black};
`
export const TabName = styled.p<{
  isSubTab?: boolean
  isFAQ?: boolean
}>`
  font-family: Inter;
  font-size: 1.25rem;
  font-weight: 700;
  text-align: left;

  width: 100%;
  margin: 0 auto 0.5rem;
  padding: 0 0.125rem 0.75rem;
  color: ${p => (p.isFAQ ? p.theme.colors.white : p.theme.colors.fuchsia)};
  ${p =>
    p.isSubTab &&
    `
    padding-left: 0;
    margin-left: 0;
  `}
  @media (min-width: 425px) {
    ${p =>
      p.isSubTab &&
      `
      padding-left: 92px;
    `}
  }

  @media (min-width: 992px) {
    ${p => p.isSubTab && 'padding-left: 50px;'}
  }

  @media (min-width: 398px) {
    max-width: unset;
  }
`

export const Amount = styled.p`
  color: ${p => p.theme.colors.gold};
`

export const SubTabNav = styled(TabsNavigation)`
  flex-wrap: nowrap;
`

export const Title = styled.p`
  font-family: Inter;
  font-size: 1.25rem;
  font-weight: 900;
  line-height: 1.5 rem;

  margin-bottom: 15px;
`

export const InfluencerTabs = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`
