import { AchParagraph, PaymentTextBlock } from './USBank/styles'
import React, { FC } from 'react'
import Modal from 'src/components/UI/Modal'

interface ModalProps {
  isOpen: boolean
  onClose: () => void
}
export const PaymentAgreementModal: FC<ModalProps> = ({ isOpen, onClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={<b>ELECTRONIC FUNDS TRANSFERS POLICY</b>}
    >
      <PaymentTextBlock style={{ flexDirection: 'column' }}>
        <AchParagraph>
          {' '}
          Introduction to Electronic Funds Transfers{' '}
        </AchParagraph>
        <AchParagraph>
          <b>FanVestor</b>, processes Electronic Funds Transfers (EFTs) as an
          efficient method to receive electronic deposits from investors, to
          purchase and redeem investments and to issue refunds to investors.
          EFTs are processed through the vehicles described below:
          <ul>
            <li>The Automated Clearing House (ACH)</li>
            <li>The Fedwire</li>
            <li>Credit Card</li>
          </ul>
        </AchParagraph>
        <AchParagraph>
          <i>
            <b>Electronic Funds Transfers Agreement and Disclosure</b>
          </i>
        </AchParagraph>
        <AchParagraph>
          The Electronic Funds Transfers Agreement and Disclosure (this
          agreement) is the contract which covers you and our rights and
          responsibilities concerning the EFTs services offered to you by{' '}
          <b>FanVestor</b>. In this Agreement, the words "you," "your," and
          "yours" mean an individual who signs up on the <b>FanVestor</b> Online
          Platform (Platform) and utilizes the Platform to participate in
          offerings (and any authorized users). The words "we," "us," and "our"
          mean <b>FanVestor CF, LLC</b>. EFTs are electronically initiated
          transfers of money from your bank account or credit card account,
          which you utilize the Platform to initiate, and which allow you to
          participate in the <b>FanVestor</b> offerings. By using any{' '}
          <b>FanVestor</b> service, you agree to the terms and conditions in
          this Agreement and any amendments for the EFTs services offered.{' '}
        </AchParagraph>

        <AchParagraph style={{ paddingBottom: '20px' }}>
          In order to use the payment functionality of <b>FanVestor</b>, you
          authorize us to share your identity, bank account data and credit card
          account data with North Capital Private Securities (NCPS), a
          broker-dealer registered with the Securities and Exchange Commision
          and the Financial Industry Regulatory Authority, Inc., and with North
          Capital Investment Technology (NCIT) for the purpose of processing
          your EFTs, and you are responsible for the accuracy and completeness
          of that data. You understand that you will access and are responsible
          for managing your bank account data and credit card account data
          through the Platform. You also authorize us and NCPS and NCIT to
          process EFTs as you direct through <b>FanVestor</b>. You acknowledge
          that none of NCPS, NCIT or we will be liable for any loss, expense or
          cost arising out of EFT services provided through your use of{' '}
          <b>FanVestor</b>, which are based on your instruction; NCPS and CIT
          are third party beneficiaries to this Agreement.
        </AchParagraph>
      </PaymentTextBlock>
    </Modal>
  )
}
