import styled, { css } from 'styled-components'
import { ButtonProps } from './'

export const ClearButton = styled.button<ButtonProps>`
  cursor: pointer;
  text-decoration: none;
  background: none;
  outline: 0;
  border: 0;
  font-weight: 500;
  font-size: 0.875rem;
  transition: 0.5s all linear;
  color: ${p => p.theme.colors.white};
  font-family: Inter;
`

export const DefaultButton = styled(ClearButton)`
  padding: 1rem 1.5rem;
  background: ${p => p.theme.colors.fuchsia};
  border-radius: 0.75rem;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.375rem;
  letter-spacing: 0.125rem;
  text-transform: uppercase;
  text-align: center;

  :hover {
    opacity: 0.8;
  }

  ${props =>
    props.disabled &&
    css`
      cursor: auto;
      background: ${p => p.theme.button.default.disabled};
      color: #423e3e;

      :hover {
        background: ${p => p.theme.button.default.disabled};
        box-shadow: unset;
        color: #423e3e;
      }
    `}
`

export const SecondaryButton = styled(ClearButton)`
  padding: 1rem 1.5rem;
  border-radius: 0.75rem;
  border: 1px solid ${p => p.theme.colors.white};
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: 0.125rem;
  text-transform: uppercase;
  color: ${p => p.theme.colors.white};

  :hover {
    opacity: 0.8;
  }

  ${props =>
    props.disabled &&
    css`
      cursor: auto;
      background: ${p => p.theme.button.default.disabled};
      color: #423e3e;

      :hover {
        background: ${p => p.theme.button.default.disabled};
        box-shadow: unset;
        color: #423e3e;
      }
    `}
`

export const TextButton = styled(ClearButton)`
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.75rem;
  color: ${p => p.theme.colors.fuchsia};

  :hover {
    opacity: 0.6;
  }
`

export const IconButton = styled(ClearButton)`
  width: auto;
  font-size: 1rem;
  font-weight: 400;
  line-height: 19px;
  display: flex;
  align-items: center;
`

export const Loading = styled.span`
  text-align: center;

  @keyframes opacity {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  span:not(:last-child) {
    margin-right: 5px;
  }

  span {
    animation-name: opacity;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }

  span:nth-child(2) {
    animation-delay: 100ms;
  }

  span:nth-child(3) {
    animation-delay: 300ms;
  }
`
