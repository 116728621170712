import { EntityType } from 'redux/investment/types'
import { convertValueToPriceWithDecimal } from 'utils/helpers'

interface Props {
  amount: number
  entity: EntityType
  title: string
}

const configSections = (props: Props) => {
  const { amount, entity, title } = props

  return [
    {
      title: 'investment amount',
      value: convertValueToPriceWithDecimal(amount)
    },
    {
      title: 'project',
      value: title
    },
    {
      title: 'investment type',
      subtitle: entity
    }
  ]
}

export default configSections
