import React from 'react'
import { generatePath } from 'react-router-dom'
import { routePath } from 'routes'
import { IEventDetail } from 'redux/events/types'
import { Statuses } from 'redux/commonTypes'
import AnalyticsManager from 'services/analyticService'
import { formatPrice } from 'utils/dateFormats'
import DaysLeft from '../DaysLeft'
import { ButtonVariant } from 'src/components/UI/Button'
import ProgressBar from 'src/components/UI/ProgressBar'
import SocialButtons from 'src/components/UI/SocialButtons'

import {
  AmountStrong,
  AmountTotal,
  AmountRow,
  CardContent,
  CardInfo,
  EventDescription,
  EventTitle,
  InfluencerName,
  LearnMoreLink,
  LearnMoreSection,
  MinInvestmentText
} from './styles'

interface Props {
  event: IEventDetail
  showHours?: boolean
}

const InvestCardContent: React.FC<Props> = ({
  event,
  showHours
}): React.ReactElement => {
  const {
    status,
    event_title,
    event_end_in_hours,
    event_influencer,
    investmentTerms,
    event_description,
    event_id,
    event_end_at
  } = event
  const { amount_raised, total_offering, min_investment } =
    investmentTerms || {}
  const isEnded = status === Statuses.ENDED

  const trackAnalytics = () => {
    AnalyticsManager.trackRecommendedSectionEvent(event_id)
  }

  return (
    <CardContent>
      <DaysLeft
        eventStatus={status}
        hoursLeft={event_end_in_hours}
        showHours={showHours}
        eventEndDate={event_end_at}
      />

      <div style={{ width: '100%' }}>
        <CardInfo>
          <InfluencerName>{event_influencer[0].influencer_name}</InfluencerName>
          <EventTitle>{event_title}</EventTitle>
          <EventDescription
            dangerouslySetInnerHTML={{ __html: event_description }}
          />
        </CardInfo>

        {status === Statuses.LIVE && (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <LearnMoreSection>
              <LearnMoreLink
                to={generatePath(routePath.INVESTMENT, { id: event_id })}
                variant={ButtonVariant.Default}
                onClick={trackAnalytics}
              >
                Learn More
              </LearnMoreLink>
              <MinInvestmentText>
                Starting <br /> from ${min_investment}
              </MinInvestmentText>
            </LearnMoreSection>
            <SocialButtons path={routePath.INVESTMENT} event={event} />
          </div>
        )}

        {status !== Statuses.COMING_SOON && amount_raised > 0 && (
          <div style={{ marginTop: 28 }}>
            <ProgressBar
              total={total_offering}
              raised={amount_raised}
              isEndedEvent={isEnded}
            />
            <AmountRow>
              <AmountStrong isEnded={isEnded}>
                {formatPrice(amount_raised)}
              </AmountStrong>
              <AmountTotal>{formatPrice(total_offering)}</AmountTotal>
            </AmountRow>
          </div>
        )}
      </div>
    </CardContent>
  )
}

export default InvestCardContent
