import {
  DescriptionSectionContentWithPicture,
  DescriptionSectionPicture
} from 'components/invest/details/styles'
import {
  MainContent,
  SectionTitle,
  SectionWrapper
} from 'containers/invest/InvestDetailPage/styles'
import React from 'react'
import { EventSection } from 'redux/events/types'
import { Container } from 'styled-bootstrap-grid'
import { theme } from 'styled/theme'
import { IncomingHTMLLayout } from '../Disclosures/styles'
import { ContentDescription, ContentTitle } from '../DrivingInvestors/styles'

const CompanyHighlights: React.FC<{ section?: EventSection }> = ({
  section
}) => {
  return (
    <>
      {section && (
        <MainContent sectionBackground={'#f8f8f8'}>
          <Container className={'content-container'}>
            <SectionWrapper id={'company-highlights'}>
              <SectionTitle color={theme.colors.black}>
                {section.name}
              </SectionTitle>
              <IncomingHTMLLayout>
                {section.items.map(
                  ({ title, description, picture, direction }, index) => {
                    return (
                      <div key={title}>
                        {title && <ContentTitle>{title}</ContentTitle>}
                        {description && (
                          <ContentDescription
                            firstdesc={!index}
                            dangerouslySetInnerHTML={{
                              __html: description
                            }}
                          />
                        )}
                        {picture && (
                          <DescriptionSectionContentWithPicture
                            reverse={direction}
                          >
                            <ContentDescription
                              dangerouslySetInnerHTML={{
                                __html: description || ''
                              }}
                            />
                            <DescriptionSectionPicture
                              size={direction}
                              src={picture}
                              alt="Description picture"
                            />
                          </DescriptionSectionContentWithPicture>
                        )}
                      </div>
                    )
                  }
                )}
              </IncomingHTMLLayout>
            </SectionWrapper>
          </Container>
        </MainContent>
      )}
    </>
  )
}

export default CompanyHighlights
