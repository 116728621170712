import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { ClearButton } from 'src/components/UI/Button'

export const CommentWrapper = styled.li<{ secondary?: boolean; ref?: any }>`
  display: flex;
  max-width: 600px;
  margin-top: 29px;
  :first-of-type {
    margin-top: ${p => (p.secondary ? '29px' : 0)};
  }
  ${media.md`
    margin-top: 48px;
  `}
  > p, img {
    margin-right: 0.938rem;
  }
`

export const CommentContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${p => p.theme.colors.white};
  ::before {
    display: block;
    position: absolute;
    content: '';
    width: 1px;
    top: 60px;
    left: -38px;
    bottom: 0;
    background-color: ${p => p.theme.colors.white};
    opacity: 0.2;
  }
`

export const BottomContent = styled.div`
  span {
    margin-right: 1.5rem;
    font-size: 0.875rem;
    font-weight: 500;
  }

  ${media.md`
  span {
    margin-right: 3.125rem;
  }
  `}
`

export const UserName = styled.p`
  font-family: Inter;
  font-weight: 900;
  font-size: 0.9375rem;
  line-height: 1.125rem;
  color: ${p => p.theme.colors.white};
  margin-bottom: 5px;
  ${media.md`
    font-size: 1.125rem;
    line-height: 1.375rem;
  `}
`
export const AdditionalInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const AdditionalItem = styled(ClearButton)`
  display: flex;
  align-items: center;
  font-family: Inter;
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 1.75rem;
  margin-right: 12px;
  opacity: 0.5;
  :hover {
    opacity: 0.8;
  }
  svg {
    margin-right: 6px;
  }
  ${media.md`
    font-size: 1rem;
    line-height: 1.875rem;
    margin-right: 20px;
  `}
  &#report {
    ::before {
      display: inline-block;
      content: '';
      width: 1px;
      height: 20px;
      background-color: ${p => p.theme.colors.white};
      margin-right: 17px;
    }
  }
`
export const Comment = styled.p`
  font-family: Inter;
  font-weight: 300;
  font-size: 0.9375rem;
  line-height: 1.5rem;
  color: ${p => p.theme.colors.white};
  ${media.md`
    font-size: 1.125rem;
    line-height: 1.75rem;
  `}
`

export const ReportBlock = styled.div``

export const ReplyWrapper = styled.div`
  > div {
    width: 100%;
  }
`
