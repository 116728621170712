import React from 'react'

const HeartIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="20"
    viewBox="0 0 22 20"
    {...props}
  >
    <path
      fill="#fff"
      d="M20.286 2.44A5.81 5.81 0 0015.964.559c-1.247 0-2.389.394-3.395 1.172a6.942 6.942 0 00-1.372 1.433A6.946 6.946 0 009.824 1.73C8.818.953 7.677.559 6.43.559a5.811 5.811 0 00-4.323 1.88C1.002 3.64.393 5.278.393 7.053c0 1.828.681 3.5 2.143 5.264 1.31 1.579 3.188 3.18 5.365 5.036a250.34 250.34 0 012.46 2.116 1.265 1.265 0 001.67 0c.875-.765 1.72-1.483 2.462-2.117 2.177-1.855 4.057-3.456 5.364-5.035C21.32 10.552 22 8.88 22 7.052c0-1.775-.609-3.413-1.714-4.612z"
      {...props}
    />
  </svg>
)

export default HeartIcon
