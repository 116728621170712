import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export const ContentWrapper = styled.div`
  > * {
    margin-bottom: 2rem;
    font-family: Inter;
  }
  h2 {
    color: ${p => p.theme.colors.black};
    font-size: 1.25rem;
  }
`

export const ButtonWrapper = styled.div`
  width: 100%;
  padding: 0 20px 17px;

  ${media.md`
     padding: 0 35px 25px;
  `}

  button {
    width: 100%;
  }
`

export const ReportTypesWrapper = styled.div`
  width: 250px;
  min-height: 88px;
  background-color: #191e3a;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.4);
  border-radius: 12px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 2;
  right: 0;
  ${media.md`
     right: unset;
  `}
  button {
    padding: 12px 20px;
    font-family: Inter;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.188rem;
    color: ${p => p.theme.colors.white};
  }
`

export const Title = styled.h2`
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-size: 2.875rem;
  line-height: 3.5rem;
  color: ${p => p.theme.colors.black};
`
