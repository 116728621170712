import React, { FC } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Col, Container, Row } from 'styled-bootstrap-grid'
import GoBackLink from 'components/common/GoBackLink'
import { EMAILS } from 'utils/constants'
import { routePath } from 'routes'
import { ContentWrapper } from 'components/profile/settings/AddressForm/styles'
import {
  Wrapper,
  Title,
  SubTitle,
  Text,
  TextLink,
  List,
  ListItem,
  HeaderWrapper
} from './styles'

type Props = RouteComponentProps<{}, {}, { from?: { pathname: string } }> & {
  isFanProfile?: boolean
}

const TermsAndConditions: FC<Props> = ({ isFanProfile, location }) => {
  const prevPath =
    location && location.state && location.state.from
      ? location.state.from.pathname
      : ''

  return (
    <ContentWrapper>
      {prevPath && (
        <HeaderWrapper>
          <Container>
            <Row>
              <Col>
                <GoBackLink to={prevPath} />
              </Col>
            </Row>
          </Container>
        </HeaderWrapper>
      )}
      <Container>
        <Row>
          <Col>
            <Wrapper isFanProfile={isFanProfile}>
              <Title>EdenLedger Terms of Service Agreement</Title>
              <Text>
                These Terms of Service (“Terms”) apply to your access to and use
                of the websites, mobile applications, social media accounts and
                other online products and services (collectively, the
                “Services”) provided by EdenLedger, Inc. (d/b/a FanVestor) [,
                and] [list affiliates, if any] ([collectively,] “EdenLedger” or
                “we”). By using our Services, you agree to these Terms,
                including the mandatory arbitration provision and class action
                waiver in Section 14. If you do not agree to these Terms, do not
                use our Services.
              </Text>
              <Text>
                If you have any questions about these Terms or our Services,
                please contact us at{' '}
                <TextLink to={`mailto:${EMAILS.INFO}`}>{EMAILS.INFO}</TextLink>.
                For information about how we collect, use, share and otherwise
                process information about you, please{' '}
                <TextLink to={routePath.PRIVACY}>
                  see our Privacy Policy
                </TextLink>{' '}
              </Text>
              <SubTitle>1. Eligibility</SubTitle>{' '}
              <Text>
                You must be at least 18 years of age to use our Services. If you
                use our Services on behalf of another person or entity, (a) all
                references to “you” throughout these Terms will include that
                person or entity, (b) you represent that you are authorized to
                accept these Terms on that person’s or entity’s behalf, and (c)
                in the event you or the person or entity violates these Terms,
                the person or entity agrees to be responsible to us. In order to
                use the Services, you must first successfully provide the
                required identification information pursuant to our Bank Secrecy
                Act (“BSA”) and Anti-Money Laundering (“AML”) Compliance Program
                (collectively, our “BSA/AML Program”).
              </Text>{' '}
              <SubTitle>2. User Accounts and Account Security</SubTitle>
              <Text>
                {' '}
                Only persons that have opened an account (“Account”) and
                successfully completed our BSA/AML Program are considered
                EdenLedger customers (each, a “Customer”). If you register for
                an account, you must provide accurate account information and
                promptly update this information if it changes. You hereby
                authorize us, or a third-party service provider, to take any
                measures that we consider necessary to verify and authenticate
                your identity, confirm the accuracy of all information you
                submit to us, and to take any action we deem necessary based on
                the results. You also must maintain the security of your account
                and promptly notify us if you discover or suspect that someone
                has accessed your account. Your account is for personal use
                only. You agree that you will not allow any persons to access or
                use your account credentials. We reserve the right to reclaim
                usernames, including on behalf of businesses or individuals that
                hold legal claim, including trademark rights, in those
                usernames. We may, at any time and in our sole discretion,
                restrict your access to, or otherwise impose conditions or
                restrictions upon your use of, the Services, with or without
                prior notice.
              </Text>
              <SubTitle>3. Perks and Perks Wallet</SubTitle>
              <Text>
                Customers may obtain digital credits (“Perks”) through the
                Services. Each Perk is a non-fungible digital asset that is
                issued on the Ethereum blockchain and redeemable for goods and
                services with third-party brands and influencers. The terms of
                sale applicable to each Perks are available on the applicable
                Perk listing. When you purchase Perks through the Services, the
                processing and settlement of each purchase transaction is
                carried out by a third-party payment service provider (“Payment
                Service Partner”) under separate terms of service. The Payment
                Service Partner’s role is to accept and process credit card,
                debit card and other types of payments to enable you to purchase
                Perks. EdenLedger is not a party to the Payment Service
                Partner’s terms of service and is not liable to you in respect
                thereof. EdenLedger is not directly supported, endorsed, or
                certified by any such payment service processors, and EdenLedger
                and such Payment Service Partners make no warranties or claims
                about the other. EdenLedger provides each Customer with a
                digital wallet (“Perks Wallet”) to maintain the Perks that they
                obtain through the Services. The Perks Wallet is an account that
                is hosted by EdenLedger that enables each Customer to store,
                track, transfer, and manage their Perks. You cannot maintain
                other types of digital assets, such as bitcoin or ether, in your
                Perks Wallet. If you transfer digital assets other than Perks to
                your Perks Wallet, such digital assets may not be retrievable.
                EdenLedger assumes no responsibility in connection with any
                attempt to use your Perks Wallet with digital assets that we do
                not support. Each Perks Wallet is secured by private key
                cryptography. We securely store your Perks Wallet private keys,
                which are used to process transactions, in a combination of
                online and offline storage. As a result of our security
                protocols, it may be necessary for us to retrieve private keys
                or related information from offline storage in order to
                facilitate a Perks transaction in accordance with your
                instructions, and you acknowledge that this may delay the
                initiation of such a transaction.
              </Text>{' '}
              <Text>
                All Perks held in your Perks Wallet are custodial assets held by
                EdenLedger for your benefit. Title to Perks shall at all times
                remain with you and shall not transfer to EdenLedger. As the
                owner of Perks in your Digital Wallet, you shall bear all risk
                of loss of such Perks. None of the Perks in your Perks Wallet
                are the property of, or shall or may be loaned to, EdenLedger;
                EdenLedger does not represent or treat assets in users Perks
                Wallets as belonging to EdenLedger. EdenLedger may not grant a
                security interest in the Perks held in your Perks Wallet. Except
                as required by a facially valid court order, or except as
                provided herein, EdenLedger will not sell, transfer, loan,
                hypothecate, or otherwise alienate Perks in your Perks Wallet
                unless instructed by you.
              </Text>{' '}
              <Text>
                You control the Perks held in your Perks Wallet. At any time,
                subject to outages, downtime, and other applicable policies, you
                may withdraw your Perks by sending them to a different
                blockchain address. As long as you continue to custody your
                Perks with EdenLedger, EdenLedger shall retain control over
                electronic private keys associated with blockchain addresses
                operated by EdenLedger, including the blockchain addresses that
                hold your Perks. You may transfer the Perks maintained in your
                Perks Wallet to other Perks Wallets [or to an external
                blockchain wallet that supports Perks] through the Services. You
                acknowledge that Perks are not subject to protections or
                insurance provided by the Federal Deposit Insurance Corporation
                or the Securities Investor Protection Corporation.]
              </Text>
              <SubTitle>4. Prohibited Conduct and Content</SubTitle>{' '}
              <Text>
                (a) You will not violate any applicable law, contract,
                intellectual property right or other third- party right or
                commit a tort, and you are solely responsible for your conduct
                while using our Services. You will not:
              </Text>
              <List>
                <ListItem>
                  Engage in any harassing, threatening, intimidating, predatory
                  or stalking conduct;
                </ListItem>

                <ListItem>
                  Use or attempt to use another user’s account;
                </ListItem>
                <ListItem>
                  Impersonate or post on behalf or any person or entity or
                  otherwise misrepresent your affiliation with a person or
                  entity;
                </ListItem>
                <ListItem>
                  Sell, resell or commercially use our Services;
                </ListItem>
                <ListItem>
                  Copy, reproduce, distribute, publicly perform or publicly
                  display all or portions of our Services, except as expressly
                  permitted by us or our licensors;
                </ListItem>
                <ListItem>
                  Modify our Services, remove any proprietary rights notices or
                  markings, or otherwise make any derivative works based upon
                  our Services;
                </ListItem>
                <ListItem>
                  Use our Services other than for their intended purpose and in
                  any manner that could interfere with, disrupt, negatively
                  affect or inhibit other users from fully enjoying our Services
                  or that could damage, disable, overburden or impair the
                  functioning of our Services in any manner;
                </ListItem>
                <ListItem>
                  Reverse engineer any aspect of our Services or do anything
                  that might discover source code or bypass or circumvent
                  measures employed to prevent or limit access to any part of
                  our Services;
                </ListItem>
                <ListItem>
                  Use any data mining, robots or similar data gathering or
                  extraction methods designed to scrape or extract data from our
                  Services;
                </ListItem>
                <ListItem>
                  Develop or use any applications that interact with our
                  Services without our prior written consent;
                </ListItem>
                <ListItem>
                  Send, distribute or post spam, unsolicited or bulk commercial
                  electronic communications, chain letters, or pyramid schemes;
                </ListItem>
                <ListItem>
                  Bypass or ignore instructions contained in our robots.txt
                  file; or
                </ListItem>
                <ListItem>
                  Use our Services for any illegal or unauthorized purpose, or
                  engage in, encourage or promote any activity that violates
                  these Terms.
                </ListItem>
              </List>
              <Text>
                (b) Enforcement of this Section 4 is solely at EdenLedger’s
                discretion, and failure to enforce this section in some
                instances does not constitute a waiver of our right to enforce
                it in other instances. In addition, this Section 4 does not
                create any private right of action on the part of any third
                party or any reasonable expectation that the Services will not
                contain any content that is prohibited by such rules.
              </Text>
              <SubTitle>5. Ownership; Limited License</SubTitle>
              <Text>
                {' '}
                The Services, including the text, graphics, images, photographs,
                videos, illustrations and other content contained therein, are
                owned by EdenLedger or our licensors and are protected under
                both United States and foreign laws. Except as explicitly stated
                in these Terms, all rights in and to the Services are reserved
                by us or our licensors. Subject to your compliance with these
                Terms, you are hereby granted a limited, nonexclusive,
                nontransferable, non-sublicensable, revocable license to access
                and use our Services for your own personal, noncommercial use.
                Any use of the Services other than as specifically authorized
                herein, without our prior written permission, is strictly
                prohibited, will terminate the license granted herein and
                violate our intellectual property rights.
              </Text>
              <SubTitle>6. Trademarks</SubTitle>
              <Text>
                {' '}
                EdenLedger, FanVestor, FanPerks and our logos, our product or
                service names, our slogans and the look and feel of the Services
                are trademarks of EdenLedger and may not be copied, imitated or
                used, in whole or in part, without our prior written permission.
                All other trademarks, registered trademarks, product names and
                company names or logos mentioned on the Services are the
                property of their respective owners. Reference to any products,
                services, processes or other information by trade name,
                trademark, manufacturer, supplier or otherwise does not
                constitute or imply endorsement, sponsorship or recommendation
                by us.
              </Text>{' '}
              <SubTitle>7. Feedback</SubTitle>{' '}
              <Text>
                You may voluntarily post, submit or otherwise communicate to us
                any questions, comments, suggestions, ideas, original or
                creative materials or other information about EdenLedger or our
                Services (collectively, “Feedback”). You understand that we may
                use such Feedback for any purpose, commercial or otherwise,
                without acknowledgment or compensation to you, including to
                develop, copy, publish, or improve the Feedback in EdenLedger’s
                sole discretion. You understand that EdenLedger may treat
                Feedback as nonconfidential.
              </Text>{' '}
              <SubTitle>8. Third-Party Content</SubTitle>{' '}
              <Text>
                We may provide information about third-party products, services,
                activities or events, or we may allow third parties to make
                their content and information available on or through the
                Services (collectively, “Third-Party Content”). We provide
                Third-Party Content as a service to those interested in such
                content. Your dealings or correspondence with third parties and
                your use of or interaction with any Third-Party Content are
                solely between you and the third party. EdenLedger does not
                control or endorse, and makes no representations or warranties
                regarding, any Third-Party Content, and your access to and use
                of such Third-Party Content is at your own risk.
              </Text>{' '}
              <SubTitle>9. Indemnification</SubTitle>{' '}
              <Text>
                To the fullest extent permitted by applicable law, you will
                indemnify, defend and hold harmless EdenLedger and our
                subsidiaries and affiliates, and each of our respective
                officers, directors, agents, partners and employees
                (individually and collectively, the “EdenLedger Parties”) from
                and against any losses, liabilities, claims, demands, damages,
                expenses or costs (“Claims”) arising out of or related to (a)
                your access to or use of the Services; (b) your User Content or
                Feedback; (c) your violation of these Terms; (d) your violation,
                misappropriation or infringement of any rights of another
                (including intellectual property rights or privacy rights); or
                (e) your conduct in connection with the Services. You agree to
                promptly notify the EdenLedger Parties of any third-party
                Claims, cooperate with the EdenLedger Parties in defending such
                Claims and pay all fees, costs and expenses associated with
                defending such Claims (including attorneys&#39; fees). You also
                agree that the EdenLedger Parties will have control of the
                defense or settlement, at EdenLedger’s sole option, of any
                third-party Claims. This indemnity is in addition to, and not in
                lieu of, any other indemnities set forth in a written agreement
                between you and EdenLedger or the other EdenLedger Parties.
              </Text>{' '}
              <SubTitle>10. Disclosures; Disclaimers</SubTitle>{' '}
              <Text>
                EdenLedger does not own or control certain aspects of the
                underlying software protocols that are used in connection with
                the Services. In general, those underlying protocols are open-
                source and anyone can use, copy, modify, and distribute them.
                EdenLedger is not responsible for operation of those underlying
                protocols, and EdenLedger makes no guarantee of their
                functionality, security, or availability. EdenLedger is not your
                broker, lawyer, intermediary, agent, or advisor and has no
                fiduciary relationship or obligation to you regarding any other
                decisions or activities that you effect when using the Services.
                Neither our communications nor any information that we provide
                to you is intended as, or shall be considered or construed as,
                advice. EdenLedger is not registered or licensed by the
                Securities and Exchange Commission, the Commodity Futures
                Trading Commission, or any financial regulatory authority. Your
                use of our Services is at your sole risk. Except as otherwise
                provided in a writing by us, our Services and any content
                therein are provided “as is” and “as available” without
                warranties of any kind, either express or implied, including
                implied warranties of merchantability, fitness for a particular
                purpose, title, and non-infringement. In addition, EdenLedger
                does not represent or warrant that our Services are accurate,
                complete, reliable, current or error-free. While EdenLedger
                attempts to make your use of our Services and any content
                therein safe, we cannot and do not represent or warrant that our
                Services or servers are free of viruses or other harmful
                components. You assume the entire risk as to the quality and
                performance of the Services.
              </Text>{' '}
              <SubTitle>11. Limitation of Liability</SubTitle>{' '}
              <Text>
                (a) To the fullest extent permitted by applicable law,
                EdenLedger and the other EdenLedger Parties will not be liable
                to you under any theory of liability—whether based in contract,
                tort, negligence, strict liability, warranty, or otherwise—for
                any indirect, consequential, exemplary, incidental, punitive or
                special damages or lost profits, even if EdenLedger or the other
                EdenLedger Parties have been advised of the possibility of such
                damages. (b) The total liability of EdenLedger and the other
                EdenLedger Parties for any claim arising out of or relating to
                these Terms or our Services, regardless of the form of the
                action, is limited to US $1,000. (c) The limitations set forth
                in this Section 11 will not limit or exclude liability for the
                gross negligence, fraud or intentional misconduct of EdenLedger
                or the other EdenLedger Parties or for any other matters in
                which liability cannot be excluded or limited under applicable
                law. Additionally, some jurisdictions do not allow the exclusion
                or limitation of incidental or consequential damages, so the
                above limitations or exclusions may not apply to you.
              </Text>{' '}
              <SubTitle>12. Release</SubTitle>
              <Text>
                To the fullest extent permitted by applicable law, you release
                EdenLedger and the other EdenLedger Parties from responsibility,
                liability, claims, demands and/or damages (actual and
                consequential) of every kind and nature, known and unknown
                (including claims of negligence), arising out of or related to
                disputes between users and the acts or omissions of third
                parties. If you are a consumer who resides in California, you
                hereby waive your rights under California Civil Code § 1542,
                which provides: “A general release does not extend to claims
                which the creditor does not know or suspect to exist in his or
                her favor at the time of executing the release, which if known
                by him or her must have materially affected his or her
                settlement with the debtor.”
              </Text>{' '}
              <SubTitle>13. Transfer and Processing Data</SubTitle>{' '}
              <Text>
                In order for us to provide our Services, you agree that we may
                process, transfer and store information about you in the United
                States and other countries, where you may not have the same
                rights and protections as you do under local law.
              </Text>{' '}
              <SubTitle>14. Dispute Resolution; Binding Arbitration</SubTitle>{' '}
              <Text>
                Please read the following section carefully because it requires
                you to arbitrate certain disputes and claims with EdenLedger and
                limits the manner in which you can seek relief from us, unless
                you opt out of arbitration by following the instructions set
                forth below. No class or representative actions or arbitrations
                are allowed under this arbitration provision. In addition,
                arbitration precludes you from suing in court or having a jury
                trial. (a) No Representative Actions. You and EdenLedger agree
                that any dispute arising out of or related to these Terms or our
                Services is personal to you and EdenLedger and that any dispute
                will be resolved solely through individual action, and will not
                be brought as a class arbitration, class action or any other
                type of representative proceeding. (b) Arbitration of Disputes.
                Except for small claims disputes in which you or EdenLedger
                seeks to bring an individual action in small claims court
                located in the county of your billing address or disputes in
                which you or EdenLedger seeks injunctive or other equitable
                relief for the alleged infringement or misappropriation of
                intellectual property, you and EdenLedger waive your rights to a
                jury trial and to have any other dispute arising out of or
                related to these Terms or our Services, including claims related
                to privacy and data security, (collectively, “Disputes”)
                resolved in court. Instead, for any Dispute that you have
                against EdenLedger you agree to first contact EdenLedger and
                attempt to resolve the claim informally by sending a written
                notice of your claim (“Notice”) to EdenLedger by email at{' '}
                <TextLink to={`mailto:${EMAILS.LEGAL}`}>
                  {EMAILS.LEGAL}
                </TextLink>{' '}
                or by certified mail addressed to 2055 Lombard Street, # 470217
                San Francisco, CA 94147. The Notice must (a) include your name,
                residence address, email address, and telephone number; (b)
                describe the nature and basis of the Dispute; and (c) set forth
                the specific relief sought. Our notice to you will be similar in
                form to that described above. If you and EdenLedger cannot reach
                an agreement to resolve the Dispute within thirty (30) days
                after such Notice is received, then either party may submit the
                Dispute to binding arbitration administered by JAMS or, under
                the limited circumstances set forth above, in court. All
                Disputes submitted to JAMS will be resolved through
                confidential, binding arbitration before one arbitrator.
                Arbitration proceedings will be held in [county, state] unless
                you are a consumer, in which case you may elect to hold the
                arbitration in your county of residence. For purposes of this
                Section 14, a “consumer” means a person using the Services for
                personal, family or household purposes. You and EdenLedger agree
                that Disputes will be held in accordance with the JAMS
                Streamlined Arbitration Rules and Procedures (“JAMS Rules”). The
                most recent version of the JAMS Rules are available on the JAMS
                website and are hereby incorporated by reference. You either
                acknowledge and agree that you have read and understand the JAMS
                Rules or waive your opportunity to read the JAMS Rules and waive
                any claim that the JAMS Rules are unfair or should not apply for
                any reason. (c) You and EdenLedger agree that these Terms affect
                interstate commerce and that the enforceability of this Section
                14 will be substantively and procedurally governed by the
                Federal Arbitration Act, 9 U.S.C. § 1, et seq. (the “FAA”), to
                the maximum extent permitted by applicable law. As limited by
                the FAA, these Terms and the JAMS Rules, the arbitrator will
                have exclusive authority to make all procedural and substantive
                decisions regarding any Dispute and to grant any remedy that
                would otherwise be available in court, including the power to
                determine the question of arbitrability. The arbitrator may
                conduct only an individual arbitration and may not consolidate
                more than one individual’s claims, preside over any type of
                class or representative proceeding or preside over any
                proceeding involving more than one individual. (d) The
                arbitration will allow for the discovery or exchange of
                non-privileged information relevant to the Dispute. The
                arbitrator, EdenLedger, and you will maintain the
                confidentiality of any arbitration proceedings, judgments and
                awards, including information gathered, prepared and presented
                for purposes of the arbitration or related to the Dispute(s)
                therein. The arbitrator will have the authority to make
                appropriate rulings to safeguard confidentiality, unless the law
                provides to the contrary. The duty of confidentiality does not
                apply to the extent that disclosure is necessary to prepare for
                or conduct the arbitration hearing on the merits, in connection
                with a court application for a preliminary remedy or in
                connection with a judicial challenge to an arbitration award or
                its enforcement, or to the extent that disclosure is otherwise
                required by law or judicial decision. (e) You and EdenLedger
                agree that for any arbitration you initiate, you will pay the
                filing fee (up to a maximum of $250 if you are a consumer), and
                EdenLedger will pay the remaining JAMS fees and costs. For any
                arbitration initiated by EdenLedger, EdenLedger will pay all
                JAMS fees and costs. You and EdenLedger agree that the state or
                federal courts of the State of [state] and the United States
                sitting in [county, state] have exclusive jurisdiction over any
                appeals and the enforcement of an arbitration award. (f) Any
                Dispute must be filed within one year after the relevant claim
                arose; otherwise, the Dispute is permanently barred, which means
                that you and EdenLedger will not have the right to assert the
                claim. (g) You have the right to opt out of binding arbitration
                within 30 days of the date you first accepted the terms of this
                Section 14 by emailing an opt-out notice to us at{' '}
                <TextLink to={`mailto:${EMAILS.LEGAL}`}>
                  {EMAILS.LEGAL}
                </TextLink>
                . In order to be effective, the opt-out notice must include your
                full name and address and clearly indicate your intent to opt
                out of binding arbitration. By opting out of binding
                arbitration, you are agreeing to resolve Disputes in accordance
                with
              </Text>{' '}
              <SubTitle>Section 15.</SubTitle>{' '}
              <Text>
                (h) If any portion of this Section 14 is found to be
                unenforceable or unlawful for any reason, (a) the unenforceable
                or unlawful provision shall be severed from these Terms; (b)
                severance of the unenforceable or unlawful provision shall have
                no impact whatsoever on the remainder of this Section 14 or the
                parties’ ability to compel arbitration of any remaining claims
                on an individual basis pursuant to this Section 14; and (c) to
                the extent that any claims must therefore proceed on a class,
                collective, consolidated, or representative basis, such claims
                must be litigated in a civil court of competent jurisdiction and
                not in arbitration, and the parties agree that litigation of
                those claims shall be stayed pending the outcome of any
                individual claims in arbitration. Further, if any part of this
                Section 14 is found to prohibit an individual claim seeking
                public injunctive relief, that provision will have no effect to
                the extent such relief is allowed to be sought out of
                arbitration, and the remainder of this Section 14 will be
                enforceable.
              </Text>{' '}
              <SubTitle>16. Governing Law and Venue</SubTitle>{' '}
              <Text>
                Any dispute arising from these Terms and your use of the
                Services will be governed by and construed and enforced in
                accordance with the laws of Delware, except to the extent
                preempted by U.S. federal law, without regard to conflict of law
                rules or principles (whether of [state] or any other
                jurisdiction) that would cause the application of the laws of
                any other jurisdiction. Any dispute between the parties that is
                not subject to arbitration or cannot be heard in small claims
                court will be resolved in the state or federal courts of
                California and the United States, respectively, sitting in San
                Francisco, CA.
              </Text>{' '}
              <SubTitle>17. Modifying and Terminating our Services</SubTitle>{' '}
              <Text>
                We reserve the right to modify our Services or to suspend or
                stop providing all or portions of our Services at any time. You
                also have the right to stop using our Services at any time. We
                are not responsible for any loss or harm related to your
                inability to access or use our Services.
              </Text>{' '}
              <SubTitle>18. Additional Terms and Amendments</SubTitle>{' '}
              <Text>
                (a) We may supply different or additional terms in relation to
                some of our Services, and those different or additional terms
                become part of your agreement with us if you use those Services.
                If there is a conflict between these Terms and the additional
                terms, the additional terms will control for that conflict. (b)
                We may make changes to these Terms from time to time. If we make
                changes, we will provide you with notice of such changes, such
                as by sending an email, providing a notice through our Services
                or updating the date at the top of these Terms. Unless we say
                otherwise in our notice, the amended Terms will be effective
                immediately, and your continued use of our Services after we
                provide such notice will confirm your acceptance of the changes.
                If you do not agree to the amended Terms, you must stop using
                our Services.
              </Text>{' '}
              <SubTitle>19. Our Commitment to Accessibility</SubTitle>
              <Text>
                EdenLedger is committed to making our website's content
                accessible and user friendly to everyone. If you are having
                difficulty viewing or navigating the content on this website, or
                notice any content, feature, or functionality that you believe
                is not fully accessible to people with disabilities, please
                email our Customer Service team at{' '}
                <TextLink to={`mailto:${EMAILS.LEGAL}`}>{EMAILS.INFO}</TextLink>{' '}
                with "Disabled Access" in the subject line and provide a
                description of the specific feature you feel is not fully
                accessible or a suggestion for improvement. We take your
                feedback seriously and will issue you a response within five
                business days. We consider all feedback it as we evaluate ways
                to accommodate all of our customers and our overall
                accessibility policies. Additionally, while we do not control
                such vendors, we strongly encourage vendors of third-party
                digital content to provide content that is accessible and user
                friendly.
              </Text>{' '}
              <SubTitle>20. Use of Cookies</SubTitle>{' '}
              <Text>
                EdenLedger uses third party cookies to enhance the user
                experience of the FanVestor site and mobile application. Please
                refer to our{' '}
                <TextLink to={'/profile/settings/policies/privacy-policy'}>
                  Privacy Policy
                </TextLink>{' '}
                for more information.
              </Text>{' '}
              <SubTitle>21. Participation in Sweepstakes</SubTitle>{' '}
              <Text>
                EdenLedger Inc is a registered Outside Solicitor in the State of
                California, and operates FanPerks sweepstakes for the benefit of
                registered recipient charitable organizations. You must be 18 or
                older and a resident of the State of California to participate
                in any FanPerks sweepstakes offer. No purchase is necessary to
                participate in a FanPerks sweepstakes, and there will be an
                alternative free means of entry posted on each relevant
                sweepstakes listing. The start and end date of each FanPerks
                sweepstake promotion will be posted on each relevant sweepstakes
                listing.
              </Text>{' '}
              <SubTitle>22. Severability</SubTitle>{' '}
              <Text>
                If any provision or part of a provision of these Terms is
                unlawful, void or unenforceable, that provision or part of the
                provision is deemed severable from these Terms and does not
                affect the validity and enforceability of any remaining
                provisions.
              </Text>{' '}
              <SubTitle>23. Miscellaneous</SubTitle>{' '}
              <Text>
                The failure of EdenLedger to exercise or enforce any right or
                provision of these Terms will not operate as a waiver of such
                right or provision. These Terms reflect the entire agreement
                between the parties relating to the subject matter hereof and
                supersede all prior agreements, representations, statements and
                understandings of the parties. The section titles in these Terms
                are for convenience only and have no legal or contractual
                effect. Use of the word “including” will be interpreted to mean
                “including without limitation.” Except as otherwise provided
                herein, these Terms are intended solely for the benefit of the
                parties and are not intended to confer third-party beneficiary
                rights upon any other person or entity. You agree that
                communications and transactions between us may be conducted
                electronically.
              </Text>
            </Wrapper>
          </Col>
        </Row>
      </Container>
    </ContentWrapper>
  )
}

// TermsAndConditions.defaultProps = {
//   isFanProfile: false
// }

export default withRouter(TermsAndConditions)
