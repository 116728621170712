import { action, createAsyncAction, createCustomAction } from 'typesafe-actions'
import { IStatus } from 'redux/commonTypes'
import { EventsActionTypes } from '../types'
import {
  IComment,
  ICommentThread,
  IEventComments,
  IReply,
  ReportedComment,
  ReplyList
} from './types'

export const fetchCommentsAsync = createAsyncAction(
  EventsActionTypes.FETCH_COMMENTS_REQUEST,
  EventsActionTypes.FETCH_COMMENTS_SUCCESS,
  EventsActionTypes.FETCH_COMMENTS_FAIL
)<void, IEventComments, Error>()

export const postCommentAsync = createAsyncAction(
  EventsActionTypes.POST_COMMENT_REQUEST,
  EventsActionTypes.POST_COMMENT_SUCCESS,
  EventsActionTypes.POST_COMMENT_FAIL
)<void, { data: IComment }, Error>()

export const replyToCommentAsync = createAsyncAction(
  EventsActionTypes.REPLY_TO_COMMENT_REQUEST,
  EventsActionTypes.REPLY_TO_COMMENT_SUCCESS,
  EventsActionTypes.REPLY_TO_COMMENT_FAIL
)<void, { data: IReply; commentId: number }, Error>()

export const likeCommentAsync = createAsyncAction(
  EventsActionTypes.LIKE_COMMENT_REQUEST,
  EventsActionTypes.LIKE_COMMENT_SUCCESS,
  EventsActionTypes.LIKE_COMMENT_FAIL
)<void, IStatus, Error>()

export const reportCommentAsync = createAsyncAction(
  EventsActionTypes.REPORT_COMMENT_REQUEST,
  EventsActionTypes.REPORT_COMMENT_SUCCESS,
  EventsActionTypes.REPORT_COMMENT_FAIL
)<void, { data: ReportedComment }, Error>()

export const fetchCommentThreadAsync = createAsyncAction(
  EventsActionTypes.FETCH_COMMENT_THREAD_REQUEST,
  EventsActionTypes.FETCH_COMMENT_THREAD_SUCCESS,
  EventsActionTypes.FETCH_COMMENT_THREAD_FAIL
)<void, ICommentThread, Error>()

export const showMoreCommentsAsync = createAsyncAction(
  EventsActionTypes.SHOW_MORE_COMMENTS_REQUEST,
  EventsActionTypes.SHOW_MORE_COMMENTS_SUCCESS,
  EventsActionTypes.SHOW_MORE_COMMENTS_FAIL
)<void, void, Error>()

export const fetchRepliesAsync = createAsyncAction(
  EventsActionTypes.FETCH_REPLIES_REQUEST,
  EventsActionTypes.FETCH_REPLIES_SUCCESS,
  EventsActionTypes.FETCH_REPLIES_FAIL
)<void, ReplyList & { id: number }, Error>()

export const setActiveCommentId = createCustomAction(
  EventsActionTypes.SET_ACTIVE_COMMENT,
  (id: number | null) => ({
    payload: { id }
  })
)

export const updateShownComments = () =>
  action(EventsActionTypes.UPDATE_SHOWN_COMMENTS)

export const clearComments = () => action(EventsActionTypes.CLEAR_COMMENTS)
