import React from 'react'

const HeartIcon = () => (
  <svg
    width="21"
    height="18"
    viewBox="0 0 21 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.8199 1.57961L9.99919 2.40211L9.17594 1.57886C7.07688 -0.520204 3.67361 -0.520204 1.57455 1.57886C-0.524523 3.67793 -0.524523 7.08119 1.57455 9.18026L9.46991 17.0756C9.76281 17.3685 10.2377 17.3685 10.5306 17.0756L18.432 9.1788C20.5264 7.07277 20.53 3.67906 18.4306 1.57961C16.3277 -0.523282 12.9228 -0.523279 10.8199 1.57961ZM17.3684 8.12108L10.0002 15.4846L2.63521 8.1196C1.12192 6.60632 1.12192 4.15281 2.63521 2.63952C4.14849 1.12624 6.602 1.12624 8.11528 2.63952L9.47273 3.99697C9.77059 4.29483 10.2553 4.28903 10.5459 3.98412L11.8806 2.64027C13.3977 1.12317 15.8528 1.12316 17.3699 2.64027C18.8836 4.15391 18.881 6.60015 17.3684 8.12108Z"
      fill="#EB78FF"
    />
  </svg>
)

export default HeartIcon
