import styled from 'styled-components'

export const Label = styled.label<{
  isLabelHidden?: boolean
}>`
  display: ${p => (p.isLabelHidden ? 'none' : 'block')};
  color: ${p => p.theme.colors.black};
  opacity: 0.75;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.1875rem;
  margin-bottom: 0;
  // REMOVE AFTER REDESIGN
  font-family: Inter;

  span {
    color: #9f9f9f;
  }
`

export default Label
