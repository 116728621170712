const settings = {
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  cssEase: 'linear',
  touchMove: true,
  dots: false,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 767,
      settings: {
        arrows: false
      }
    }
  ]
}

export default settings
