import styled from 'styled-components'
import { Container, media } from 'styled-bootstrap-grid'
import Link from 'components/UI/Link'
import Button from 'components/UI/Button'

export const Header = styled.div<{ img?: string }>`
  position: relative;
  padding-top: 2.25rem;
  background: ${p =>
    p.img
      ? `linear-gradient(rgba(0,0,0, 0.75), rgba(0,0,0, 0.75)), url(${p.img}) no-repeat center`
      : p.theme.colors.black};
  background-size: cover;
  z-index: 1;
`
export const HeaderContent = styled.div`
  display: flex;
  padding-bottom: 3.5rem;
`
export const Avatar = styled.div.attrs(p => ({
  'data-testid': 'user-profile-avatar'
}))<{ url: string }>`
  width: 200px;
  height: 200px;
  box-shadow: 0 7px 15px rgba(0, 0, 0, 0.25);
  border: 20px solid #191919;
  background: ${p => p.url && `url(${p.url})`} #fff;
  background-size: cover;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 25%;
  left: 50%;
  transform: translate(-50%, 50%);
`
export const Navigation = styled.div`
  padding-bottom: 3.375rem;
  display: flex;
  justify-content: space-between;
  position: relative;
`
export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;

  h1 {
    display: none;
  }

  > a {
    margin-right: 4rem;
  }

  ${media.lg`
  h1 {
  display: block;
  }
  `}
`
export const Option = styled(Link)`
  width: 2.75rem;
  height: 2.75rem;
  padding: 0.75rem;
  border: none;
  border-radius: 50%;
  background: rgba(49, 49, 49, 0.7);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
`
export const SignOutWrapper = styled(Button)`
  display: flex;
  align-items: center;

  svg {
    margin-right: 1rem;
  }

  p {
    font-size: 1rem;
    ${media.xs`display: none`}
  }
`

export const WrapperBlock = styled(Container)`
  padding-top: 6.25rem;
`
export const UserInfo = styled.div`
  margin-bottom: 1.5rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.md`
    margin-bottom: 0.25rem;
  `}
`
export const UserName = styled.p`
  margin-bottom: 1rem;

  opacity: 0.7;
  font-size: 1.25rem;
  font-weight: 700;
`
export const UserMail = styled.p`
  opacity: 0.4;
  font-size: 0.75rem;
  letter-spacing: 0.125rem;
`
