import React, { ReactNode, Component } from 'react'
import styled from 'styled-components'
import Header from 'components/content/Header'
import Footer from 'components/content/Footer'

interface TemplateProps {
  children: ReactNode
  location?: Location
}

class Template extends Component<TemplateProps> {
  componentDidUpdate(prevProps: Readonly<TemplateProps>) {
    const { location } = this.props
    const { location: prevLocation } = prevProps

    if (
      location &&
      prevLocation &&
      prevLocation.pathname !== location.pathname &&
      !location.hash
    ) {
      window.setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0)
    }
  }

  render() {
    return (
      <div className="wrapper">
        <div className="content">
          <Header />
          <Page>{this.props.children}</Page>
        </div>
        <Footer />
      </div>
    )
  }
}

export default Template

export const Page = styled.div`
  width: 100%;
  padding-top: 4.625rem;
`
