import React, { useEffect, useState, useRef, FC } from 'react'
import { Col, Container, Row } from 'styled-bootstrap-grid'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useLocation, useParams } from 'react-router-dom'
import { routePath } from 'routes'
import { fetchComments, fetchEvent } from 'redux/events/operations'
import {
  clearInvestmentAmountAndTier,
  setInvestmentTierId
} from 'redux/investment/actions'
import { selectActiveTierId } from 'redux/investment/selectors'
import { isBrandSelector, selectIsAuth } from 'redux/auth/selectors'
import {
  selectCommentList,
  selectEventsComments,
  selectEventsDetail,
  selectEventsIsFetching,
  selectEventsIsLoaded
} from 'redux/events/selectors'
import { Meta, Statuses } from 'redux/commonTypes'
import { EventType } from 'redux/events/types'
import Loader from 'src/components/UI/Loader'
import AnalyticsManager from 'services/analyticService'
import ComingSoon from 'components/common/ComingSoon'
import InvestmentHeader from 'components/invest/headers/InvestmentHeader'
import {
  Conversation,
  DataRoom,
  FAQSpoiler,
  Sitemap,
  Summary
} from 'components/invest'
import EventTier, { TierProps } from 'components/events/EventTierInvest'
import InvestNowModal from 'src/components/investment/InvestNowModal'
import { AllSections } from 'components/invest/details'

import {
  MainContent,
  SectionTitle,
  SectionWrapper,
  Wrapper,
  TiersLeftBlock,
  TiersRightBlock,
  SectionWrapperAbsolute,
  SectionWrapperColumn,
  DescriptionColumn,
  SectionTitleColumn,
  FAQList
} from './styles'
import { theme } from 'styled/theme'
import InvestorDeck from 'components/invest/sections/InvestorDeck'
import Disclosures from 'components/invest/sections/Disclosures'
import DrivingInvestors from 'components/invest/sections/DrivingInvestors'
import CompanyHighlights from 'components/invest/sections/CompanyHighlights'
import PhotoGallery from 'components/invest/sections/PhotoGallery'
import ManagementTeam from 'components/invest/sections/ManagementTeam'
import { fetchTierDetails } from 'redux/investment/operations'
import * as actions from 'redux/events/comments/actions'
import { LoaderWrapper } from 'components/invest/Offers/styles'

interface LocationState {
  position: number
  investmentAmount: number
}

interface UrlParams {
  id: string
}

const EventPage: FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [tierWithoutValue, setTierWithoutValue] = useState<boolean>(false)
  const [activeTierId, setActiveTierId] = useState<number>(0)
  const dispatch = useDispatch()
  const location = useLocation<LocationState>()
  const { id } = useParams<UrlParams>()

  const firstSection = useRef<HTMLDivElement>(null)

  const event = useSelector(selectEventsDetail)
  const eventsLoading = useSelector(selectEventsIsFetching)
  const eventsLoaded = useSelector(selectEventsIsLoaded)
  const investTierId = useSelector(selectActiveTierId)
  const isAuthenticated = useSelector(selectIsAuth)
  const isBrand = useSelector(isBrandSelector)
  const fromProfile = location.pathname.split('/').includes('profile')
  const comments = useSelector(selectCommentList)
  const { meta: { current_page } = {} as Meta } = useSelector(
    selectEventsComments
  )

  const getSectionFromList = (name: string) => {
    return (
      event && event.sections && event.sections.find(el => el.name === name)
    )
  }

  const reasonsToInvestSection = getSectionFromList('REASONS TO INVEST')
  const investDeckSection = getSectionFromList('INVESTOR DECK')
  const disclosuresSection = getSectionFromList('DISCLOSURES')
  const drivingInvestorsSection = getSectionFromList('DRIVING INVESTORS')
  const companyHighlightsSection = getSectionFromList('COMPANY HIGHLIGHTS')
  const managerTeamSection = getSectionFromList('MANAGEMENT TEAM')
  const photoGallerySection = getSectionFromList('PHOTO GALLERY')
  const faqSection = event.faqs
  const dataRoomSection = event.data_room

  const lintToDownload =
    event &&
    event.data_room &&
    event.data_room.find(el => {
      return el.name === 'Investor Deck'
    })

  useEffect(() => {
    return () => {
      setTierWithoutValue(false)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const { state: { position = 0 } = {} } = location || {}

    if (position) {
      window.scrollTo({
        top: window.pageYOffset + position
      })
      return
    }
    if (investTierId) {
      setIsOpen(true)
      setActiveTierId(investTierId)
    }
    // eslint-disable-next-line
  }, [location])

  useEffect(() => {
    const { hash } = location || {}

    if (!hash) {
      window.scrollTo({
        top: 0
      })
    }

    if (hash && current_page === 1) {
      const targetElement = document.querySelector(hash)

      if (targetElement) {
        targetElement.scrollIntoView()
      }
    }
  }, [location, current_page])

  useEffect(() => {
    const fetchEvents = async () => {
      await dispatch(fetchEvent(Number(id)))
      dispatch(actions.clearComments())
      await dispatch(fetchComments(Number(id)))
    }
    fetchEvents()
  }, [id, dispatch])

  const setActiveTier = (id: number) => {
    setActiveTierId(id)
    setIsOpen(true)
    AnalyticsManager.trackNorthCapitalEvent('Open', id)
    dispatch(setInvestmentTierId(id))
  }

  type TierCardProps = Pick<TierProps, 'tier' | 'withAmount' | 'disabled'>

  const TierCard = (props: TierCardProps) => (
    <EventTier
      isBrand={isBrand}
      isActivePayment={event.payment_active}
      setActiveTier={setActiveTier}
      isAuthenticated={isAuthenticated}
      eventId={event.event_id}
      {...props}
    />
  )

  if (!eventsLoaded && (eventsLoading || !Object.keys(event).length)) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    )
  }

  if (
    (!eventsLoading && eventsLoaded && !Object.keys(event).length) ||
    event.type !== EventType.INVESTMENT
  ) {
    return <ComingSoon text={'Not found'} />
  }

  if (
    (!eventsLoading && eventsLoaded && !Object.keys(event).length) ||
    (!fromProfile &&
      [Statuses.ENDED, Statuses.COMING_SOON].includes(event.status))
  ) {
    return <Redirect to={routePath.INDEX} />
  }

  const closeModal = () => {
    setIsOpen(false)
    setTierWithoutValue(false)
    AnalyticsManager.trackNorthCapitalEvent('Close', activeTierId)
    dispatch(clearInvestmentAmountAndTier())
  }

  const handleClick = () => {
    const tierId = event.tiers[0].id
    setTierWithoutValue(true)
    setIsOpen(true)
    setActiveTier(tierId)
    dispatch(fetchTierDetails(tierId))
    AnalyticsManager.trackTierCheckoutFromEvent(tierId)
  }

  return (
    <Wrapper>
      <InvestNowModal
        activeTierId={activeTierId}
        isOpen={isOpen}
        event={event}
        onClose={closeModal}
        hideValue={tierWithoutValue}
      />

      <InvestmentHeader onClick={handleClick} event={event} />
      <Sitemap event={event} firstSection={firstSection && firstSection} />

      {/* Story & Invest now section */}
      <MainContent sectionBackground={theme.colors.white} ref={firstSection}>
        <Container className={'content-container'}>
          <Row>
            <Col>
              {event.event_description && (
                <SectionWrapperColumn id={'story'}>
                  <SectionTitleColumn color={theme.colors.black}>
                    Story
                  </SectionTitleColumn>

                  <DescriptionColumn
                    dangerouslySetInnerHTML={{
                      __html: event.event_description
                    }}
                  />
                </SectionWrapperColumn>
              )}
            </Col>
          </Row>
        </Container>
      </MainContent>
      {/* Reasons to invest */}
      <>
        <div style={{ backgroundColor: theme.colors.white, height: '9rem' }} />
        <MainContent
          sectionBackground={theme.colors.navy}
          style={{ paddingBottom: '100px' }}
        >
          <Container
            className={'content-container'}
            style={{
              transform: !fromProfile ? 'translateY(-150px)' : 'unset',
              marginBottom: !fromProfile ? '-300px' : 'unset'
            }}
          >
            {event.tiers.length > 0 && (
              <SectionWrapperAbsolute id={'tiers-block'}>
                <TiersLeftBlock>
                  <Summary terms={event.investmentTerms} />
                </TiersLeftBlock>
                <TiersRightBlock>
                  <Row
                    style={{
                      marginLeft: 0,
                      marginRight: 0,
                      borderRadius: '25px',
                      overflow: 'hidden'
                    }}
                  >
                    {event.tiers.map(tier => (
                      <Col
                        md={6}
                        lg={4}
                        xl={3}
                        col={6}
                        key={tier.id}
                        className={'tiers-block'}
                        style={{ padding: 0 }}
                      >
                        <TierCard tier={tier} />
                      </Col>
                    ))}
                  </Row>
                </TiersRightBlock>
              </SectionWrapperAbsolute>
            )}
            <Row>
              <Col>
                <SectionWrapperColumn id={'reasons-to-invest'}>
                  <SectionTitleColumn color={theme.colors.white}>
                    {reasonsToInvestSection && reasonsToInvestSection.name}
                  </SectionTitleColumn>
                  {reasonsToInvestSection &&
                    reasonsToInvestSection.items.map(el => {
                      return (
                        <DescriptionColumn
                          key={el.description}
                          color={theme.colors.white}
                          dangerouslySetInnerHTML={{
                            __html: el.description || ''
                          }}
                        />
                      )
                    })}
                </SectionWrapperColumn>
              </Col>
            </Row>
          </Container>
        </MainContent>
      </>
      {/* All sections */}
      <div style={{ background: '#f8f8f8' }}>
        {/* Company Highlights */}
        {companyHighlightsSection && (
          <CompanyHighlights section={companyHighlightsSection} />
        )}

        {/* All sections */}
        <AllSections event={event} />
      </div>

      {/* Management team */}
      {managerTeamSection && (
        <>
          <div style={{ height: '350px', background: '#f8f8f8' }} />
          <MainContent sectionBackground={theme.colors.navy}>
            <Container className={'content-container'}>
              <ManagementTeam section={managerTeamSection} />
            </Container>
          </MainContent>
        </>
      )}

      {/* Data room */}
      {dataRoomSection.length && (
        <MainContent sectionBackground={theme.colors.navy}>
          <Container className={'content-container'}>
            <SectionWrapper id={'data-room'}>
              <DataRoom data={dataRoomSection} />
            </SectionWrapper>
          </Container>
        </MainContent>
      )}

      {/* Investor Deck */}
      {investDeckSection && (
        <InvestorDeck
          section={investDeckSection}
          lintToDownload={lintToDownload && lintToDownload.value}
        />
      )}

      {/* Driving Investors */}
      {drivingInvestorsSection && (
        <DrivingInvestors section={drivingInvestorsSection} />
      )}

      {/* Photo Gallery */}
      {photoGallerySection && <PhotoGallery data={investDeckSection} />}

      {/* Disclosures */}
      {disclosuresSection && <Disclosures section={disclosuresSection} />}

      {/* FAQ */}
      {faqSection && (
        <MainContent sectionBackground={theme.colors.white}>
          <Container className={'content-container'}>
            {faqSection.length > 0 && (
              <SectionWrapper id={'faq'}>
                <SectionTitle color={theme.colors.black}>
                  Frequently Asked Questions
                </SectionTitle>
                <FAQList>
                  {faqSection.map(item => (
                    <FAQSpoiler item={item} key={item.question} />
                  ))}
                </FAQList>
              </SectionWrapper>
            )}
          </Container>
        </MainContent>
      )}

      {/* Comments */}
      <MainContent sectionBackground={theme.colors.navy}>
        <Container className={'content-container'}>
          <SectionWrapper id={'conversation'}>
            <SectionTitle color={theme.colors.white}>
              {!isAuthenticated
                ? 'Join the conversation'
                : event.comments_count
                ? `Comments ${event.comments_count}`
                : 'Comments'}
            </SectionTitle>
            <Conversation data={comments} />
          </SectionWrapper>
        </Container>
      </MainContent>
    </Wrapper>
  )
}

export default EventPage
