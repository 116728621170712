import React, { Component } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { Col, Container } from 'styled-bootstrap-grid'
import { connect } from 'react-redux'

import { selectUser } from 'redux/auth/selectors'
import { routePath } from 'routes'

import { NewTabs as Tabs } from 'components/UI/Tabs'
import Link from 'components/UI/Link'
import PurchasesList from 'components/profile/dashboard/purchases/PurchasesList'
import InvestList from 'components/profile/dashboard/investments/InvestList'
import configTabs from './tabs'

import {
  Wrapper,
  SubMenuContentWrapper,
  SubMenuBlockWrapper
} from 'containers/UserSettingsPage/styles'

import { AppState } from 'store/types'
import { ProfileSectionTypes } from 'redux/profile/types'

class UserProfilePage extends Component<Props> {
  render() {
    const tabs: any = configTabs()

    return (
      <Wrapper>
        <Container id={'user_profile_container'}>
          <div>
            <Col>
              <SubMenuBlockWrapper>
                <Tabs tabs={tabs}>
                  {Object.keys(tabs).map(category => (
                    <Link key={category} to={tabs[category].url} />
                  ))}
                </Tabs>
                <SubMenuContentWrapper>
                  <Switch>
                    <Route
                      exact={true}
                      path={tabs[ProfileSectionTypes.EXPERIENCES].url}
                      component={PurchasesList}
                    />
                    <Route
                      exact={true}
                      path={tabs[ProfileSectionTypes.INVESTMENTS].url}
                      component={InvestList}
                    />
                    <Redirect
                      from={routePath.PROFILE}
                      to={tabs[ProfileSectionTypes.EXPERIENCES].url}
                    />
                  </Switch>
                </SubMenuContentWrapper>
              </SubMenuBlockWrapper>
            </Col>
          </div>
        </Container>
      </Wrapper>
    )
  }
}

type StateProps = ReturnType<typeof mapStateToProps>
export type Props = StateProps

const mapStateToProps = (state: AppState) => ({
  user: selectUser(state)
})

export default connect(mapStateToProps)(UserProfilePage)
