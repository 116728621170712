import React, { Component, ReactNode } from 'react'
import { Range, Handle } from 'rc-slider'
import 'rc-slider/assets/index.css'
import 'rc-tooltip/assets/bootstrap.css'
import { formatPrice } from 'utils/dateFormats'
import {
  handleStyle,
  railStyle,
  trackStyle,
  HandleLabel,
  RangeWrap
} from './styles'

const handle = (props: any): ReactNode => {
  const { value, dragging, index, ...restProps } = props
  return (
    <Handle key={index} value={value} {...restProps}>
      <HandleLabel>{formatPrice(value)}</HandleLabel>
    </Handle>
  )
}

interface Props {
  label?: string
  tipFormatter?: (value: number) => ReactNode
  min: number
  max: number
  step: number
  defaultValue: number[]
  onChange: any
}
interface State {
  value: number[]
}
class RangeSlider extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      value: props.defaultValue
    }
  }

  onSliderChange = (value: number[]): void => {
    this.setState({ value }, () => this.props.onChange(this.state.value))
  }

  render() {
    const { min, max, step, defaultValue, tipFormatter, label } = this.props
    const { value } = this.state

    return (
      <RangeWrap>
        {label && <p>{label}</p>}
        <Range
          tipFormatter={tipFormatter ? tipFormatter : formatPrice}
          min={min}
          max={max}
          step={step}
          defaultValue={defaultValue}
          value={value}
          onChange={this.onSliderChange}
          handleStyle={[handleStyle, handleStyle]}
          railStyle={railStyle}
          trackStyle={trackStyle}
          // @ts-ignore
          handle={handle}
        />
      </RangeWrap>
    )
  }
}

export default RangeSlider
