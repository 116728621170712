import styled, { css } from 'styled-components'
import { media } from 'styled-bootstrap-grid'

import ErrorLabel from 'components/UI/ErrorLabel'

export const PaypalButtonWrap = styled.div<{ isDisabled: boolean }>`
  position: relative;
  max-width: 363px;
  margin: 0 2.5rem;
  padding-bottom: 2rem;

  ${media.lg`
    margin-right: 0;
  `}

  ${p =>
    p.isDisabled &&
    css`
      &:before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 999;
      }
    `}
`

export const CustomErrorLabel = styled(ErrorLabel)`
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 150px;
`
