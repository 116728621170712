import React, { Component } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import Media from 'react-media'
import { cutText } from 'utils/formatText'
import { SubTab } from './Tab'
import { SubTabNav, ContentWrapper, TabName, Amount } from './styles'

interface Props {
  tabContainer?: React.ReactNode
  children: React.ReactNode[]
  tabs: any
  activeTab: string
  onClickTab?: any
}

class Tabs extends Component<Props, { activeTab: number | string }> {
  constructor(props: Props) {
    super(props)

    this.state = {
      activeTab: this.props.activeTab
    }
  }

  onClickTabItem = (tab: any) => {
    this.setState({ activeTab: tab.currentTarget.id })
  }

  render() {
    const {
      props: { children, tabContainer, tabs },
      state: { activeTab }
    } = this
    const TabNav: any = tabContainer ? tabContainer : SubTab

    return (
      <div className="tabs">
        <SubTabNav>
          {React.Children.map(children, (child: React.ReactNode, index) => {
            // @ts-ignore
            const { restProps } = child.props
            // @ts-ignore
            const currentId = child.key
            // @ts-ignore
            const currentTab = tabs[currentId] || tabs[Number(currentId)]

            return (
              <TabNav
                {...restProps}
                active={activeTab === String(currentId)}
                key={currentId}
                to={currentTab.url}
                id={currentId}
                index={index}
                num={Object.keys(tabs).length}
                onClick={this.onClickTabItem}
              >
                <Media query="(max-width: 767px)">
                  {matches =>
                    matches && activeTab !== String(currentId) ? (
                      <TabName>{cutText(currentTab.title, 3, false)}</TabName>
                    ) : (
                      <>
                        <TabName>{currentTab.title}</TabName>
                        <Amount>{currentTab.amount}</Amount>
                      </>
                    )
                  }
                </Media>
              </TabNav>
            )
          })}
        </SubTabNav>
        <div className="tab-content">
          <TransitionGroup className={'tab-content-container'}>
            {React.Children.map(children, (child: React.ReactNode, i) => {
              // @ts-ignore
              if (child && child.key !== activeTab) {
                return null
              }

              return (
                <CSSTransition
                  // @ts-ignore
                  in={child.key === activeTab}
                  key={i}
                  timeout={500}
                  classNames="alert"
                >
                  <ContentWrapper index={i} allTabs={children.length}>
                    {child}
                  </ContentWrapper>
                </CSSTransition>
              )
            })}
          </TransitionGroup>
        </div>
      </div>
    )
  }
}

export default Tabs

export { Tabs }
