import React, { ChangeEvent, useState, KeyboardEvent, useEffect } from 'react'
import InputMask, { Props as MaskProps } from 'react-input-mask'
import { internationalPhoneCodes as optionsList } from 'utils/countryCodes'
import { CSSObject } from 'styled-components'
import ReactTooltip from 'react-tooltip'
import { Label } from 'src/components/UI/Label'
import { dateMaskConfig } from 'utils/dateFormats'
import {
  DefaultInput,
  SecondaryInput,
  Wrapper,
  Input,
  IconWrapper
} from './styles'
import { AlertIcon, InfoCircleIcon } from 'src/components/icons'
import { DropDownPhone, getPhoneCountryInfo } from '../DropDownPhone'

export enum InputVariant {
  Default = 'default',
  Secondary = 'secondary',
  Clear = 'clear'
}

export interface TextInputProps {
  id?: string
  type?:
    | 'text'
    | 'password'
    | 'submit'
    | 'button'
    | 'date'
    | 'tel'
    | 'email'
    | 'phone'
  variant?: InputVariant
  as?: 'input' | 'textarea'
  name: string
  value?: string
  label?: string
  labelOptional?: string
  placeholder?: string
  errorLabel?: string | string[]
  infoLabel?: string | string[]
  disabled?: boolean
  readOnly?: boolean
  maxLength?: number
  maskConfig?: MaskProps
  handleChange?: (name: string, value: string) => void
  handleKeyUp?: (e: KeyboardEvent<HTMLInputElement>) => void
  handleKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void
  onBlur?: (e: FocusEvent) => void
  onFocus?: (e: FocusEvent) => void
  style?: CSSObject
  wrapperStyle?: CSSObject
  wrapperClassName?: string
  isValid?: boolean
  isLabelHidden?: boolean
}

const TextInput: React.FC<TextInputProps> = props => {
  const {
    name = '',
    value = '',
    label = '',
    labelOptional = '',
    errorLabel = '',
    infoLabel = '',
    id,
    variant = InputVariant.Default,
    wrapperStyle = {},
    wrapperClassName = '',
    isValid = true,
    isLabelHidden = false
  } = props

  const [inputValue, setInputValue] = useState<string>(value)

  useEffect(() => {
    if (inputValue !== value) {
      setInputValue(value)
    }
  }, [inputValue, value])

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.currentTarget
    const { handleChange } = props

    if (handleChange) {
      handleChange(name, value)
    }

    setInputValue(value)
  }

  const onKeyUp = (event: KeyboardEvent<HTMLInputElement>): void => {
    const { handleKeyUp } = props

    if (handleKeyUp) {
      handleKeyUp(event)
    }
  }

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
    const { handleKeyDown } = props

    if (handleKeyDown) {
      handleKeyDown(event)
    }
  }

  const onChangePhone = (code: string): void => {
    const { handleChange } = props

    if (handleChange) {
      handleChange('phone_code', code)
    }

    setInputValue(code)
  }

  const renderInput = () => {
    const {
      maskConfig,
      type = 'text',
      placeholder = '',
      disabled = false,
      readOnly = false,
      maxLength,
      ...rest
    } = props

    let value = inputValue
    if (type === 'phone') {
      const { calling_code } = getPhoneCountryInfo(inputValue, optionsList)
      const p =
        inputValue && inputValue[0] !== '+' ? `+${inputValue}` : inputValue
      value = p.replace(calling_code, '')
    }

    const components = {
      [InputVariant.Default]: DefaultInput,
      [InputVariant.Secondary]: SecondaryInput,
      [InputVariant.Clear]: Input
    }

    const InputComponent = components[variant]

    let input = (
      // @ts-ignore
      <InputComponent
        {...rest}
        name={name}
        type={type}
        value={value}
        maxLength={maxLength}
        placeholder={placeholder}
        disabled={disabled}
        readOnly={readOnly}
        onChange={onChange}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        isValid={isValid}
        isLabelHidden={isLabelHidden}
      />
    )

    if (maskConfig || type === 'date') {
      const mask = !maskConfig ? dateMaskConfig : maskConfig

      input = (
        // @ts-ignore
        <InputMask
          {...mask}
          name={name}
          onChange={onChange}
          {...rest}
          value={value}
          disabled={disabled}
          readOnly={readOnly}
        >
          {(inputProps: TextInputProps) => (
            // @ts-ignore
            <InputComponent
              {...inputProps}
              {...rest}
              name={name}
              maxLength={maxLength}
              type={'text'}
              value={value}
              onKeyUp={onKeyUp}
              onKeyDown={onKeyDown}
              placeholder={placeholder}
              disabled={disabled}
              readOnly={readOnly}
              errorLabel={errorLabel}
              isValid={isValid}
              isLabelHidden={isLabelHidden}
            />
          )}
        </InputMask>
      )
    }

    return type === 'phone' ? (
      <DropDownPhone
        value={inputValue}
        onChange={onChangePhone}
        isValid={isValid}
      >
        {input}
      </DropDownPhone>
    ) : (
      input
    )
  }

  return (
    <Wrapper
      variant={variant}
      style={wrapperStyle}
      className={wrapperClassName}
      isLabelHidden={isLabelHidden}
    >
      {!!label && (
        <Label htmlFor={id} isLabelHidden={isLabelHidden}>
          {label} {labelOptional && <span>{labelOptional}</span>}
          {infoLabel && (
            <IconWrapper data-tip={infoLabel} data-for={`tooltip__${name}`}>
              <InfoCircleIcon fill={'#d3d3d3'} />
            </IconWrapper>
          )}
        </Label>
      )}

      {renderInput()}

      {(!isValid || errorLabel) && (
        <IconWrapper data-tip={errorLabel} data-for={`error-tooltip__${name}`}>
          <AlertIcon />
        </IconWrapper>
      )}
      <ReactTooltip
        type={'error'}
        effect={'solid'}
        clickable={true}
        id={`error-tooltip__${name}`}
        backgroundColor={'#FF3A44'}
      />
      <ReactTooltip
        type={'light'}
        place={'right'}
        effect={'solid'}
        clickable={true}
        id={`tooltip__${name}`}
        backgroundColor={'#d3d3d3'}
      />
    </Wrapper>
  )
}

export default TextInput
