import React from 'react'
import Modal from 'src/components/UI/Modal'

import { ModalFooter, Terms, Text, Title } from './styles'

interface InvestPrivacyProps {
  isOpen: boolean
  onClose?: () => void
}

const InvestPrivacyModal: React.FC<InvestPrivacyProps> = ({
  isOpen,
  onClose
}) => {
  const renderHeader = () => <Title>Privacy Policy</Title>

  return (
    <Modal
      header={renderHeader()}
      footer={<ModalFooter />}
      isOpen={isOpen}
      onClose={onClose}
      style={{ maxHeight: '866px' }}
    >
      <Terms>
        <Text>We care about your privacy</Text>

        <Text>
          FANVESTOR cares about the privacy and confidentiality of your personal
          information. We collect, use and share your personal information to
          support the products and services that we offer to you. We don’t sell
          your personal information, and we don’t share it with others unless
          the provision of our services requires us to do so, or the law says we
          can or must.
        </Text>

        <Text>
          <strong>Information we collect from you</strong>
        </Text>

        <Text>
          When you interact with us—whether on our websites, on our mobile apps,
          at events FANVESTOR attends, organizes or sponsors, or in our branches
          or offices—we may collect certain personal information about you. For
          example:
        </Text>

        <ul>
          <li>
            When you apply for our products, we may collect information such as
            your name, address, social security number, date of birth, income,
            and employment information.
          </li>
          <li>
            When you visit our websites or use our mobile apps, we may collect
            information such as your computer IP address, your device
            identifier, your location, and what pages you view or visit.
          </li>
          <li>
            When you visit our branches or offices or attend events that we
            sponsor or organize, we may collect contact information such as your
            email address, your phone number or your physical address. We may
            also collect audio or visual information about you as a result of
            security measures we use in our facilities.
          </li>
        </ul>

        <Text>
          <strong>How we use your information</strong>
        </Text>

        <Text>
          We use your personal information to support products and services that
          you use or that we offer to you. For example, if you have accounts
          with us:
        </Text>

        <ul>
          <li>
            We may use information such as your purchases, trading activity,
            investor accreditation, and account history to display it to you
            online or through our mobile apps.
          </li>
          <li>
            We may use information such as a birthdate or place of birth for
            account authentication and for use with fraud prevention
            technologies.
          </li>
          <li>
            We may use your contact information to market products and services
            to you that we think may be of interest to you based on your user
            profile.
          </li>
        </ul>

        <Text>
          <strong>When and why we share your information</strong>
        </Text>

        <Text>
          We may disclose certain personal information to our service providers
          and other trusted business partners to support our products and
          services as permitted by law. We may also disclose your personal
          information to others upon your request. For example, we may disclose
          certain personal information to third parties to:
        </Text>

        <ul>
          <li>
            Perform services, such as marketing or market research, on our
            behalf.
          </li>
          <li>Service, maintain, or process your transactions and accounts.</li>
          <li>
            Protect against actual or potential fraud or unauthorized
            transactions, and to protect the security of our records and
            systems.
          </li>
          <li>
            Cooperate with government agencies or regulators, (including for tax
            purposes), securities exchanges, self-regulatory bodies (such as
            FINRA and the SEC) and law enforcement officials.
          </li>
        </ul>

        <Text>
          <strong>Privacy Statement</strong>
        </Text>

        <Text>
          This Privacy Statement (“Statement”) covers the following affiliated
          companies in the FANVESTOR family of companies: Edenledger, Inc.,
          FanVestor LLC, FanPerks LLC, Dalmore Securities, KoreConX, DataHum and
          HSBC (including products and services branded with FANVESTOR).
          Together, these entities are referred to as “FANVESTOR,” “our
          affiliates,” “we,” “us,” and “our” in this Statement.
        </Text>

        <Text>
          <strong>We care about your privacy.</strong>
        </Text>

        <Text>
          We are committed to maintaining the privacy and confidentiality of
          your personal information. This Statement explains what personal
          information we collect about you, how we use and share that
          information, and the rights you have in connection with that
          information, including how you can limit certain uses and sharing of
          your information. Certain rights, requirements, and disclosures in
          this Statement may be subject to exemption or otherwise may not apply
          to you based, for example, on applicable law or regulations.
        </Text>

        <Text>
          Personal information that we collect from or about you when you
          purchase a financial product or service for personal, family or
          household purposes is also governed by a separate notice entitled
          FANVESTOR CONSUMER PRIVACY NOTICE. If you are a current or former
          customer, please refer to that notice, which is incorporated into and
          part of this Privacy Statement, for additional information about our
          privacy practices.
        </Text>

        <Text>
          Personal information that we collect about you when you apply for a
          job at FANVESTOR or when you act as an employee or contractor at
          FANVESTOR is governed by a separate notice, which will be provided to
          you at or before the time your personal information is collected.
        </Text>

        <Text>
          <strong>1. Information we collect</strong>
        </Text>

        <Text>
          As described in this Statement, we may collect personal information
          from or about you in order to provide, enhance, market, and offer our
          products and services (together, “Services”) to you and to our
          institutional clients. “Services” include, among other things,
          maintaining or servicing accounts, providing customer service,
          processing or fulfilling trades and other transactions, verifying
          customer information, processing payments, providing financing,
          determining eligibility for Services, recordkeeping, and related
          activities.
        </Text>

        <Text>
          This section describes the categories of personal information we may
          collect, including personal information we have collected in the past
          twelve months. For purposes of this Statement, personal information
          generally includes information that may be used to identify you. You
          are not required to give us all the personal information identified in
          this Statement; however, if you do not provide requested personal
          information, we may not be able to provide you with some or all of the
          Services. We only collect personal information in accordance with
          applicable law.
        </Text>

        <ul>
          <li>Categories of Personal Information We Collect</li>
          <li>Examples of Personal Information We Collect</li>
          <li>Identifiers</li>
        </ul>

        <Text>
          Name, alias, home and work addresses, home and work phone numbers,
          mobile phone number, Social Security number, driver’s license number,
          passport number, tax identification number, other government-issued
          identification numbers, alien registration number, date of birth,
          email address, username and password, social media name, signature,
          customer identification number, IP address, electronic device
          (“Device”) identifier, device type, browser type, platform type,
          cookies, pixels, and beacons
        </Text>

        <Text>
          <strong>Financial information</strong>
        </Text>

        <Text>
          Financial account number, credit card number, debit card number,
          account balance, trading activity, transactions, equity compensation,
          loan number, loan amount, 529 plan account number, account history,
          income, debt, assets, revenue, credit history, credit score,
          investment experience, risk tolerance, and information about your
          personal investment goals or objectives
        </Text>

        <Text>
          Protected classifications under California or federal law (“Protected
          Class Information”) Age, sex, gender, national origin, citizenship,
          race, ethnicity, marital status, and disability status Commercial
          information Records of personal property, and order and purchase
          history for non-financial goods and services Biometric information
          Fingerprint, palm scan, and voiceprint Internet or similar network
          activity Browsing history, search history, clickstream data, session
          information, navigation paths, and other information about how you
          interact with our websites and mobile applications
        </Text>

        <ul>
          <li>Geolocation data</li>
          <li>Physical location or movements</li>
          <li>Sensory data</li>
          <li>Audio recordings, video recordings, and photographs</li>
          <li>Professional or employment-related information</li>
          <li>
            Employment information (including employer name), employment
            history, professional licenses or registrations, and employment
            identification number
          </li>
          <li>Education information</li>
          <li>Academic record, degrees and schooling</li>
          <li>Inferences from other personal information</li>
        </ul>

        <Text>
          Preferences, behaviors, characteristics (including, for example, age
          gender, race, and marital status), number of persons in household,
          location, income, assets, accounts, funds, investments, and similar
          information
        </Text>

        <Text>
          In addition, we may compile aggregate, anonymous, or de-identified
          data from various sources, including, but not limited to, accounts and
          transactions. This data, which we may use for our business purposes
          consistent with applicable law, does not identify an individual and is
          not personal information.
        </Text>

        <Text>
          <strong>
            2. Sources of Information and Purposes of Collection and Disclosure
          </strong>
        </Text>

        <Text>
          This section describes the sources we may collect personal information
          from and why we may collect and, if applicable, share personal
          information.
        </Text>

        <ul>
          <li>Sources of Personal Information</li>
          <li>Categories Personal Information We Collect</li>
          <li>Purpose of Collecting and Sharing Personal Information</li>
          <li>Applications and Forms</li>
        </ul>

        <Text>
          We may collect personal information from you or your authorized
          representatives during the application or account creation process,
          through other forms, or through discussions we have with you or your
          authorized representatives.
        </Text>

        <ul>
          <li>Identifiers</li>
          <li>Financial information</li>
          <li>Protected Class Information</li>
          <li>Professional or employment-related information</li>
          <li>Provide the Services</li>
          <li>Market, advertise, and promote the Services</li>
          <li>
            Protect against unauthorized access, security incidents, fraud, and
            other malicious or illegal activity
          </li>
          <li>Comply with legal and regulatory obligations</li>
        </ul>

        <Text>
          <strong>Communications and Interactions with Us</strong>
        </Text>

        <Text>
          We may collect personal information from you or your authorized
          representative when you communicate with us, including when you
          contact customer support, submit inquiries, request information from
          us, provide feedback, or participate in a promotion, sweepstakes,
          contest, survey or conference.
        </Text>

        <ul>
          <li>Identifiers</li>
          <li>Financial information</li>
          <li>Protected Class Information</li>
          <li>Sensory data</li>
          <li>Provide the Services</li>
          <li>Enhance the Services</li>
          <li>Market, advertise, and promote the Services</li>
          <li>
            Protect against unauthorized access, security incidents, fraud, and
            other malicious or illegal activity
          </li>
          <li>Comply with legal and regulatory obligations</li>
        </ul>

        <Text>
          <strong>Transaction Activity</strong>
        </Text>

        <Text>
          We may collect personal information from you and from records we
          create about you when you make transactions with or through us.
        </Text>

        <ul>
          <li>Identifiers</li>
          <li>Financial information</li>
          <li>Provide the Services</li>
          <li>Maintain system and process functionality</li>
          <li>
            Protect against unauthorized access, security incidents, fraud, and
            other malicious or illegal activity
          </li>
          <li>Comply with legal and regulatory obligations</li>
        </ul>

        <Text>
          <strong>Our Websites and Mobile Applications</strong>
        </Text>

        <Text>
          We may collect personal information from your Device when you visit or
          interact with our websites or mobile applications.
        </Text>

        <ul>
          <li>Identifiers</li>
          <li>Internet or similar network activity</li>
          <li>Geolocation data</li>
          <li>Provide the Services</li>
          <li>Enhance the Services</li>
          <li>Maintain system and process functionality</li>
          <li>Market, advertise, and promote the Services</li>
          <li>
            Protect against unauthorized access, security incidents, fraud, and
            other malicious or illegal activity
          </li>
          <li>Comply with legal and regulatory obligations</li>
        </ul>

        <Text>
          <strong>Social Media and Online Forums</strong>
        </Text>

        <Text>
          We may collect personal information from third-party social media
          platforms and sites, when you engage with our social media pages,
          online communities and forums, and when you mention us on your own or
          other social media pages, online communities or forums. Please note
          that online forums may be publicly accessible and other users may view
          information you post in the forums. We encourage you to exercise care
          in deciding what information and content you wish to disclose on the
          areas of the website that are accessible to the general public.
        </Text>

        <ul>
          <li>Identifiers</li>
          <li>Internet or similar network activity</li>
          <li>Provide Services</li>
          <li>Enhance the Services</li>
          <li>Market, advertise, and promote the Services</li>
          <li>Comply with legal and regulatory obligations</li>
        </ul>

        <Text>
          <strong>Our Physical Sites</strong>
        </Text>

        <Text>
          We may collect personal information both directly and indirectly from
          you when you visit our branches, offices, and other physical locations
          (“Sites”).
        </Text>

        <ul>
          <li>Biometric information</li>
          <li>Sensory data</li>
          <li>
            Protect against unauthorized access, security incidents, fraud, and
            other malicious or illegal activity
          </li>
          <li>Comply with legal and regulatory obligations</li>
        </ul>

        <Text>
          <strong>Advisor Services</strong>
        </Text>

        <Text>
          We may collect personal information from your registered investment
          advisor (“RIA”) if your RIA uses our technology and Services to
          administer and manage your assets.
        </Text>

        <ul>
          <li>Identifiers</li>
          <li>Financial information</li>
          <li>Protected Class Information</li>
          <li>Commercial information</li>
          <li>Professional or employment-related information</li>
          <li>Education information</li>
          <li>Provide the Services</li>
          <li>Market, advertise, and promote the Services</li>
          <li>Maintain system and process functionality</li>
          <li>
            Protect against unauthorized access, security incidents, fraud, and
            other malicious or illegal activity
          </li>
          <li>Comply with legal and regulatory obligations</li>
        </ul>

        <Text>
          <strong>Affiliates</strong>
        </Text>

        <Text>We may collect personal information from our affiliates.</Text>

        <ul>
          <li>Identifiers</li>
          <li>Financial information</li>
          <li>Protected Class Information</li>
          <li>Commercial information</li>
          <li>Biometric information</li>
          <li>Internet or similar network activity</li>
          <li>Geolocation data</li>
          <li>Sensory data</li>
          <li>Professional or employment-related information</li>
          <li>Education information</li>
          <li>Inferences</li>
          <li>Provide the Services</li>
          <li>
            Market, advertise, and promote the Services (unless you opt out)
          </li>
          <li>Maintain system and process functionality</li>
          <li>
            Protect against unauthorized access, security incidents, fraud, and
            other malicious or illegal activity
          </li>
          <li>Comply with legal and regulatory obligations</li>
        </ul>

        <Text>
          <strong>
            Service Providers, Broker Dealers, Escrow Agents, and Business
            Partners
          </strong>
        </Text>

        <Text>
          We may collect personal information from service providers and
          business partners.
        </Text>

        <ul>
          <li>Identifiers</li>
          <li>Financial information</li>
          <li>Commercial information</li>
          <li>Protected Class Information</li>
          <li>Biometric information</li>
          <li>Internet or similar network activity</li>
          <li>Geolocation data</li>
          <li>Sensory data</li>
          <li>Professional or employment-related information</li>
          <li>Education information</li>
          <li>Inferences</li>
          <li>Provide the Services</li>
          <li>
            Market, advertise, and promote the Services (unless you opt out)
          </li>
          <li>Maintain system and process functionality</li>
          <li>
            Protect against unauthorized access, security incidents, fraud, and
            other malicious or illegal activity
          </li>
          <li>Comply with legal and regulatory obligations</li>
        </ul>

        <Text>
          <strong>Government Entities</strong>
        </Text>

        <Text>
          We may collect personal information from government agencies,
          regulators, securities, futures and commodity exchanges, and
          self-regulatory bodies (“Government Entities”).
        </Text>

        <ul>
          <li>Identifiers</li>
          <li>Financial information</li>
          <li>Protected Class Information</li>
          <li>Biometric information</li>
          <li>Internet or similar network activity</li>
          <li>Geolocation data</li>
          <li>Sensory data</li>
          <li>Professional or employment-related information</li>
          <li>Education information</li>
          <li>Provide the Services</li>
          <li>
            Protect against unauthorized access, security incidents, fraud, and
            other malicious or illegal activity
          </li>
          <li>Comply with legal and regulatory obligations</li>
        </ul>

        <Text>
          <strong>Consumer Reporting Agencies</strong>
        </Text>

        <Text>
          We may collect personal information from consumer reporting agencies.
        </Text>

        <ul>
          <li>Identifiers</li>
          <li>Financial information</li>
          <li>Protected Class Information</li>
          <li>Inferences</li>
          <li>Provide the Services</li>
        </ul>

        <Text>
          <strong>
            3. Information We May Share and Who We May Share It With
          </strong>
        </Text>

        <Text>
          This section describes the categories of personal information we may
          share, including personal information we have shared in the past
          twelve months, and the parties we may share it with. We may share your
          personal information for the purposes described in Section 2, when you
          have provided your consent for disclosure, or as otherwise permitted
          or required by law.&nbsp;
          <strong>
            We do not sell personal information and have not sold personal
            information in the past twelve months.
          </strong>
        </Text>

        <Text>Categories of Personal Information We May Share</Text>

        <Text>Operational Service Providers</Text>

        <Text>
          We may share your personal information with service providers that
          help us perform the Services, including, for example, companies that
          provide administrative, telecommunications, system, software, trade
          execution, payment, and securities clearing services, and that prepare
          and send account statements and transaction confirmations and other
          services in connection with the operation of our business.
        </Text>

        <ul>
          <li>Identifiers</li>
          <li>Financial information</li>
          <li>Commercial Information</li>
          <li>Protected Class Information</li>
          <li>Biometric Information</li>
          <li>Sensory Data</li>
          <li>Professional or employment-related information</li>
        </ul>

        <Text>
          <strong>Security Service Providers</strong>
        </Text>

        <Text>
          We may share personal information with service providers that detect,
          protect against, and respond to security incidents or other malicious,
          deceptive, illegal or fraudulent activity or actual or potential
          threats to the safety or well-being of any person.
        </Text>

        <ul>
          <li>Identifiers</li>
          <li>Financial information</li>
          <li>Biometric Information</li>
          <li>Internet or similar network activity</li>
          <li>Geolocation data</li>
          <li>Sensory Data</li>
        </ul>

        <Text>
          <strong>Marketing and Advertising Firms</strong>
        </Text>

        <Text>
          We may share personal information with service providers that perform
          marketing, advertising or market research on our behalf or help us
          determine the effectiveness of our marketing.
        </Text>

        <ul>
          <li>Identifiers</li>
          <li>Financial information</li>
          <li>Internet or similar network activity</li>
          <li>Geolocation data</li>
          <li>Inferences</li>
        </ul>

        <Text>
          <strong>Data Analytics Firms</strong>
        </Text>

        <Text>
          We may share personal information with service providers that perform
          data analytics to help us improve our Services and develop new
          offerings, among other things.
        </Text>

        <ul>
          <li>Identifiers</li>
          <li>Financial information</li>
          <li>Commercial Information</li>
          <li>Protected Class Information</li>
          <li>Internet or similar network activity</li>
          <li>Geolocation data</li>
          <li>Inferences</li>
        </ul>

        <Text>
          <strong>Government Entities</strong>
        </Text>

        <Text>
          We may share personal information with Government Entities and law
          enforcement officials. In certain cases, laws and regulations may
          require us to share personal information with these entities.
        </Text>

        <ul>
          <li>Identifiers</li>
          <li>Financial information</li>
          <li>Protected Class Information</li>
          <li>Biometric Information</li>
          <li>Internet or similar network activity</li>
          <li>Geolocation data</li>
          <li>Sensory Data</li>
          <li>Professional or employment-related information</li>
          <li>Education Information</li>
        </ul>

        <Text>
          <strong>Consumer Reporting Agencies</strong>
        </Text>

        <Text>
          We may share personal information with consumer reporting agencies.
        </Text>

        <ul>
          <li>Identifiers</li>
          <li>Financial information</li>
        </ul>

        <Text>
          <strong>Professional Service Firms</strong>
        </Text>

        <Text>
          We may share personal information with professional services firms
          that help us comply with our legal and regulatory obligations and
          establish, enforce, and otherwise exercise our rights and defend
          against claims, including, for example, auditors, law firms, and
          consultants.
        </Text>

        <ul>
          <li>Identifiers</li>
          <li>Financial information</li>
          <li>Protected Class Information</li>
          <li>Commercial Information</li>
          <li>Internet or similar network activity</li>
          <li>Geolocation data</li>
          <li>Sensory Data</li>
          <li>Professional or employment-related information</li>
        </ul>

        <Text>
          <strong>Institutional Clients</strong>
        </Text>

        <Text>
          We may share personal information with our institutional clients,
          including RIAs and corporate entities that use our technology to
          administer their stock or other employee benefit plan(s).
        </Text>

        <ul>
          <li>Identifiers</li>
          <li>Financial information</li>
          <li>Protected Class Information</li>
        </ul>

        <Text>
          <strong>Business Partners</strong>
        </Text>

        <Text>We may share personal information with business partners.</Text>

        <ul>
          <li>Identifiers</li>
          <li>Financial information</li>
          <li>Commercial information</li>
          <li>Protected Class Information</li>
        </ul>

        <Text>
          <strong>Affiliates</strong>
        </Text>

        <Text>
          We may share your personal information with our affiliates (unless you
          opt out of eligible sharing).
        </Text>

        <ul>
          <li>Identifiers</li>
          <li>Financial information</li>
          <li>Protected Class Information</li>
          <li>Commercial Information</li>
          <li>Biometric Information</li>
          <li>Internet or similar network activity</li>
          <li>Geolocation data</li>
          <li>Sensory Data</li>
          <li>Professional or employment-related information</li>
          <li>Education Information</li>
          <li>Inferences</li>
        </ul>

        <Text>
          <strong>Merger and Acquisition Activity</strong>
        </Text>

        <Text>
          We may share your personal information with third parties in
          connection with the proposed or actual financing, insuring, sale,
          securitization, assignment, or other disposal of all or part of our
          business or assets (including accounts) for the purposes of evaluating
          and performing the proposed transaction.
        </Text>

        <ul>
          <li>Identifiers</li>
          <li>Financial information</li>
          <li>Commercial Information</li>
          <li>Internet or similar network activity</li>
          <li>Professional or employment-related information</li>
        </ul>

        <Text>
          <strong>
            4.&nbsp;Use of Cookies and Similar Technologies and Interest-Based
            Advertising
          </strong>
        </Text>

        <Text>
          <strong>
            <em>4.1 Cookies and Similar Technologies</em>
          </strong>
        </Text>

        <Text>
          We and our third-party service providers may use cookies and similar
          technologies on our website and mobile applications to improve user
          experience, for performance and analytics, and to improve our content,
          products, and services. We also use cookies and similar technologies
          for purposes of marketing and advertising, including interest-based
          advertising. For more information on interest-based advertising, see
          the “Interest-Based Advertising” section. Certain information may be
          stored or accessed using tracking technologies such as “cookies” and
          “web beacons” that may be downloaded to your Device when you visit or
          interact with our websites and mobile applications. This information
          may include, but is not limited to:
        </Text>

        <ul>
          <li>
            IP address, UDID or other unique device identifier, a number that is
            automatically assigned to your Device and used by us to identify
            your Device;
          </li>
          <li>
            Device functionality and characteristics (including browser,
            operating system, hardware, and mobile network information);
          </li>
          <li>The URL that referred you to our website;</li>
          <li>
            The areas within our website or mobile application that you visit
            and your activities, including remembering your preferences; and
          </li>
          <li>Device location.</li>
        </ul>

        <Text>
          A “cookie” is a small text file that a web server stores in browser
          software. A browser sends cookies to a server when the browser makes a
          connection to the server (for example, when requesting a web page from
          the same domain that created the cookie). The purpose of cookies is to
          remember the browser over time and distinguish one browser instance
          (or user) from all others. We use both HTML cookies and Local Shared
          Objects (sometimes referred to as Flash cookies) that are built for
          use with the Adobe® Flash® Player for authentication purposes and to
          enhance navigation and website functionality. Cookies can remember
          login information, preferences, and similar information. If you access
          your account online, cookies may be used to verify your identity, to
          authenticate you as a user on our system and to personalize aspects of
          your experience on our website. We also use cookies to let us know how
          our customers and other visitors are using our website so that we can
          optimize their experience. We permit certain third party advertising
          providers to set cookies and use related technology such as web
          beacons or “clear GIFs” through our website and other websites on
          which we advertise, and via HTML email messages, to track performance
          of advertisements and to collect aggregate data on web page
          viewing.&nbsp; Some cookies and other technologies may serve to track
          personal information previously entered by a web user on the site. We
          may also use web beacons or “pixels,” and in certain circumstances may
          collect IP address, screen resolution and browser software and
          operating system types, clickstream patterns, dates and times that our
          site is accessed, and other categories of data.
        </Text>

        <Text>
          Most browsers allow you to control cookies, including whether to
          accept them and how to remove them. You can manually delete HTML
          cookies and set most browsers to refuse to accept HTML cookies. For
          information on how to disable cookies, refer to your browser’s
          documentation. Flash cookies may be removed by managing your Adobe®
          Flash® Player settings. To delete or prevent placement of Flash
          cookies, visit the Adobe® Flash® Player Help site at&nbsp;
          <a href="http://www.adobe.com/go/settingsmanager">
            www.adobe.com/go/settingsmanager
          </a>
          , click on the hyperlink for “Website Privacy Settings Panel” on the
          left side of the screen, and follow the instructions for preventing
          third party Flash content from storing data on your computer. If you
          choose to erase or block your cookies, doing so may affect your
          website experience, and certain parts of our website may not function
          correctly.
        </Text>

        <Text>
          Additional information about tracking technologies can be found on our
          website. Visit the&nbsp;
          <a href="https://us.etrade.com/security-center">Security Center</a>
          &nbsp;and select Information about Tracking Technologies.
        </Text>

        <Text>
          <strong>
            <em>4.2 Interest-Based Advertising</em>
          </strong>
        </Text>

        <Text>
          We use “interest-based” ads, also known as “online behavioral
          advertising.” Interest-based advertising is advertising that is
          targeted to you based on your web browsing and app usage over time and
          across websites or apps. You have the option to restrict the use of
          information for interest-based advertising and to opt-out of receiving
          interest-based ads. We may partner with ad networks and other
          advertising providers (“Advertising Providers”) who serve ads on
          behalf of us and others on non-affiliated sites. Some of these third
          parties may use cookies and other technologies to collect information
          about your online activities over time and across third party websites
          or online services to deliver advertising based on your interests and
          preferences, as inferred from your browsing history. Some of these ads
          may be personalized, meaning that they are intended to be relevant to
          you based on information that FANVESTOR and its Advertising Providers
          collect about your visits to this site and elsewhere over time.
        </Text>

        <Text>
          To learn more about this type of advertising and how to opt-out of
          this form of advertising, you may either visit www.aboutads.info to
          opt-out of sites and services participating in the Digital Advertising
          Alliance (“DAA”) self-regulatory program, or visit&nbsp;
          <a href="http://www.networkadvertising.org/choices/">
            www.networkadvertising.org/choices/
          </a>
          &nbsp;to opt-out of this form of advertising by members of the Network
          Advertising Initiative (“NAI”). Note that electing to opt-out will not
          stop advertising from appearing in your browser or applications,
          although it may make the ads you see less relevant to your interests.
          In addition, if you use a different browser or erase cookies from your
          browser, you may need to renew your opt-out choice.&nbsp;
        </Text>

        <Text>
          <strong>
            <em>4.3 Do Not Track</em>
          </strong>
        </Text>

        <Text>
          Do-Not-Track is a public-private initiative that has developed a
          “flag” or signal that an Internet user may activate in the user’s
          browser software to notify websites that the user does not wish to be
          “tracked” by third-parties as defined by the initiative. Our websites
          do not alter their behavior when we receive a “Do Not Track” signal
          from browser software. Currently, we do not monitor or take any action
          with respect to these signals or other similar mechanisms.
        </Text>

        <Text>
          <strong>5. How to Exercise Your Rights</strong>
        </Text>

        <Text>
          In certain cases, you may be able to limit the types of communications
          you receive and certain sharing of your personal information. This
          section describes how to exercise those rights.
        </Text>

        <Text>
          <strong>
            <em>5.1 Updating Your Information</em>
          </strong>
        </Text>

        <Text>
          As a customer, you may access and update some of your personal
          information by logging onto our website using your username and
          password and, from the home page, clicking on Account Preferences
          under the Accounts tab and selecting the information you want to
          change. If you believe that your personal information is inaccurate,
          incomplete, or out-of-date, you also may contact us using the
          information in the “Contact Us” section of this Statement.
        </Text>

        <Text>
          <strong>
            <em>5.2 Limiting Direct Marketing</em>
          </strong>
        </Text>

        <Text>
          If you would like to stop receiving newsletters or other marketing or
          promotional messages, notifications, or updates from us, you may
          follow the unsubscribe instructions that appear in these e-mail
          communications or you may opt out by contacting us through the
          information in the “Contact Us” section of this Statement. You may not
          be able to opt out of receiving certain service or transactional
          messages from us, including, for example, legal notices.
        </Text>

        <Text>
          <strong>
            <em>5.3 Limiting Sharing for Affiliate Marketing</em>
          </strong>
        </Text>

        <Text>
          We may share certain information about you with our affiliates so they
          can market their Services to you. You may opt out of this sharing by
          contacting us through the information in the “Contact Us” section of
          this Statement. You may change your opt-out status at any time. For
          consumers with joint accounts, an opt out by one joint account holder
          will be treated as an opt out by all associated joint account holders.
          We will process your request in compliance with applicable laws and
          regulations and our policies and procedures. Before responding to
          certain requests, we may ask you for additional information to verify
          your identity.
        </Text>

        <Text>
          <strong>6. Information on Individuals Under 18 Years</strong>
        </Text>

        <Text>
          We do not knowingly collect information from individuals under the age
          of 18 years without parental consent. If you become aware that an
          individual under 18 years of age has provided us with personal
          information without parental consent, please contact us using the
          information in the “Contact Us” section of this Statement. If we
          become aware that an individual under 18 years has provided us with
          personal information without parental consent, we will take steps to
          remove the data as permitted by law.
        </Text>

        <Text>
          <strong>7. Location of Data Storage and Processing</strong>
        </Text>

        <Text>
          The personal information we collect from you will be stored in the
          United States and may be stored in other countries that may have
          different privacy laws and standards than those in your country of
          residence. Your personal information may be transferred to or
          processed in the United States by us or by a third-party service
          provider working for us. If you are located outside of the United
          States, the transfer of personal information is necessary to provide
          you with the requested information, products, and services and/or to
          perform any requested transaction. By submitting personal information
          via our website or other means, you agree to such storage, transfers,
          and processing.
        </Text>

        <Text>
          <strong>8. Data Security</strong>
        </Text>

        <Text>
          We maintain reasonable physical, administrative, and technical
          safeguards to protect your personal information. Our employees and
          third-party service providers have access to your personal information
          only on a “need to know” basis. We conduct regular internal audits of
          our business practices and procedures in order to protect your
          personal information.
        </Text>

        <Text>
          In addition to our security measures, you can also take steps to
          enhance your security online and to protect your confidential
          information.&nbsp;
        </Text>

        <Text>
          <strong>9. Links to Other Sites</strong>
        </Text>

        <Text>
          Our websites or mobile applications may contain links or otherwise
          provide access to another website, mobile application, or Internet
          location (collectively “Third-Party Sites”). We have no control over
          and are not responsible for Third-Party Sites, their content or any
          goods or services available through the Third-Party Sites. This
          Statement does not apply to Third-Party Sites. We encourage you to
          read the privacy policies of any Third-Party Site with which you
          choose to interact.
        </Text>

        <Text>
          <strong>10. Notice to California Residents</strong>
        </Text>

        <Text>
          This section describes your rights under the California Consumer
          Privacy Act (“CCPA”) and how to exercise those rights. Your CCPA
          rights are in addition to the rights described in Section 5.
        </Text>

        <Text>
          <strong>
            <em>10.1 Description of Your Rights</em>
          </strong>
        </Text>

        <Text>
          Subject to certain exceptions and limitations, you have the following
          rights with respect to covered personal information.
        </Text>

        <ul>
          <li>
            <strong>Right to request access to personal information</strong>.
            You have the right to request the following information from us.
            <ul>
              <li>
                Specific pieces of personal information we have about you;
              </li>
            </ul>
            <ul>
              <li>
                Categories of personal information we have collected about you;
              </li>
            </ul>
            <ul>
              <li>
                Categories of sources from which the personal information is
                collected;
              </li>
            </ul>
            <ul>
              <li>
                Categories of personal information sold or disclosed, if any;
              </li>
            </ul>
            <ul>
              <li>
                Categories of third parties with whom the personal information
                was sold or disclosed; and
              </li>
            </ul>
            <ul>
              <li>
                The purpose(s) for collecting or selling the personal
                information.
              </li>
            </ul>
          </li>
          <li>
            <strong>Right to delete personal information:</strong>&nbsp;You have
            the right to request that we delete the personal information we have
            collected about you.
          </li>
          <li>
            <strong>
              Right to opt-out of the sale of personal information:
            </strong>
            &nbsp;You have the right to request to be opted out from the sale of
            your personal information; however, as set forth in Section 3, we do
            not and will not sell personal information to third parties.
          </li>
          <li>
            <strong>Right to Data Portability:&nbsp;</strong>In certain
            circumstances, you may have the right to receive personal
            information concerning you in a portable format.
          </li>
          <li>
            <strong>
              Right not to be discriminated against for exercising the rights
              identified above:&nbsp;
            </strong>
            You have the right not to be denied goods or services, charged
            different prices or rates for goods or services, or receive a
            differing level of quality of goods or services as a result of
            exercising the above rights.
          </li>
        </ul>

        <Text>
          <strong>10.2 Covered Personal Information</strong>
        </Text>

        <Text>
          The rights described in Section 10.1 do not apply to certain
          categories of personal information. The personal information excluded
          from coverage under the CCPA includes, among other things:
        </Text>

        <ul>
          <li>
            Personal information we collect from or about you when you apply for
            or obtain a product or service for personal, family or household
            purposes;
          </li>
          <li>
            Personal information about communications or transactions with you
            when you are acting on behalf of a company or government agency to
            do business with us; and
          </li>
          <li>
            Personal information sold or bought from a consumer reporting agency
            for use in a consumer report in compliance with applicable law.
          </li>
        </ul>

        <Text>
          We will apply verifiable consumer requests to covered personal
          information.
        </Text>

        <Text>
          <strong>10.3 How to Submit a Request</strong>
        </Text>

        <Text>
          You or your authorized agent may submit a CCPA request using the
          following method:
        </Text>

        <ul>
          <li>Email privacy@fanvestor.com</li>
        </ul>

        <Text>
          We will respond to authorized requests as soon as practicable and as
          required by law. The above rights are subject to our ability to
          reasonably verify your identity and authority to make these requests.
          In order to verify that you are authorized to submit a request, you
          may be required to provide the following information.
        </Text>

        <ul>
          <li>
            <strong>Customers:</strong>&nbsp;If you are a customer, you may
            verify your identity through account login.
          </li>
          <li>
            <strong>Non-Customers:&nbsp;</strong>If you are not a customer, you
            may verify your identity by submitting certain non-sensitive
            identifiers. To help protect the security of your personal
            information, we may use a third-party service provider to verify
            your information, which may include receiving a one-time password
            via text message. By submitting your mobile phone number for
            identity verification, you consent to receiving text messages for
            this purpose.
          </li>
          <li>
            <strong>Authorized Agents:&nbsp;</strong>If you are an authorized
            agent, you may verify your own identity by submitting certain
            non-sensitive identifiers. To help protect the security of your
            personal information and the personal information you are
            requesting, we may use a third-party service provider to verify your
            information, which may include receiving a one-time password via
            text message. By submitting your mobile phone number for identity
            verification, you consent to receiving text messages for this
            purpose. As an authorized agent, you also may be required to submit
            a legally sufficient Power of Attorney appointing you to act on
            behalf of the owner of the personal information you are requesting.
          </li>
        </ul>

        <Text>
          Your CCPA rights are subject to various exclusions and exceptions
          under the law. Under certain circumstances, we may be unable to
          implement your request, pursuant to applicable law. We will advise you
          of any reason for denying or restricting a request to the extent
          permitted by law.
        </Text>

        <Text>
          We will not provide social security numbers, driver’s license numbers
          or government issued identification numbers, financial account
          numbers, health insurance or medical identification numbers, account
          passwords or security questions and answers, or other specific pieces
          of personal information if the disclosure presents an unreasonable
          risk to the security of personal information, customer accounts or our
          systems and network security.
        </Text>

        <Text>
          <strong>11. Notice to Nevada Residents</strong>
        </Text>

        <Text>
          We are providing you with this notice pursuant to state law. You may
          be placed on our internal Do Not Call list by emailing us at
          privacy@fanvestor.com. For further information, contact the Nevada
          Attorney General’s office at 555 E. Washington Ave., Suite 3900, Las
          Vegas, NV 89101; by phone at&nbsp;702-486-3132; or by email at&nbsp;
          <a href="mailto:BCPINFO@ag.state.nv.us">BCPINFO@ag.state.nv.us</a>.
        </Text>

        <Text>
          <strong>12. Accessibility</strong>
        </Text>

        <Text>
          To obtain a copy of this Statement in Braille or in large print,
          please email us at support@fanvestor.com&nbsp;
        </Text>

        <Text>
          <strong>13. Updates to this Statement</strong>
        </Text>

        <Text>
          We may update this Statement from time to time.&nbsp; Publication of
          this statement to our website shall serve as legal notice of any
          updates made. We encourage you to periodically review this Statement
          to learn about updates to our privacy practices.
        </Text>

        <Text>
          <strong>14. Contact Us</strong>
        </Text>

        <Text>
          For more information about our privacy practices, or if you have
          questions or concerns, please visit the Contact Us page on our
          website. You may also email us at support@fanvestor.com or contact us
          by mail at the following address:
        </Text>

        <Text>FANVESTOR&nbsp;</Text>

        <Text>2055 Lombard Street, #470217</Text>

        <Text>San Francisco, CA 94147</Text>
      </Terms>
    </Modal>
  )
}

export default InvestPrivacyModal
