import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { History } from 'history'
import { authReducer } from 'redux/auth/reducer'
import { eventsReducer } from 'redux/events/reducer'
import { checkoutReducer } from 'redux/checkout/reducer'
import { userReducer } from 'redux/profile/reducer'
import { votesReducer } from 'redux/votes/reducer'
import { influencerReducer } from 'redux/influencer/reducer'
import { investmentReducer } from 'redux/investment/reducer'

export default (history: History) =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    events: eventsReducer,
    checkout: checkoutReducer,
    profile: userReducer,
    votes: votesReducer,
    influencer: influencerReducer,
    investment: investmentReducer
  })
