import { batch } from 'react-redux'
import ReactPixel from 'react-facebook-pixel'
import branch from 'branch-sdk'

import { AuthApi } from 'api/auth.api'
import { ProfileApi } from 'api/profile.api'
import { NotificationApi } from 'api/notification.api'

import { ThunkAction } from 'store/types'
import * as actions from './actions'
import { AuthProviders, IBetablockParams } from 'redux/auth/types'
import { formatDateParams, MIN_PASSWORD_LENGTH } from 'utils/helpers'

import { TokenStorage } from 'services/tokenService'
import Firebase from 'services/firebaseService'
import AnalyticsManager from 'services/analyticService'

import {
  IConfirmPassword,
  ILoginPayload,
  IRegisterPayload,
  ISocialLoginPayload,
  IChangePassword
} from './types'
import { selectAccessToken, selectUserId } from './selectors'
import { resetGuestBuyNow } from '../checkout/actions'
import { shoppingCartInitialization } from '../checkout/operations'
import { setBetablocksParams } from './actions'

export const initApp = (): ThunkAction => {
  return async (dispatch, getState) => {
    const isBetaBlock = window.location.pathname.includes('betablock')
    if (!isBetaBlock) {
      return batch(async () => {
        // @ts-ignore
        await dispatch(fetchProfileSettings())

        await Firebase.init()
        await Firebase.setUserId(selectUserId(getState()))
        // @ts-ignore
        dispatch(shoppingCartInitialization())
      })
    }
  }
}

export const register = (user: IRegisterPayload): ThunkAction => {
  return async dispatch => {
    dispatch(actions.registerAsync.request())

    try {
      const resp = await AuthApi.register(
        formatDateParams<IRegisterPayload>(user)
      )

      TokenStorage.storeToken(resp.data.accessToken)

      return dispatch(actions.registerAsync.success(resp.data))
    } catch (err) {
      dispatch(actions.registerAsync.failure(err.response.data))
      return Promise.reject(err)
    }
  }
}

export const verify = (token: string): ThunkAction => {
  return async (dispatch, getState) => {
    const authToken = selectAccessToken(getState())

    dispatch(actions.verifyAsync.request())

    try {
      const resp = await AuthApi.verify(token, authToken)

      dispatch(actions.verifyAsync.success(resp.data))
      TokenStorage.storeToken(resp.data.accessToken)
      // @ts-ignore
      dispatch(initApp())
      AnalyticsManager.trackRegistrationComplete()
      ReactPixel.track('CompleteRegistration')
      branch.logEvent('UserRegisteredWithLink', {
        user_who_shared: resp.data.user.id,
        auth_method: AuthProviders.Original
      })
    } catch (err) {
      dispatch(actions.verifyAsync.failure(err.response.data))
    }
  }
}

export const resend = (accessToken?: string): ThunkAction => {
  return async dispatch => {
    dispatch(actions.resendAsync.request())
    try {
      const resp = accessToken
        ? await AuthApi.resendWithAccessToken(accessToken)
        : await AuthApi.resend()
      if (accessToken) {
        dispatch(actions.setAccessToken(accessToken))
      }
      dispatch(actions.resendAsync.success(resp.data))
    } catch (err) {
      dispatch(actions.resendAsync.failure(err.response.data))
    }
  }
}

export const login = (user: ILoginPayload): ThunkAction => {
  return async dispatch => {
    dispatch(actions.loginAsync.request())

    try {
      const resp = await AuthApi.login(user)

      dispatch(actions.loginAsync.success(resp.data))
      await TokenStorage.storeToken(resp.data.accessToken)
      // @ts-ignore
      dispatch(initApp())
      dispatch(resetGuestBuyNow())
      AnalyticsManager.trackSignIn(AuthProviders.Original)
      branch.logEvent('UserLoggedInWithLink', {
        user_who_shared: resp.data.user.id,
        auth_method: AuthProviders.Original
      })
      return Promise.resolve()
    } catch (err) {
      dispatch(actions.loginAsync.failure(err.response.data))
      return Promise.reject()
    }
  }
}

export const betablockLogin = (
  user: ILoginPayload,
  betablocksParams?: IBetablockParams
): ThunkAction => {
  return async dispatch => {
    dispatch(actions.loginAsync.request())

    try {
      const resp = await AuthApi.login(user)

      const { data } = await AuthApi.oauthAuthorize(
        resp.data.accessToken,
        betablocksParams
      )

      dispatch(setBetablocksParams(data))

      dispatch(actions.loginAsync.success(resp.data))
      // @ts-ignore
      AnalyticsManager.trackSignIn(AuthProviders.Original)
      branch.logEvent('UserLoggedInWithLink', {
        user_who_shared: resp.data.user.id,
        auth_method: AuthProviders.Original
      })
    } catch (err) {
      dispatch(actions.loginAsync.failure(err.response.data))
    }
  }
}

export const verifyBetablock = (
  token: string,
  betablocksParams?: IBetablockParams
): ThunkAction => {
  return async (dispatch, getState) => {
    const authToken = selectAccessToken(getState())

    dispatch(actions.verifyAsync.request())

    try {
      const resp = await AuthApi.verify(token, authToken)

      const { data } = await AuthApi.oauthAuthorize(
        resp.data.accessToken,
        betablocksParams
      )

      await dispatch(setBetablocksParams(data))

      dispatch(actions.verifyAsync.success(resp.data))

      // @ts-ignore
      AnalyticsManager.trackRegistrationComplete()
      ReactPixel.track('CompleteRegistration')
      branch.logEvent('UserRegisteredWithLink', {
        user_who_shared: resp.data.user.id,
        auth_method: AuthProviders.Original
      })
    } catch (err) {
      dispatch(actions.verifyAsync.failure(err.response.data))
    }
  }
}

export const socialLogin = (user: ISocialLoginPayload): ThunkAction => {
  let socialAccessToken: string
  return async dispatch => {
    dispatch(actions.socialLoginAsync.request())

    try {
      FB.getLoginStatus((response: any) => {
        if (response.status === 'connected') {
          socialAccessToken = response.authResponse.accessToken
        }
      })

      const resp = await AuthApi.socialLogin(user)

      TokenStorage.storeSocialToken(socialAccessToken)

      dispatch(
        actions.socialLoginAsync.success({
          userData: resp.data,
          isVerified: !TokenStorage.isVerified(resp.data.accessToken)
        })
      )

      if (TokenStorage.isVerified(resp.data.accessToken)) {
        TokenStorage.storeToken(resp.data.accessToken)
        // @ts-ignore
        dispatch(initApp())
      }

      if (resp.data && resp.data.shouldChoosePreferences) {
        AnalyticsManager.trackSignUp(user.provider)
        branch.logEvent('UserRegisteredWithLink', {
          user_who_shared: resp.data.user.id,
          auth_method: user.provider
        })
      } else {
        AnalyticsManager.trackSignIn(user.provider)
        branch.logEvent('UserLoggedInWithLink', {
          user_who_shared: resp.data.user.id,
          auth_method: user.provider
        })
      }
    } catch (err) {
      dispatch(actions.socialLoginAsync.failure(err.response.data))
      return Promise.reject(err)
    }
  }
}

export const socialLoginEmail = (email: string): ThunkAction => {
  return async dispatch => {
    FB.getLoginStatus((response: any) => {
      if (response.status === 'connected') {
        const token = response.authResponse.accessToken
        dispatch(
          // @ts-ignore
          socialLogin({ email, token, provider: AuthProviders.Facebook })
        )
      }
    }, true)
  }
}

export const fetchProfileSettings = (): ThunkAction => {
  return async dispatch => {
    dispatch(actions.profileSettingsAsync.request())

    try {
      const resp = await ProfileApi.getSettings()

      dispatch(actions.profileSettingsAsync.success({ ...resp.data.data }))
    } catch (err) {
      dispatch(actions.profileSettingsAsync.failure(err))
    }
  }
}

export const logout = (): ThunkAction => async dispatch => {
  TokenStorage.clear()
  // history.push(routePath.START_PAGE)

  if ('gapi' in window) {
    const authGoogle = gapi.auth2.getAuthInstance()
    authGoogle.signOut().then(() => {
      authGoogle.disconnect()
    })

    authGoogle.disconnect()
  }

  if ('FB' in window) {
    FB.logout(() => {
      // @ts-ignore
      FB.Auth.setAuthResponse(null, 'unknown')
    })
  }

  return dispatch(actions.logout())
}

export const setAuthenticated = (): ThunkAction => async dispatch => {
  return dispatch(actions.setAuthenticated())
}

export const forgotPassword = (email: string): ThunkAction => {
  return async dispatch => {
    dispatch(actions.forgotAsync.request())

    try {
      const resp = await AuthApi.forgotPassword(email)

      dispatch(actions.forgotAsync.success(resp.data))
    } catch (err) {
      dispatch(actions.forgotAsync.failure(err.response.data))
      return Promise.reject(err)
    }
  }
}

export const verifyRecovery = (token: string, email: string): ThunkAction => {
  return async dispatch => {
    dispatch(actions.verifyRecoveryAsync.request())

    try {
      const resp = await AuthApi.verifyRecovery(email, token)

      dispatch(actions.verifyRecoveryAsync.success(resp.data))
    } catch (err) {
      dispatch(actions.verifyRecoveryAsync.failure(err.response.data))
      return Promise.reject(err)
    }
  }
}

export const verifyBetaBlock = (token: string, email: string): ThunkAction => {
  return async dispatch => {
    dispatch(actions.verifyRecoveryAsync.request())

    try {
      const resp = await AuthApi.verifyRecovery(email, token)

      dispatch(actions.verifyRecoveryAsync.success(resp.data))
    } catch (err) {
      dispatch(actions.verifyRecoveryAsync.failure(err.response.data))
      return Promise.reject(err)
    }
  }
}

export const betablockAccess = (token: string): ThunkAction => {
  return async dispatch => {
    dispatch(actions.betablocksLoginAsync.request())

    try {
      const resp = await AuthApi.betablockAccess(token)

      dispatch(actions.betablocksLoginAsync.success(resp.data))
    } catch (err) {
      dispatch(actions.betablocksLoginAsync.failure(err.response.data))
      return Promise.reject(err)
    }
  }
}

export const recoveryPassword = (
  recoveryData: IConfirmPassword
): ThunkAction => {
  return async (dispatch, getState) => {
    dispatch(actions.recoveryPasswordAsync.request())

    const token = selectAccessToken(getState())
    try {
      const resp = await AuthApi.recoveryPassword(recoveryData, token)

      dispatch(actions.recoveryPasswordAsync.success(resp.data))
      TokenStorage.clear()
    } catch (err) {
      dispatch(actions.recoveryPasswordAsync.failure(err.response.data))
      return Promise.reject(err)
    }
  }
}

export const changePassword = (newPassword: IChangePassword): ThunkAction => {
  return async dispatch => {
    // @ts-ignore
    const isValid = await dispatch(isValidPassword(newPassword))

    if (!isValid) {
      return
    }

    dispatch(actions.changePasswordAsync.request())

    try {
      const resp = await AuthApi.changePassword(newPassword)

      dispatch(actions.changePasswordAsync.success(resp.data))
      TokenStorage.storeToken(resp.data.accessToken)
      dispatch(actions.showModal('Your profile has been updated successfully'))
    } catch (err) {
      dispatch(actions.changePasswordAsync.failure(err.response.data))
      return Promise.reject(err)
    }
  }
}

export const saveSubscriptionToken = (token: string): ThunkAction => {
  return async dispatch => {
    dispatch(actions.pushSubscriptionToken.request())

    try {
      await NotificationApi.sync(token)

      return dispatch(actions.pushSubscriptionToken.success())
    } catch (error) {
      return dispatch(actions.pushSubscriptionToken.failure(error))
    }
  }
}

export const isValidPassword = (passwordForm: IChangePassword): ThunkAction => {
  return async dispatch => {
    const errors = {} as IChangePassword

    for (const field of Object.keys(passwordForm) as Array<
      keyof IChangePassword
    >) {
      if (!passwordForm[field]) {
        errors[field] = 'This field is required'
      }

      if (
        passwordForm[field].length < MIN_PASSWORD_LENGTH &&
        passwordForm[field].length > 0
      ) {
        errors[
          field
        ] = `The password must be at least ${MIN_PASSWORD_LENGTH} characters long`
      }
      if (passwordForm[field].includes(' ')) {
        errors[field] = 'Spaces are not allowed in the password'
      }
    }

    if (passwordForm.password_confirmation !== passwordForm.password) {
      errors.password_confirmation = "The passwords don't match"
    }

    if (
      passwordForm.password.length > 0 &&
      passwordForm.password === passwordForm.current_password
    ) {
      errors.password = 'New password should be different from the old one'
    }

    const isValid = !Object.keys(errors).length

    dispatch(actions.setProfilePasswordErrors(errors))
    return isValid
  }
}
