import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { routePath } from 'routes'

import { AppState, Dispatch } from 'store/types'
import {
  login,
  socialLogin,
  socialLoginEmail,
  verify,
  resend,
  betablockLogin
} from 'redux/auth/operations'
import { clearAuthErrors } from 'redux/auth/actions'
import {
  getError,
  selectAuthErrors,
  selectIsFetchingLoggedIn
} from 'redux/auth/selectors'

import SignInForm from 'components/auth/SignInForm'
import EnterCodeForm from 'components/auth/EnterCodeForm'
import AuthWrapper from 'components/content/AuthWrapper'
import EnterEmailForm from 'components/auth/EnterEmailForm'
import AuthForm from 'components/auth/AuthForm'

const breadCrumbs = ['Log In to Continue', 'Enter email', 'Enter Code']

class SignInPage extends Component<Props, { currentStep: number }> {
  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (
      nextProps.logInErrors === 'The email field is required.' &&
      prevState.currentStep === 0
    ) {
      return {
        currentStep: 1
      }
    } else if (nextProps.errorWithToken.accessToken) {
      return { currentStep: 2 }
    } else {
      return null
    }
  }
  constructor(props: Props) {
    super(props)
    this.state = { currentStep: 0 }
  }

  componentWillUnmount(): void {
    this.props.clearAuthErrors()
  }

  nextStep = () => {
    this.setState(({ currentStep }) => ({
      currentStep: currentStep >= 1 ? breadCrumbs.length - 1 : currentStep + 1
    }))
  }

  onSendEmail = async (email: string) => {
    const { socialLoginEmail } = this.props

    try {
      await socialLoginEmail(email)
      this.nextStep()
    } catch (err) {
      console.error('login fail')
    }
  }

  renderFormElem = (step: number) => {
    const {
      login,
      betablockLogin,
      logInErrors,
      location,
      clearAuthErrors,
      verify,
      resend,
      isFetchingVerify,
      errorWithToken
    } = this.props

    const isBetaBlock = location.pathname.includes(routePath.SIGN_IN_BETABLOCK)

    switch (step) {
      case 0:
        return (
          <SignInForm
            onSubmit={isBetaBlock ? betablockLogin : login}
            clearErrors={clearAuthErrors}
            serverErrors={logInErrors}
            isFetching={isFetchingVerify}
          />
        )
      case 1:
        return (
          <EnterEmailForm
            onSubmit={this.onSendEmail}
            clearErrors={clearAuthErrors}
            serverErrors={logInErrors}
            isFetching={isFetchingVerify}
          />
        )
      case 2:
        return (
          <EnterCodeForm
            serverErrors={logInErrors}
            onSubmit={verify}
            isFetching={isFetchingVerify}
            resend={resend}
            accessToken={errorWithToken.accessToken}
            clearErrors={clearAuthErrors}
          />
        )
      default:
        return null
    }
  }

  render() {
    const { currentStep } = this.state
    return (
      <AuthWrapper>
        <AuthForm
          title={breadCrumbs[currentStep]}
          content={this.renderFormElem(currentStep)}
        />
      </AuthWrapper>
    )
  }
}

interface LocationState {
  pathname: string
  search: any
  state: { guestEmail: string }
}

interface ReceivedProps {
  location: LocationState
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & ReceivedProps

const mapStateToProps = (state: AppState) => ({
  logInErrors: getError(selectAuthErrors(state)),
  errorWithToken: selectAuthErrors(state),
  isFetchingVerify: selectIsFetchingLoggedIn(state)
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      login,
      betablockLogin,
      socialLogin,
      socialLoginEmail,
      clearAuthErrors,
      verify,
      resend
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignInPage)
