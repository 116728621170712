import React from 'react'

const CommentClockIcon = props => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.99996 0.666504C13.6025 0.666504 17.3333 4.39817 17.3333 8.99984C17.3333 13.6015 13.6025 17.3332 8.99996 17.3332C4.39746 17.3332 0.666626 13.6015 0.666626 8.99984C0.666626 4.39817 4.39746 0.666504 8.99996 0.666504ZM8.99996 2.05567C5.17079 2.05567 2.05579 5.17067 2.05579 8.99984C2.05579 12.829 5.17079 15.944 8.99996 15.944C12.8291 15.944 15.9441 12.829 15.9441 8.99984C15.9441 5.17067 12.8291 2.05567 8.99996 2.05567ZM8.37496 3.99984C8.69121 3.99984 8.95284 4.23512 8.99425 4.54007L8.99996 4.62484V8.99984H11.7083C12.0533 8.99984 12.3333 9.27984 12.3333 9.62484C12.3333 9.94109 12.098 10.2027 11.7931 10.2441L11.7083 10.2498H8.37496C8.05871 10.2498 7.79708 10.0146 7.75567 9.70961L7.74996 9.62484V4.62484C7.74996 4.27984 8.02996 3.99984 8.37496 3.99984Z"
      fill="white"
      {...props}
    />
  </svg>
)

export default CommentClockIcon
