import axios from 'axios'
import { ProfileApi } from 'api/profile.api'
import { NotificationApi } from 'api/notification.api'
import { ReportApi } from 'api/report.api'

import * as actions from './actions'
import {
  selectEventDetails,
  selectFetchingInvestments,
  selectNotifications,
  selectNotificationsData,
  selectProfileFollowing,
  selectProfileIsFetching
} from './selectors'
import { IProfile, INewAddress, ReportType } from './types'

import { fetchAddressAsync } from 'redux/checkout/actions'
import { selectCountries } from 'redux/checkout/selectors'
import { fetchProfileSettings } from 'redux/auth/operations'
import { showModal } from 'redux/auth/actions'
import { IExportForm } from 'components/influencer/analytics'

import {
  checkSocialUrl,
  isSpecialCharacter,
  isValidEmail,
  hasNumbers
} from 'utils/helpers'

import { IErrors, ISocial, IStatus } from 'redux/commonTypes'
import { ThunkAction } from 'store/types'
import { selectAllInvestments } from './selectors'

export const isValidProfileDetails = (form: IProfile): ThunkAction => {
  return async dispatch => {
    const errors = {} as IErrors<IProfile>
    const { social } = form

    if (isSpecialCharacter(form.first_name) || hasNumbers(form.first_name)) {
      errors.first_name =
        'First name should not contain numbers or special characters'
    }

    if (isSpecialCharacter(form.last_name) || hasNumbers(form.last_name)) {
      errors.last_name =
        'Last name should not contain numbers or special characters'
    }

    if (form.email && !isValidEmail(form.email)) {
      errors.email = 'You must enter valid email'
    }

    for (const key of Object.keys(social) as Array<keyof ISocial>) {
      if (social[key] && !checkSocialUrl(social[key], key)) {
        // @ts-ignore
        errors.social = { ...errors.social }
        // @ts-ignore
        errors.social[key] = 'Please enter a valid URL'
      }
    }

    for (const field of Object.keys(form) as Array<keyof IProfile>) {
      if (['phone', 'social', 'birth_date'].some(k => field === k)) {
        continue
      }

      if (!form[field]) {
        errors[field] = 'This field is required'
      }
    }

    const isValid = !Object.keys(errors).length

    dispatch(actions.setProfileDetailsErrors(errors))
    return isValid
  }
}

export const updateProfileSettings = (user: IProfile): ThunkAction => {
  const { birth_date, ...userToSubmit } = user
  return async dispatch => {
    // @ts-ignore
    const isValid = await dispatch(isValidProfileDetails(userToSubmit))

    if (!isValid) {
      return
    }

    dispatch(actions.profileSettingsUpdateAsync.request())

    try {
      const resp = await ProfileApi.updateSettings(userToSubmit)

      dispatch(actions.profileSettingsUpdateAsync.success(resp.data))
      dispatch(showModal('Your profile has been updated successfully'))
      // @ts-ignore
      dispatch(fetchProfileSettings())
    } catch (err) {
      dispatch(actions.profileSettingsUpdateAsync.failure(err.response.data))
      return Promise.reject(err)
    }
  }
}

export const profileExport = (form: IExportForm): ThunkAction => {
  return async (dispatch, getState) => {
    dispatch(actions.profileExportAsync.request())
    try {
      const allEventsIds = selectEventDetails(getState()).map(e => e.id)

      const data = {
        ...Object.keys(form.types).reduce(
          // @ts-ignore
          (acc, key) => ({ ...acc, [key]: form.types[key] ? 'on' : 'off' }),
          {}
        ),
        events:
          form.events.value === 'all' ? allEventsIds : [form.events.value],
        format: form.format.value
      }

      const resp = await ProfileApi.export(data)

      dispatch(actions.profileExportAsync.success(resp.data))
      dispatch(showModal('Data has been exported successfully'))
    } catch (err) {
      dispatch(actions.profileExportAsync.failure(err))
    }
  }
}

export const sendMessage = (form: any): ThunkAction => {
  return async dispatch => {
    dispatch(actions.sendMessageAsync.request())
    try {
      const data = {
        channels: Object.keys(form.channels)
          // @ts-ignore
          .filter(key => form.channels[key]),
        message: form.message,
        subject: form.subject,
        filter: {
          amount_to: form.amountTo,
          amount_from: form.amountFrom,
          ages: form.ages,
          events: form.events
        }
      }

      const resp = await NotificationApi.send(data)

      dispatch(actions.sendMessageAsync.success(resp.data))
      dispatch(showModal('Message has been sent successfully'))
    } catch (err) {
      dispatch(actions.sendMessageAsync.failure(err))
    }
  }
}

export const updateProfilePhoto = (photo: any): ThunkAction => {
  return async dispatch => {
    dispatch(actions.profilePhotoUpdateAsync.request())

    try {
      if (photo.size > 1024 * 1024 * 2) {
        return dispatch(
          actions.profilePhotoUpdateAsync.failure({
            message: 'The image size should not exceed 2MBs'
          })
        )
      }

      const resp = await ProfileApi.updatePhoto(photo)
      dispatch(actions.profilePhotoUpdateAsync.success(resp.data))
      // @ts-ignore
      dispatch(fetchProfileSettings())
    } catch (err) {
      dispatch(actions.profilePhotoUpdateAsync.failure(err.response.data))
    }
  }
}

export const deleteProfilePhoto = (): ThunkAction => {
  return async dispatch => {
    dispatch(actions.profilePhotoDeleteAsync.request())
    try {
      const resp = await ProfileApi.deletePhoto()

      dispatch(actions.profilePhotoDeleteAsync.success(resp.data))
    } catch (err) {
      dispatch(actions.profilePhotoDeleteAsync.failure(err.response.data))
    }
  }
}

export const loadFollowedUsers = (): ThunkAction => {
  return async dispatch => {
    dispatch(actions.clearFollowing())

    // @ts-ignore
    dispatch(fetchFollowing())
  }
}

export const loadMoreFollowedUsers = (): ThunkAction => {
  return async (dispatch, getState) => {
    const isFetching = selectProfileIsFetching(getState())
    const { meta } = selectProfileFollowing(getState())

    if (!isFetching && meta && meta.current_page < meta.last_page) {
      // @ts-ignore
      dispatch(fetchFollowing())
    }
  }
}

export const fetchFollowing = (): ThunkAction => {
  return async (dispatch, getState) => {
    dispatch(actions.profileFollowingAsync.request())

    try {
      const following = selectProfileFollowing(getState())

      const params: { page?: number } = {}

      params.page = following.meta
        ? following.meta.current_page < following.meta.last_page
          ? following.meta.current_page + 1
          : undefined
        : 1
      const resp = await ProfileApi.getFollowing(params)

      dispatch(actions.profileFollowingAsync.success(resp.data))
    } catch (err) {
      dispatch(actions.profileFollowingAsync.failure(err.response.data))
    }
  }
}

export const fetchBalance = (): ThunkAction => {
  return async dispatch => {
    dispatch(actions.profileBalanceAsync.request())
    try {
      const resp = await ProfileApi.getBalance()
      dispatch(actions.profileBalanceAsync.success(resp.data))
    } catch (err) {
      dispatch(actions.profileBalanceAsync.failure(err))
    }
  }
}

export const loadMoreNotifications = (): ThunkAction => {
  return async (dispatch, getState) => {
    const isFetching = selectProfileIsFetching(getState())
    const { meta } = selectNotifications(getState())

    if (!isFetching && meta && meta.current_page < meta.last_page) {
      // @ts-ignore
      dispatch(fetchNotifications())
    }
  }
}

export const loadNotifications = (): ThunkAction => {
  return async (dispatch, getState) => {
    const data = selectNotificationsData(getState())

    if (data.length > 0) {
      dispatch(actions.clearNotifications())
    }

    // @ts-ignore
    dispatch(fetchNotifications())
  }
}

export const fetchNotifications = (): ThunkAction => {
  return async (dispatch, getState) => {
    dispatch(actions.profileNotificationsAsync.request())
    try {
      const notifications = selectNotifications(getState())

      const params: { page?: number } = {}

      params.page = notifications.meta
        ? notifications.meta.current_page < notifications.meta.last_page
          ? notifications.meta.current_page + 1
          : undefined
        : 1

      const resp = await ProfileApi.getNotifications(params)

      dispatch(actions.profileNotificationsAsync.success(resp.data))
    } catch (err) {
      dispatch(actions.profileNotificationsAsync.failure(err.response.data))
    }
  }
}

export const deleteNotification = (id: number): ThunkAction => {
  return async dispatch => {
    dispatch(actions.deleteNotificationAsync.request())
    try {
      await ProfileApi.deleteNotification(id)

      dispatch(actions.deleteNotificationAsync.success({ id }))
    } catch (err) {
      dispatch(actions.deleteNotificationAsync.failure(err.response.data))
    }
  }
}

export const readNotifications = (notifs: {
  notifications: number[]
}): ThunkAction => {
  return async dispatch => {
    dispatch(actions.profileNotificationsReadAsync.request())
    try {
      await ProfileApi.readNotifications(notifs)

      dispatch(
        actions.profileNotificationsReadAsync.success({
          ids: notifs.notifications
        })
      )
    } catch (err) {
      dispatch(actions.profileNotificationsReadAsync.failure(err.response.data))
    }
  }
}

export const fetchAboutApplication = (): ThunkAction => {
  return async dispatch => {
    dispatch(actions.profileFetchAboutApplicationAsync.request())
    try {
      const resp = await ProfileApi.getFaq()

      dispatch(actions.profileFetchAboutApplicationAsync.success(resp.data))
    } catch (err) {
      dispatch(
        actions.profileFetchAboutApplicationAsync.failure(err.response.data)
      )
    }
  }
}

export const fetchUserAddress = (): ThunkAction => {
  return async dispatch => {
    dispatch(actions.fetchUserAddressAsync.request())
    try {
      const resp = await ProfileApi.getAddress()

      dispatch(actions.fetchUserAddressAsync.success(resp.data))
    } catch (err) {
      dispatch(actions.fetchUserAddressAsync.failure(err.response.data))
    }
  }
}

export const isValidUserAddress = (form: INewAddress): ThunkAction => {
  return async (dispatch, getState) => {
    const countries = selectCountries(getState())

    let states = [] as any

    const currentCountry =
      countries && countries.find(c => c.code === form.country)
    if (currentCountry && countries && form.country) {
      states = currentCountry.states.map((c: any) => ({
        value: c.id,
        label: c.state_name
      }))
    }

    const errors = {} as IErrors<INewAddress>
    let isValid

    if (form.zip && form.zip.length < 5) {
      errors.zip = 'Please enter a 5-digit Postal Code (numbers only)'
    }

    for (const field of Object.keys(form as INewAddress) as Array<
      keyof INewAddress
    >) {
      if (field === 'room') {
        continue
      }

      if (
        !form[field] ||
        (typeof form[field] !== 'string' &&
          form[field] &&
          // @ts-ignore
          !form[field].value)
      ) {
        errors[field] = 'This field is required'
        if (field === 'city') {
          errors[field] = 'Full address is required'
        }
      }

      if (states && states.length === 0) {
        delete errors.state
      }
    }

    isValid = !Object.keys(errors).length

    dispatch(actions.setAddressErrors(errors))
    return isValid
  }
}

export const updateUserAddress = (address: INewAddress): ThunkAction => {
  return async dispatch => {
    // @ts-ignore
    const isValid = await dispatch(isValidUserAddress(address))

    if (!isValid) {
      return
    }

    dispatch(actions.updateUserAddressAsync.request())

    try {
      await ProfileApi.sendAddress(address)

      dispatch(actions.updateUserAddressAsync.success(address))
      dispatch(showModal('Your address has been updated successfully'))
    } catch (err) {
      dispatch(actions.updateUserAddressAsync.failure(err.response.data))
      return Promise.reject(err)
    }
  }
}

export const fetchAddress = (input: any): ThunkAction => {
  return async dispatch => {
    dispatch(fetchAddressAsync.request())

    try {
      const autocomplete = new window.google.maps.places.AutocompleteService()

      // @ts-ignore
      autocomplete.getPlacePredictions(input, (predictions, status) => {
        dispatch(fetchAddressAsync.success(predictions))
      })
    } catch (err) {
      return dispatch(fetchAddressAsync.failure(err))
    }
  }
}

export const sendReport = (type: ReportType, message: string): ThunkAction => {
  return async dispatch => {
    if (message.trim().length < 4) {
      return dispatch(
        actions.sendReportAsync.failure({
          message: 'Please fill out this field',
          name: 'empty field'
        })
      )
    }

    dispatch(actions.sendReportAsync.request())

    const report = { message, device: '3', version: '1.0' }

    try {
      const resp = await ReportApi.send(type, report)

      dispatch(actions.sendReportAsync.success(resp.data))
      dispatch(showModal('Your message was successfully delivered.'))
    } catch (e) {
      dispatch(actions.sendReportAsync.failure(e.response.data))
    }
  }
}

export const fetchAnalytics = (): ThunkAction => {
  return async dispatch => {
    dispatch(actions.fetchAnalyticsAsync.request())

    try {
      const resp = await ProfileApi.getAnalytics()

      dispatch(actions.fetchAnalyticsAsync.success(resp.data))
    } catch (e) {
      dispatch(actions.fetchAnalyticsAsync.failure(e.response.data))
    }
  }
}

export const cancelInvestment = (id: number): ThunkAction => {
  return async dispatch => {
    dispatch(actions.cancelInvestmentAsync.request())

    try {
      const resp = await axios.delete<IStatus>(`fund-america/investments/${id}`)
      dispatch(actions.cancelInvestmentAsync.success({ ...resp.data, id }))
    } catch (err) {
      dispatch(actions.cancelInvestmentAsync.failure(err.response.data))
    }
  }
}

export const fetchInvestments = (): ThunkAction => {
  return async (dispatch, getState) => {
    dispatch(actions.fetchInvestmentsAsync.request())

    const { meta } = selectAllInvestments(getState())

    const params: { page: number } = { page: 1 }

    if (meta && meta.current_page < meta.last_page) {
      params.page = meta.current_page + 1
    }

    try {
      const resp = await ProfileApi.getUserInvestments(params)
      dispatch(actions.fetchInvestmentsAsync.success(resp.data))
    } catch (err) {
      dispatch(actions.fetchInvestmentsAsync.failure(err))
    }
  }
}

export const loadMoreInvestments = (): ThunkAction => {
  return async (dispatch, getState) => {
    const isFetching = selectFetchingInvestments(getState())
    const { meta } = selectAllInvestments(getState())

    if (!isFetching && meta && meta.current_page !== meta.last_page) {
      // @ts-ignore
      dispatch(fetchInvestments())
    }
  }
}

export const fetchInvestmentDetails = (id: number | string): ThunkAction => {
  return async dispatch => {
    dispatch(actions.fetchInvestmentDetailsAsync.request())

    try {
      const resp = await ProfileApi.getInvestmentDetails(id)
      dispatch(actions.fetchInvestmentDetailsAsync.success(resp.data))
    } catch (err) {
      dispatch(actions.fetchInvestmentDetailsAsync.failure(err))
    }
  }
}
