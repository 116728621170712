import React from 'react'
import { LINKS, WEBSITE } from 'utils/constants'
import Modal from 'src/components/UI/Modal'
import Link from 'src/components/UI/Link'

import { ModalFooter, Terms, Text, Title } from './styles'

interface InvestTermsProps {
  isOpen: boolean
  onClose?: () => void
}

const InvestTermsModal: React.FC<InvestTermsProps> = ({ isOpen, onClose }) => {
  const renderHeader = () => <Title>Terms & Conditions</Title>

  return (
    <Modal
      header={renderHeader()}
      footer={<ModalFooter />}
      isOpen={isOpen}
      onClose={onClose}
      style={{ maxHeight: '866px' }}
    >
      <Terms>
        <Text>Updated September 16th, 2020</Text>
        <Text>
          Terms of Use — Innovative data-driven ecosystem for celebrities,
          brands and fans
        </Text>
        <Text>
          <strong>SDF </strong>These Terms of Service (“Terms”) apply to your
          access to and use of the websites, mobile applications, social media
          accounts and other online products and services (collectively, the
          “Services”) provided by EdenLedger, Inc. (d/b/a FanVestor) , and
          Dalmore, Prime Trust / North Capital Private Securities Corporation,
          KoreConX, SMARSH, and other affiliates &nbsp;(collectively,
          “EdenLedger” or “we”). By using our Services, you agree to these
          Terms, including the mandatory arbitration provision and class action
          waiver in Section 14. If you do not agree to these Terms, do not use
          our Services.
        </Text>

        <Text>
          If you have any questions about these Terms or our Services, please
          contact us a info@fanvestor.com. For information about how we collect,
          use, share and otherwise process information about you, please see our{' '}
          <Link target="_blank" to={WEBSITE + LINKS.PRIVACY}>
            Privacy Policy
          </Link>
        </Text>

        <Text>
          <strong>1. Eligibility</strong>
        </Text>

        <Text>
          You must be at least 18 years of age to use our Services. If you use
          our Services on behalf of another person or entity, (a) all references
          to “you” throughout these Terms will include that person or entity,
          (b) you represent that you are authorized to accept these Terms on
          that person’s or entity’s behalf, and (c) in the event you or the
          person or entity violates these Terms, the person or entity agrees to
          be responsible to us. In order to use the Services, you must first
          successfully provide the required identification information pursuant
          to our Bank Secrecy Act (“BSA”) and Anti-Money Laundering (“AML”)
          Compliance Program (collectively, our “BSA/AML Program”).
        </Text>

        <Text>
          <strong>2. User Accounts and Account Security</strong>
        </Text>

        <Text>
          Only persons that have opened an account (“Account”) and successfully
          completed our BSA/AML Program are considered EdenLedger customers
          (each, a “Customer”). If you register for an account, you must provide
          accurate account information and promptly update this information if
          it changes. You hereby authorize us, or a third-party service
          provider, to take any measures that we consider necessary to verify
          and authenticate your identity, confirm the accuracy of all
          information you submit to us, and to take any action we deem necessary
          based on the results. You also must maintain the security of your
          account and promptly notify us if you discover or suspect that someone
          has accessed your account. Your account is for personal use only. You
          agree that you will not allow any persons to access or use your
          account credentials. We reserve the right to reclaim usernames,
          including on behalf of businesses or individuals that hold legal
          claim, including trademark rights, in those usernames. We may, at any
          time and in our sole discretion, restrict your access to, or otherwise
          impose conditions or restrictions upon your use of, the Services, with
          or without prior notice.
        </Text>

        <Text>
          <strong>3. Perks</strong>
        </Text>

        <Text>
          Customers may obtain digital credits (“Perks”) through the Services or
          other incentives. Each Perk is redeemable for goods and services with
          third-party brands and influencers. The terms of sale applicable to
          each Perks are available on the applicable Perk listing. When you
          purchase Perks through the Services, the processing and settlement of
          each purchase transaction is carried out by a third-party payment
          service provider (“Payment Service Partner”) under separate terms of
          service. The Payment Service Partner’s role is to accept and process
          credit card, debit card and other types of payments to enable you to
          purchase Perks. EdenLedger is not a party to the Payment Service
          Partner’s terms of service and is not liable to you in respect
          thereof. EdenLedger is not directly supported, endorsed, or certified
          by any such payment service processors, and EdenLedger and such
          Payment Service Partners make no warranties or claims about the other.
          EdenLedger provides each Customer with an individual User Account to
          maintain the Perks that they obtain through the Services. The User
          Account is hosted by EdenLedger that enables each Customer to store
          and track their Perks.
        </Text>

        <Text>
          All Perks held in your User Account are custodial assets held by
          EdenLedger for your benefit. Title to Perks shall at all times remain
          with you and shall not transfer to EdenLedger. As the owner of Perks
          in your User Account, you shall bear all risk of loss of such Perks.
          None of the Perks in your User Account are the property of, or shall
          or may be loaned to, EdenLedger;
        </Text>

        <Text>
          EdenLedger does not represent or treat assets in users accounts as
          belonging to EdenLedger. EdenLedger may not grant a security interest
          in the Perks held in your User Account. Except as required by a
          facially valid court order, or except as provided herein, EdenLedger
          will not sell, transfer, loan, hypothecate, or otherwise alienate
          Perks in your User Account unless instructed by you.
        </Text>

        <Text>
          <strong>4. Prohibited Conduct and Content</strong>
        </Text>

        <Text>
          (a) You will not violate any applicable law, contract, intellectual
          property right or other third-party right or commit a tort, and you
          are solely responsible for your conduct while using our Services. You
          will not:
        </Text>

        <ul>
          <li>
            Engage in any harassing, threatening, intimidating, predatory or
            stalking conduct;
          </li>
          <li>Use or attempt to use another user’s account;</li>
          <li>
            Impersonate or post on behalf or any person or entity or otherwise
            misrepresent your affiliation with a person or entity;
          </li>
          <li>Sell, resell or commercially use our Services;</li>
          <li>
            Copy, reproduce, distribute, publicly perform or publicly display
            all or portions of our Services, except as expressly permitted by us
            or our licensors;
          </li>
          <li>
            Modify our Services, remove any proprietary rights notices or
            markings, or otherwise make any derivative works based upon our
            Services;
          </li>
          <li>
            Use our Services other than for their intended purpose and in any
            manner that could interfere with, disrupt, negatively affect or
            inhibit other users from fully enjoying our Services or that could
            damage, disable, overburden or impair the functioning of our
            Services in any manner;
          </li>
          <li>
            Reverse engineer any aspect of our Services or do anything that
            might discover source code or bypass or circumvent measures employed
            to prevent or limit access to any part of our Services;
          </li>
          <li>
            Use any data mining, robots or similar data gathering or extraction
            methods designed to scrape or extract data from our Services;
          </li>
          <li>
            Develop or use any applications that interact with our Services
            without our prior written consent;
          </li>
        </ul>

        <ul>
          <li>
            Send, distribute or post spam, unsolicited or bulk commercial
            electronic communications, chain letters, or pyramid schemes;
          </li>
          <li>
            Bypass or ignore instructions contained in our robots.txt file; or
          </li>
          <li>
            Use our Services for any illegal or unauthorized purpose, or engage
            in, encourage or promote any activity that violates these Terms.
          </li>
        </ul>

        <Text>
          (b) Enforcement of this Section 4 is solely at EdenLedger’s
          discretion, and failure to enforce this section in some instances does
          not constitute a waiver of our right to enforce it in other instances.
          In addition, this Section 4 does not create any private right of
          action on the part of any third party or any reasonable expectation
          that the Services will not contain any content that is prohibited by
          such rules.
        </Text>

        <Text>
          <strong>5. Ownership. Limited License</strong>
        </Text>

        <Text>
          The Services, including the text, graphics, images, photographs,
          videos, illustrations and other content contained therein, are owned
          by EdenLedger or our licensors and are protected under both United
          States and foreign laws. Except as explicitly stated in these Terms,
          all rights in and to the Services are reserved by us or our licensors.
          Subject to your compliance with these Terms, you are hereby granted a
          limited, nonexclusive, nontransferable, non-sublicensable, revocable
          license to access and use our Services for your own personal,
          noncommercial use. Any use of the Services other than as specifically
          authorized herein, without our prior written permission, is strictly
          prohibited, will terminate the license granted herein and violate our
          intellectual property rights.
        </Text>

        <Text>
          <strong>6. Trademarks</strong>
        </Text>

        <Text>
          EdenLedger, FanVestor, FanPerks and our logos, our product or service
          names, our slogans and the look and feel of the Services are
          trademarks of EdenLedger and may not be copied, imitated or used, in
          whole or in part, without our prior written permission. All other
          trademarks, registered trademarks, product names and company names or
          logos mentioned on the Services are the property of their respective
          owners. Reference to any products, services, processes or other
          information by trade name, trademark, manufacturer, supplier or
          otherwise does not constitute or imply endorsement, sponsorship or
          recommendation by us.
        </Text>

        <Text>
          <strong>7. Feedback</strong>
        </Text>

        <Text>
          You may voluntarily post, submit or otherwise communicate to us any
          questions, comments, suggestions, ideas, original or creative
          materials or other information about EdenLedger or our Services
          (collectively, “Feedback”). You understand that we may use such
          Feedback for any purpose, commercial or otherwise, without
          acknowledgment or compensation to you, including to develop, copy,
          publish, or improve the Feedback in EdenLedger’s sole discretion. You
          understand that EdenLedger may treat Feedback as nonconfidential.
        </Text>

        <Text>
          <strong>8. Third-Party Content</strong>
        </Text>

        <Text>
          We may provide information about third-party products, services,
          activities or events, or we may allow third parties to make their
          content and information available on or through the Services
          (collectively, “Third-Party Content”). We provide Third-Party Content
          as a service to those interested in such content. Your dealings or
          correspondence with third parties and your use of or interaction with
          any Third-Party Content are solely between you and the third party.
          EdenLedger does not control or endorse, and makes no representations
          or warranties regarding, any Third-Party Content, and your access to
          and use of such Third-Party Content is at your own risk.
        </Text>

        <Text>
          <strong>9. Indemnification</strong>
        </Text>

        <Text>
          To the fullest extent permitted by applicable law, you will indemnify,
          defend and hold harmless EdenLedger and our subsidiaries and
          affiliates, and each of our respective officers, directors, agents,
          partners and employees (individually and collectively, the “EdenLedger
          Parties”) from and against any losses, liabilities, claims, demands,
          damages, expenses or costs (“Claims”) arising out of or related to (a)
          your access to or use of the Services; (b) your User Content or
          Feedback; (c) your violation of these Terms; (d) your violation,
          misappropriation or infringement of any rights of another (including
          intellectual property rights or privacy rights); or
        </Text>

        <Text>
          (e) your conduct in connection with the Services. You agree to
          promptly notify the EdenLedger Parties of any third-party Claims,
          cooperate with the EdenLedger Parties in defending such Claims and pay
          all fees, costs and expenses associated with defending such Claims
          (including attorney’s fees). You also agree that the EdenLedger
          Parties will have control of the defense or settlement, at
          EdenLedger’s sole option, of any third-party Claims. This indemnity is
          in addition to, and not in lieu of, any other indemnities set forth in
          a written agreement between you and EdenLedger or the other EdenLedger
          Parties.
        </Text>

        <Text>
          <strong>10. Disclosures. Disclaimers</strong>
        </Text>

        <Text>
          EdenLedger does not own or control certain aspects of the underlying
          software protocols that are used in connection with the Services. In
          general, those underlying protocols are open-source and anyone can
          use, copy, modify, and distribute them. EdenLedger is not responsible
          for operation of those underlying protocols, and EdenLedger makes no
          guarantee of their functionality, security, or availability.
          EdenLedger is not your broker, lawyer, intermediary, agent, or advisor
          and has no fiduciary relationship or obligation to you regarding any
          other decisions or activities that you effect when using the Services.
          Neither our communications nor any information that we provide to you
          is intended as, or shall be considered or construed as, advice.
          EdenLedger is not registered or licensed by the Securities and
          Exchange Commission, the Commodity Futures Trading Commission, or any
          financial regulatory authority.
        </Text>

        <Text>
          Your use of our Services is at your sole risk. Except as otherwise
          provided in a writing by us, our Services and any content therein are
          provided “as is” and “as available” without warranties of any kind,
          either express or implied, including implied warranties of
          merchantability, fitness for a particular purpose, title, and
          non-infringement. In addition, EdenLedger does not represent or
          warrant that our Services are accurate, complete, reliable, current or
          error-free. While EdenLedger attempts to make your use of our Services
          and any content therein safe, we cannot and do not represent or
          warrant that our Services or servers are free of viruses or other
          harmful components. You assume the entire risk as to the quality and
          performance of the Services.
        </Text>

        <Text>
          <strong>11. Limitation of Liability</strong>
        </Text>

        <Text>
          (a) To the fullest extent permitted by applicable law, EdenLedger and
          the other EdenLedger Parties will not be liable to you under any
          theory of liability—whether based in contract, tort, negligence,
          strict liability, warranty, or otherwise—for any indirect,
          consequential, exemplary, incidental, punitive or special damages or
          lost profits, even if EdenLedger or the other EdenLedger Parties have
          been advised of the possibility of such damages.
        </Text>

        <Text>
          (b) The total liability of EdenLedger and the other EdenLedger Parties
          for any claim arising out of or relating to these Terms or our
          Services, regardless of the form of the action, is limited to the fees
          paid by each individual Claimant in the preceeding six (6) month
          period (c) The limitations set forth in this Section 11 will not limit
          or exclude liability for the gross negligence, fraud or intentional
          misconduct of EdenLedger or the other EdenLedger Parties or for any
          other matters in which liability cannot be excluded or limited under
          applicable law. Additionally, some jurisdictions do not allow the
          exclusion or limitation of incidental or consequential damages, so the
          above limitations or exclusions may not apply to you.
        </Text>

        <Text>
          <strong>12. Release</strong>
        </Text>

        <Text>
          To the fullest extent permitted by applicable law, you release
          EdenLedger and the other EdenLedger Parties from responsibility,
          liability, claims, demands and/or damages (actual and consequential)
          of every kind and nature, known and unknown (including claims of
          negligence), arising out of or related to disputes between users and
          the acts or omissions of third parties. If you are a consumer who
          resides in California, you hereby waive your rights under California
          Civil Code § 1542, which provides: “A general release does not extend
          to claims which the creditor does not know or suspect to exist in his
          or her favor at the time of executing the release, which if known by
          him or her must have materially affected his or her settlement with
          the debtor.”
        </Text>

        <Text>
          <strong>13. Transfer and Processing Data</strong>
        </Text>

        <Text>
          In order for us to provide our Services, you agree that we may
          process, transfer and store information about you in the United States
          and other countries, where you may not have the same rights and
          protections as you do under local law.
        </Text>

        <Text>
          <strong>14. Dispute Resolution. Binding Arbitration</strong>
        </Text>

        <Text>
          Please read the following section carefully because it requires you to
          arbitrate certain disputes and claims with EdenLedger and limits the
          manner in which you can seek relief from us, unless you opt out of
          arbitration by following the instructions set forth below. No class or
          representative actions or arbitrations are allowed under this
          arbitration provision. In addition, arbitration precludes you from
          suing in court or having a jury trial.
        </Text>

        <Text>
          (a) No Representative Actions. You and EdenLedger agree that any
          dispute arising out of or related to these Terms or our Services is
          personal to you and EdenLedger and that any dispute will be resolved
          solely through individual action, and will not be brought as a class
          arbitration, class action or any other type of representative
          proceeding.
        </Text>

        <Text>
          (b) Arbitration of Disputes. Except for small claims disputes in which
          you or EdenLedger seeks to bring an individual action in small claims
          court located in the county of your billing address or disputes in
          which you or EdenLedger seeks injunctive or other equitable relief for
          the alleged infringement or misappropriation of intellectual property,
          you and EdenLedger waive your rights to a jury trial and to have any
          other dispute arising out of or related to these Terms or our
          Services, including claims related to privacy and data security,
          (collectively, “Disputes”) resolved in court. Instead, for any Dispute
          that you have against EdenLedger you agree to first contact EdenLedger
          and attempt to resolve the claim informally by sending a written
          notice of your claim (“Notice”) to EdenLedger by email at
          legal@fanvestor.com.
        </Text>

        <Text>
          The Notice must (a) include your name, residence address, email
          address, and telephone number; (b) describe the nature and basis of
          the Dispute; and (c) set forth the specific relief sought. Our notice
          to you will be similar in form to that described above. If you and
          EdenLedger cannot reach an agreement to resolve the Dispute within
          thirty (30) days after such Notice is received, then either party may
          submit the Dispute to binding arbitration administered by JAMS or,
          under the limited circumstances set forth above, in court. All
          Disputes submitted to JAMS will be resolved through confidential,
          binding arbitration before one arbitrator. Arbitration proceedings
          will be held in [county, state] unless you are a consumer, in which
          case you may elect to hold the arbitration in your county of
          residence. For purposes of this Section 14, a “consumer” means a
          person using the Services for personal, family or household purposes.
          You and EdenLedger agree that Disputes will be held in accordance with
          the JAMS Streamlined Arbitration Rules and Procedures (“JAMS Rules”).
          The most recent version of the JAMS Rules are available on the JAMS
          website and are hereby incorporated by reference. You either
          acknowledge and agree that you have read and understand the JAMS Rules
          or waive your opportunity to read the JAMS Rules and waive any claim
          that the JAMS Rules are unfair or should not apply for any reason.
        </Text>

        <Text>
          (c) You and EdenLedger agree that these Terms affect interstate
          commerce and that the enforceability of this Section 14 will be
          substantively and procedurally governed by the Federal Arbitration
          Act, 9 U.S.C. § 1, et seq. (the “FAA”), to the maximum extent
          permitted by applicable law. As limited by the FAA, these Terms and
          the JAMS Rules, the arbitrator will have exclusive authority to make
          all procedural and substantive decisions regarding any Dispute and to
          grant any remedy that would otherwise be available in court, including
          the power to determine the question of arbitrability. The arbitrator
          may conduct only an individual arbitration and may not consolidate
          more than one individual’s claims, preside over any type of class or
          representative proceeding or preside over any proceeding involving
          more than one individual.
        </Text>

        <Text>
          (d) The arbitration will allow for the discovery or exchange of
          non-privileged information relevant to the Dispute. The arbitrator,
          EdenLedger, and you will maintain the confidentiality of any
          arbitration proceedings, judgments and awards, including information
          gathered, prepared and presented for purposes of the arbitration or
          related to the Dispute(s) therein. The arbitrator will have the
          authority to make appropriate rulings to safeguard confidentiality,
          unless the law provides to the contrary. The duty of confidentiality
          does not apply to the extent that disclosure is necessary to prepare
          for or conduct the arbitration hearing on the merits, in connection
          with a court application for a preliminary remedy or in connection
          with a judicial challenge to an arbitration award or its enforcement,
          or to the extent that disclosure is otherwise required by law or
          judicial decision.
        </Text>

        <Text>
          (e) You and EdenLedger agree that for any arbitration you initiate,
          you will pay the filing fee (up to a maximum of $250 if you are a
          consumer), and EdenLedger will pay the remaining JAMS fees and costs.
          For any arbitration initiated by EdenLedger, EdenLedger will pay all
          JAMS fees and costs. You and EdenLedger agree that the state or
          federal courts of the State of California and the United States
          sitting in San Francisco, CA have exclusive jurisdiction over any
          appeals and the enforcement of an arbitration award.
        </Text>

        <Text>
          (f) Any Dispute must be filed within one year after the relevant claim
          arose; otherwise, the Dispute is permanently barred, which means that
          you and EdenLedger will not have the right to assert the claim.
        </Text>

        <Text>
          (g) You have the right to opt out of binding arbitration within 30
          days of the date you first accepted the terms of this Section 14 by
          emailing an opt-out notice to us at privacy@fanvestor.com. In order to
          be effective, the opt-out notice must include your full name and
          address and clearly indicate your intent to opt out of binding
          arbitration. By opting out of binding arbitration, you are agreeing to
          resolve Disputes in accordance with
        </Text>

        <Text>
          <strong>Section 15.</strong>
        </Text>

        <Text>
          (h) If any portion of this Section 14 is found to be unenforceable or
          unlawful for any reason, (a) the unenforceable or unlawful provision
          shall be severed from these Terms; (b) severance of the unenforceable
          or unlawful provision shall have no impact whatsoever on the remainder
          of this Section 14 or the parties’ ability to compel arbitration of
          any remaining claims on an individual basis pursuant to this Section
          14; and (c) to the extent that any claims must therefore proceed on a
          class, collective, consolidated, or representative basis, such claims
          must be litigated in a civil court of competent jurisdiction and not
          in arbitration, and the parties agree that litigation of those claims
          shall be stayed pending the outcome of any individual claims in
          arbitration. Further, if any part of this Section 14 is found to
          prohibit an individual claim seeking public injunctive relief, that
          provision will have no effect to the extent such relief is allowed to
          be sought out of arbitration, and the remainder of this Section 14
          will be enforceable.
        </Text>

        <Text>
          <strong>16. Governing Law and Venue</strong>
        </Text>

        <Text>
          Any dispute arising from these Terms and your use of the Services will
          be governed by and construed and enforced in accordance with the laws
          of Delaware, except to the extent preempted by U.S. federal law,
          without regard to conflict of law rules or principles (whether of
          [state] or any other jurisdiction) that would cause the application of
          the laws of any other jurisdiction. Any dispute between the parties
          that is not subject to arbitration or cannot be heard in small claims
          court will be resolved in the state or federal courts of California
          and the United States, respectively, sitting in San Francisco, CA.
        </Text>

        <Text>
          <strong>17. Modifying and Terminating our Services</strong>
        </Text>

        <Text>
          We reserve the right to modify our Services or to suspend or stop
          providing all or portions of our Services at any time. You also have
          the right to stop using our Services at any time. We are not
          responsible for any loss or harm related to your inability to access
          or use our Service.
        </Text>

        <Text>
          <strong>18. Additional Terms and Amendments</strong>
        </Text>

        <Text>
          (a) We may supply different or additional terms in relation to some of
          our Services, and those different or additional terms become part of
          your agreement with us if you use those Services. If there is a
          conflict between these Terms and the additional terms, the additional
          terms will control for that conflict.
        </Text>

        <Text>
          (b) We may make changes to these Terms from time to time. If we make
          changes, we will provide you with notice of such changes, such as by
          sending an email, providing a notice through our Services or updating
          the date at the top of these Terms. Unless we say otherwise in our
          notice, the amended Terms will be effective immediately, and your
          continued use of our Services after we provide such notice will
          confirm your acceptance of the changes. If you do not agree to the
          amended Terms, you must stop using our Services.
        </Text>

        <Text>
          <strong>19. Our Commitment to Accessibility</strong>
        </Text>

        <Text>
          EdenLedger is committed to making our website’s content accessible and
          user friendly to everyone. If you are having difficulty viewing or
          navigating the content on this website, or notice any content,
          feature, or functionality that you believe is not fully accessible to
          people with disabilities, please email our Customer Service team at
          info@fanvestor.com with “Disabled Access” in the subject line and
          provide a description of the specific feature you feel is not fully
          accessible or a suggestion for improvement. We take your feedback
          seriously and will issue you a response within five business days. We
          consider all feedback as we evaluate ways to accommodate all of our
          customers and our overall accessibility policies. Additionally, while
          we do not control such vendors, we strongly encourage vendors of
          third-party digital content to provide content that is accessible and
          user friendly.
        </Text>

        <Text>
          <strong>20. Use of Cookies</strong>
        </Text>

        <Text>
          EdenLedger uses third party cookies to enhance the user experience of
          the FanVestor site and mobile application. Please refer to our{' '}
          <Link target="_blank" to={WEBSITE + LINKS.PRIVACY}>
            Privacy Policy
          </Link>{' '}
          for more information.
        </Text>

        <Text>
          <strong>21. Participation in Sweepstakes</strong>
        </Text>

        <Text>
          EdenLedger Inc is a registered Outside Solicitor in the State of
          California, and operates FanPerks sweepstakes for the benefit of
          registered recipient charitable organizations. You must be 18 or older
          and a resident of the State of California to participate in any
          FanPerks sweepstakes offer. No purchase is necessary to participate in
          a FanPerks sweepstakes, and there will be an alternative free means of
          entry posted on each relevant sweepstakes listing. The start and end
          date of each FanPerks sweepstake promotion will be posted on each
          relevant sweepstakes listing.
        </Text>

        <Text>
          <strong>22. Severability</strong>
        </Text>

        <Text>
          If any provision or part of a provision of these Terms is unlawful,
          void or unenforceable, that provision or part of the provision is
          deemed severable from these Terms and does not affect the validity and
          enforceability of any remaining provisions.
        </Text>

        <Text>
          <strong>23. Miscellaneous</strong>
        </Text>

        <Text>
          The failure of EdenLedger to exercise or enforce any right or
          provision of these Terms will not operate as a waiver of such right or
          provision. These Terms reflect the entire agreement between the
          parties relating to the subject matter hereof and supersede all prior
          agreements, representations, statements and understandings of the
          parties. The section titles in these Terms are for convenience only
          and have no legal or contractual effect. Use of the word “including”
          will be interpreted to mean “including without limitation.” Except as
          otherwise provided herein, these Terms are intended solely for the
          benefit of the parties and are not intended to confer third-party
          beneficiary rights upon any other person or entity. You agree that
          communications and transactions between us may be conducted
          electronically.
        </Text>

        <Text>
          <strong>
            24. Terms &amp; Conditions Pertaining to Products, Perks, Rewards
            &amp; Experiences
          </strong>
        </Text>

        <Text>
          <strong>Items You Purchase.</strong>&nbsp;You understand that
          FanVestor does not manufacture, store, or inspect any of the items
          sold through our Services. We provide the platform; the items in our
          marketplaces are produced, listed, and sold directly by independent
          sellers, so FanVestor cannot and does not make any warranties about
          their quality, safety, or even their legality. Any legal claim related
          to an item you purchase must be brought directly against the vendor of
          the item. You release FanVestor from any claims related to items sold
          through our Services, including for defective items,
          misrepresentations by vendors, or items that caused physical injury
          (like product liability claims).
        </Text>

        <Text>
          <strong>Content You Access.</strong>&nbsp;You may come across
          materials that you find offensive or inappropriate while using our
          Platform. We make no representations concerning any content posted by
          users through the Platform. FanVestor is not responsible for the
          accuracy, copyright compliance, legality, or decency of content posted
          by users that you accessed through the Platform. You release us from
          all liability relating to that content.
        </Text>

        <Text>
          <strong>People You Interact With.</strong>&nbsp;You can use the
          Services to interact with other individuals, either online or in
          person. However, you understand that we do not screen users of our
          Platform, and you release us from all liability relating to your
          interactions with other users. Please be careful and exercise caution
          and good judgment in all interactions with others, especially if you
          are meeting someone in person.&nbsp;
        </Text>

        <Text>
          <strong>Perks, Rewards, Experiences and Promotions.</strong>&nbsp;You
          acknowledge that FanVestor does not make any warranties with respect
          to your eligibility for perks, rewards, experiences, or promotions
          from individual vendors and is not responsible for any experience,
          reward, perk, promotion or availability thereof offered on our
          Platform. By participating in a special offer or promotion, you agree
          that you may not later claim that the rules of that special offer or
          promotion were ambiguous. The redemption of each perk, reward,
          experience or promotion will be detailed in the offering page,
          including limitation on the redemption thereof, or the substitution of
          a prize of equal or greater value in the sole discretion of the
          vendor. Each experience is offered at the sole discretion of the
          vendor and said vendor may specify a timeframe or alternative prize
          should the experience be impossible, impractical, or unsafe to
          fulfill. You waive any and all claims against FanVestor pertaining to
          the availability or quality of any perk, reward, experience or
          promotion, and expressly agree that your claims are entirely against
          the individual vendor.
        </Text>
      </Terms>
    </Modal>
  )
}

export default InvestTermsModal
