import axios from 'axios'
import { IUser } from 'redux/auth/types'
import { IPagination, ISocial, IStatus, IVideo } from 'redux/commonTypes'
import { IInfluencer, IInfluencerData } from 'redux/influencer/types'

export const InfluencerApi = {
  getInfluencer: async (id: string, isProfile: boolean | undefined) => {
    const url = isProfile ? '/influencer-profile/settings' : `/influencer/${id}`
    return await axios.get<GetInfluencerResponse>(url)
  },

  updateInfluencer: async (data: UpdateInfluencerRequest) =>
    await axios.patch<UpdateInfluencerResponse>(
      '/influencer-profile/settings',
      data
    ),

  searchInfluencers: async (obj: SearchInfluencersRequest) => {
    const { name, params } = obj
    const url = name ? `/search/${name}` : '/search'

    return await axios.get<SearchInfluencersResponse>(url, {
      params
    })
  },

  uploadYoutubeVideo: async (url: string) =>
    await axios.post<UploadYoutubeVideoResponse>(
      '/influencer/youtube-upload',
      url
    ),

  deleteYoutubeVideo: async (params: DeleteYoutubeVideoRequest) =>
    await axios.delete<DeleteYoutubeVideoResponse>('/influencer/video-delete', {
      params
    }),

  followInfluencer: async (influencerId: number) =>
    await axios.put<IStatus>('/profile/following', {
      user_id: influencerId
    }),

  unfollowInfluencer: async (influencerId: number) =>
    await axios.delete<IStatus>('/profile/following', {
      params: { user_id: influencerId }
    })
}

interface GetInfluencerResponse {
  data: IInfluencer
}

interface UpdateInfluencerResponse {
  status: boolean
}

interface UploadYoutubeVideoResponse {
  data: IVideo
}
interface UpdateInfluencerRequest {
  first_name: string
  last_name: string
  email?: string
  social: { [k: string]: ISocial }
  influencer_data: IInfluencerData
}

interface DeleteYoutubeVideoResponse {
  video_id: number
}

interface DeleteYoutubeVideoRequest {
  video_id: number
  storage: number
}

interface SearchInfluencersResponse {
  data: IUser[]
  pagination: IPagination
}
interface SearchInfluencersRequest {
  params: { page?: number | string }
  name?: string
}
