import React from 'react'

const ClockIcon = style => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
  >
    <path
      fill="#fff"
      {...style}
      d="M15 7.5c0 4.136-3.365 7.5-7.5 7.5C3.364 15 0 11.635 0 7.5 0 3.364 3.365 0 7.5 0 11.636 0 15 3.365 15 7.5zm-1.596 0A5.91 5.91 0 007.5 1.596 5.91 5.91 0 001.596 7.5 5.91 5.91 0 007.5 13.404 5.91 5.91 0 0013.404 7.5z"
    />
    <path
      fill="#fff"
      {...style}
      d="M11.406 7.27h-3.37V3.218a.618.618 0 00-1.236 0v4.67c0 .34.277.617.618.617h3.988a.618.618 0 000-1.235z"
    />
  </svg>
)

export default ClockIcon
