import React, { Component } from 'react'
import { RouteChildrenProps } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  register,
  verify,
  resend,
  verifyBetablock
} from 'redux/auth/operations'
import { IRegisterPayload } from 'redux/auth/types'
import { clearAuthErrors } from 'redux/auth/actions'
import {
  getError,
  selectAuthErrors,
  selectIsFetchingLoggedIn,
  selectIsRegistered
} from 'redux/auth/selectors'
import { AppState, Dispatch } from 'store/types'
import AuthWrapper from 'components/content/AuthWrapper'
import SignUpForm from 'components/auth/SignUpForm'
import EnterCodeForm from 'components/auth/EnterCodeForm'
import AuthForm from 'components/auth/AuthForm'
import { routePath } from 'routes'

class SignUpPage extends Component<Props> {
  state = { isActive: true, isClicked: false }

  componentWillUnmount(): void {
    this.props.clearAuthErrors()
  }

  nextStep = () => this.setState({ isActive: false })

  onRegister = async (userData: IRegisterPayload) => {
    const { register } = this.props

    try {
      this.setState({ isClicked: true })
      await register(userData)
      this.nextStep()
    } catch (err) {
      this.setState({ isClicked: false })
      console.error('register fail')
    }
  }
  render() {
    const {
      verify,
      registerErrors,
      clearAuthErrors,
      resend,
      isFetchingVerify,
      location,
      verifyBetablock
    } = this.props
    const isBetaBlock = location.pathname === routePath.SIGN_UP_BETABLOCK
    const { isActive, isClicked } = this.state

    return (
      <AuthWrapper>
        <AuthForm
          isActive={isActive}
          title={isActive ? 'Sign Up' : 'Enter Code'}
          content={
            isActive ? (
              <SignUpForm
                onSubmit={this.onRegister}
                clearErrors={clearAuthErrors}
                serverErrors={registerErrors}
                isClicked={isClicked}
              />
            ) : (
              <EnterCodeForm
                onSubmit={isBetaBlock ? verifyBetablock : verify}
                resend={resend}
                isFetching={isFetchingVerify}
                serverErrors={registerErrors}
                clearErrors={clearAuthErrors}
              />
            )
          }
        />
      </AuthWrapper>
    )
  }
}

// tslint:disable-next-line:no-empty-interface
interface ReceivedProps {}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps &
  DispatchProps &
  ReceivedProps &
  RouteChildrenProps<{}, { guestEmail: string }>

const mapStateToProps = (state: AppState) => ({
  registerErrors: getError(selectAuthErrors(state)),
  isRegistered: selectIsRegistered(state),
  isFetchingVerify: selectIsFetchingLoggedIn(state)
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { register, verify, clearAuthErrors, resend, verifyBetablock },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUpPage)
