import styled from 'styled-components'
import { media, Row } from 'styled-bootstrap-grid'

export const SectionWrapperAbsolute = styled(Row)`
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  padding: 2rem 1.5625rem;
  background: ${p => p.theme.colors.white};
  border-radius: 1rem;
  box-shadow: -1px 45px 100px rgba(21, 21, 18, 0.2);

  .manager-column {
    padding: 0;
  }

  .manager-column:not(:first-child) {
    margin-top: 2.1875rem;
  }

  ${media.md`
    padding: 3.75rem 3.4375rem;

    .manager-column {
      padding: 0 2.34375rem;
    }
    
    .manager-column:not(:first-child) {
      margin-top: 0;
    }
  
    .manager-column:first-child{
      padding-left: 0;
    }
    
    .manager-column:last-child{
      padding-right: 0;
    }

  `}
`

export const DescripUnderTitle = styled.p<{ color: string }>`
  width: 100%;
  font-size: 1.125rem;
  font-family: Inter;
  font-weight: 400;
  color: ${p => p.color};
  margin-bottom: 2.1875rem;
  line-height: 1.625rem;

  ${media.md`
    width: 70%;
    font-size: 1.375rem;
    margin-bottom: 3.4375rem;
    line-height: 2rem;
  `};
`

export const PhotoManager = styled.div`
  width: 80px;
  height: 80px;
  background-color: ${p => p.theme.colors.black};
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 0.875rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${media.md`
    width: 100px;
    height: 100px;
    margin-bottom: 0;
  `}
`

export const TeamMembers = styled(Row)`
  margin: 0;
`

export const DescriptionColumn = styled.div`
  /* Manager name */
  h4 span span {
    display: inline-block;
    color: ${p => p.theme.colors.black};
    font-family: Inter !important;
    font-size: 0.9375rem !important;
    font-weight: 900;
    overflow: hidden;
  }

  /* Position */
  .job {
    margin-bottom: 0.75rem;
    line-height: 1.8125rem;
  }

  .job span {
    font-family: Inter !important;
    font-size: 0.9375rem !important;
    font-weight: 300;
    color: ${p => p.theme.colors.black};
    opacity: 0.6;
  }

  /* Manager description */
  ul li div span span {
    font-family: Inter !important;
    font-size: 0.875rem !important;
    font-weight: 300;
    line-height: 1.25rem;
    color: ${p => p.theme.colors.black};
  }

  /* Commons */
  ul {
    padding-left: 1.25rem;
  }

  ul li {
    list-style: none !important;
    position: relative;
  }

  ul li:before {
    content: '—';
    top: 12px;
    position: absolute;
    left: -20px;
    line-height: 1px;
  }

  ul li:not(:first-child) {
    margin-top: 0.5rem;
  }

  ${media.md`
    /* Manager name */
    h4 span span {
      margin-top: 1.375rem;
      font-size: 1.125rem !important;
    }

    /* Position */
    .job span{
      font-size: 1.125rem !important;
    }

    /* Common */

    ul {
      padding-left: 0;
    }

    ul li div span span {
      font-size: 1rem !important;
    }

    ul li:before {
      left: -25px;
    }
  `}
`
