import React, { ChangeEvent, FC, useEffect } from 'react'
import ReactTooltip from 'react-tooltip'
import { useDispatch, useSelector } from 'react-redux'

import { UploadButton } from './VerificationForm/styles'
import {
  EntityType,
  IAddress,
  ICompany,
  IContact,
  IIRA,
  ITrust
} from 'redux/investment/types'
import { Controls } from './index'
import {
  FileCol,
  FileName,
  FileTitle,
  FileWrapper,
  UploadWrapper
} from './styles'
import {
  checkProhibitedCountry,
  getAddressErrors,
  getCompanyInfoErrors,
  getContactErrors,
  getEntityDocumentErrors,
  getIraErrors,
  getTrustErrors
} from 'redux/investment/operations'
import { setErrors, setInvestmentStep } from 'redux/investment/actions'

import {
  selectCurrentStep,
  selectEntityAddressInfo,
  selectInvestmentErrors
} from 'redux/investment/selectors'
import { AlertIcon } from 'src/components/icons'
import { Row } from 'styled-bootstrap-grid'
import Button, { ButtonVariant } from 'src/components/UI/Button'
import { TrashIcon } from 'components/icons/settings'

const UploadDocument: FC<{ [key: string]: any }> = props => {
  const dispatch = useDispatch()
  const {
    selectedEntity,
    selectedEntityInfo,
    selectedEntityDocument,
    setSelectedEntityDocument,
    selectedEntityContactInfo,
    isAlreadyInvested
  } = props
  const currentStep = useSelector(selectCurrentStep)
  const errors = useSelector(selectInvestmentErrors)
  const address = useSelector(selectEntityAddressInfo)

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  const trustTitle = isAlreadyInvested
    ? 'You previously provided us with the trust documents. However, if there was any ' +
      'material change, you can upload an updated document. You can upload the signature ' +
      'page that shows the trust name and your name'
    : 'Please provide us with the trust document. You can upload the signature ' +
      'page that shows the trust name and your name.'

  const entityTitle = isAlreadyInvested
    ? 'You previously provided us with the required documents for this entity. ' +
      'However, if there was any material change, you can upload the updated documents. ' +
      'We accept any one of there documents: certificate of formation, articles of formation, ' +
      'signed operating agreement, or company by-laws listing you as an associated person with this entity'
    : 'Please provide one of the following documents for this entity: ' +
      'certificate of formation, articles of formation, signed operating agreement, ' +
      'or company by-laws listing you as an associated person with this entity.'

  const title = selectedEntity === EntityType.TRUST ? trustTitle : entityTitle
  const representative =
    selectedEntity === EntityType.TRUST ? 'Custodian' : 'Contact'

  const handleUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const { target: { files = [] } = {} } = event

    const document = files ? files[0] : new File([], 'entity_document')

    setSelectedEntityDocument({
      ...selectedEntityDocument,
      entity_document: document
    })

    dispatch(setErrors({ ...errors, entity_document: '' }))
  }

  const deleteDocument = () => {
    setSelectedEntityDocument({
      ...selectedEntityDocument,
      entity_document: ''
    })
  }

  const splitName =
    selectedEntityDocument.entity_document &&
    selectedEntityDocument.entity_document.name.split('.')
  const filename = splitName && splitName[0]
  const fileExtension = splitName && '.' + splitName[1]

  const renderPassportPhotos = () => (
    <Row style={{ marginTop: 16, padding: '0 0.875rem' }}>
      <FileCol col={6}>
        <FileWrapper>
          <FileName>{filename}</FileName>
          <FileName style={{ flex: '1 0 auto', marginRight: 16 }}>
            {fileExtension}
          </FileName>
          <Button variant={ButtonVariant.Icon} onClick={deleteDocument}>
            <TrashIcon />
          </Button>
        </FileWrapper>
      </FileCol>
    </Row>
  )

  const checkRestrictedCountry = async (
    country: string,
    cityName: string,
    countryLabel: string,
    isEntity: boolean
  ) => {
    let data
    let countryError
    let field
    try {
      data = await dispatch(
        checkProhibitedCountry(
          { iso_code: country, city_name: cityName },
          isEntity
        )
      )
      // @ts-ignore
      const fieldName = data.type === 'city' ? 'state' : 'country'
      field = isEntity ? `entity_${fieldName}` : fieldName
    } catch (e) {
      console.error(e)
    } finally {
      countryError = {
        // @ts-ignore
        [field]: data.restricted
          ? `We are sorry, we cannot accept investments from ${
              // @ts-ignore
              data.type === 'country' ? countryLabel : cityName
            }`
          : ''
      }
    }
    return countryError
  }

  const onClick = async () => {
    const documentErrors = getEntityDocumentErrors(
      selectedEntityDocument,
      selectedEntity,
      isAlreadyInvested
    )
    const contactErrors = getContactErrors(
      selectedEntityContactInfo,
      representative
    )
    const updatedEntityContactErrors: object = Object.keys(
      contactErrors
    ).reduce(
      (acc, fieldName) => ({
        ...acc,
        [`entity_${fieldName}`]: contactErrors[fieldName as keyof IContact]
      }),
      {}
    )
    const addressErrors = getAddressErrors(address)
    const restrictedCountry = await checkRestrictedCountry(
      address.country,
      address.state_label || address.state,
      address.country_label,
      true
    )
    const updatedEntityAddressErrors: object = Object.keys(
      addressErrors
    ).reduce(
      (acc, fieldName) => ({
        ...acc,
        [`entity_${fieldName}`]: addressErrors[fieldName as keyof IAddress]
      }),
      {}
    )
    let entityErrors = {}
    switch (selectedEntity) {
      case EntityType.TRUST:
        entityErrors = getTrustErrors(selectedEntityInfo as ITrust)
        break
      case EntityType.IRA:
        entityErrors = getIraErrors(selectedEntityInfo as IIRA)
        break
      case EntityType.ENTITY:
        entityErrors = getCompanyInfoErrors(selectedEntityInfo as ICompany)
        break
      default:
        return null
    }

    dispatch(
      setErrors({
        ...(entityErrors as ITrust | IIRA | ICompany),
        ...restrictedCountry,
        ...updatedEntityContactErrors,
        ...updatedEntityAddressErrors,
        ...documentErrors
      })
    )
    const isValidContact = !Object.keys(contactErrors).length
    const isValidAddress =
      !Object.keys(addressErrors).length &&
      // @ts-ignore
      !restrictedCountry.entity_country &&
      // @ts-ignore
      !restrictedCountry.entity_state
    const isValidEntity = !Object.keys(entityErrors).length
    const isDocumentUploaded = !Object.keys(documentErrors).length

    if (
      isValidContact &&
      isValidEntity &&
      isValidAddress &&
      isDocumentUploaded
    ) {
      dispatch(setInvestmentStep(currentStep + 1))
    }
  }

  return (
    <>
      <UploadWrapper>
        <FileTitle>{title}</FileTitle>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <input
            name={'entity_document'}
            type={'file'}
            id={'file_1'}
            accept="image/png, image/jpeg, application/pdf"
            onChange={handleUpload}
          />
          <UploadButton htmlFor="file_1">Upload File</UploadButton>
          {errors.entity_document && (
            <div
              data-tip={errors.entity_document}
              data-for="error-tooltip__entity_document"
              style={{ cursor: 'pointer' }}
            >
              <AlertIcon style={{ display: 'block' }} />
            </div>
          )}
        </div>
      </UploadWrapper>
      {selectedEntityDocument && selectedEntityDocument.entity_document
        ? renderPassportPhotos()
        : null}
      <Controls onClick={onClick} />
      <ReactTooltip
        type={'error'}
        effect={'solid'}
        clickable={true}
        id="error-tooltip__entity_document"
        backgroundColor={'#FF3A44'}
      />
    </>
  )
}

export default React.memo(UploadDocument)
