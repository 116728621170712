import React from 'react'
import { Col, Row } from 'styled-bootstrap-grid'
import {
  Description,
  DescriptionDiv,
  DescriptionSectionContentWithPicture,
  DescriptionSectionPicture,
  DescriptionSectionPictureCol
} from '../styles'
import { EventSection, EventSectionItems } from 'redux/events/types'
import Media from 'react-media'

interface EventDescriptionProps {
  descriptionSections: EventSection[]
}

const EventDetailDescription: React.FC<EventDescriptionProps> = ({
  descriptionSections
}): React.ReactElement => (
  <>
    {descriptionSections.map(({ id, name, items }) => (
      <Row justifyContent={'center'} key={id}>
        <Col xs={12} col={9}>
          <Description>
            {name && <h2>{name}</h2>}
            {items.map((p: EventSectionItems, idx) => {
              const { title, picture, direction, description } = p
              return (
                <DescriptionDiv key={idx}>
                  {title && <h2>{title}</h2>}
                  {picture ? (
                    <Media
                      queries={{
                        extra: '(min-width: 1346px)'
                      }}
                    >
                      {matches => (
                        <DescriptionSectionContentWithPicture
                          reverse={direction}
                        >
                          <Col
                            xs={12}
                            sm={12}
                            md={7}
                            col={matches.extra ? 12 : 7}
                            lg={matches.extra ? 8 : 6}
                          >
                            <div
                              style={{ flex: 1 }}
                              dangerouslySetInnerHTML={{
                                __html: description || ''
                              }}
                            />
                          </Col>
                          {matches.extra ? (
                            <DescriptionSectionPictureCol
                              xs={12}
                              sm={12}
                              md={5}
                              lg={4}
                              col={6}
                              reverse={direction}
                            >
                              <DescriptionSectionPicture
                                size={direction}
                                src={picture}
                                alt={title || ''}
                              />
                            </DescriptionSectionPictureCol>
                          ) : (
                            <Col xs={12} sm={12} md={5} lg={6} col={5}>
                              <DescriptionSectionPicture
                                size={direction}
                                src={picture}
                                alt={title || ''}
                              />
                            </Col>
                          )}
                        </DescriptionSectionContentWithPicture>
                      )}
                    </Media>
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: description || ''
                      }}
                    />
                  )}
                </DescriptionDiv>
              )
            })}
          </Description>
        </Col>
      </Row>
    ))}
  </>
)

export default EventDetailDescription
