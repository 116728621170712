import { AppState } from 'store'

export const selectInfluencer = (state: AppState) => state.influencer

export const selectInfluencerUser = (state: AppState) =>
  selectInfluencer(state).user
export const selectInfluencerIsFetching = (state: AppState) =>
  selectInfluencer(state).isFetching
export const selectInfluencerErrors = (state: AppState) =>
  selectInfluencer(state).errors

export const selectInfluencerId = (state: AppState) =>
  selectInfluencerUser(state).data.id

export const selectUserInfo = (state: AppState) =>
  selectInfluencerUser(state).data
