import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export const OrderListWrapper = styled.div`
  background-color: ${p => p.theme.colors.darkenGold};
  width: 100%;
  cursor: default;
  box-shadow: 20px 20px 130px rgba(0, 0, 0, 0.5);
  margin-bottom: 1rem;

  ${media.lg`
    max-width: 504px;
    margin-bottom: 0;
  `}
`

export const OrderListBody = styled.div`
  max-height: 456px;
  overflow-y: auto;
  width: 100%;
  display: 'block'
  flex-direction: 'column'
  justify-content: center;
  align-items: center;
  scrollbar-color: #9d7d50 #d4ab73;
  scrollbar-width: thin;

  ${media.xs`
    // max-height: calc(100vh - 273px);
    max-height: calc(100vh - 48vh);
    height: 100%;
  `}
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .1);
    background-color: #D4AB73;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #b19062;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #9d7d50;
  }
`

export const OrderListDetails = styled.div<{
  total?: boolean
}>`
  display: flex;
  flex-direction: column;
  padding: ${p =>
    p.total ? '1.25rem 1.5625rem 1.375rem' : '1rem 1.5625rem 1.125rem'};
  border-top: ${p => (p.total ? '1px solid #000000' : '1px solid #9d7d50')};

  ${media.xs`
    padding: 6vw 3vw 3vw;
  `}
`

export const DetailsItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.625rem;

  :nth-last-child(1) {
    padding-bottom: 0;
  }

  h4 {
    font-family: 'Poppins';
    color: ${p => p.theme.colors.black};
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  h3 {
    font-family: 'Poppins';
    color: ${p => p.theme.colors.black};
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.875rem;
  }
`
export const Value = styled.div<{ bold?: boolean }>`
  font-family: 'Lato';
  font-weight: ${p => (p.bold ? 600 : 300)};
  font-size: 1.25rem;
  line-height: 1.875rem;
`
