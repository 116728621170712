import React, { RefObject, useEffect, useRef, useState } from 'react'
import { IEventDetail } from 'redux/events/types'
import { LogoIcon } from 'components/icons/index'

import NFTsHeaderContent from './NFTsHeaderContent'
import Loader from 'src/components/UI/Loader'
import PlayButtonIcon from 'src/components/icons/PlayButtonIcon'

import {
  ImgEvent,
  InvestCard,
  PlayButton,
  Player,
  PlayerWrapper,
  VideoPreview,
  FallbackImageWrapper
} from './styles'

interface Props {
  event: IEventDetail
  showHours?: boolean
}

export const fallbackImage = (
  <FallbackImageWrapper>
    <LogoIcon width={'50%'} height={'50%'} marginBottom={0} light={false} />
  </FallbackImageWrapper>
)

const NFTsHeader: React.FC<Props> = ({
  event,
  showHours
}): React.ReactElement => {
  const { event_id, event_picture, video_url } = event
  const [isPlaying, setIsPlaying] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const ref: RefObject<HTMLVideoElement> = useRef(null)

  useEffect(() => {
    document.addEventListener('scroll', handleScroll)
    document.addEventListener('click', handleClick)
    document.addEventListener('touchend', handleClick)

    return () => {
      document.removeEventListener('scroll', handleScroll)
      document.removeEventListener('click', handleClick)
      document.removeEventListener('touchend', handleClick)
    }
  })

  const handleScroll = () => {
    const playerRect = ref.current
      ? ref.current.getBoundingClientRect()
      : ({} as ClientRect)
    if (
      playerRect.top <= -playerRect.height ||
      playerRect.top >= window.innerHeight ||
      window.scrollY === 0
    ) {
      handleStop()
    }
  }

  const handleClick = (e: any) => {
    const playerRect = ref.current
      ? ref.current.getBoundingClientRect()
      : ({} as ClientRect)
    const playerRectY = playerRect.top
    const element = e.target.getBoundingClientRect()
    const clickY = element.top
    const clickX = element.left

    if ((playerRect && clickY !== playerRectY) || clickY === playerRectY) {
      handleStop()
    }
    if (
      playerRect &&
      !isPlaying &&
      !clickY &&
      !clickX &&
      e.target.getAttribute('aria-label') === `Play-${event_id}`
    ) {
      handlePlay()
    }
  }

  const handleCanPlay = () => {
    setIsLoading(false)
  }

  const handlePlay = () => {
    setIsPlaying(true)
    if (ref.current) {
      ref.current.play()
    }
  }
  const handleStop = () => {
    if (ref.current) {
      setIsPlaying(false)
      setIsLoading(true)
      ref.current.pause()
    }
  }

  const handleTap = () =>
    ref.current &&
    (ref.current.paused ? ref.current.play() : ref.current.pause())

  return (
    <InvestCard>
      {video_url ? (
        <>
          <PlayerWrapper id={'player-wrapper'}>
            {isPlaying && isLoading && <Loader />}
            <Player
              id={String(event_id)}
              src={video_url}
              isLoading={isLoading}
              preload="auto"
              onPlaying={handleCanPlay}
              ref={ref}
              onEnded={handleStop}
              onTouchEnd={handleTap}
              playsInline={true}
              controls={isPlaying} // must be isPlaying instead of true/false for preventing bug
            />
            {!isPlaying && <VideoPreview img={event_picture} />}
          </PlayerWrapper>
        </>
      ) : (
        <ImgEvent src={event_picture} fallback={fallbackImage} />
      )}
      <NFTsHeaderContent
        isPlaying={ref.current !== null && isPlaying}
        event={event}
        showHours={showHours}
      >
        {video_url && (
          <PlayButton aria-label={`Play-${event_id}`} onClick={handlePlay}>
            <PlayButtonIcon />
            Play Video
          </PlayButton>
        )}
      </NFTsHeaderContent>
    </InvestCard>
  )
}

export default NFTsHeader
