import styled, { css } from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import Button from 'src/components/UI/Button'

const fontStyles = css`
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5rem;
`

export const AchParagraph = styled.p`
  color: ${p => p.theme.colors.black};
  ul {
    padding-left: 35px;
  }
`

export const PaymentTextBlock = styled.div`
  width: 100%;
  padding-bottom: 1rem;
  border-radius: 0.75rem;
  display: flex;
  max-height: 80vh;
  margin-bottom: 20px;

  p {
    :not(:last-child) {
      margin-bottom: 1.5rem;
    }
    font-family: Inter, sans-serif;
    ${() => fontStyles}
  }

  ${media.md`
    margin-bottom: 2.875rem;
  `}
`

export const PaymentTextWrapper = styled(PaymentTextBlock)`
  padding: 1rem;
  background-color: ${p => p.theme.colors.lightBlue};
`

export const AchButton = styled(Button)`
  ${() => fontStyles}
`
