import DeviceDetector from 'device-detector-js'
import { v4 } from 'uuid'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { TokenStorage } from 'services/tokenService'
import { formatDateFromString, formatPrice } from 'utils/dateFormats'
import { SocialProviders, Statuses, StatusLabels } from 'redux/commonTypes'
import { IUserDevice } from 'redux/auth/types'
import { EntityType } from 'redux/investment/types'
import { ClientResult } from 'device-detector-js/dist/parsers/client'
import queryString from 'querystring'

export const DATE_PARAMS = ['birth_date']

export const MIN_PASSWORD_LENGTH = 8

const tester = /^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,64}$/
const youtubePattern = /youtu(?:.*\/v\/|.*v=|\.be\/)([A-Za-z0-9_-]{11})/

export const noSpaceRegex = /\s/g
export const noFirstSpaceRegex = /^ +/gm
export const noTwoSpacesRegex = /\s\s+/g
export const onlyNumbersRegex = /[^\d]/g
export const validNameRegex = /[^-'`\D]/g

export const isValidEmail = (email: string): boolean => {
  const valid = tester.test(email)
  if (!email || email.length > 254 || !valid) {
    return false
  }
  return true
}

export const isSpecialCharacter = (word: string): boolean => {
  const pattern = /[!@#$%^&*(),.?":{}|<>]/g

  return pattern.test(word)
}

export const isValidFullName = (name: string): boolean => {
  const pattern = /^[a-zA-Z][a-zA-Z.`'‘’-]+(?: [a-zA-Z.`'‘’-]+)+$/
  return pattern.test(name)
}

export const convertValueToPrice = (value: string) => {
  const cleanValue = value.replace(/[^0-9]/g, '')
  return formatPrice(Number(cleanValue))
}

export const convertValueToPriceWithDecimal = (value: number) => {
  const roundedPriceArr = String(value).split('.')
  return (
    convertValueToPrice(roundedPriceArr[0]) +
    `${roundedPriceArr[1] ? '.' + roundedPriceArr[1] : ''}`
  )
}
export const convertValueToPriceWithDecimalInput = (value: string) => {
  const roundedPriceArr = value.split('.')
  const withDot = value.split('').includes('.')
  if (withDot) {
    return (
      convertValueToPrice(roundedPriceArr[0]) +
      `${roundedPriceArr[1] ? '.' + roundedPriceArr[1] : '.'}`
    )
  } else {
    return convertValueToPrice(value)
  }
}

export const parsePhone = (phone: string) => {
  const p = phone && phone[0] !== '+' ? `+${phone}` : phone
  return parsePhoneNumberFromString(p)
}

export const isValidPhone = (phone: string) =>
  // @ts-ignore
  phone && parsePhone(phone).isValid()

export const convertValueToTaxId = (value: string) => {
  const cleanValue = value.replace(/[^0-9]/g, '')
  return cleanValue.replace(/(\d{2})(\d)/, '$1-$2')
}

export const convertValueToSSN = (value: string) => {
  const cleanValue = value.replace(/[^0-9]/g, '')
  return cleanValue.replace(/(\d{3})(\d{2})(\d)/, '$1-$2-$3')
}

export const hasNumbers = (text: string): boolean => /\d/.test(text)

export const isUrl = (url: any): boolean => /^https?:\/\//.test(url)

export const isYoutubeVideo = (url: string): boolean => youtubePattern.test(url)

export const isMailTo = (url: any): boolean => /^mailto:/.test(url)

export const getQueryParam = (url: string, param: string) => {
  const rx = new RegExp('[?&]' + param + '=([^&]+).*$')
  const returnVal = url.match(rx)
  return returnVal === null
    ? ''
    : decodeURIComponent(returnVal[1].replace(/\+/g, ' '))
}

export const checkSocialUrl = (url: string, provider: string): boolean => {
  // @ts-ignore
  const targetProvider: RegExp = {
    [SocialProviders.Facebook]: /http(s)?:\/\/(www\.)?(facebook|fb)\.com\//,
    [SocialProviders.Instagram]: /https?:\/\/(www\.)?instagram\.com\//,
    [SocialProviders.Twitter]: /http(s)?:\/\/(.*\.)?twitter\.com\//
  }[provider]

  return targetProvider.test(url)
}

export const getUserName = (link: any, provider: string) => {
  const FBpattern = /http(s)?:\/\/(www\.)?(facebook|fb)\.com\/([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?)/

  const IGpattern = /https?:\/\/(www\.)?instagram\.com\/([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?)/

  const TWpattern = /^(http(s)?:\/\/)?(www\.)?twitter\.com\/([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?)/

  let result

  switch (provider) {
    case SocialProviders.Facebook:
      result = link.match(FBpattern)
      if (result) {
        return result[4]
      }
      break
    case SocialProviders.Instagram:
      result = link.match(IGpattern)
      if (result) {
        return result[2]
      }
      break
    case SocialProviders.Twitter:
      result = link.match(TWpattern)
      if (result) {
        return result[4]
      }
  }

  return link
}

export const getYoutubeVideoId = (url: string) => {
  const matched = url.match(youtubePattern) || []

  return matched.length > 0 ? matched[1] : ''
}

export const formatDateParams = <T extends { [key: string]: any }>(
  obj: T
): T => {
  return Object.keys(obj).reduce((acc: any, key) => {
    if (obj[key] && Array.isArray(obj[key]) && obj[key].length === 0) {
      return acc
    }

    if (DATE_PARAMS.includes(key) && obj[key]) {
      return { ...acc, [key]: formatDateFromString(obj[key]) }
    }

    return { ...acc, [key]: obj[key] }
  }, {})
}

// @ts-ignore
export const reduceParams = (obj: { [key: string]: any }) => {
  return Object.keys(obj).reduce((acc, key) => {
    if (
      !obj[key] ||
      (obj[key] && Array.isArray(obj[key]) && obj[key].length === 0)
    ) {
      return acc
    }

    if (DATE_PARAMS.includes(key) && obj[key]) {
      return { ...acc, [key]: formatDateFromString(obj[key]) }
    }

    if (obj[key]) {
      return { ...acc, [key]: obj[key] }
    }

    return acc
  }, {})
}

export const formatSSN = (ssn: string) => {
  const cleanValue = ssn.replace(/[^0-9.]/g, '')
  let currentSSN
  currentSSN = cleanValue.replace(/^(\d{3})/, '$1-')
  currentSSN = currentSSN.replace(/-(\d{2})/, '-$1-')
  currentSSN = currentSSN.replace(/(\d)-(\d{4}).*/, '$1-$2')
  return currentSSN
}

export const maskSsn = (ssn: string) => {
  const cleanValue = ssn.replace(/[^0-9.]/g, '')
  const regex = /.(?=.{4,}$)/g
  const subst = '*'
  let val = cleanValue.replace(regex, subst)
  val = val.replace(/^(\*{3})/, '$1-')
  val = val.replace(/-(\*{2})/, '-$1-')
  val = val.replace(/(\*)-(\*{4}).*/, '$1-$2')
  return val
}

export const displayStatus = (statusNumber: Statuses): string =>
  statusNumber !== Statuses.LIVE ? StatusLabels[statusNumber] : ''

export function plural(forms: string[], n: number) {
  // @see http://docs.translatehouse.org/projects/localization-guide/en/latest/l10n/pluralforms.html
  const idx = n !== 1 ? 1 : 0
  return forms[idx] || ''
}

export const toDashCase = (str: string): string =>
  str.replace(/\s+/g, '-').toLowerCase()

export const useDeviceClient = (): ClientResult => {
  const deviceDetector = new DeviceDetector()
  const deviceObject = deviceDetector.parse(navigator.userAgent)

  return deviceObject.client
}

export const useDevice = (): IUserDevice => {
  const deviceDetector = new DeviceDetector()
  const deviceObject = deviceDetector.parse(navigator.userAgent)
  const deviceClient = deviceObject.client
  const deviceOS = deviceObject && deviceObject.os

  return {
    os: (deviceOS && deviceOS.name) || '',
    browser:
      `${deviceOS && deviceOS.name + ':'} ${deviceClient &&
        deviceClient.name}` || '',
    browserId: TokenStorage.getDevice() || TokenStorage.getFingerPrint() || v4()
  }
}

export const getBaseUrl = () => {
  const regex = /^(?:\/\/|[^/]+)*/i

  const [baseUrl] = regex.exec(String(process.env.REACT_APP_API_URL))

  return baseUrl
}

export const showEntityLabel = (entity: string) => {
  if (entity === EntityType.IRA) {
    return `Invest as self-directed ${entity}`
  }
  return `Invest as ${entity.toLowerCase()}`
}

export const showValidationError = (
  fieldName: string,
  correctValue: string,
  value?: string
) => {
  return `The ${fieldName} is ${
    !value ? 'empty' : 'not valid'
  }. Please enter a ${correctValue}.`
}

export const checkLuhnAlgorithm = (setValue: string | number) => {
  let ch = 0
  const num = String(setValue).replace(/\D/g, '')
  const isOdd = num.length % 2 !== 0

  if ('' === num) {
    return false
  }

  for (let i = 0; i < num.length; i++) {
    let n = parseInt(num[i], 10)

    // tslint:disable-next-line:no-conditional-assignment
    ch += (isOdd || 0) === i % 2 && 9 < (n *= 2) ? n - 9 : n
  }

  return 0 === ch % 10
}

export const parseBetablockSearchParams = (
  location: any,
  isBetaBlock: boolean
) => {
  const parsed = isBetaBlock ? queryString.parse(location.search) : {}
  const redirectURI = isBetaBlock
    ? parsed.redirect_uri || parsed['?redirect_uri']
    : ''

  return isBetaBlock
    ? {
        parsedSearchParams: queryString.parse(location.search),
        clientParams: {
          client_id: parsed.client_id,
          client_secret: parsed.client_secret
        },
        redirectURI
      }
    : {}
}
