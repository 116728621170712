import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

const AuthWrapper = styled.div`
  background: #12162a;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  padding-top: 74px;

  input {
    font-size: 0.9375rem !important;
    ${media.md`
      font-size: 1.125rem !important;
  `}
    &::placeholder {
      font-size: 0.9375rem;
      font-weight: 300;
      color: ${p => p.theme.colors.graphite};
      opacity: 0.9;
      ${media.md`
      font-size: 1.125rem;
  `}
    }
  }

  ${media.md`
  padding: 1.75rem;
  `}
`

export default AuthWrapper
