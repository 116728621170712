import React, { FC, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { useDispatch, useSelector } from 'react-redux'
import { InvestmentTerms, SecurityLabels } from 'redux/events/types'
import { InvestmentStatus } from 'redux/profile/types'
import {
  selectFetchingInvestments,
  selectInvestmentDetails,
  selectInvestmentErrors
} from 'redux/profile/selectors'
import { cancelInvestment } from 'redux/profile/operations'
import { DATE_WITH_TIMEZONE_FORMAT, formatPrice } from 'utils/dateFormats'
import Button from 'src/components/UI/Button'
import ErrorLabel from 'components/UI/ErrorLabel'

import {
  ButtonBlock,
  CardInfoWrapper,
  DateBlock,
  InvestmentList,
  MainWrapper,
  TextMessage
} from './styles'
import Modal from 'src/components/UI/Modal'

interface Props {
  eventEnd: number
  investmentTerms: InvestmentTerms
}

const ShareCard: FC<Props> = props => {
  const CANCEL_MESSAGE =
    'Canceling your investment will forfeit your shares and your investment in this campaign. This cannot be undone and should you decide to reinvest again in this campaign, FanVestor cannot guarantee that the investment will be available at a later time due to availability. FanVestor reserves the right to deny your future investments on the platform if we notice a regular pattern of investment cancellations. Are you sure you want to cancel the investment?'

  const { eventEnd, investmentTerms } = props

  const dispatch = useDispatch()
  const errors = useSelector(selectInvestmentErrors)
  const { message: errorMessage } = errors || {}
  const isFetching = useSelector(selectFetchingInvestments)
  const { data: investment } = useSelector(selectInvestmentDetails)
  const {
    status: investmentStatus,
    internal_status,
    id: investmentId,
    created_at,
    amount
  } = investment

  const [isShown, setIsShown] = useState<boolean>(false)
  const [isCancelled, setIsCancelled] = useState<boolean>(
    [
      InvestmentStatus.CANCELLED,
      InvestmentStatus.VOIDED,
      InvestmentStatus.OLD_CANCELLED
    ].includes(investmentStatus)
  )

  const daysToEventEnd = eventEnd <= 2 * 24
  const thisTimezone = moment.tz.guess()
  const date = moment
    .tz(created_at, thisTimezone)
    .format(DATE_WITH_TIMEZONE_FORMAT)

  useEffect(() => {
    setIsCancelled(
      [
        InvestmentStatus.CANCELLED,
        InvestmentStatus.VOIDED,
        InvestmentStatus.OLD_CANCELLED
      ].includes(investmentStatus)
    )
  }, [investmentStatus])

  const handleCancel = () => {
    try {
      dispatch(cancelInvestment(investmentId))
      hideModal()
    } catch (e) {}
  }

  const showModal = () => setIsShown(true)

  const hideModal = () => setIsShown(false)

  return (
    <MainWrapper>
      <Modal
        onClose={hideModal}
        isOpen={isShown}
        header={<div>Cancel investment</div>}
        footer={
          <ButtonBlock>
            <Button onClick={handleCancel}>OK</Button>
            <Button onClick={hideModal}>Cancel</Button>
          </ButtonBlock>
        }
      >
        {<TextMessage>{CANCEL_MESSAGE}</TextMessage>}
      </Modal>
      <DateBlock>
        <div style={{ marginBottom: '2rem' }}>
          <p>{date}</p>
          Date Invested
        </div>
      </DateBlock>
      <CardInfoWrapper>
        <InvestmentList>
          <li>
            {formatPrice(Number(amount))}
            <span>Total Investment</span>
          </li>
          {investmentTerms && investmentTerms.security_type && (
            <li>
              {SecurityLabels[investmentTerms.security_type]}
              <span>Security Type</span>
            </li>
          )}
          <li>
            {internal_status}
            <span>Status</span>
          </li>
        </InvestmentList>
        <Button
          variant={'default'}
          onClick={showModal}
          disabled={isCancelled || daysToEventEnd || isFetching}
        >
          {isCancelled ? 'Cancelled' : 'Cancel Investment'}
        </Button>
      </CardInfoWrapper>
      {errorMessage && <ErrorLabel label={errorMessage} />}
    </MainWrapper>
  )
}

export default ShareCard
