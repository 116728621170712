import { createSelector } from 'reselect'
import { AppState } from 'store'
import { CheckoutStepType, ICartItem, ICustomField } from './types'
import { ITier } from '../events/types'

export const selectCheckout = (state: AppState) => state.checkout

export const selectCheckoutProcessing = (state: AppState) =>
  selectCheckout(state).isPaymentProcessing
export const selectCheckoutFetching = (state: AppState) =>
  selectCheckout(state).isFetching
export const selectCartInfo = (state: AppState) =>
  selectCheckout(state).cartInfo
export const checkoutSteps = (state: AppState) =>
  selectCheckout(state).checkoutSteps
export const selectShipping = (state: AppState) =>
  selectCheckout(state).shipping
export const selectShippingErrors = (state: AppState) =>
  selectCheckout(state).errorsShipping
export const selectCurrentStep = (state: AppState) =>
  selectCheckout(state).currentStep
export const selectCountriesList = (state: AppState) =>
  selectCheckout(state).countries
export const selectCustomFields = (state: AppState) =>
  selectCheckout(state).customFields
export const selectSuggestAddresses = (state: AppState) =>
  selectCheckout(state).suggestAddresses
export const selectCheckoutLastActivity = (state: AppState) =>
  selectCheckout(state).lastActivity
export const selectCheckoutBraintreeToken = (state: AppState) =>
  selectCheckout(state).braintreeToken
export const selectCheckoutError = (state: AppState) =>
  selectCheckout(state).error

export const selectCart = (state: AppState) => selectCheckout(state).cart
export const selectCartItems = (state: AppState) => selectCart(state).items
export const selectCartItemsLength = (state: AppState) =>
  selectCartItems(state).length

export const selectBuyNowCart = (state: AppState) =>
  selectCheckout(state).buyNowCart
export const selectBuyNowTier = (state: AppState) =>
  selectBuyNowCart(state).tier
export const selectBuyNowTierQuantity = (state: AppState) =>
  selectBuyNowCart(state).quantity
export const selectBuyNowGuestCheckout = (state: AppState) =>
  selectBuyNowCart(state).guestCheckout

export const selectCartItemsCount = createSelector(
  selectCartItems,
  items => {
    const sets = items.reduce(
      (acc: any, item: ICartItem) => [...acc, ...item.sets],
      []
    )
    if (sets) {
      const quantityArr = sets.map((set: any) => {
        return set.quantity
      })

      return quantityArr.reduce(
        (total: number, amount: number) => total + amount,
        0
      )
    } else {
      return
    }
  }
)
export const selectCartItemsSubtotal = createSelector(
  selectCartItems,
  items => {
    return items.reduce((init, obj) => {
      if (obj.sets) {
        const quantityArr = obj.sets.map(set => {
          return set.quantity
        })
        const quantitySumm = quantityArr.reduce(
          (partialSum, a) => partialSum + a,
          0
        )
        const itemTotalPrice = obj.tier_price * quantitySumm
        return init + itemTotalPrice
      } else {
        const itemTotalPrice = obj.tier_price * obj.quantity
        return init + itemTotalPrice
      }
    }, 0)
  }
)
export const selectCartTiersQuantity = createSelector(
  selectCartItems,
  items => {
    const sets = items.reduce(
      (acc: any, item: ICartItem) => [...acc, ...item.sets],
      []
    )
    if (sets) {
      return sets.reduce((acc, set: any) => {
        return { ...acc, [set.tier_set_id]: set.left }
      }, {})
    } else {
      return
    }
  }
)

export const filterSteps = (
  steps: Array<{ [p: string]: any }>,
  tier: ITier | undefined
) => {
  if (tier && !tier.required_shipping_address) {
    steps = steps.filter(
      stepInfo => stepInfo.value !== CheckoutStepType.SHIPPING
    )
  }

  if (tier && (!tier.custom_fields || tier.custom_fields.length === 0)) {
    steps = steps.filter(
      stepInfo => stepInfo.value !== CheckoutStepType.PRODUCT_SPECIFIC
    )
  }

  return steps
}

export const selectVisibleSteps = createSelector(
  checkoutSteps,
  selectBuyNowTier,
  filterSteps
)

export const selectCountries = createSelector(
  selectCountriesList,
  countries => {
    return countries.map(country => ({
      value: country.id,
      label: country.country_name,
      code: country.code,
      states: country.available_states
    }))
  }
)

export const selectStates = createSelector(
  selectCountriesList,
  selectShipping,
  (countries, shipping) => {
    const currentCountry = countries.find(
      c => c.id === Number(shipping.country)
    )
    if (currentCountry && countries && shipping.country) {
      return currentCountry.available_states.map(country => ({
        value: country.id,
        label: country.state_name
      }))
    }

    return []
  }
)

export const selectAutocompleteAddresses = createSelector(
  selectSuggestAddresses,
  addresses => {
    return addresses && addresses.length > 0
      ? addresses.map(suggestion => {
          const {
            place_id,
            structured_formatting: { main_text, secondary_text }
          } = suggestion

          return {
            value: place_id,
            label: `${main_text}, ${secondary_text}`
          }
        })
      : []
  }
)

export const plainCustomFields = createSelector(
  selectCustomFields,
  customFields => {
    return Object.values(customFields).reduce(
      (acc: ICustomField[], item: ICustomField[]) => [
        ...Object.values(acc),
        ...Object.values(item)
      ],
      [] as ICustomField[]
    )
  }
)

export const selectStepInfo = createSelector(
  selectCurrentStep,
  selectVisibleSteps,
  (current, steps) => {
    return steps[current].value
  }
)

export const isValidProductSpecificSelector = createSelector(
  plainCustomFields,
  selectBuyNowTier,
  (customFields, activeTier) => {
    if (
      activeTier &&
      activeTier.custom_fields &&
      activeTier.custom_fields.length > 0
    ) {
      const disabledOptions = customFields.filter(
        field => field && !field.selected
      )
      return !!(disabledOptions.length > 0)
    }
    return false
  }
)
