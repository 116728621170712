import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export const Title = styled.h1`
  margin-bottom: 2.25rem;
  font-size: 2.875rem;
  font-weight: 900;
  line-height: 3.375rem;
`

export const SidebarWrapper = styled.div`
  display: none;
  max-width: 50%;
  text-align: left;

  // REMOVE AFTER REDESIGN
  h1,
  h2,
  span {
    font-family: Inter;
  }

  h2,
  span {
    text-transform: capitalize;
  }

  ${media.md`
    display: block;
  `}
`

export const SectionTitle = styled.h2`
  max-width: 7.75rem;
  margin-bottom: 0.75rem;
  font-size: 1rem;
  line-height: 1.25rem;
  opacity: 0.8;
`

export const SectionValue = styled(Title).attrs(() => ({ as: 'span' }))`
  margin-bottom: 2.5rem;
  display: block;
  font-size: 1.25rem;
  line-height: 1.25;
`

export const SectionSubtitle = styled.span`
  font-size: 1.25rem;
  font-weight: 800;
  line-height: 1.5rem;
`

export const Separator = styled.div`
  height: 1px;
  width: 1.625rem;
  margin-bottom: 2rem;
  background-color: ${p => p.theme.colors.white};
  opacity: 0.5;
`
