import React from 'react'

interface CardIconProps {
  color?: string
  rest?: React.SVGProps<SVGElement>
}
export const CardIcon: React.FC<CardIconProps> = ({
  color = '#fff',
  ...rest
}): React.ReactElement => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M22.5 6.25H7.5C5.42893 6.25 3.75 7.92893 3.75 10V20C3.75 22.0711 5.42893 23.75 7.5 23.75H22.5C24.5711 23.75 26.25 22.0711 26.25 20V10C26.25 7.92893 24.5711 6.25 22.5 6.25Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.75 12.5H26.25"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.75 18.75H8.7625"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.75 18.75H16.25"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
