import React, { Component, ReactNode } from 'react'
import Select, { components } from 'react-select'
import { Label } from 'components/UI/Label'
import OutsideClick from 'components/UI/OutsideClick'
import { CrossIcon, ChevronDownIcon } from 'components/icons'
import { debounce } from 'utils/debounce'
import selectStyles from './select.styles'
import {
  Wrapper,
  ArrowBlock,
  MainButton,
  Menu,
  StyledDropdown,
  StyledIndicator,
  LabelWrap,
  Placeholder,
  SelectedOption,
  IconWrapper
} from './styles'
import { SErrorLabel } from '../TextInput/styles'

export interface Option {
  label?: string
  value?: any
  code?: string
}

export enum dropdownSpecificity {
  Default = 'default',
  Checkout = 'checkout'
}

export interface DropdownProps {
  id?: string
  name: string
  options?: Option[]
  label?: string
  placeholder?: string
  hideControl?: boolean
  onInputChange?: (value: string) => void
  value?: any
  onSelect?: (name: string, value: any) => void
  forceUpdate: boolean
  isDisabled: boolean
  isReadOnly: boolean
  isAsync: boolean
  errorLabel: string
  icon?: ReactNode
  valid?: boolean
}

interface DropdownState {
  isOpen: boolean
  option: Option
  prevPropsOption: any
}

// @ts-ignore
const DropdownIndicator = props => (
  <StyledIndicator hidden={props.selectProps.inputValue}>
    <components.DropdownIndicator {...props}>
      <CrossIcon width={'16px'} heigh={'16px'} fill={'#e3e7ed'} />
    </components.DropdownIndicator>
  </StyledIndicator>
)

const Dropdown = ({ children, isOpen, target, onClose, isReadOnly }: any) => (
  <StyledDropdown>
    <OutsideClick onOutsideClick={onClose}>
      {target}
      {isOpen && !isReadOnly ? <Menu id="menu">{children}</Menu> : null}
    </OutsideClick>
  </StyledDropdown>
)

class DropdownSelect extends Component<DropdownProps, DropdownState> {
  public static defaultProps = {
    hideControl: false,
    forceUpdate: false,
    isDisabled: false,
    isReadOnly: false,
    isAsync: false,
    errorLabel: ''
  }

  static getDerivedStateFromProps(props: DropdownProps, state: DropdownState) {
    if (
      !state.prevPropsOption ||
      (props.value && props.value.value !== state.prevPropsOption.value)
    ) {
      return {
        prevPropsOption: props.value,
        option: props.value
      }
    }
    return null
  }

  constructor(props: DropdownProps) {
    super(props)

    this.state = {
      isOpen: false,
      option: props.value ? props.value : { label: '' },
      prevPropsOption: null
    }

    this.onInputChange = debounce(this.onInputChange.bind(this), 400)
  }

  componentDidMount(): void {
    const { forceUpdate, value } = this.props
    if (forceUpdate && value) {
      this.onSelectChange(value)
    }
  }

  componentDidUpdate(
    prevProps: Readonly<DropdownProps>,
    prevState: Readonly<DropdownState>
  ): void {
    const { forceUpdate, value } = this.props
    if (forceUpdate && prevProps.value.value !== this.props.value.value) {
      this.onSelectChange(value)
    }
  }

  // @ts-ignore
  filterOption = ({ label, value, data }, query: string): boolean =>
    this.props.isAsync ||
    !query ||
    (value !== 'all' &&
      !data.isDisabled &&
      label.toLowerCase().includes(query.toLowerCase()))

  toggleOpen = () => this.setState(state => ({ isOpen: !state.isOpen }))
  closeOpen = () => this.setState({ isOpen: false })

  onSelectChange = (option: Option) => {
    const { onSelect, name } = this.props
    this.closeOpen()

    this.setState({ option }, () => {
      if (onSelect) {
        // @ts-ignore
        onSelect(name, option)
      }
    })
  }

  // @ts-ignore
  onInputChange = (query: string, { action }) => {
    const { onInputChange: onChange } = this.props
    // Prevents resetting our input after option has been selected
    if (action !== 'set-value') {
      if (onChange) {
        onChange(query)
      }
      // this.setState({inputValue: query});
    }
  }

  render() {
    const {
      placeholder,
      options,
      label,
      name,
      id,
      hideControl,
      isDisabled,
      isReadOnly,
      errorLabel,
      icon
    } = this.props
    const { isOpen, option } = this.state
    const styles = selectStyles({ hideControl })

    return (
      <Wrapper isSelected={isOpen} data-testid={`${name}-dropdown`}>
        <LabelWrap>
          {!!label && (
            <div>
              <Label htmlFor={id}>{label}</Label>
            </div>
          )}
        </LabelWrap>
        <IconWrapper>{icon}</IconWrapper>
        <Dropdown
          isOpen={isOpen}
          isDisabled={isDisabled}
          isReadOnly={isReadOnly}
          onClose={this.closeOpen}
          target={
            // @ts-ignore
            <MainButton
              type="button"
              onClick={this.toggleOpen}
              isSelected={isOpen}
              isDisabled={isDisabled}
              isErrored={!!errorLabel}
              icon={icon}
            >
              {option && option.label ? (
                <SelectedOption>{option.label}</SelectedOption>
              ) : (
                <Placeholder>{placeholder}</Placeholder>
              )}
              <ArrowBlock>
                <ChevronDownIcon />
              </ArrowBlock>
            </MainButton>
          }
        >
          <Select
            autoFocus={true}
            components={{
              DropdownIndicator,
              IndicatorSeparator: null
            }}
            isClearable={false}
            menuIsOpen={true}
            openMenuOnClick={false}
            openOnClick={false}
            openAfterFocus={false}
            openOnFocus={false}
            closeMenuOnSelect={true}
            onSelectResetsInput={true}
            controlShouldRenderValue={false}
            backspaceRemovesValue={false}
            // @ts-ignore
            onChange={this.onSelectChange}
            onInputChange={this.onInputChange}
            options={options}
            placeholder={' '}
            styles={styles}
            tabSelectsValue={false}
            value={option}
            filterOption={this.filterOption}
          />
        </Dropdown>
        {errorLabel && <SErrorLabel label={errorLabel} />}
      </Wrapper>
    )
  }
}

export default DropdownSelect
