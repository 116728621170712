import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import Link from 'components/UI/Link'
import Button from 'components/UI/Button'

export const WrapperBlock = styled.div.attrs(() => ({
  'data-testid': 'tier-block'
}))`
  height: 100%;
  text-align: left;
  line-height: normal;
  position: relative;
  overflow: hidden;
  display: flex;
`

export const Card = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid black;
`

export const DescText = styled.p`
  margin-bottom: 1.5rem;
  font-weight: 300;
  color: #000000;
  font-size: 1.125rem;
`

export const TierButton = styled.div`
  a {
    display: inline-block;
    padding: 1.375rem 2.375rem;
  }
`
export const TierInfo = styled.div.attrs(() => ({
  'data-testid': 'tier-info-block'
}))`
  margin-right: 0.625rem;
`
export const TierTitle = styled.h3`
  font-size: 1.375rem;
  color: #000000;
  font-weight: 300;
  margin-bottom: 0.875rem;
  line-height: 3rem;

  ${media.md`
    font-size: 1.625rem;
  `}
  ${media.lg`
    font-size: 2.375rem;
  `}
`
export const StatusButton = styled(Button).attrs(() => ({
  disabled: true,
  'data-testid': 'status-button'
}))`
  padding: 0 0 1.375rem;
  flex: 1 0 auto;
  border-width: 1px;
  letter-spacing: 0.02625rem;
  background-color: transparent;
  cursor: auto;
  font-size: 2rem;
  font-weight: 500;
  color: ${p => p.theme.colors.darkGrey};

  :hover {
    background-color: transparent;
    color: ${p => p.theme.colors.darkGrey};
  }
`
export const CheckoutLink = styled(Link).attrs(p => ({
  'data-testid': 'checkout-button'
}))`
  font-size: 0.875rem;
`
