import styled from 'styled-components'

export const WrapperBlock = styled.div`
  margin-top: -6.25rem;

  .tabs .tab-nav {
    height: 100px;
  }
`
export const TextWrapper = styled.div`
  padding: 5.875rem 1rem;
  text-align: center;
`
export const Text = styled.p`
  margin: 0 auto 2rem;
  color: #8c8c8c;
  font-size: 1.25rem;
  letter-spacing: 0.8px;
  line-height: 2.5rem;
`
export const IconWrapper = styled.div`
  position: relative;
`
