import React, { FC } from 'react'
import { Container } from 'styled-bootstrap-grid'
import { selectIsAuth } from 'redux/auth/selectors'
import { useSelector } from 'react-redux'

import Apps from './Apps'
import Menu from './Menu'

import { Copyright, CopyrightWrapper, FooterWrap, Wrapper } from './styles'
import Footnote from './Footnote'

const Footer: FC = () => {
  const isAuthenticated = useSelector(selectIsAuth)
  return (
    <FooterWrap>
      <Container>
        <Apps />
        <Wrapper>
          <Menu isAuthenticated={isAuthenticated} />
          <CopyrightWrapper>
            <Copyright>© 2021 FanVestor. All rights reserved.</Copyright>
            <Copyright>
              All investments involve risks, including the possible loss of
              capital.
            </Copyright>
          </CopyrightWrapper>
        </Wrapper>
        <Footnote />
      </Container>
    </FooterWrap>
  )
}

export default Footer
