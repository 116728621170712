import React from 'react'

interface MinusIconProps {
  color: string
}
export const MinusIcon: React.FC<MinusIconProps> = ({
  color = '#161616'
}): React.ReactElement => {
  return (
    <svg
      width="10"
      height="2"
      viewBox="0 0 10 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.25 1H8.75"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
