import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export const WrapBlock = styled.div`
  .offers-container {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
    @media (min-width: 1346px) {
      max-width: 1465px;
    }
  }
`
export const Heading = styled.h2`
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 2.5rem;
  font-size: calc(2.16vw + 1rem);

  ${media.xl`
    font-size: 2.8rem;
  `}
`
export const Wrapper = styled.div`
  margin: 20px auto;

  p {
    color: ${p => p.theme.colors.fuchsia};
  }
`

export const InvestmentList = styled.section``
