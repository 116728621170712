import React from 'react'
import {
  LightLabel,
  DarkLabel,
  CheckBox,
  CheckBoxCheckout,
  CheckboxWrap,
  BlueCheckboxLabel,
  BlueCheckBox
} from './styles'

export enum LabelVariant {
  Light = 'light',
  Dark = 'dark',
  Blue = 'blue'
}

export enum CheckboxVariant {
  Light = 'light',
  Dark = 'dark',
  Blue = 'blue'
}

export interface CheckboxProps {
  label?: any
  name?: string
  value?: string
  checked?: boolean
  disabled?: boolean
  errorLabel?: string
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  variant?: 'light' | 'dark' | 'blue'
}
const Checkbox: React.FC<CheckboxProps> = ({
  label,
  name,
  value,
  checked,
  errorLabel,
  handleChange,
  disabled = false,
  variant = 'light'
}): React.ReactElement => {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (handleChange) {
      handleChange(event)
    }
  }

  const labels = {
    [LabelVariant.Light]: LightLabel,
    [LabelVariant.Dark]: DarkLabel,
    [LabelVariant.Blue]: BlueCheckboxLabel
  }

  const checkboxes = {
    [CheckboxVariant.Light]: CheckBox,
    [CheckboxVariant.Dark]: CheckBoxCheckout,
    [CheckboxVariant.Blue]: BlueCheckBox
  }

  const LabelComponent = labels[variant]
  const CheckboxComponent = checkboxes[variant]

  return (
    <CheckboxWrap>
      <LabelComponent>
        <CheckboxComponent
          name={name}
          value={value}
          checked={checked}
          disabled={disabled}
          errorLabel={errorLabel}
          onChange={onChange}
        />
        <span>{label}</span>
      </LabelComponent>
    </CheckboxWrap>
  )
}

export default Checkbox
