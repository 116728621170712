import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { routePath } from 'routes'
import { selectIsAuth, selectUserId } from 'redux/auth/selectors'
import { likeEvent } from 'redux/events/operations'

import {
  CommentIcon,
  HeartIcon,
  HeartIconLiked,
  SocialShareIcon
} from 'src/components/icons'
import DetailShareButton from 'components/common/DetailShareButton'
import Loader from 'src/components/UI/Loader'

import { IEventDetail } from 'redux/events/types'
import { Statuses } from 'redux/commonTypes'
import { generatePath, useHistory } from 'react-router-dom'
import { CommentButton, Counter, SocialButton, SocialWrapper } from './styles'

interface IProps {
  event: IEventDetail
  isShareHidden?: boolean
  path: string
}

const SocialButtons: FC<IProps> = ({ event, isShareHidden = false, path }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const fromProfile = history.location.pathname.split('/').includes('profile')
  const { likes_count, event_id, is_liked } = event
  const [likes, setLikes] = useState<number>(likes_count)
  const [isLiked, setIsLiked] = useState<boolean>(is_liked)

  const isAuthenticated = useSelector(selectIsAuth)
  const userId = useSelector(selectUserId)

  useEffect(() => {
    setLikes(likes_count)
  }, [likes_count])

  const handleLike = () => {
    if (!isAuthenticated) {
      history.push({
        pathname: routePath.SIGN_IN,
        state: {
          from: history.location.pathname
        }
      })
    }

    setLikes(isLiked ? likes - 1 : likes + 1)
    setIsLiked(!isLiked)

    dispatch(likeEvent(event_id))
  }

  if (!Object.keys(event).length) {
    return <Loader />
  }

  return (
    <SocialWrapper>
      <SocialButton
        variant={'clear'}
        onClick={handleLike}
        style={{ position: 'relative' }}
      >
        {isLiked ? <HeartIconLiked /> : <HeartIcon />}
        <Counter>{likes > 0 ? likes : ''}</Counter>
      </SocialButton>
      {!isShareHidden &&
        ![Statuses.ENDED, Statuses.STOPPED].includes(event.status) && (
          <DetailShareButton
            event={event}
            children={<SocialShareIcon />}
            userId={userId}
            isAuthenticated={isAuthenticated}
          />
        )}
      <CommentButton
        to={
          !fromProfile
            ? generatePath(path, {
                id: event_id
              }) + '#conversation'
            : history.location.pathname + '#conversation'
        }
      >
        <CommentIcon />
        <Counter>
          {event.comments_count > 0 ? event.comments_count : ''}{' '}
        </Counter>
      </CommentButton>
    </SocialWrapper>
  )
}

export default SocialButtons
