import React from 'react'
import ReactDOM from 'react-dom'
import 'utils/axiosConfig'
import App from './App'
// import TagManager from 'react-gtm-module'
//
// const tagManagerArgs = {
//   gtmId: String(process.env.REACT_APP_GTM_ID),
//   dataLayerName: 'PageDataLayer'
// }
// if (process.env.REACT_APP_GTM_ID) {
//   TagManager.initialize(tagManagerArgs)
// }

ReactDOM.render(<App />, document.getElementById('root'))
