import React, { FC, useEffect } from 'react'
import { RouteProps } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { clearInfluencerSearchField } from 'redux/events/actions'
import { searchInfluencers } from 'redux/events/operations'
import { Container } from 'styled-bootstrap-grid'
import { Dispatch } from 'store/types'
import { History } from 'history'

import InfluencerSearch from 'components/influencer/InfluencerSearch'
import CustomTemplate from 'components/content/CustomTemplate'
import EventPageHeader from 'components/common/EventPageHeader'
import { Wrapper } from './styles'

const InfluencerPage: FC<Props> = props => {
  const { history, clearInfluencerSearchField, searchInfluencers } = props
  const subtitle = 'The FanVestor Hall of Fame.'

  useEffect(() => {
    return () => {
      const { location: { pathname = '' } = {} } = history || {}
      const isSameRoute = pathname.includes('creators')
      if (!isSameRoute) {
        clearInfluencerSearchField()
        searchInfluencers('')
      }
    }
  }, [history, clearInfluencerSearchField, searchInfluencers])

  return (
    <CustomTemplate
      header={
        <EventPageHeader
          title={'Creators'}
          subtitle={subtitle}
          viewControl={false}
        />
      }
      inFlow={true}
    >
      <Wrapper>
        <Container className={'offers-container'}>
          <InfluencerSearch />
        </Container>
      </Wrapper>
    </CustomTemplate>
  )
}

interface ReceivedProps {
  isInfluencerProfile?: boolean
  history?: History
}

type DispatchProps = ReturnType<typeof mapDispatchToProps>
export type Props = DispatchProps & ReceivedProps & RouteProps

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      clearInfluencerSearchField,
      searchInfluencers
    },
    dispatch
  )

export default connect(
  null,
  mapDispatchToProps
)(InfluencerPage)
