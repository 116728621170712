import React, { useEffect } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { TokenStorage } from 'services/tokenService'
import { ProfileSettingTypes } from 'redux/profile/types'
import { clearAuthErrors } from 'redux/auth/actions'
import { selectUser } from 'redux/auth/selectors'
import { routePath } from 'routes'

import PopUpMessage from 'components/common/PopUpMessage'
import { NewTabs as Tabs } from 'components/UI/Tabs'
import Link from 'components/UI/Link'
import configTabs from './tabs'

import {
  SubMenuContentWrapper,
  Wrapper,
  SubMenuBlockWrapper,
  MainTitle,
  Email,
  Description,
  Title
} from './styles'

export const UserSettingsPage: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch()
  const user = useSelector(selectUser)
  const socialAccount = !!TokenStorage.getSocialToken()
  const { pathname } = useLocation()
  const path = pathname.split('/')
  const isHelpCenter = path.includes('help')
  const isContactSupport = path.includes('contact-support')
  const isFAQ = path.includes('faq')

  useEffect(() => {
    return () => {
      dispatch(clearAuthErrors())
    }
  }, [dispatch])

  const renderTitles = () => {
    if (path.includes('details')) {
      return 'User Details'
    }
    if (isHelpCenter) {
      return 'Help Center'
    }
  }

  const renderRoutes = (mainTabUrl: string, subTabs: any) => {
    const allTabs = Object.keys(subTabs)
    return (
      <Route strict={true} path={mainTabUrl} key={mainTabUrl}>
        <div>
          <Title>
            <MainTitle fullWidth={isHelpCenter}>
              {renderTitles()}
              {isContactSupport && (
                <Description>
                  Let us know how we can help. Someone from our team will
                  contact you as soon as possible.
                </Description>
              )}
            </MainTitle>
            {isContactSupport && (
              <Email>
                <p>Tech Support</p>
                <p>tech@fanvestor.com </p>
              </Email>
            )}
          </Title>
          <Tabs tabs={subTabs}>
            {allTabs.map(category => (
              <Link key={category} to={subTabs[category].url} />
            ))}
          </Tabs>
        </div>
        <SubMenuContentWrapper isFAQ={isFAQ}>
          <Switch>
            {allTabs.map((category: string) => (
              <Route key={category} exact={true} path={subTabs[category].url}>
                {subTabs[category].component}
              </Route>
            ))}
            <Redirect from={mainTabUrl} to={subTabs[allTabs[0]].url} />
          </Switch>
        </SubMenuContentWrapper>
      </Route>
    )
  }

  const tabs: any = configTabs(user, socialAccount)
  return (
    <div className={'wrapper'}>
      <div className={'content'}>
        <PopUpMessage />
        <Wrapper>
          <SubMenuBlockWrapper>
            <Switch>
              {Object.keys(tabs.main).map(category =>
                renderRoutes(
                  tabs.main[category].url,
                  tabs.main[category].resources
                )
              )}
              <Redirect
                from={routePath.PROFILE_SETTINGS}
                to={tabs.main[ProfileSettingTypes.DETAILS].url}
              />
            </Switch>
          </SubMenuBlockWrapper>
        </Wrapper>
      </div>
    </div>
  )
}
