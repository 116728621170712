export const cutText = (
  text: string,
  chars: number = 0,
  ellipsis: boolean = true
) => {
  let newText

  if (text.length > chars) {
    newText = text.slice(0, chars)
    if (!ellipsis) {
      return newText
    }

    newText = newText.trim() + '...'
    return newText
  }
  return text
}

export const getInitials = (word: string) => {
  const splitWord = word.split(' ')

  return splitWord.reduce(
    (acc, word, index, set) =>
      acc + (index === 0 || index === set.length - 1 ? word.slice(0, 1) : ''),
    ''
  )
}

export const stripHtml = (htmlString: string) => {
  const div = document.createElement('div')
  div.innerHTML = htmlString

  return div.textContent || div.innerText || ''
}
