import React from 'react'
import {
  NavLink as RouterLink,
  NavLinkProps as RouterLinkProps
} from 'react-router-dom'
import { Link, RouterLinkWithButtonProps } from 'components/UI/Link'
import { isUrl, isMailTo } from 'utils/helpers'

type NavLinkProps = RouterLinkWithButtonProps & RouterLinkProps

// tslint:disable-next-line:no-empty-interface
export interface LinkProps extends NavLinkProps {}

const NavLink: React.FC<LinkProps> = props => {
  const { to } = props

  const newProps = {
    as: isUrl(to) || isMailTo(to) ? 'a' : RouterLink,
    ...props
  }
  if (typeof to === 'string' && (isMailTo(to) || isUrl(to))) {
    newProps.href = to
    delete newProps.to
  }

  return <Link {...newProps} />
}

export default NavLink

export { NavLink }
