import React from 'react'
import Offers from 'components/invest/Offers'
import CustomTemplate from 'components/content/CustomTemplate'
import EventPageHeader from 'components/common/EventPageHeader'

const InvestPage = () => (
  <CustomTemplate
    header={
      <EventPageHeader
        title={'Investments & S-NFTs'}
        subtitle={'Discover and invest in unique businesses and opportunities.'}
      />
    }
    inFlow={false}
  >
    <Offers />
  </CustomTemplate>
)

export default InvestPage
