import React, { FC, useEffect, useState } from 'react'
import { IForgotPayload } from 'redux/auth/types'
import { IErrors } from 'redux/commonTypes'
import { isValidEmail } from 'utils/helpers'
import TextInput from 'src/components/UI/TextInput'
import Button from 'src/components/UI/Button'
import ErrorLabel from 'components/UI/ErrorLabel'
import { ErrorWrapper } from 'components/auth/SignInForm/styles'
import { Wrapper, Subtitle } from './styles'

interface IFormProps {
  onSubmit?: (email: string) => {}
  clearErrors: () => void
  serverErrors?: any
  isFetching?: boolean
}

const ForgotForm: FC<IFormProps> = ({
  serverErrors,
  onSubmit,
  clearErrors,
  isFetching
}) => {
  const [form, setForm] = useState<IForgotPayload>({
    email: ''
  })
  const [errors, setErrors] = useState<IErrors<IForgotPayload>>({} as IErrors<
    IForgotPayload
  >)

  useEffect(() => {
    if (serverErrors && Object.keys(serverErrors).length > 0) {
      clearErrors()
    }
    // eslint-disable-next-line
  }, [form, errors])

  const isValid = (): boolean => {
    const validationErrors = {} as { email: string; password: string }

    if (!isValidEmail(form.email)) {
      validationErrors.email = 'You must enter valid email'
    }

    if (!form.email) {
      validationErrors.email = 'You must enter an email'
    }

    setErrors(validationErrors)
    return !Object.keys(validationErrors).length
  }

  const onUpdate = (name: string, value: string) => {
    setForm({
      ...form,
      [name]: value
    })
    setErrors({
      ...errors,
      [name]: ''
    })
  }

  const handleSubmit = async () => {
    if (isValid() && onSubmit) {
      onSubmit(form.email)
    }
  }
  return (
    <Wrapper>
      <Subtitle>Please enter email to recover your password</Subtitle>
      <TextInput
        name={'email'}
        label={'Email'}
        errorLabel={errors.email || ''}
        value={form.email}
        isLabelHidden={!form.email}
        placeholder={'Email'}
        handleChange={onUpdate}
        wrapperClassName={'form__single-field'}
      />
      <ErrorWrapper>
        {serverErrors && <ErrorLabel label={serverErrors} />}
      </ErrorWrapper>
      <Button
        variant={'default'}
        type={'submit'}
        onClick={handleSubmit}
        disabled={!!(form.email === '' || errors.email) || isFetching}
        isLoading={isFetching}
      >
        Send
      </Button>
    </Wrapper>
  )
}

export default ForgotForm
