import React, { FC } from 'react'

import { BurgerWrapper, BurgerBar } from './styles'

const Burger: FC<{ handleClick: () => void; isClicked: boolean }> = ({
  handleClick,
  isClicked = false
}) => (
  <BurgerWrapper variant={'icon'} onClick={handleClick} isClicked={isClicked}>
    <BurgerBar />
    <BurgerBar />
    <BurgerBar />
  </BurgerWrapper>
)

export default Burger
