import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import ErrorLabelUI from 'components/UI/ErrorLabel'

export const CreditCard = styled.div`
  margin-bottom: 2rem;
  padding: 0 calc(10vw - 1rem);

  ${media.sm`
    padding: 0 2.5rem;
  `}

  ${media.md`
    max-width: calc(363px + 5rem);
    padding: 0 2.5rem;
  `}

  ${media.lg`
    max-width: 467px;
    position: absolute;
    top: -302px;
    left: -10px;
    max-width: calc(100% - 30px);
    width: 100%;
    padding: 0 0.9375rem 0 1.875rem;
  `}

  

  .input {
    background: transparent;
    border: none;
    outline: 0;
    color: #ffffff;
    padding: 0;
    margin: 0;
  }

  .input:-webkit-autofill {
    -webkit-text-fill-color: #fff;
    transition: background-color 100000000s;
    -webkit-animation: 1ms void-animation-out;
  }

  .StripeElement--webkit-autofill {
    background: transparent !important;
  }

  .input,
  button {
    -webkit-animation: 1ms void-animation-out;
  }
`

export const ErrorLabel = styled(ErrorLabelUI)`
  color: #787878;
  position: absolute;
`

export const Label = styled.label`
  padding: 0 0 12px 0;
`

export const FieldBlock = styled.div`
  margin-bottom: 1.5rem;
`
export const Field = styled.div<{ error?: boolean }>`
  display: block;
  position: relative;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid ${p => (p.error ? 'red' : '#666666')} !important;
  transition: border-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);

  :focus {
    border-color: ${p => (p.error ? '#D82C2C' : p.theme.colors.white)};
  }

  :disabled {
    border-color: #8c8c8c;
    color: #8c8c8c;
    font-size: 1rem;

    ${media.md`
    font-size: 1.25rem;
    `}

    ${media.lg`
    font-size: 1.5rem;
    `}
  }

  .input:not(.empty) {
    opacity: 1;
  }

  .braintree-hosted-field {
    position: absolute;
    border: 0;
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    color: #fff;
    background-color: transparent;
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    will-change: opacity;
    outline: none;
    padding-top: 12px;
    line-height: 1.8125rem;
    font-size: 1rem;
    font-family: 'Acumin Pro, Poppins, Roboto';

    ${media.md`
      font-size: 1.25rem;
    `}

    ${media.lg`
      font-size: 1.5rem;
    `}

    &:-webkit-autofill {
      -webkit-text-fill-color: #fff;
      transition: background-color 100000000s;
      -webkit-animation: 1ms void-animation-out;
    }

    &::placeholder {
      color: transparent;
      transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    &:not(.empty)::placeholder,
    &.braintree-hosted-field-focused::placeholder {
      color: #cfd7df;
    }

    &.braintree-hosted-field-focused,
    :not(.empty) {
      opacity: 1;
    }

    &.braintree-hosted-field-focused + label,
    :not(.empty) + label {
      transform: scale(0.85) translateY(-25px);
      cursor: default;
    }

    &.braintree-hosted-fields-invalid + label {
      //color: #e25950;
    }
  }

  label {
    position: absolute;
    width: 100%;
    left: 0;
    font-size: 1rem;
    font-family: 'Acumin Pro';
    line-height: 1.1875rem;
    font-weight: 400;
    bottom: 0;
    color: #999999;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transform-origin: 0 50%;
    cursor: text;
    pointer-events: none;
    transition-property: color, transform;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  }
`
