import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export const TimeLeft = styled.div`
  background: rgba(0, 0, 0, 0.4);
  border-radius: 0.5rem;
  width: fit-content;
  z-index: 2;
  padding: 3px 5px;
  ${media.md`
    padding: 6px 10px;
  `}
`
export const EventStatus = styled.span`
  color: ${p => p.theme.colors.white};
  font-family: Inter, sans-serif;
  font-size: 1.125rem;
  font-weight: 800;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  line-height: 1;

  ${media.md`
    max-width: 155px;
    font-size: 1.5rem;
    line-height: 1.8125rem;
  `}
`
export const EventDate = styled(EventStatus)`
  :first-of-type {
    font-size: 2.5rem;
    font-weight: 500;
    margin-right: 10px;
    ${media.md`
    font-size: 3.75rem;
    margin-right: 5px;
  `}
  }
`
export const Text = styled.div`
  display: flex;
  align-items: center;
`
