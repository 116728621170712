import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export const StepProgress = styled.div`
  margin: 3rem auto;
  display: flex;
`
export const Step = styled.div<{ active?: boolean }>`
  width: 2rem;
  height: 0.125rem;
  background: ${p => (p.active ? '#000' : '#c4c4c4')};
  margin-right: 0.75rem;
  
  &:last-child {
    margin-right: 0;
`
export const TextWrapper = styled.div`
  font-family: Inter;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 0.9375rem;
  font-weight: 300;
  text-align: center;
  color: ${p => p.theme.colors.white};

  max-width: 400px;
  margin: 0 auto;

  @media (min-width: 372px) {
    flex-direction: row;
  }

  ${media.md`
     font-size: 1.125rem;
  `}

  * {
    font-family: inherit !important;
    font-size: inherit !important;
    font-weight: inherit !important;
    color: inherit !important;
  }
  a {
    text-decoration: underline;
  }
`
export const Text = styled.p`
  margin-right: 0.25rem;
  font-weight: 400;
`
