import React from 'react'
import { Route, RouteProps, Switch, Redirect } from 'react-router-dom'
import SignInPage from 'containers/SignInPage'
import SignUpPage from 'containers/SignUpPage'
import ForgotPage from 'containers/ForgotPage'
import { routePath } from 'routes'
import { AppState } from 'store'
import { connect } from 'react-redux'
import CommonTemplate from 'components/content/CommonTemplate'

const AuthRoutes = (props: Props) => {
  const {
    history: {
      location: { state }
    },
    location
  } = props

  const historyPath = state && (state.from.pathname || state.from)
  const locationState = location && location.state
  const locationPath =
    // @ts-ignore
    locationState && (locationState.from.pathname || locationState.from)
  let redirectPath = historyPath || locationPath || routePath.START_PAGE

  if (
    (historyPath && historyPath.includes(routePath.PROFILE)) ||
    (locationPath && locationPath.includes(routePath.PROFILE))
  ) {
    redirectPath = routePath.START_PAGE
  }

  return props.isAuthenticated ? (
    <Redirect
      to={{
        pathname: redirectPath,
        // @ts-ignore
        state: { position: locationState && locationState.position }
      }}
    />
  ) : (
    <CommonTemplate>
      <Switch>
        <Route path={routePath.FORGOT} component={ForgotPage} />
        <Route path={routePath.SIGN_IN} component={SignInPage} />
        <Route path={routePath.SIGN_UP} component={SignUpPage} />
        <Redirect to={routePath.SIGN_IN} />
      </Switch>
    </CommonTemplate>
  )
}

// tslint:disable-next-line:no-empty-interface
interface ReceivedProps {
  history: any
}

type StateProps = ReturnType<typeof mapStateToProps>
export type Props = StateProps & ReceivedProps & RouteProps

const mapStateToProps = (state: AppState) => ({
  isAuthenticated:
    state.auth.isAuthenticated && !state.auth.isForceChangePassword
})

export default connect(mapStateToProps)(AuthRoutes)
