import { Currency } from './checkout/types'

export interface IPagination {
  total_results: number
  current_page: number
  per_page: number
  total_pages: number
  first_page_url: string
  last_page_url: string
  next_page_url: string
  prev_page_url: string
}

export interface ISocial {
  id: number
  link: string
  user_id: number
  provider_id: number
  created_at: string
  updated_at: string
}

export enum SocialProviders {
  Facebook = '1',
  Instagram = '2',
  Twitter = '3'
}

export enum VideoStorage {
  CLOUD = 1,
  YOUTUBE = 2
}

export interface IVideo {
  id: number
  preview: string
  title: string
  url: string
  video_storage: VideoStorage
}

export interface IStatus {
  status: boolean
}

export type IErrors<Type> = {
  [Property in keyof Type]?: string
}

export type Without<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>

export enum Statuses {
  LIVE = 1,
  COMING_SOON = 3,
  SUBMITTED = 4,
  SUSPENDED = 5,
  ENDED = 6,
  STOPPED = 7
}

export enum CancellationType {
  AFTER_PURCHASE_DAY = 1,
  BEFORE_THE_END_DATE_OF_THE_EVENT = 2,
  DISALLOW_CANCELLATION = 3
}

export const StatusLabels = {
  [Statuses.LIVE]: 'Live',
  [Statuses.COMING_SOON]: 'Coming soon',
  [Statuses.SUBMITTED]: 'Submitted',
  [Statuses.SUSPENDED]: 'Suspended',
  [Statuses.ENDED]: 'Ended',
  [Statuses.STOPPED]: 'Stopped'
}

export interface MetaAndLinks {
  links: { [k: string]: string | number | null }
  meta: Meta
}

export interface Meta {
  current_page: number
  last_page: number
  [k: string]: string | number | null
}

export const CurrencyLabels = {
  [Currency.USD]: 'USD',
  [Currency.CRYPTO]: 'USD'
}
