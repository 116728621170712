import { generatePath } from 'react-router-dom'
import { ProfileSectionTypes } from 'redux/profile/types'
import { routePath } from 'routes'

const tabs = () => ({
  [ProfileSectionTypes.NOTIFICATIONS]: {
    title: 'Notifications',
    url: generatePath(routePath.PROFILE_SECTION, {
      type: ProfileSectionTypes.NOTIFICATIONS
    })
  }
})

export default tabs
