import { createReducer } from 'typesafe-actions'
import {
  CheckoutActionTypes,
  CheckoutState,
  CheckoutAction,
  CheckoutStepType,
  BuyNowGuest
} from './types'
import { EnumHelpers } from 'services/EnumHelpers'
import { ProfileSectionTypes } from '../profile/types'
import { ITier } from 'redux/events/types'

export const initialState: CheckoutState = {
  isFetching: false,
  isPaymentProcessing: false,
  cartInfo: {
    shipping_charge: 0,
    subtotal: 0,
    tax_amount: 0,
    total: 0,
    isBlocked: false,
    isFetching: false
  },
  shipping: {
    country: '',
    state: '',
    city: '',
    zip: '',
    street: {},
    streetName: '',
    use_as_default: false,
    recipient_name: ''
  },
  currentStep: 0,
  checkoutSteps: EnumHelpers.toKeyValueArray(CheckoutStepType),
  customFields: {},
  countries: [],
  suggestAddresses: [],
  braintreeToken: '',
  errorsShipping: {
    country: '',
    state: '',
    city: '',
    zip: '',
    street: ''
  },
  error: '',
  lastActivity: ProfileSectionTypes.INVESTMENTS,
  cart: {
    items: []
  },
  buyNowCart: {
    tier: {} as ITier,
    quantity: 1,
    guestCheckout: {} as BuyNowGuest
  }
}

export const checkoutReducer = createReducer<CheckoutState, CheckoutAction>(
  initialState,
  {
    [CheckoutActionTypes.FETCH_CHECKOUT_INFO_REQUEST]: (state, action) => ({
      ...state,
      cartInfo: {
        ...state.cartInfo,
        isFetching: true,
        isBlocked: false
      }
    }),
    [CheckoutActionTypes.FETCH_CHECKOUT_INFO_SUCCESS]: (state, action) => ({
      ...state,
      cartInfo: {
        ...action.payload,
        isBlocked: false,
        isFetching: false
      }
    }),
    [CheckoutActionTypes.FETCH_CHECKOUT_INFO_FAIL]: (state, action) => ({
      ...state,
      cartInfo: {
        ...state.cartInfo,
        isBlocked: true,
        isFetching: false
      }
    }),
    [CheckoutActionTypes.BRAINTREE_TOKEN_REQUEST]: (state, action) => ({
      ...state,
      braintreeToken: ''
    }),
    [CheckoutActionTypes.BRAINTREE_TOKEN_SUCCESS]: (state, action) => ({
      ...state,
      braintreeToken: action.payload.token
    }),
    [CheckoutActionTypes.PAY_DONATE_REQUEST]: state => ({
      ...state,
      isFetching: true,
      error: initialState.error
    }),
    [CheckoutActionTypes.PAY_DONATE_SUCCESS]: state => ({
      ...state,
      isFetching: false,
      shipping: initialState.shipping,
      currentStep: initialState.currentStep,
      customFields: initialState.customFields,
      error: '',
      lastActivity: ProfileSectionTypes.EXPERIENCES
    }),
    [CheckoutActionTypes.PAY_DONATE_FAIL]: (state, action) => ({
      ...state,
      isPaymentProcessing: false,
      isFetching: false,
      // @ts-ignore
      error: action.payload.message || action.payload.reason
    }),
    [CheckoutActionTypes.CLEAR_ERRORS]: state => ({
      ...state,
      error: initialState.error,
      isFetching: false
    }),
    [CheckoutActionTypes.CHANGE_SHIPPING]: (state, action) => {
      let errorsShipping = {
        ...state.errorsShipping
      }

      if (
        action.payload.field &&
        errorsShipping &&
        // @ts-ignore
        errorsShipping[action.payload.field]
      ) {
        errorsShipping = {
          ...state.errorsShipping
        }
        // @ts-ignore
        delete errorsShipping[action.payload.field]
      }

      return {
        ...state,
        shipping: {
          ...state.shipping,
          state: !(action.payload.field === 'country')
            ? state.shipping.state
            : initialState.shipping.state,
          [action.payload.field]: action.payload.value
        },
        errorsShipping
      }
    },
    [CheckoutActionTypes.SET_CHECKOUT_STEP]: (state, action) => ({
      ...state,
      currentStep: action.payload.step
    }),
    [CheckoutActionTypes.SET_CUSTOM_FIELD]: (state, action) => ({
      ...state,
      customFields: action.payload.fields
    }),
    [CheckoutActionTypes.INITIAL_CHECKOUT]: state => ({
      ...initialState,
      lastActivity: state.lastActivity,
      cart: state.cart
    }),
    [CheckoutActionTypes.FETCH_COUNTRIES_SUCCESS]: (state, action) => ({
      ...state,
      countries: action.payload.shipping_countries
    }),
    [CheckoutActionTypes.FETCH_ADDRESS_SUCCESS]: (state, action) => ({
      ...state,
      suggestAddresses: action.payload
    }),
    [CheckoutActionTypes.SET_SHIPPING_ERRORS]: (state, action) => ({
      ...state,
      errorsShipping: action.payload.fields
    }),
    [CheckoutActionTypes.SET_SHIPPING_ADDRESS_FROM_PROFILE]: (
      state,
      action
    ) => ({
      ...state,
      shipping: {
        ...state.shipping,
        ...action.payload.fields,
        use_as_default: false
      }
    }),
    [CheckoutActionTypes.SET_LAST_ACTIVITY]: (state, action) => ({
      ...state,
      lastActivity: action.payload
    }),
    [CheckoutActionTypes.SHOPPING_CART_INITIALIZED]: (state, action) => ({
      ...state,
      cart: action.payload
    }),
    [CheckoutActionTypes.FETCH_SHOPPING_CART_REQUEST]: state => ({
      ...state,
      isFetching: true
    }),
    [CheckoutActionTypes.FETCH_SHOPPING_CART_SUCCESS]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        cart: {
          items: action.payload
        }
      }
    },
    [CheckoutActionTypes.FETCH_SHOPPING_CART_FAIL]: state => ({
      ...state,
      isFetching: false
    }),
    [CheckoutActionTypes.ADD_ITEM_TO_CART_REQUEST]: state => ({
      ...state,
      isFetching: true
    }),
    [CheckoutActionTypes.ADD_ITEM_TO_CART_SUCCESS]: (state, action) => {
      const [tier] = state.cart.items.filter(
        item => item.tier_id === action.payload.tier_id
      )
      const isItemAlreadyExists = Boolean(tier)
      let updatedTiersSets = action.payload.sets
      if (tier && tier.sets) {
        const isTierAlreadyExists = Boolean(
          tier.sets.filter(set => set.tier_set_id === action.payload.id).length
        )

        const changeExistedTiers = tier.sets.map(tierSet => {
          if (tierSet.tier_set_id === action.payload.id) {
            return {
              ...tierSet,
              ...action.payload.sets[0]
            }
          }
          return tierSet
        })

        updatedTiersSets = isTierAlreadyExists
          ? changeExistedTiers
          : [...tier.sets, ...action.payload.sets]
      }

      const updatedItems = isItemAlreadyExists
        ? state.cart.items.map(item =>
            item.tier_id === action.payload.tier_id
              ? { ...item, sets: updatedTiersSets }
              : item
          )
        : [...state.cart.items, action.payload]

      return {
        ...state,
        isFetching: false,
        cart: {
          ...state.cart,
          items: updatedItems
        }
      }
    },
    [CheckoutActionTypes.ADD_ITEM_TO_CART_FAIL]: state => ({
      ...state,
      isFetching: false
    }),
    [CheckoutActionTypes.DELETE_ITEM_FROM_CART_REQUEST]: state => ({
      ...state,
      isFetching: true
    }),
    [CheckoutActionTypes.DELETE_ITEM_FROM_CART_SUCCESS]: (state, action) => {
      const filteredItems = state.cart.items.filter(item => {
        // @ts-ignore
        item.sets = item.sets.filter(
          (set: any) => set.shopping_cart_id !== action.payload.itemId
        )
        return item
      })
      return {
        ...state,
        isFetching: false,
        cart: {
          ...state.cart,
          items: filteredItems
        }
      }
    },
    [CheckoutActionTypes.DELETE_ITEM_FROM_CART_FAIL]: state => ({
      ...state,
      isFetching: false
    }),
    [CheckoutActionTypes.INCREASE_CART_ITEM_COUNT_SUCCESS]: (state, action) => {
      const updatedItems = state.cart.items.map(item => {
        item.sets = item.sets.map(set => {
          if (set.tier_set_id === action.payload.tierSet.tier_set_id) {
            return { ...set, ...action.payload.tierSet }
          } else {
            return set
          }
        })
        return item
      })
      return {
        ...state,
        cart: {
          ...state.cart,
          items: updatedItems
        }
      }
    },
    [CheckoutActionTypes.DECREASE_CART_ITEM_COUNT_SUCCESS]: (state, action) => {
      const updatedItems = state.cart.items.map(item => {
        item.sets = item.sets.map(set => {
          if (set.tier_set_id === action.payload.tierSet.tier_set_id) {
            return { ...set, ...action.payload.tierSet }
          } else {
            return set
          }
        })
        return item
      })
      return {
        ...state,
        cart: {
          ...state.cart,
          items: updatedItems
        }
      }
    },
    [CheckoutActionTypes.INCREASE_BUYNOW_ITEM_QUANTITY]: state => ({
      ...state,
      buyNowCart: {
        ...state.buyNowCart,
        quantity: state.buyNowCart.quantity + 1
      }
    }),
    [CheckoutActionTypes.DECREASE_BUYNOW_ITEM_QUANTITY]: state => ({
      ...state,
      buyNowCart: {
        ...state.buyNowCart,
        quantity:
          state.buyNowCart.quantity < 2 ? 1 : state.buyNowCart.quantity - 1
      }
    }),
    [CheckoutActionTypes.DELETE_BUYNOW_ITEM_QUANTITY]: state => ({
      ...state,
      isFetching: true,
      buyNowCart: {
        ...state.buyNowCart,
        quantity: initialState.buyNowCart.quantity
      }
    }),
    [CheckoutActionTypes.CHANGE_BUYNOW_ITEM_QUANTITY]: (state, action) => ({
      ...state,
      isFetching: true,
      buyNowCart: {
        ...state.buyNowCart,
        quantity: action.payload
      }
    }),
    [CheckoutActionTypes.SET_GUEST_BUYNOW]: (state, action) => ({
      ...state,
      isFetching: true,
      buyNowCart: {
        ...state.buyNowCart,
        guestCheckout: action.payload
      }
    }),
    [CheckoutActionTypes.RESET_GUEST_BUYNOW]: state => ({
      ...state,
      isFetching: true,
      buyNowCart: {
        ...state.buyNowCart,
        guestCheckout: initialState.buyNowCart.guestCheckout
      }
    }),
    [CheckoutActionTypes.FETCH_TIER_REQUEST]: state => ({
      ...state,
      isFetching: true
    }),
    [CheckoutActionTypes.FETCH_TIER_SUCCESS]: (state, action) => ({
      ...state,
      isFetching: false,
      buyNowCart: {
        ...state.buyNowCart,
        tier: action.payload.data
      }
    }),
    [CheckoutActionTypes.FETCH_TIER_FAIL]: state => ({
      ...state,
      isFetching: false
    }),
    [CheckoutActionTypes.START_PAYMENT_PROCESSING]: state => ({
      ...state,
      isPaymentProcessing: true
    }),
    [CheckoutActionTypes.FINISH_PAYMENT_PROCESSING]: state => ({
      ...state,
      isPaymentProcessing: false
    })
  }
)
