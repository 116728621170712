import React, { FC, ReactNode, useRef, useState } from 'react'
import Modal from 'src/components/UI/Modal'
import { FacebookIcon, TwitterIcon } from 'components/icons'
import { SocialButton } from '../styles'
import {
  ButtonWrapper,
  CopyButton,
  FacebookButton,
  Input,
  InputWrapper,
  ModalTitle,
  socialButtonStyle,
  TwitterButton
} from './styles'

interface Props {
  url: string
  children?: ReactNode
  onClick?: () => void
}

const SocialShareButton: FC<Props> = ({ url, onClick, children }) => {
  const [isShown, setIsShown] = useState<boolean>(false)

  const ref = useRef<any>(null)

  const showModal = (): void => {
    if (onClick) {
      onClick()
    }
    setIsShown(true)
  }

  const hideModal = (): void => setIsShown(false)

  const copyText = (event: any) => {
    const { target } = event
    target.focus()
    ref.current.select()
    window.navigator.clipboard.writeText(url)
  }
  const renderHeader = () => <ModalTitle>Share</ModalTitle>

  return (
    <>
      <Modal isOpen={isShown} onClose={hideModal} header={renderHeader()}>
        <div style={{ maxWidth: 568 }}>
          <ButtonWrapper>
            <FacebookButton url={url} translate={''} style={socialButtonStyle}>
              <FacebookIcon />
              Facebook
            </FacebookButton>
            <TwitterButton url={url} translate={''} style={socialButtonStyle}>
              <TwitterIcon />
              Twitter
            </TwitterButton>
          </ButtonWrapper>
          <InputWrapper>
            <CopyButton variant={'default'} onClick={copyText}>
              Copy Link
            </CopyButton>
            <Input readOnly={true} value={url} ref={ref} />
          </InputWrapper>
        </div>
      </Modal>
      <SocialButton variant={'clear'} onClick={showModal}>
        {children}
      </SocialButton>
    </>
  )
}

export default SocialShareButton
