import React from 'react'
import { TextLink } from 'components/profile/settings/Policies/styles'
import { EMAILS } from 'utils/constants'

const sections = [
  {
    title: 'About FanVestor',
    subsections: [
      {
        question: 'What is FanVestor?',
        answer:
          'FanVestor is an innovative new ecosystem that allows fans to learn about projects and businesses related to talent brands and celebrities.'
      },
      {
        question: 'Who can use FanVestor?',
        answer:
          'Anyone over the age of 18 can become a member of FanVestor. Certain sweepstakes offerings are only available to residents of selected states, which will be noted in the listing for each offering.'
      },
      {
        question: 'Who are fans and followers in the FanVestor community?',
        answer:
          'Fans and followers are FanVestor members who can participate in exclusive FanVestor Experiences & Rewards offered by their favorite celebrities, musicians or athletes or entertainment/sports companies.'
      },
      {
        question: 'What are FanVestor’s Exclusive Experiences & Rewards?',
        answer:
          'FanVestor’s Exclusive Experiences & Rewards can be obtained by fans through monetary support. Typically, these are one-of-a-kind experiences or items.'
      },
      {
        question:
          'Why do Celebrities or Athletes or Companies create Fanvestor Offering?',
        answer:
          'Celebrities, athletes or companies create Fanvestor Offerings to raise funds for personal and charitable causes and to create deeper connections with their fan base through FanVestor’s community.'
      },
      {
        question:
          'Why do fans and followers support FanVestor’s Experiences & Rewards?',
        answer:
          'Fans engage with FanVestor’s Experiences & Rewards to become part of the world of celebrities, athletes and entertainment brands. Projects listed on the FanVestor platform are often exclusive and limited in nature.'
      },
      {
        question: 'What do fans receive as members of the FanVestor community?',
        answer:
          'Fans who purchase a FanVestor experience or reward receive exclusive access to the world of celebrities, athletes or entertainment brands, creating a deeper connection through engaging in FanVestor’s ecosystem.'
      },
      {
        question: 'What does FanVestor do to protect its community?',
        answer:
          'Each project is vetted before being listed on FanVestor to ensure all commercial and business obligations are compliant with our policies. We have a dedicated Trust & Safety team that monitors the system for suspicious activity and tracks fulfillment records as comprehensively as possible to help ensure accountability.'
      },
      {
        question:
          'Can I purchase FanVestor Experiences & Rewards for a single project more than once?',
        answer:
          "Of course, one can purchase a FanVestor Experience or Reward as many times as they would like as long as the experience/reward isn't sold out."
      },
      {
        question: 'Can fans post their own projects?',
        answer:
          'No, FanVestor is currently only offering exclusive rewards and experiences from celebrities, athletes and sports/entertainment companies.'
      },
      {
        question:
          'Can fans invest in securities from projects from their favorite celebrities, athletes and entertainment brands?',
        answer:
          'FanVestor is developing investment opportunities and currently is offering experiences and rewards from your favorite celebrities, athletes and entertainment brands.'
      }
    ]
  },
  {
    title: 'Platform Projects',
    subsections: [
      {
        question: 'What type of offerings are found on FanVestor?',
        answer:
          'The FanVestor ecosystem delivers the ability to purchase exclusive FanVestor Experiences & Rewards.'
      },
      {
        question: 'What type of Investments are found on FanVestor?',
        answer:
          'FanVestor takes care to evaluate potential investment offerings thoroughly, and as such, does not have any current opportunities for financial investment. Stay tuned, our team is in the process of bringing some vetted investment opportunities to the platform soon. \n' +
          'These investment opportunities will include:\n' +
          ' “Initial Entertainment Offering” (or "IEO") for investments you can place to fund exciting new entertainment ventures. \n' +
          '“Initial Lifestyle Offering” (or "ILO") for investmenting with your favorite celebrities in homeware, cooking, fashion, or hospitality products. \n' +
          '“Initial Sports Offering” (or "ISO") to financially participate with your favorite teams or individual athletes on and off the field. \n' +
          '“Initial Talent Offering” (or "ITO") for investments you can place with your favorite celebrities in their newest projects. \n' +
          '“Initial Influencer Offering” (or "IIO") for investments you can place with your favorite social trendsetters in fashion, design, or media.\n\n' +
          'All investments have risks, including loss of principal, and you should consult with a private and independent financial advisor prior to investing on FanVestor. '
      },
      {
        question:
          'How do members “win” exclusive FanVestor Experiences & Rewards?',
        answer:
          'FanVestor can conduct exclusive sweepstakes with individual eligibility requirements, conducted through specific rules of participation and recipient selection. The listing of each exclusive sweepstakes raffle will feature details pertinent to each eligibility project.'
      },
      {
        question: 'When does a Fanvestor of Experience & Reward offering end?',
        answer:
          'A Fanvestor Experience & Reward offering ends when its time limit has expired or the number of items available have been purchased. The FanVestor app features the time remaining as well as the number of items left for each project.'
      },
      {
        question:
          'What is a celebrity, athlete or entertainment/sports company obligated to do once a project ends?',
        answer:
          'The individual or company is responsible for completing the project and fulfilling each commitment to the best of their abilities.'
      },
      {
        question: 'Why can’t FanVestor guarantee projects?',
        answer:
          'FanVestor is a marketplace and platform, not a producer or owner of goods and services; providing celebrities, athletes or sports and entertainment companies to promote and sell FanVestor Experiences and Rewards to their fans.'
      },
      {
        question:
          'What happens if I am not satisfied with the outcome of an item that I purchased via FanVestor?',
        answer:
          'FanVestor continually monitors the obligations of those selling FanVestor Experiences or Rewards to their fans. However those selling Experiences or Rewards are responsible for making sure the projects offered on FanVestor meet the highest level of customer satisfaction. If a FanVestor member is not satisfied with the outcome, you can message us and we will be happy to help resolve any issues to the best of our abilities.'
      },
      {
        question:
          'What happens if a Experience/Reward breaks FanVestor’s rules?',
        answer:
          'FanVestor’s Trust & Safety team reviews any projects flagged internally or reported by members of the FanVestor community. We look for violations of FanVestor’s rules, and take the appropriate administrative action according to our Terms and Conditions.'
      }
    ]
  },
  {
    title: 'FanVestor App',
    subsections: [
      {
        question: 'Where can I download the FanVestor app?',
        answer: (
          <>
            You can download the FanVestor mobile apps on the{' '}
            {
              <TextLink
                to={'https://apps.apple.com/us/app/fanvestor/id1484304835'}
              >
                Apple Store
              </TextLink>
            }{' '}
            and{' '}
            {
              <TextLink
                to={
                  'https://play.google.com/store/apps/details?id=com.edenledger.fanvestor'
                }
              >
                Google Play Store
              </TextLink>
            }
            .
          </>
        )
      },
      {
        question: 'How do I update my FanVestor profile?',
        answer:
          'You can update your FanVestor profile by logging into your mobile or desktop app, clicking on your profile avatar and selecting the gear icon to open your account settings.'
      },
      {
        question: 'How do I update my email or password?',
        answer:
          'You can update your email or password by logging into FanVestor on your mobile or desktop app, clicking on your profile avatar and selecting the gear icon to open your account settings. If you log in through Google, Facebook or your Apple ID, you cannot change your email or password.'
      },
      {
        question: 'I forgot my password, what do I do?',
        answer:
          'If you are still logged in to FanVestor, log out of the app, select Log In and click on forgot password. Follow the steps to reset your password.'
      },
      {
        question:
          'I did not receive my email for resetting my password. What do I do?',
        answer: (
          <>
            If you do not receive the email for resetting your password within
            minutes, please check your spam or bulk mail folder just in case the
            email got delivered there instead of your inbox. If so, select the
            email and click Not Spam, which will allow future messages to get
            through. If you did not find the email in your spam or bulk folder,
            click on the forgot password link again or contact support by
            sending an email to{' '}
            <TextLink to={`mailto:${EMAILS.SUPPORT}`}>
              {EMAILS.SUPPORT}
            </TextLink>{' '}
            and someone from our team will assist you.
          </>
        )
      },
      {
        question: 'Why do you need my shipping address?',
        answer:
          'Some items purchased through FanVestor require physical delivery. If a reward you are purchasing requires physical delivery, during the checkout phase you will have the option to input your shipping address. Additionally, you can save your preferred shipping address in the settings tab.'
      },
      {
        question: 'How are FanVestor’s trending events selected?',
        answer:
          'FanVestor selects and displays trending events based on project popularity among our community members.'
      },
      {
        question: 'How does FanVestor select recommended events?',
        answer:
          'Recommended events are displayed based on the selection of your interests during registration. You can always change your interests in your account settings.'
      },
      {
        question: 'How do I follow / unfollow Experiences & Rewards?',
        answer:
          'From within the FanVestor app, go to a celebrity, athlete charity or company profile page and select the heart icon. If you then click into your fan profile and select the heart icon tab, those  you have followed will populate there. To unfollow a celebrity, charity or company, deselect the heart icon.'
      },
      {
        question:
          'Why am I receiving messages from a celebrity, charity or company?',
        answer:
          'If you have purchased an experience or reward from a celebrity, athlete charity or company or are currently following one of the aforementioned, you will receive important announcements and project updates.'
      },
      {
        question:
          'How do I stop receiving messages from a celebrity athlete, charity or company?',
        answer:
          'Currently, you cannot stop receiving messages from those you are following within the FanVestor app or have purchased an experience or reward from. By unfollowing those you’re following, you’ll reduce the messages you receive.'
      },
      {
        question: 'Why do I not receive in-app notifications?',
        answer:
          'You may have accidentally shut off this feature while browsing the app settings on your phone. Please go to your phone notification settings, choose the FanVestor app and make sure that the notification setting is turned on.'
      },
      {
        question: 'How do I contact support?',
        answer: (
          <>
            You can either send an email to{' '}
            <TextLink to={`mailto:${EMAILS.SUPPORT}`}>
              {EMAILS.SUPPORT}
            </TextLink>{' '}
            or from within the app go to your account settings and click on
            “Help” then “Contact Support.”
          </>
        )
      },
      {
        question: 'How do I delete my account?',
        answer: (
          <>
            To delete your account, please send an account deletion request to{' '}
            <TextLink to={`mailto:${EMAILS.SUPPORT}`}>
              {EMAILS.SUPPORT}
            </TextLink>{' '}
            and someone from our team will assist you.
          </>
        )
      }
    ]
  },
  {
    title: 'Payment Processing',
    subsections: [
      {
        question: 'What payment methods are available?',
        answer:
          'FanVestor accepts all major credit cards, PayPal and Cryptocurrency payments. FanVestor does not store any credit card information on file. The credit card payment processor we use is PCI Level-1 compliant.'
      },
      {
        question: 'When is my payment method charged?',
        answer:
          'When purchasing a FanVestor Experience or Reward, your payment method is charged at the time of checkout.'
      },
      {
        question: 'Does FanVestor issue refunds?',
        answer:
          'FanVestor will do its best to facilitate a refund if you are not satisfied with the outcome of a purchase, however it is the responsibility of the Brands to issue any refunds. Therefore, we cannot guarantee that refunds will be made all the time.'
      },
      {
        question: 'My refund was sent to an expired card. What do I do?',
        answer:
          'Refunds issued can only be applied to the credit card originally used to support a project. If the refund was already sent to an expired card, FanVestor recommends reaching out directly to your bank. When speaking with them, you’ll want to mention the date of your refund as well as the amount refunded.'
      },
      {
        question: 'Why is there a charge from EDENLEDGER on my bank statement?',
        answer:
          'EdenLedger is the parent Company of FanVestor. All charges from FanVestor will show up from EdenLedger on your bank or credit card statement.'
      },
      {
        question: 'Can I cancel a payment?',
        answer:
          'No, payments cannot be cancelled once you have purchased a FanVestor Experience or Reward. If you believe there is a false charge, please reach out to us and we will look into it.'
      }
    ]
  }
]

export default sections
