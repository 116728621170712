import styled, { css } from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import smallBg from './header-bg-sm.png'
import mediumBg from './header-bg-md.png'
import largeBg from './header-bg-lg.png'
import extraLargeBg from './header-bg-xl.png'

export const HeaderWrapper = styled.div`
  height: 20.25rem;
  display: flex;
  align-items: flex-end;

  ${media.md`
    height: 29.875rem;
  `}
`

export const BodyWrapper = styled.div`
  padding: 0 1rem;
  z-index: 1;

  ${media.md`
    padding: 0 4.125rem;
  `}
`

export const ContentWrapper = styled.div<{ inFlow: boolean }>`
  width: 100%;
  position: relative;
  background-color: ${p => p.theme.colors.white};
  display: flex;
  flex-direction: column;

  ::before {
    content: '';
    position: absolute;
    height: 20.25rem;
    width: 100%;
    background-image: url(${smallBg});
    background-size: cover;
    background-repeat: no-repeat;
    
      ${media.md`
        background-image: url(${mediumBg});
        height: 32.875rem;
      `}
    
      ${media.lg`
        background-image: url(${largeBg});
      `}
    
      ${media.xl`
        background-image: url(${extraLargeBg});
      `}
  }
  
  ${p =>
    !p.inFlow &&
    css`
      ::before {
        height: 26rem;

        ${media.md`
          height: 37rem;
        `}
      }
    `}
`
