import React from 'react'

const DownloadIcon = styles => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M19.9993 10.0007C19.9993 15.5236 15.5221 20.0007 9.99927 20.0007C4.47642 20.0007 -0.000732422 15.5236 -0.000732422 10.0007C-0.000732422 4.47788 4.47642 0.000732422 9.99927 0.000732422C15.5221 0.000732422 19.9993 4.47788 19.9993 10.0007ZM18.4993 10.0007C18.4993 5.30631 14.6937 1.50073 9.99927 1.50073C5.30485 1.50073 1.49927 5.30631 1.49927 10.0007C1.49927 14.6952 5.30485 18.5007 9.99927 18.5007C14.6937 18.5007 18.4993 14.6952 18.4993 10.0007ZM14.4462 9.64778L14.5303 9.7204C14.7966 9.98667 14.8208 10.4033 14.6029 10.6969L14.5303 10.7811L10.5295 14.7819C10.2632 15.0482 9.84645 15.0724 9.55283 14.8544L9.46872 14.7818L5.46872 10.7803C5.17588 10.4873 5.17597 10.0124 5.46892 9.7196C5.73524 9.45338 6.15191 9.42925 6.44548 9.64717L6.52958 9.7198L9.25027 12.4417L9.24997 5.75003C9.24997 5.37033 9.53213 5.05654 9.8982 5.00688L9.99997 5.00003C10.3797 5.00003 10.6935 5.28218 10.7431 5.64826L10.75 5.75003L10.7503 12.4397L13.4696 9.7204C13.7359 9.45414 14.1526 9.42993 14.4462 9.64778L14.5303 9.7204L14.4462 9.64778Z"
      fill="#EB78FF"
      {...styles}
    />
  </svg>
)

export default DownloadIcon
