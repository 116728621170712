import styled from 'styled-components'

export const ComingSoonBlock = styled.div<{
  textColor?: string
}>`
  text-align: center;
  margin-bottom: 8rem;
  padding-top: 6.25rem;

  svg {
    opacity: 1;
    width: 176px;
    height: 177px;
    margin-bottom: 5.625rem;

    path {
      fill: ${p => p.theme.colors.white};
    }
  }

  p {
    max-width: 25.75rem;
    margin: 0 auto;
    fill: #202532;
    font-weight: 500;
    font-size: 38px;
    line-height: 45px;
    text-transform: uppercase;
    letter-spacing: 15.2px;
    color: ${p => p.textColor};
  }
`
