import React, { FC } from 'react'
import { IUserEventDetails } from 'redux/profile/types'
import EventFund from 'components/events/EventFund'
import { cutText } from 'utils/formatText'

import { Wrapper, EventTitle, FundWrapper } from './styles'

const AmountRaisedBars: FC<{ event: IUserEventDetails }> = ({ event }) => (
  <Wrapper>
    <EventTitle>{cutText(event.title, 26)}</EventTitle>
    <FundWrapper>
      <EventFund
        total={event.tiers_total}
        collected={event.raised}
        variant={'secondary'}
        eventStatus={event.status}
      />
    </FundWrapper>
  </Wrapper>
)

export default AmountRaisedBars
