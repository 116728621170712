import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export const Terms = styled.article`
  max-height: 60vh;
  height: auto;
  padding-bottom: 9px;
  font-family: Inter !important;
  font-size: 0.875rem !important;
  overflow-x: hidden;
  ${media.md`
    padding-bottom: 35px;
  `}
  ${media.md`
    margin-bottom: 20px;
  `}
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar,
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    background-color: rgba(0, 0, 0, 0);
  }
  ul {
    font-size: 0.75rem;
    line-height: 1.1875rem;
    margin-bottom: 15px;
    color: ${p => p.theme.colors.black};
    li {
      list-style-position: inside;
    }
  }

  * {
    color: ${p => p.theme.colors.black} !important;
    font-family: Inter !important;
  }
  kbd {
    position: initial !important;
    font-size: 0.875rem !important;
    margin-right: 5px;
    text-transform: uppercase !important;
  }
`

export const Title = styled.h2`
  font-weight: 900;
  font-size: 1.875rem;
  font-family: Inter;
  color: ${p => p.theme.colors.black};
  ${media.md`
    font-weight: 800;
    font-size: 2.1875rem;
  `}
`

export const Text = styled.p`
  font-size: 0.75rem;
  font-family: Inter;
  line-height: 1.1875rem;
  color: ${p => p.theme.colors.black};
  :not(:last-of-type) {
    margin-bottom: 15px;
  }
  a {
    font-size: 0.75rem !important;
    color: ${p => p.theme.colors.fuchsia} !important;
    :hover {
      opacity: 0.7;
    }
  }
`

export const ModalFooter = styled.div`
  padding-bottom: 17px;

  ${media.md`
    padding: 25px;
  `}
`
