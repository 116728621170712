import React from 'react'
import { Route } from 'react-router-dom'
import { Container } from 'styled-bootstrap-grid'

import { ProfileSectionTypes } from 'redux/profile/types'

import { NewTabs as Tabs } from 'components/UI/Tabs'
import Link from 'components/UI/Link'
import NotificationList from 'components/profile/notifications/NotificationList'
import configTabs from './tabs'

import {
  Wrapper,
  SubMenuContentWrapper,
  SubMenuBlockWrapper
} from 'containers/UserSettingsPage/styles'
const UserNotificationsPage = () => {
  const tabs: any = configTabs()

  return (
    <Wrapper>
      <Container>
        <SubMenuBlockWrapper>
          <Tabs tabs={tabs}>
            {Object.keys(tabs).map(category => (
              <Link key={category} to={tabs[category].url} />
            ))}
          </Tabs>
          <SubMenuContentWrapper>
            <Route
              exact={true}
              path={tabs[ProfileSectionTypes.NOTIFICATIONS].url}
              component={NotificationList}
            />
          </SubMenuContentWrapper>
        </SubMenuBlockWrapper>
      </Container>
    </Wrapper>
  )
}

export default UserNotificationsPage
