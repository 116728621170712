import React from 'react'

interface Props {
  hasMore?: true
  onLoadMore?: () => void
  scrollParent?: boolean
}

export class InfiniteScroll extends React.PureComponent<Props> {
  static defaultProps = {
    hasMore: true,
    onLoadMore: () => {},
    scrollParent: false
  }

  private readonly myRef: React.RefObject<HTMLDivElement>

  constructor(props: Props) {
    super(props)
    // @ts-ignore
    this.myRef = React.createRef()
  }

  componentDidMount() {
    document.addEventListener('wheel', this.onScroll, { passive: true })
    document.addEventListener('scroll', this.onScroll, { passive: true })
  }

  componentWillUnmount() {
    document.removeEventListener('wheel', this.onScroll, false)
    document.removeEventListener('scroll', this.onScroll, false)
  }

  onScroll = () => {
    const { onLoadMore, hasMore } = this.props

    if (!hasMore) {
      return
    }

    const node = this.myRef.current

    if (node) {
      const offsetTop = node.offsetTop
      const offsetHeight = node.offsetHeight
      const scrolledToBottom =
        Math.ceil(window.innerHeight + window.scrollY) >=
        offsetHeight + offsetTop - 100

      if (scrolledToBottom && onLoadMore) {
        onLoadMore()
      }
    }
  }

  render() {
    return <div ref={this.myRef}>{this.props.children}</div>
  }
}

export default InfiniteScroll
