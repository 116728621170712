import React, { FC, useCallback, useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { ITrust, TrustFields } from 'redux/investment/types'
import {
  selectInvestmentErrors,
  selectTrustInfo
} from 'redux/investment/selectors'
import {
  convertValueToTaxId,
  noFirstSpaceRegex,
  noTwoSpacesRegex
} from 'utils/helpers'
import { DEFAULT_TAX_ID } from 'utils/dateFormats'
import { setErrors } from 'redux/investment/actions'
import TextInput from 'src/components/UI/TextInput'
import BackButton from 'src/components/investment/steps/BackButton'

import { Form, Title, Navigation, BackButtonWrapper, Step } from './styles'
import debounce from 'utils/debounce'

const TrustForm: FC<{ [key: string]: any }> = props => {
  const dispatch = useDispatch()
  const trustInfo = useSelector(selectTrustInfo)
  const errors = useSelector(selectInvestmentErrors)
  const { selectedEntityInfo, setSelectedEntityInfo: setTrust } = props
  const trust = selectedEntityInfo as ITrust
  const [trustForm, setTrustForm] = useState<ITrust>(trust)

  useEffect(() => {
    setToContext(trustForm)
    // eslint-disable-next-line
  }, [trustForm])

  useEffect(() => {
    const { isFetching, ...selectedTrust } = selectedEntityInfo
    if (Object.values(selectedTrust).every(c => !c)) {
      setTrustForm(trustInfo)
    }
    // eslint-disable-next-line
  }, [trustInfo])

  const setToContext = useCallback(debounce(setTrust, 400), [])

  const onChange = (name: string, value: string) => {
    if (name === 'trust_tax_id') {
      setTrustForm({ ...trust, [name]: convertValueToTaxId(value) })
    } else {
      const currentValue = value
        .replace(noFirstSpaceRegex, '')
        .replace(noTwoSpacesRegex, ' ')
      setTrustForm({ ...trust, [name]: currentValue })
    }

    if (errors[name]) {
      dispatch(setErrors({ ...errors, [name]: '' }))
    }
  }

  return (
    <>
      <Navigation>
        <BackButtonWrapper>
          <Step>2</Step>
          <BackButton />
          <Title>Trust Information</Title>
        </BackButtonWrapper>
      </Navigation>
      <Form>
        <TextInput
          name={'trust_name'}
          value={trustForm.trust_name}
          label={TrustFields.trust_name}
          handleChange={onChange}
          wrapperClassName={'form__first-field'}
          errorLabel={errors.trust_name}
          maxLength={128}
        />
        <TextInput
          type={'tel'}
          name={'trust_tax_id'}
          value={trustForm.trust_tax_id}
          label={TrustFields.trust_tax_id}
          maxLength={10}
          placeholder={DEFAULT_TAX_ID}
          handleChange={onChange}
          errorLabel={errors.trust_tax_id}
          wrapperClassName={'form__last-field'}
        />
      </Form>
    </>
  )
}

export default React.memo(TrustForm)
