import React, { FC } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {
  postComment,
  loadMoreComments,
  showMoreComments
} from 'redux/events/operations'
import { clearErrors } from 'redux/events/actions'
import { selectIsAuth, selectUser } from 'redux/auth/selectors'
import {
  getCommentsLeftNumber,
  selectCommentCount,
  selectEventsDetail,
  selectEventsErrors,
  selectEventsShownComments,
  selectIsFetchingComments,
  selectIsSendingComments
} from 'redux/events/selectors'
import { routePath } from 'routes'

import CommentCard from '../CommentCard'
import CommentField from '../CommentField'

import {
  Wrapper,
  LoadMoreButton,
  AuthLink,
  CommentBlock,
  AuthMessage
} from './styles'

import { IComment } from 'redux/events/comments/types'
import { AppState, Dispatch } from 'store/types'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import Avatar from 'components/common/Avatar'
import { Loading } from '../CommentField/styles'

const Conversation: FC<Props> = props => {
  const {
    shownComments,
    eventId,
    isAuthenticated,
    isFetchingComments,
    isSendingComments,
    error,
    clearErrors,
    commentCount,
    user,
    location: { pathname = '' } = {}
  } = props

  const commentsLeft = getCommentsLeftNumber(commentCount, shownComments)
  const noComments =
    isAuthenticated && shownComments && shownComments.length === 0

  const loadMore = async () => {
    const { data, loadMoreComments, showMoreComments } = props

    try {
      if (shownComments.length + 3 >= data.length) {
        await loadMoreComments()
      }
      showMoreComments()
    } catch (e) {}
  }

  const handlePostComment = (content: string) => {
    const { postComment } = props

    postComment(eventId, content)
    scrollToParent()
  }

  const scrollToParent = () => {
    const parent = document.getElementById('comments')
    if (parent) {
      parent.scrollIntoView()
    }
  }

  const renderComment = (comment: IComment) => {
    return <CommentCard eventId={eventId} comment={comment} />
  }

  return (
    <>
      <Wrapper>
        {!isAuthenticated && (
          <AuthMessage>
            <AuthLink
              to={{
                pathname: routePath.SIGN_UP,
                state: {
                  from: pathname
                }
              }}
            >
              Sign up
            </AuthLink>{' '}
            or{' '}
            <AuthLink
              to={{
                pathname: routePath.SIGN_IN,
                state: {
                  from: pathname
                }
              }}
            >
              log in
            </AuthLink>{' '}
            to join the discussion.
          </AuthMessage>
        )}
        {noComments && !isFetchingComments && (
          <p>Be the first one to leave a comment for this project</p>
        )}
        {shownComments && shownComments.length > 0 && (
          <ul>
            {shownComments.map((comment: IComment) => renderComment(comment))}
          </ul>
        )}
        {commentsLeft > 0 && (
          <LoadMoreButton onClick={loadMore} disabled={isFetchingComments}>
            {!isFetchingComments ? (
              `Show more (${commentsLeft})`
            ) : (
              <Loading>
                <span>&bull;</span>
                <span>&bull;</span>
                <span>&bull;</span>
              </Loading>
            )}
          </LoadMoreButton>
        )}
      </Wrapper>
      {isAuthenticated && (
        <CommentBlock>
          <Avatar
            name={`${user.first_name} ${user.last_name}`}
            src={user.profile_photo}
            size={40}
          />
          <CommentField
            post={handlePostComment}
            isSending={isSendingComments}
            error={error}
            clearErrors={clearErrors}
          />
        </CommentBlock>
      )}
    </>
  )
}

interface ReceivedProps {
  data: IComment[]
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & ReceivedProps & RouteComponentProps

const mapStateToProps = (state: AppState) => ({
  eventId: selectEventsDetail(state).event_id,
  isAuthenticated: selectIsAuth(state),
  isFetchingComments: selectIsFetchingComments(state),
  isSendingComments: selectIsSendingComments(state),
  commentCount: selectCommentCount(state),
  error: selectEventsErrors(state),
  shownComments: selectEventsShownComments(state),
  user: selectUser(state)
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      postComment,
      loadMoreComments,
      clearErrors,
      showMoreComments
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Conversation)
)
