import React, { FC } from 'react'

import Link from 'components/UI/Link'
import { LogoIcon } from 'components/icons/logo'
import { WEBSITE } from 'utils/constants'

import { LogoWrapper } from './styles'
import logo from './logoP.png'

export interface Props {
  width?: number
  height?: number
  showFullLogo?: boolean
  isLightSkeletonLogo?: boolean
}

const Logo: FC<Props> = ({
  width,
  height,
  showFullLogo,
  isLightSkeletonLogo = true
}) => (
  <LogoWrapper>
    <Link to={WEBSITE}>
      {showFullLogo ? (
        <img src={logo} alt={'logo'} width={width} />
      ) : (
        <LogoIcon width={width} height={height} light={isLightSkeletonLogo} />
      )}
    </Link>
  </LogoWrapper>
)

Logo.defaultProps = {
  width: 66,
  height: 66,
  showFullLogo: false
}

export default Logo
