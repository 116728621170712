import React from 'react'
import { IEventDetail } from 'redux/events/types'
import NFTCardContent from './NFTCardContent'
import { fallbackImage } from '../NFTsCardList'

import {
  ImgEvent,
  InvestCard
} from 'components/invest/InvestmentCard/InvestmentCardList/styles'

interface Props {
  event: IEventDetail
  showHours?: boolean
}

const InvestmentCardMosaic: React.FC<Props> = ({
  event,
  showHours
}): React.ReactElement => {
  const { event_picture } = event

  return (
    <InvestCard style={{ padding: 30, height: 500 }}>
      <ImgEvent src={event_picture} fallback={fallbackImage} />
      <NFTCardContent event={event} showHours={showHours} />
    </InvestCard>
  )
}

export default InvestmentCardMosaic
