import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import bg from './fv-pattern-dark.png'

export const WrapperBlock = styled.div`
  position: relative;
  margin-left: -0.125rem;
  padding: 3.3vmax 0;
  background: url(${bg}) repeat 0 48%;
  background-size: cover;
  margin-top: 60px;

  &:before {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: ${p => p.theme.colors.gold};
    opacity: 0.15;
  }
  ${media.md`
    margin-top: 0;
  `}
`
export const Wrapper = styled.div.attrs(p => ({
  'data-testid': 'event-head-wrapper'
}))`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`
export const EventTitle = styled.h2`
  margin: 1rem;
  font-family: 'Acumin Pro';
  text-transform: uppercase;
  line-height: 3rem;
  color: ${p => p.theme.colors.gold};
  font-size: calc(2.16vw + 1rem);
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: baseline;
  flex-wrap: wrap;

  > div {
    width: auto;
    margin-right: 1rem;
    background: none;
    padding: 0;

    p {
      font-family: 'Acumin Pro';
      text-transform: uppercase;
      line-height: 3rem;
      color: ${p => p.theme.colors.gold};
      font-size: inherit;
      width: 100%;
      text-align: center;
    }
  }

  ${media.md`
    margin-left: 4.75rem; 
    font-size: 3rem;
  `}

  ${media.xl`
    font-size: 2.8rem;
  `}
`
