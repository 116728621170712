import styled from 'styled-components'
import Button from 'src/components/UI/Button'
import Link from 'components/UI/Link'
import { media } from 'styled-bootstrap-grid'

export const SocialWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -1rem;
  button:not(:last-child) {
    margin-right: 24px;
    @media screen and (max-width: 321px) {
      margin-right: 15px;
    }
  }
  ${media.md`
     margin-top: 0;
  `}
`

export const SocialButton = styled(Button)`
  display: flex;
  align-items: center;
  padding: 0 !important;
  ${media.xs`
  svg { width: calc(20px*0.85); }
  `}
`

export const CommentButton = styled(Link)`
  display: flex;
  align-items: center;
  ${media.xs`
  svg { width: calc(20px*0.85); }
  `}
`

export const Counter = styled.span`
  font-family: Inter;
  font-size: 0.938rem;
  font-weight: 400;
  color: ${p => p.theme.colors.brightFuchsia};
  min-height: 21px;
  margin-left: 5px;
  ${media.md`
   font-size: 1.125rem;
  `}
`
