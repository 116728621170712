import { IEventDetail } from 'redux/events/types'
import { toDashCase } from 'utils/helpers'

export const generateMenu = (item: IEventDetail) => {
  const isEnabled = (prop: keyof IEventDetail) =>
    // @ts-ignore
    item[prop] && item[prop].length > 0

  const isEnabledSection = (prop: string) =>
    item.sections &&
    item.sections.some(i => i.name.toLowerCase() === prop.toLowerCase())

  const exceptions = [
    'REASONS TO INVEST',
    'INVESTOR DECK',
    'DISCLOSURES',
    'DRIVING INVESTORS',
    'COMPANY HIGHLIGHTS',
    'MANAGEMENT TEAM',
    'PHOTO GALLERY'
  ]

  const sections =
    item &&
    item.sections &&
    item.sections.filter(el => {
      return !exceptions.includes(el.name)
    })

  const menuItems = [
    {
      id: 'story',
      title: 'Story',
      position: null,
      enabled: isEnabled('event_description')
    },
    {
      id: 'tiers-block',
      title: 'Investments',
      position: null,
      enabled: isEnabled('tiers')
    },
    {
      id: 'reasons-to-invest',
      title: 'Reasons to invest',
      position: null,
      enabled: isEnabledSection('reasons to invest')
    },
    {
      id: 'company-highlights',
      title: 'Company highlights',
      position: null,
      enabled: isEnabledSection('company highlights')
    },
    // push
    {
      id: 'management-team',
      title: 'Management team',
      position: null,
      enabled: isEnabledSection('management team')
    },
    {
      id: 'data-room',
      title: 'Data room',
      position: null,
      enabled: isEnabled('data_room')
    },
    {
      id: 'investor-deck',
      title: 'Investor deck',
      position: null,
      enabled: isEnabledSection('investor deck')
    },
    {
      id: 'driving-investors',
      title: 'Driving investors',
      position: null,
      enabled: isEnabledSection('driving investors')
    },
    {
      id: 'photo-gallery',
      title: 'Photo gallery',
      position: null,
      enabled: isEnabledSection('photo gallery')
    },
    {
      id: 'disclosures',
      title: 'Disclosures',
      position: null,
      enabled: isEnabledSection('disclosures')
    },
    {
      id: 'faq',
      title: 'FAQ',
      position: null,
      enabled: isEnabled('faqs')
    },
    { id: 'comments', title: 'comments', position: null, enabled: true }
  ]

  sections.reverse().forEach(section => {
    menuItems.splice(4, 0, {
      id: toDashCase(section.name),
      title: section.name,
      position: null,
      enabled: true
    })
  })

  // menuItems.push(
  //   { id: 'faq', title: 'Faq', position: null, enabled: isEnabled('faqs') },
  //   {
  //     id: 'data-room',
  //     title: 'Data room',
  //     position: null,
  //     enabled: isEnabled('data_room')
  //   },
  //   { id: 'comments', title: 'comments', position: null, enabled: true }
  // )

  return menuItems.filter(el => el.enabled)
}
