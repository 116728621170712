import React from 'react'

const AlertIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path
      fill="#ff5353"
      d="M16 8c0 4.422-3.578 8-8 8-4.422 0-8-3.578-8-8 0-4.422 3.578-8 8-8 4.422 0 8 3.578 8 8zm-1.25 0c0-3.73-3.019-6.75-6.75-6.75A6.746 6.746 0 001.25 8c0 3.73 3.019 6.75 6.75 6.75 3.73 0 6.75-3.019 6.75-6.75z"
    />
    <path
      fill="#ff5353"
      d="M8.005 4.03a.625.625 0 00-.625.625V8.68a.625.625 0 101.25 0V4.655a.625.625 0 00-.625-.625zM8.004 11.758a.844.844 0 100-1.688.844.844 0 000 1.688z"
    />
  </svg>
)

export default AlertIcon
