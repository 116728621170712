import React from 'react'

const ChevronIcon = props => (
  <svg viewBox="0 0 9 5" width={9} height={5} fill="none" {...props}>
    <path
      opacity=".9"
      d="M4.5 4.145L.573.1a.325.325 0 00-.477.007.372.372 0 00.006.505L4.265 4.9c.13.135.34.135.47 0L8.898.613a.372.372 0 00.006-.505.325.325 0 00-.477-.007L4.5 4.145z"
      fill="#fff"
    />
  </svg>
)

export default ChevronIcon
