import React, { FC } from 'react'
import { Option } from '../profile/UserHeader/styles'
import { GoBackArrowIcon } from 'components/icons'

const GoBackLink: FC<{ to: string }> = ({ to }) => (
  <Option to={to}>
    <GoBackArrowIcon />
  </Option>
)

export default GoBackLink
