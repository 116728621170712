import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { AppState, Dispatch } from 'store/types'
import { AnalyticsTabs } from 'redux/influencer/types'
import { fetchAnalytics, profileExport } from 'redux/profile/operations'
import {
  selectAmountRaised,
  selectEventDetails,
  selectSupportEvents
} from 'redux/profile/selectors'
import AmountRaisedBars from 'components/influencer/AmountRaisedBars'
import { SubTabs as Tabs } from 'components/UI/Tabs'
import { ExportForm } from 'components/influencer/analytics'
import configTabs from './tabs'

import {
  Wrapper,
  TabContainer,
  TabContentWrapper,
  ExportButton,
  Text
} from './styles'

interface State {
  isShownExport: boolean
}
class AnalyticsBlock extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      isShownExport: false
    }
  }

  componentDidMount() {
    this.props.fetchAnalytics()
  }

  showExport = () => this.setState({ isShownExport: true })

  noData = () => <Text>No data</Text>

  render() {
    const { isShownExport } = this.state
    const { eventDetails, supportEvents, profileExport } = this.props
    const tabs = configTabs(this.props)

    if (isShownExport) {
      return (
        <ExportForm supportEvents={supportEvents} onSubmit={profileExport} />
      )
    }

    return (
      <Wrapper>
        <Tabs
          tabs={tabs}
          activeTab={AnalyticsTabs.AMOUNT_RAISED}
          tabContainer={TabContainer}
        >
          <TabContentWrapper key={AnalyticsTabs.FOLLOWERS}>
            {this.noData()}
          </TabContentWrapper>
          <TabContentWrapper key={AnalyticsTabs.SUPPORTERS}>
            {this.noData()}
          </TabContentWrapper>
          <TabContentWrapper key={AnalyticsTabs.AMOUNT_RAISED}>
            {eventDetails.length > 0
              ? eventDetails.map(event => (
                  <AmountRaisedBars key={event.id} event={event} />
                ))
              : this.noData()}
          </TabContentWrapper>
          <TabContentWrapper key={AnalyticsTabs.REGIONS}>
            {this.noData()}
          </TabContentWrapper>
        </Tabs>
        <ExportButton variant={'default'} onClick={this.showExport}>
          Export Data
        </ExportButton>
      </Wrapper>
    )
  }
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
export type Props = StateProps & DispatchProps

const mapStateToProps = (state: AppState) => ({
  amountRaised: selectAmountRaised(state),
  followers: '',
  supporters: '',
  eventDetails: selectEventDetails(state),
  supportEvents: selectSupportEvents(state)
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ fetchAnalytics, profileExport }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnalyticsBlock)
