import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export const Form = styled.form`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${media.md`
    padding: 3.75rem;
  `}

  ${media.lg`
    padding: 3.75rem 12.5rem;
  `}
`
export const WrapInputs = styled.div`
  width: 100%;
  padding-bottom: 2rem;
`
export const WrapCheckboxBlock = styled.div`
  width: 100%;
  margin-bottom: 3rem;

  label {
    margin-top: 2rem;
  }
`
export const Title = styled.label`
  font-size: 16px;
  color: #ffffff;
  font-weight: 500;
  padding: 5px 16px 8px 0;
  white-space: nowrap;

  ${media.md`
    font-size: 22px;
    padding: 5px 16px 0 0;
  `}
  ${media.sm`
     padding: 8px 16px 0 0;
  `}
`
export const ErrorBlock = styled.div`
  flex-direction: column;

  label {
    margin-top: 0.4rem;
  }
`
