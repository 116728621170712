import styled, { css } from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { FacebookShareButton, TwitterShareButton } from 'react-share'
import Button from 'components/UI/Button'

export const ModalTitle = styled.h3`
  font-size: 1.5rem;
  font-family: Inter;
  font-weight: 500;
  color: black;
`
export const ButtonWrapper = styled.div`
  margin-bottom: 2.5rem;
  padding: 2rem 0;
  display: flex;
  justify-content: space-between;
  svg {
    width: 40px;
    margin-right: 0.625rem;
  }
`
export const ShareTitleWrapper = styled.div`
  width: 100%;
  margin-bottom: 2.625rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const ShareTitle = styled.p`
  font-size: 1.375rem;
  font-weight: 500;
`
const svgStyle = css`
  position: relative;
  :after {
    display: none;
    content: ' ';
    position: absolute;
    left: 45px;
    width: 1px;
    height: 30px;
    margin-left: 5px;
    background-color: rgba(255, 255, 255, 0.6);
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 255, 0.2);
    ${media.sm`
  display: block;
  left: 35px;
  `}
    ${media.md`
  left: 45px;
  `}
  }
  svg {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 5px;
    ${media.md`
      width: 30px;
      height: 30px;
  `}
  }
`
export const FacebookButton = styled(FacebookShareButton)`
  background-color: #4267b2 !important;
  ${() => svgStyle};
  ${media.md`
  width: 14.25rem;
  `}
`
export const TwitterButton = styled(TwitterShareButton)`
  background-color: #1da1f2 !important;
  ${() => svgStyle};
  ${media.md`
  width: 14.25rem;
  `}
`
export const InputWrapper = styled.div`
  position: relative;
  margin-bottom: 20px;
`
export const CopyButton = styled(Button)`
  padding: 0.625rem 1.125rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  z-index: 1;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
  border-radius: 0.75rem;
  background-color: ${p => p.theme.colors.fuchsia};
  color: ${p => p.theme.colors.white};
  font-size: 0.875rem;
  font-weight: 500;
`
export const Input = styled.input`
  width: 100%;
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.15);
  border: 0.0625rem solid #dddddd;
  border-radius: 0.75rem;
  padding: 0.625rem 5.875rem 0.625rem 1.5rem;
  color: #6f6f6f;
  font-size: 0.75rem;
  letter-spacing: 0.0625rem;
  line-height: 1.375rem;
  :focus {
    outline: none;
    border: 0.0625rem solid ${p => p.theme.colors.fuchsia};
  }
`
export const socialButtonStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontFamily: 'Inter',
  fontSize: '1.125rem',
  lineHeight: '1.5rem',
  borderRadius: '0.75rem',
  boxShadow: 'none',
  paddingLeft: '15px',
  width: '45%',
  color: '#fff',
  height: '55px'
}
