const settings = {
  infinite: true,
  arrow: false,
  slidesToShow: 3,
  slidesToScroll: 2,
  variableWidth: true,
  initialSlide: 0,
  cssEase: 'linear',
  touchMove: true,
  dots: false
}

export default settings
