import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import Img from 'components/UI/Img'

export const Wrapper = styled.div`
  padding-top: 1rem;
  position: relative;

  a {
    display: inline-block;
  }

  :before {
    content: '';
    position: absolute;
    bottom: -65px;
    left: 0;
    width: 30px;
    height: 1px;
    background: ${p => p.theme.colors.white};
  }
`
export const Text = styled.div`
  font-size: 1.375rem;
  font-family: Inter;
  width: 100%;
  color: ${p => p.theme.colors.white};
  opacity: 0.7;
  line-height: 2rem;
  margin-bottom: 1.5625rem;

  ${media.md`
    width: 70%;
  `};
`

export const LinkRow = styled.p`
  display: flex;
  align-items: center;

  img {
    width: 30px;
    height: 30px;
    object-fit: cover;
    margin-right: 0.5rem;
  }
`

export const IconWrapper = styled.div`
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 1.875rem;
  background: rgba(36, 44, 88, 0.3);
  border: 1px solid #191919;
  border-radius: 1rem;
  margin-right: 1.25rem;

  span {
    font-family: Inter;
    font-size: 1.125rem;
    font-weight: 300;
    padding-left: 0.75rem;
    color: ${p => p.theme.colors.brightFuchsia};
  }

  > div {
    width: 40px;
    height: 40px;
    img {
      width: 25px !important;
      height: 25px !important;
    }
  }
`

export const Icon = styled(Img)`
  height: 20px;
  width: 20px;
`
