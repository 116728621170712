import React, { FC, Fragment } from 'react'
import { routePath } from 'routes'
import configSections from './sections'
import { ButtonVariant } from 'src/components/UI/Button'
import Link from 'src/components/UI/Link'
import { useInvestNowContext } from 'src/pages/investment/InvestNowPage'

import {
  SectionSubtitle,
  SectionTitle,
  SectionValue,
  Separator,
  SidebarWrapper,
  Title
} from './styles'

interface Props {
  amount: number
  title: string
}

const Sidebar: FC<Props> = ({ amount, title }) => {
  const { selectedEntity: entity } = useInvestNowContext()
  const sections = configSections({ amount, entity, title })

  return (
    <SidebarWrapper>
      <Title>Invest in Project</Title>
      {sections.map(section => {
        const { title, subtitle, value } = section

        return (
          <Fragment key={title}>
            <SectionTitle>{title}</SectionTitle>
            {subtitle && <SectionSubtitle>{subtitle}</SectionSubtitle>}
            <SectionValue>{value}</SectionValue>
            <Separator />
          </Fragment>
        )
      })}
      <Link variant={ButtonVariant.Text} to={routePath.PROFILE_SETTINGS_FAQ}>
        Frequently Asked Questions
      </Link>
    </SidebarWrapper>
  )
}

export default Sidebar
