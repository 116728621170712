import React from 'react'
import styled from 'styled-components'

const Label = styled.label`
  margin-top: 3px;
  display: inline-block;
  color: red;
  font-size: 11px;
  line-height: 14px;
`

interface Props {
  label?: string | string[]
  style?: any
}

const ErrorLabel = (props: Props) => {
  const { label, ...restProps } = props

  if (Array.isArray(label) && label.length > 0) {
    return <Label {...restProps}>{label[0]}</Label>
  }
  return <Label {...restProps}>{label}</Label>
}

export default ErrorLabel
