import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import Img from 'components/UI/Img'

export const EventLogo = styled(Img)`
  max-width: 100%;
  max-height: 180px;
  margin: 0.5rem 0;
  object-fit: contain;
`

export const TitleLogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 15px;
  min-height: 250px;
  ${media.md`
    flex-direction: row;
    justify-content: space-between;
    min-height: 135px;
  `}
`

export const WrapperLogo = styled.div`
  max-width: 200px;
  div {
    background-color: none !important;
    width: 200px !important;
    height: 100px !important;
    margin: 20px 0;
    img {
      margin: 2.5rem 0 !important;
      width: 80px !important;
      height: 80px !important;
    }
  }
`
