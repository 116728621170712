import { media } from 'styled-bootstrap-grid'
import styled, { css } from 'styled-components'

export const ContentTitle = styled.div`
  font-family: Inter !important;
  font-size: 0.9375rem !important;
  font-weight: 900;
  margin-bottom: 0.5rem;
  line-height: 1.125rem;

  ${media.md`
    font-size: 1.125rem !important;
    margin-bottom: 0.75rem;
  `}
`

export const ContentDescription = styled.div<{ firstdesc?: boolean }>`
  p {
    margin-bottom: 1.125rem;
  }

  p,
  span,
  strong {
    font-family: Inter !important;
    font-size: ${p => (p.firstdesc ? '1.125rem' : '0.9375rem')} !important;
    line-height: ${p => (p.firstdesc ? '1.625rem' : '1.5rem')};
    color: ${p => p.theme.colors.black} !important;
  }

  font-weight: ${p => (p.firstdesc ? 400 : 300)};
  margin-bottom: ${p => (p.firstdesc ? '2rem' : '0')};

  li {
    list-style: none !important;
    margin-bottom: 1.125rem;
  }

  /* Tables */
  table {
    font-family: Inter;
    font-size: 1.125rem;
    width: 100% !important;
    margin-bottom: 0;
    margin-top: 15px;
    border: none;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
  }

  thead tr th {
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 1px solid #e5e5e5;
    text-align: left;
  }

  thead tr th {
    color: ${p => p.theme.colors.black} !important;
    opacity: 0.8;
    font-weight: 300;
    padding: 0.5rem 0;
  }

  tbody tr th {
    font-weight: 900;
    line-height: 1.125rem;
    padding: 0.5rem 0;
  }

  tbody tr th,
  td {
    border: none;
    text-align: left !important;
  }

  ${p => css`
    ${media.md`
      p, span, strong {
        font-size: ${p.firstdesc ? '1.375rem' : '1.125rem'} !important;
        line-height: ${p.firstdesc ? '2rem' : '1.75rem'};
      }
      table {
        width: 85% !important;
        margin-bottom: 1rem;
      }
      tbody tr th {
        line-height: 1.375rem;
      }
    `}
  `}
`
