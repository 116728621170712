import React, { Component } from 'react'
import TextInput from 'components/UI/TextInput'
import Checkbox from 'components/UI/Checkbox'
import ErrorLabel from 'components/UI/ErrorLabel'
import Button from 'components/UI/Button'
import Dropdown, { Option } from 'components/UI/Dropdown'
import { Form, WrapCheckboxBlock, ErrorBlock, WrapInputs } from './styles'

const allEventOption = { label: 'All events', value: 'all' }

const formatOptions = [
  { value: 'xlsx', label: 'xlsx' },
  { value: 'csv', label: 'csv' }
]

interface Props {
  onSubmit?: any
  supportEvents: Array<{ label: string; value: string }>
}

interface ITypesRecipe {
  followers: boolean
  supporters: boolean
  raised: boolean
}

export interface IExportForm {
  types: ITypesRecipe
  events: Option
  format: Option
}
interface IState extends IExportForm {
  errors: any
}

const initialState: IState = {
  types: {
    followers: true,
    supporters: true,
    raised: true
  },
  events: allEventOption,
  format: formatOptions[0],
  errors: {}
}

class ExportForm extends Component<Props, IState> {
  constructor(props: Props) {
    super(props)

    this.state = initialState
  }

  handleChange = (name: string, value: string) => {
    this.setState((prevState: IState) => ({
      ...prevState,
      [name]: value,
      errors: {
        ...prevState.errors,
        [name]: ''
      }
    }))
  }

  handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target
    this.setState((prevState: IState) => ({
      ...prevState,
      types: {
        ...prevState.types,
        [name]: checked
      },
      errors: {
        ...prevState.errors,
        [name]: ''
      }
    }))
  }

  sendMessage = async (e: any) => {
    e.preventDefault()
    const { onSubmit } = this.props
    const { errors, ...form } = this.state

    await onSubmit(form)
    // this.setState({ ...initialState })
  }

  render() {
    const { supportEvents } = this.props
    const {
      types: { followers, supporters, raised },
      format,
      events,
      errors,
      types
    } = this.state

    const isDisabled = (Object.keys(types) as Array<keyof ITypesRecipe>).every(
      t => !types[t]
    )
    const eventsOptions = [allEventOption, ...supportEvents]

    return (
      <Form onSubmit={this.sendMessage}>
        <h3>Export</h3>
        <WrapCheckboxBlock>
          <Checkbox
            variant={'dark'}
            name={'followers'}
            checked={!!followers}
            label={'Followers'}
            handleChange={this.handleChangeCheckbox}
          />
          <Checkbox
            variant={'dark'}
            name={'supporters'}
            checked={!!supporters}
            label={'Supporters'}
            handleChange={this.handleChangeCheckbox}
          />
          <Checkbox
            variant={'dark'}
            name={'raised'}
            checked={!!raised}
            label={'Amount Raised'}
            handleChange={this.handleChangeCheckbox}
          />
          <ErrorBlock>
            {errors.channel ? <ErrorLabel label={errors.channel} /> : ''}
          </ErrorBlock>
        </WrapCheckboxBlock>
        <WrapInputs>
          <Dropdown
            label={'Event'}
            name={'events'}
            options={eventsOptions}
            isReadOnly={true}
            value={events}
            errorLabel={errors.events || ''}
            placeholder={'Event'}
            hideControl={true}
            onSelect={this.handleChange}
          />
          <Dropdown
            label={'Format'}
            name={'format'}
            options={formatOptions}
            value={format}
            placeholder={'Format'}
            hideControl={true}
            onSelect={this.handleChange}
          />
          <TextInput
            variant={'dark'}
            name={'destination'}
            label={'Destination'}
            value={'Email'}
            readOnly={true}
          />
        </WrapInputs>

        <Button type={'submit'} disabled={isDisabled}>
          Export data
        </Button>
      </Form>
    )
  }
}

export default ExportForm

export { ExportForm }
