import React, { FC, MouseEvent, ChangeEvent, useState } from 'react'
import Button from 'components/UI/Button'
import ErrorLabel from 'components/UI/ErrorLabel'

import { Form, Loading } from './styles'

interface Props {
  post: (content: string) => void
  isSending: boolean
  error: object
  clearErrors: () => void
}

const CommentField: FC<Props> = props => {
  const { isSending, error } = props

  const [text, setText] = useState('')

  const changeText = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    const { clearErrors } = props
    const { value } = e.currentTarget

    setText(value)

    if (error) {
      clearErrors()
    }
  }

  const handlePost = (event: MouseEvent) => {
    event.preventDefault()

    const { post } = props

    post(text)
    setText('')
  }

  return (
    <Form>
      <div>
        <textarea
          placeholder={'Your comment'}
          value={text}
          onChange={changeText}
        />
        {error && (
          <ErrorLabel label={'An error occurred during comment submission'} />
        )}
      </div>
      <Button
        type={'submit'}
        onClick={handlePost}
        disabled={!text.trim() || isSending}
      >
        {!isSending ? (
          'Post'
        ) : (
          <Loading>
            <span>&bull;</span>
            <span>&bull;</span>
            <span>&bull;</span>
          </Loading>
        )}
      </Button>
    </Form>
  )
}

export default CommentField
