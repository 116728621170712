import styled from 'styled-components'
import Img from 'components/UI/Img'
import { media } from 'styled-bootstrap-grid'

export const Title = styled.div`
  color: #ffffff;
  text-align: left;
  font-size: 1.875rem;
  line-height: 2.25rem;
  margin-bottom: 1rem;
  font-weight: 900;
  font-family: Inter;
  ${media.md`
    font-size: 4.688rem;
    line-height: 5rem;
    margin-bottom: 0.625rem;
  `}
`

export const Name = styled.h1`
  font-family: Inter;
  font-size: 1.875rem;
  font-weight: 900;
  line-height: 2.25rem;
  margin-bottom: 0.125rem;
  max-width: 549px;
  color: ${p => p.theme.colors.white};

  ${media.md`
    font-size: 4.688rem;
    line-height: 5rem;
    margin-bottom: 0.438rem;
  `}
`

export const Description = styled.p`
  max-height: 80px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${p => p.theme.colors.white};
  font-family: Inter !important;
  font-weight: 400 !important;
  font-size: 1.063rem !important;
  line-height: 1.625rem !important;
  margin-top: 16px;
  margin-bottom: 26px;
  ${media.lg`
    font-weight: 300 !important;
    font-size: 1.625rem !important;
    line-height: 2.375rem !important;
    max-width: 90% !important;
    margin-top: 16px;
    margin-bottom: 60px;
  `}
  * {
    color: ${p => p.theme.colors.white};
    font-family: Inter !important;
    font-weight: 400 !important;
    font-size: 1.063rem !important;
    line-height: 1.625rem !important;
    ${media.lg`
    font-weight: 300 !important;
    font-size: 1.625rem !important;
    line-height: 2.375rem !important;
    max-width: 90% !important;
  `}
  }
`

export const ImgEvent = styled(Img)`
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: -1;
`

export const InfluencerCard = styled.div<{ img?: string }>`
  width: 100%;
  min-height: 90vh;
  position: relative;
  margin: 0 auto;
  z-index: -1;
  ::before {
    content: '';
    display: block;
    position: absolute;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(8, 9, 18, 1) 90%
    );
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
  }

  ::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -149px;
    width: 100%;
    height: 150px;
    background-color: rgba(8, 9, 18, 1);
    z-index: 2;

    ${media.lg`
    bottom: -199px;
    height: 200px;
  `}
  }

  img {
    z-index: 1;
  }
  @media screen and (max-width: 345px) {
    min-height: 100vh;
  }
  ${media.lg`
    min-height: 80vh;
  `};
`

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

export const CardInfo = styled.div`
  display: flex;
  flex-direction: column-reverse;
  ${media.md`
    flex-direction: row;
   justify-content: space-between;
  `};
`

export const TitleWrapper = styled.div`
  width: 100%;
  ${media.md`
    width: 71%;
  `};
`

export const InfluencerDescriptionColumn = styled.p`
  font-family: Inter;
  font-size: 0.9375rem;
  font-weight: 300;
  line-height: 1.75rem;
  margin-top: 26px;
  max-height: calc(100vh - 300px);
  overflow-y: hidden;

  p {
    height: 100%;
    padding-bottom: 50px;
    overflow-y: auto;
    font-size: 1rem;
    
    ${media.lg`
        height: auto;
        font-size: 1.125rem;
  `}
    ::-webkit-scrollbar {
      width: 2px;
      background-color: transparent;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 30px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  // ${media.md`
  //   height: 50vh;
  // `}
  ${media.lg`
    font-size: 1.125rem;
    line-height: 1.75rem;
    max-height: unset;
    overflow: hidden;
    
    columns: 3;
    column-width: 240px;
    column-gap: 50px;
  `}
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 1500px;
  margin: 0 auto;
  padding-top: 100px;
  position: absolute;
  top: 65px;
  bottom: 15px;
  right: 15px;
  left: 15px;
  z-index: 2;
  // ${media.md`
  //   top: 3vw;
  //   bottom: 3vw;
  //   right: 3vw;
  //   left: 3vw;
  // `}
`
export const FallbackImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
`

// ***** VIDEO ***** //

export const PlayButton = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: none;
  letter-spacing: 2px;
  color: ${p => p.theme.colors.white};
  font-family: Inter;
  font-size: 0.8125rem;
  text-transform: uppercase;
  text-align: left;
  margin-bottom: 23px;
  max-width: 110px;
  svg {
    margin-right: 10px;
    pointer-events: none;
  }
  ${media.md`
  margin-bottom: 31px;
  max-width: unset;
  font-size: 0.875rem;
     svg {
    margin-right: 12px;
  }
  `}
`

export const VideoPreview = styled.div<{ img: string }>`
  width: 100%;
  height: 100vh;
  background: url(${p => p.img}),
    linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 1) 90%);
  background-blend-mode: overlay;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
`

export const PlayerWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 12rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Player = styled.video<{ isLoading: boolean }>`
  width: 100%;
  height: 100%;
  display: ${p => (p.isLoading ? 'none' : 'block')};
  outline: none;
  z-index: 3;
`
