import styled, { css } from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import Button from 'src/components/UI/Button'

const activeState = css`
  background-color: rgb(38, 46, 89);
`

const isCentred = css`
  display: block;
  text-align: center;
`

export const ListItem = styled.li`
  background-color: ${p => p.theme.colors.blue};
  background-clip: padding-box;
  :not(:last-child) {
    border-bottom: 1px solid transparent;
  }

  ${media.md`
    :not(:last-child) {
      border-bottom: none;
      border-right: 1px solid transparent;
    }
  `}
`

export const List = styled.ul<{ count?: number }>`
  width: 100%;
  list-style: none;
  border-radius: 0.75rem;
  overflow: hidden;

  ${p => css`
    ${media.md`
      margin: 1rem 0;
      display: flex;
      
      ${ListItem} {
        flex: 1 0 ${p.count ? `calc(100%/${p.count})` : 'auto'};
      } 
    `}
  `}
`

export const Option = styled(Button)<{ isActive: boolean; centred?: boolean }>`
  width: 100%;
  padding: 0.75rem 1.25rem;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1.75rem;
  border-radius: 0;
  ${p => p.centred && isCentred}
  ${p => (p.isActive ? activeState : '')}

  :active,
  :focus {
    ${() => activeState}
  }

  ${media.md`
    height: 100%;
    padding: 0.875rem 1.125rem;
  `}
`
