import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export const WrapBlock = styled.div`
  .offers-container {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
    @media (min-width: 1346px) {
      max-width: 1465px;
    }
  }
`
export const Heading = styled.h2`
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 2.5rem;
  font-size: calc(2.16vw + 1rem);

  ${media.xl`
    font-size: 2.8rem;
  `}
`
export const Wrapper = styled.div`
  margin: 0 auto;
`

export const InvestmentList = styled.section``

export const LoaderWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  z-index: 1000;
  background: black;

  display: flex;
  align-items: center;
  justify-content: center;
`
