import React, { ReactNode, FC, useEffect } from 'react'
import styled from 'styled-components'
import Header from 'components/content/Header'

interface TemplateProps {
  children: ReactNode
  background?: string
}

const CommonTemplate: FC<TemplateProps> = ({ children, background }) => {
  useEffect(() => {
    window.scrollTo(0, 0)
    // eslint-disable-next-line
  }, [])

  return (
    <div className="wrapper">
      <Content background={background} className="content">
        <Header />
        <Page>{children}</Page>
      </Content>
    </div>
  )
}

const Page = styled.div`
  width: 100%;
  z-index: 1;
`
const Content = styled.div<{
  background?: string
}>`
  ${p => p.background && `background: ${p.background};`}
`
export default CommonTemplate
