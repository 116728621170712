import React, { FC } from 'react'
import sections from './sections'
import { FAQSpoiler } from 'components/invest/sections'
import {
  FAQList,
  SectionWrapper
} from 'containers/invest/InvestDetailPage/styles'

const FAQBlock: FC<{ i: number }> = ({ i }) => (
  <SectionWrapper id={'faq'}>
    <FAQList>
      {sections[i].subsections.map(item => (
        <FAQSpoiler item={item} key={item.question} />
      ))}
    </FAQList>
  </SectionWrapper>
)

export default FAQBlock
