import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectAddressInfo,
  selectEntityAddressInfo,
  selectIdInfo,
  selectPaymentMethod,
  selectPersonInfo
} from 'redux/investment/selectors'
import {
  investPaymentACH,
  investPaymentCreditCard,
  investPaymentWire,
  sendEntityInfo,
  sendEntityPhoto,
  sendIRAInfo,
  sendPersonalInfo,
  sendTrustInfo,
  sendVerificationInfo
} from 'redux/investment/operations'
import {
  EntityType,
  IACHBank,
  ICompany,
  ICreditCard,
  IIRA,
  IPaymentMethods,
  ITrust
} from 'redux/investment/types'
import { useInvestNowContext } from 'src/pages/investment/InvestNowPage/context'

const withNorthCapitalRequest = (WrappedComponent: any) => {
  const WithNorthCapitalRequest: FC<{
    step?: number
    [key: string]: any
  }> = props => {
    const dispatch = useDispatch()
    const paymentMethod = useSelector(selectPaymentMethod)
    const { entity_id } = useSelector(selectIdInfo)
    const address = useSelector(selectAddressInfo)
    const personInfo = useSelector(selectPersonInfo)
    const entityAddress = useSelector(selectEntityAddressInfo)
    const [isPaymentProcessing, setIsPaymentProcessing] = useState<boolean>(
      false
    )
    const [isPaymentSuccess, setIsPaymentSuccess] = useState<boolean>(false)
    const {
      selectedPaymentState,
      selectedContactForm,
      verificationInfo,
      selectedEntityInfo,
      selectedEntity,
      selectedEntityContactInfo,
      selectedEntityDocument,
      tierId,
      amount,
      selectedAnnualIncome: { annual_income, net_worth },
      IP
    } = useInvestNowContext()

    const FArequest = async () => {
      let personData = {}
      let entityData

      try {
        setIsPaymentProcessing(true)
        setIsPaymentSuccess(false)

        // PERSON INFORMATION //

        const {
          street,
          additional_address,
          city,
          state,
          postal_code,
          country
        } = address

        const {
          first_name,
          last_name,
          email,
          phone_code,
          phone
        } = selectedContactForm

        const { date_of_birth, ssn, citizenUSA } = verificationInfo
        const personChangedId = personInfo.id
          ? { changed_id: personInfo.id }
          : {}
        const taxId = citizenUSA ? ssn : '999999999'
        const personalInfo = {
          tax_id_number: taxId ? String(taxId).replace(/[^0-9.]/g, '') : '',
          phone: String(phone_code + phone).replace(/[^0-9.]/g, '') || '',
          last_name,
          email,
          first_name,
          date_of_birth,
          annual_income:
            (annual_income && annual_income.replace(/[^0-9]/g, '')) || 0,
          net_worth: (net_worth && net_worth.replace(/[^0-9]/g, '')) || 0,
          userAddress: {
            address: street,
            additional_address,
            postal_code,
            city,
            state: state.replace(/'/g, ''),
            country
          },
          ...personChangedId
        }

        try {
          personData = await dispatch(sendPersonalInfo(personalInfo))
        } catch (e) {
          console.error('Please, try again')
        }

        if (!citizenUSA && verificationInfo.passport) {
          for (const passport of verificationInfo.passport) {
            dispatch(
              sendEntityPhoto({
                doc: passport,
                type: 'user',
                // @ts-ignore
                entity_id: personData.id || ''
              })
            )
          }
        }

        // ENTITY CONTACT INFORMATION //

        const contactPerson = {
          full_name:
            selectedEntityContactInfo.first_name +
            ' ' +
            selectedEntityContactInfo.last_name,
          email: selectedEntityContactInfo.email,
          phone:
            String(
              selectedEntityContactInfo.phone_code +
                selectedEntityContactInfo.phone
            ).replace(/[^0-9.]/g, '') || ''
        }

        const contactAddress = {
          address: entityAddress.street,
          additional_address: entityAddress.additional_address,
          city: entityAddress.city,
          state: entityAddress.state.replace(/'/g, ''),
          postal_code: entityAddress.postal_code,
          country: entityAddress.country
        }

        switch (selectedEntity) {
          case EntityType.ENTITY:
            const entity = selectedEntityInfo as ICompany
            const companyChangedId = entity.changed_id
              ? { changed_id: entity.changed_id }
              : {}
            const entityInfo = {
              // @ts-ignore
              person_id: personData.investor_id || '',
              annual_income: personalInfo.annual_income,
              net_worth: personalInfo.net_worth,
              entity_name: entity.entity_name,
              entity_tax_id: entity.entity_tax_id.replace('-', '') || '',
              state: entity.state ? entity.state.replace(/'/g, '') : '',
              entityAddress: {
                ...contactAddress
              },
              contact: {
                ...contactPerson
              },
              ...companyChangedId
            }
            // @ts-ignore
            entityData = await dispatch(sendEntityInfo(entityInfo))
            break
          case EntityType.TRUST:
            const selectedTrust = selectedEntityInfo as ITrust
            const { trust_name, trust_tax_id } = selectedTrust
            const trustChangedId = entity_id ? { changed_id: entity_id } : {}
            const trustInfo = {
              annual_income: personalInfo.annual_income || '',
              net_worth: personalInfo.net_worth || '',
              // @ts-ignore
              person_id: personData.investor_id || '',
              trust_name,
              trust_tax_id,
              state: entityAddress.state.replace(/'/g, ''),
              trustAddress: {
                ...contactAddress
              },
              custodian: {
                ...contactPerson
              },
              ...trustChangedId
            }
            entityData = await dispatch(sendTrustInfo(trustInfo))
            break
          case EntityType.IRA:
            const IRA = selectedEntityInfo as IIRA
            const IRAChangedId = IRA.id ? { changed_id: IRA.id } : {}
            const IRAInfo = {
              // @ts-ignore
              person_id: personData.investor_id || '',
              annual_income: personalInfo.annual_income,
              net_worth: personalInfo.net_worth,
              vesting_name: IRA.vesting_name,
              ira_tax_id: IRA.ira_tax_id,
              state: entityAddress.state.replace(/'/g, ''),
              iraAddress: { ...contactAddress },
              contact: { ...contactPerson },
              ...IRAChangedId
            }

            entityData = await dispatch(sendIRAInfo(IRAInfo))
            break
        }

        if (
          ![EntityType.INDIVIDUAL, EntityType.IRA].includes(selectedEntity) &&
          selectedEntityDocument.entity_document
        ) {
          await dispatch(
            sendEntityPhoto({
              doc: selectedEntityDocument.entity_document,
              // @ts-ignore
              entity_id: entityData.id || ''
            })
          )
        }

        dispatch(
          sendVerificationInfo({
            date_of_birth: personalInfo.date_of_birth,
            // @ts-ignore
            entity_id: personData.id || ''
          })
        )

        // PAYMENT //

        const investmentData = {
          tier_id: String(tierId),
          amount: String(amount),
          ip_address: IP,
          // prettier-ignore
          // @ts-ignore
          nc_account_id: EntityType.INDIVIDUAL === selectedEntity ? personData.id : entityData.id
        }

        switch (paymentMethod) {
          case IPaymentMethods.CARD:
            const creditCard = selectedPaymentState as ICreditCard
            const [expMonth, expYear] = creditCard.expiration_date
              ? creditCard.expiration_date.split('/')
              : ['', '']
            const creditCardInfo = {
              credit_card_number: creditCard.card_number,
              credit_card_exp_month: expMonth,
              credit_card_exp_year: expYear.slice(-2),
              credit_card_cvv: creditCard.cvv,
              billing_name: creditCard.cardholder
            }

            await dispatch(
              investPaymentCreditCard({
                ...creditCardInfo,
                ...investmentData
              })
            )
            break
          case IPaymentMethods.US_BANK:
            const ACHData = selectedPaymentState as IACHBank

            await dispatch(
              investPaymentACH({
                ...investmentData,
                routing_number: ACHData.routing_number,
                account_number: ACHData.account_number,
                name_on_account: ACHData.name_on_account.trim()
              })
            )
            break
          case IPaymentMethods.WIRE_TRANSFER:
            await dispatch(investPaymentWire(investmentData))
        }
        setIsPaymentProcessing(false)
        setIsPaymentSuccess(true)
      } catch (e) {
        setIsPaymentProcessing(false)
        setIsPaymentSuccess(false)
        console.error('Please, try again')
      } finally {
        setIsPaymentProcessing(false)
      }
    }

    return (
      <WrappedComponent
        {...props}
        paymentSuccess={isPaymentSuccess}
        paymentProcessing={isPaymentProcessing}
        request={FArequest}
      />
    )
  }
  return WithNorthCapitalRequest
}

export default withNorthCapitalRequest
