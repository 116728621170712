import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export const ShoppingCartWrapper = styled.div`
  position: absolute;
  top: 132%;
  background-color: ${p => p.theme.colors.darkenGold};
  width: 504px;
  right: 0;
  cursor: default;
  box-shadow: 20px 20px 130px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  animation: appear 0.1s;

  @keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  ${media.xs`
    position: fixed;
    top: 73px;
    right: 0;
    left: 0;
    width: 100vw;
  `}
  @media (max-height: 550px) {
    top: 143%;
  }
`

export const ShoppingCartHeader = styled.div`
  padding: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #9d7d50;

  h2 {
    font-size: 35px;
    line-height: 42px;
    color: ${p => p.theme.colors.black};
  }

  ${media.xs`
    padding: 4vw 3vw;

    h2 {
      padding-top: 5px;
    }
  `}
`

export const ShoppingCartBody = styled.div<{ isEmpty: boolean }>`
  max-height: 48vh;
  overflow-y: auto;
  width: 100%;
  height: ${p => (p.isEmpty ? '48vh' : undefined)};
  display: ${p => (p.isEmpty ? 'flex' : 'block')};
  flex-direction: ${p => (p.isEmpty ? 'row' : 'column')};
  justify-content: center;
  align-items: center;
  scrollbar-color: #9d7d50 #d4ab73;
  scrollbar-width: thin;

  ${media.xs`
    // max-height: calc(100vh - 273px);
    max-height: calc(100vh - 48vh);
    height: 100%;
  `}
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #d4ab73;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #b19062;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #9d7d50;
  }
  @media (max-height: 550px) {
    max-height: 25vh;
  }
`

export const ShoppingCartFooter = styled.div<{ isEmpty: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 6vw 3vw 3vw;
  ${p => !p.isEmpty && 'border-top: 1px solid #9d7d50'};

  ${media.md`
    padding: 45px 25px 25px;
  `}
`

export const Subtotal = styled.div`
  text-align: end;
  font-size: 1.25rem;
  line-height: 30px;
  font-weight: 600;
  padding-bottom: 0.625rem;

  span {
    font-family: 'Lato';
    font-size: 1.25rem;
    color: ${p => p.theme.colors.black};
  }
`

export const EmptyCartContent = styled.div`
  display: flex;
  flex-direction: column;

  h5 {
    text-align: center;
    color: ${p => p.theme.colors.black};
    font-size: 1.25rem;
    line-height: 24px;
    font-weight: 400;
    padding-top: 25px;
  }
  @media (max-height: 550px) {
    max-height: 25vh;
  }
`

export const IconInCircle = styled.div<{ width: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${p => p.width || '22.5px'};
  height: ${p => p.width || '22.5px'};
  border: 1.5px solid ${p => p.theme.colors.black};
  border-radius: 50%;
  transition: ease-out 0.1s;

  &:hover,
  &:active {
    background: rgba(157, 125, 80, 0.5);
  }
`
