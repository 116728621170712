import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

import Link from 'components/UI/Link'
import Img from 'components/UI/Img'

export const CardWrap = styled.div`
  margin-bottom: 4rem;
`
export const TopCard = styled.div`
  width: 100%;
  display: block;
  margin-bottom: 4rem;
  position: relative;

  ${media.lg`
    display: flex;
    margin-bottom: 2rem;
  `}
`

export const CardIconsWrapper = styled.div<{
  num?: number
  isSupported?: boolean
}>`
  opacity: 1;
  transform: matrix(1, 0, 0, 1, 0, 0);
  color: ${p => p.theme.colors.black};
  width: 100%;
  background-color: rgba(255, 255, 255, 0.63);
  pointer-events: none;
  padding: 8%;

  display: flex;
  align-items: center;
  justify-content: center;
  p {
    color: ${p => p.theme.colors.black};
    text-transform: uppercase;
    line-height: 1.3328;
    font-size: calc(0.64vw + 1rem);
    font-family: 'Acumin Pro';
    padding-top: 0.5rem;
    white-space: nowrap;
  }
  ${media.xl`
    p {
      font-size: 1.6rem;
    }
  `}
`
export const SupportersWrapper = styled(CardIconsWrapper)`
  padding-right: ${p => !p.isSupported && 0};
`
export const BottomCard = styled.div`
  height: 100%;
  display: flex;
  justify-content: end;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: flex-start;

  a {
    font-weight: normal;
  }
`
export const Description = styled.div`
  color: ${p => p.theme.colors.white};
  text-align: left;
  font-family: Inter;
  font-size: 1rem;
  margin-bottom: 2rem;
`
export const Name = styled.h3`
  color: ${p => p.theme.colors.white};
  font-size: calc(1.44vw + 1rem);
  font-family: 'Acumin Pro';
  width: 100%;
  white-space: pre-wrap;
  line-height: 1.3328;
  margin-bottom: 1.5rem;
  text-align: left;
  text-transform: uppercase;

  ${media.xl`
    font-size: 2.2rem;
  `}
`
export const Title = styled.p`
  font-size: 1rem;
  color: ${p => p.theme.colors.gold};
  font-family: 'Poppins', sans-serif;
  margin-bottom: 2rem;
  text-transform: uppercase;
  font-weight: bold;
  text-align: left;
`
export const ComingSoonText = styled.span`
  color: ${p => p.theme.colors.gold};
  font-size: calc(1.44vw + 0.875rem);
  text-transform: capitalize;
`
export const EventContent = styled.div`
  z-index: 2;
`

export const ImgEvent = styled(Img)`
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: 1;
`
export const Left = styled.div`
  position: relative;
  width: 90%;

  ${media.lg`
    width: 50%;
  `}
`
export const Right = styled.div`
  width: 60%;
  position: absolute;
  right: 0;
  top: 50%;
  z-index: 1;

  p {
    font-size: calc(0.94vw + 1rem);
    white-space: normal;
  }

  ${media.lg`
    width: 50%;
  `}
`
export const EventWrap = styled.div<{ img?: string }>`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 23.125rem;
  margin: 0 auto;
  box-shadow: 4px 7px 11px rgba(0, 0, 0, 0.36);
  overflow: hidden;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  ${media.lg`
    width: 140%;
    height: 600px;
  `}
`
export const FundWrapper = styled.div`
  padding: 0 1.125rem;
  width: 100%;
  position: absolute;
  top: -0.75rem;
  left: 0;
`

export const LinkWrapper = styled(Link)<{ disabled?: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;

  pointer-events: ${p => (p.disabled ? 'none' : undefined)};
`
