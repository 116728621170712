import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {
  forgotPassword,
  verifyRecovery,
  recoveryPassword
} from 'redux/auth/operations'
import { clearAuthErrors } from 'redux/auth/actions'
import { IBetablockParams, IConfirmPassword } from 'redux/auth/types'
import {
  getError,
  selectAuthErrors,
  selectIsFetchingLoggedIn,
  selectIsRegistered
} from 'redux/auth/selectors'

import Link from 'components/UI/Link'
import AuthWrapper from 'components/content/AuthWrapper'
import ForgotForm from 'components/auth/forgot/ForgotForm'
import Form from 'components/auth/AuthForm'
import EnterCodeForm from 'components/auth/EnterCodeForm'
import ConfirmPasswordForm from 'components/auth/forgot/ConfirmPasswordForm'
import { routePath, history } from 'routes'

import { TextWrapper } from '../SignUpPage/styles'
import { AppState, Dispatch } from 'store/types'
import { Redirect } from 'react-router-dom'

const breadCrumbs = ['Password Recovery', 'Enter Code', 'Create new password']

class ForgotPage extends Component<
  Props,
  { currentStep: number; email: string }
> {
  constructor(props: Props) {
    super(props)

    this.state = {
      currentStep: 0,
      email: ''
    }
  }

  componentWillUnmount(): void {
    this.props.clearAuthErrors()
  }

  nextStep = () => {
    this.setState(({ currentStep }) => ({
      currentStep: currentStep >= 1 ? breadCrumbs.length - 1 : currentStep + 1
    }))
  }

  onForgotEmail = async (email: string) => {
    const { forgotPassword } = this.props

    try {
      await forgotPassword(email)
      this.setState({ email })
      this.nextStep()
    } catch (err) {
      console.error('register fail')
    }
  }

  onVerifyEmail = async (code: string) => {
    const { verifyRecovery } = this.props
    const { email } = this.state

    try {
      await verifyRecovery(code, email)
      this.nextStep()
    } catch (err) {
      console.error('verifyRecovery fail')
    }
  }

  onRecoveryPassword = async (recoveryData: IConfirmPassword) => {
    const { recoveryPassword, location } = this.props
    const isBetaBlock = location.pathname === routePath.FORGOT_BETABLOCK

    try {
      await recoveryPassword(recoveryData)
      this.nextStep()
      history.push(
        isBetaBlock
          ? { pathname: routePath.SIGN_IN_BETABLOCK, search: location.search }
          : { pathname: routePath.SIGN_IN }
      )
    } catch (err) {
      console.error('onRecoveryPassword fail')
    }
  }

  onResendCode = () => {
    const { forgotPassword } = this.props
    const { email } = this.state

    forgotPassword(email)
  }

  renderFormElem = (step: number) => {
    const { registerErrors, clearAuthErrors, isFetchingVerify } = this.props

    switch (step) {
      case 0:
        return (
          <ForgotForm
            onSubmit={this.onForgotEmail}
            clearErrors={clearAuthErrors}
            serverErrors={registerErrors}
            isFetching={isFetchingVerify}
          />
        )
      case 1:
        return (
          <EnterCodeForm
            onSubmit={this.onVerifyEmail}
            resend={this.onResendCode}
            isFetching={isFetchingVerify}
            serverErrors={registerErrors}
            clearErrors={clearAuthErrors}
          />
        )
      case 2:
        return (
          <ConfirmPasswordForm
            onSubmit={this.onRecoveryPassword}
            serverErrors={registerErrors}
            clearErrors={clearAuthErrors}
            isFetching={isFetchingVerify}
          />
        )
      default:
        return null
    }
  }

  render() {
    const { currentStep } = this.state
    const { location } = this.props
    const isBetaBlock = location.pathname === routePath.FORGOT_BETABLOCK

    if (isBetaBlock && !location.search) {
      return <Redirect to={routePath.INVEST_PAGE} />
    }

    return (
      <AuthWrapper>
        <Form
          isActive={currentStep !== 1}
          title={breadCrumbs[currentStep]}
          content={this.renderFormElem(currentStep)}
          footer={
            <TextWrapper>
              <p>Do you remember your password?</p>
              <Link
                to={
                  isBetaBlock
                    ? {
                        pathname: routePath.SIGN_IN_BETABLOCK,
                        search: location.search
                      }
                    : { pathname: routePath.SIGN_IN }
                }
                variant={'clear'}
              >
                Log in
              </Link>
            </TextWrapper>
          }
        />
      </AuthWrapper>
    )
  }
}

interface LocationState {
  state: IBetablockParams
  search: string
  pathname: string
}

interface ReceivedProps {
  location: LocationState
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & ReceivedProps

const mapStateToProps = (state: AppState) => ({
  registerErrors: getError(selectAuthErrors(state)),
  isRegistered: selectIsRegistered(state),
  isFetchingVerify: selectIsFetchingLoggedIn(state)
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      forgotPassword,
      verifyRecovery,
      recoveryPassword,
      clearAuthErrors
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPage)
