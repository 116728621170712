import React, { FC } from 'react'
import { Col, Container, Row } from 'styled-bootstrap-grid'
import { useSelector } from 'react-redux'
import { IEventsTab } from 'redux/events/types'
import { selectIsAuth } from 'redux/auth/selectors'
import Link from 'components/UI/Link'
import { mainLinks, configGeneralLinks } from './links'

import { GeneralLinks, MainLinks, MainPageLink, MenuWrapper } from './styles'

interface Props {
  hideMenu: () => void
  eventsCount: IEventsTab[]
}

const Menu: FC<Props> = ({ hideMenu, eventsCount }) => {
  const isAuthenticated = useSelector(selectIsAuth)
  const generalLinks = configGeneralLinks(isAuthenticated)

  return (
    <MenuWrapper>
      <Container>
        <Row>
          <Col md={4}>
            <MainLinks>
              {mainLinks.map(({ url, title, type }) => {
                const isEventTypeLink = eventsCount.find(i => i.type === type)

                if (!type || (isEventTypeLink && isEventTypeLink.count)) {
                  return (
                    <li key={title}>
                      <MainPageLink
                        to={url}
                        target={'_self'}
                        onClick={hideMenu}
                        exact={true}
                        dangerouslySetInnerHTML={{ __html: title }}
                      />
                    </li>
                  )
                }
                return null
              })}
            </MainLinks>
          </Col>

          <Col md={8}>
            <GeneralLinks>
              {generalLinks.map(({ sectionName, links, hidden }) => {
                if (hidden) {
                  return null
                }
                return (
                  <div key={sectionName} id={sectionName.toLowerCase()}>
                    <h3>{sectionName}</h3>
                    <ul>
                      {links.map(({ title, url }) => {
                        return (
                          <li key={title}>
                            <Link to={url} key={title}>
                              {title}
                            </Link>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                )
              })}
            </GeneralLinks>
          </Col>
        </Row>
      </Container>
    </MenuWrapper>
  )
}

export default Menu
