import React from 'react'

import { PlusIcon } from './PlusIcon'
import { MinusIcon } from './MinusIcon'
import {
  ItemsCounterCircleButton,
  ItemsCounterValue,
  ItemsCounterWrapperSmall,
  ItemsCounterWrapperMedium
} from './styles'

export enum ItemsCounterSizes {
  small = 'small',
  medium = 'medium',
  large = 'large'
}

enum ItemsCounterVariants {
  light = 'light',
  dark = 'dark'
}

interface ItemsCounterProps {
  variant?: 'light' | 'dark'
  size?: 'small' | 'medium' | 'large'
  value: number
  disabled?: boolean

  onIncrease: () => void
  onDecrease: () => void
}
export const ItemsCounter: React.FC<ItemsCounterProps> = ({
  variant = ItemsCounterVariants.dark,
  size = ItemsCounterSizes.small,
  value,
  disabled,
  onIncrease,
  onDecrease
}): React.ReactElement => {
  const sizeInPixels = {
    circle: {
      [ItemsCounterSizes.small]: 22.5,
      [ItemsCounterSizes.medium]: 33,
      [ItemsCounterSizes.large]: 40
    },
    totalWidth: {
      [ItemsCounterSizes.small]: 85,
      [ItemsCounterSizes.medium]: 130,
      [ItemsCounterSizes.large]: 155
    }
  }
  const isLight = variant === ItemsCounterVariants.light

  const component = {
    [ItemsCounterSizes.small]: ItemsCounterWrapperSmall,
    [ItemsCounterSizes.medium]: ItemsCounterWrapperMedium
  }

  const handleOnIncrease = () => !disabled && onIncrease()
  const handleOnDecrease = () => !disabled && onDecrease()

  // @ts-ignore
  const Component = component[size]

  return (
    <Component width={sizeInPixels.totalWidth[size]}>
      <ItemsCounterCircleButton
        onClick={handleOnDecrease}
        width={sizeInPixels.circle[size]}
        isLight={isLight}
      >
        <MinusIcon color={isLight ? '#B19062' : '#171716'} />
      </ItemsCounterCircleButton>
      <ItemsCounterValue isLight={isLight}>{value}</ItemsCounterValue>
      <ItemsCounterCircleButton
        onClick={handleOnIncrease}
        width={sizeInPixels.circle[size]}
        isLight={isLight}
      >
        <PlusIcon color={isLight ? '#B19062' : '#171716'} />
      </ItemsCounterCircleButton>
    </Component>
  )
}
