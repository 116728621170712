import React, { FC } from 'react'
import CheckIcon from 'src/components/icons/CheckIcon'
import {
  SwitchCheckbox,
  Switcher,
  SwitchInner,
  SwitchLabel,
  SwitchWrapper
} from './styles'

interface SwitchProps {
  name: string
  selected: boolean
  onSelect: () => void
}

const Switch: FC<SwitchProps> = ({ selected, onSelect, name }) => {
  return (
    <SwitchWrapper>
      <SwitchCheckbox
        checked={selected}
        onChange={onSelect}
        id={`switch-${name}`}
      />
      <SwitchLabel htmlFor={`switch-${name}`}>
        <SwitchInner />
        {selected && <CheckIcon />}
        <Switcher />
      </SwitchLabel>
    </SwitchWrapper>
  )
}

export default Switch
