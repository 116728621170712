import axios from 'axios'
import {
  ICreditCardToken,
  IEntityInfo,
  IEntityInfoResp,
  IIRAInfo,
  IIRAInfoResp,
  IPayment,
  IPaymentACH,
  IPersonalInfo,
  IPersonalInfoResp,
  IProhibitedCountry,
  IProhibitedCountryResp,
  ISubscriptionAgreement,
  ISubscriptionAgreementResp,
  ITierAmountDetails,
  ITrustInfo,
  ITrustInfoResp,
  IVerificationInfo
} from 'redux/investment/types'
import { IStatus } from 'redux/commonTypes'

export const InvestmentApi = {
  getTierDetails: async (tierId: number | string) =>
    await axios.get<GetTierAmountDetails>(`investments/tiers/${tierId}`),

  postPersonalInfo: async (data: IPersonalInfo) =>
    await axios.post<any>('/investments/personal', data),

  getPersonalInfo: async () =>
    await axios.get<IPersonalInfoResp>('/investments/personal'),

  checkProhibitedCountryForInvesting: async (params: IProhibitedCountry) =>
    await axios.post<IProhibitedCountryResp>(
      '/investments/country/check',
      params
    ),

  postVerificationInfo: async (data: IVerificationInfo) =>
    await axios.post<IStatus>('/investments/verification', data),

  postTrustInfo: async (data: ITrustInfo) =>
    await axios.post<ITrustInfoResp>('/investments/trust', data),

  getTrustInfo: async () =>
    await axios.get<ITrustInfoResp>('/investments/trust'),

  postEntityInfo: async (data: IEntityInfo) =>
    await axios.post<any>('/investments/company', data),

  getEntityInfo: async () => {
    const data = await axios.get<IEntityInfoResp[]>('/investments/company')
    // return await axios.get<GetEntityInfo>('/investments/company'),
    return { data }
  },
  getEntityInfoDetails: async (id: string) =>
    await axios.get(`/investments/company/${id}`),

  postIRAInfo: async (data: IIRAInfo) =>
    await axios.post<IIRAInfoResp>('/investments/ira', data),

  getIRAInfo: async () => await axios.get<IIRAInfoResp>('/investments/ira'),

  createSubscriptionAgreement: async (data: ISubscriptionAgreement) =>
    await axios.post<ISubscriptionAgreementResp>(
      '/investments/subscription_agreement',
      data
    ),

  sendEntityPhoto: async (data: {
    doc: File
    entity_id: string
    type?: string
  }) => {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
    const formData = new FormData()
    formData.append('doc', data.doc)
    formData.append('entity_id', data.entity_id)

    if (data.type === 'user') {
      formData.append('type', data.type)
    }

    return await axios.post<any>(
      '/investments/entity_documents',
      formData,
      config
    )
  },

  investPaymentCreditCard: async (data: ICreditCardToken) =>
    await axios.post('/investments/payments/card', data),

  investPaymentACH: async (data: IPaymentACH) =>
    await axios.post<IPaymentACH>('/investments/payments/ach', data),

  investPaymentWireTransfer: async (data: IPayment) =>
    await axios.post<IPayment>('/investments/payments/wire', data)
}

interface GetTierAmountDetails {
  data: ITierAmountDetails
}
