import styled, { css } from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import Button from 'components/UI/Button'

export const Form = styled.form`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${media.md`
    padding: 3.75rem;
  `}

  ${media.lg`
    padding: 3.75rem 12.5rem;
  `}
`
export const WrapCheckboxBlock = styled.div`
  width: 100%;
  margin-bottom: 3rem;

  label {
    margin-top: 2rem;
  }
`

export const SubmitButoon = styled(Button).attrs(p => ({ variant: 'default' }))`
  padding: 1rem 3.125rem;

  ${media.xs`
    font-size: 1rem;
  `}
`

export const SendToBlock = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 2rem;
  flex-direction: column;
  position: relative;

  > div {
    display: flex;
    flex-direction: column;

    ${media.sm`
      flex-direction: row;
    `}
  }
`
export const ButtonsBlock = styled.div`
  display: flex;

  > div {
    position: initial;
    ${media.md`
      position: relative;
    `}
  }
`
export const BtnLabel = styled.div<{
  active: boolean
  isDisabled?: boolean
  isOpen?: boolean
}>`
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.5);
  min-width: 100px;
  padding: 10px 12px;
  color: #808080;
  font-size: 14px;
  text-align: center;
  margin: 0 5px;

  ${p =>
    p.active &&
    css`
      background-color: ${p.theme.colors.gold};
      color: #161616;
    `}

  ${p =>
    p.isOpen &&
    css`
      border: 1px solid ${p.theme.colors.gold};
      background-color: transparent;
      color: #ffffff;
    `}
`

export const DropBlock = styled.div`
  position: absolute;
  background: #010101;
  color: #ffffff;
  border-radius: 6px;
  padding: 20px;
  margin: 16px 0;
  left: 0;
  min-width: 100%;
  z-index: 5;

  ${media.md`
    margin: 16px 5px;
    min-width: 375px;
  `}
`
export const Title = styled.label`
  font-size: 16px;
  color: #ffffff;
  font-weight: 500;
  padding: 5px 16px 8px 0;
  white-space: nowrap;

  ${media.md`
    font-size: 22px;
    padding: 5px 16px 0 0;
  `}
  ${media.sm`
     padding: 8px 16px 0 0;
  `}
`
export const ErrorBlock = styled.div`
  flex-direction: column;

  label {
    margin-top: 0.4rem;
  }
`
export const RangeWrap = styled.div`
  border-bottom: 1px solid #3c3c3c;
`
