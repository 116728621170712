import React from 'react'

interface EmptyCartIconProps {
  width?: number
  height?: number
}
export const EmptyCartIcon: React.FC<EmptyCartIconProps> = ({
  width = 383,
  height = 276
}): React.ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 383 276"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M103.084 261.487C107.886 270.391 118.077 274.214 118.077 274.214C118.077 274.214 120.483 263.599 115.681 254.694C110.879 245.79 100.689 241.967 100.689 241.967C100.689 241.967 98.2829 252.582 103.084 261.487Z"
        fill="#171716"
      />
      <path
        d="M106.385 258.557C115.054 263.773 118.393 274.132 118.393 274.132C118.393 274.132 107.676 276.035 99.0075 270.82C90.3387 265.604 87 255.245 87 255.245C87 255.245 97.7163 253.342 106.385 258.557Z"
        fill="#D6A869"
      />
      <path
        d="M226.878 257.409H135.398V256.751H226.22V224.832H143.089L139.051 216.419L139.644 216.134L143.503 224.174H226.878V257.409Z"
        fill="#2F2E41"
      />
      <path
        d="M147.573 273.533C151.39 273.533 154.484 270.44 154.484 266.623C154.484 262.807 151.39 259.713 147.573 259.713C143.757 259.713 140.663 262.807 140.663 266.623C140.663 270.44 143.757 273.533 147.573 273.533Z"
        fill="#171716"
      />
      <path
        d="M215.69 273.533C219.506 273.533 222.6 270.44 222.6 266.623C222.6 262.807 219.506 259.713 215.69 259.713C211.873 259.713 208.779 262.807 208.779 266.623C208.779 270.44 211.873 273.533 215.69 273.533Z"
        fill="#171716"
      />
      <path
        d="M295.982 124.138C298.345 124.138 300.26 122.223 300.26 119.86C300.26 117.498 298.345 115.582 295.982 115.582C293.619 115.582 291.704 117.498 291.704 119.86C291.704 122.223 293.619 124.138 295.982 124.138Z"
        fill="#171716"
      />
      <path
        d="M236.662 213.973H133.839L111.59 141.249H259.575L259.441 141.677L236.662 213.973ZM134.325 213.315H236.18L258.678 141.908H112.479L134.325 213.315Z"
        fill="#2F2E41"
      />
      <path
        d="M228.636 210.188H138.322L118.78 145.692H248.761L248.643 146.071L228.636 210.188Z"
        fill="#D6A869"
      />
      <path
        d="M261.42 134.752L260.783 134.584L265.126 118.215H290.388V118.873H265.633L261.42 134.752Z"
        fill="#2F2E41"
      />
      <path
        d="M252.18 163.297H118.779V163.955H252.18V163.297Z"
        fill="#2F2E41"
      />
      <path
        d="M244.54 187.543H126.197V188.202H244.54V187.543Z"
        fill="#2F2E41"
      />
      <path
        d="M185.91 141.578H185.252V213.644H185.91V141.578Z"
        fill="#2F2E41"
      />
      <path
        d="M215.376 141.558L210.651 213.621L211.308 213.664L216.033 141.601L215.376 141.558Z"
        fill="#2F2E41"
      />
      <path
        d="M155.799 141.557L155.143 141.6L159.839 213.665L160.496 213.622L155.799 141.557Z"
        fill="#2F2E41"
      />
      <path d="M316 274.127H51V275.127H316V274.127Z" fill="#9D7D50" />
      <path
        d="M164.364 122.47H110.276V123.243H164.364V122.47Z"
        fill="#9D7D50"
      />
      <path
        d="M125.73 115.516H124.957V122.663H125.73V115.516Z"
        fill="#9D7D50"
      />
      <path
        d="M149.296 115.516H148.523V122.663H149.296V115.516Z"
        fill="#9D7D50"
      />
      <path
        d="M183.295 8.11316H129.207V8.88584H183.295V8.11316Z"
        fill="#9D7D50"
      />
      <path
        d="M168.614 8.69266H167.841V15.84H168.614V8.69266Z"
        fill="#9D7D50"
      />
      <path
        d="M145.047 8.69266H144.274V15.84H145.047V8.69266Z"
        fill="#9D7D50"
      />
      <path
        d="M237.768 84.2222H183.681V84.9948H237.768V84.2222Z"
        fill="#9D7D50"
      />
      <path d="M300.742 0H246.654V0.77268H300.742V0Z" fill="#9D7D50" />
      <path d="M54.0876 7.12738H0V7.90006H54.0876V7.12738Z" fill="#9D7D50" />
      <path d="M64.0876 151.127H10V151.9H64.0876V151.127Z" fill="#9D7D50" />
      <path
        d="M286.062 0.579529H285.289V7.72682H286.062V0.579529Z"
        fill="#9D7D50"
      />
      <path
        d="M39.4074 7.70691H38.6348V14.8542H39.4074V7.70691Z"
        fill="#9D7D50"
      />
      <path
        d="M49.4074 151.707H48.6348V158.854H49.4074V151.707Z"
        fill="#9D7D50"
      />
      <path
        d="M262.494 0.579529H261.722V7.72682H262.494V0.579529Z"
        fill="#9D7D50"
      />
      <path
        d="M15.8401 7.70691H15.0674V14.8542H15.8401V7.70691Z"
        fill="#9D7D50"
      />
      <path
        d="M25.8401 151.707H25.0674V158.854H25.8401V151.707Z"
        fill="#9D7D50"
      />
      <path
        d="M328.172 37.0887H274.084V37.8613H328.172V37.0887Z"
        fill="#9D7D50"
      />
      <path
        d="M81.5173 44.216H27.4297V44.9887H81.5173V44.216Z"
        fill="#9D7D50"
      />
      <path
        d="M91.5173 188.216H37.4297V188.989H91.5173V188.216Z"
        fill="#9D7D50"
      />
      <path d="M382.088 91.0815H328V91.8542H382.088V91.0815Z" fill="#9D7D50" />
      <path
        d="M289.538 30.1346H288.766V37.2818H289.538V30.1346Z"
        fill="#9D7D50"
      />
      <path
        d="M42.884 37.2619H42.1113V44.4092H42.884V37.2619Z"
        fill="#9D7D50"
      />
      <path
        d="M52.884 181.262H52.1113V188.409H52.884V181.262Z"
        fill="#9D7D50"
      />
      <path
        d="M343.454 84.1274H342.682V91.2747H343.454V84.1274Z"
        fill="#9D7D50"
      />
      <path
        d="M313.105 30.1346H312.332V37.2818H313.105V30.1346Z"
        fill="#9D7D50"
      />
      <path
        d="M66.4504 37.2619H65.6777V44.4092H66.4504V37.2619Z"
        fill="#9D7D50"
      />
      <path
        d="M76.4504 181.262H75.6777V188.409H76.4504V181.262Z"
        fill="#9D7D50"
      />
      <path
        d="M367.021 84.1274H366.248V91.2747H367.021V84.1274Z"
        fill="#9D7D50"
      />
      <path
        d="M302.287 109.721H248.199V110.493H302.287V109.721Z"
        fill="#9D7D50"
      />
      <path
        d="M263.654 102.766H262.881V109.914H263.654V102.766Z"
        fill="#9D7D50"
      />
      <path
        d="M287.22 102.766H286.447V109.914H287.22V102.766Z"
        fill="#9D7D50"
      />
      <path
        d="M319.287 202.829H265.199V203.601H319.287V202.829Z"
        fill="#9D7D50"
      />
      <path
        d="M280.652 195.875H279.879V203.022H280.652V195.875Z"
        fill="#9D7D50"
      />
      <path
        d="M304.219 195.875H303.446V203.022H304.219V195.875Z"
        fill="#9D7D50"
      />
      <path
        d="M223.087 84.8017H222.314V91.949H223.087V84.8017Z"
        fill="#9D7D50"
      />
      <path
        d="M199.521 84.8017H198.748V91.949H199.521V84.8017Z"
        fill="#9D7D50"
      />
      <path
        d="M145.82 83.8358H91.7324V84.6085H145.82V83.8358Z"
        fill="#9D7D50"
      />
      <path
        d="M131.139 84.4153H130.366V91.5626H131.139V84.4153Z"
        fill="#9D7D50"
      />
      <path
        d="M107.572 84.4153H106.799V91.5626H107.572V84.4153Z"
        fill="#9D7D50"
      />
      <path
        d="M210.724 45.2018H156.637V45.9745H210.724V45.2018Z"
        fill="#9D7D50"
      />
      <path
        d="M172.091 38.2477H171.318V45.395H172.091V38.2477Z"
        fill="#9D7D50"
      />
      <path
        d="M195.657 38.2477H194.885V45.395H195.657V38.2477Z"
        fill="#9D7D50"
      />
    </svg>
  )
}
