import React, { MouseEvent } from 'react'
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps
} from 'react-router-dom'
import { Button, ButtonProps, ButtonVariant } from 'src/components/UI/Button'
import { isUrl, isMailTo } from 'utils/helpers'

export type RouterLinkWithButtonProps = ButtonProps &
  RouterLinkProps & { target?: string }

const Link: React.FC<RouterLinkWithButtonProps> = props => {
  const { to, onClick = () => {}, variant = ButtonVariant.Clear } = props

  const onClickLink = (e: MouseEvent<HTMLButtonElement>): void => {
    if (to === '#') {
      e.preventDefault()
      e.nativeEvent.stopImmediatePropagation()
    }

    onClick(e)
  }

  const newProps = {
    as: isUrl(to) || isMailTo(to) ? 'a' : RouterLink,
    variant,
    ...props
  }
  if (typeof to === 'string' && (isMailTo(to) || isUrl(to))) {
    newProps.href = to
    delete newProps.to
  }

  return <Button {...newProps} onClick={onClickLink} />
}

export default Link

export { Link }
