import React, { FC } from 'react'
import { FootNote, FootNoteLink, FootNoteWrapper } from './styles'
import { EXTERNAL_LINKS, LINKS, WEBSITE } from 'utils/constants'
import FootnoteIcon from './images/FootnoteIcon'

const Footnote: FC = () => (
  <FootNoteWrapper>
    <FootNote>
      <FootnoteIcon />
    </FootNote>
    <FootNote>
      In making an investment decision, investors must rely on their own
      examination of the issuer and the terms of the offering, including the
      merits and risks involved. Investment opportunities posted on this website
      are speculative, illiquid, and involve a high degree of risk, including
      the possible loss of your entire investment. This site is operated by
      EdenLedger, Inc. (d/b/a “FanVestor”), which is a technology service
      provider.
    </FootNote>
    <FootNote>
      FanVestor does not offer investment advice, broker-dealer services, or
      recommendations with respect to any securities. All securities posted on
      this website are offered by, and all information included on this site is
      the responsibility of, the applicable issuer of such securities. FanVestor
      cannot verify the adequacy, accuracy or completeness of any information.
      Neither FanVestor nor any of its officers, directors, agents and employees
      makes any warranty, express or implied, of any kind whatsoever related to
      the adequacy, accuracy or completeness of any information on this site or
      the use of information on this site. By accessing this site and any pages
      thereof, you agree to be bound by the{' '}
      <FootNoteLink to={WEBSITE + LINKS.TERMS}>
        Terms&nbsp;of&nbsp;Use
      </FootNoteLink>{' '}
      and{' '}
      <FootNoteLink to={WEBSITE + LINKS.PRIVACY}>Privacy Policy</FootNoteLink>.
    </FootNote>
    <FootNote>
      All Regulation Crowdfunding (“Reg&nbsp;CF”) offerings are conducted by
      FanVestor&nbsp;CF&nbsp;LLC, a wholly-owned subsidiary of EdenLedger Inc.,
      and a funding portal registered with the US Securities and Exchange
      Commission (“SEC”) and member of the{' '}
      <FootNoteLink to={EXTERNAL_LINKS.FINRA}>
        {' '}
        Financial Industry Regulatory Authority{' '}
      </FootNoteLink>
      (“FINRA”).{' '}
      <FootNoteLink to={EXTERNAL_LINKS.FINRA_REGULATE}>
        {' '}
        FINRA’s Portal We Regulate
      </FootNoteLink>
      .
    </FootNote>
    <FootNote>
      All Regulation&nbsp;D and Regulation&nbsp;A+ broker-dealer related
      securities offerings and activities are conducted through the{' '}
      <FootNoteLink to={EXTERNAL_LINKS.DALMORE}>
        Dalmore&nbsp;Group&nbsp;LLC
      </FootNoteLink>
      , a broker-dealer registered with the SEC and member{' '}
      <FootNoteLink to={EXTERNAL_LINKS.FINRA}>FINRA</FootNoteLink>
      &nbsp;/&nbsp;
      <FootNoteLink to={EXTERNAL_LINKS.SPIC}>SiPC</FootNoteLink>. Office of
      Supervisory Jurisdiction 525 Green Place Woodmere,&nbsp;NY11598.{' '}
      <FootNoteLink to={EXTERNAL_LINKS.BROKER_CHECK}>
        FINRA&nbsp;’s&nbsp;BrokerCheck
      </FootNoteLink>
      . FanVestor CF LLC and the Dalmore Group are independent and unaffiliated
      entities.
    </FootNote>
  </FootNoteWrapper>
)

export default Footnote
