import { ThunkAction } from 'store/types'
import { EventsApi } from 'api/events.api'
import {
  selectEvents,
  selectEventsComments,
  selectEventsDetail
} from '../selectors'
import * as actions from './actions'

import {
  IComment,
  ReportHarassment,
  ReportReason,
  ReportReasonType,
  ReportViolence
} from './types'

export const fetchCommentsMain = (): ThunkAction => {
  return async (dispatch, getState) => {
    const { event_id } = selectEventsDetail(getState())

    // @ts-ignore
    dispatch(fetchComments(event_id))
  }
}

export const fetchComments = (eventId: number): ThunkAction => {
  return async (dispatch, getState) => {
    dispatch(actions.fetchCommentsAsync.request())

    const { meta } = selectEventsComments(getState())

    const params: { page: number } = { page: 1 }

    if (meta && meta.current_page < meta.last_page) {
      params.page = meta.current_page + 1
    }

    try {
      const resp = await EventsApi.getComments({
        eventId,
        params
      })

      dispatch(actions.fetchCommentsAsync.success(resp.data))
    } catch (err) {
      dispatch(actions.fetchCommentsAsync.failure(err))
    }
  }
}

export const fetchCommentThread = (route: string): ThunkAction => {
  return async dispatch => {
    dispatch(actions.fetchCommentThreadAsync.request())

    try {
      const resp = await EventsApi.getCommentTread(route)

      dispatch(actions.fetchCommentThreadAsync.success(resp.data))
    } catch (err) {
      dispatch(actions.fetchCommentThreadAsync.failure(err.response.data))
    }
  }
}

export const loadComments = (): ThunkAction => {
  return async (dispatch, getState) => {
    const {
      comments: { isFetching }
    } = selectEvents(getState())

    if (!isFetching) {
      dispatch(actions.clearComments())
      // @ts-ignore
      dispatch(fetchCommentsMain())
    }
  }
}

export const loadMoreComments = (): ThunkAction => {
  return async (dispatch, getState) => {
    const {
      comments: { data: comments, isFetching }
    } = selectEvents(getState())

    if (
      !isFetching &&
      comments.meta &&
      comments.meta.current_page !== comments.meta.last_page
    ) {
      // @ts-ignore
      dispatch(fetchCommentsMain())
    }
  }
}

export const postComment = (eventId: number, content: string): ThunkAction => {
  return async dispatch => {
    dispatch(actions.postCommentAsync.request())

    try {
      const resp = await EventsApi.sendComment(eventId, content)

      dispatch(actions.postCommentAsync.success(resp.data))
      dispatch(actions.updateShownComments())
    } catch (err) {
      dispatch(actions.postCommentAsync.failure(err))
    }
  }
}

export const replyToComment = (
  eventId: number,
  commentId: number,
  content: string
): ThunkAction => {
  return async dispatch => {
    dispatch(actions.replyToCommentAsync.request())

    try {
      const resp = await EventsApi.replyToComment(eventId, commentId, content)

      dispatch(actions.replyToCommentAsync.success({ ...resp.data, commentId }))
      dispatch(actions.updateShownComments())
    } catch (err) {
      dispatch(actions.replyToCommentAsync.failure(err))
    }
  }
}

export const likeComment = (
  commentId: number | null,
  eventId: number
): ThunkAction => {
  return async dispatch => {
    dispatch(actions.likeCommentAsync.request())

    try {
      const resp = await EventsApi.likeComment(eventId, commentId)

      dispatch(actions.likeCommentAsync.success(resp.data))
    } catch (err) {
      dispatch(actions.likeCommentAsync.failure(err))
    }
  }
}

export const reportComment = (
  commentId: number | null,
  eventId: number,
  reason: ReportReason,
  reasonType?: ReportReasonType,
  reasonTypeDetails?: ReportViolence | ReportHarassment
): ThunkAction => {
  return async dispatch => {
    dispatch(actions.reportCommentAsync.request())

    const params: {
      reason: ReportReason
      reason_type?: ReportReasonType
      reason_type_details?: ReportViolence | ReportHarassment
    } = {
      reason
    }

    if (reasonType) {
      params.reason_type = reasonType
    }

    if (reasonTypeDetails) {
      params.reason_type_details = reasonTypeDetails
    }

    try {
      const resp = await EventsApi.reportComment({
        eventId,
        commentId,
        params
      })

      dispatch(actions.reportCommentAsync.success(resp.data))
    } catch (err) {
      dispatch(actions.reportCommentAsync.failure(err))
    }
  }
}

export const fetchReplies = (
  eventId: number,
  commentId: number
): ThunkAction => {
  return async (dispatch, getState) => {
    dispatch(actions.fetchRepliesAsync.request())

    const {
      comments: { shownComments }
    } = selectEvents(getState())

    const targetComment =
      shownComments.find(comment => comment.id === commentId) ||
      ({} as IComment)
    const targetReplies = targetComment.replies

    const lastReplyId = targetReplies[0].id

    const params: { reply_id?: number } = {}

    if (lastReplyId) {
      params.reply_id = lastReplyId
    }

    try {
      const resp = await EventsApi.getReplies({
        eventId,
        commentId,
        params
      })

      dispatch(
        actions.fetchRepliesAsync.success({ ...resp.data, id: commentId })
      )
      dispatch(actions.updateShownComments())
    } catch (err) {
      dispatch(actions.fetchRepliesAsync.failure(err))
    }
  }
}

export const showMoreComments = (): ThunkAction => {
  return async dispatch => {
    dispatch(actions.showMoreCommentsAsync.request())

    setTimeout(() => dispatch(actions.showMoreCommentsAsync.success()), 300)
  }
}
