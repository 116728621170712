import React from 'react'

const CircularIcon = styles => (
  <svg
    width="21"
    height="19"
    viewBox="0 0 21 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.0006 6.99995C18.2101 6.99995 20.0013 8.7911 20.0013 11.0006C20.0013 12.0157 19.6232 12.9424 19.0003 13.6478L19.0002 18.2486C19.0002 18.8194 18.3957 19.1681 17.9101 18.9175L17.8206 18.8634L16.0003 17.5912L14.1806 18.8634C13.7127 19.1904 13.0807 18.8945 13.008 18.353L13.0011 18.2486L13.0001 13.6468C12.3777 12.9415 12 12.0152 12 11.0006C12 8.7911 13.7911 6.99995 16.0006 6.99995ZM17.4994 14.711C17.0365 14.8982 16.5306 15.0012 16.0006 15.0012C15.4699 15.0012 14.9633 14.8979 14.4999 14.7102L14.5003 16.8092L15.5711 16.0621C15.7968 15.9043 16.0878 15.8846 16.3298 16.0029L16.4302 16.0621L17.4993 16.8092L17.4994 14.711ZM17.25 0.00415039C18.7125 0.00415039 19.9084 1.14585 19.995 2.58663L20 2.75415L20.0014 8.00001C19.5971 7.4618 19.0867 7.00782 18.5013 6.66912L18.5 2.75415C18.5 2.10694 18.0081 1.57462 17.3778 1.5106L17.25 1.50415H2.75C2.10279 1.50415 1.57047 1.99603 1.50645 2.62635L1.5 2.75415V12.2542C1.5 12.9014 1.99187 13.4337 2.62219 13.4977L2.75 13.5042L11.6716 13.5054L11.7955 13.7078L11.9291 13.9045L12 13.9979L11.999 15.0042H2.75C1.28747 15.0042 0.0915956 13.8624 0.00501871 12.4217L0 12.2542V2.75415C0 1.29162 1.1417 0.095746 2.58248 0.0091691L2.75 0.00415039H17.25ZM16.0006 8.49995C14.6196 8.49995 13.5 9.61952 13.5 11.0006C13.5 12.3817 14.6196 13.5012 16.0006 13.5012C17.3817 13.5012 18.5013 12.3817 18.5013 11.0006C18.5013 9.61952 17.3817 8.49995 16.0006 8.49995ZM9.25 9.49995C9.66421 9.49995 10 9.83573 10 10.2499C10 10.6296 9.71785 10.9434 9.35177 10.9931L9.25 10.9999H4.75C4.33579 10.9999 4 10.6642 4 10.2499C4 9.87025 4.28215 9.55646 4.64823 9.50679L4.75 9.49995H9.25ZM15.25 3.99995C15.6642 3.99995 16 4.33573 16 4.74995C16 5.12964 15.7178 5.44344 15.3518 5.4931L15.25 5.49995H4.75C4.33579 5.49995 4 5.16416 4 4.74995C4 4.37025 4.28215 4.05646 4.64823 4.00679L4.75 3.99995H15.25Z"
      fill="#EB78FF"
      {...styles}
    />
  </svg>
)

export default CircularIcon
