import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

import checkbox from './checkbox.svg'
import tick from './tick.svg'
import { CheckboxProps } from './index'

export const Label = styled.label`
  span,
  a {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 0.75rem;
  }
`

export const CheckBox = styled.input.attrs({ type: 'checkbox' })<CheckboxProps>`
  :focus + span {
    border-color: ${p => (p.errorLabel ? '#D82C2C' : p.theme.colors.black)};
  }

  :not(:checked),
  :checked {
    position: absolute;
    margin-top: 14px;
    left: -9999px;
  }

  :not(:checked) + span,
  :checked + span {
    display: block;
    position: relative;
    padding-left: 2rem;
    cursor: pointer;
  }

  :not(:checked) + span:before,
  :checked + span:before {
    content: '';
    position: absolute;
    left: 0;
    border-radius: 4px;
    border: 1px solid ${p => p.theme.colors.black};
    padding: 0.75rem;
    font-size: 1rem;
    color: #152735;
    background-clip: padding-box;
    transition: border-color 0.15s;
    box-sizing: border-box;
  }

  :not(:checked) + span:after,
  :checked + span:after {
    content: url(${checkbox});
    position: absolute;
    left: 0;
    top: 0;
    font-size: 1rem;
    color: #000000;
    line-height: 1.5rem;
    width: 1.5rem;
    text-align: center;
    transition: all 0.2s;
  }
  :not(:checked) + span:after {
    opacity: 0;
    transform: scale(0);
  }
  :checked + span:after {
    opacity: 1;
    transform: scale(1);
  }

  :disabled:not(:checked) + span:before,
  :disabled:checked + span:before {
    box-shadow: none;
    border-color: #585858;
    background-color: transparent;
  }
  :disabled:checked + span:after {
    color: #999;
  }
  :disabled + span {
    color: #585858;
  }

  + span:before {
    border-color: ${p => (p.errorLabel ? '#D82C2C !important' : '')};
    outline: 0;
    cursor: pointer;
  }
  :checked:focus + span:before,
  :not(:checked):focus + span:before {
    border-color: ${p => p.theme.colors.black};
    outline: 0;
  }
`
export const CheckBoxCheckout = styled(CheckBox)`
  :not(:checked) + span:before,
  :checked + span:before {
    bottom: 0;
    border-radius: 0;
    width: 29px;
    height: 29px;
  }

  :not(:checked) + span:after,
  :checked + span:after {
    content: '';
    top: inherit;
    left: 5px;
    bottom: 5px;
    width: 19px;
    height: 19px;
    background-color: #d4ab72;
  }
`

export const CheckboxWrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`

export const LightLabel = styled(Label)``

export const DarkLabel = styled(Label)`
  span {
    opacity: 1;
    color: #999999;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.375rem;

    ${media.lg`
    font-size: 1.125rem;
    `}
  }

  input {
    :not(:checked) + span:before,
    :checked + span:before {
      padding: 0.6875rem;
      background-color: transparent;
      border: 1px solid ${p => p.theme.colors.gold} !important;
    }

    :not(:checked) + span,
    :checked + span {
      padding-left: 2.8125rem;
      display: flex;
      align-items: center;
    }

    :checked + span:after {
      background-color: #d4ab72;
    }
  }
`

export const BlueCheckboxLabel = styled(Label)`
  width: 12px;
  height: 12px;

  span,
  a {
    font-weight: 300;
    line-height: 1.125rem;
    font-family: Inter, sans-serif;
  }
`

export const BlueCheckBox = styled(CheckBox)`
  :focus + span {
    border-color: ${p => (p.errorLabel ? '#D82C2C' : p.theme.colors.white)};
  }

  :not(:checked) + span,
  :checked + span {
    padding-left: 0.75rem;
  }

  :not(:checked) + span:before,
  :checked + span:before {
    border-radius: 3px;
    padding: 5px;
    font-size: 0.75rem;
    color: ${p => p.theme.colors.white};
  }

  :not(:checked) + span:before,
  :not(:checked):focus + span:before {
    border-color: ${p => p.theme.colors.white};
  }

  :checked + span:before,
  :checked:focus + span:before {
    border-color: transparent;
  }

  :not(:checked) + span:after,
  :checked + span:after {
    content: url(${tick});
    font-size: 0.75rem;
    color: ${p => p.theme.colors.white};
    line-height: 0.75rem;
    width: 0.75rem;
    background: linear-gradient(180deg, #326ae3 0%, #2b5dc6 100%);
    box-shadow: 0 0.5px 0.550000011920929px 0 rgba(255, 255, 255, 0.25) inset;
    border-radius: 3px;
  }
  + span::before,
  + span::after {
    ${media.xs`
          width: 17px !important;
          height: 17px !important;
          line-height: 1rem !important;
    `}
  }
`
