import React from 'react'

const CommentIcon = props => (
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.25 15C1.45507 15 0 13.5449 0 11.75V3.25C0 1.45507 1.45507 0 3.25 0H16.75C18.5449 0 20 1.45507 20 3.25V11.75C20 13.5449 18.5449 15 16.75 15H11.0125L5.99868 18.7507C5.44585 19.1642 4.6625 19.0512 4.24901 18.4984C4.08736 18.2822 4 18.0196 4 17.7499L3.99921 15H3.25ZM10.5135 13.5H16.75C17.7165 13.5 18.5 12.7165 18.5 11.75V3.25C18.5 2.2835 17.7165 1.5 16.75 1.5H3.25C2.2835 1.5 1.5 2.2835 1.5 3.25V11.75C1.5 12.7165 2.2835 13.5 3.25 13.5H5.49879L5.499 14.2498L5.49986 17.2506L10.5135 13.5Z"
      fill="#EB78FF"
    />
  </svg>
)

export default CommentIcon
