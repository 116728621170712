import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export const Wrapper = styled.div`
  min-height: 5.125rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #252525;
  padding: 1rem 0;

  :last-child {
    border: none;
  }

  ${media.md`
    min-height: 5.375rem;
  `}
`
export const EventTitle = styled.p`
  max-width: 5.875rem;
  width: 100%;
  margin-right: 0.375rem;
  font-size: 1rem;
  text-shadow: 0 4px 34px rgba(0, 0, 0, 0.45);
  line-height: 1.875rem;
  overflow: hidden;
  text-overflow: ellipsis;

  ${media.md`
    max-width: 8.5rem;
    margin-right: 1.5rem;
  `}
  ${media.lg`
    max-width: 12.75rem;
    margin-right: 1.25rem;
  `}
`
export const FundWrapper = styled.div`
  max-width: 49.75rem;
  width: 100%;

  > div {
    margin-bottom: 0;
  }
`
