import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export const CardWrapper = styled.div`
  > div:first-child {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
  }

  > div:last-child {
    margin-bottom: 1.75rem;
    border-bottom-left-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }
`
export const Title = styled.h2`
  margin-right: 0.625rem;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 10px;
  ${media.lg`
  padding-top: 80px;
  `}
`
export const Counter = styled.div`
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${p => p.theme.colors.fuchsia};
  border-radius: 50%;

  color: ${p => p.theme.colors.white};
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 0.02375rem;
`
export const TitleWrapper = styled.div<{ marginTop?: boolean }>`
  ${p => p.marginTop && 'margin-top: 3rem;'}
  margin-bottom: 1rem;
  display: flex;
  align-items: baseline;
`
export const Wrapper = styled.div`
  padding-top: 2.75rem;
  background: ${p => p.theme.colors.darkGrey};

  ${media.md`
  padding-top: 4rem;
  `}
`
