import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectAutocompleteAddresses } from 'redux/checkout/selectors'
import { setEntityAddressInfo, setErrors } from 'redux/investment/actions'
import {
  selectEntityAddressInfo,
  selectInvestmentErrors
} from 'redux/investment/selectors'
import { AddressFields, EntityType } from 'redux/investment/types'
import { fetchAddress } from 'redux/checkout/operations'
import {
  extractFieldFromAddress,
  extractLabelAndCodeFromAddress,
  geocodeByPlaceId
} from 'utils/googleHelpers'
import { noFirstSpaceRegex, noTwoSpacesRegex } from 'utils/helpers'
import TextInput from 'src/components/UI/TextInput'
import Dropdown from 'src/components/UI/Dropdown'

import { Form } from 'src/components/investment/steps/styles'

const EntityAddressForm: FC<{ selectedEntity: any }> = ({ selectedEntity }) => {
  const dispatch = useDispatch()
  const address = useSelector(selectEntityAddressInfo)
  const suggestAddresses = useSelector(selectAutocompleteAddresses)
  const errors = useSelector(selectInvestmentErrors)

  const onChangeStreet = async (name: string, value: { value: string }) => {
    if (!value.value) {
      return
    }

    // @ts-ignore
    const [place] = await geocodeByPlaceId(value.value)
    let city = extractFieldFromAddress(place, 'locality')
    if (!city) {
      city = extractFieldFromAddress(place, 'sublocality_level_1')
    }
    const countryName = extractLabelAndCodeFromAddress(place, 'country')
    const stateLabel = extractFieldFromAddress(
      place,
      'administrative_area_level_1'
    )
    const stateCode = extractLabelAndCodeFromAddress(
      place,
      'administrative_area_level_1'
    )
    const state = stateCode ? stateCode.code : stateLabel
    const postalCode = extractFieldFromAddress(place, 'postal_code') || ''
    const fullStreet = extractFieldFromAddress(place, 'route')
    const streetNumber = extractFieldFromAddress(place, 'street_number') || ''
    const street = `${streetNumber && streetNumber + ' '}${fullStreet}`
    const country = countryName ? countryName.code || countryName.label : ''
    const newAddress = {
      country_label: countryName && countryName.label,
      state_label: stateLabel.replace(/'/g, ''),
      country,
      state: state.replace(/'/g, ''),
      city: city.replace(/'/g, ''),
      street,
      postal_code: postalCode
    }
    dispatch(
      setEntityAddressInfo({
        ...address,
        ...newAddress
      })
    )
    dispatch(
      setErrors({
        ...errors,
        entity_country: '',
        entity_state: ''
      })
    )
  }

  const handleChangeInputAddress = (value: string) => {
    if (value) {
      dispatch(fetchAddress(value))
    }

    if (errors.street) {
      dispatch(setErrors({ ...errors, street: '' }))
    }
  }

  const handleChangeRoom = (name: string, value: string) => {
    const room = value
      .replace(noFirstSpaceRegex, '')
      .replace(noTwoSpacesRegex, ' ')
    dispatch(
      setEntityAddressInfo({
        ...address,
        additional_address: room
      })
    )
  }

  const addressLabel =
    selectedEntity === EntityType.IRA
      ? 'IRA Company Address'
      : selectedEntity === EntityType.ENTITY
      ? 'Business Address'
      : 'Mailing Address (for notices)'

  return (
    <Form>
      <Dropdown
        label={addressLabel}
        name={'street'}
        options={suggestAddresses}
        value={{
          label: address.street,
          value: address.street
        }}
        placeholder={'185 Berry st'}
        onSelect={onChangeStreet}
        onInputChange={handleChangeInputAddress}
        isAsync={true}
        wrapperClassName={'address-form__street'}
        forceUpdate={true}
        buttonClassName={'address-form__select'}
        customStyles={{
          control: { background: '#fff' },
          input: { background: '#fff' },
          firstOption: { borderRadius: '0' },
          option: { backgroundColor: '#fff' }
        }}
        valid={!errors.entity_street}
        errorLabel={errors.entity_street}
      />
      <TextInput
        name={'additional_address'}
        value={address.additional_address}
        label={'Address Line 2 (optional)'}
        handleChange={handleChangeRoom}
        maxLength={128}
      />
      <TextInput
        name={'city'}
        value={address.city}
        label={'City'}
        wrapperClassName={'address-form__city'}
        readOnly={true}
      />
      <TextInput
        name={'state'}
        value={address.state}
        label={AddressFields.state}
        wrapperClassName={'address-form__state'}
        errorLabel={errors.entity_state}
        readOnly={true}
      />
      <TextInput
        name={'country'}
        value={address.country}
        label={AddressFields.country}
        wrapperClassName={'address-form__country'}
        readOnly={true}
        errorLabel={errors.entity_country}
      />
      <TextInput
        name={'postal_code'}
        value={address.postal_code}
        label={AddressFields.postal_code}
        wrapperClassName={'address-form__zip'}
        readOnly={true}
      />
    </Form>
  )
}

export default React.memo(EntityAddressForm)
