import styled, { css } from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { InputVariant, TextInputProps } from './index'

export const Wrapper = styled.div<{
  variant: InputVariant
  isLabelHidden?: boolean
}>`
  width: 100%;
  position: relative;

  ${p =>
    p.variant === InputVariant.Default &&
    css`
      padding: 7px 10px;
      background-color: ${p.theme.colors.white};

      :not(:last-child) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      }
    `}
    padding: ${p => p.isLabelHidden && '16.5px 10px'};
    
    label {
       user-select: none;
    }
  [data-id='tooltip'] {
    max-width: 300px;
    ${media.md`
        max-width: 500px;
      `}
  }
`
export const Input = styled.input.attrs({ className: 'input' })<TextInputProps>`
  display: block;
  border: none;
  background-color: transparent;
  width: ${p => (p.errorLabel ? '90%' : '100%')};
  padding: 0.5rem 0;
  font-size: 1.125rem;
  line-height: 1rem;
  color: ${p => p.theme.colors.black};
  background-clip: padding-box;
  transition: border-color 0.15s;

  text-overflow: ellipsis;
  overflow: hidden;

  // REMOVE AFTER REDESIGN
  font-family: Inter;

  ::placeholder {
    color: ${p => p.theme.colors.black};
    font-size: 1.125rem;
  }

  :focus {
    border-color: ${p => (p.errorLabel ? '#D82C2C' : p.theme.colors.black)};
    outline: 0;
  }

  border-color: ${props => props.errorLabel && '#D82C2C'};

  ${props =>
    props.as === 'textarea' &&
    css`
      min-height: 9.5rem;

      ${media.md`
        height: 12.5rem;
      `}
    `};
`

export const IconWrapper = styled.div`
  cursor: pointer;
  position: relative;
  display: inline-block;
  top: 3px;
  width: 1rem;
  height: 1rem;
  &[data-for^='error-tooltip'] {
    width: 1.625rem;
    height: 1.625rem;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);

    ${media.md`
    right: 10px;
  `}
  }
`

export const SecondaryInput = styled(Input)`
  ~ ${IconWrapper} {
    transform: translate(0, -50%);
  }
  border: none;
  font-size: 3.125rem;
  font-weight: 700;
  line-height: normal; //fixed placeholder on IPad 7
  ${media.md`
      font-size: 4.063rem;
 `}
  ::placeholder {
    color: rgba(0, 0, 0, 0.3);
    font-size: 3rem;
  }

  ::selection {
    background: rgba(0, 0, 0, 0.3);
  }

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 1000px #eee inset;
    transition: background-color 5000s ease-in-out 0s;
  }
`
export const DefaultInput = styled(Input)<{
  isLabelHidden: boolean
}>`
  padding: 0;
  caret-color: ${p => p.theme.colors.black};
  font-weight: 700;
  line-height: 1.75rem;

  ::placeholder {
    color: ${p => p.theme.colors.black};
    opacity: 0.15;
    line-height: 1.75rem;
    font-weight: 400;
    font-size: ${p => p.isLabelHidden && '1.125rem'};
  }
  ::selection {
    background: rgba(9, 11, 22, 0.2);
  }

  ${p =>
    p.as === 'textarea' &&
    css`
      padding: 10px;
      border-radius: 0.5rem;
      font-size: 1.25rem;
      font-weight: 400;

      ::placeholder {
        max-width: 40.375rem;
        color: ${p => p.theme.colors.white};
      }
    `}

  :-webkit-autofill,
   :-webkit-autofill:hover,
   :-webkit-autofill:focus,
   :-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 2rem ${p => p.theme.colors.white} inset !important;
  }
`
