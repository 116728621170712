import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { ClearButton } from 'src/components/UI/Button'

export const HeaderWrapper = styled.div`
  padding-bottom: 2rem;
  text-align: center;

  ${media.md`
    padding-bottom: 3rem;
  `}
`

export const Title = styled.h1`
  margin-bottom: 0.625rem;
  font-family: Inter, sans-serif;
  font-size: 1.875rem;
  font-weight: 900;
  line-height: 2.25rem;

  ${media.md`
    font-size: 3.4375rem;
    line-height: 5rem;
  `}
`

export const Text = styled.p`
  margin-bottom: 1.5rem;
  padding: 0 2.25rem;
  font-family: Inter, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.625rem;

  ${media.md`
    max-width: 75%;
    margin: 0 auto 1.5rem;
    padding: 0;
    font-size: 1.625rem;
    font-weight: 300;
    line-height: 2.375rem;
  `}
`

export const OutlineButton = styled(ClearButton)<{ active?: boolean }>`
  width: auto;
  padding: 0.125rem 1rem;
  border: 1px solid;
  border-color: ${p => (p.active ? p.theme.colors.white : 'transparent')};
  border-radius: 1.875rem;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 2.375rem;
  text-transform: capitalize;

  :not(:last-child) {
    margin-right: 0.25rem;
  }

  ${media.md`
    font-size: 1.375rem;
  `}
`

export const ViewSwitchWrapper = styled.div`
  display: none;

  ${media.lg`
    display: flex;
    justify-content: flex-end;
  `}
`
