import React, { FC, MouseEvent, useState } from 'react'
import Media from 'react-media'
import { INotification } from 'redux/profile/types'

import { cutText } from 'utils/formatText'
import { CrossIcon } from 'components/icons'
import { CommentClockIcon } from 'components/icons/comments'
import Button from 'src/components/UI/Button'
import PopUpAlert from 'components/common/PopUpAlert'
import Avatar from 'components/common/Avatar'

import {
  Date,
  DateAndDeleteWrapper,
  Notification,
  SenderName,
  ShowMoreButton,
  Text
} from './styles'

interface Props {
  notification: INotification
  deleteNotification: (id: number) => void
  isProcessing: boolean
}

const NotificationCard: FC<Props> = ({
  notification,
  deleteNotification,
  isProcessing
}) => {
  const [isShown, setIsShown] = useState<boolean>(false)
  const [isShowConfirmDelete, setIsShowConfirmDelete] = useState<boolean>(false)

  const toggle = () => setIsShown(!isShown)

  const showDeleteAlert = () => setIsShowConfirmDelete(true)

  const closeDeleteAlert = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setIsShowConfirmDelete(false)
  }

  const handleDelete = () => {
    const { id } = notification
    deleteNotification(id)
  }

  const { status, sender, created_at, subject, text, icon } =
    notification && notification
  const isInitial = icon && icon.split('/').includes('user.png')
  const avatar = icon ? icon : sender.profile_photo
  const initials = text.split(' ')

  return (
    <Notification>
      <Avatar
        src={isInitial ? undefined : avatar}
        name={isInitial ? `${initials[0]} ${initials[1]}` : ''}
        size={45}
      />
      <div style={{ marginLeft: '15px' }}>
        <PopUpAlert
          onHideModal={closeDeleteAlert}
          isOpen={isShowConfirmDelete}
          message={'Are you sure you want to delete this notification?'}
          header={<p>{''}</p>}
          buttons={
            <>
              <Button onClick={handleDelete} disabled={isProcessing}>
                OK
              </Button>
              <Button onClick={closeDeleteAlert}>Cancel</Button>
            </>
          }
        />
        <SenderName isNew={!status}>
          {sender.name} - {subject}
        </SenderName>
        <Text style={{ paddingTop: '10px' }}>
          <Media
            queries={{
              small: '(max-width: 767px)',
              medium: '(min-width: 768px) and (max-width: 1023px)',
              large: '(min-width: 1024px)'
            }}
          >
            {matches => {
              if (
                (matches.small && text.length > 60) ||
                (matches.medium && text.length > 110) ||
                (matches.large && text.length > 170)
              ) {
                return (
                  <>
                    {isShown
                      ? text
                      : (matches.small && cutText(text, 60)) ||
                        (matches.medium && cutText(text, 110)) ||
                        (matches.large && cutText(text, 170))}{' '}
                    <ShowMoreButton onClick={toggle}>
                      {isShown ? 'Show less' : 'Show more'}
                    </ShowMoreButton>
                  </>
                )
              }
              return text
            }}
          </Media>
        </Text>
        <DateAndDeleteWrapper>
          <Date>
            <CommentClockIcon /> {created_at}
          </Date>
          <span>|</span>
          <Button variant={'clear'} onClick={showDeleteAlert}>
            <CrossIcon width={10} height={10} /> Remove
          </Button>
        </DateAndDeleteWrapper>
      </div>
    </Notification>
  )
}

export default NotificationCard
