import { AppState } from 'store'
import { createSelector } from 'reselect'
import { selectCountries, selectCountriesList } from 'redux/checkout/selectors'
import { formatMoney } from 'utils/dateFormats'

export const selectProfile = (state: AppState) => state.profile

export const selectNewAddress = (state: AppState) =>
  selectProfile(state).newAddress
export const selectCurrentAddress = (state: AppState) =>
  selectProfile(state).currentAddress
export const selectProfileFollowing = (state: AppState) =>
  selectProfile(state).following
export const selectProfileIsFetching = (state: AppState) =>
  selectProfile(state).isFetching
export const selectNotifications = (state: AppState) =>
  selectProfile(state).notifications
export const selectProfileBalance = (state: AppState) =>
  selectProfile(state).balance
export const selectProfileAddressErrors = (state: AppState) =>
  selectProfile(state).addressErrors
export const selectProfileErrors = (state: AppState) =>
  selectProfile(state).errors
export const selectAddressIsFetching = (state: AppState) =>
  selectProfile(state).isFetchingAddress

export const selectNotificationsData = (state: AppState) =>
  selectNotifications(state).data
export const selectErrors = (state: AppState) => selectProfile(state).errors
export const selectEventDetailsData = (state: AppState) =>
  selectProfile(state).eventDetails
export const selectProfileDetailsErrors = (state: AppState) =>
  selectProfile(state).profileDetailsErrors

export const selectReportErrors = (state: AppState) =>
  selectErrors(state).message
export const selectEventDetails = (state: AppState) =>
  selectEventDetailsData(state).events || []

export const findUserAddress = (newAddress: any, currentAddress: any) => {
  if (currentAddress.hasOwnProperty('country')) {
    return currentAddress
  }
  return newAddress
}

export const selectUserAddress = createSelector(
  selectNewAddress,
  selectCurrentAddress,
  findUserAddress
)

export const selectNewMessages = createSelector(
  selectNotificationsData,
  notifications => notifications.filter(n => n.status === 0)
)

export const hasNewNotificationsSelector = createSelector(
  selectNewMessages,
  notifications => notifications.length > 0
)

export const selectOldMessages = createSelector(
  selectNotificationsData,
  notifications => notifications.filter(n => n.status === 1)
)

export const selectMessageIDs = createSelector(
  selectNewMessages,
  notifications => notifications.map(n => n.id)
)

export const findUserCountry = (countryList: any, userAddress: any) => {
  if (userAddress.country) {
    return countryList.find((country: any) => {
      if (userAddress.country.value) {
        return userAddress.country.value === country.value
      }
      return userAddress.country === country.code
    })
  }
  return userAddress.country
}

export const selectUserCountry = createSelector(
  selectCountries,
  selectUserAddress,
  findUserCountry
)

export const selectStates = createSelector(
  selectCountriesList,
  selectUserCountry,
  (countries, userCountry) => {
    const currentCountry = countries.find(c => {
      if (userCountry && userCountry.value) {
        return userCountry.value === c.id
      }
      return userCountry === c.code
    })

    if (currentCountry && countries && userCountry) {
      return currentCountry.available_states.map(country => ({
        value: country.id,
        label: country.state_name
      }))
    }

    return []
  }
)

export const selectFormattedAddress = createSelector(
  selectCurrentAddress,
  address => {
    const {
      state,
      country,
      city,
      street,
      zip,
      place_id,
      room: roomNumber
    } = address

    let room = ''
    if (roomNumber) {
      room = roomNumber
    }

    return {
      country,
      state,
      city,
      street,
      zip,
      place_id,
      room
    }
  }
)

export const selectSupportEvents = createSelector(
  selectEventDetails,
  events =>
    events.map(e => ({
      label: e.title,
      value: String(e.id)
    }))
)

export const selectAmountRaised = createSelector(
  selectEventDetails,
  events => {
    const amount = events
      ? events.reduce((acc, event) => acc + event.raised, 0)
      : 0
    return `$${formatMoney(amount)}`
  }
)

export const selectFetchingInvestments = (state: AppState) =>
  state.profile.investments.isFetching

export const selectAllInvestments = (state: AppState) =>
  state.profile.investments.allInvestments

export const selectInvestmentDetails = (state: AppState) =>
  state.profile.investments.investmentDetails

export const selectInvestmentErrors = (state: AppState) =>
  state.profile.investments.errors
