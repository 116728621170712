import React, { FC, useEffect, useState } from 'react'
import TextInput from 'src/components/UI/TextInput'
import Button from 'src/components/UI/Button'
import ErrorLabel from 'components/UI/ErrorLabel'
import { IErrors } from 'redux/commonTypes'
import { Wrapper, Subtitle, ErrorWrapper } from './styles'
import { ButtonWrapper } from '../../SignUpForm/styles'
import { MIN_PASSWORD_LENGTH } from 'utils/helpers'

interface IConfirmPassword {
  password: string
  password_confirmation: string
}

interface IFormProps {
  onSubmit?: (form: IConfirmPassword) => {}
  clearErrors: () => void
  serverErrors?: any
  isFetching?: boolean
}

const ConfirmPasswordForm: FC<IFormProps> = ({
  onSubmit,
  clearErrors,
  isFetching,
  serverErrors
}) => {
  const [form, setForm] = useState<IConfirmPassword>({
    password: '',
    password_confirmation: ''
  })
  const [errors, setErrors] = useState<IErrors<IConfirmPassword>>({} as IErrors<
    IConfirmPassword
  >)
  const { password, password_confirmation } = form

  useEffect(() => {
    if (serverErrors && Object.keys(serverErrors).length) {
      clearErrors()
    }
    // eslint-disable-next-line
  }, [form, errors])

  const isValid = (): boolean => {
    const validationErrors = {} as {
      password: string
      password_confirmation: string
    }

    if (password.length < MIN_PASSWORD_LENGTH) {
      validationErrors.password = `The password must be at least ${MIN_PASSWORD_LENGTH} characters long`
    }

    if (password.includes(' ')) {
      validationErrors.password = 'Spaces are not allowed in the password'
    }

    if (password_confirmation !== password) {
      validationErrors.password_confirmation = "The passwords don't match"
    }

    setErrors(validationErrors)
    return !Object.keys(validationErrors).length
  }

  const onUpdate = (name: string, value: string) => {
    setForm({
      ...form,
      [name]: value
    })
    setErrors({
      ...errors,
      [name]: ''
    })
  }

  const handleSubmit = async () => {
    if (isValid() && onSubmit) {
      onSubmit(form)
    }
  }

  const emptyFields = !password.length && !password_confirmation.length

  return (
    <>
      <Subtitle>Please set a new password before you can continue</Subtitle>
      <Wrapper>
        <TextInput
          name={'password'}
          type={'password'}
          value={password}
          label={'Password'}
          errorLabel={errors.password || ''}
          isLabelHidden={!password}
          placeholder={'Password'}
          handleChange={onUpdate}
          wrapperClassName={'form__first-field'}
        />
        <TextInput
          name={'password_confirmation'}
          type={'password'}
          value={password_confirmation}
          label={'Confirm Password'}
          isLabelHidden={!password_confirmation}
          placeholder={'Confirm Password'}
          errorLabel={errors.password_confirmation || ''}
          handleChange={onUpdate}
          wrapperClassName={'form__last-field'}
        />
      </Wrapper>
      <ErrorWrapper>
        {serverErrors && <ErrorLabel label={serverErrors} />}
      </ErrorWrapper>
      <ButtonWrapper>
        <Button
          variant={'default'}
          type={'submit'}
          onClick={handleSubmit}
          disabled={emptyFields || isFetching}
          isLoading={isFetching}
        >
          Save
        </Button>
      </ButtonWrapper>
    </>
  )
}

export default ConfirmPasswordForm
