import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { INotification } from 'redux/profile/types'
import {
  readNotifications,
  deleteNotification,
  loadMoreNotifications,
  loadNotifications
} from 'redux/profile/operations'
import {
  selectMessageIDs,
  selectNewMessages,
  selectNotificationsData,
  selectOldMessages,
  selectProfileIsFetching
} from 'redux/profile/selectors'
import { routePath } from 'routes'

import NotificationCard from '../NotificationCard'
import InfiniteScroll from 'components/common/InfiniteScroll'
import Loader from 'src/components/UI/Loader'
import Link from 'components/UI/Link'

import { Title, Counter, TitleWrapper, CardWrapper } from './styles'
import { Text, TextWrapper } from 'containers/profile/UserProfilePage/styles'

const NotificationList: FC = () => {
  const oldMessages = useSelector(selectOldMessages)
  const newMessageIDs = useSelector(selectMessageIDs)
  const isFetching = useSelector(selectProfileIsFetching)
  const newNotifications = useSelector(selectNewMessages)
  const allMessages = useSelector(selectNotificationsData)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadNotifications())
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    return () => {
      if (newMessageIDs.length > 0) {
        dispatch(readNotifications({ notifications: newMessageIDs }))
      }
    }
    // eslint-disable-next-line
  }, [newMessageIDs])

  const handleClick = (event: any) => {
    if (event.target.tagName === 'BUTTON') {
      event.preventDefault()
    }
  }

  const noNotifications = () => (
    <TextWrapper>
      <Text>You have no In-App notifications yet</Text>
    </TextWrapper>
  )

  const renderNotificationCard = (n: INotification) => {
    const deleteNotificationCard = () => {
      dispatch(deleteNotification(n.id))
    }

    return (
      <NotificationCard
        notification={n}
        key={n.id}
        deleteNotification={deleteNotificationCard}
        isProcessing={isFetching}
      />
    )
  }

  if (!isFetching && allMessages && !allMessages.length) {
    return noNotifications()
  }
  const loadMore = () => {
    dispatch(loadMoreNotifications())
  }
  return (
    <InfiniteScroll onLoadMore={loadMore}>
      {newNotifications.length > 0 && (
        <>
          <TitleWrapper>
            <Title>New</Title>
            <Counter>{newNotifications.length}</Counter>
          </TitleWrapper>
          <CardWrapper>
            {newNotifications.map(n =>
              n.route ? (
                <Link
                  key={n.id}
                  onClick={handleClick}
                  to={{
                    pathname: routePath.COMMENT_DETAILS,
                    state: { route: n.route }
                  }}
                >
                  {renderNotificationCard(n)}
                </Link>
              ) : (
                renderNotificationCard(n)
              )
            )}
          </CardWrapper>
        </>
      )}
      {oldMessages.length > 0 && (
        <>
          <TitleWrapper>
            <Title>Earlier</Title>
          </TitleWrapper>

          {oldMessages.map(n =>
            n.route ? (
              <Link
                onClick={handleClick}
                to={{
                  pathname: routePath.COMMENT_DETAILS,
                  state: { route: n.route }
                }}
              >
                {renderNotificationCard(n)}
              </Link>
            ) : (
              renderNotificationCard(n)
            )
          )}
        </>
      )}
      {isFetching && <Loader />}
    </InfiniteScroll>
  )
}

export default NotificationList
