import React, { useState } from 'react'
import { Col, Row } from 'styled-bootstrap-grid'
import { PaymentSystem } from 'redux/checkout/types'
import MyStoreCheckout from 'components/payments/braintree/CheckoutForm'
import PayPalCheckout from 'components/payments/paypal/PayPalCheckout'
import { CardIcon, PayPalIcon } from 'components/icons/checkout'

import {
  TabHeadContainer,
  Icon,
  TabButton,
  PaymentMethodsWrapper,
  FormTitle,
  FieldsHolder,
  Overlay
} from './styles'

interface PaymentMethodsProps {
  isBlocked: boolean
  isShopping: boolean
}
export const PaymentMethods: React.FC<PaymentMethodsProps> = ({
  isShopping,
  isBlocked
}): React.ReactElement => {
  const [activeTab, setActiveTab] = useState<PaymentSystem>(
    PaymentSystem.STRIPE
  )

  const onTabChange = (e: any) => {
    const activeTab = e.currentTarget.getAttribute('data-tab')

    if (activeTab) {
      setActiveTab(activeTab)
    }
  }
  const renderTab = () => {
    switch (activeTab) {
      case PaymentSystem.STRIPE:
        return <MyStoreCheckout isShopping={isShopping} />
      case PaymentSystem.PAY_PAL:
        return <PayPalCheckout isShopping={isShopping} />
      default:
        return null
    }
  }
  return (
    <PaymentMethodsWrapper>
      {isBlocked && <Overlay />}
      <FormTitle>Payment Methods</FormTitle>
      <TabHeadContainer>
        <Row>
          <Col sm={12} md={12} lg={6}>
            <FieldsHolder>
              <TabButton
                onClick={onTabChange}
                dataTab={PaymentSystem.STRIPE}
                active={activeTab === PaymentSystem.STRIPE}
              >
                <Icon>
                  <CardIcon
                    color={
                      activeTab === PaymentSystem.STRIPE ? '#B19062' : '#fff'
                    }
                  />
                </Icon>
                <span>Credit Card</span>
              </TabButton>
              <TabButton
                onClick={onTabChange}
                dataTab={PaymentSystem.PAY_PAL}
                active={activeTab === PaymentSystem.PAY_PAL}
              >
                <Icon>
                  <PayPalIcon
                    color={
                      activeTab === PaymentSystem.PAY_PAL ? '#B19062' : '#fff'
                    }
                  />
                </Icon>
                <span>PayPal</span>
              </TabButton>
            </FieldsHolder>
          </Col>
          <Col sm={12} md={12} lg={6}>
            <div />
          </Col>
        </Row>
      </TabHeadContainer>
      {renderTab()}
    </PaymentMethodsWrapper>
  )
}
