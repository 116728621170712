import React, { FC } from 'react'
import { Col, Row } from 'styled-bootstrap-grid'
import { EventSectionItems } from 'redux/events/types'

import { Wrapper, Photo, Description } from './styles'

const Team: FC<{ data: EventSectionItems[] }> = ({ data }) => {
  return (
    <Wrapper>
      <Row>
        {data.map(item => (
          <Col xs={12} col={6} key={item.id}>
            <Photo src={item.file_url || ''} />
            <Description
              dangerouslySetInnerHTML={{ __html: item.description || '' }}
            />
          </Col>
        ))}
      </Row>
    </Wrapper>
  )
}

export default Team
