import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export const TimeLeft = styled.div`
  background: rgba(0, 0, 0, 0.4);
  border-radius: 0.5rem;
  padding: 5px;
  z-index: 2;

  ${media.md`
    padding: 5px 10px;
  `}
`

export const EventStatus = styled.span`
  font-family: Inter, sans-serif;
  font-size: 1.125rem;
  font-weight: 900;
  line-height: 1.375rem;
  white-space: pre-line;
`
