import styled from 'styled-components'
import { ReactNode } from 'react'
import { media } from 'styled-bootstrap-grid'

export const Wrapper = styled.div.attrs(({ className }) => ({
  className
}))<{ isSelected: boolean; className: string }>`
  width: 100%;
  z-index: ${p => p.isSelected && 1};
  position: relative;
`

export const Menu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  outline: 0;
  background-color: #eee;
  border-radius: 12px;
  margin-top: 3px;
  z-index: 2;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.25);
  color: ${p => p.theme.colors.black};
  div {
    -ms-overflow-style: none;
    scrollbar-width: none;

    ::-webkit-scrollbar {
      width: 5px;
      background-color: #f5f5f5;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 30px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
      background-color: #e5e5e5;
    }
  }
`
export const MainButton = styled.button<{
  isSelected: boolean
  isDisabled: boolean
  isErrored: boolean
  icon?: ReactNode
  buttonClassName?: string
}>`
  cursor: pointer;
  font-size: 1.125rem;
  color: ${p => p.theme.colors.black};
  font-weight: 600;
  line-height: 1.75rem;
  text-align: left;
  border: none;
  background: ${p => p.theme.colors.greyPearl};
  border-radius: 12px;
  margin: 0;
  padding: 14px 19px;
  text-decoration: none;
  width: 100%;
  height: 100%;
  min-width: 70px;
  min-height: 27px;
  outline: 0;
  ${p => p.isSelected && 'svg{transform: rotate(180deg)}'}

  ${media.lg`
    font-size: 1.25rem;
  `}
  &.address-form__select {
    position: relative;
    padding: 0;
    background-color: ${p => p.theme.colors.white};

    div {
      &:first-of-type {
        width: 90%;
      }
      &:last-of-type {
        position: absolute;
        right: 0;
      }
    }
  }
`

export const StyledDropdown = styled.div`
  font-family: Inter;
  position: relative;
  width: 100%;
`
export const StyledIndicator = styled.div`
  color: gray;
  height: 24px;
  width: 32px;
  display: ${props => (props.hidden ? 'inline-block' : 'none')};
`
export const ArrowBlock = styled.div`
  position: absolute;
  right: 1.375rem;
  top: 50%;
  transform: translateY(-50%);
`
export const SelectedOption = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: Inter;
  font-size: 1.125rem;
`

export const Placeholder = styled(SelectedOption)`
  color: ${p => p.theme.colors.black};
  font-family: Inter;
  font-weight: 700;
  opacity: 0.4;
  pointer-events: none;
  font-size: 0.75rem;

  ${media.md`
    font-size: 1rem;
  `}

  ${media.lg`
    font-size: 1.125rem;
  `}
`
export const LabelWrap = styled.div`
  display: flex;
  align-content: space-between;
  width: 100%;
`
export const IconWrapper = styled(ArrowBlock)`
  right: 0;
  left: 2rem;
`
export const AlertWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 58%;
  transform: translateY(-50%);
`
