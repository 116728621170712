import styled, { css } from 'styled-components'
import Button from 'components/UI/Button'

export const BurgerBar = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${p => p.theme.colors.white};
  transition: 0.4s;

  :nth-of-type(2) {
    transition: none;
  }

  :not(:last-child) {
    margin-bottom: 0.375rem;
  }
`

const rotate = css`
  ${BurgerBar} {
    :first-child {
      transform: rotate(-45deg) translate(-5px, 5px);
    }

    :last-child {
      transform: rotate(45deg) translate(-5px, -5px);
    }

    :nth-of-type(2) {
      opacity: 0;
    }
  }
`

export const BurgerWrapper = styled(Button)<{ isClicked: boolean }>`
  width: 1.625rem;
  display: block;

  ${p => p.isClicked && rotate}
`
