import React, { FC } from 'react'
import menuList from './menuList'

import {
  ListWrapper,
  MenuBlock,
  MenuLink,
  MenuList,
  MenuListItem,
  MenuTitle
} from './styles'

const Menu: FC<{ isAuthenticated: boolean }> = ({ isAuthenticated }) => (
  <MenuBlock>
    {menuList(isAuthenticated).map(listItem => {
      const { hidden, title, list } = listItem
      if (hidden) {
        return null
      }
      return (
        <ListWrapper key={title}>
          <MenuTitle>{title}</MenuTitle>
          <MenuList>
            {list.map(item => (
              <MenuListItem key={item.subtitle}>
                <MenuLink to={item.link}>{item.subtitle}</MenuLink>
              </MenuListItem>
            ))}
          </MenuList>
        </ListWrapper>
      )
    })}
  </MenuBlock>
)

export default Menu
