import React, { Component } from 'react'
import TextInput from 'components/UI/TextInput'
import Checkbox from 'components/UI/Checkbox'
import DropMenu from 'components/UI/DropMenu'
import ErrorLabel from 'components/UI/ErrorLabel'
import Range from 'components/UI/Range'
import Options from '../OptionsBlock'
import { formatPrice } from 'utils/dateFormats'
import {
  Form,
  WrapCheckboxBlock,
  SubmitButoon,
  SendToBlock,
  ButtonsBlock,
  BtnLabel,
  Title,
  DropBlock,
  RangeWrap,
  ErrorBlock
} from './styles'
import { Option } from '../../../UI/Dropdown'

interface Props {
  onSubmit?: any
  supportEvents: Array<{ label: string; value: string }>
}

const defaultRange = [1, 10000]
const followersOptions = [
  { value: '18-34', label: '18-34' },
  { value: '35-44', label: '35-44' },
  { value: '45+', label: '45+' }
]

interface IState {
  subject: string
  message: string
  channels: {
    email: boolean
    sms: boolean
    push: boolean
  }
  amountFrom: number | null
  amountTo: number | null
  ages: any
  events: any
  errors: any
}

const initialState: IState = {
  subject: '',
  message: '',
  channels: {
    email: false,
    sms: false,
    push: false
  },
  amountFrom: defaultRange[0],
  amountTo: defaultRange[1],
  ages: [],
  events: [],
  errors: {}
}

class MessagingForm extends Component<Props, IState> {
  constructor(props: Props) {
    super(props)

    this.state = initialState
  }

  handleChange = (name: string, value: string) => {
    const errorName = ['events', 'ages'].includes(name) ? 'audience' : name
    this.setState((prevState: IState) => ({
      ...prevState,
      [name]: value,
      errors: {
        ...prevState.errors,
        [errorName]: ''
      }
    }))
  }

  handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target
    const errorName = ['email', 'push', 'sms'].includes(name) ? 'channel' : name
    this.setState((prevState: IState) => ({
      ...prevState,
      channels: {
        ...prevState.channels,
        [name]: checked
      },
      errors: {
        ...prevState.errors,
        [errorName]: ''
      }
    }))
  }

  onSliderChange = (value: number[]): void => {
    const [amountFrom, amountTo] = value
    this.setState(prevState => ({ ...prevState, amountFrom, amountTo }))
  }

  sendMessage = async (e: any) => {
    e.preventDefault()
    const { onSubmit } = this.props
    const { errors, ...form } = this.state

    if (this.isValid()) {
      await onSubmit(form)
      this.setState({ ...initialState })
    }
  }

  isValid = (): boolean => {
    const {
      message: m,
      subject: s,
      ages,
      events,
      channels: { sms, push, email }
    } = this.state
    const errors = {} as any

    const message = m.trim()
    const subject = s.trim()

    if (events.length === 0 && ages.length === 0) {
      errors.audience = 'The audience is required.'
    }

    if (!message) {
      errors.message = 'The message is required.'
    } else if (message.length < 2) {
      errors.message = 'The message must be at least 2 characters.'
    } else if (message.length >= 160 && sms) {
      errors.message =
        'This message is should be less than 160 characters to send it via SMS.'
    } else if (message.length > 2000) {
      errors.message = 'The message is too long.'
    }

    if (!email && !sms && !push) {
      errors.channel = 'The channel is required.'
    }
    if (!subject) {
      errors.subject = 'The subject is required.'
    } else if (subject.length < 2) {
      errors.subject = 'The subject must be at least 2 characters.'
    } else if (subject.length > 255) {
      errors.subject = 'The subject is too long.'
    }

    this.setState({ errors })
    return !Object.keys(errors).length
  }

  selectAllAges = () => {
    const { ages } = this.state
    const newAges =
      ages.length === 0 ? followersOptions.map((a: Option) => a.value) : []

    // @ts-ignore
    this.handleChange('ages', newAges)
  }

  render() {
    const { supportEvents } = this.props
    const {
      subject,
      message,
      channels: { email, sms, push },
      ages,
      events,
      errors
    } = this.state

    return (
      <Form onSubmit={this.sendMessage}>
        <SendToBlock>
          <div>
            <Title>Send to:</Title>
            <ButtonsBlock>
              <DropMenu
                onClick={this.selectAllAges}
                disabled={true}
                target={<BtnLabel active={ages.length > 0}>Followers</BtnLabel>}
              >
                <DropBlock>
                  <Options
                    name={'ages'}
                    options={followersOptions}
                    selected={ages}
                    selectAllLabel={'Select all'}
                    onChange={this.handleChange}
                  />
                </DropBlock>
              </DropMenu>

              <DropMenu
                target={
                  <BtnLabel active={events.length > 0}>Supporters</BtnLabel>
                }
              >
                <DropBlock>
                  <RangeWrap>
                    <Range
                      label={'Amount of Contribution'}
                      tipFormatter={formatPrice}
                      min={0}
                      max={10000}
                      step={1}
                      defaultValue={defaultRange}
                      onChange={this.onSliderChange}
                    />
                  </RangeWrap>

                  <Options
                    name={'events'}
                    options={supportEvents}
                    selected={events}
                    selectAllLabel={'Select all events'}
                    onChange={this.handleChange}
                  />
                </DropBlock>
              </DropMenu>
            </ButtonsBlock>
          </div>
          <ErrorBlock>
            {errors.audience ? <ErrorLabel label={errors.audience} /> : ''}
          </ErrorBlock>
        </SendToBlock>

        <TextInput
          variant={'dark'}
          name={'subject'}
          label={'Subject'}
          placeholder={'Subject'}
          errorLabel={errors.subject || ''}
          value={subject}
          handleChange={this.handleChange}
        />
        <TextInput
          as={'textarea'}
          variant={'dark'}
          name={'message'}
          label={'Compose Message'}
          errorLabel={errors.message || ''}
          handleChange={this.handleChange}
          value={message}
        />

        <WrapCheckboxBlock>
          <Checkbox
            variant={'dark'}
            name={'email'}
            checked={!!email}
            label={'Email'}
            handleChange={this.handleChangeCheckbox}
          />
          <Checkbox
            variant={'dark'}
            name={'sms'}
            checked={!!sms}
            disabled={true}
            label={'SMS'}
            handleChange={this.handleChangeCheckbox}
          />
          <Checkbox
            variant={'dark'}
            name={'push'}
            checked={!!push}
            label={'In App'}
            handleChange={this.handleChangeCheckbox}
          />
          <ErrorBlock>
            {errors.channel ? <ErrorLabel label={errors.channel} /> : ''}
          </ErrorBlock>
        </WrapCheckboxBlock>
        <SubmitButoon type={'submit'}>Send</SubmitButoon>
      </Form>
    )
  }
}

export default MessagingForm
