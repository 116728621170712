import React, { FC, useEffect, useState } from 'react'
import Media from 'react-media'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import publicIp from 'public-ip'
import {
  selectInvestmentErrors,
  selectInvestmentPaymentError,
  selectSubscriptionAgreement,
  selectUserSignature
} from 'redux/investment/selectors'
import { routePath } from 'routes'
import { CheckoutMethod } from 'redux/checkout/types'
import { useInvestNowContext } from 'src/pages/investment/InvestNowPage/context'
import { changeUserSignature, setErrors } from 'redux/investment/actions'
import { selectEventsDetail } from 'redux/events/selectors'
import BackButton from 'src/components/investment/steps/BackButton'
import withNorthCapitalRequest from 'src/components/investment/steps/withNorthCapitalRequest'
import Button, { ButtonVariant } from 'src/components/UI/Button'
import {
  isValidFullName,
  noFirstSpaceRegex,
  noTwoSpacesRegex
} from 'utils/helpers'
import { configList } from './configList'
import { showHoursLeft } from 'utils/dateFormats'
import { getUserSignatureErrors } from 'redux/investment/operations'
import TextInput, { InputVariant } from 'src/components/UI/TextInput'
import Loader from 'src/components/UI/Loader'
import { InvestPrivacyModal, InvestTermsModal } from './investModals'
import { ErrorMessage } from 'src/pages/investment/InvestNowPage/styles'
import { AlertIcon } from 'src/components/icons'
import { BackButtonWrapper, Navigation, Step, Title } from '../styles'
import { ReviewList, ReviewText, TermsButton, TermsLink } from './styles'

interface ReviewProps {
  step?: number
  paymentProcessing: boolean
  paymentSuccess: boolean
  request: () => void
  [key: string]: any
}

const Review: FC<ReviewProps> = ({
  setIsOpenModal,
  setIP,
  isOpenModal,
  paymentProcessing,
  paymentSuccess,
  request,
  ...props
}) => {
  const { state } = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const userSignature = useSelector(selectUserSignature)
  const errors = useSelector(selectInvestmentErrors)
  const paymentError = useSelector(selectInvestmentPaymentError)
  const event = useSelector(selectEventsDetail)
  const { templateUrl } = useSelector(selectSubscriptionAgreement)
  const [isOpenPrivacyPolicy, setIsOpenPrivacyPolicy] = useState<boolean>(false)
  const { selectedContactForm } = useInvestNowContext()

  useEffect(() => {
    if (!paymentProcessing && !paymentError && paymentSuccess) {
      history.push({
        pathname: routePath.CHECKOUT_SUCCESS,
        state: {
          ...state,
          checkoutMethod: CheckoutMethod.INVESTMENT,
          userName: selectedContactForm.first_name
        }
      })
    }
    // eslint-disable-next-line
  }, [paymentSuccess, paymentProcessing])

  const changeSignature = (name: string, value: string) => {
    const currentValue = value
      .replace(/[0-9]/g, '')
      .replace(noFirstSpaceRegex, '')
      .replace(noTwoSpacesRegex, ' ')

    dispatch(changeUserSignature(currentValue))

    if (errors[name] && currentValue.length > 2) {
      dispatch(setErrors({ ...errors, [name]: '' }))
    }
  }

  const openModal = () => setIsOpenModal(true)
  const openPolicyModal = () => setIsOpenPrivacyPolicy(true)

  const getPublicIp = async () => {
    const resp = await publicIp.v4()

    setIP(resp)
  }

  useEffect(() => {
    getPublicIp()
    // eslint-disable-next-line
  }, [])

  const finishInvestment = async () => {
    const errors = getUserSignatureErrors(userSignature)

    dispatch(setErrors({ ...errors }))

    if (!Object.keys(errors).length) {
      if (isValidFullName(userSignature)) {
        request()
      }
    }
  }
  const renderFinishLink = () => (
    <>
      {paymentProcessing && <Loader />}
      {paymentError && (
        <ErrorMessage>
          <AlertIcon />
          {paymentError}
        </ErrorMessage>
      )}
      <Button
        style={{ display: 'block', marginTop: '0.625rem', width: '100%' }}
        variant={ButtonVariant.Default}
        disabled={paymentProcessing}
        onClick={finishInvestment}
      >
        complete investment
      </Button>
    </>
  )
  const closeTermsModal = () => setIsOpenModal(false)
  const closePrivacyModal = () => setIsOpenPrivacyPolicy(false)

  const renderReview = () => {
    if (Object.keys(event).length) {
      const {
        investmentTerms: { cancellation_days, cancellation_type }
      } = event
      const time =
        cancellation_days === 2
          ? showHoursLeft(48)
          : cancellation_days === 1
          ? showHoursLeft(24)
          : `${cancellation_days} days`

      const list = configList(time, cancellation_type)
      return (
        <>
          {event.investmentTerms &&
            Object.keys(event.investmentTerms).length &&
            list.map((i, index) => {
              return (
                <li key={`review_item-${index}`}>
                  {i.item.endsWith('Subscription Agreement.') ? (
                    <>
                      {i.item.replace('Subscription Agreement.', '')}
                      <TermsLink
                        variant={ButtonVariant.Text}
                        to={templateUrl || '#'}
                      >
                        Subscription Agreement.
                      </TermsLink>
                    </>
                  ) : (
                    i.item
                  )}
                </li>
              )
            })}
        </>
      )
    }
  }
  return (
    <>
      <InvestTermsModal isOpen={isOpenModal} onClose={closeTermsModal} />
      <InvestPrivacyModal
        isOpen={isOpenPrivacyPolicy}
        onClose={closePrivacyModal}
      />
      <div style={{ marginBottom: 44 }}>
        <Navigation>
          <BackButtonWrapper>
            <Step>{props.step}</Step>
            <BackButton />
            <Title>Review</Title>
          </BackButtonWrapper>
        </Navigation>
        <ReviewList>{renderReview()}</ReviewList>
        <TextInput
          variant={InputVariant.Default}
          name={'userSignatory'}
          value={userSignature}
          handleChange={changeSignature}
          label={'Sign Your Name'}
          placeholder={'Type Your Full Name'}
          wrapperStyle={{ borderRadius: 12 }}
          errorLabel={errors.userSignatory}
          maxLength={128}
        />
      </div>
      <div>
        <ReviewText>
          By clicking ”Complete Investment” you agree to our{' '}
          <TermsButton variant={ButtonVariant.Text} onClick={openModal}>
            Terms & Conditions
          </TermsButton>{' '}
          and{' '}
          <TermsButton variant={ButtonVariant.Text} onClick={openPolicyModal}>
            Privacy&nbsp;Policy
          </TermsButton>{' '}
          .
        </ReviewText>
        <Media query={'(max-width: 767px)'} render={renderFinishLink} />
      </div>
    </>
  )
}

export default React.memo(withNorthCapitalRequest(Review))
