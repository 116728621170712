import React from 'react'
import { Col, Container, Row } from 'styled-bootstrap-grid'
import { EventSection } from 'redux/events/types'
import {
  ButtonAfterIcon,
  MainContent,
  SectionWrapper,
  SectionTitle
} from 'containers/invest/InvestDetailPage/styles'
import { DownloadIcon } from 'components/icons'
import { Description } from './styles'
import Gallery from '../Gallery'

const InvestorDeck: React.FC<{
  section: EventSection | undefined
  lintToDownload?: string
}> = ({ section, lintToDownload }) => {
  return (
    <>
      <MainContent sectionBackground={'#111529'} className={'investor-deck'}>
        <Container className={'content-container'}>
          <SectionWrapper id={'investor-deck'}>
            <SectionTitle color={'#fff'}>Investor Deck</SectionTitle>
            <Row>
              <Col col={12} md={8}>
                <Description>
                  To be successful, you must have the right team and disciplines
                  to make the 3-legged stool stand.
                </Description>
              </Col>
              {lintToDownload && (
                <Col col={12} md={4}>
                  <ButtonAfterIcon href={lintToDownload}>
                    <DownloadIcon />
                    <span>Download</span>
                  </ButtonAfterIcon>
                </Col>
              )}
            </Row>
            {section && <Gallery data={section.items} />}
          </SectionWrapper>
        </Container>
      </MainContent>
      <MainContent
        sectionBackground={'white'}
        className={'investor-deck__empty'}
      />
    </>
  )
}

export default InvestorDeck
