import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export const Wrapper = styled.div`
  padding: 1rem;
  background: ${p => p.theme.colors.white};

  ${media.lg`
  padding: 3rem;
  `}
`

export const Photo = styled.img`
  width: 100%;
  height: 250px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: block;
  object-fit: cover;
  
  ${media.md`
    margin-top: 0;
    height: 300px;
  `}
  
  ${media.lg`
    height: 350px;
  `}
  
    ${media.xl`
    height: 30rem;
  `}
`

export const Description = styled.div`
  margin-bottom: 3rem;
  margin: 0 auto;
  &:first-child {
    margin-bottom: 10px;
  }

  p,
  span {
    color: ${p => p.theme.colors.black};
    font-size: 1rem !important;
    font-family: Poppins !important;
    display: block;
    text-align: justify;
  }
  ul {
    padding-left: 20px;
    margin: 0 auto;
  }
  h4 {
    font-weight: bold;
  }
`
