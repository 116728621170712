import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export const Arrow = styled.div<{ className: string; type: string }>`
  ${p => (p.type === 'next' ? 'right: 15px' : 'left: 15px')};
  background: ${p =>
    p.className.includes('disabled')
      ? 'transparent'
      : p.type === 'next'
      ? 'linear-gradient(270deg, #171717 26.48%, rgba(23, 23, 23, 0) 100%)'
      : 'linear-gradient(-270deg, #171717 26.48%, rgba(23, 23, 23, 0) 100%)'};

  :after {
    content: '';
    width: 3.75rem;
    height: 3.75rem;
    ${media.xs`
        width: 2.75rem;
        height: 2.75rem;
    `}
    position: absolute;
    top: 50%;
    ${p => (p.type === 'next' ? 'right: 0' : 'left: 0')};
    transform: translate(0, -50%);
    background-color: ${p =>
      p.className.includes('disabled') ? 'transparent' : p.theme.colors.gold};
  }

  :hover {
    background: ${p =>
      p.className.includes('disabled')
        ? 'transparent'
        : p.type === 'next'
        ? 'linear-gradient(270deg, #171717 26.48%, rgba(23, 23, 23, 0) 100%)'
        : 'linear-gradient(-270deg, #171717 26.48%, rgba(23, 23, 23, 0) 100%)'};
  }

  :hover:after {
    background: ${p =>
      p.className.includes('disabled')
        ? 'transparent'
        : p.theme.button.default.hover};
  }

  :before {
    content: '';
    height: 14px;
    width: 14px;
    position: absolute;
    top: 55px;
    ${p => (p.type === 'next' ? media.xs`right: 18px` : media.xs`left: 18px`)};
    ${p => (p.type === 'next' ? 'right: 26px' : 'left: 26px')};
    border-right: 3px solid black;
    border-top: 3px solid black;
    opacity: 1;
    transform: ${p =>
      p.type === 'next' ? 'rotate(45deg)' : 'rotate(-135deg)'};
    z-index: 1;

    :hover {
      color: ${p => p.theme.colors.black};
    }
  }
`
