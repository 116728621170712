import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import Link from 'src/components/UI/Link'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  position: relative;

  width: 100%;
  max-width: 330px;
  margin: 0 auto;
  margin-bottom: 35px;

  > div,
  button,
  input {
    width: 100% !important;
  }

  ${media.lg`
      margin-bottom: 55px;
  `}
`

export const ErrorWrapper = styled.div`
  display: flex;
  justify-content: center;

  min-height: 35px;
  padding-bottom: 1rem;

  button {
    padding: 0.375rem 0;
    font-size: 0.875rem;
  }
`

export const ForgotLink = styled(Link).attrs(() => ({
  variant: 'clear',
  children: 'Password Recovery'
}))`
  font-size: 0.9375rem;
  font-weight: 300;
  line-height: 1.875rem;
  text-decoration: underline;
  color: ${p => p.theme.colors.white} !important;

  margin: 8px 0 0;
`

export const SuspendedError = styled.div`
  a {
    color: ${p => p.theme.colors.white};
  }
  p {
    font-size: 11px;
    color: ${p => p.theme.colors.red};
    text-align: center;
  }
`

export const SignUpLink = styled.p`
  font-family: Inter;
  font-size: 0.9375rem;
  font-weight: 300;
  text-align: center;

  max-width: 330px;
  margin: 0 auto;

  ${media.md`
     font-size: 1.125rem;
  `}

  * {
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
  }
  a {
    text-decoration: underline;
  }
`
