import React, { FC, useEffect } from 'react'
import { Container } from 'styled-bootstrap-grid'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { initTabsContent } from 'redux/influencer/operations'
import {
  selectInfluencerIsFetching,
  selectInfluencerUser
} from 'redux/influencer/selectors'
import { selectUserId } from 'redux/auth/selectors'
import { clearInfluencerSearchField } from 'redux/events/actions'
import { searchInfluencers } from 'redux/events/operations'
import EventTabs from 'components/influencer/EventTabs'
import Loader from 'src/components/UI/Loader'
import InfluencerHeader from 'components/influencer/InfluencerHeader'
import { LoaderWrapper } from 'components/invest/Offers/styles'

export interface Props {
  isInfluencerProfile: boolean
}
const InfluencerPage: FC<Props> = ({ isInfluencerProfile = false }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { id } = useParams()
  const isFetching = useSelector(selectInfluencerIsFetching)
  const { data: influencer } = useSelector(selectInfluencerUser)
  const userId = useSelector(selectUserId)
  const influencerId = isInfluencerProfile ? String(userId) : id ? id : ''

  useEffect(() => {
    dispatch(initTabsContent(influencerId, isInfluencerProfile))
    // eslint-disable-next-line
  }, [dispatch, initTabsContent, influencerId, isInfluencerProfile])

  useEffect(() => {
    return () => {
      const { location: { pathname = '' } = {} } = history || {}
      const isSameRoute = pathname.includes('creators')
      if (!isSameRoute) {
        dispatch(clearInfluencerSearchField())
        dispatch(searchInfluencers(''))
      }
    }
    // eslint-disable-next-line
  }, [history, clearInfluencerSearchField, searchInfluencers])

  return (
    <>
      {isFetching ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : (
        <>
          <InfluencerHeader event={influencer} />
          <Container className={'offers-container'} style={{ zIndex: 1 }}>
            {Object.keys(influencer).length > 0 && (
              <EventTabs influencerId={influencerId} />
            )}
          </Container>
        </>
      )}
    </>
  )
}

export default InfluencerPage
