import styled, { css } from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export const HeaderIcon = styled.div`
  width: 188px;
  height: 38px;
  border: 1px solid ${p => p.theme.colors.white};
  border-radius: 20px;
  margin-top: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  :hover {
    cursor: pointer;
  }

  :hover * {
    cursor: pointer;
  }

  span {
    text-transform: uppercase;
    margin-left: 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 2px;
  }

  input {
    width: 100% !important;
    height: 100% !important;
    z-index: 1 !important;
  }
`
export const SubMenuWrapper = styled.div`
  padding: 1rem 0 1.75rem;
  background: ${p => p.theme.colors.darkGrey};

  .tab-nav {
    margin-bottom: 0;
  }
`
export const SubMenuBlockWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  ${media.lg`
    display: flex;
    padding-left: 78px;
  `}
`

export const MainTitle = styled.h2<{
  fullWidth: boolean
}>`
  font-size: 1.875rem;
  font-weight: 900;
  line-height: 2.25rem;
  color: ${p => p.theme.colors.white};
  ${media.lg`
      font-size: 2.8125rem;
      line-height: 3.375rem;
  `}
  ${props =>
    !props.fullWidth &&
    css`
      ${media.lg`
      max-width: 18.75rem;
      `}
    `}
    
  ::after {
    display: block;
    content: '';
    width: 26px;
    height: 1px;
    background: #fff;
    opacity: 0.5;
    margin: 35px 0;
  }
`

export const Description = styled.div`
  font-size: 1.063rem;
  font-weight: 300;
  line-height: 1.625rem;
  text-transform: initial;
  max-width: 21.3125rem;
  margin-top: 10px;
  color: ${p => p.theme.colors.white};
  ${media.lg`
      font-size: 1.625rem;
      line-height: 2.375rem;
      `}
`

export const Email = styled.div`
  p {
    text-transform: initial;

    :first-child {
      font-size: 1rem;
      font-weight: 300;
      line-height: 1.375rem;
      opacity: 0.65;
      ${media.lg`
      font-size: 1rem;
      `}
    }
    :nth-child(2) {
      font-size: 0.875rem;
      line-height: 1.625rem;
      font-weight: 400;
      ${media.lg`
      font-size: 1.375rem;
      line-height: 2rem;
      `}
    }
  }
  ::after {
    display: block;
    content: '';
    width: 26px;
    height: 1px;
    background: #fff;
    opacity: 0.5;
    margin: 35px 0;
  }
`

export const Title = styled.div`
  ${Email} {
    display: none;
    ${media.lg`
    display: block;
      `}
  }
`
export const SubMenuContentWrapper = styled.div<{
  isFAQ?: boolean
}>`
  width: 100%;
  max-width: 680px;
  margin: 0 auto;
  padding-top: 3.5rem;
  ${p =>
    p.isFAQ &&
    `
    background: #fff; 
    padding: 0 20px;
    @media (min-width: 1200px) {
        max-width: 700px;
    }
    @media (min-width: 1539px) {
        max-width: 1000px;
    }
   
   `}
  ${media.lg`
    padding-top: 0;
  `}
`
export const Wrapper = styled.div`
  padding: 55px 20px;
  background-color: ${p => p.theme.colors.darkNavy};
  ${media.lg`
    padding-top: 100px;
  `}
`
