import styled, { css } from 'styled-components'
import Img from 'components/UI/Img'
import { media } from 'styled-bootstrap-grid'
import Link from 'src/components/UI/Link'
import { ButtonVariant } from 'src/components/UI/Button'

export const TopCard = styled.div`
  width: 100%;
  display: block;
  margin-bottom: 4rem;
  position: relative;

  ${media.lg`
    display: flex;
    margin-bottom: 2rem;
  `}
`

export const Title = styled.div<{ fontControl?: boolean }>`
  color: #ffffff;
  text-align: left;
  font-size: 1.5rem;
  margin-bottom: 2rem;
  font-weight: 300;
  font-family: Inter, sans-serif;
  ${p =>
    css`
      ${media.md`
    font-size: ${p.fontControl ? '2.25rem' : '2.25rem'};
    font-weight: 800;
    line-height: 2.75rem;
    margin-bottom: 0.625rem;
  `}
    `}
  ${media.xs`
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin-bottom: 0.5rem;
  `}
`

export const Name = styled.h2`
  color: ${p => p.theme.colors.white};
  font-family: Inter, sans-serif;
  font-size: 1.125rem;
  font-weight: 900;
  line-height: 1.8125rem;
  margin-bottom: 0.438rem;
  ${media.md`
    font-size: 1.5rem;
    font-weight: 800;
    line-height: 2.75rem;
    margin-bottom: 0.625rem;
  `}
`

export const Description = styled.p`
  max-height: 80px;
  overflow: hidden;
  * {
    color: ${p => p.theme.colors.white};
    font-family: Inter, sans-serif !important;
    font-weight: 400 !important;
    font-size: 1.063rem !important;
    line-height: 1.625rem !important;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    ${media.lg`
      font-weight: 300 !important;
      font-size: 1.625rem !important;
      line-height: 2.375rem !important;
      max-width: 90% !important;
    `}
  }

  ${media.xs`
    max-height: 65px;
    
    * {
      font-size: 0.9rem !important;
      line-height: 1.4rem !important;
    }
  `}
`

export const ImgEvent = styled(Img)`
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: -1;
`
export const Left = styled.div`
  position: relative;
  width: 90%;

  ${media.lg`
    width: 50%;
  `}
`
export const Right = styled.div`
  width: 60%;
  position: absolute;
  right: 0;
  top: 50%;
  z-index: 1;

  p {
    font-size: calc(0.94vw + 1rem);
    white-space: normal;
  }

  ${media.lg`
    width: 50%;
  `}
`
export const EventWrapperCard = styled.div<{ img?: string }>`
  width: 100%;
  max-width: 1150px;
  height: 96vh;
  max-height: 850px;
  overflow: hidden;
  position: relative;
  margin: 0 auto 3.125rem;
  box-shadow: 0 35.92px 60px rgba(21, 21, 18, 0.5);
  border-radius: 15px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 1) 90%
  );

  &:before {
    content: '';
    display: block;
    position: absolute;
    background: ${p => p.theme.colors.greyPearl};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
  ${media.lg`
     max-height: 650px;
  `}

  @media only screen and (-webkit-min-device-pixel-ratio: 4),
    only screen and (min-height: 667px) and (min--moz-device-pixel-ratio: 4),
    only screen and (min-height: 667px) and (-o-min-device-pixel-ratio: 4/1),
    only screen and (min-height: 667px) and (min-device-pixel-ratio: 4),
    only screen and (min-height: 667px) and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min-height: 667px) and (min--moz-device-pixel-ratio: 2),
    only screen and (min-height: 667px) and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-height: 667px) and (min-device-pixel-ratio: 2),
    only screen and (min-height: 667px) and (min-resolution: 192dpi),
    only screen and (min-height: 667px) and (min-resolution: 2dppx) {
    height: 70vh;
    max-height: 570px;
  }

  @media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2),
    only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3),
    only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3),
    only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3),
    only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
    //This media queries is for: iPhone 11, XR, 11 Pro Max, Xs Max, 12 Pro, 12 Mini, 12 Pro Max
    height: 70vh;
    max-height: 570px;
  }
`

export const DaysLeftContainer = styled.div`
  max-height: 42%;
`

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

export const CardInfo = styled.div`
  display: flex;
  flex-direction: column-reverse;
  ${media.md`
    flex-direction: row;
   justify-content: space-between;
  `};
`

export const TitleWrapper = styled.div`
  width: 100%;
  ${media.md`
    width: 71%;
  `};
`
export const CardBottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  ${media.md`
   flex-direction: row;
  `}
`

export const SocialWrapper = styled.div<{
  withButton?: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: ${p => (p.withButton ? 'space-between' : 'flex-end')};
  ${media.md`
   flex-direction: column;
   justify-content: flex-end;
  `};
`

export const Amount = styled.p`
  font-family: Inter, sans-serif;
  font-weight: 300;
  font-size: 1.125rem;
  line-height: 1.375rem;
  color: ${p => p.theme.colors.fuchsia};
  ${media.md`
     font-size: 1.625rem;
  line-height: 3.75rem;
  `};
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 15px;
  bottom: 15px;
  right: 15px;
  left: 15px;
  z-index: 2;
  ${media.md`
    top: 25px;
    bottom: 25px;
    right: 25px;
    left: 25px;
  `}
  ${media.lg`
    top: 50px;
    bottom: 50px;
    right: 50px;
    left: 50px;
  `}
`

export const FallbackImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
`

export const SocialButtonsWrapper = styled.div`
  ${media.md`
    margin-top: 33px;
  `}
`
export const LearnMore = styled(Link).attrs(() => ({
  variant: ButtonVariant.Default
}))`
  border-radius: 36px;
  font-size: 1rem;
  padding: 1rem;
  @media (max-width: 374px) {
    font-size: 0.8125rem;
    letter-spacing: 0.063rem;
    padding: 1rem 0.313rem;
  }
  ${media.md`
     letter-spacing: 0.125rem;
     font-size: 1.125rem;
     padding: 1rem 1.125rem;
  `};
`

// ***** VIDEO ***** //

export const PlayButton = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: none;
  letter-spacing: 2px;
  color: ${p => p.theme.colors.white};
  font-family: Inter, sans-serif;
  font-size: 0.8125rem;
  text-transform: uppercase;
  text-align: left;
  margin-bottom: 23px;
  max-width: 110px;
  svg {
    margin-right: 10px;
    pointer-events: none;
  }

  ${media.md`
    margin-bottom: 0;
    max-width: unset;
    font-size: 0.875rem;
       svg {
      margin-right: 12px;
    }
  `}

  ${media.xs`
     margin-bottom: 10px;
  `}
`

export const VideoPreview = styled.div<{ img: string }>`
  width: 100%;
  height: 100%;
  background: url(${p => p.img}),
    linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 1) 90%);
  background-blend-mode: overlay;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
`

export const PlayerWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 12rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Player = styled.video<{ isLoading: boolean }>`
  width: 100%;
  height: 100%;
  display: ${p => (p.isLoading ? 'none' : 'block')};
  outline: none;
`
