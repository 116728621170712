import { AppState } from 'store'
import { selectEvents, selectEventsDetail } from '../selectors'
import { IComment, IReply } from './types'

export const selectComments = (state: AppState) => selectEvents(state).comments
export const selectEventsComments = (state: AppState) =>
  selectComments(state).data
export const selectEventsShownComments = (state: AppState) =>
  selectComments(state).shownComments
export const selectEventsComment = (state: AppState) =>
  selectComments(state).singleCommentThread
export const selectActiveCommentId = (state: AppState) =>
  selectComments(state).activeCommentId
export const selectCommentList = (state: AppState) =>
  selectEventsComments(state).data
export const selectCommentCount = (state: AppState) =>
  selectEventsDetail(state).comments_count
export const selectIsFetchingComments = (state: AppState) =>
  selectComments(state).isFetching
export const selectIsSendingComments = (state: AppState) =>
  selectComments(state).isSending
export const selectIsFetchingReplies = (state: AppState) =>
  selectEvents(state).isFetchingReplies
export const getCommentsLeftNumber = (
  allCommentsNumber: number,
  shownCommentsList: IComment[] | IReply[]
) => allCommentsNumber - shownCommentsList.length
