import styled from 'styled-components'

export const PaymentWrap = styled.div`
  padding: 0 0 2rem;
  color: #202532;
  max-width: 750px;
`
export const PriceRow = styled.div<{ large?: boolean }>`
  display: flex;
  justify-content: space-between;
  padding-top: ${p => (p.large ? '1rem' : '0')};
  p,
  h4 {
    font-weight: 400;
    font-family: 'Acumin Pro';
    font-size: ${p => (p.large ? '18px' : '14px')};
    line-height: 16px;
    color: #ffffff;
  }
  p {
    font-weight: bold;
    color: ${p => (p.large ? p.theme.colors.gold : p.theme.colors.white)};
  }
`
