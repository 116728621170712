import styled from 'styled-components'

export const WrapBlock = styled.div`
  .offers-container {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
    @media (min-width: 1346px) {
      max-width: 1465px;
    }
  }
`

export const Wrapper = styled.div`
  margin: 20px auto;

  p {
    color: ${p => p.theme.colors.fuchsia};
  }
`
