import { ActionType } from 'typesafe-actions'
import * as actions from './actions'
import { ISocial, Statuses } from '../commonTypes'

export type AuthAction =
  | ActionType<typeof actions.loginAsync>
  | ActionType<typeof actions.socialLoginAsync>
  | ActionType<typeof actions.socialLoginEmailAsync>
  | ActionType<typeof actions.profileSettingsAsync>
  | ActionType<typeof actions.registerAsync>
  | ActionType<typeof actions.verifyAsync>
  | ActionType<typeof actions.resendAsync>
  | ActionType<typeof actions.logout>
  | ActionType<typeof actions.clearAuthErrors>
  | ActionType<typeof actions.setAuthenticated>
  | ActionType<typeof actions.forgotAsync>
  | ActionType<typeof actions.recoveryPasswordAsync>
  | ActionType<typeof actions.changePasswordAsync>
  | ActionType<typeof actions.pushSubscriptionToken>
  | ActionType<typeof actions.setProfilePasswordErrors>
  | ActionType<typeof actions.setBetablocksParams>
  | ActionType<typeof actions.showModal>
  | ActionType<typeof actions.hideModal>
  | ActionType<typeof actions.setAccessToken>
  | ActionType<typeof actions.betablocksLoginAsync>

export interface AuthState {
  isFetchingLoggedIn: boolean
  isAuthenticated: boolean
  isRegistered: boolean
  isForceChangePassword: boolean
  user: IUser
  accessToken: string
  preferences: IUserPreference[]
  errors: any
  showModal: boolean
  modalMessage: string
  betablockAccess: boolean
  betablockParams: any
}

export enum AuthActionTypes {
  LOGIN_REQUEST = '@@auth/LOGIN_REQUEST',
  LOGIN_SUCCESS = '@@auth/LOGIN_SUCCESS',
  LOGIN_FAIL = '@@auth/LOGIN_FAIL',
  SOCIAL_LOGIN_REQUEST = '@@auth/SOCIAL_LOGIN_REQUEST',
  SOCIAL_LOGIN_SUCCESS = '@@auth/SOCIAL_LOGIN_SUCCESS',
  SOCIAL_LOGIN_FAIL = '@@auth/SOCIAL_LOGIN_FAIL',
  SOCIAL_LOGIN_EMAIL_REQUEST = '@@auth/SOCIAL_LOGIN_EMAIL_REQUEST',
  SOCIAL_LOGIN_EMAIL_SUCCESS = '@@auth/SOCIAL_LOGIN_EMAIL_SUCCESS',
  SOCIAL_LOGIN_EMAIL_FAIL = '@@auth/SOCIAL_LOGIN_EMAIL_FAIL',
  BETABLOCK_LOGIN_REQUEST = '@@auth/BETABLOCK_LOGIN_REQUEST',
  BETABLOCK_LOGIN_SUCCESS = '@@auth/BETABLOCK_LOGIN_SUCCESS',
  BETABLOCK_LOGIN_FAIL = '@@auth/BETABLOCK_LOGIN_FAIL',
  FORGOT_REQUEST = '@@auth/FORGOT_REQUEST',
  FORGOT_SUCCESS = '@@auth/FORGOT_SUCCESS',
  FORGOT_FAIL = '@@auth/FORGOT_FAIL',
  PROFILE_SETTINGS_REQUEST = '@@auth/profile/PROFILE_SETTINGS_REQUEST',
  PROFILE_SETTINGS_SUCCESS = '@@auth/profile/PROFILE_SETTINGS_SUCCESS',
  PROFILE_SETTINGS_FAIL = '@@auth/PROFILE_SETTINGS_FAIL',
  REGISTER_REQUEST = '@@auth/REGISTER_REQUEST',
  REGISTER_SUCCESS = '@@auth/REGISTER_SUCCESS',
  REGISTER_FAIL = '@@auth/REGISTER_FAIL',
  VERIFY_REQUEST = '@@auth/VERIFY_REQUEST',
  VERIFY_SUCCESS = '@@auth/VERIFY_SUCCESS',
  VERIFY_FAIL = '@@auth/VERIFY_FAIL',
  RESEND_REQUEST = '@@auth/RESEND_REQUEST',
  RESEND_SUCCESS = '@@auth/RESEND_SUCCESS',
  RESEND_FAIL = '@@auth/RESEND_FAIL',
  LOGOUT = '@@auth/LOGOUT',
  SET_AUTHENTICATED = '@@auth/SET_AUTHENTICATED',
  CLEAR_ERRORS = '@@auth/CLEAR_ERRORS',
  VERIFY_RECOVERY_REQUEST = '@@auth/VERIFY_RECOVERY_REQUEST',
  VERIFY_RECOVERY_SUCCESS = '@@auth/VERIFY_RECOVERY_SUCCESS',
  VERIFY_RECOVERY_FAIL = '@@auth/VERIFY_RECOVERY_FAIL',
  RECOVERY_PASSWORD_REQUEST = '@@auth/RECOVERY_PASSWORD_REQUEST',
  RECOVERY_PASSWORD_SUCCESS = '@@auth/RECOVERY_PASSWORD_SUCCESS',
  RECOVERY_PASSWORD_FAIL = '@@auth/RECOVERY_PASSWORD_FAIL',
  CHANGE_PASSWORD_REQUEST = '@@auth/profile/CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS = '@@auth/profile/CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAIL = '@@auth/profile/CHANGE_PASSWORD_FAIL',
  PATCH_PUSH_SUBSCRIPTION_TOKEN_REQUEST = '@@auth/PATCH_PUSH_SUBSCRIPTION_TOKEN_REQUEST',
  PATCH_PUSH_SUBSCRIPTION_TOKEN_SUCCESS = '@@auth/PATCH_PUSH_SUBSCRIPTION_TOKEN_SUCCESS',
  PATCH_PUSH_SUBSCRIPTION_TOKEN_FAIL = '@@auth/PATCH_PUSH_SUBSCRIPTION_TOKEN_FAIL',
  SET_PROFILE_PASSWORD_ERRORS = '@@auth/profile/SET_PROFILE_PASSWORD_ERRORS',
  SET_BETABLOCK_PARAMS = '@@auth/profile/SET_BETABLOCK_PARAMS',
  SHOW_MODAL = '@@auth/profile/SHOW_MODAL',
  HIDE_MODAL = '@@auth/profile/HIDE_MODAL',
  SET_TOKEN = '@@auth/SET_TOKEN'
}

export enum ROLES {
  FAN = 1,
  BRAND = 2,
  ADMIN = 3
}

export interface IUserData {
  accessToken: string
  shouldChoosePreferences: boolean
  votedToday: boolean
  user: IUser
}

export interface IUser {
  adult: number
  call_prefix: number | null
  created_at: string // '2019-09-26 08:18:36'
  email: string
  events_count: number | null
  first_name: string
  followers: number | null
  following: boolean
  id: number
  influencer_name: string
  influencer_status: string
  last_name: string
  phone: string
  profile_photo: string
  role: ROLES
  updated_at: string // '2019-09-26 08:18:38'
  // from settings
  birth_date?: string // '2019-08-23T00:00:00.000000Z'
  influencer_description: string | null
  phone_verified: boolean
  social_links: ISocial[]
  status: Statuses
}

export enum AuthProviders {
  Original = 0,
  Google = 1,
  Facebook = 2,
  Apple = 3
}

export interface ISocialLoginPayload {
  provider: AuthProviders
  token: string
  email?: string
}

export type IForgotPayload = Pick<ILoginPayload, 'email'>

export interface ILoginPayload {
  email: string
  password: string
  device_id: string
  device_model: string
  device_os: string
}

export interface IBetablockParams {
  redirect_uri?: string
  client_id: string
  client_secret: string
}

export interface IRegisterPayload {
  first_name: string
  last_name: string
  email: string
  birth_date?: string
  password: string
  password_confirmation: string
  terms: number
  provider: string
  provider_id: string
  user_photo_url: string
  consent?: number
  device_id: string
  device_model: string
  device_os: string
}
export interface IUserDevice {
  os: string
  browser: string
  browserId: string
}
export interface IRegisterResponse {
  accessToken: string
  votedToday: boolean
}

export interface IUserPreference {
  id: number
  type: number
  name: string
  image: string
}

export interface IVerifyUser {
  accessToken: string
  user: IUser
  preferences: IUserPreference[]
  forceChangePassword?: boolean
}

export interface IConfirmPassword {
  password: string
  password_confirmation: string
}

export interface IChangePassword extends IConfirmPassword {
  current_password: string
}
