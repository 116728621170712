import React from 'react'

const UserIcon = () => (
  <svg
    width="17"
    height="21"
    viewBox="0 0 17 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.217 9.634c1.323 0 2.47-.475 3.406-1.411.936-.937 1.41-2.082 1.41-3.406 0-1.323-.474-2.47-1.41-3.406C10.686.475 9.54 0 8.216 0 6.893 0 5.747.475 4.81 1.411c-.937.937-1.412 2.082-1.412 3.406 0 1.324.475 2.47 1.412 3.406.936.936 2.082 1.411 3.406 1.411zM5.64 2.24C6.358 1.52 7.2 1.172 8.217 1.172c1.015 0 1.858.35 2.577 1.068.718.719 1.068 1.562 1.068 2.577 0 1.016-.35 1.858-1.068 2.577-.719.719-1.562 1.068-2.577 1.068-1.016 0-1.859-.35-2.577-1.068-.72-.718-1.069-1.561-1.069-2.577 0-1.015.35-1.858 1.069-2.577z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M16.646 15.378c-.027-.39-.082-.814-.162-1.263a9.968 9.968 0 00-.311-1.271 6.278 6.278 0 00-.523-1.185 4.468 4.468 0 00-.787-1.026 3.473 3.473 0 00-1.132-.711 3.91 3.91 0 00-1.444-.262c-.204 0-.402.084-.783.332-.235.153-.51.33-.816.526-.261.167-.617.323-1.055.465a4.19 4.19 0 01-1.29.209c-.429 0-.863-.07-1.291-.209-.438-.141-.793-.298-1.055-.465-.303-.194-.578-.37-.816-.526-.381-.248-.579-.332-.783-.332-.52 0-1.006.088-1.444.262a3.47 3.47 0 00-1.132.71 4.47 4.47 0 00-.787 1.027c-.217.382-.393.781-.522 1.185-.125.391-.23.818-.311 1.27-.08.449-.135.874-.162 1.265-.027.382-.04.78-.04 1.18 0 1.045.332 1.89.986 2.513.647.616 1.502.927 2.542.927h9.63c1.04 0 1.895-.311 2.542-.927.654-.622.986-1.468.986-2.512 0-.403-.014-.8-.04-1.182zm-1.755 2.845c-.427.407-.994.604-1.733.604h-9.63c-.74 0-1.307-.197-1.734-.604-.418-.398-.622-.943-.622-1.663 0-.375.012-.745.037-1.1.024-.349.073-.732.147-1.138.072-.402.164-.78.273-1.12a5.12 5.12 0 01.425-.964c.17-.298.364-.553.579-.758.2-.193.454-.35.752-.469.276-.109.587-.169.923-.178.042.022.115.064.233.141.24.157.519.336.826.532.346.221.792.42 1.324.592.545.176 1.1.266 1.652.266.55 0 1.106-.09 1.65-.266.534-.172.98-.371 1.326-.592.314-.2.584-.375.825-.532.119-.077.192-.119.233-.14.337.008.647.068.923.178.298.118.552.275.753.467.214.206.41.461.578.76.178.311.32.635.426.962.109.342.201.72.273 1.12.073.408.122.791.146 1.139.025.354.038.724.038 1.1 0 .72-.204 1.265-.622 1.663z"
      fill="url(#paint1_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="-.094"
        y1="7.357"
        x2="11.233"
        y2=".265"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BC9465" />
        <stop offset="1" stopColor="#D4AB72" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="-6.051"
        y1="17.555"
        x2="7.462"
        y2="3.903"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BC9465" />
        <stop offset="1" stopColor="#D4AB72" />
      </linearGradient>
    </defs>
  </svg>
)

export default UserIcon
