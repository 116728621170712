import React from 'react'
import Offers from 'components/NFTs/Offers'
import CustomTemplate from 'components/content/CustomTemplate'
import EventPageHeader from 'components/common/EventPageHeader'

const NFTsPage = () => (
  <CustomTemplate
    header={
      <EventPageHeader
        title={'NFTs'}
        subtitle={'Discover unique collectibles.'}
      />
    }
    inFlow={false}
  >
    <Offers />
  </CustomTemplate>
)

export default NFTsPage
