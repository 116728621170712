import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export const ItemsCounterWrapper = styled.div<{
  width: number
  size: 'small' | 'medium' | 'large'
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: ${p => (p.width ? `${p.width}px` : '85px')};
  width: 100%;
`

export const ItemsCounterWrapperSmall = styled(ItemsCounterWrapper)`
  ${media.xs`
    max-width: calc(4.2vw + 4rem);
  `}
`

export const ItemsCounterWrapperMedium = styled(ItemsCounterWrapper)`
  max-width: 100px;
  margin: auto 0 1rem auto;

  ${media.md`
    max-width: 130px;
    margin: 0;
  `}
`

export const ItemsCounterValue = styled.span<{
  isLight: boolean
}>`
  font-family: 'Lato';
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: ${p =>
    p.isLight ? p.theme.colors.darkenGold : p.theme.colors.fadedBlack};
`

export const ItemsCounterCircleButton = styled.div<{
  width: number
  isLight: boolean
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${p => (p.width ? `${p.width}px` : '22.5px')};
  height: ${p => (p.width ? `${p.width}px` : '22.5px')};
  border: 1.5px solid
    ${p => (p.isLight ? p.theme.colors.darkenGold : p.theme.colors.fadedBlack)};
  border-radius: 50%;
  cursor: pointer;
  transition: 0.1s ease-out;

  &:hover,
  &:active {
    background: rgba(157, 125, 80, 0.5);
    background: ${p =>
      p.isLight ? p.theme.colors.black : 'rgba(157, 125, 80, 0.5)'};
  }
`
