const EMAILS = {
  SUPPORT: 'support@fanvestor.com',
  PRIVACY: 'privacy@fanvestor.com',
  INFO: 'info@fanvestor.com',
  LEGAL: 'legal@edenledger.com'
}

const DOMAIN = 'https://open.fanvestor.com'

const SOCIALS = {
  FACEBOOK: 'http://facebook.com/fvestor',
  TWITTER: 'https://twitter.com/fvestor',
  INSTAGRAM: 'http://instagram.com/fvestor',
  LINKEDIN: 'https://www.linkedin.com/company/fvestor',
  YOUTUBE: 'https://www.youtube.com/channel/UCtJiZawMhsJge3dGX_3r5yA/'
}

const WEBSITE = 'https://www.fanvestor.com/'

const LINKS = {
  ABOUT_US: 'team',
  ACCESSIBILITY: 'accessibility',
  BLOG: 'blog',
  CAREERS: '#',
  CONTACT_US: 'contact-us',
  COOKIE: 'cookie-policy',
  EXPERIENCES: '/faq/#experiences',
  FAQ: 'faq',
  FORM_S_P: 'form-s-p',
  LEARN: 'learn',
  MEMBERSHIP: '#',
  PRESS: 'press',
  PRIVACY: 'privacy-policy',
  REG_CF: 'faq-reg-cf',
  RISK: 'risk-factors',
  TALENT: '#',
  TERMS: 'terms-and-conditions'
}

const EXTERNAL_LINKS = {
  BROKER_CHECK: 'https://brokercheck.finra.org/',
  DALMORE: 'https://www.dalmorefg.com/',
  FINRA: 'https://www.finra.org',
  FINRA_REGULATE:
    'https://www.finra.org/about/firms-we-regulate/funding-portals-we-regulate',
  SPIC: 'https://www.sipc.org/'
}
const OS = {
  iOS: 'iOS',
  MAC: 'Mac',
  ANDROID: 'Android'
}
const BROWSER = {
  MAC_SAFARI: 'Mac: Safari',
  MAC_CHROME: 'Mac: Chrome',
  SAFARI: 'Safari',
  CHROME: 'Chrome'
}
const consentAgreement =
  'I consent to the electronic delivery of all documents, notices, receipts, and agreements'

const unverifiedError =
  'Your email address was not verified when you first registered. We have emailed you a new verification code. Please enter it below.'

export {
  BROWSER,
  EMAILS,
  DOMAIN,
  OS,
  SOCIALS,
  WEBSITE,
  LINKS,
  EXTERNAL_LINKS,
  consentAgreement,
  unverifiedError
}
