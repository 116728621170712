import styled, { css } from 'styled-components'
import { media, Container, Row, Col } from 'styled-bootstrap-grid'
import Button from 'components/UI/Button'
import Link from 'components/UI/Link'
import { InfoBlock } from 'components/checkout/TierPreview/styles'
import Img from 'components/UI/Img'

export const Wrapper = styled(Container)`
  padding: 0 3vw;

  ${media.lg`
    max-width: none;  
  `}

  ${media.xl`
    max-width: none;      
  `}
`

export const EventLogo = styled(Img)`
  max-height: 180px;
  margin: 0.5rem 0;
  object-fit: cover;
  z-index: 1;
  width: 100%;
`

export const BlockWrapper = styled.div`
  background: ${p => p.theme.colors.white};
`
export const ImgEvent = styled(Img)`
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: 1;
`

export const TierSection = styled.div`
  background: ${p => p.theme.colors.gold};
  padding: 3vmax 0 0;

  h2 {
    font-size: calc(2.16vw + 1rem);
    text-transform: uppercase;
    color: ${p => p.theme.colors.black};
  }

  ${media.xl`
    h2 {
      font-size: 2.8125rem;
    }
  `}
`
export const EventDetailsWrapper = styled.div`
  padding: 3.5rem 0;
`
export const FundWrapper = styled.div`
  width: 100%;
  max-width: 18rem;
  padding-bottom: 4.25rem;
`
export const Description = styled.div.attrs(() => ({
  'data-testid': 'event-description'
}))`
  padding: 3vmax 0 0;
  width: 100%;
  color: ${p => p.theme.colors.black};
  overflow: hidden;

  p,
  div {
    span {
      font-size: 1rem !important;
      font-family: 'Poppins' !important;
    }
  }

  > div,
  > div h4,
  p {
    font-size: calc(0.6vh + 1rem);
    line-height: 1.8;
    font-weight: 300;
    font-family: 'Poppins';
  }

  h1,
  h3,
  h4,
  h5,
  h6,
  p,
  span {
    color: ${p => p.theme.colors.black};
  }

  h2 {
    font-family: Poppins;
    font-size: 2rem;
    color: ${p => p.theme.colors.gold};
    margin-bottom: 1rem;
    font-weight: 400;

    &:not(:first-child) {
      margin-top: 3rem;
    }
    ${media.md`
     font-size: 2.5rem;
   `}
  }

  ul,
  ol {
    margin-left: 30px;
  }
`
export const ReadMore = styled(Button).attrs(() => ({
  variant: 'clear',
  'data-testid': 'read-more-button'
}))<{
  isOpen: boolean
}>`
  margin-right: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${p => p.theme.colors.gold};
  font-weight: 500;
  letter-spacing: 0.02625rem;
  text-decoration: underline;
  transition: 0.5s all linear;
  position: relative;

  :after {
    content: '';
    width: 0.375rem;
    height: 0.375rem;
    transform: ${p => (p.isOpen ? 'rotate(135deg)' : 'rotate(-45deg)')};
    border-left: 1px solid ${p => p.theme.colors.gold};
    border-bottom: 1px solid ${p => p.theme.colors.gold};
    position: absolute;
    left: ${p => (p.isOpen ? '4rem' : '5rem')};
    ${p => p.isOpen && 'top: 50%;'}
  }

  :hover {
    color: ${p => p.theme.button.default.hover};

    :after {
      border-left: 1px solid ${p => p.theme.button.default.hover};
      border-bottom: 1px solid ${p => p.theme.button.default.hover};
    }
  }

  :active {
    color: ${p => p.theme.button.default.active};
    :after {
      border-left: 1px solid ${p => p.theme.button.default.active};
      border-bottom: 1px solid ${p => p.theme.button.default.active};
    }
  }
`
export const InfluencerName = styled(Link)`
  margin: auto 0;
  font-size: 1.25rem;
  font-weight: 500;
  color: ${p => p.theme.colors.white};
  transition: all 0.3s ease-out;

  :hover {
    color: ${p => p.theme.button.default.hover};
  }

  :active {
    color: ${p => p.theme.button.default.active};
  }
`
export const InfluencerNameWrapper = styled(InfoBlock)`
  width: 100%;
  position: absolute;
  top: 16.875rem;
  z-index: 1;
`

export const EventPhotoWrap = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 350px;
  margin: 0 auto;
  overflow: hidden;

  &:before {
    content: '';
    display: block;
    position: absolute;
    background-color: ${p => p.theme.colors.gold};
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  > div {
    position: relative;
    z-index: 1;
    background: none;
  }
  ${media.sm`
    height: 450px;
 `}
  ${media.md`
    height: 100vh;
 `}
`

export const WrapperLogo = styled.div`
  background: #000;
  div {
    img {
      margin: 2.5rem 0 !important;
      width: 130px !important;
      height: 130px !important;
    }
  }
`

export const MainTierBlock = styled.div`
  padding: 4vw;
  background: ${p => p.theme.colors.black};
`

export const TierTitle = styled.h2`
  margin-bottom: 1.25rem;
  font-weight: 400;
`
export const DescriptionSectionContentWithPicture = styled(Row)<{
  reverse?: string
}>`
  padding-bottom: 1.5rem;
  display: flex;
  align-items:  ${p => (p.reverse === 'above' ? 'center' : 'flex-start')};
  flex-direction: ${p =>
    p.reverse === 'above'
      ? 'column-reverse'
      : p.reverse === 'left'
      ? 'row-reverse'
      : 'row'};
  justify-content: ${p =>
    p.reverse === 'right'
      ? 'flex-start'
      : p.reverse === 'left'
      ? 'flex-end'
      : 'center'};
  // margin-top: ${p => (p.reverse === 'above' ? '30px' : 'unset')};
  ${media.xs`
    ul:last-child {
      padding-bottom: 20px;
     }
   `}

  ${media.md`
    ul:last-child {
      padding-bottom: 20px;
     }
 `}
 div {
    width: ${p => (p.reverse === 'above' ? '100%' : null)};
    max-width: ${p => (p.reverse === 'above' ? '100%' : null)};
    p {
      padding-top: ${p => (p.reverse === 'above' ? '30px' : null)};
      text-align: ${p => (p.reverse === 'above' ? 'justify' : null)};
    }
  }
`

export const DescriptionSectionPicture = styled.img<{
  size?: 'left' | 'right' | 'above'
}>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  ${p => {
    if (p.size !== 'above') {
      return css`
         ${media.md`
           height: initial;
         `}
         
          ${media.lg`
            width: 200px;
         `}
        
         ${media.xl`
           width: 280px;
           height: 280px;
         `}
      `
    }
  }}
`

export const DescriptionDiv = styled.div.attrs(() => ({
  'data-testid': 'event-description_section'
}))`
  padding-bottom: 1rem;

  &:last-child {
    padding-bottom: 0.625rem;
  }

  h2 {
    color: ${p => p.theme.colors.black};
  }
`

export const DescriptionSectionPictureCol = styled(Col)<{
  reverse?: string
}>`
  max-width: 100%;
  left: ${p => (p.reverse === 'left' ? 0 : undefined)};
  right: ${p => (p.reverse === 'right' ? 0 : undefined)};
  display: flex;
  justify-content: ${p =>
    p.reverse === 'right'
      ? 'flex-end'
      : p.reverse === 'left'
      ? 'flex-start'
      : 'center'};
`
export const WrapperSpin = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25vh;
`
export const ConversationTitle = styled.h2`
  text-transform: uppercase;
  margin-bottom: 2rem;
  font-size: 2rem;
  color: ${p => p.theme.colors.gold};
  font-weight: 400;
  font-family: Poppins;
  ${media.md`
     font-size: 2.5rem;
   `}
`
