import React, { FC } from 'react'
import { generatePath } from 'react-router-dom'
import { IUser } from 'redux/auth/types'
import { routePath } from 'routes'
import MyImage from 'components/UI/Img'

import { LinkWrapper, InfluencerName, Status, Description } from './styles'

interface Props {
  influencer: IUser
  hideSearchResults?: () => void
}

const InfluencerCard: FC<Props> = ({ influencer }) => (
  <LinkWrapper
    to={generatePath(routePath.INFLUENCER_PAGE, { id: influencer.id })}
  >
    <MyImage src={influencer.profile_photo} alt={influencer.influencer_name} />
    <InfluencerName>{influencer.influencer_name}</InfluencerName>
    <Status>{influencer.influencer_status}</Status>
    <Description>{influencer.influencer_description}</Description>
    {/*{influencer.influencer_description && (*/}
    {/*  <AdditionalDescription>*/}
    {/*    {influencer.influencer_description}*/}
    {/*  </AdditionalDescription>*/}
    {/*)}*/}
  </LinkWrapper>
)

export default InfluencerCard
