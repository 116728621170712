import styled, { css } from 'styled-components'
import { media } from 'styled-bootstrap-grid'

const textStyles = css`
  margin-bottom: 2.625rem;
  font-family: Inter;
  text-align: center;
`

export const Wrapper = styled.div`
  background: ${p => p.theme.colors.navy};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100%;
`
export const ThankYouWrapper = styled.div`
  padding: 0 2.25rem;
  ${media.md`
    max-width: 35.25rem;
  `}

  svg {
    display: block;
    margin: 0 auto 40px;
  }

  a {
    display: block;
    margin: 0 auto;
    max-width: 11.875rem;
  }
`

export const Title = styled.h1`
  padding: 0 0.5rem;
  font-size: 2.25rem;
  font-weight: 500;
  line-height: 3.125rem;
  ${() => textStyles}
`

export const Description = styled.p`
  font-size: 1.375rem;
  font-weight: 400;
  line-height: 1.875rem;
  ${() => textStyles}
`
