import styled, { css } from 'styled-components'
import { Col, media } from 'styled-bootstrap-grid'
import NavLink from 'components/UI/NavLink'
import Link from 'components/UI/Link'
import Button from 'src/components/UI/Button'

const flexContainer = (justify: string) => css`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${justify};
`

export const MenuWrapper = styled.div<{
  isActive?: boolean
}>`
  position: fixed;
  top: 74px;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  padding: 2rem 0.375rem;
  overflow: overlay;
  background: ${p => p.theme.colors.navy};
  ${media.md`
    position: static;
    height: auto;
    overflow: unset;
  `}
  @media screen and (min-width: 850px) {
    padding: 3.25rem 6%;
  }
`

export const CartItemCounter = styled.span`
  font-size: 0.875rem;
  font-weight: 300;
  color: ${p => p.theme.colors.white};
  letter-spacing: 0.125rem;
`

export const Dropdown = styled.div`
  cursor: pointer;
  position: relative;

  ul {
    min-width: 10.75rem;
    padding: 1rem;
    padding-left: 4rem;
    display: none;
    position: absolute;
    right: 0;
    text-align: right;
    background: ${p => p.theme.colors.black};

    a {
      width: 100%;
    }
  }

  :hover {
    ul {
      display: flex;
      flex-direction: column;
    }
  }
`

export const UserMenuList = styled.ul`
  width: 100%;
  list-style: none;
  border-radius: 0.75rem;
  background-color: ${p => p.theme.colors.blue};
  transition: all 0.1s ease-out;
  overflow: hidden;
  box-shadow: 0 30px 60px rgba(0, 0, 0, 0.8);

  ${media.md`
    width: 11.625rem;
    position: absolute;
    right: 0;
    top: calc(100% + 20px);
    z-index: 1;
  `}
`

export const UserMenuItem = styled.li`
  :first-of-type a {
    padding-top: 1rem;
  }
  :last-of-type a {
    padding-bottom: 1rem;
  }
  a {
    display: block;
    font-family: Inter;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.5rem;
    color: ${p => p.theme.colors.white};
    padding: 0.625rem 1.25rem;
  }
  a.active,
  a:hover {
    background-color: ${p => p.theme.colors.lightBlue};
  }
`

export const NavWrapper = styled.div`
  width: 100%;
  min-height: 4.625rem;
  padding: 0.875rem 1.25rem;
  display: flex;
  ${media.xs`
    padding: 0.875rem 15px;
  `}
  ${media.md`
    padding: 0.875rem 1.875rem;
  `}
`

export const ShopIconWrapper = styled.div<{
  isOpen: boolean
  isShopping: boolean
}>`
  ${() => flexContainer('center')};
  transition: 0.1s ease-out;
  cursor: ${p => (p.isShopping ? 'auto' : 'pointer')};

  &:hover,
  &:active,
  &:focus {
    border-color: ${p => !p.isShopping && '#c4a476'};
  }
`

export const ContentWrapper = styled.div`
  ${() => flexContainer('space-between')};
  flex-direction: column;
`

export const NavBar = styled.header.attrs(() => ({
  className: 'navbar'
}))<{ isOpen: boolean; isScrolled: boolean }>`
  position: fixed;
  top: 0;
  z-index: 999;
  background-color: ${p =>
    p.isOpen
      ? p.theme.colors.navy
      : p.isScrolled
      ? p.theme.colors.navy
      : 'transparent'};
  transition: background-color 0.4s;
  .head-container {
    padding-left: 0;
    padding-right: 0;
    max-width: none;
  }
`

export const RightNavBlock = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`

export const MainPageLink = styled(NavLink)`
  color: ${p => p.theme.colors.white};
  font-family: Inter;
  font-size: 2rem;
  font-weight: 900;
  line-height: 1;
  text-transform: capitalize;
  margin-right: 1rem;
  :hover {
    color: ${p => p.theme.colors.fuchsia};
  }

  ${media.md`
    font-size: 2.25rem;
    margin-right: 0;
  `}

  span {
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    text-transform: inherit;
    white-space: nowrap;
    :hover {
      color: inherit;
    }
    ${media.md`
    font-size: inherit;
  `}
  }
`

export const MainLinks = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding-bottom: 1.875rem;
  li {
    :not(:last-child) {
      margin-bottom: 1rem;
    }
  }
  a.active {
    color: ${p => p.theme.colors.fuchsia};
  }

  ${media.md`
     flex-direction: column;
     padding-bottom: 0;
       li {
    :not(:last-child) {
      margin-bottom: 1.25rem;
    }
  }
  `}
`

export const GeneralLinks = styled.div`
  display: grid;
  grid-gap: 1.875rem;
  grid-template-columns: 1fr 1fr;

  * {
    text-transform: capitalize;
    font-family: Inter;
    font-size: 1.125rem;
    line-height: 1.375rem;
  }

  h3 {
    margin-bottom: 0.625rem;
    font-weight: 800;

    ${media.md`
      margin-bottom: 1rem;
    `}
  }

  ul {
    list-style: none;

    a {
      font-weight: 300;
      color: ${p => p.theme.colors.white};
    }

    li {
      :not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }

  ${media.md`
    padding-bottom: 0;
    #company {
      grid-area: company;
    }
    #faq {
      grid-area: faq;
    }
    #learn {
      grid-area: learn;
    }
    #join {
      grid-area: join;
    }
    #legal {
      grid-area: legal;
    }
    grid-template-areas: "company faq learn legal"
                         "company faq join legal";
    grid-template-columns: 1fr 1fr 1fr 1fr;
  `}
`

export const LoginLink = styled(Link)`
  font-family: Inter;
  font-size: 0.875rem;
  font-weight: 300;
  letter-spacing: 0.125rem;
  color: ${p => p.theme.colors.white};
  text-transform: uppercase;
  min-width: 4.5rem;
  :not(a) {
    @media (max-width: 880px) {
      display: none;
    }
  }
`

export const CloseButton = styled(LoginLink).attrs(() => ({
  as: Button,
  to: '#'
}))`
  padding-left: 1rem;
`

export const BurgerWrapper = styled.div`
  display: flex;
`
export const LogoWrapper = styled(Col)`
  padding: 0;
  @media (min-width: 360px) {
    display: flex;
    justify-content: center;
  }
`
