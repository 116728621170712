import styled from 'styled-components'

export const Wrapper = styled.div`
  margin-bottom: 2rem;

  .tab-nav {
    max-width: 370px;
    margin: auto;

    .tab p {
      font-size: 20px;

      @media (max-width: 575px) {
        display: block;
      }
    }
  }
`
