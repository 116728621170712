import React from 'react'

const PencilIcon = style => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <path
      fill="#ecc576"
      {...style}
      d="M19.021 5.703L6.463 18.263 0 20l1.737-6.462L14.297.978A3.32 3.32 0 0116.659 0a3.32 3.32 0 012.362.979 3.345 3.345 0 010 4.724zM6.314 16.754l8.174-8.174-3.068-3.068-8.174 8.174 1.12 1.12L11.7 7.471l.829.829-7.335 7.334zm9.002-9.003l1.074-1.073-3.068-3.068-1.073 1.073zM1.66 18.341l3.612-.972-2.641-2.64zM18.193 4.874a2.172 2.172 0 000-3.068 2.172 2.172 0 00-3.068 0l-.974.974 3.068 3.068z"
    />
  </svg>
)

export default PencilIcon
