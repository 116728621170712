import styled from 'styled-components'
import { Input } from 'src/components/UI/TextInput/styles'

export const Control = styled.div<{
  isValid?: boolean
}>`
  margin-right: 0.5rem;
  height: 25px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid ${p => (p.isValid ? 'transparent' : '#FF3A44')};
`
export const DropdownWrapper = styled.div<{
  isOpen: boolean
}>`
  position: absolute;
  left: 0;
  min-width: 100%;
  display: ${p => (p.isOpen ? 'flex' : 'none')};
  flex-direction: column;
  background-color: #eee;
  overflow: auto;
  border-radius: 0.75rem;
  -ms-overflow-style: none;
  scrollbar-width: none;
  z-index: 15;
`
export const SelectedValue = styled.div`
  cursor: pointer;
  display: flex;
  position: relative;
  top: -3px;
  font-family: Inter;
  font-size: 0.75rem;
`
export const FilterSearch = styled(Input).attrs(() => ({
  autoComplete: 'off'
}))`
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  margin: 10px;
  width: auto;
  padding: 1rem 0.5rem;
  height: 2.5rem;
`
export const Search = styled(Input).attrs(() => ({ autoComplete: 'off' }))`
  cursor: pointer;
  height: auto;
  width: 40px;
  font-size: 0.75rem;
  padding: 0;
`
export const Options = styled.ul`
  display: block;
  min-width: 240px;
  max-height: 170px;
  overflow: auto;
  border-radius: 0.75rem 0 0.75rem 0.75rem;
  -ms-overflow-style: none;
  scrollbar-width: none;
  z-index: 15;

  ::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 30px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    background-color: #e5e5e5;
  }
`
export const Option = styled.li`
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  background-color: ${p => p.theme.colors.greyPearl};
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  padding: 10px;
  list-style: none;
  cursor: pointer;

  :hover {
    background-color: #e5e5e5;
  }

  :last-of-type {
    border-bottom: none;
  }
`
export const NoOption = styled(Option)`
  display: flex;
  justify-content: center;
  color: hsl(0, 0%, 60%);
  padding: 8px 12px;
  text-align: center;
  box-sizing: border-box;
  padding-bottom: 1rem;
  font-size: 1rem;

  :hover {
    background-color: transparent;
  }
`
