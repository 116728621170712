import React from 'react'

const AnalyticsIcon = style => (
  <svg
    width="23"
    height="20"
    viewBox="0 0 23 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs />
    <path
      d="M22.238 18.924H.538a.538.538 0 100 1.076h21.7a.538.538 0 000-1.076zM20.835 2.54h-3.78a.538.538 0 00-.537.538v3.659h-2.703V.538A.538.538 0 0013.277 0H9.498a.538.538 0 00-.538.538V10.72H6.257V6.146a.538.538 0 00-.538-.538H1.94a.538.538 0 00-.538.538v10.3c0 .298.241.539.538.539h18.895a.538.538 0 00.538-.538V3.078a.538.538 0 00-.538-.538zM5.18 15.91H2.478V6.684h2.703v9.225zm3.78 0H6.256v-4.113H8.96v4.113zm3.778-8.634v8.634h-2.703V1.076h2.703v6.199zm3.779 8.634h-2.703V7.813h2.703v8.096zm3.779 0h-2.703V3.616h2.703V15.91z"
      fill="#ECC576"
      {...style}
    />
  </svg>
)

export default AnalyticsIcon
