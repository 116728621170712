import { createReducer } from 'typesafe-actions'
import {
  InfluencerAction,
  InfluencerActionTypes,
  InfluencerState,
  IInfluencer
} from './types'

export const initialState: InfluencerState = {
  isFetching: false,
  user: { data: {} as IInfluencer },
  errors: {} as Error
}

export const influencerReducer = createReducer<
  InfluencerState,
  InfluencerAction
>(initialState, {
  [InfluencerActionTypes.FETCH_INFLUENCER_REQUEST]: (state, action) => ({
    ...state,
    isFetching: true,
    user: initialState.user
  }),
  [InfluencerActionTypes.FETCH_INFLUENCER_SUCCESS]: (state, action) => ({
    ...state,
    isFetching: false,
    user: action.payload
  }),
  [InfluencerActionTypes.FETCH_INFLUENCER_FAIL]: (state, action) => ({
    ...state,
    isFetching: false,
    errors: action.payload
  }),
  [InfluencerActionTypes.UPDATE_INFLUENCER_FAIL]: (state, action) => ({
    ...state,
    errors: action.payload
  }),
  [InfluencerActionTypes.UPLOAD_YOUTUBE_VIDEO_SUCCESS]: (state, action) => ({
    ...state,
    user: {
      data: {
        ...state.user.data,
        videos: [...state.user.data.videos].concat(action.payload.data)
      }
    }
  }),
  [InfluencerActionTypes.UPLOAD_YOUTUBE_VIDEO_FAIL]: (state, action) => ({
    ...state,
    errors: action.payload
  }),
  [InfluencerActionTypes.DELETE_YOUTUBE_VIDEO_SUCCESS]: (state, action) => ({
    ...state,
    user: {
      data: {
        ...state.user.data,
        videos: state.user.data.videos.filter(
          v => v.id !== action.payload.video_id
        )
      }
    }
  }),
  [InfluencerActionTypes.CLEAR_INFLUENCER]: state => ({
    ...state,
    user: initialState.user
  }),
  [InfluencerActionTypes.CLEAR_ERRORS]: state => ({
    ...state,
    errors: initialState.errors
  }),
  [InfluencerActionTypes.FOLLOW_INFLUENCER_SUCCESS]: (state, action) => ({
    ...state,
    user: {
      data: {
        ...state.user.data,
        followers: state.user.data.followers + 1,
        following: true
      }
    }
  }),
  [InfluencerActionTypes.UNFOLLOW_INFLUENCER_SUCCESS]: (state, action) => ({
    ...state,
    user: {
      data: {
        ...state.user.data,
        followers: state.user.data.followers - 1,
        following: false
      }
    }
  })
})
