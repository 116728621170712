import React from 'react'

const AlertIcon = props => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity="0.85">
      <rect width="25" height="25" rx="12.5" fill="#FF3A44" {...props} />
      <path
        d="M13.6852 6.59091H11.3148L11.5279 14.2294H13.4775L13.6852 6.59091ZM12.5027 17.6385C13.1845 17.6385 13.7757 17.0685 13.7811 16.3601C13.7757 15.6623 13.1845 15.0923 12.5027 15.0923C11.7995 15.0923 11.2189 15.6623 11.2243 16.3601C11.2189 17.0685 11.7995 17.6385 12.5027 17.6385Z"
        fill="white"
      />
    </g>
  </svg>
)

export default AlertIcon
