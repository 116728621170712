import { selectBuyNowTier } from 'redux/checkout/selectors'
import { AppState } from 'store'
import { IEventsByInfluencerState } from './types'
export * from './comments/selectors'

export const selectEvents = (state: AppState) => state.events

export const selectEventsIsFetching = (state: AppState) =>
  selectEvents(state).isFetching
export const selectEventsIsLoaded = (state: AppState) =>
  selectEvents(state).isLoaded
export const selectEventsDetail = (state: AppState) =>
  selectEvents(state).detail
export const selectEventsCount = (state: AppState) =>
  selectEvents(state).eventsCount
export const eventDetail = (state: AppState) => selectEventsDetail(state).tiers
export const selectEventsList = (state: AppState) => selectEvents(state).list
export const selectPurchased = (state: AppState) =>
  selectEvents(state).purchased
export const selectInfluencers = (state: AppState) =>
  selectEvents(state).influencers

export const selectEventsErrors = (state: AppState) =>
  selectEvents(state).errors

export const selectInvestments = (state: AppState) =>
  selectEvents(state).investments

export const selectExperiences = (state: AppState) =>
  selectEvents(state).experiences

export const selectNFTs = (state: AppState) => selectEvents(state).nft

export const selectIsExperiencesFetching = (state: AppState) =>
  selectExperiences(state).isFetching

export const selectIsInvestmentsFetching = (state: AppState) =>
  selectInvestments(state).isFetching

export const selectIsNFTsFetching = (state: AppState) =>
  selectNFTs(state).isFetching

export const selectEventSubtype = (state: AppState) =>
  selectEventsDetail(state).sub_type
export const selectEventsByInfluencer = (
  state: AppState
): { [key: string]: IEventsByInfluencerState } =>
  selectEvents(state).eventsByInfluencer

export const selectOtherTiers = (state: AppState) =>
  state.events.detail.tiers &&
  state.events.detail.tiers
    .filter(t => t.id !== selectBuyNowTier(state).id)
    .slice(0, 3)

export const selectInfluencerSearchField = (state: AppState): string =>
  selectEvents(state).influencers.influencersSearchField
