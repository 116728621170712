import React, { FC, KeyboardEvent, useEffect, useState } from 'react'
import queryString from 'querystring'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { IBetablockParams } from 'redux/auth/types'
import { selectBetablockParams } from 'redux/auth/selectors'
import { routePath } from 'routes'
import { unverifiedError } from 'utils/constants'
import { parseBetablockSearchParams } from 'utils/helpers'
import { SignUpLink } from 'components/auth/SignInForm/styles'
import Link from 'src/components/UI/Link'
import TextInput from 'src/components/UI/TextInput'
import Button from 'src/components/UI/Button'
import ErrorLabel from 'components/UI/ErrorLabel'

import { Wrapper, Subtitle, ButtonWrapper, SendButtonWrapper } from './styles'

interface CodeState {
  number_one: string
  number_two: string
  number_three: string
  number_four: string
}

interface IFormProps {
  onSubmit: (token: string, params?: IBetablockParams) => void
  resend: any
  serverErrors?: any
  clearErrors: () => void
  isFetching: boolean
  accessToken?: string
}

const EnterCodeForm: FC<IFormProps> = ({
  accessToken,
  clearErrors,
  serverErrors,
  resend,
  isFetching,
  onSubmit
}) => {
  const [code, setCode] = useState<CodeState>({
    number_one: '',
    number_two: '',
    number_three: '',
    number_four: ''
  })
  const [disabled, setDisabled] = useState<boolean>(true)
  const location = useLocation<any>()
  const userParams = useSelector(selectBetablockParams)
  const isBetaBlock = [
    routePath.SIGN_UP_BETABLOCK,
    routePath.FORGOT_BETABLOCK
  ].includes(location.pathname)
  const { clientParams, redirectURI } = parseBetablockSearchParams(
    location,
    isBetaBlock
  )

  useEffect(() => {
    if (accessToken) {
      resendCode()
    }
    return () => {
      clearErrors()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (serverErrors) {
      clearErrors()
    }
    // eslint-disable-next-line
  }, [code])

  useEffect(() => {
    if (isBetaBlock && Object.keys(userParams).length) {
      const params = {
        ...userParams,
        ...clientParams
      }
      const stringifiedParams = queryString.stringify(params)
      // @ts-ignore
      window.location.replace(redirectURI + '?' + stringifiedParams)
    }

    // eslint-disable-next-line
  }, [userParams])

  const handleChange = (name: string, value: string) => {
    setCode({
      ...code,
      [name]: value
    })
  }

  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    const { currentTarget } = e
    const form = currentTarget.closest('form')
    const allInputs = form ? form.getElementsByTagName('input') : []

    const arr = Array.from(allInputs)

    if (arr.every(el => el.value)) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }

    if (e.keyCode === 8) {
      return arr.forEach((el, idx) =>
        el === currentTarget && idx - 1 !== -1
          ? allInputs[idx - 1].focus()
          : null
      )
    }

    return arr.forEach((el, idx) => {
      if (
        currentTarget.value.match(/[0-9]/) &&
        el === currentTarget &&
        idx + 1 !== arr.length
      ) {
        return allInputs[idx + 1].focus()
      }
      return null
    })
  }

  const resendCode = () => {
    resend(accessToken)
  }

  const handleSubmit = async () => {
    const token = Object.values(code).join('')

    if (token.length === 4) {
      isBetaBlock
        ? onSubmit(token, {
            ...userParams,
            ...clientParams
          })
        : onSubmit(token)
    }
  }
  const maskConfig = { mask: '9', maskChar: null }
  return (
    <>
      <Subtitle>
        {accessToken
          ? unverifiedError
          : 'Enter the verification code sent to your email address.'}
      </Subtitle>
      <div style={{ textAlign: 'center', marginBottom: 30 }}>
        <Wrapper>
          <TextInput
            name={'number_one'}
            type={'tel'}
            value={code.number_one}
            handleChange={handleChange}
            handleKeyUp={handleKeyUp}
            placeholder={' '}
            maskConfig={maskConfig}
            wrapperClassName={'code_input'}
          />
          <TextInput
            name={'number_two'}
            type={'tel'}
            value={code.number_two}
            handleChange={handleChange}
            handleKeyUp={handleKeyUp}
            placeholder={' '}
            maskConfig={maskConfig}
            wrapperClassName={'code_input'}
          />
          <TextInput
            name={'number_three'}
            type={'tel'}
            value={code.number_three}
            handleChange={handleChange}
            handleKeyUp={handleKeyUp}
            placeholder={' '}
            maskConfig={maskConfig}
            wrapperClassName={'code_input'}
          />
          <TextInput
            name={'number_four'}
            type={'tel'}
            value={code.number_four}
            handleChange={handleChange}
            handleKeyUp={handleKeyUp}
            placeholder={' '}
            maskConfig={maskConfig}
            wrapperClassName={'code_input'}
          />
        </Wrapper>
        <ErrorLabel
          label={
            serverErrors && !accessToken
              ? 'Code expired or does not exist'
              : ' '
          }
        />
      </div>

      <ButtonWrapper>
        <Button variant={'clear'} onClick={resendCode}>
          Resend Code >
        </Button>
      </ButtonWrapper>

      <SendButtonWrapper style={{ marginBottom: '20px', width: '330px' }}>
        <Button
          variant={'default'}
          type={'submit'}
          disabled={disabled || isFetching}
          isLoading={isFetching}
          onClick={handleSubmit}
        >
          Next
        </Button>
      </SendButtonWrapper>
      <SignUpLink>
        Have an account?{' '}
        <Link
          to={
            isBetaBlock
              ? {
                  pathname: routePath.SIGN_IN_BETABLOCK,
                  search: location.search
                }
              : { pathname: routePath.SIGN_IN }
          }
          variant={'clear'}
        >
          Log in
        </Link>
      </SignUpLink>
    </>
  )
}

export default EnterCodeForm
