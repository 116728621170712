import React, { FC } from 'react'
import { generatePath } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import AnalyticsManager from 'services/analyticService'
import routePath from 'routes/routePath'
import { fetchTierDetails } from 'redux/investment/operations'
import { IEventTier } from 'redux/events/types'
import { Statuses } from 'redux/commonTypes'

import {
  DescText,
  TierInfo,
  TierTitle,
  WrapperBlock,
  InvestNowLink,
  DescTextMobile
} from './styles'

export interface TierProps {
  tier: IEventTier
  eventId?: number
  isActivePayment?: boolean
  showCheckoutBtn?: boolean
  isFanProfile?: boolean
  isBrand: boolean
  setActiveTier?: (id: number) => void
  isAuthenticated?: boolean
  withAmount?: boolean
  disabled?: boolean
}

const CheckoutButton = (
  tier: IEventTier,
  id?: number,
  setActiveTier?: (id: number) => void,
  disabled?: boolean
) => {
  const dispatch = useDispatch()

  const handleInvestment = () => {
    dispatch(fetchTierDetails(tier.id))
    AnalyticsManager.trackTierCheckoutFromEvent(tier.id)
    if (setActiveTier) {
      setActiveTier(tier.id)
    }
  }

  return (
    <InvestNowLink
      variant={'secondary'}
      disabled={disabled}
      to={'#'}
      onClick={handleInvestment}
    >
      Invest
    </InvestNowLink>
  )
}

const EventTier: FC<TierProps> = ({
  tier,
  eventId,
  isActivePayment,
  isBrand,
  setActiveTier,
  isAuthenticated,
  withAmount,
  disabled
}) => {
  const parent = document.querySelector('#tiers-block')
  const parentPosition = parent && parent.getBoundingClientRect().top
  const description =
    tier.description.length > 100
      ? `${tier.description.substr(0, 100)}...`
      : tier.description
  const renderButton = (
    tier: IEventTier,
    id?: number,
    setActiveTier?: (id: number) => void,
    disabled?: boolean
  ) => (
    <>
      {isAuthenticated &&
      !isBrand &&
      isActivePayment &&
      tier.status === Statuses.LIVE ? (
        CheckoutButton(tier, eventId, setActiveTier, disabled)
      ) : (
        <InvestNowLink
          to={{
            pathname: routePath.SIGN_IN,
            state: {
              from: generatePath(routePath.INVESTMENT, { id: eventId }),
              position: parentPosition
            }
          }}
        >
          Invest
        </InvestNowLink>
      )}
    </>
  )
  return (
    <>
      {!withAmount ? (
        <WrapperBlock>
          <TierInfo>
            <TierTitle>{tier.title}</TierTitle>
            <DescText>{tier.description}</DescText>
            <DescTextMobile>{description}</DescTextMobile>
          </TierInfo>

          {renderButton(tier, eventId, setActiveTier)}
        </WrapperBlock>
      ) : (
        <>{renderButton(tier, eventId, setActiveTier, disabled)}</>
      )}{' '}
    </>
  )
}

export default EventTier
