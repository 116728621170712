export const gallerySettings = {
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  cssEase: 'linear',
  touchMove: true,
  dots: false,
  centerMode: true,
  centerPadding: '0',
  responsive: [
    {
      breakpoint: 767,
      settings: {
        arrows: false
      }
    }
  ]
}

export const dotsSettings = {
  centerMode: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  cssEase: 'linear',
  centerPadding: '20%',
  touchMove: false,
  dots: false,
  focusOnSelect: true,
  arrows: false,
  infinity: true,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        centerPadding: '5%'
      }
    }
  ]
}
