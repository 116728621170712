import React, { Component } from 'react'
import Checkbox from 'components/UI/Checkbox'
import { OptionsBlock } from './styles'

interface IState {
  selected: string[]
}
interface Props {
  name: string
  selectAllLabel?: string
  options: Array<{ value: string; label: string }>
  selected?: string[]
  onChange: any
}

class OptionBlock extends Component<Props, IState> {
  constructor(props: Props) {
    super(props)

    this.state = {
      selected: props.selected || []
    }
  }

  handleChangeOption = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked, value } = event.target
    const { options: initOptions, onChange } = this.props
    this.setState(
      (prevState: IState) => {
        let options = checked
          ? [...prevState.selected, value]
          : prevState.selected.filter(option => option !== value)

        if (value === 'all') {
          options = checked ? initOptions.map(i => i.value) : []
        }

        return {
          ...prevState,
          selected: options
        }
      },
      () => {
        onChange(name, this.state.selected)
      }
    )
  }

  render() {
    const { name, options, selectAllLabel } = this.props
    const { selected } = this.state

    return (
      <OptionsBlock>
        {selectAllLabel && (
          <Checkbox
            variant={'dark'}
            name={name}
            checked={selected.length === options.length}
            label={selectAllLabel}
            value={'all'}
            handleChange={this.handleChangeOption}
          />
        )}

        {options.map(({ label, value }) => (
          <Checkbox
            key={value}
            variant={'dark'}
            name={name}
            checked={selected.includes(value)}
            label={label}
            value={String(value)}
            handleChange={this.handleChangeOption}
          />
        ))}
      </OptionsBlock>
    )
  }
}

export default OptionBlock
