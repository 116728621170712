import React from 'react'

const GoldSearchIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="#ecc576"
  >
    <defs />
    <path
      fill="#ecc576"
      d="M19.634 17.964a1.18 1.18 0 11-1.669 1.67l-5.47-5.474-.006.004a7.726 7.726 0 01-4.631 1.517A7.903 7.903 0 010 7.81 7.766 7.766 0 017.801.002a7.902 7.902 0 017.857 7.867 7.751 7.751 0 01-1.5 4.618zm-7.359-5.675a6.215 6.215 0 001.817-4.427 6.322 6.322 0 00-6.286-6.294 6.215 6.215 0 00-6.24 6.25 6.322 6.322 0 006.286 6.294 6.215 6.215 0 004.423-1.823z"
    />
  </svg>
)

export default GoldSearchIcon
