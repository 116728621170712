import React from 'react'

const PlayButtonIcon = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 34C26.3888 34 34 26.3888 34 17C34 7.61116 26.3888 0 17 0C7.61116 0 0 7.61116 0 17C0 26.3888 7.61116 34 17 34ZM13.9134 24.4562L24.0537 17.0001L13.9134 9.54395V24.4562Z"
      fill="white"
    />
  </svg>
)

export default PlayButtonIcon
