import React, { Component, Suspense, ReactNode } from 'react'
import { useImage } from 'react-image'
import { CSSObject } from 'styled-components'
import { LogoIcon } from 'components/icons'
import { Wrap } from './styles'

class ErrorBoundary extends Component<any, any> {
  state = {
    err: null
  }
  componentDidCatch(err: Error, errorInfo: React.ErrorInfo) {
    this.setState({ err })
  }

  render() {
    return this.state.err ? this.props.fallback : this.props.children
  }
}

function MyImage({
  source,
  alt,
  fallback,
  ...props
}: {
  source: string
  alt?: string
  fallback: React.ReactNode
}) {
  const { src } = useImage({
    srcList: source
  })

  return <img src={src} {...props} alt={alt} />
}

export default function Img({
  src,
  fallback,
  ...props
}: {
  src: string
  fallback?: ReactNode
  alt?: string
  className?: string
  style?: CSSObject
}) {
  const fallbackComp = fallback ? (
    <>{fallback}</>
  ) : (
    <Wrap>
      <LogoIcon width={'50%'} height={'50%'} marginBottom={0} light={false} />
    </Wrap>
  )

  return (
    <ErrorBoundary fallback={fallbackComp}>
      <Suspense fallback={fallbackComp}>
        <MyImage source={src} {...props} fallback={fallback} />
      </Suspense>
    </ErrorBoundary>
  )
}
