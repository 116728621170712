import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export const FormWrapper = styled.div`
  width: 100%;
  max-width: 862px;

  :not(input),
  :not(textarea) {
    user-select: none;
  }
  a:hover {
    transition: all 0.2s ease-out;
  }

  ${media.md`
    margin: 0 auto 1.75rem;
  `}
`

export const FormContainer = styled.form`
  padding: 50px 4.25rem;

  ${media.xs`
      padding: 20px;
  `}
`

export const FormTitle = styled.h1`
  font-family: Inter;
  font-size: 1.875rem;
  font-weight: 900;
  text-align: center;

  margin-bottom: 28px;

  ${media.lg`
    font-size: 2.5rem;
    margin-bottom: 26px;
  `}
`

export const FormContent = styled.div`
  div {
    width: auto;
  }
`

export const LogInWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  button {
    border-radius: 6.25rem;
    font-weight: 500;
    font-size: 0.875rem;
  }

  p,
  a {
    font-size: 0.875rem;
    ${media.md`
      font-size: 1rem;
  `}
  }

  p {
    margin-right: 0.25rem;
    font-weight: 400;
  }

  a {
    font-weight: bold;
  }
`
