import React from 'react'

const UploadPhotoIcon = props => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.3125 0.25C12.6587 0.25 13.75 1.34131 13.75 2.6875V11.3125C13.75 12.6587 12.6587 13.75 11.3125 13.75H2.6875C1.34131 13.75 0.25 12.6587 0.25 11.3125V2.6875C0.25 1.34131 1.34131 0.25 2.6875 0.25H11.3125ZM11.7479 12.5511L7.3935 8.28611C7.19471 8.09144 6.88759 8.07373 6.6691 8.23299L6.6064 8.28608L2.25134 12.5508C2.3878 12.5989 2.5346 12.625 2.6875 12.625H11.3125C11.4651 12.625 11.6117 12.599 11.7479 12.5511L7.3935 8.28611L11.7479 12.5511ZM11.3125 1.375H2.6875C1.96263 1.375 1.375 1.96263 1.375 2.6875V11.3125C1.375 11.4688 1.40232 11.6187 1.45245 11.7577L5.81934 7.48224C6.44415 6.87047 7.4256 6.84138 8.08433 7.39494L8.18063 7.48234L12.5473 11.7585C12.5976 11.6193 12.625 11.4691 12.625 11.3125V2.6875C12.625 1.96263 12.0374 1.375 11.3125 1.375ZM9.43909 2.875C10.3719 2.875 11.1282 3.63123 11.1282 4.56409C11.1282 5.49694 10.3719 6.25317 9.43909 6.25317C8.50623 6.25317 7.75 5.49694 7.75 4.56409C7.75 3.63123 8.50623 2.875 9.43909 2.875ZM9.43909 4C9.12755 4 8.875 4.25255 8.875 4.56409C8.875 4.87562 9.12755 5.12817 9.43909 5.12817C9.75062 5.12817 10.0032 4.87562 10.0032 4.56409C10.0032 4.25255 9.75062 4 9.43909 4Z"
      fill="white"
    />
  </svg>
)

export default UploadPhotoIcon
