import styled from 'styled-components'
import Link from 'components/UI/Link'

export const Header = styled.div<{ img?: string }>`
  min-height: 225px;
  padding-top: 2.25rem;
  background: ${p =>
    p.img
      ? `linear-gradient(rgba(0,0,0, 0.75), rgba(0,0,0, 1)), url(${p.img}) no-repeat center`
      : p.theme.colors.black};
  background-size: cover;
`
export const HeaderContent = styled.div`
  display: flex;
  padding-bottom: 3.5rem;
`
export const Option = styled(Link)`
  width: 2.75rem;
  height: 2.75rem;
  border: none;
  border-radius: 50%;
  background: #313131;
  opacity: 0.7;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
`
