import React, { FC } from 'react'
import { Container } from 'styled-bootstrap-grid'
import { IEventDetail } from 'redux/events/types'
import { toDashCase } from 'utils/helpers'
import { renderSection } from './helpers'
import {
  SectionWrapper,
  SectionTitle,
  MainContent
} from 'containers/invest/InvestDetailPage/styles'
import { theme } from 'styled/theme'

const AllSections: FC<{
  event: IEventDetail
}> = ({ event }) => {
  const exceptions = [
    'REASONS TO INVEST',
    'INVESTOR DECK',
    'DISCLOSURES',
    'DRIVING INVESTORS',
    'COMPANY HIGHLIGHTS',
    'MANAGEMENT TEAM',
    'PHOTO GALLERY'
  ]

  const sections =
    event &&
    event.sections &&
    event.sections.filter(el => {
      return !exceptions.includes(el.name)
    })

  return (
    <>
      {sections.map(({ id, name, type, items }) => (
        <MainContent sectionBackground="#f8f8f8" key={id}>
          <Container className={'content-container'}>
            <SectionWrapper id={toDashCase(name)}>
              <SectionTitle color={theme.colors.black}>{name}</SectionTitle>
              {renderSection(type, items)}
            </SectionWrapper>
          </Container>
        </MainContent>
      ))}
    </>
  )
}

export default AllSections
