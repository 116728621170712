import React from 'react'
import { Route, Switch, Redirect, RouteComponentProps } from 'react-router-dom'
import { EntityType } from 'redux/investment/types'
import Template from 'components/content/Template'
import { CheckoutPage } from 'containers/checkout/EventCheckoutPage'
import EventDetailPage from 'containers/EventDetailPage'
import TermsAndConditions from 'components/profile/settings/Policies/TermsAndConditions'
import SuccessPage from 'src/pages/SuccessPage'
import PrivacyPolicy from 'components/profile/settings/Policies/PrivacyPolicy'
import { PrivateRoute, routePath } from 'routes'
import AuthRoutes from './AuthRoutes'
import UserRoutes from './profile/UserRoutes'
import InvestPage from 'containers/invest/InvestPage'
import InvestDetailPage from 'containers/invest/InvestDetailPage'
import CommentDetailPage from 'containers/comment/CommentDetailPage'
import InfluencerRoutes from './InfluencerRoutes'
import {
  InvestNowPage,
  InvestNowProvider
} from 'src/pages/investment/InvestNowPage'
import CommonTemplate from 'components/content/CommonTemplate'
import SignInGuestPage from 'containers/SignInGuestPage'
import SignInPage from 'containers/SignInPage'
import SignUpPage from 'containers/SignUpPage'
import ForgotPage from 'containers/ForgotPage'
import NFTsEventPage from 'containers/NFTs/NFTDetailPage'
import NFTsPage from 'containers/NFTs/NFTsPage'
import ExperiencesPage from 'containers/experiences/ExperiencesPage'

const InvestNowWrapped = (
  props: RouteComponentProps<any, any, { amount: string; entity: EntityType }>
) => (
  <CommonTemplate>
    <InvestNowProvider>
      <InvestNowPage {...props} />
    </InvestNowProvider>
  </CommonTemplate>
)

const Routes = () => (
  <Switch>
    <PrivateRoute path={routePath.INVEST_NOW} component={InvestNowWrapped} />
    <Route path={routePath.SIGN_IN_GUEST} component={SignInGuestPage} />
    <Route path={routePath.SIGN_IN_BETABLOCK} component={SignInPage} />
    <Route path={routePath.SIGN_UP_BETABLOCK} component={SignUpPage} />
    <Route path={routePath.FORGOT_BETABLOCK} component={ForgotPage} />
    <Route path={routePath.AUTH} component={AuthRoutes} />
    <Route exact={true} path={routePath.NFT_PAGE} component={NFTsPage} />
    <Route path={routePath.INFLUENCER} component={InfluencerRoutes} />
    <Route
      exact={true}
      path={routePath.CHECKOUT_SUCCESS}
      component={SuccessPage}
    />
    <Route exact={true} path={routePath.INVEST_PAGE} component={InvestPage} />
    <Route
      exact={true}
      path={routePath.EVENTS_PAGE}
      component={ExperiencesPage}
    />
    <Template>
      <Switch>
        <PrivateRoute path={routePath.PROFILE} component={UserRoutes} />
        <PrivateRoute path={routePath.CHECKOUT_CART} component={CheckoutPage} />
        <Route path={routePath.CHECKOUT} component={CheckoutPage} />
        <Route
          exact={true}
          path={routePath.EVENT}
          component={EventDetailPage}
        />
        <Route
          exact={true}
          path={routePath.INVESTMENT}
          component={InvestDetailPage}
        />
        <Route
          exact={true}
          path={routePath.NFT_DETAIL_PAGE}
          component={NFTsEventPage}
        />
        <Route
          exact={true}
          path={routePath.COMMENT_DETAILS}
          component={CommentDetailPage}
        />
        <Route
          exact={true}
          path={routePath.TERMS}
          component={TermsAndConditions}
        />
        <Route
          exact={true}
          path={routePath.PRIVACY}
          component={PrivacyPolicy}
        />
        <Redirect from={routePath.INDEX} to={routePath.START_PAGE} />
      </Switch>
    </Template>
  </Switch>
)

export default Routes
