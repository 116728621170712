import React, { useEffect, FC } from 'react'
import { Container } from 'styled-bootstrap-grid'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useLocation, useParams } from 'react-router-dom'
import { routePath } from 'routes'
import { fetchComments, fetchEvent } from 'redux/events/operations'
import { selectIsAuth } from 'redux/auth/selectors'
import {
  selectCommentList,
  selectEventsComments,
  selectEventsDetail,
  selectEventsIsFetching,
  selectEventsIsLoaded
} from 'redux/events/selectors'
import { Meta, Statuses } from 'redux/commonTypes'
import Loader from 'src/components/UI/Loader'
import { Conversation } from 'components/invest'

import { MainContent, SectionTitle, SectionWrapper, Wrapper } from './styles'
import { theme } from 'styled/theme'
import * as actions from 'redux/events/comments/actions'
import NFTsHeader from 'components/NFTs/NFTsHeader'

interface LocationState {
  position: number
  investmentAmount: number
}

interface UrlParams {
  id: string
}

const NFTsEventPage: FC = () => {
  const dispatch = useDispatch()
  const location = useLocation<LocationState>()
  const { id } = useParams<UrlParams>()

  const event = useSelector(selectEventsDetail)
  const eventsLoading = useSelector(selectEventsIsFetching)
  const eventsLoaded = useSelector(selectEventsIsLoaded)
  const isAuthenticated = useSelector(selectIsAuth)
  const comments = useSelector(selectCommentList)
  const { meta: { current_page } = {} as Meta } = useSelector(
    selectEventsComments
  )

  useEffect(() => {
    const { state: { position = 0 } = {} } = location || {}

    if (position) {
      window.scrollTo({
        top: window.pageYOffset + position
      })
      return
    }
    // eslint-disable-next-line
  }, [location])

  useEffect(() => {
    const { hash } = location || {}

    if (!hash) {
      window.scrollTo({
        top: 0
      })
    }

    if (hash && current_page === 1) {
      const targetElement = document.querySelector(hash)

      if (targetElement) {
        targetElement.scrollIntoView()
      }
    }
  }, [location, current_page])

  useEffect(() => {
    const fetchEvents = async () => {
      await dispatch(fetchEvent(Number(id)))
      dispatch(actions.clearComments())
      await dispatch(fetchComments(Number(id)))
    }
    fetchEvents()
  }, [id, dispatch])

  if (!eventsLoaded && (eventsLoading || !Object.keys(event).length)) {
    return <Loader />
  }

  if (
    (!eventsLoading && eventsLoaded && !Object.keys(event).length) ||
    [Statuses.ENDED, Statuses.COMING_SOON].includes(event.status)
  ) {
    return <Redirect to={routePath.INDEX} />
  }

  return (
    <Wrapper>
      <NFTsHeader event={event} />

      {/* Comments */}
      <MainContent sectionBackground={theme.colors.navy}>
        <Container className={'content-container'}>
          <SectionWrapper id={'conversation'}>
            <SectionTitle color={theme.colors.white}>
              {isAuthenticated ? (
                <>Comments {event.comments_count > 0 && event.comments_count}</>
              ) : (
                'Join the conversation'
              )}
            </SectionTitle>
            <Conversation data={comments} />
          </SectionWrapper>
        </Container>
      </MainContent>
    </Wrapper>
  )
}

export default NFTsEventPage
