import React, { Component } from 'react'
import OutsideClick from 'components/UI/OutsideClick'
import { Menu, StyledDropdown } from './styles'

interface Props {
  children: any
  target: any
  disabled?: boolean
  onClick?: () => void
}

class DropMenu extends Component<Props, { isOpen: boolean }> {
  constructor(props: Props) {
    super(props)

    this.state = {
      isOpen: false
    }
  }

  toggleOpen = () => {
    const { onClick, disabled } = this.props

    if (onClick) {
      onClick()
    }

    if (!disabled) {
      this.setState(state => ({ isOpen: !state.isOpen }))
    }
  }

  onClose = () => this.setState({ isOpen: false })

  render() {
    const { children, target, disabled: isDisabled } = this.props
    const { isOpen } = this.state

    return (
      <StyledDropdown>
        <OutsideClick onOutsideClick={this.onClose}>
          <div onClick={this.toggleOpen}>
            {React.Children.map(target, (child: React.ReactNode, i) => {
              // @ts-ignore
              return React.cloneElement(child, { isOpen, isDisabled })
            })}
          </div>
          {isOpen ? <Menu>{children}</Menu> : null}
        </OutsideClick>
      </StyledDropdown>
    )
  }
}

export default DropMenu
