import React, { FC } from 'react'
import { Col, Container, Row } from 'styled-bootstrap-grid'
import { IEventDetail } from 'redux/events/types'

import { TitleWrapper, EventTitle, Wrapper, WrapperBlock } from './styles'
import DaysLeft from '../EventDonateCard/DaysLeft'

interface Props {
  event: IEventDetail
  isAuthenticated?: boolean
  userId?: number
}

const EventHead: FC<Props> = ({ event }) => {
  return (
    <WrapperBlock>
      <Container>
        <Row>
          <Col>
            <Wrapper>
              <TitleWrapper>
                <EventTitle>
                  <DaysLeft
                    eventStatus={event.status}
                    hoursLeft={event.event_end_in_hours}
                    showHours={true}
                  />
                </EventTitle>
              </TitleWrapper>
            </Wrapper>
          </Col>
        </Row>
      </Container>
    </WrapperBlock>
  )
}

export default EventHead
