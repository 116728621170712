import React, { ReactNode } from 'react'
import { generatePath } from 'react-router-dom'
import { IEventDetail, PreferencesType } from 'redux/events/types'
import { Statuses } from 'redux/commonTypes'
import { routePath } from 'routes'
import AnalyticsManager from 'services/analyticService'
import DaysLeft from '../DaysLeft'
import SocialButtons from 'src/components/UI/SocialButtons'
import {
  CardBottom,
  CardInfo,
  DaysLeftContainer,
  Description,
  InfoWrapper,
  LearnMore,
  Name,
  SocialButtonsWrapper,
  SocialWrapper,
  Title,
  TitleWrapper,
  Wrapper
} from '../styles'

interface Props {
  event: IEventDetail
  showHours?: boolean
  showType?: boolean
  isPlaying: boolean
  children: ReactNode
}

const ExperiencesCardContent: React.FC<Props> = ({
  event,
  showHours,
  showType,
  isPlaying,
  children
}): React.ReactElement => {
  const {
    status,
    event_title,
    event_description,
    event_end_at,
    event_end_in_hours,
    event_id,
    event_influencer
  } = event

  const isComingSoon = status === Statuses.COMING_SOON
  // const isEnded = status === Statuses.ENDED
  const isLive = status === Statuses.LIVE
  const analyticsManager = () =>
    AnalyticsManager.trackRecommendedSectionEvent(event_id)

  const hideContent = isPlaying ? 'none' : 'flex'
  return (
    <Wrapper style={{ display: hideContent }}>
      <DaysLeftContainer>
        <DaysLeft
          endDate={event_end_at}
          eventStatus={status}
          hoursLeft={event_end_in_hours}
          showHours={showHours}
        />
      </DaysLeftContainer>
      <InfoWrapper>
        <CardInfo>
          <TitleWrapper>
            <Name>{event_influencer[0].influencer_name}</Name>
            <Title fontControl={showType}>{event_title}</Title>
            <Description
              dangerouslySetInnerHTML={{
                __html: event_description
              }}
            />
          </TitleWrapper>
          {children}
        </CardInfo>
        <CardBottom>
          {isLive && (
            <SocialWrapper withButton={!isComingSoon}>
              <LearnMore
                to={generatePath(routePath.EVENT, { id: event_id })}
                onClick={analyticsManager}
              >
                {event.preferences.some(
                  i => i.name === PreferencesType.CLASSICAL
                )
                  ? 'Donate Now'
                  : 'Shop Now'}
              </LearnMore>
              <SocialButtonsWrapper>
                <SocialButtons path={routePath.EVENT} event={event} />
              </SocialButtonsWrapper>
            </SocialWrapper>
          )}
        </CardBottom>
      </InfoWrapper>
    </Wrapper>
  )
}

export default ExperiencesCardContent
