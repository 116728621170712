import React from 'react'
import { routePath } from 'routes'
import { IEventDetail, PreferencesType } from 'redux/events/types'
import { Statuses } from 'redux/commonTypes'
import { generatePath } from 'react-router-dom'
import AnalyticsManager from 'services/analyticService'
import DaysLeft from '../DaysLeft'
import { ButtonVariant } from 'src/components/UI/Button'
import SocialButtons from 'src/components/UI/SocialButtons'

import {
  CardContent,
  CardInfo,
  EventDescription,
  EventTitle,
  InfluencerName,
  LearnMoreLink,
  LearnMoreSection
} from './styles'

interface Props {
  event: IEventDetail
  showHours?: boolean
}

const ExperiencesCardContent: React.FC<Props> = ({
  event,
  showHours
}): React.ReactElement => {
  const {
    status,
    event_title,
    event_end_in_hours,
    event_influencer,
    event_description,
    event_id,
    event_end_at
  } = event
  const trackAnalytics = () => {
    AnalyticsManager.trackRecommendedSectionEvent(event_id)
  }

  return (
    <CardContent>
      <DaysLeft
        eventStatus={status}
        hoursLeft={event_end_in_hours}
        showHours={showHours}
        eventEndDate={event_end_at}
      />

      <div style={{ width: '100%' }}>
        <CardInfo>
          <InfluencerName>{event_influencer[0].influencer_name}</InfluencerName>
          <EventTitle>{event_title}</EventTitle>
          <EventDescription
            dangerouslySetInnerHTML={{ __html: event_description }}
          />
        </CardInfo>

        {status === Statuses.LIVE && (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <LearnMoreSection>
              <LearnMoreLink
                to={generatePath(routePath.EVENT, {
                  id: event_id
                })}
                variant={ButtonVariant.Default}
                onClick={trackAnalytics}
              >
                {event.preferences.some(
                  i => i.name === PreferencesType.CLASSICAL
                )
                  ? 'Donate Now'
                  : 'Shop Now'}
              </LearnMoreLink>
            </LearnMoreSection>
            <SocialButtons path={routePath.EVENT} event={event} />
          </div>
        )}
      </div>
    </CardContent>
  )
}

export default ExperiencesCardContent
