import styled from 'styled-components'

export const PopUpBlock = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;

  button {
    max-width: 10rem;
    width: 100%;
  }
`
export const PopUpText = styled.p`
  margin-bottom: 2rem;
  font-size: 1rem;
  color: ${p => p.theme.colors.black};
  font-family: Inter, sans-serif;
  .error {
    color: ${p => p.theme.colors.red};
    font-size: 1.2rem;
    font-family: inherit;
    font-weight: 500;
  }
`
