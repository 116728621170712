import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export const Wrapper = styled.div`
  height: 100%;
  padding: 4.5rem 0 2rem;
  background-color: ${p => p.theme.colors.navy};

  ${media.md`
    padding-top: 6rem;
  `}

  ${media.lg`
    padding-top: 11rem;
  `}
`

export const MainContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${media.md`
    height: unset;
  `}
`

export const ErrorMessage = styled.div`
  font-size: 1rem;
  color: ${p => p.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  svg {
    margin-right: 5px;
  }
`
