import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {
  uploadYoutubeVideo,
  deleteYoutubeVideo
} from 'redux/influencer/operations'
import {
  selectInfluencerErrors,
  selectUserInfo
} from 'redux/influencer/selectors'
import { clearErrors } from 'redux/influencer/actions'

import TextInput from 'components/UI/TextInput'
import Button from 'components/UI/Button'
import { TrashIcon } from 'components/icons'

import { Form, SubmitButton, InputWrapper } from './styles'

import { AppState, Dispatch } from 'store/types'
import { IVideo } from 'redux/commonTypes'

class EditProfileForm extends Component<Props, { url: string }> {
  state = {
    url: ''
  }

  componentWillUnmount = (): void => {
    this.props.clearErrors()
  }

  changeUrl = (name: string, value: string) =>
    this.setState({ url: value }, () => this.props.clearErrors())

  uploadVideo = async (e: any) => {
    e.preventDefault()
    await this.props.uploadYoutubeVideo(this.state.url)
    this.setState({ url: '' })
  }

  deleteVideo = (event: any) => {
    const {
      currentTarget: { parentNode }
    } = event

    const targetField = parentNode.querySelector('input')

    const targetVideo = this.props.videos.find(
      v => v.id === Number(targetField.name)
    )

    const videoToDelete = {
      // @ts-ignore
      video_id: targetVideo.id,
      // @ts-ignore
      storage: targetVideo.video_storage
    }

    this.props.deleteYoutubeVideo(videoToDelete)
  }

  render() {
    const { videos, errorMessage } = this.props

    return (
      <Form>
        {videos.map((v: IVideo, idx: number) => (
          <InputWrapper key={v.id}>
            <TextInput
              value={v.url}
              variant={'dark'}
              label={idx === 0 ? 'Youtube' : ''}
              name={v.id.toString()}
              readOnly={true}
            />
            <Button variant={'clear'} onClick={this.deleteVideo}>
              <TrashIcon />
            </Button>
          </InputWrapper>
        ))}
        <TextInput
          variant={'dark'}
          key={videos.length + 1}
          name={'url'}
          handleChange={this.changeUrl}
          placeholder={'Add Youtube video link'}
          label={videos.length === 0 ? 'Youtube' : 'Add Youtube video link'}
          errorLabel={errorMessage}
        />
        <span>* You can add only a maximum of five links</span>
        <SubmitButton onClick={this.uploadVideo} disabled={videos.length === 5}>
          Save
        </SubmitButton>
      </Form>
    )
  }
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
export type Props = StateProps & DispatchProps

const mapStateToProps = (state: AppState) => ({
  videos: selectUserInfo(state).videos,
  errorMessage: selectInfluencerErrors(state).message
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { uploadYoutubeVideo, deleteYoutubeVideo, clearErrors },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditProfileForm)
