import styled from 'styled-components'

export const trackStyle = [{ backgroundColor: '#D4AB72', height: 2 }]
export const railStyle = { backgroundColor: '#dddddd', height: 2 }
export const handleStyle = {
  boxShadow:
    '0 3px 1px rgba(0, 0, 0, 0.1), 0 1px 1px rgba(0, 0, 0, 0.16), 0 3px 8px rgba(0, 0, 0, 0.15)',
  border: '1px solid rgba(0, 0, 0, 0.04)',
  background: '#ffffff',
  height: 24,
  width: 24,
  marginLeft: 0,
  marginTop: -10
}

export const RangeWrap = styled.div`
  padding: 0 12px 40px;

  p {
    font-size: 14px;
    padding-bottom: 1rem;
    margin: 0 -12px;
  }
`
export const HandleLabel = styled.div`
  font-size: 12px;
  color: #ffffff;
  opacity: 0.5;
  height: 16px;
  width: 50px;
  position: absolute;
  left: -15px;
  top: 30px;
  text-align: center;
`
