import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export const SectionTitle = styled.h3<{
  colorMode?: 'dark' | 'light'
  isSubsection?: boolean
}>`
  font-size: 0.9375rem;
  font-weight: 900;
  font-family: Inter;
  color: ${p =>
    p.colorMode === 'dark' ? p.theme.colors.white : p.theme.colors.black};

  ${media.md`
    font-size: 1.125rem;
  `}
`

export const DropdownPlus = styled.div<{ isOpen: boolean }>`
  margin-left: 1rem;
  color: ${p => p.theme.colors.fuchsia};
  font-size: 1.75rem;
  transition: 0.2s;
  position: relative;

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 14px;
    height: 2px;
    top: 50%;
    margin-left: -7px;
    margin-top: -1px;
    left: 50%;
    background-color: ${p => p.theme.colors.fuchsia};
  }

  &:before {
    transform: ${p => (p.isOpen ? 'rotate(0deg)' : 'rotate(90deg)')};
    transition: 0.3s;
  }
`

export const SectionTitleWrapper = styled.div<{ isOpen: boolean }>`
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #ebebeb;
  transition: 0.5s;
  margin: ${p => (p.isOpen ? '1rem 0' : '15px 0')};
`

export const SubsectionWrapper = styled.div`
  :not(:last-child) {
    padding-bottom: 2.75rem;
  }
`

export const Text = styled.p<{
  colorMode?: 'dark' | 'light'
}>`
  padding: 0;
  color: ${p =>
    p.colorMode === 'dark' ? p.theme.colors.white : p.theme.colors.black};
  font-weight: 300;
  font-family: Inter;
  font-size: 0.9375rem;
  line-height: 1.75rem;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: ${p =>
    p.colorMode === 'dark' ? p.theme.colors.navy : '#f9f9f9'};
  padding: 1.25rem;

  ${media.md`
    font-size: 1.125rem;
  `};
`

export const SectionListItem = styled.li`
  width: 97%;
  ${media.md`
    width: initial;
  `};
`
