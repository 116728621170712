import React from 'react'

const WebsiteIcon = styles => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.75 0C18.5449 0 20 1.45507 20 3.25V14.75C20 16.5449 18.5449 18 16.75 18H3.25C1.45507 18 0 16.5449 0 14.75V3.25C0 1.45507 1.45507 0 3.25 0H16.75ZM16.75 1.5H3.25C2.2835 1.5 1.5 2.2835 1.5 3.25V14.75C1.5 15.7165 2.2835 16.5 3.25 16.5H16.75C17.7165 16.5 18.5 15.7165 18.5 14.75V3.25C18.5 2.2835 17.7165 1.5 16.75 1.5ZM4.74835 3.5H15.2543C15.6685 3.5 16.0043 3.83579 16.0043 4.25C16.0043 4.6297 15.7221 4.94349 15.3561 4.99315L15.2543 5H4.74835C4.33414 5 3.99835 4.66421 3.99835 4.25C3.99835 3.8703 4.28051 3.55651 4.64658 3.50685L4.74835 3.5H15.2543H4.74835Z"
      fill="#EB78FF"
      {...styles}
    />
  </svg>
)

export default WebsiteIcon
