import React, { FC } from 'react'
import styled from 'styled-components'
import { getInitials } from 'utils/formatText'
import Img from 'components/UI/Img'

interface Props {
  src?: string
  name: string
  size: number
}

const Avatar: FC<Props> = ({ src, name, size }) => {
  const noAvatar = <Initials size={size}>{getInitials(name)}</Initials>

  return src ? (
    <Image src={src} alt={name} size={size} fallback={noAvatar} />
  ) : (
    <>{noAvatar}</>
  )
}

Avatar.defaultProps = {
  size: 100
}

export const Image = styled(Img)<{ src: string; size?: number; alt: string }>`
  width: ${p => p.size}px;
  min-width: ${p => p.size}px;
  height: ${p => p.size}px;
  border-radius: 50%;
  object-fit: cover;
`
export const Initials = styled.p<{ size: number }>`
  min-width: ${p => p.size}px;
  width: ${p => p.size}px;
  height: ${p => p.size}px;
  border-radius: 50%;
  background: ${p => p.theme.colors.lightBlue};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 300;
  text-transform: uppercase;
  color: ${p => p.theme.colors.white};
  line-height: 1rem;
  letter-spacing: 1px;
`

export default Avatar
