import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export const TextWrapper = styled.div`
  margin-bottom: 2rem;
  padding: 0 0.625rem;

  p {
    margin-bottom: 1.5rem;
    @media (max-height: 550px) {
      max-height: 150px;
      overflow-y: scroll;
    }
  }
  @media (max-height: 550px) {
    max-height: 80vh;
  }
`

export const Wrapper = styled.div`
  width: 100%;

  button {
    width: 10rem;
    margin: 0 auto;
  }

  iframe {
    width: 100%;
    height: 37.5rem;
    @media (max-height: 550px) {
      height: 80vh;
    }
  }

  ${media.md`
    width: 31.25rem;
  `}
`
