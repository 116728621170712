import React, { Component, ReactNode } from 'react'

interface Props {
  children: ReactNode
  onOutsideClick: () => void
}
export default class OutsideClick extends Component<Props> {
  private wrapperRef: any

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
    document.addEventListener('touchstart', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
    document.removeEventListener('touchstart', this.handleClickOutside)
  }

  setWrapperRef = (node: ReactNode) => {
    this.wrapperRef = node
  }

  handleClickOutside = (event: any) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.onOutsideClick()
    }
  }

  render() {
    const { children } = this.props
    return <div ref={this.setWrapperRef}>{children}</div>
  }
}
