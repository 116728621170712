import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setErrors, setInvestmentStep } from 'redux/investment/actions'
import { selectCurrentStep } from 'redux/investment/selectors'
import { BackWrapper } from './styles'
import ChevronDownIcon from 'src/components/icons/ChevronDownIcon'

const BackButton: FC = () => {
  const currentStep = useSelector(selectCurrentStep)
  const dispatch = useDispatch()
  const history = useHistory()
  const onClick = () => {
    dispatch(setErrors({}))

    if (currentStep === 1) {
      history.goBack()
    } else {
      dispatch(setInvestmentStep(currentStep - 1))
    }
  }

  return (
    <BackWrapper onClick={onClick}>
      <ChevronDownIcon fill={'#fff'} opacity={'1'} />
    </BackWrapper>
  )
}

export default BackButton
