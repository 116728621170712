import { action, createAsyncAction, createCustomAction } from 'typesafe-actions'
import {
  IAddress,
  IContact,
  ICreditCard,
  IIRA,
  InvestmentActionTypes,
  IPaymentMethods,
  IUSBank,
  IVerification,
  ITierAmountDetails,
  ITrust,
  ICompany,
  ITrustInfo,
  IEntityInfo,
  IPersonalInfoResp,
  IEntityInfoResp,
  IIRAInfoResp,
  ITrustInfoResp,
  IEntityInfoDetails,
  ISubscriptionAgreementResp,
  IPaymentACH,
  IPayment,
  IProhibitedCountryResp
} from './types'
import { IStatus } from '../commonTypes'

export const setInvestmentTierId = createCustomAction(
  InvestmentActionTypes.SET_INVESTMENT_TIER_ID,
  (tierId: number) => ({
    payload: tierId
  })
)

export const changeInvestmentAmount = createCustomAction(
  InvestmentActionTypes.CHANGE_INVESTMENT_AMOUNT,
  (amount: number) => ({
    payload: amount
  })
)

export const clearInvestmentAmountAndTier = () =>
  action(InvestmentActionTypes.CLEAR_INVESTMENT_AMOUNT_AND_TIER)

export const fetchTierDetailsAsync = createAsyncAction(
  InvestmentActionTypes.FETCH_TIER_DETAILS_REQUEST,
  InvestmentActionTypes.FETCH_TIER_DETAILS_SUCCESS,
  InvestmentActionTypes.FETCH_TIER_DETAILS_FAIL
)<void, ITierAmountDetails, Error>()

export const checkProhibitedCountryForInvestment = createAsyncAction(
  InvestmentActionTypes.CHECK_PROHIBITED_COUNTRY_REQUEST,
  InvestmentActionTypes.CHECK_PROHIBITED_COUNTRY_SUCCESS,
  InvestmentActionTypes.CHECK_PROHIBITED_COUNTRY_FAIL
)<void, IProhibitedCountryResp, Error>()

export const sendPersonalInformationAsync = createAsyncAction(
  InvestmentActionTypes.SEND_PERSONAL_INFORMATION_REQUEST,
  InvestmentActionTypes.SEND_PERSONAL_INFORMATION_SUCCESS,
  InvestmentActionTypes.SEND_PERSONAL_INFORMATION_FAIL
)<void, IPersonalInfoResp, Error>()

export const fetchPersonalInformationAsync = createAsyncAction(
  InvestmentActionTypes.FETCH_PERSONAL_INFORMATION_REQUEST,
  InvestmentActionTypes.FETCH_PERSONAL_INFORMATION_SUCCESS,
  InvestmentActionTypes.FETCH_PERSONAL_INFORMATION_FAIL
)<void, IPersonalInfoResp, Error>()

export const sendVerificationAsync = createAsyncAction(
  InvestmentActionTypes.SEND_VERIFICATION_REQUEST,
  InvestmentActionTypes.SEND_VERIFICATION_SUCCESS,
  InvestmentActionTypes.SEND_VERIFICATION_FAIL
)<void, IStatus, Error>()

export const sendTrustInformationAsync = createAsyncAction(
  InvestmentActionTypes.SEND_TRUST_INFORMATION_REQUEST,
  InvestmentActionTypes.SEND_TRUST_INFORMATION_SUCCESS,
  InvestmentActionTypes.SEND_TRUST_INFORMATION_FAIL
)<void, ITrustInfo, Error>()

export const fetchTrustInformationAsync = createAsyncAction(
  InvestmentActionTypes.FETCH_TRUST_INFORMATION_REQUEST,
  InvestmentActionTypes.FETCH_TRUST_INFORMATION_SUCCESS,
  InvestmentActionTypes.FETCH_TRUST_INFORMATION_FAIL
)<void, ITrustInfoResp, Error>()

export const sendEntityInformationAsync = createAsyncAction(
  InvestmentActionTypes.SEND_ENTITY_INFORMATION_REQUEST,
  InvestmentActionTypes.SEND_ENTITY_INFORMATION_SUCCESS,
  InvestmentActionTypes.SEND_ENTITY_INFORMATION_FAIL
)<void, IEntityInfo, Error>()

export const fetchEntityInformationAsync = createAsyncAction(
  InvestmentActionTypes.FETCH_ENTITY_INFORMATION_REQUEST,
  InvestmentActionTypes.FETCH_ENTITY_INFORMATION_SUCCESS,
  InvestmentActionTypes.FETCH_ENTITY_INFORMATION_FAIL
)<void, IEntityInfoResp[], Error>()

export const fetchEntityInfoDetailsAsync = createAsyncAction(
  InvestmentActionTypes.FETCH_ENTITY_INFO_DETAILS_REQUEST,
  InvestmentActionTypes.FETCH_ENTITY_INFO_DETAILS_SUCCESS,
  InvestmentActionTypes.FETCH_ENTITY_INFO_DETAILS_FAIL
)<void, IEntityInfoDetails, Error>()

export const sendIraInformationAsync = createAsyncAction(
  InvestmentActionTypes.SEND_IRA_INFORMATION_REQUEST,
  InvestmentActionTypes.SEND_IRA_INFORMATION_SUCCESS,
  InvestmentActionTypes.SEND_IRA_INFORMATION_FAIL
)<void, IIRAInfoResp, Error>()

export const fetchIraInformationAsync = createAsyncAction(
  InvestmentActionTypes.FETCH_IRA_INFORMATION_REQUEST,
  InvestmentActionTypes.FETCH_IRA_INFORMATION_SUCCESS,
  InvestmentActionTypes.FETCH_IRA_INFORMATION_FAIL
)<void, IIRAInfoResp, Error>()

export const investPaymentCreditCardAsync = createAsyncAction(
  InvestmentActionTypes.INVEST_PAYMENT_CREDIT_CARD_REQUEST,
  InvestmentActionTypes.INVEST_PAYMENT_CREDIT_CARD_SUCCESS,
  InvestmentActionTypes.INVEST_PAYMENT_CREDIT_CARD_FAIL
)<void, any, Error>()

export const investPaymentACHAsync = createAsyncAction(
  InvestmentActionTypes.INVEST_PAYMENT_ACH_REQUEST,
  InvestmentActionTypes.INVEST_PAYMENT_ACH_SUCCESS,
  InvestmentActionTypes.INVEST_PAYMENT_ACH_FAIL
)<void, IPaymentACH, Error>()

export const investPaymentWireAsync = createAsyncAction(
  InvestmentActionTypes.INVEST_PAYMENT_WIRE_REQUEST,
  InvestmentActionTypes.INVEST_PAYMENT_WIRE_SUCCESS,
  InvestmentActionTypes.INVEST_PAYMENT_WIRE_FAIL
)<void, IPayment, Error>()

export const setInvestmentStep = createCustomAction(
  InvestmentActionTypes.SET_INVESTMENT_STEP,
  (step: number) => ({
    payload: step
  })
)

export const setPaymentMethod = createCustomAction(
  InvestmentActionTypes.SET_PAYMENT_METHOD,
  (method: IPaymentMethods) => ({
    payload: method
  })
)

export const setCreditCardInfo = createCustomAction(
  InvestmentActionTypes.SET_CREDIT_CARD_INFO,
  (field: ICreditCard) => ({
    payload: field
  })
)

export const setUSBankInfo = createCustomAction(
  InvestmentActionTypes.SET_US_BANK_INFO,
  (field: Required<IUSBank>) => ({
    payload: field
  })
)

export const setVerification = createCustomAction(
  InvestmentActionTypes.SET_VERIFICATION,
  (field: Required<IVerification>) => ({
    payload: field
  })
)

export const setErrors = createCustomAction(
  InvestmentActionTypes.SET_ERRORS,
  (errors: { [k: string]: any }) => ({
    payload: errors
  })
)

export const changeInvestmentContact = createCustomAction(
  InvestmentActionTypes.CHANGE_INVESTMENT_CONTACT,
  (field: Required<IContact>) => ({
    payload: field
  })
)

export const changeInvestmentAddress = createCustomAction(
  InvestmentActionTypes.CHANGE_INVESTMENT_ADDRESS,
  (field: Required<IAddress>) => ({
    payload: field
  })
)

export const clearInvestmentData = () =>
  action(InvestmentActionTypes.CLEAR_INVESTMENT_DATA)

export const clearInvestmentPaymentError = () =>
  action(InvestmentActionTypes.CLEAR_INVESTMENT_PAYMENT_ERROR)

export const setTrustInfo = createCustomAction(
  InvestmentActionTypes.SET_TRUST_INFO,
  (field: Required<ITrust>) => ({
    payload: field
  })
)

export const setIRAInfo = createCustomAction(
  InvestmentActionTypes.SET_IRA_INFO,
  (field: Required<IIRA>) => ({
    payload: field
  })
)

export const setCompanyInfo = createCustomAction(
  InvestmentActionTypes.SET_COMPANY_INFO,
  (field: Required<ICompany>) => ({
    payload: field
  })
)

export const setEntityContactInfo = createCustomAction(
  InvestmentActionTypes.SET_ENTITY_CONTACT_INFO,
  (field: IContact) => ({
    payload: field
  })
)
export const setEntityAddressInfo = createCustomAction(
  InvestmentActionTypes.SET_ENTITY_ADDRESS_INFO,
  (field: IAddress) => ({
    payload: field
  })
)

export const changeUserSignature = createCustomAction(
  InvestmentActionTypes.CHANGE_USER_SIGNATURE,
  (signature: string) => ({
    payload: signature
  })
)

export const switchViewMode = () =>
  action(InvestmentActionTypes.SWITCH_VIEW_MODE)

export const switchEventType = () =>
  action(InvestmentActionTypes.SWITCH_EVENT_TYPE)

export const createSubscriptionAgreementAsync = createAsyncAction(
  InvestmentActionTypes.CREATE_SUBSCRIPTION_AGREEMENT_REQUEST,
  InvestmentActionTypes.CREATE_SUBSCRIPTION_AGREEMENT_SUCCESS,
  InvestmentActionTypes.CREATE_SUBSCRIPTION_AGREEMENT_FAIL
)<void, ISubscriptionAgreementResp, Error>()

export const sendEntityDocAsync = createAsyncAction(
  InvestmentActionTypes.SEND_ENTITY_DOC_REQUEST,
  InvestmentActionTypes.SEND_ENTITY_DOC_SUCCESS,
  InvestmentActionTypes.SEND_ENTITY_DOC_FAIL
)<void, any, Error>()
