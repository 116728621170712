import React from 'react'

const TwitterIcon = styles => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <path
      fill="#fff"
      d="M12 12H8c-1.103 0-2-.897-2-2V9h6a2 2 0 0 0 0-4H6V2a2 2 0 0 0-4 0v8c0 3.309 2.691 6 6 6h4a2 2 0 0 0 0-4z"
      {...styles}
    />
  </svg>
)

export default TwitterIcon
