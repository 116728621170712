import { CustomStyles } from './index'

const selectStyles = ({
  hideControl,
  customStyles
}: {
  hideControl?: boolean
  customStyles: CustomStyles
}) => {
  return {
    menu: (provided: any) => ({
      ...provided,
      position: 'relative',
      background: '#eee',
      boxShadow: 'none',
      margin: 0,
      borderRadius: '12px',
      ...customStyles.menu
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      '&:hover': { borderColor: '#d6d6d6' },
      '&:focus-within': { borderColor: '#d6d6d6' },
      background: '#eee',
      border: 'none',
      borderBottom: '1px solid #d6d6d6',
      outline: '0 !important',
      marginBottom: '3px',
      boxShadow: 'none',
      height: '40px',
      display: !hideControl ? 'flex' : 'none',
      ...customStyles.control
    }),
    input: (provided: any, state: any) => ({
      ...provided,
      width: '100%',
      color: state.isSelected ? '#fff' : '#000',
      cursor: 'text',
      fontFamily: 'Inter',
      background: '#eee',
      ...customStyles.input
    }),
    placeholder: (provided: any) => ({
      ...provided,
      cursor: 'text',
      fontFamily: 'Inter'
    }),
    menuList: (provided: any) => ({
      ...provided,
      padding: 0,
      maxHeight: '280px'
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      fontFamily: 'Inter',
      padding: '1rem 1.25rem',
      transition: '0.1s all ease-out',
      '&:hover': {
        backgroundColor: '#BC6ACA',
        color: '#fff'
      },
      '&:first-of-type': {
        borderRadius: '12px 12px 0 0',
        ...customStyles.firstOption
      },
      '&:last-of-type': {
        borderRadius: '0 0 12px 12px',
        borderBottom: 'none'
      },
      color: state.isDisabled ? '#383838' : state.isSelected ? '#fff' : '#000',
      fontSize: '0.75rem',
      fontWeight: state.isSelected ? '500 !important' : '400',
      backgroundColor: state.isSelected
        ? '#BC6ACA'
        : state.isActive
        ? '#E5EAEF'
        : customStyles.hasOwnProperty('option')
        ? customStyles.option && customStyles.option.backgroundColor
        : state.backgroundColor,
      cursor: !state.isDisabled && 'pointer',
      borderBottom: '1px solid #d6d6d6'
    }),
    noOptionsMessage: (provided: any) => ({
      ...provided,
      paddingBottom: '1rem'
    })
  }
}

export default selectStyles
