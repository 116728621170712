import styled, { css } from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import Button from 'components/UI/Button'

export const Wrapper = styled.div`
  padding: 0.2rem 0 2.5rem;

  ${media.md`
  padding: 1.675rem 0 3.75rem;
`}
`
export const TabContainer = styled.div<{
  active?: boolean
}>`
  cursor: pointer;
  max-height: 3.95rem;
  height: 100%;
  flex: 1 1 25%;
  margin-bottom: 0.375rem;
  margin-right: 0.375rem;
  padding: 0.625rem 0;
  display: flex;
  flex-direction: column;
  border-radius: 0.375rem;
  background-color: #0f0f0f;
  font-size: 1rem;
  overflow: hidden;
  text-align: center;

  ${p =>
    !p.active &&
    media.md`
    margin-right: 0.625rem;
    margin-bottom: 0.625rem;
    padding: 0.75rem 1.25rem;
    text-align: left;
`}

  ${p =>
    !p.active &&
    media.lg`
    padding: 1.25rem;
    padding-bottom: 1.5rem;
    flex-direction: row; 
    justify-content: space-between;     
    align-items: center;
    `}

  :last-of-type {
    margin-right: 0;
  }

  ${p =>
    p.active &&
    css`
      max-height: 4.325rem;
      margin-bottom: 0;
      padding: 0.625rem 0.625rem;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      background-color: ${p.theme.colors.black};
      text-align: left;
      ${media.xs`
      flex: none;
      `}
      ${media.md`
      max-height: 4.575rem;
      margin-right: 0.625rem;
      padding: 0.75rem 1.25rem;
      `}
      ${media.lg`
      padding: 1.25rem;
      padding-bottom: 2.125rem;
      flex-direction: row; 
      justify-content: space-between;     
      align-items: center;
      `}
      `}
`
export const TabContentWrapper = styled.div`
  padding: 0 0.625rem;

  ${media.md`
  padding: 0 1.25rem;
`}

  ${media.lg`
  padding: 2rem 3.75rem;
`}
`
export const ExportButton = styled(Button).attrs(p => ({
  'data-testid': 'export-button'
}))`
  margin: 2.5rem auto 0;
  padding: 1.125rem 2.5rem;
  font-size: 0.875rem;

  ${media.md`
  margin: 3.75rem auto 0;
`}
`
export const Text = styled.p`
  padding: 1rem;
  text-align: center;
`
