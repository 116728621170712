import React from 'react'

const CheckMarkIcon = props => (
  <svg
    width="27"
    height="27"
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.499.583C6.369.583.582 6.369.582 13.499c0 7.13 5.787 12.917 12.917 12.917S26.415 20.63 26.415 13.5 20.63.583 13.5.583zm-2.584 19.375l-6.458-6.459 1.821-1.82 4.637 4.623L20.72 6.5l1.821 1.834-11.625 11.625z"
      fill="#000"
      {...props}
    />
  </svg>
)

export default CheckMarkIcon
