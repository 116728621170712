import styled from 'styled-components'

export const Form = styled.form`
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  textarea {
    width: 100%;
    max-height: 2.5rem;
    margin-top: 2rem;
    margin-right: 1.125rem;
    padding: 0.563rem;
    font-family: Inter;
    font-size: 1.125rem;
    font-weight: 300;
    background: #1d2341;
    border-radius: 8px;
    color: ${p => p.theme.colors.white};
    resize: none;
    border: none;

    :focus {
      outline: none;
      box-shadow: -3px 1px 6px -2px rgba(0, 0, 0, 0.3);
    }

    ::placeholder {
      font-size: 1.125rem;
      font-weight: 400;
      color: ${p => p.theme.colors.lightGrey};
    }
  }

  button {
    padding: 0.5rem;
    text-transform: uppercase;
    color: ${p => p.theme.colors.fuchsia};
    font-size: 1rem;
    background: transparent;
    font-weight: 400;
    border: 1px solid rgba(188, 106, 202, 0.5);
    border-radius: 12px;
    letter-spacing: 2px;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);

    :hover {
      opacity: 0.8;
    }

    :disabled {
      color: ${p => p.theme.colors.fuchsia};
      :hover {
        background-color: inherit;
        color: ${p => p.theme.colors.fuchsia};
      }
    }
  }
`
