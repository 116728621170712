import { action, createAsyncAction, createCustomAction } from 'typesafe-actions'
import {
  AuthActionTypes,
  IUserData,
  IUser,
  IRegisterResponse,
  IVerifyUser,
  IChangePassword
} from './types'
import { IErrors, IStatus } from '../commonTypes'

export const loginAsync = createAsyncAction(
  AuthActionTypes.LOGIN_REQUEST,
  AuthActionTypes.LOGIN_SUCCESS,
  AuthActionTypes.LOGIN_FAIL
)<void, IUserData, Error>()

export const betablocksLoginAsync = createAsyncAction(
  AuthActionTypes.BETABLOCK_LOGIN_REQUEST,
  AuthActionTypes.BETABLOCK_LOGIN_SUCCESS,
  AuthActionTypes.BETABLOCK_LOGIN_FAIL
)<void, any, Error>()

export const socialLoginAsync = createAsyncAction(
  AuthActionTypes.SOCIAL_LOGIN_REQUEST,
  AuthActionTypes.SOCIAL_LOGIN_SUCCESS,
  AuthActionTypes.SOCIAL_LOGIN_FAIL
)<void, { userData: IUserData; isVerified: boolean }, Error>()

export const socialLoginEmailAsync = createAsyncAction(
  AuthActionTypes.SOCIAL_LOGIN_EMAIL_REQUEST,
  AuthActionTypes.SOCIAL_LOGIN_EMAIL_SUCCESS,
  AuthActionTypes.SOCIAL_LOGIN_EMAIL_FAIL
)<void, IUserData, Error>()

export const profileSettingsAsync = createAsyncAction(
  AuthActionTypes.PROFILE_SETTINGS_REQUEST,
  AuthActionTypes.PROFILE_SETTINGS_SUCCESS,
  AuthActionTypes.PROFILE_SETTINGS_FAIL
)<void, IUser, Error>()

export const registerAsync = createAsyncAction(
  AuthActionTypes.REGISTER_REQUEST,
  AuthActionTypes.REGISTER_SUCCESS,
  AuthActionTypes.REGISTER_FAIL
)<void, IRegisterResponse, Error>()

export const verifyAsync = createAsyncAction(
  AuthActionTypes.VERIFY_REQUEST,
  AuthActionTypes.VERIFY_SUCCESS,
  AuthActionTypes.VERIFY_FAIL
)<void, IVerifyUser, Error>()

export const verifyRecoveryAsync = createAsyncAction(
  AuthActionTypes.VERIFY_REQUEST,
  AuthActionTypes.VERIFY_SUCCESS,
  AuthActionTypes.VERIFY_FAIL
)<void, IVerifyUser, Error>()

export const resendAsync = createAsyncAction(
  AuthActionTypes.RESEND_REQUEST,
  AuthActionTypes.RESEND_SUCCESS,
  AuthActionTypes.RESEND_FAIL
)<void, IStatus, Error>()

export const logout = () => action(AuthActionTypes.LOGOUT)
export const setAuthenticated = () => action(AuthActionTypes.SET_AUTHENTICATED)
export const clearAuthErrors = () => action(AuthActionTypes.CLEAR_ERRORS)

export const forgotAsync = createAsyncAction(
  AuthActionTypes.FORGOT_REQUEST,
  AuthActionTypes.FORGOT_SUCCESS,
  AuthActionTypes.FORGOT_FAIL
)<void, IRegisterResponse, Error>()

export const recoveryPasswordAsync = createAsyncAction(
  AuthActionTypes.RECOVERY_PASSWORD_REQUEST,
  AuthActionTypes.RECOVERY_PASSWORD_SUCCESS,
  AuthActionTypes.RECOVERY_PASSWORD_FAIL
)<void, IRegisterResponse, Error>()

export const changePasswordAsync = createAsyncAction(
  AuthActionTypes.CHANGE_PASSWORD_REQUEST,
  AuthActionTypes.CHANGE_PASSWORD_SUCCESS,
  AuthActionTypes.CHANGE_PASSWORD_FAIL
)<void, { accessToken: string; status: boolean }, Error>()

export const pushSubscriptionToken = createAsyncAction(
  AuthActionTypes.PATCH_PUSH_SUBSCRIPTION_TOKEN_REQUEST,
  AuthActionTypes.PATCH_PUSH_SUBSCRIPTION_TOKEN_SUCCESS,
  AuthActionTypes.PATCH_PUSH_SUBSCRIPTION_TOKEN_FAIL
)<void, void, Error>()

export const setProfilePasswordErrors = createCustomAction(
  AuthActionTypes.SET_PROFILE_PASSWORD_ERRORS,
  (fields: IErrors<IChangePassword>) => ({
    payload: { fields }
  })
)

export const showModal = createCustomAction(
  AuthActionTypes.SHOW_MODAL,
  (message: string) => ({
    payload: message
  })
)

export const setBetablocksParams = createCustomAction(
  AuthActionTypes.SET_BETABLOCK_PARAMS,
  (params: any) => ({
    payload: params
  })
)

export const setAccessToken = createCustomAction(
  AuthActionTypes.SET_TOKEN,
  (accessToken: string) => ({
    payload: accessToken
  })
)

export const hideModal = () => action(AuthActionTypes.HIDE_MODAL)
