import { CancellationType } from 'redux/commonTypes'

export const configList = (
  cancellationDays: string,
  cancellationType: number
) => {
  return [
    {
      item:
        "I acknowledge that I have reviewed and understand FanVestor's educational materials."
    },
    {
      item:
        'I certify that I am not exceeding my annual Regulation Crowdfunding investment limit by making this investment within the past 12 months on any crowdfunding portal, including FanVestor, if I am a non-accredited investor.'
    },
    {
      item:
        'I understand that the entire amount of my investment is at risk and may be lost, and I am in a financial position to bear the loss of the entire investment.'
    },
    {
      item:
        cancellationType === CancellationType.BEFORE_THE_END_DATE_OF_THE_EVENT
          ? `I understand and acknowledge that I am only able to cancel my investment commitment up until ${cancellationDays} before the closing of this offering. After that time, I cannot cancel my investment to obtain a refund of the funds committed.`
          : cancellationType === CancellationType.AFTER_PURCHASE_DAY
          ? `I understand and acknowledge that I am only able to cancel my investment commitment within ${cancellationDays} of making this investment. After that time, I cannot cancel my investment to obtain a refund of the funds committed.`
          : 'I understand and acknowledge that I am not able to cancel my investment commitment and obtain a refund after confirming my investment.'
    },
    {
      item:
        'I understand and acknowledge that there is a holding period before these securities can be transferable and that it may be difficult to resell these securities, as there may not be an active secondary market.'
    },
    {
      item:
        'I understand that in the event that the issuer deems that there is a “material” change to this offering as defined under Regulation Crowdfunding, I will be required to reconfirm this investment or it will be canceled and funds will be returned.'
    },
    {
      item:
        'I understand and acknowledge that there is no guarantee of a relationship between FanVestor and the issuer upon closing of the offering.'
    },
    {
      item:
        'I acknowledge that I have reviewed the issuer’s offering material, documents, and disclosures related to this investment.'
    },
    {
      item:
        'I certify that I have read and agree to the terms set in the Subscription Agreement.'
    }
  ]
}
