import React, { FC, ReactNode } from 'react'
import { CloseWrapper, ModalBody, ModalFooter, ModalHeader } from './styles'
import CloseIcon from 'src/components/icons/CloseIcon'
import GenericModal, { GenericModalProps } from '../GenericModal'

export interface ModalProps extends GenericModalProps {
  header?: ReactNode
  footer?: ReactNode
  backgroundFooter?: string
  hideControl?: boolean
  bodyOverflow?: boolean
  closeButtonColor?: string
}

const Modal: FC<ModalProps> = ({
  children,
  isOpen,
  onClose,
  style = {},
  id,
  className,
  closeTimeout,
  closeButtonColor,
  header,
  footer,
  backgroundFooter,
  bodyOverflow,
  hideControl,
  ...rest
}) => (
  <GenericModal
    isOpen={isOpen}
    onClose={onClose}
    id={id}
    closeTimeout={closeTimeout}
    className={className}
    style={style}
    {...rest}
  >
    <ModalHeader>
      {header}
      <CloseWrapper
        closeButtonColor={closeButtonColor}
        hideControl={hideControl}
        onClick={onClose}
      >
        <CloseIcon />
      </CloseWrapper>
    </ModalHeader>
    <ModalBody bodyOverflow={bodyOverflow}>{children}</ModalBody>
    <ModalFooter backgroundFooter={backgroundFooter}>{footer}</ModalFooter>
  </GenericModal>
)

export default Modal
