import React from 'react'

const LoaderIcon = props => (
  <svg
    style={{ margin: 'auto', display: 'block', shapeRendering: 'auto' }}
    width="36"
    height="36"
    viewBox="0 0 100 100"
    {...props}
  >
    <defs>
      <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
        <stop offset="0%" stopColor="#412051" />
        <stop offset="150%" stopColor="#BC6ACA" />
      </linearGradient>
    </defs>
    <circle
      cx="50"
      cy="50"
      fill="none"
      stroke="url(#gradient)"
      strokeWidth="8"
      r="36"
      strokeDasharray="169.64600329384882 58.548667764616276"
      transform="rotate(233.707 50 50)"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        repeatCount="indefinite"
        dur="1s"
        values="0 50 50;360 50 50"
        keyTimes="0;1"
      />
    </circle>
  </svg>
)

export default LoaderIcon
