import React from 'react'

const FootnoteIcon = () => (
  <svg
    width="32"
    height="31"
    viewBox="0 0 32 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.2"
      d="M7.9 30.86L5.04 28.78L12.06 17.6L0.62 13.18L1.66 10.06L13.62 13.44L14.4 0.439998H17.78L18.56 13.18L30.52 10.06L31.56 13.18L20.12 17.6L27.14 28.78L24.28 30.86L16.22 20.2L7.9 30.86Z"
      fill="white"
    />
  </svg>
)

export default FootnoteIcon
