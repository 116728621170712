const selectStyles = ({ hideControl }: { hideControl?: boolean }) => {
  return {
    menu: (provided: any) => ({
      ...provided,
      position: 'relative',
      background: '#191919',
      boxShadow: 'none',
      margin: 0
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      '&:hover': { borderColor: '#89919C' },
      '&:focus-within': { borderColor: '#89919C' },
      background: '#191919',
      border: '1px solid #dddddd',
      outline: '0 !important',
      marginBottom: '16px',
      boxShadow: 'none',
      height: '40px',
      display: !hideControl ? 'flex' : 'none'
    }),
    input: (provided: any) => ({
      ...provided,
      width: '100%',
      color: '#ffffff',
      cursor: 'text'
    }),
    placeholder: (provided: any) => ({
      ...provided,
      cursor: 'text'
    }),
    menuList: (provided: any) => ({
      ...provided,
      padding: 0,
      maxHeight: '280px'
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      padding: '1rem 1.25rem',
      transition: '0.1s all ease-out',
      '&:hover': { backgroundColor: '#ecc576', color: '#000' },
      color: state.isDisabled
        ? '#383838'
        : state.isSelected
        ? '#000'
        : '#ffffff',
      fontSize: '0.75rem',
      fontWeight: state.isSelected ? '500 !important' : '400',
      backgroundColor: state.isSelected
        ? '#ecc576'
        : state.isActive
        ? '#E5EAEF'
        : state.backgroundColor,
      cursor: !state.isDisabled && 'pointer',
      borderBottom: '1px solid #3c3c3c'
    }),
    noOptionsMessage: (provided: any) => ({
      ...provided,
      paddingBottom: '1rem'
    })
  }
}

export default selectStyles
