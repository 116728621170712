import styled from 'styled-components'
import Img from 'components/UI/Img'
import { media } from 'styled-bootstrap-grid'
import Link from 'src/components/UI/Link'

export const TopCard = styled.div`
  width: 100%;
  display: block;
  margin-bottom: 4rem;
  position: relative;

  ${media.lg`
    display: flex;
    margin-bottom: 2rem;
  `}
`

export const CardIconsWrapper = styled.div<{
  num?: number
  isSupported?: boolean
}>`
  opacity: 1;
  transform: matrix(1, 0, 0, 1, 0, 0);
  color: #000000;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.63);
  pointer-events: none;
  padding: 8%;

  display: flex;
  align-items: center;
  justify-content: center;
  p {
    color: #000000;
    text-transform: uppercase;
    line-height: 1.3328;
    font-size: calc(0.64vw + 1rem);
    font-family: 'Acumin Pro';
    padding-top: 0.5rem;
    white-space: nowrap;
  }
  ${media.xl`
    p {
      font-size: 1.6rem;
    }
  `}
`
export const SupportersWrapper = styled(CardIconsWrapper)`
  padding-right: ${p => !p.isSupported && 0};
`
export const BottomCard = styled.div`
  height: 100%;
  display: flex;
  justify-content: end;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: flex-start;

  a {
    font-weight: normal;
  }
`
export const Title = styled.div`
  color: #ffffff;
  text-align: left;
  font-size: 1.875rem;
  line-height: 2.25rem;
  margin-bottom: 1rem;
  font-weight: 900;
  font-family: Inter;
  ${media.md`
    font-size: 4.688rem;
    line-height: 5rem;
    margin-bottom: 0.625rem;
  `}
`
export const Name = styled.h2`
  color: ${p => p.theme.colors.white};
  font-family: Inter;
  font-size: 1.125rem;
  font-weight: 800;
  line-height: 1.375rem;
  margin-bottom: 0.125rem;

  ${media.md`
    font-size: 1.875rem;
    line-height: 2.25rem;
    margin-bottom: 0.438rem;
  `}
`
export const Description = styled.p`
  max-height: 80px;
  overflow: hidden;
  * {
    color: ${p => p.theme.colors.white};
    font-family: Inter !important;
    font-weight: 400 !important;
    font-size: 1.063rem !important;
    line-height: 1.625rem !important;
    ${media.lg`
    font-weight: 300 !important;
    font-size: 1.625rem !important;
    line-height: 2.375rem !important;
    max-width: 90% !important;
  `}
  }
`

export const EventContent = styled.div`
  z-index: 2;
`

export const ImgEvent = styled(Img)`
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: -1;
`
export const Left = styled.div`
  position: relative;
  width: 90%;

  ${media.lg`
    width: 50%;
  `}
`
export const Right = styled.div`
  width: 60%;
  position: absolute;
  right: 0;
  top: 50%;
  z-index: 1;

  p {
    font-size: calc(0.94vw + 1rem);
    white-space: normal;
  }

  ${media.lg`
    width: 50%;
  `}
`
export const InvestCard = styled.div<{ img?: string }>`
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  margin: -4.625rem auto 0;

  ::before {
    content: '';
    display: block;
    position: absolute;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(8, 9, 18, 1) 90%
    );
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
  }
  img {
    z-index: 1;
  }
  @media screen and (max-width: 345px) {
    min-height: 120vh;
  }
`

export const DaysLeftContainer = styled.div`
  margin-top: 0;
  height: 0;

  ${media.md`
    margin-top: 52px;
    height: 42%;
  `}
`

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

export const CardInfo = styled.div`
  display: flex;
  flex-direction: column-reverse;
  ${media.md`
    flex-direction: row;
   justify-content: space-between;
  `};
`

export const TitleWrapper = styled.div`
  width: 100%;
  ${media.md`
    width: 71%;
  `};
`
export const CardBottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  ${media.md`
   flex-direction: row;
  `};
`

export const SocialWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${media.md`
   flex-direction: column;
   justify-content: flex-end;
  `};
`
export const FileLink = styled(Link)`
  display: flex;
  align-items: center;
  margin-right: 20px;
`

export const InvestNowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 1500px;
  margin: 0 auto;
  position: absolute;
  top: 65px;
  bottom: 15px;
  right: 15px;
  left: 15px;
  z-index: 2;
  ${media.md`
    top: 3vw;
    bottom: 3vw;
    right: 3vw;
    left: 3vw;
  `}
`
export const FallbackImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
`

export const SocialButtonsWrapper = styled.div`
  ${media.md`
    margin-top: 33px;
  `}
`

// ***** VIDEO ***** //

export const PlayButton = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: none;
  letter-spacing: 2px;
  color: ${p => p.theme.colors.white};
  font-family: Inter;
  font-size: 0.8125rem;
  text-transform: uppercase;
  text-align: left;
  margin-bottom: 23px;
  max-width: 110px;
  svg {
    margin-right: 10px;
    pointer-events: none;
  }
  ${media.md`
  margin-bottom: 0;
  max-width: unset;
  font-size: 0.875rem;
     svg {
    margin-right: 12px;
  }
  `}
`

export const VideoPreview = styled.div<{ img: string }>`
  width: 100%;
  height: 100vh;
  background: url(${p => p.img}),
    linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 1) 90%);
  background-blend-mode: overlay;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
`

export const PlayerWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 12rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Player = styled.video<{ isLoading: boolean }>`
  width: 100%;
  height: 100%;
  display: ${p => (p.isLoading ? 'none' : 'block')};
  outline: none;
  z-index: 3;
`
