import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import Link from 'components/UI/Link'

export const FooterWrap = styled.footer.attrs(() => ({
  className: 'footer'
}))`
  padding: 3.3vmax 0;

  ${media.xl`
    padding: 3.3vmax 4vw;
  `}
`

export const Wrapper = styled.nav`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  ${media.md`
  flex-direction: row;
  justify-content: space-between;
  `}
`

// *** APPS *** //

export const AppWrapper = styled.div`
  display: flex;
  margin-bottom: 2.125rem;
  ${media.md`
    margin-bottom: 2.9375rem;
  `}
`

export const AppLink = styled(Link).attrs(() => ({
  target: '_blank'
}))`
  height: 40px;
  :first-of-type {
    margin-right: 0.9375rem;
    ${media.md`
    margin-right: 1.4375rem;
  `}
  }
`
export const AppImage = styled.img`
  height: 100%;
`

// *** MENU *** //

export const MenuBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${media.md`
  flex-wrap: unset;
  margin-right: 5rem;
  max-width: 43.75rem;
  `}
`

export const ListWrapper = styled.div`
  width: 40%;
  min-width: 120px;
  margin-right: 1rem;
  ${media.md`
    width: unset;
    margin-right: 1.5625rem;
`}
`

// todo: remove font-family
export const MenuTitle = styled.span`
  font-family: Inter;
  font-weight: 800;
  font-size: 1.125rem;

  ${media.md`
   font-weight: 900;
  `}
`

export const MenuList = styled.ul`
  margin-bottom: 1.875rem;
  margin-top: 1.125rem;
  list-style: none;
  text-transform: capitalize;

  ${media.md`
    margin-bottom: unset;
`}
`
export const MenuListItem = styled.li`
  margin-bottom: 1rem;
  ${media.md`
      max-width: 6.875rem;
      margin-bottom: 1.125rem;
`}
  ${media.lg`
    max-width: 100%;
`}
`

// todo: remove font-family
export const MenuLink = styled(Link).attrs(() => ({
  target: '_blank'
}))`
  color: ${p => p.theme.colors.white};
  font-family: Inter, sans-serif;
  font-weight: 300;
  font-size: 1.125rem;
  line-height: 1.5rem;
  :hover {
    opacity: 0.7;
  }
`

// *** COPYRIGHT *** //

export const CopyrightWrapper = styled.div`
  max-width: 321px;
  ::before {
    display: block;
    content: '';
    width: 30px;
    height: 1px;
    background: #b136ff;
    margin-bottom: 0.875rem;
  }
  ${media.md`
    max-width: 10.563rem;
`}
`

// todo: remove font-family
export const Copyright = styled.p`
  font-family: Inter;
  font-weight: 200;
  font-size: 1rem;
  line-height: 1.5rem;
  ${media.md`
   font-weight: 300;
   line-height: 1.375rem;
   :first-of-type {
    margin-bottom: 2rem;
    max-width: 150px;
   }
`}
`

// *** FOOTNOTE *** //

export const FootNoteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  ${media.md`
  margin-top: 80px;
  flex-direction: row;
   `}
`

export const FootNote = styled.div`
  font-family: Inter;
  font-weight: 200;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: rgba(255, 255, 255, 0.7);
  padding: 10px 0;

  ${media.md`
  width: 23.75%;
    padding: 10px;
    :first-of-type {
       padding-left: 0;
       width: 5%;
    }
    :last-of-type {
       padding-right: 0;
  }
  `}
`

export const FootNoteLink = styled(Link).attrs(() => ({
  target: '_blank'
}))`
  color: #800080;
  font-family: Inter;
  font-weight: 200;
  :hover {
    color: #191970;
  }
`
