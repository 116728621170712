import React, { ReactNode } from 'react'
import { generatePath } from 'react-router-dom'
import { IEventDetail } from 'redux/events/types'
import { Statuses } from 'redux/commonTypes'
import { routePath } from 'routes'
import AnalyticsManager from 'services/analyticService'
import DaysLeft from '../DaysLeft'
import ProgressBar from 'src/components/UI/ProgressBar'
import { formatMoney, formatPrice } from 'utils/dateFormats'
import SocialButtons from 'src/components/UI/SocialButtons'
import {
  Amount,
  AmountRow,
  AmountStrong,
  AmountTitle,
  AmountTotal,
  AmountWrapper,
  CardBottom,
  CardInfo,
  DaysLeftContainer,
  Description,
  InfoWrapper,
  LearnMore,
  Name,
  OfferingWrapper,
  SocialButtonsWrapper,
  SocialWrapper,
  Title,
  TitleWrapper,
  Type,
  Wrapper
} from '../styles'
import { clearInvestmentAmountAndTier } from 'redux/investment/actions'
import { useDispatch } from 'react-redux'

interface Props {
  event: IEventDetail
  showHours?: boolean
  showType?: boolean
  isPlaying: boolean
  children: ReactNode
}

const InvestCardContent: React.FC<Props> = ({
  event,
  showHours,
  showType,
  isPlaying,
  children
}): React.ReactElement => {
  const {
    status,
    event_title,
    event_description,
    event_end_at,
    event_end_in_hours,
    event_id,
    event_influencer,
    investmentTerms
  } = event
  const dispatch = useDispatch()
  const { amount_raised, total_offering, min_investment } =
    investmentTerms || {}
  const isComingSoon = status === Statuses.COMING_SOON
  const isEnded = status === Statuses.ENDED
  const isLive = status === Statuses.LIVE
  const analyticsManager = () => {
    dispatch(clearInvestmentAmountAndTier())
    AnalyticsManager.trackRecommendedSectionEvent(event_id)
  }

  const hideContent = isPlaying ? 'none' : 'flex'
  return (
    <Wrapper style={{ display: hideContent }}>
      <DaysLeftContainer>
        <DaysLeft
          endDate={event_end_at}
          eventStatus={status}
          hoursLeft={event_end_in_hours}
          showHours={showHours}
        />
      </DaysLeftContainer>
      <InfoWrapper>
        <CardInfo>
          <TitleWrapper>
            {showType ? (
              <Type>Investment</Type>
            ) : (
              <Name>{event_influencer[0].influencer_name}</Name>
            )}
            <Title fontControl={showType}>{event_title}</Title>
            <Description
              dangerouslySetInnerHTML={{
                __html: event_description
              }}
            />
          </TitleWrapper>
          {children}
        </CardInfo>
        <CardBottom>
          {!isComingSoon ? (
            <OfferingWrapper isEnded={isEnded}>
              {amount_raised > 0 ? (
                <>
                  <ProgressBar
                    total={total_offering}
                    raised={amount_raised}
                    isEndedEvent={isEnded}
                  />
                  <AmountRow isEnded={isEnded}>
                    <div>
                      <AmountWrapper>
                        <AmountStrong>
                          {formatPrice(amount_raised)}
                        </AmountStrong>
                        <AmountTitle>amount raised</AmountTitle>
                      </AmountWrapper>
                      <AmountWrapper>
                        <Amount>{formatPrice(min_investment)}</Amount>
                        <AmountTitle>starting from</AmountTitle>
                      </AmountWrapper>
                      <AmountWrapper>
                        <Amount>
                          {formatMoney(
                            (amount_raised / total_offering) * 100,
                            1
                          )}
                          %
                        </Amount>
                        <AmountTitle>funded</AmountTitle>
                      </AmountWrapper>
                    </div>
                    <AmountWrapper>
                      <AmountTotal>{formatPrice(total_offering)}</AmountTotal>
                      <AmountTitle> total offering</AmountTitle>
                    </AmountWrapper>
                  </AmountRow>
                </>
              ) : (
                <AmountRow
                  style={{ justifyContent: 'flex-start' }}
                  isEnded={isEnded}
                >
                  <AmountWrapper>
                    <AmountTotal>{formatPrice(total_offering)}</AmountTotal>
                    <AmountTitle> total offering</AmountTitle>
                  </AmountWrapper>
                  <AmountWrapper>
                    <Amount>{formatPrice(min_investment)}</Amount>
                    <AmountTitle>starting from</AmountTitle>
                  </AmountWrapper>
                </AmountRow>
              )}
            </OfferingWrapper>
          ) : (
            <OfferingWrapper>
              <AmountRow style={{ justifyContent: 'flex-start' }}>
                <AmountWrapper>
                  <Amount>{formatPrice(min_investment)}</Amount>
                  <AmountTitle>starting from</AmountTitle>
                </AmountWrapper>
                <AmountWrapper>
                  <AmountTotal>{formatPrice(total_offering)}</AmountTotal>
                  <AmountTitle> total offering</AmountTitle>
                </AmountWrapper>
              </AmountRow>
            </OfferingWrapper>
          )}
          {isLive && (
            <SocialWrapper withButton={!isComingSoon}>
              <LearnMore
                to={generatePath(routePath.INVESTMENT, { id: event_id })}
                onClick={analyticsManager}
              >
                Learn More
              </LearnMore>
              <SocialButtonsWrapper>
                <SocialButtons path={routePath.INVESTMENT} event={event} />
              </SocialButtonsWrapper>
            </SocialWrapper>
          )}
        </CardBottom>
      </InfoWrapper>
    </Wrapper>
  )
}

export default InvestCardContent
