import React, { Component } from 'react'
import { NavTab } from './Tab'
import TabsNav from './TabsNavigation'

export interface INavTab {
  id: string | number
  active: boolean
  index: number
  onClickTab: any
  num: number
}

interface Props {
  tabContainer?: React.ReactNode
  children: React.ReactNode
  tabs: any
  activeTab?: string
  hiddenSlider?: boolean
}

class Tabs extends Component<Props> {
  render() {
    const {
      props: { children, tabContainer, tabs, hiddenSlider }
    } = this
    const TabNav: any = tabContainer ? tabContainer : NavTab

    return (
      <div className="tabs">
        <TabsNav tabs={tabs} tabContainer={TabNav} hiddenSlider={hiddenSlider}>
          {children}
        </TabsNav>
      </div>
    )
  }
}

export default Tabs

export { Tabs }
