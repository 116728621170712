import { ActionType } from 'typesafe-actions'
import * as actions from './actions'
import { IErrors, Statuses, MetaAndLinks } from '../commonTypes'
import { InvestmentTier } from '../events/types'

export type UserAction =
  | ActionType<typeof actions.profileSettingsUpdateAsync>
  | ActionType<typeof actions.profilePhotoUpdateAsync>
  | ActionType<typeof actions.profilePhotoDeleteAsync>
  | ActionType<typeof actions.profileFollowingAsync>
  | ActionType<typeof actions.profileBalanceAsync>
  | ActionType<typeof actions.profileNotificationsAsync>
  | ActionType<typeof actions.profileNotificationsReadAsync>
  | ActionType<typeof actions.deleteNotificationAsync>
  | ActionType<typeof actions.profileFetchAboutApplicationAsync>
  | ActionType<typeof actions.fetchUserAddressAsync>
  | ActionType<typeof actions.updateUserAddressAsync>
  | ActionType<typeof actions.sendReportAsync>
  | ActionType<typeof actions.sendMessageAsync>
  | ActionType<typeof actions.profileExportAsync>
  | ActionType<typeof actions.fetchAnalyticsAsync>
  | ActionType<typeof actions.clearProfileErrors>
  | ActionType<typeof actions.clearFollowing>
  | ActionType<typeof actions.changeAddress>
  | ActionType<typeof actions.setAddressErrors>
  | ActionType<typeof actions.setProfileDetailsErrors>
  | ActionType<typeof actions.clearAddressErrors>
  | ActionType<typeof actions.clearNotifications>
  | ActionType<typeof actions.fetchInvestmentsAsync>
  | ActionType<typeof actions.fetchInvestmentDetailsAsync>
  | ActionType<typeof actions.cancelInvestmentAsync>
  | ActionType<typeof actions.clearInvestments>

export interface UserState {
  isFetching: boolean
  following: IProfileFollowing
  balance: IBalance
  notifications: IProfileNotifications
  currentAddress: ICurrentAddress
  isFetchingAddress: boolean
  newAddress: INewAddress
  addressErrors: IErrors<INewAddress>
  eventDetails: { events: IUserEventDetails[] }
  about: { info: string }
  profileDetailsErrors: IErrors<IProfile>
  errors: any
  isSendingReport: boolean
  investments: {
    isFetching: boolean
    allInvestments: AllUserInvestments
    investmentDetails: { data: UserInvestment }
    errors: Error | null
  }
}

export enum UserActionTypes {
  SEND_MESSAGE_REQUEST = '@@profile/SEND_MESSAGE_REQUEST',
  SEND_MESSAGE_SUCCESS = '@@profile/SEND_MESSAGE_SUCCESS',
  SEND_MESSAGE_FAIL = '@@profile/SEND_MESSAGE_FAIL',
  PROFILE_EXPORT_REQUEST = '@@profile/PROFILE_EXPORT__REQUEST',
  PROFILE_EXPORT_SUCCESS = '@@profile/PROFILE_EXPORT__SUCCESS',
  PROFILE_EXPORT_FAIL = '@@profile/PROFILE_EXPORT__FAIL',
  PROFILE_SETTINGS_UPDATE_REQUEST = '@@profile/PROFILE_SETTINGS_UPDATE_REQUEST',
  PROFILE_SETTINGS_UPDATE_SUCCESS = '@@profile/PROFILE_SETTINGS_UPDATE_SUCCESS',
  PROFILE_SETTINGS_UPDATE_FAIL = '@@profile/PROFILE_SETTINGS_UPDATE_FAIL',
  PROFILE_PHOTO_UPDATE_REQUEST = '@@profile/PROFILE_PHOTO_UPDATE_REQUEST',
  PROFILE_PHOTO_UPDATE_SUCCESS = '@@profile/PROFILE_PHOTO_UPDATE_SUCCESS',
  PROFILE_PHOTO_UPDATE_FAIL = '@@profile/PROFILE_PHOTO_UPDATE_FAIL',
  PROFILE_PHOTO_DELETE_REQUEST = '@@profile/PROFILE_PHOTO_DELETE_REQUEST',
  PROFILE_PHOTO_DELETE_SUCCESS = '@@profile/PROFILE_PHOTO_DELETE_SUCCESS',
  PROFILE_PHOTO_DELETE_FAIL = '@@profile/PROFILE_PHOTO_DELETE_FAIL',
  PROFILE_FOLLOWING_REQUEST = '@@profile/PROFILE_FOLLOWING_REQUEST',
  PROFILE_FOLLOWING_SUCCESS = '@@profile/PROFILE_FOLLOWING_SUCCESS',
  PROFILE_FOLLOWING_FAIL = '@@profile/PROFILE_FOLLOWING_FAIL',
  PROFILE_BALANCE_REQUEST = '@@profile/PROFILE_BALANCE_REQUEST',
  PROFILE_BALANCE_SUCCESS = '@@profile/PROFILE_BALANCE_SUCCESS',
  PROFILE_BALANCE_FAIL = '@@profile/PROFILE_BALANCE_FAIL',
  PROFILE_NOTIFICATIONS_REQUEST = '@@profile/PROFILE_NOTIFICATIONS_REQUEST',
  PROFILE_NOTIFICATIONS_SUCCESS = '@@profile/PROFILE_NOTIFICATIONS_SUCCESS',
  PROFILE_NOTIFICATIONS_FAIL = '@@profile/PROFILE_NOTIFICATIONS_FAIL',
  PROFILE_NOTIFICATIONS_READ_REQUEST = '@@profile/PROFILE_NOTIFICATIONS_READ_REQUEST',
  PROFILE_NOTIFICATIONS_READ_SUCCESS = '@@profile/PROFILE_NOTIFICATIONS_READ_SUCCESS',
  PROFILE_NOTIFICATIONS_READ_FAIL = '@@profile/PROFILE_NOTIFICATIONS_READ_FAIL',
  DELETE_NOTIFICATION_REQUEST = '@@profile/DELETE_NOTIFICATION_REQUEST',
  DELETE_NOTIFICATION_SUCCESS = '@@profile/DELETE_NOTIFICATION_SUCCESS',
  DELETE_NOTIFICATION_FAIL = '@@profile/DELETE_NOTIFICATION_FAIL',
  ABOUT_APPLICATION_REQUEST = '@@profile/ABOUT_APPLICATION_REQUEST',
  ABOUT_APPLICATION_SUCCESS = '@@profile/ABOUT_APPLICATION_SUCCESS',
  ABOUT_APPLICATION_FAIL = '@@profile/ABOUT_APPLICATION_FAIL',
  FETCH_USER_ADDRESS_REQUEST = '@@profile/FETCH_USER_ADDRESS_REQUEST',
  FETCH_USER_ADDRESS_SUCCESS = '@@profile/FETCH_USER_ADDRESS_SUCCESS',
  FETCH_USER_ADDRESS_FAIL = '@@profile/FETCH_USER_ADDRESS_FAIL',
  UPDATE_USER_ADDRESS_REQUEST = '@@profile/UPDATE_USER_ADDRESS_REQUEST',
  UPDATE_USER_ADDRESS_SUCCESS = '@@profile/UPDATE_USER_ADDRESS_SUCCESS',
  UPDATE_USER_ADDRESS_FAIL = '@@profile/UPDATE_USER_ADDRESS_FAIL',
  CLEAR_ERRORS = '@@profile/CLEAR_ERRORS',
  CLEAR_FOLLOWING = '@@profile/CLEAR_FOLLOWING',
  CHANGE_ADDRESS = '@@profile/CHANGE_ADDRESS',
  SET_ADDRESS_ERRORS = '@@profile/SET_ADDRESS_ERRORS',
  SET_PROFILE_DETAILS_ERRORS = '@@profile/SET_PROFILE_DETAILS_ERRORS',
  CLEAR_ADDRESS_ERRORS = '@@profile/CLEAR_ADDRESS_ERRORS',
  SEND_REPORT_REQUEST = '@@profile/SEND_REPORT_REQUEST',
  SEND_REPORT_SUCCESS = '@@profile/SEND_REPORT_SUCCESS',
  SEND_REPORT_FAIL = '@@profile/SEND_REPORT_FAIL',
  CLEAR_NOTIFICATIONS = '@@profile/CLEAR_NOTIFICATIONS',
  FETCH_ANALYTICS_REQUEST = '@@profile/FETCH_ANALYTICS_REQUEST',
  FETCH_ANALYTICS_SUCCESS = '@@profile/FETCH_ANALYTICS_SUCCESS',
  FETCH_ANALYTICS_FAIL = '@@profile/FETCH_ANALYTICS_FAIL',
  FETCH_INVESTMENTS_REQUEST = '@@profile/FETCH_INVESTMENTS_REQUEST',
  FETCH_INVESTMENTS_SUCCESS = '@@profile/FETCH_INVESTMENTS_SUCCESS',
  FETCH_INVESTMENTS_FAIL = '@@profile/FETCH_INVESTMENTS_FAIL',
  FETCH_INVESTMENT_DETAILS_REQUEST = '@@profile/FETCH_INVESTMENT_DETAILS_REQUEST',
  FETCH_INVESTMENT_DETAILS_SUCCESS = '@@profile/FETCH_INVESTMENT_DETAILS_SUCCESS',
  FETCH_INVESTMENT_DETAILS_FAIL = '@@profile/FETCH_INVESTMENT_DETAILS_FAIL',
  CANCEL_INVESTMENT_REQUEST = '@@profile/CANCEL_INVESTMENT_REQUEST',
  CANCEL_INVESTMENT_SUCCESS = '@@profile/CANCEL_INVESTMENT_SUCCESS',
  CANCEL_INVESTMENT_FAIL = '@@profile/CANCEL_INVESTMENT_FAIL',
  CLEAR_INVESTMENTS = '@@profile/CLEAR_INVESTMENTS'
}

export enum BrandProfileSectionTypes {
  FAN_VIEW = 'fan-view',
  EDIT_PROFILE = 'edit-profile',
  ANALYTICS = 'analytics',
  MESSAGING = 'messaging'
}

export enum ProfileSectionTypes {
  EXPERIENCES = 'experiences',
  NOTIFICATIONS = 'notifications',
  INVESTMENTS = 'investments'
}

export enum ProfileSettingTypes {
  DETAILS = 'details',
  PASSWORD = 'change-password',
  ADDRESS = 'address',
  FAQ = 'faq',
  REPORT_BUG = 'report-bug',
  CONTACT_SUPPORT = 'contact-support',
  HELP = 'help',
  POLICIES = 'policies',
  TERMS = 'terms-and-conditions',
  PRIVACY = 'privacy-policy',
  SWEEPSTAKES = 'sweepstakes-rules',
  ACCOUNT = 'account'
}

export enum FAQSections {
  ABOUT = 'about-fanvestor',
  PROJECTS = 'platform-projects',
  APP = 'fanvestor-app',
  PAYMENT = 'payment-processing'
}

export interface IProfile {
  email: string
  first_name: string
  last_name: string
  phone?: string
  birth_date?: string // '2019-08-23T00:00:00.000000Z'
  social: any
}

export interface IFollowing {
  id: number
  name: string
  first_name: string
  last_name: string
  influencer_name: string
  influencer_status: string
  status: Statuses
  profile_photo: string | undefined
  events_count: number
}

export interface IProfileFollowing extends MetaAndLinks {
  data: IFollowing[]
}

export interface IToken {
  token: string
  token_name: string
  token_alias: string
  token_picture: string | undefined
  balance: number
}

export interface IBalance extends MetaAndLinks {
  coins: number
  data: IToken[]
}

export interface INotification {
  id: number
  icon: string | null
  sender: {
    name: string
    profile_photo: string | undefined
  }
  subject: string
  text: string
  status: number
  type: number
  created_at: string
  route: string | null
}

export interface IProfileNotifications extends MetaAndLinks {
  data: INotification[]
}

export interface INewAddress {
  country: string
  state: string
  city: string
  street: string
  zip: string
  place_id: string
  room?: string
}

export interface ICurrentAddress extends INewAddress {
  id: number
  user_id: number
  created_at: string
  updated_at: string
}

export enum ReportType {
  BUG = '1',
  SUPPORT = '2'
}

export interface IReport {
  message: string
  device: string
  version: string
}

export interface IUserEventDetails {
  id: number
  internal_status: string
  status: Statuses
  title: string
  picture: string
  description: string
  timeline: string
  tiers_total: number
  user_id: number
  payments_active: number
  start_date: string
  created_at: string
  updated_at: string
  raised: number
}

export interface UserInvestment {
  id: number
  tier_id: number
  user_id: number
  investment_id: string
  amount: string
  share_price: string
  share_count: string
  status: InvestmentStatus
  created_at: string
  updated_at: string
  internal_status: string
  tier: InvestmentTier
}

export interface AllUserInvestments extends MetaAndLinks {
  data: UserInvestment[]
}

export enum InvestmentStatus {
  // NEW STATUSES
  CREATED = 'CREATED',
  FUNDED = 'FUNDED',
  SETTLED = 'SETTLED',
  UNWINDPENDING = 'UNWINDPENDING',
  REFUNDED = 'UNWINDSETTLED',
  CANCELLED = 'CANCELED',
  // OLD STATUSES
  PENDING = 'not_received',
  SUCCESS = 'received',
  OLD_CANCELLED = 'cancelled',
  VOIDED = 'voided'
}
