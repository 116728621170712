import styled, { css } from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { INavTab } from './Tabs'
import NavLink from 'components/UI/NavLink'
import Link from 'components/UI/Link'

export const Slider = styled.div<{ num: number }>`
  background: ${p => p.theme.colors.gold};
  width: calc(100% / ${p => p.num});
  height: 3px;
  position: absolute;
  left: 0;
  top: calc(100% - 3px);
  transition: left 0.3s ease-out;
  display: none;

  ${media.md`display: block;`}
`

export const NavTab = styled(NavLink).attrs(() => ({
  className: 'tab'
}))<INavTab>`
  // flex: 1 1 ${p => (p.num ? 100 / p.num + '%' : 'auto')};
  //border-bottom: 2px solid transparent;
  text-align: center;
  color: #fff;
  cursor: pointer;
  border: none;
  overflow: hidden;
  padding-bottom: 0;
  flex: 0 0 auto;
  margin-right: 10px;
  ${media.lg`
    text-align: left;
    width: 100%;
  `}
  ~ ${Slider} {
    left: 0%;
  }

  &.active {
    p {
      color: ${p => p.theme.colors.white};
    }

    ~ ${Slider} {
      left: ${p => (100 / p.num) * Number(p.index)}%;
    }
  }
`

export const Tab = styled(Link).attrs(() => ({
  className: 'tab'
}))<INavTab>`
  //@ts-ignore
  flex: 1 1 ${p => (p.num ? 100 / p.num + '%' : 'auto')};
  padding: 1rem;
  text-align: center;
  color: #fff;
  cursor: pointer;

  ~ ${Slider} {
    // @ts-ignore
    left: ${p => (p.active ? (100 / p.num) * Number(p.index) : 0)}%;
  }

  p {
    color: #fff;
    font-size: 1rem;
  }

  ${media.md`
    padding-bottom: 0;
    border: none;`}

  ${p =>
    (!p.active &&
      css`
        svg {
          fill: #fff;

          path {
            fill: #fff;
            stroke: #fff;
            stroke-width: 0;
          }
        }
      `) ||
    (p.active &&
      css`
        p {
          color: ${p => p.theme.colors.fuchsia};
        }
      `)}
`

export const InfluencerTab = styled(Link).attrs(() => ({
  className: 'tab'
}))<INavTab>`
  p {
    display: flex;
    align-items: center;
    font-size: 1.75rem;
    font-family: 'Inter';
    color: ${p => (p.active ? p.theme.colors.fuchsia : p.theme.colors.white)};
    opacity: 0.7;

    ${media.lg`
        font-size: 2rem;
    `}
    :hover {
      opacity: 1;
      span {
        opacity: 1;
      }
    }
  }
  span {
    display: block;
    color: ${p => (p.active ? p.theme.colors.fuchsia : p.theme.colors.white)};
    font-family: Inter;
    font-size: 0.75rem;
    line-height: 0.9375rem;
    margin-left: 8px;
    margin-right: 18px;
    line-height: 0.9375rem;
    max-width: 100px;
    ${media.xs`
      font-size: 0.625rem;
      line-height: 0.9375rem;
      letter-spacing: 2px;
      margin-left: 5px;
      margin-right: 5px;
    `}
    ${media.lg`
      font-size: 0.875rem;
      line-height: 1.063rem;
      margin-left: 8px;
      margin-right: 41px;
    `}
  }
`

export const SubTab = styled(NavLink).attrs(() => ({
  className: 'tab'
}))<INavTab>`
  height: 4rem;
  max-width: 4.625rem;
  margin-bottom: 0.625rem;
  margin-right: 0.625rem;
  padding: 0.625rem 0;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  border-radius: 0.375rem;
  background-color: #0f0f0f;
  font-size: 1rem;
  overflow: hidden;
  text-align: center;

  p {
    padding: 0.625rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  ${media.md`
    max-width: 100%;
    margin-right: 0.625rem;
    margin-bottom: 0.625rem;
    padding: 0.75rem 1.25rem;
    text-align: left;
`}

  ${media.lg`
    padding: 1.25rem;
    padding-bottom: 1.5rem;
    flex-direction: row; 
    justify-content: space-between;     
    align-items: center;
    `}

  :last-of-type {
    margin-right: 0;
  }

  &.active {
    height: 4.625rem;
    max-width: 100%;
    margin-bottom: 0;
    padding: 0.625rem 0.625rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-color: ${p => p.theme.colors.black};
    text-align: left;

    :last-of-type {
      margin-right: 0;
    }

    p {
      overflow: visible;
      text-overflow: unset;
      white-space: break-spaces;
    }

    ${media.md`
    margin-right: 0.625rem;
    padding: 0.75rem 1.25rem;
    `}
    ${media.lg`
    padding: 1.25rem;
    padding-bottom: 2.125rem;
    flex-direction: row; 
    justify-content: space-between;     
    align-items: center;
    `}
  }
`
