import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export const Subtitle = styled.p`
  font-family: Inter;
  font-size: 1.063rem;
  font-weight: 400;
  line-height: 1.625rem;
  text-align: center;

  width: 100%;
  margin-bottom: 29px;

  ${media.lg`
     font-size: 1.625rem;
     font-weight: 300;
     line-height: 2.375rem;
     
     margin-bottom: 45px;
  `}
`

export const Wrapper = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;

  > div {
    border-radius: 12px;
    width: 70px !important;
    height: 85px;
    margin-right: 10px;
    ${media.md`
       margin-right: 22px;
      `}
    &:nth-of-type(4) {
      margin-right: 0;
    }
  }

  .code_input {
    input {
      font-family: Inter;
      font-size: 50px !important;
      font-weight: 800 !important;
      line-height: 50px;
      text-align: center;

      width: 100%;
      height: 100%;
    }
  }
`
export const ButtonWrapper = styled.div`
  display: flex;
  max-width: 340px;
  margin: 0 auto;

  button {
    margin: 0 auto;
    margin-bottom: 30px;
    padding: 0;
    font-size: 1.375rem;
    font-weight: 400;
    line-height: 1.625rem;
    ${media.md`
     margin-bottom: 50px;
  `}
  }
`
export const SendButtonWrapper = styled.div`
  max-width: 230px;
  margin: 0 auto;
  button {
    width: 100%;
  }
`
