import React, { FC, useEffect, useState } from 'react'
// @ts-ignore
import { HostedField } from 'react-braintree-fields'
import { Col, Row } from 'styled-bootstrap-grid'
import { useDispatch, useSelector } from 'react-redux'
import { userNameSelector } from 'redux/auth/selectors'
import { clearCheckoutErrors } from 'redux/checkout/actions'
import { selectShipping } from 'redux/checkout/selectors'
import { ICustomFields } from '../CheckoutForm'
import { CreditCard, Field, FieldBlock, Label, ErrorLabel } from './styles'

interface Props {
  cardInfo: any
  onChangeCustomField: (name: string, value: string) => void
  errors: ICustomFields
  fields: any
}

const CardSection: FC<Props> = props => {
  const {
    onChangeCustomField,
    errors,
    cardInfo,
    fields: { number: cardNumber, cvv, expirationDate }
  } = props

  const dispatch = useDispatch()

  const userInfo = useSelector(userNameSelector)
  const { recipient_name } = useSelector(selectShipping)

  const [name, setName] = useState(userInfo || recipient_name || '')

  useEffect(() => {
    return () => {
      dispatch(clearCheckoutErrors())
    }
  }, [dispatch])

  useEffect(() => {
    setName(recipient_name)
    onChangeCustomField('name', recipient_name)
  }, [recipient_name, setName, onChangeCustomField])

  const handleFocusCustomField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.target.classList.add('braintree-hosted-field-focused')
  }

  const handleBlurCustomField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!event.target.value) {
      event.target.classList.remove('braintree-hosted-field-focused')
    }
  }

  const handleChangeCustomField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target

    if (name === 'name') {
      setName(value)
    }

    onChangeCustomField(name, value)
    dispatch(clearCheckoutErrors())

    if (!value.length) {
      event.target.classList.add('empty')
    } else {
      event.target.classList.remove('empty')
    }
  }

  return (
    <CreditCard>
      <Row>
        <Col>
          <FieldBlock>
            <Field
              error={!cardNumber.isPotentiallyValid && !cardNumber.isValid}
            >
              <HostedField
                type="number"
                placeholder="XXXX XXXX XXXX XXXX"
                className={'empty'}
              />
              <Label>Card number</Label>
            </Field>
            {cardNumber.error && cardNumber.error.message && (
              <ErrorLabel label={cardNumber.error.message} />
            )}
          </FieldBlock>
        </Col>
      </Row>
      <Row>
        <Col col={6}>
          <FieldBlock>
            <Field
              error={
                !expirationDate.isPotentiallyValid && !expirationDate.isValid
              }
            >
              <HostedField
                type="expirationDate"
                placeholder="MM / YY"
                className={'empty'}
              />
              <Label>Exp date</Label>
            </Field>
            {expirationDate.error && expirationDate.error.message && (
              <ErrorLabel label={expirationDate.error.message} />
            )}
          </FieldBlock>
        </Col>
        <Col col={6}>
          <FieldBlock>
            <Field error={!cvv.isPotentiallyValid && !cvv.isValid}>
              <HostedField type="cvv" placeholder={''} className={'empty'} />
              <Label>{(cardInfo.code && cardInfo.code.name) || 'CVV'}</Label>
            </Field>
            {cvv.error && cvv.error.message && (
              <ErrorLabel label={cvv.error.message} />
            )}
          </FieldBlock>
        </Col>
      </Row>

      <Row>
        <Col>
          <FieldBlock>
            <Field error={!!(errors && errors.name)}>
              <input
                autoComplete={'off'}
                className={`braintree-hosted-field empty ${
                  name ? 'braintree-hosted-field-focused' : ''
                }`}
                type="text"
                name="name"
                value={name}
                placeholder={userInfo || recipient_name || 'Full Name'}
                onChange={handleChangeCustomField}
                onFocus={handleFocusCustomField}
                onBlur={handleBlurCustomField}
              />
              <Label>Card holder</Label>
            </Field>
            {errors && errors.name && <ErrorLabel label={''} />}
          </FieldBlock>
        </Col>
      </Row>
    </CreditCard>
  )
}

export default CardSection
