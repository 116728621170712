import styled, { css } from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import Link from 'src/components/UI/Link'

const normalTextStyles = css`
  font-weight: 900;
  font-size: 1.125rem;
  line-height: 1.375rem;
`

export const CardInfo = styled.div`
  max-width: 50%;

  :not(:last-of-type) {
    margin-bottom: 1.5rem;
  }
`

export const InfluencerName = styled.span`
  margin-bottom: 0.625rem;
  display: block;
  font-family: Inter, sans-serif;

  ${() => normalTextStyles}
`

export const EventTitle = styled.h2`
  margin-bottom: 0.625rem;
  font-family: Inter, sans-serif;
  font-size: 1.5rem;
  font-weight: 900;
  line-height: 1.75rem;
`

export const EventDescription = styled.div`
  max-height: 7rem;
  overflow: hidden;

  p,
  span,
  a {
    font-family: Inter, sans-serif !important;
    font-size: 1.125rem !important;
    font-weight: 300 !important;
    line-height: 1.75rem !important;
  }
`

export const LearnMoreLink = styled(Link)`
  margin-right: 0.5rem;
  padding: 0.625rem 1.5rem;
  border-radius: 2.25rem;
  flex: 1 1 auto;

  ${media.lg`
    margin-right: 1.125rem;
  `}
`

export const LearnMoreSection = styled.div`
  max-width: 70%;
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
`

export const CardContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`
