import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import Img from 'components/UI/Img'

export const ShoppingCartItemWrapper = styled.div`
  display: flex;
  width: 100%;
  background-color: ${p => p.theme.colors.darkenGold};
  padding: 1.5625rem;
  border-bottom: 1px solid #9d7d50;

  &:last-child {
    border-bottom: none;
  }

  img {
    overflow: hidden;
    width: 100%;
    height: auto;
  }

  ${media.xs`
    padding: 3vw;
  `}
`

export const ShoppingCartItemContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding-left: 0.625rem;

  ${media.sm`
    padding-left: 1.0625rem;
  `}
`

export const ShoppingCartItemContentHeader = styled.div`
  display: flex;
  justify-content: space-between;

  h5 {
    font-weight: 300;
    color: ${p => p.theme.colors.fadedBlack};
    font-size: 1rem;
    line-height: 1.5rem;
  }
`

export const ShoppingCartItemContentFooter = styled.div<{
  isCounter?: boolean
}>`
  display: flex;
  justify-content: ${p => (p.isCounter ? 'flex-end' : 'space-between')};
  align-items: center;
  flex-wrap: wrap;
`

export const ShoppingCartItemPrice = styled.div`
  display: flex;
  align-items: center;
`

export const PriceText = styled.span<{ bold?: boolean }>`
  font-family: 'Lato';
  font-weight: ${p => (p.bold ? 600 : 300)};
  font-size: 1.25rem;
  line-height: 1.875rem;
  color: ${p => p.theme.colors.fadedBlack};

  ${media.xs`
    font-size: calc(0.5vw + 1rem);
  `}
`

export const Line = styled.div`
  margin: 0 3px;
  color: ${p => p.theme.colors.fadedBlack};
`

export const IconInCircle = styled.div<{ width: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${p => p.width || '22.5px'};
  height: ${p => p.width || '22.5px'};
  transition: ease-out 0.1s;
  border: 1px solid transparent;
  border-radius: 50%;

  &:hover,
  &:active {
    border-color: #8f7f68;
    background: rgba(157, 125, 80, 0.5);
  }

  ${media.xs`
    width: 25px;
    height: 25px;
  `}
`

export const TrashIconArea = styled.div`
  position: relative;
  display: flex;
  width: 30px;
  height: 30px;
`
export const ButtonArea = styled.div`
  position: absolute;
  top: -7px;

  ${media.xs`
    top: -2px;
    right: 0;
  `}
`
export const ImgEvent = styled(Img)`
  object-fit: contain;
  width: auto;
  height: 100%;
  z-index: 1;
`
export const EventPhotoWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  min-width: 120px;
  width: 120px;
  height: 120px;
  margin: 0 auto;

  &:before {
    content: '';
    display: block;
    position: absolute;
    background-color: ${p => p.theme.colors.gold};
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  > div {
    z-index: 1;
    background: none;
    > img {
    width: 60px !important;
    height: 60px !important;
  }
`
export const Params = styled.div`
  display: flex;
  align-items: center;
  color: #615038;
  font-family: Poppins;
  font-weight: 300;
  font-size: 12px !important;
  span {
    color: #615038;
    font-family: Poppins;
    font-weight: 300;
    font-size: 12px !important;
    &:first-of-type {
      margin-right: 0;
    }
    &:nth-of-type(2) {
      margin-left: 5px;
    }
  }
`
export const ColorCircle = styled.div<{ hex: string }>`
  width: 14px;
  height: 14px;
  margin-right: 8px;
  border-radius: 50%;
  background-color: ${p => (p.hex ? p.hex : '#fff')};
`
