import styled, { css } from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import Button from 'src/components/UI/Button'
import Link from 'src/components/UI/Link'

const fontStyles = css`
  font-size: 0.875rem;
  text-decoration: underline;
  font-weight: 300;

  ${media.md`
    font-size: 1rem;
  `}
`

export const TermsButton = styled(Button)`
  ${() => fontStyles};
`

export const TermsLink = styled(Link)`
  ${() => fontStyles};
`

export const ReviewText = styled.p`
  margin-bottom: 2.75rem;
  font-size: 0.875rem;
  font-family: Inter;
  font-weight: 300;
  line-height: 1.625rem;

  ${media.md`
    font-size: 1rem;
  `}
`

export const ReviewList = styled.ul`
  margin-bottom: 2.75rem;
  font-size: 0.875rem;
  font-family: Inter;
  font-weight: 300;
  line-height: 1.625rem;
  color: ${p => p.theme.colors.white};

  ${media.md`
    font-size: 1rem;
  `}

  li {
    list-style-position: inside;
  }
`
