import { ProfileSectionTypes, ProfileSettingTypes } from 'redux/profile/types'
import { generatePath } from 'react-router-dom'
import { routePath } from 'routes'
import { LINKS, WEBSITE } from 'utils/constants'
import { EventType } from '../../../redux/events/types'

export interface ILink {
  title: string
  url: string
}

export interface ILinkSections {
  sectionName: string
  links: ILink[]
  hidden?: boolean
}

const mainLinks = [
  {
    title: 'home',
    url: WEBSITE,
    type: null
  },
  {
    title: 'creators',
    url: routePath.INFLUENCER,
    type: null
  },
  {
    title: `Investments & <span>S-NFTs</span>`,
    url: routePath.INVEST_PAGE,
    type: EventType.INVESTMENT
  },
  {
    title: 'NFTs',
    url: routePath.NFT_PAGE,
    type: EventType.NFT
  },
  {
    title: 'shop & charity',
    url: routePath.EVENTS_PAGE,
    type: EventType.EXPERIENCES
  }
]

const configUserLinks = (userActivity: ProfileSectionTypes): ILink[] => [
  {
    title: 'Dashboard',
    url: generatePath(routePath.PROFILE_SECTION, {
      type: userActivity
    })
  },
  {
    title: 'Notifications',
    url: generatePath(routePath.PROFILE_SECTION, {
      type: ProfileSectionTypes.NOTIFICATIONS
    })
  },
  {
    title: 'User Details',
    url: generatePath(routePath.PROFILE_SETTINGS_OPTION, {
      type: ProfileSettingTypes.DETAILS
    })
  },
  {
    title: 'Help Center',
    url: generatePath(routePath.PROFILE_SETTINGS_OPTION, {
      type: ProfileSettingTypes.HELP
    })
  }
]

const configGeneralLinks = (isAuthenticated: boolean): ILinkSections[] => {
  return [
    {
      sectionName: 'company',
      links: [
        {
          title: 'About Us',
          url: WEBSITE + LINKS.ABOUT_US
        },
        {
          title: 'Press',
          url: WEBSITE + LINKS.PRESS
        },
        {
          title: 'Careers',
          url: LINKS.CAREERS
        },
        {
          title: 'Blog',
          url: WEBSITE + LINKS.BLOG
        },
        {
          title: 'Contact Us',
          url: WEBSITE + LINKS.CONTACT_US
        }
      ]
    },
    {
      sectionName: 'FAQ',
      links: [
        {
          title: 'FanVestor',
          url: `${
            isAuthenticated
              ? routePath.PROFILE_SETTINGS_FAQ
              : WEBSITE + LINKS.FAQ
          }`
        },
        {
          title: 'Reg CF',
          url: `${
            isAuthenticated
              ? routePath.PROFILE_SETTINGS_FAQ
              : WEBSITE + LINKS.REG_CF
          }`
        },
        {
          title: 'Experiences',
          url: `${
            isAuthenticated
              ? routePath.PROFILE_SETTINGS_FAQ
              : WEBSITE + LINKS.EXPERIENCES
          }`
        }
      ]
    },
    {
      sectionName: 'How it works',
      links: [
        {
          title: 'invest',
          url: WEBSITE + LINKS.LEARN
        },
        {
          title: 'Risk factors',
          url: WEBSITE + LINKS.RISK
        }
      ]
    },
    {
      sectionName: 'Profile',
      hidden: isAuthenticated,
      links: [
        {
          title: 'Log In',
          url: routePath.SIGN_IN
        },
        {
          title: 'Register',
          url: routePath.SIGN_UP
        }
      ]
    },
    {
      sectionName: 'legal',
      links: [
        {
          title: 'Terms & Conditions',
          url: WEBSITE + LINKS.TERMS
        },
        {
          title: 'Privacy Policy',
          url: WEBSITE + LINKS.PRIVACY
        },
        {
          title: 'Cookie Notice',
          url: WEBSITE + LINKS.COOKIE
        },
        {
          title: 'Accessibility ',
          url: WEBSITE + LINKS.ACCESSIBILITY
        },
        {
          title: 'Form S-P',
          url: WEBSITE + LINKS.FORM_S_P
        }
      ]
    }
  ]
}

export { configUserLinks, mainLinks, configGeneralLinks }
