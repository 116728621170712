import { InfluencerApi } from 'api/influencer.api'

import * as actions from './actions'
import { selectUserInfo } from './selectors'

import { isYoutubeVideo } from 'utils/helpers'
import AnalyticsManager from 'services/analyticService'

import { loadEventsByInfluencer } from 'redux/events/operations'
import { EventType, IEventsByInfluencer } from 'redux/events/types'

import { ThunkAction } from 'store/types'
import { IUpdateInfluencer } from './types'
import axios from 'axios'
import { IStatus } from 'redux/commonTypes'

export const fetchInfluencer = (
  id: string,
  isProfile?: boolean
): ThunkAction => {
  return async dispatch => {
    dispatch(actions.fetchInfluencerAsync.request())

    try {
      const resp = await InfluencerApi.getInfluencer(id, isProfile)

      dispatch(actions.fetchInfluencerAsync.success(resp.data))
    } catch (e) {
      dispatch(actions.fetchInfluencerAsync.failure(e.response.data))
    }
  }
}
export const loadInfluencer = (
  id: string,
  isProfile?: boolean
): ThunkAction => {
  return async dispatch => {
    dispatch(actions.clearInfluencer())

    // @ts-ignore
    dispatch(fetchInfluencer(id, isProfile))
  }
}

export const initTabsContent = (
  id: string,
  isProfile?: boolean
): ThunkAction => {
  return async dispatch => {
    // @ts-ignore
    dispatch(loadInfluencer(id, isProfile))

    dispatch(
      // @ts-ignore
      loadEventsByInfluencer(
        Number(id),
        IEventsByInfluencer.ALL_EVENTS,
        EventType.INVESTMENT
      )
    )

    dispatch(
      // @ts-ignore
      loadEventsByInfluencer(
        Number(id),
        IEventsByInfluencer.ALL_EVENTS,
        EventType.EXPERIENCES
      )
    )

    dispatch(
      // @ts-ignore
      loadEventsByInfluencer(
        Number(id),
        IEventsByInfluencer.ALL_EVENTS,
        EventType.NFT
      )
    )
  }
}
export const updateInfluencer = (description: string): ThunkAction => {
  return async (dispatch, getState) => {
    dispatch(actions.updateInfluencerAsync.request())

    try {
      const {
        first_name,
        last_name,
        influencer_status,
        influencer_name
      } = selectUserInfo(getState())

      const profileData: IUpdateInfluencer = {
        first_name,
        last_name,
        social: {},
        influencer_data: {
          influencer_status,
          description,
          influencer_name
        }
      }

      const resp = await InfluencerApi.updateInfluencer(profileData)

      dispatch(actions.updateInfluencerAsync.success(resp.data))
    } catch (e) {
      dispatch(actions.updateInfluencerAsync.failure(e.response.data))
    }
  }
}

export const uploadYoutubeVideo = (url: string): ThunkAction => {
  return async dispatch => {
    dispatch(actions.uploadYoutubeVideoAsync.request())

    if (!url) {
      return dispatch(
        actions.uploadYoutubeVideoAsync.failure({
          message: 'This field is required',
          name: 'required field'
        })
      )
    }

    if (!isYoutubeVideo(url)) {
      return dispatch(
        actions.uploadYoutubeVideoAsync.failure({
          message: 'The entered URL is invalid',
          name: 'invalid url'
        })
      )
    }

    try {
      const resp = await InfluencerApi.uploadYoutubeVideo(url)

      dispatch(actions.uploadYoutubeVideoAsync.success(resp.data))
    } catch (e) {
      dispatch(actions.uploadYoutubeVideoAsync.failure(e.response.data))
    }
  }
}

export const deleteYoutubeVideo = (params: {
  video_id: number
  storage: number
}): ThunkAction => {
  return async dispatch => {
    dispatch(actions.deleteYoutubeVideoAsync.request())

    try {
      await InfluencerApi.deleteYoutubeVideo(params)

      dispatch(
        actions.deleteYoutubeVideoAsync.success({ video_id: params.video_id })
      )
    } catch (e) {
      dispatch(actions.deleteYoutubeVideoAsync.failure(e.response.data))
    }
  }
}

export const followInfluencer = (): ThunkAction => {
  return async (dispatch, getState) => {
    dispatch(actions.followInfluencerAsync.request())
    try {
      const {
        user: {
          data: { id }
        }
      } = getState().influencer

      const resp = await axios.put<IStatus>('/profile/following', {
        user_id: id
      })
      AnalyticsManager.trackFollowAction(true, id)
      dispatch(actions.followInfluencerAsync.success(resp.data))
    } catch (err) {
      dispatch(actions.followInfluencerAsync.failure(err.response.data))
    }
  }
}

export const unfollowInfluencer = (): ThunkAction => {
  return async (dispatch, getState) => {
    dispatch(actions.unfollowInfluencerAsync.request())
    try {
      const {
        user: {
          data: { id }
        }
      } = getState().influencer

      const params = { user_id: id }

      const resp = await axios.delete<IStatus>('/profile/following', { params })
      AnalyticsManager.trackFollowAction(false, id)
      dispatch(actions.unfollowInfluencerAsync.success(resp.data))
    } catch (err) {
      dispatch(actions.unfollowInfluencerAsync.failure(err.response.data))
    }
  }
}
