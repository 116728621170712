import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import ErrorLabel from 'components/UI/ErrorLabel'

export const braintreeStyles = {
  input: {
    'font-size': '1.315rem',
    'line-height': '1.8125rem',
    'font-family': 'Acumin Pro, Roboto',
    padding: '8px 0 9px 0',
    color: '#ffffff'
  },

  ':focus': {
    color: '#ffffff'
  },
  '::placeholder': {
    color: '#ffffff',
    'font-weight': '300',
    'font-size': '12px'
  },
  ':-webkit-autofill': {
    color: '#ffffff'
  }
}

export const CreditCardForm = styled.form`
  width: 100%;

  ${media.lg`
    max-width: 100%;
    margin: 0;
  `}
`

export const FooterCard = styled.div`
  position: relative;
  margin-top: -1px;
`

export const CustomErrorLabel = styled(ErrorLabel)`
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 150px;
`
