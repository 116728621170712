import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IIRA, IRAFields } from 'redux/investment/types'
import {
  selectInvestmentErrors,
  selectIraInfo
} from 'redux/investment/selectors'
import { setErrors } from 'redux/investment/actions'
import {
  convertValueToTaxId,
  noFirstSpaceRegex,
  noTwoSpacesRegex
} from 'utils/helpers'
import debounce from 'utils/debounce'
import { DEFAULT_TAX_ID } from 'utils/dateFormats'
import TextInput from 'src/components/UI/TextInput'
import BackButton from 'src/components/investment/steps/BackButton'

import { BackButtonWrapper, Form, Navigation, Step, Title } from './styles'

const IRAForm: FC<{ [key: string]: any }> = props => {
  const dispatch = useDispatch()
  const iraInfo = useSelector(selectIraInfo)
  const errors = useSelector(selectInvestmentErrors)
  const { selectedEntityInfo, setSelectedEntityInfo: setIra } = props
  const ira = selectedEntityInfo as IIRA
  const [iraForm, setIraForm] = useState<IIRA>(ira)

  useEffect(() => {
    const { isFetching, id, investor_id, ...selectedIRA } = selectedEntityInfo
    if (Object.values(selectedIRA).every(c => !c)) {
      setIraForm({
        ...iraInfo,
        ira_tax_id: convertValueToTaxId(iraInfo.ira_tax_id)
      })
    }
    // eslint-disable-next-line
  }, [iraInfo])

  useEffect(() => {
    setToContext(iraForm)
    // eslint-disable-next-line
  }, [iraForm])

  const setToContext = useCallback(debounce(setIra, 400), [])

  const onChange = (name: string, value: string) => {
    if (name === 'ira_tax_id') {
      const convertedValue = convertValueToTaxId(value)
      setIraForm({ ...ira, [name]: convertValueToTaxId(convertedValue) })
    } else {
      const currentValue = value
        .replace(noFirstSpaceRegex, '')
        .replace(noTwoSpacesRegex, ' ')
      setIraForm({ ...ira, [name]: currentValue })
    }

    if (errors[name]) {
      dispatch(setErrors({ ...errors, [name]: '' }))
    }
  }

  return (
    <>
      <Navigation>
        <BackButtonWrapper>
          <Step>2</Step>
          <BackButton />
          <Title>IRA Information</Title>
        </BackButtonWrapper>
      </Navigation>
      <Form>
        <TextInput
          name={'vesting_name'}
          value={iraForm.vesting_name}
          label={IRAFields.vesting_name}
          handleChange={onChange}
          wrapperClassName={'form__first-field'}
          errorLabel={errors.vesting_name}
        />
        <TextInput
          type={'tel'}
          name={'ira_tax_id'}
          value={iraForm.ira_tax_id}
          label={IRAFields.ira_tax_id}
          handleChange={onChange}
          maxLength={10}
          placeholder={DEFAULT_TAX_ID}
          errorLabel={errors.ira_tax_id}
          wrapperClassName={'form__last-field'}
        />
      </Form>
    </>
  )
}

export default React.memo(IRAForm)
