import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  EntityType,
  IPaymentMethods,
  paymentMethods
} from 'redux/investment/types'
import { setPaymentMethod } from 'redux/investment/actions'
import { selectPaymentMethod } from 'redux/investment/selectors'
import CreditCard from '../CreditCard'
import Button, { ButtonVariant } from 'src/components/UI/Button'
import { ChevronIcon } from 'src/components/icons'
import PaymentSelector from '../PaymentSelector'
import GenericModal from 'src/components/UI/GenericModal'
import USBank from '../USBank'
import WireTransfer from '../WireTransfer'
import BackButton from 'src/components/investment/steps/BackButton'
import {
  BackButtonWrapper,
  Navigation,
  Step,
  Title
} from 'src/components/investment/steps/styles'

interface Props {
  smallScreen?: boolean
  step?: number
  entity: any
}
const PaymentSection: FC<Props> = ({ entity, smallScreen = false, step }) => {
  const dispatch = useDispatch()
  const selectedMethod = useSelector(selectPaymentMethod)

  const [isOpen, setIsOpen] = useState(false)

  const isEntityIRA = entity === EntityType.IRA

  useEffect(() => {
    if (isEntityIRA) {
      dispatch(setPaymentMethod(IPaymentMethods.WIRE_TRANSFER))
    }
  }, [isEntityIRA, dispatch])

  const renderMethod = () => {
    if (isEntityIRA) {
      return <WireTransfer />
    }
    switch (selectedMethod) {
      case IPaymentMethods.CARD:
        return <CreditCard isMobile={smallScreen} />
      case IPaymentMethods.US_BANK:
        return <USBank />
      case IPaymentMethods.WIRE_TRANSFER:
        return <WireTransfer />
      default:
        return <CreditCard isMobile={smallScreen} />
    }
  }
  const options = paymentMethods.filter(method =>
    isEntityIRA ? method === IPaymentMethods.WIRE_TRANSFER : method
  )

  const onSelect = (method: IPaymentMethods) => {
    dispatch(setPaymentMethod(method))
    closeModal()
  }

  const openModal = () => setIsOpen(true)
  const closeModal = () => setIsOpen(false)

  return (
    <>
      {smallScreen ? (
        <>
          <GenericModal
            isOpen={isOpen}
            onClose={closeModal}
            style={{
              background: 'transparent',
              top: '75%'
            }}
            overlayStyle={{ backgroundColor: 'rgba(9, 11, 22, 0.85)' }}
          >
            <PaymentSelector
              list={options}
              onSelect={onSelect}
              selectedMethod={selectedMethod}
            />
          </GenericModal>
          <Navigation>
            <BackButtonWrapper>
              <BackButton />
              <Title>Payment</Title>
            </BackButtonWrapper>
            {!isEntityIRA && (
              <Button variant={ButtonVariant.Icon} onClick={openModal}>
                <span style={{ paddingRight: 8, fontFamily: 'Inter' }}>
                  {selectedMethod}
                </span>
                <ChevronIcon />
              </Button>
            )}
          </Navigation>
        </>
      ) : (
        <Navigation>
          <BackButtonWrapper>
            <Step>{step}</Step>
            <Title>Payment</Title>
          </BackButtonWrapper>
          {!isEntityIRA && (
            <PaymentSelector
              centred={isEntityIRA}
              list={options}
              onSelect={onSelect}
              selectedMethod={selectedMethod}
            />
          )}
        </Navigation>
      )}
      {renderMethod()}
    </>
  )
}

export default React.memo(PaymentSection)
