import React, { FC, useEffect } from 'react'
import { generatePath, useLocation, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { routePath } from 'routes'
import { selectEventsDetail } from 'redux/events/selectors'
import { CheckoutMethod } from 'redux/checkout/types'
import { shoppingCartInitialization } from 'redux/checkout/operations'
import { selectUser } from 'redux/auth/selectors'
import { resetGuestBuyNow } from 'redux/checkout/actions'
import Link from 'src/components/UI/Link'
import { ButtonVariant } from 'src/components/UI/Button'
import Logo from 'components/UI/Logo'

import { ThankYouWrapper, Title, Wrapper, Description } from './styles'

const SuccessPage: FC = () => {
  // This component must receive location state with checkoutMethod
  const dispatch = useDispatch()
  const event = useSelector(selectEventsDetail)
  const location = useLocation<LocationState>()
  const { first_name } = useSelector(selectUser)

  const {
    state: { eventId = '', checkoutMethod = '', userName = '' } = {}
  } = location

  const text =
    checkoutMethod === CheckoutMethod.INVESTMENT
      ? 'investment commitment'
      : 'order'
  const firstName = userName || first_name || ''

  useEffect(() => {
    return () => {
      dispatch(shoppingCartInitialization())
      dispatch(resetGuestBuyNow())
    }
    // eslint-disable-next-line
  }, [])

  if (!checkoutMethod) {
    return <Redirect to={routePath.START_PAGE} />
  }

  const continueLink = () => {
    switch (checkoutMethod) {
      case CheckoutMethod.INVESTMENT: {
        return generatePath(routePath.INVEST_PAGE)
      }
      case CheckoutMethod.BUY_NOW: {
        return generatePath(routePath.EVENT, {
          id: event.event_id || eventId
        })
      }
      case CheckoutMethod.SHOPPING: {
        return routePath.EVENTS_PAGE
      }
      default: {
        return routePath.START_PAGE
      }
    }
  }

  return (
    <Wrapper>
      <ThankYouWrapper>
        <div style={{ marginBottom: 36 }}>
          <Logo width={130} height={130} />
        </div>
        <Title>{`Thank You ${firstName}`}</Title>
        <Description>
          Your {text} is being processed. You should receive an email
          confirmation shortly.
        </Description>
        <Link variant={ButtonVariant.Default} to={continueLink}>
          Continue
        </Link>
      </ThankYouWrapper>
    </Wrapper>
  )
}

interface LocationState {
  checkoutMethod: CheckoutMethod
  eventId?: string
  tierId?: string
  userName?: string
}

export default SuccessPage
