import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import { Row } from 'styled-bootstrap-grid'
import { maskSsn } from 'utils/helpers'
import {
  selectCurrentStep,
  selectInvestmentErrors,
  selectVerificationInfo
} from 'redux/investment/selectors'
import { setErrors, setInvestmentStep } from 'redux/investment/actions'
import {
  createSubscriptionAgreement,
  getVerificationErrors
} from 'redux/investment/operations'
import { IVerification, VerificationFields } from 'redux/investment/types'
import { selectEventsDetail } from 'redux/events/selectors'
import debounce from 'utils/debounce'
import { DEFAULT_DATE_FORMAT } from 'utils/dateFormats'
import Switch from 'src/components/UI/Switch'
import TextInput, { InputVariant } from 'src/components/UI/TextInput'
import Button, { ButtonVariant } from 'src/components/UI/Button'
import { Controls, BackButton } from 'src/components/investment/steps'
import { AlertIcon } from 'src/components/icons'
import { TrashIcon } from 'components/icons'

import {
  BackButtonWrapper,
  TextWrapper,
  Title,
  Navigation,
  Step,
  FileName,
  FileWrapper,
  FileCol
} from '../styles'
import {
  PassportBlock,
  SSNBlock,
  SSNText,
  SwitchBlock,
  UploadButton,
  VerificationBlock
} from './styles'

interface VerificationProps {
  step?: number
  [key: string]: any
}

const VerificationForm: FC<VerificationProps> = ({ step, ...props }) => {
  const SSN =
    'Are you a US citizen, US resident, or legal in the US and have a Social Security Number (SSN)?'
  const dispatch = useDispatch()
  const verification = useSelector(selectVerificationInfo)
  const currentStep = useSelector(selectCurrentStep)
  const errors = useSelector(selectInvestmentErrors)
  const event = useSelector(selectEventsDetail)
  const {
    verificationInfo,
    setVerificationInfo,
    eventId,
    selectedContactForm,
    tierId,
    subscriptionAgreement,
    setSubscriptionAgreement
  } = props

  const isAlreadyInvested = !!selectedContactForm.id
  const PASSPORT = isAlreadyInvested
    ? 'You previously provided us with a picture of your passport. ' +
      'However, you must upload a new picture if you have since renewed your passport or obtained a new one.'
    : 'Please provide us with a picture of your passport.'

  useEffect(() => {
    ReactTooltip.rebuild()
  })
  const [verificationForm, setVerificationForm] = useState<IVerification>(
    verificationInfo
  )
  const [maskedSsn, setMaskedSsn] = useState<string>('')

  useEffect(() => {
    setToContext(verificationForm)
    // eslint-disable-next-line
  }, [verificationForm])

  useEffect(() => {
    setVerificationForm({
      ...verificationInfo,
      ssn: verification.ssn
    })
    setMaskedSsn(maskSsn(verification.ssn || ''))
    // eslint-disable-next-line
  }, [verification])

  useEffect(() => {
    dispatch(
      setErrors({
        ...errors,
        passport: ''
      })
    )
    // eslint-disable-next-line
  }, [verificationInfo.citizenUSA])

  const setToContext = useCallback(debounce(setVerificationInfo, 600), [])

  const getEventInfo = async () => {
    if (Object.keys(event).length) {
      // const {
      //   investmentTerms: { price_per_share }
      // } = event

      // const equityShareCount = String(amount / price_per_share)
      // const equityShares = Number(parseFloat(equityShareCount).toPrecision(12))

      setSubscriptionAgreement({
        tier_id: tierId.toString()
      })
    }
  }

  useEffect(() => {
    if (eventId) {
      getEventInfo()
    }
    // eslint-disable-next-line
  }, [eventId, event])

  // const onFocus = () => {
  //   setMaskedSsn(formatSSN(verificationForm.ssn || ''))
  // }

  const onBlur = () => {
    setMaskedSsn(maskSsn(verificationForm.ssn || ''))
  }

  const onSelect = () => {
    const updatedFields = {} as IVerification

    if (verificationInfo.citizenUSA) {
      updatedFields.ssn = ''
    } else {
      updatedFields.passport = undefined
      updatedFields.ssn = verificationForm.ssn
    }
    setVerificationInfo({
      ...verificationInfo,
      ...updatedFields,
      citizenUSA: !verificationInfo.citizenUSA
    })
  }

  const onKeyDown = (e: any) => {
    const { key: keyPressed, target } = e
    const { name } = target

    let text = maskedSsn
    let value = verificationInfo[name]

    if (keyPressed === 'Backspace') {
      e.preventDefault()

      text = text.substring(0, text.length - 1)
      value = value.trim('-').substring(0, text.length)

      setMaskedSsn(text)
      setVerificationForm({ ...verificationInfo, [name]: value })

      if (errors[name]) {
        dispatch(setErrors({ ...errors, [name]: '' }))
      }
    }
    return
  }
  const onChange = (name: string, value: string) => {
    if (name === 'ssn') {
      let val = value
      val = val.replace(/\D/g, '')
      val = val.replace(/^(\d{3})/, '$1-')
      val = val.replace(/-(\d{2})/, '-$1-')
      val = val.replace(/(\d)-(\d{4}).*/, '$1-$2')

      setMaskedSsn(val)
    }
    setVerificationForm({ ...verificationInfo, [name]: value })

    if (errors[name]) {
      dispatch(setErrors({ ...errors, [name]: '' }))
    }
  }

  const onNext = () => {
    const errors = getVerificationErrors(verificationInfo, isAlreadyInvested)
    dispatch(setErrors(errors))
    const isValid = !Object.keys(errors).length

    if (isValid) {
      try {
        dispatch(createSubscriptionAgreement(subscriptionAgreement))
        dispatch(setInvestmentStep(currentStep + 1))
      } catch (e) {
        console.error('Please, try again')
      }
    }
  }

  const handleUploadDocument = (event: ChangeEvent<HTMLInputElement>) => {
    const { target: { files = [] } = {} } = event

    const fileList: any[] =
      files && files.length === 0
        ? []
        : files && files.length
        ? Array.from(files)
        : [files]

    const passport = fileList || [new File([], 'passport')]

    setVerificationInfo({
      ...verificationInfo,
      passport: verificationInfo.passport
        ? [...verificationInfo.passport, ...passport]
        : passport
    })
  }

  const deleteDocument = (documentName: string) => {
    const filteredList = verificationInfo.passport.filter(
      (document: any) => document.name !== documentName
    )

    setVerificationInfo({
      ...verificationInfo,
      passport: filteredList
    })
  }

  const renderPassportPhotos = () => (
    <Row style={{ marginTop: 16, padding: '0 0.875rem' }}>
      {verificationInfo &&
        verificationInfo.passport &&
        verificationInfo.passport.map((passport: any, index: number) => {
          if (passport.length === 0) {
            return null
          }
          const splitName = passport.name.split('.')
          const filename = splitName[0]
          const fileExtension = '.' + splitName[1]
          const deleteFile = () => deleteDocument(passport.name)

          return (
            <FileCol col={6} key={`${passport.name}-${index}`}>
              <FileWrapper>
                <FileName>{filename}</FileName>
                <FileName style={{ flex: '1 0 auto', marginRight: 16 }}>
                  {fileExtension}
                </FileName>
                <Button variant={ButtonVariant.Icon} onClick={deleteFile}>
                  <TrashIcon />
                </Button>
              </FileWrapper>
            </FileCol>
          )
        })}
    </Row>
  )

  return (
    <>
      <div>
        <VerificationBlock>
          <Navigation>
            <TextWrapper>
              <BackButtonWrapper>
                <Step>{step}</Step>
                <BackButton />
                <Title>Verification</Title>
              </BackButtonWrapper>
            </TextWrapper>
          </Navigation>
          <SSNBlock>
            <SSNText>{SSN}</SSNText>
            <SwitchBlock>
              <Switch
                selected={verificationInfo.citizenUSA}
                onSelect={onSelect}
                name={'ssn'}
              />
            </SwitchBlock>
          </SSNBlock>
          <TextInput
            variant={InputVariant.Default}
            name={'date_of_birth'}
            value={verificationForm.date_of_birth}
            handleChange={onChange}
            label={VerificationFields.date_of_birth}
            placeholder={DEFAULT_DATE_FORMAT}
            wrapperStyle={{
              borderRadius: verificationInfo.citizenUSA
                ? '0.75rem 0.75rem 0 0'
                : 12
            }}
            maskConfig={{
              mask: '99/99/9999',
              maskChar: null
            }}
            errorLabel={errors.date_of_birth}
          />
          {verificationInfo.citizenUSA ? (
            <TextInput
              variant={InputVariant.Default}
              name={'ssn'}
              onBlur={onBlur}
              value={maskedSsn}
              handleChange={onChange}
              handleKeyDown={onKeyDown}
              maxLength={11}
              label={VerificationFields.ssn}
              placeholder={'XXX-XX-XXXX'}
              maskConfig={{
                mask: '999-99-9999',
                formatChars: { '9': '[*0-9]' },
                maskChar: null
              }}
              errorLabel={errors.ssn}
              wrapperStyle={{ borderRadius: '0 0 0.75rem 0.75rem' }}
            />
          ) : null}
        </VerificationBlock>

        {!verificationInfo.citizenUSA && (
          <>
            <SSNBlock>
              <SSNText>{PASSPORT} (Max. 3 files)</SSNText>
              <PassportBlock>
                <div>
                  {verificationInfo && verificationInfo.passport && (
                    <FileName>{verificationInfo.passport.name}</FileName>
                  )}
                  <input
                    name={'passport'}
                    type={'file'}
                    id={'file'}
                    multiple={true}
                    onChange={handleUploadDocument}
                    accept="image/png, image/jpeg, application/pdf"
                  />
                  <UploadButton htmlFor={'file'}>Upload Passport</UploadButton>
                </div>
                {errors.passport && (
                  <div
                    data-tip={errors.passport}
                    data-for="error-tooltip__passport"
                    style={{ cursor: 'pointer' }}
                  >
                    <AlertIcon />
                  </div>
                )}
                <ReactTooltip
                  type={'error'}
                  effect={'solid'}
                  clickable={true}
                  id="error-tooltip__passport"
                  backgroundColor={'#FF3A44'}
                />
              </PassportBlock>
            </SSNBlock>
            {verificationInfo &&
            verificationInfo.passport &&
            verificationInfo.passport.length
              ? renderPassportPhotos()
              : null}
          </>
        )}
      </div>
      <div>
        <Controls onClick={onNext} />
      </div>
    </>
  )
}

export default React.memo(VerificationForm)
