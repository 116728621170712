import React, { FC, RefObject, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {
  likeComment,
  reportComment,
  replyToComment
} from 'redux/events/operations'
import { setActiveCommentId } from 'redux/events/actions'
import { selectIsAuth, selectUserId } from 'redux/auth/selectors'
import {
  selectActiveCommentId,
  selectIsFetchingComments
} from 'redux/events/selectors'
import { theme } from 'styled/theme'
import ReportDropdown from '../ReportDropdown'
import ReplyField from '../ReplyField'
import OutsideClick from 'components/UI/OutsideClick'

import {
  CommentWrapper,
  CommentContent,
  ReportBlock,
  ReplyWrapper,
  UserName,
  Comment,
  AdditionalInfo,
  AdditionalItem
} from 'components/invest/comments/CommentCard/styles'

import {
  IReply,
  ReportHarassment,
  ReportReason,
  ReportReasonType,
  ReportViolence
} from 'redux/events/comments/types'
import { AppState, Dispatch } from 'store/types'

import Avatar from 'components/common/Avatar'
import {
  LikeIcon,
  CommentClockIcon,
  ReplyIcon,
  ReportIcon
} from 'components/icons'

const ReplyCard: FC<Props> = props => {
  const {
    reply,
    reply: { total_likes = 0, is_liked = false } = {},
    eventId,
    setActiveCommentId,
    isAuthenticated,
    userId,
    activeCommentId,
    isFetching
  } = props

  const ref: RefObject<HTMLDivElement> = useRef(null)

  const [likes, setLikes] = useState(total_likes)
  const [isLiked, setIsLiked] = useState(is_liked)
  const [isReportListShown, setIsReportListShown] = useState(false)
  const [isReplyVisible, setIsReplyVisible] = useState(false)

  const [openModal, setOpenModal] = useState(false)
  const canReport =
    isAuthenticated && reply.user_id !== userId && reply.user_id !== 1
  const isOpen = isReportListShown && activeCommentId === reply.id

  const handleLike = () => {
    const { likeComment } = props

    setLikes(isLiked ? likes - 1 : likes + 1)
    setIsLiked(!isLiked)
    likeComment(reply.id, eventId)
  }

  const handleReport = (
    reason: ReportReason,
    reasonType?: ReportReasonType,
    reasonTypeDetails?: ReportViolence | ReportHarassment
  ) =>
    props.reportComment(
      reply.id,
      eventId,
      reason,
      reasonType,
      reasonTypeDetails
    )

  const toggleReportList = () => {
    setIsReportListShown(!isReportListShown)
    setOpenModal(true)
    if (reply.id !== activeCommentId) {
      setActiveCommentId(reply.id)
    }
  }

  const hideReportList = (isVisible: boolean = openModal) => {
    if (activeCommentId !== reply.id || isVisible) {
      return
    }

    setIsReportListShown(false)
    setActiveCommentId(null)
  }

  const handlePostComment = async (content: string) => {
    const { replyToComment } = props
    const elem = ref.current

    try {
      replyToComment(eventId, reply.parent_id, content)
      hideReplyField()
      if (elem && elem.parentElement) {
        elem.parentElement.scrollIntoView()
      }
    } catch (e) {}
  }

  const showReplyField = () => setIsReplyVisible(true)

  const hideReplyField = () => setIsReplyVisible(false)

  return (
    <CommentWrapper secondary={true} ref={ref}>
      <Avatar name={reply.name} src={reply.icon} size={40} />
      <CommentContent>
        <UserName>
          {reply.name}
          &nbsp;&#8226;&nbsp;
          {reply.title}
        </UserName>
        <Comment>{reply.content}</Comment>
        <AdditionalInfo>
          <AdditionalItem>
            <CommentClockIcon />
            {reply.left}
          </AdditionalItem>

          <AdditionalItem onClick={handleLike} disabled={!isAuthenticated}>
            <LikeIcon
              fill={isLiked ? theme.colors.fuchsia : theme.colors.white}
            />
            Like{likes > 0 && ` • ${likes}`}
          </AdditionalItem>

          {isAuthenticated && (
            <AdditionalItem onClick={showReplyField}>
              <ReplyIcon />
              Reply
            </AdditionalItem>
          )}
          {canReport && (
            <OutsideClick onOutsideClick={hideReportList}>
              <ReportBlock>
                <AdditionalItem id={'report'} onClick={toggleReportList}>
                  <ReportIcon />
                  Report
                </AdditionalItem>
                {isOpen && (
                  <ReportDropdown
                    report={handleReport}
                    hide={hideReportList}
                    isOpen={openModal}
                    setIsOpen={setOpenModal}
                  />
                )}
              </ReportBlock>
            </OutsideClick>
          )}
        </AdditionalInfo>
        {isAuthenticated && isReplyVisible && (
          <ReplyWrapper>
            <OutsideClick onOutsideClick={hideReplyField}>
              <ReplyField
                userName={reply.name}
                isFetching={isFetching}
                post={handlePostComment}
              />
            </OutsideClick>
          </ReplyWrapper>
        )}
      </CommentContent>
    </CommentWrapper>
  )
}

interface ReceivedProps {
  reply: IReply
  eventId: number
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & ReceivedProps

const mapStateToProps = (state: AppState) => ({
  isAuthenticated: selectIsAuth(state),
  userId: selectUserId(state),
  isFetching: selectIsFetchingComments(state),
  activeCommentId: selectActiveCommentId(state)
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      likeComment,
      reportComment,
      replyToComment,
      setActiveCommentId
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReplyCard)
