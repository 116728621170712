import axios from 'axios'

import { IUser } from 'redux/auth/types'
import { IStatus } from 'redux/commonTypes'
import { IPurchased } from 'redux/events/types'
import {
  AllUserInvestments,
  IBalance,
  ICurrentAddress,
  INewAddress,
  INotification,
  IProfileFollowing,
  IProfileNotifications,
  UserInvestment
} from 'redux/profile/types'

export const ProfileApi = {
  getSettings: async () =>
    await axios.get<GetSettingsResponse>('/profile/settings'),

  updateSettings: async (user: UpdateSettingsRequest) =>
    await axios.patch<IStatus>('/profile/settings', user),

  export: async (data: ExportRequest) =>
    await axios.post<IStatus>('/profile/export', data),

  updatePhoto: async (photo: any) => {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
    const formData = new FormData()
    formData.append('profile_photo', photo)

    return await axios.post<IStatus>('/profile/settings', formData, config)
  },

  deletePhoto: async () => await axios.delete<IStatus>('/profile/settings', {}),

  getFollowing: async (params: GetFollowingRequest) =>
    await axios.get<IProfileFollowing>('/profile/following', {
      params
    }),

  getBalance: async () => await axios.get<IBalance>('/profile/balance'),

  getNotifications: async (params: GetNotificationsRequest) =>
    await axios.get<IProfileNotifications>('/profile/notifications', {
      params
    }),

  deleteNotification: async (id: number) =>
    axios.delete<INotification>(`/profile/notifications/${id}`),

  readNotifications: async (data: ReadNotificationRequest) =>
    await axios.put<IStatus>('/profile/notifications/read', data),

  getFaq: async () =>
    await axios.get<GetFaqResponse>('/profile/faq-application'),

  getAddress: async () => await axios.get<ICurrentAddress>('/profile/address'),

  sendAddress: async (address: INewAddress) =>
    await axios.post<any>('/profile/address', address),

  getAnalytics: async () => await axios.get<any>('/profile/export'),

  getPurchasedItems: async (params: GetPurchasedItemsRequest) =>
    await axios.get<IPurchased>('/orders', { params }),

  getUserInvestments: (params: { page: number }) => {
    return axios.get<AllUserInvestments>('/fund-america/user-investments', {
      params
    })
  },

  getInvestmentDetails: (id: number | string) => {
    return axios.get<{ data: UserInvestment }>(
      `/fund-america/user-investments/${id}`
    )
  }
}

interface UpdateSettingsRequest {
  email: string
  first_name: string
  last_name: string
  phone?: string
  social: any
}

interface ExportRequest {
  events: any[]
  format: any
}

interface GetFollowingRequest {
  page?: number
}

interface GetNotificationsRequest {
  page?: number
}

interface ReadNotificationRequest {
  notifications: number[]
}

interface GetFaqResponse {
  info: string
}

interface GetSettingsResponse {
  data: IUser
}

interface GetPurchasedItemsRequest {
  page?: number | string
  title?: string
}
