import React, { useMemo, Fragment } from 'react'
import { IEventTierSets } from 'redux/events/types'
import { ParamsSection, ParameterBlock, OptionRow, OptionBlock } from './styles'
interface TierParamsProps {
  sets: IEventTierSets[]
  selectedTierSet: IEventTierSets | undefined
  handleSelect: (newSet: IEventTierSets) => void
}

interface Option {
  name?: string
  value: string
  label: string
  active: boolean
  disabled: boolean
  hideLabel: boolean
}
interface IParameter {
  label: string
  type: 'size' | 'color'
  options: Option[]
}

const CrossLine = () => (
  <svg width="100%" height="100%" fill="none">
    <line x2="100%" y2="100%" stroke="#554734" x1="0.295159" y1="0.596415" />
  </svg>
)
const listParams = ['size', 'color']

const listInfo = {
  size: {
    label: 'Size',
    type: 'size'
  },
  color: {
    label: 'Color',
    type: 'color'
  }
}

export const TierParams: React.FC<TierParamsProps> = ({
  sets,
  selectedTierSet,
  handleSelect
}): React.ReactElement => {
  const parametersInfo = useMemo<IParameter[]>(() => {
    const notDisabled = listParams.reduce((acc, key, index) => {
      const notDisabledParams = selectedTierSet
        ? sets
            .filter(set => {
              let check = true
              for (let i = 1; i <= index; i++) {
                const checkedKey = listParams[i - 1]

                // @ts-ignore
                if (set[checkedKey] !== selectedTierSet[checkedKey]) {
                  check = false
                }
              }
              return set.quantity > 0 && check
            })
            // @ts-ignore
            .map(s => s[key])
        : []

      return { ...acc, [key]: notDisabledParams }
    }, {})

    const optionsList = listParams.reduce((opt, paramName: string) => {
      const optionValues = sets.reduce((acc, set) => {
        // @ts-ignore
        const tierSetOption = set[paramName]
        // @ts-ignore
        const tierSetCollection: Option[] = acc[paramName] || []
        let option = {
          label: tierSetOption,
          value: tierSetOption,
          hideLabel: false
        }
        if (paramName === 'color') {
          option = {
            label: set.color_name && set.color_name !== null && set.color_name,
            value: tierSetOption,
            hideLabel: true
          }
        }
        return {
          [paramName]:
            !tierSetCollection.find(o => o.value === tierSetOption) &&
            tierSetOption !== null
              ? [...tierSetCollection, option]
              : tierSetCollection
        }
      }, {})

      return { ...opt, ...optionValues }
    }, {})

    return listParams.reduce((acc: IParameter[], key: string) => {
      // @ts-ignore
      const optionItems: Option[] = optionsList[key]
      if (optionItems && optionItems.length !== 0) {
        acc.push({
          // @ts-ignore
          label: listInfo[key].label,
          // @ts-ignore
          type: listInfo[key].type,
          options: optionItems.map(option => {
            return {
              ...option,
              active: selectedTierSet
                ? selectedTierSet[key as keyof IEventTierSets] === option.value
                : false,
              // @ts-ignore
              disabled: !notDisabled[key].includes(option.value)
            }
            // }
          })
        })
      }

      return acc
    }, [])
  }, [sets, selectedTierSet])

  const handleOptionClick = (event: any) => {
    const { name, value } = event.currentTarget
    if (selectedTierSet) {
      let newParams = { ...selectedTierSet, [name]: value }

      let newSet = sets.find(
        set =>
          set.quantity > 0 &&
          set.color === newParams.color &&
          set.size === newParams.size
      )

      if (!newSet) {
        newSet = sets.find(
          set => set.quantity > 0 && set.size === newParams.size
        )
        if (newSet) {
          newParams = {
            ...newParams,
            color: newSet.color
          }
        }
      }

      // @ts-ignore
      handleSelect({ ...newSet })
    }
  }

  return (
    <ParamsSection>
      <ParameterBlock>
        {parametersInfo.map(setParams => {
          const activeLabel =
            (setParams.options.find(o => o.active) || {}).label || ''
          return (
            <Fragment key={setParams.label}>
              <h6>
                {setParams.label}
                <span>{activeLabel && `: ${activeLabel}`}</span>
              </h6>
              <OptionRow>
                {setParams.options.map(option => {
                  return (
                    <OptionBlock
                      key={option.value}
                      name={setParams.type}
                      value={option.value}
                      variant={setParams.type}
                      onClick={handleOptionClick}
                      hex={option.value}
                      selected={option.active}
                      disabled={option.disabled}
                      hideLabel={option.hideLabel}
                    >
                      {option.label && <span>{option.label}</span>}
                      {option.disabled && <CrossLine />}
                    </OptionBlock>
                  )
                })}
              </OptionRow>
            </Fragment>
          )
        })}
      </ParameterBlock>
    </ParamsSection>
  )
}
