import { ActionType } from 'typesafe-actions'
import { ROLES } from 'redux/auth/types'
import * as actions from './actions'

export type VotesAction = ActionType<typeof actions.checkingVotesAsync>

export interface VotesState {
  checking: IVotesChecking
}

export enum VotesActionTypes {
  VOTES_CHECKING_REQUEST = '@@auth/VOTES_CHECKING_REQUEST',
  VOTES_CHECKING_SUCCESS = '@@auth/VOTES_CHECKING_SUCCESS',
  VOTES_CHECKING_FAIL = '@@auth/VOTES_CHECKING_FAIL'
}

export interface IVotesChecking {
  user_voted: boolean
  splash_image: string | null
  votes: string // "2"
  time_until: number
  user_role: ROLES
}
