import { EventsApi } from 'api/events.api'
import { InfluencerApi } from 'api/influencer.api'
import { ProfileApi } from 'api/profile.api'

import * as actions from './actions'
import { selectEvents, selectInfluencers, selectPurchased } from './selectors'

import AnalyticsManager from 'services/analyticService'

import {
  EventCategories,
  EventCategoryFolder,
  EventType,
  IEventsByInfluencer
} from './types'
import { ThunkAction } from 'store/types'
import { Meta } from 'redux/commonTypes'
export * from './comments/operations'

export const fetchEvents = (type: EventType): ThunkAction => {
  return async (dispatch, getState) => {
    dispatch(actions.fetchEventsAsync.request(type))

    let meta: Meta

    if (type === EventType.INVESTMENT) {
      meta = getState().events.investments.meta
    }
    if (type === EventType.EXPERIENCES) {
      meta = getState().events.experiences.meta
    }
    if (type === EventType.NFT) {
      meta = getState().events.nft.meta
    }

    const params: {
      page: number
      type?: number
      include?: string
    } = {
      page: 1
    }

    params.type = type
    // @ts-ignore
    params.page = meta.current_page || 1

    if (params.type === EventType.INVESTMENT) {
      params.include = 'investmentTerms'
    }

    try {
      const resp = await EventsApi.getEvents(params)

      dispatch(actions.fetchEventsAsync.success({ ...resp.data, type }))
    } catch (err) {
      dispatch(
        actions.fetchEventsAsync.failure(
          err.response ? err.response.data : 'Something gone wrong.'
        )
      )
    }
  }
}

export const fetchAllEvents = () => {
  return async (dispatch: any) => {
    dispatch(actions.fetchEventsMenuAsync.request())

    try {
      const resp = await EventsApi.getAllEvents()

      dispatch(actions.fetchEventsMenuAsync.success(resp.data))
    } catch (err) {
      dispatch(actions.fetchEventsMenuAsync.failure(err))
    }
  }
}

export const loadAllEvents = (type: EventType): ThunkAction => {
  return async dispatch => {
    dispatch(actions.clearEventsHomePage(type))
    // @ts-ignore
    dispatch(fetchEvents(type))
  }
}

export const loadMoreAllEvents = (type: EventType): ThunkAction => {
  return async (dispatch, getState) => {
    const { investments, experiences, nft } = selectEvents(getState())

    switch (type) {
      case EventType.INVESTMENT: {
        const {
          links: { next },
          isFetching
        } = investments

        if (!isFetching && next) {
          await dispatch(actions.changeAllEventsHomePage(type))
          // @ts-ignore
          dispatch(fetchEvents(type))
        }
        break
      }

      case EventType.NFT: {
        const {
          links: { next },
          isFetching
        } = nft

        if (!isFetching && next) {
          await dispatch(actions.changeAllEventsHomePage(type))
          // @ts-ignore
          dispatch(fetchEvents(type))
        }
        break
      }

      case EventType.EXPERIENCES:
        {
          const {
            links: { next },
            isFetching
          } = experiences

          if (!isFetching && next) {
            await dispatch(actions.changeAllEventsHomePage(type))
            // @ts-ignore
            dispatch(fetchEvents(type))
          }
        }
        break

      default:
        break
    }
  }
}

export const fetchEventsByInfluencer = (
  influencerId: number,
  eventStatus: IEventsByInfluencer,
  eventType?: EventType
): ThunkAction => {
  return async (dispatch, getState) => {
    const key = `${influencerId}|${eventStatus}|${eventType}`
    dispatch(actions.fetchEventsByInfluencerAsync.request({ key }))

    const { eventsByInfluencer } = selectEvents(getState())

    const {
      pagination: { current_page = 1, total_pages = 1 } = {}
    } = eventsByInfluencer[key]

    const params: { page?: number; type?: number } = { page: 1 }

    params.page = current_page < total_pages ? current_page + 1 : current_page

    if (eventType) {
      params.type = eventType
    }

    try {
      const resp = await EventsApi.getEventsByInfluencer({
        influencerId,
        eventStatus,
        params
      })

      dispatch(
        actions.fetchEventsByInfluencerAsync.success({ key, events: resp.data })
      )
    } catch (err) {
      dispatch(actions.fetchEventsByInfluencerAsync.failure({ key, err }))
    }
  }
}
export const loadMoreEventsByInfluencer = (
  influencerId: number,
  status: IEventsByInfluencer,
  eventType?: EventType
): ThunkAction => {
  return async (dispatch, getState) => {
    const { eventsByInfluencer } = selectEvents(getState())
    const key = `${influencerId}|${status}|${eventType}`

    if (eventsByInfluencer[key]) {
      const { pagination, isFetching = false } = eventsByInfluencer[key]

      if (
        !isFetching &&
        pagination &&
        pagination.current_page < pagination.total_pages
      ) {
        // @ts-ignore
        dispatch(fetchEventsByInfluencer(influencerId, status, eventType))
      }
    }
  }
}
export const loadEventsByInfluencer = (
  influencerId: number,
  eventStatus: IEventsByInfluencer,
  eventType?: EventType
): ThunkAction => {
  return async (dispatch, getState) => {
    const key = `${influencerId}|${eventStatus}|${eventType}`

    dispatch(actions.clearEventsByInfluencer(key))
    // @ts-ignore
    dispatch(fetchEventsByInfluencer(influencerId, eventStatus, eventType))
  }
}
export const loadHomeEvents = (category: EventCategories): ThunkAction => {
  return async (dispatch, getState) => {
    const { isFetching } = selectEvents(getState()).homepage[category]

    if (!isFetching) {
      // @ts-ignore
      dispatch(actions.clearEventsHomePage())
      // @ts-ignore
      dispatch(fetchHomeEvents(category))
    }
  }
}
export const fetchHomeEvents = (category: EventCategories): ThunkAction => {
  return async (dispatch, getState) => {
    dispatch(actions.fetchEventsHomeAsync.request({ category }))

    try {
      const { homepage } = selectEvents(getState())

      const { current_page } = homepage[category].recommended.meta

      const params: { page?: number } = {}

      if (current_page) {
        params.page = current_page
      }

      const resp = await EventsApi.getHomeEvents({
        params,
        category
      })

      dispatch(
        actions.fetchEventsHomeAsync.success({ category, data: resp.data })
      )
    } catch (err) {
      dispatch(
        actions.fetchEventsHomeAsync.failure({
          category,
          errorMessage: err.response.data.errorMessage
        })
      )
    }
  }
}

export const loadMoreHomeEvents = (
  category: EventCategories,
  type: EventCategoryFolder
): ThunkAction => {
  return async (dispatch, getState) => {
    const { isFetching, recommended } = selectEvents(getState()).homepage[
      category
    ]
    const {
      links: { next }
    } = recommended

    if (!isFetching && next) {
      dispatch(actions.changeEventsHomePage(category, type))
      // @ts-ignore
      dispatch(fetchHomeEvents(category))
    }
  }
}

export const fetchEvent = (id: number): ThunkAction => {
  return async dispatch => {
    dispatch(actions.fetchDetailEventsAsync.request())

    try {
      const resp = await EventsApi.getEvent(id)

      dispatch(
        actions.fetchDetailEventsAsync.success({ event: resp.data.data })
      )
    } catch (err) {
      dispatch(actions.fetchDetailEventsAsync.failure(err))
    }
  }
}

export const loadPurchasedItems = (): ThunkAction => {
  return async dispatch => {
    dispatch(actions.clearPurchasedItems())
    // @ts-ignore
    dispatch(fetchPurchased())
  }
}

export const fetchPurchased = (): ThunkAction => {
  return async (dispatch, getState) => {
    dispatch(actions.fetchPurchasedAsync.request())

    try {
      const purchased = selectPurchased(getState())

      const params: { page: number | string } = { page: 1 }

      if (
        purchased.meta &&
        purchased.meta.current_page < purchased.meta.last_page
      ) {
        params.page = purchased.meta.current_page + 1
      }

      const resp = await ProfileApi.getPurchasedItems(params)

      dispatch(actions.fetchPurchasedAsync.success(resp.data))
    } catch (err) {
      dispatch(actions.fetchPurchasedAsync.failure(err))
    }
  }
}

export const loadInfluencers = (name: string): ThunkAction => {
  return async (dispatch, getState) => {
    dispatch(actions.clearInfluencerList())

    const { isFetchingInfluencers } = selectEvents(getState())

    if (!isFetchingInfluencers) {
      // @ts-ignore
      dispatch(searchInfluencers(name))
    }
  }
}

export const loadMoreInfluencers = (name: string): ThunkAction => {
  return async (dispatch, getState) => {
    const { influencers, isFetchingInfluencers } = selectEvents(getState())

    if (
      !isFetchingInfluencers &&
      influencers.pagination &&
      influencers.pagination.current_page !== influencers.pagination.total_pages
    ) {
      // @ts-ignore
      dispatch(searchInfluencers(name))
    }
  }
}

export const searchInfluencers = (name: string): ThunkAction => {
  return async (dispatch, getState) => {
    dispatch(actions.searchInfluencersAsync.request())

    try {
      const influencers = selectInfluencers(getState())

      const params: { page?: number | string } = {}

      if (
        influencers.pagination &&
        influencers.pagination.current_page < influencers.pagination.total_pages
      ) {
        params.page = influencers.pagination.current_page + 1
      }

      const resp = await InfluencerApi.searchInfluencers({
        name,
        params
      })

      AnalyticsManager.trackSearch(name)
      dispatch(actions.searchInfluencersAsync.success(resp.data))
    } catch (err) {
      dispatch(actions.searchInfluencersAsync.failure(err))
    }
  }
}

export const likeEvent = (id: number): ThunkAction => {
  return async dispatch => {
    dispatch(actions.likeEventAsync.request())

    try {
      const resp = await EventsApi.likeEvent(id)

      dispatch(actions.likeEventAsync.success(resp.data))
    } catch (err) {
      dispatch(actions.likeEventAsync.failure(err))
    }
  }
}

export const handleSearchFieldChange = (value: string): ThunkAction => {
  return async (dispatch, getState) => {
    const prevValue = getState().events.influencers.influencersSearchField

    if ((value.length < 2 && value !== '') || value === prevValue) {
      return
    }

    dispatch(actions.changeInfluencerSearchField(value))

    if (prevValue !== value) {
      // @ts-ignore
      dispatch(loadInfluencers(value))
      return
    }
    // @ts-ignore
    dispatch(searchInfluencers(value))
  }
}
