import React, { Component } from 'react'
import Modal from 'src/components/UI/Modal'
import Button from 'components/UI/Button'

import { PopUpBlock, PopUpText, PopUpButtons } from './styles'

interface Props {
  isOpen: boolean
  onHideModal: any
  message?: string
  buttons?: React.ReactNode
  content?: React.ReactNode
  header?: React.ReactNode
}

class PopUpAlert extends Component<Props> {
  static defaultProps: {
    isAlert: true
  }

  render() {
    const {
      isOpen,
      onHideModal,
      message,
      buttons,
      content,
      header
    } = this.props
    return (
      <Modal header={header} isOpen={isOpen} onClose={onHideModal}>
        <PopUpBlock>
          {message && <PopUpText>{message}</PopUpText>}
          {content}
          <PopUpButtons>
            {buttons ? buttons : <Button onClick={onHideModal}>OK</Button>}
          </PopUpButtons>
        </PopUpBlock>
      </Modal>
    )
  }
}

export default PopUpAlert
