import React from 'react'
import { Provider } from 'react-redux'
import branch from 'branch-sdk'
import { ConnectedRouter as Router } from 'connected-react-router'
import ReactPixel from 'react-facebook-pixel'
import { BaseCSS, GridThemeProvider } from 'styled-bootstrap-grid'
import { ThemeProvider } from 'styled-components'
import GlobalStyle from 'styled/globalStyles'
import { gridTheme, theme } from 'styled/theme'
import Loader, { FullLoaderContainer } from 'src/components/UI/Loader'
import { history, routePath, Routes } from 'routes'
import configureStore from 'store'
import { TokenStorage } from 'services/tokenService'
import { initApp } from 'redux/auth/operations'
import { generatePath } from 'react-router-dom'

export const store = configureStore(history)
const branchKey = process.env.REACT_APP_BRANCH_KEY || ''
const pixelID = process.env.REACT_APP_FACEBOOK_PIXEL_ID || ''

class App extends React.Component<{}, { app: React.ReactNode }> {
  constructor(props: {}) {
    super(props)

    this.state = {
      app: (
        <>
          <FullLoaderContainer>
            <Loader />
          </FullLoaderContainer>
          <GlobalStyle />
        </>
      )
    }
  }

  renderMainApp = (): React.ReactNode => (
    <ThemeProvider theme={theme}>
      <GridThemeProvider gridTheme={gridTheme}>
        <Provider store={store}>
          <Router history={history}>
            <Routes />
            <BaseCSS />
            <GlobalStyle />
          </Router>
        </Provider>
      </GridThemeProvider>
    </ThemeProvider>
  )

  initBranch = () => {
    const options = { no_journeys: true }
    branch.init(branchKey, options, (err, data) => {
      if (
        data &&
        data.data_parsed &&
        // @ts-ignore
        data.data_parsed['+clicked_branch_link']
      ) {
        history.replace(
          generatePath(routePath.EVENT, {
            // @ts-ignore
            id: data.data_parsed.$canonical_identifier
          })
        )
      }
    })
  }

  startApp = async () => {
    const isAuth = TokenStorage.isAuthenticated()

    await TokenStorage.initDeviceId()

    if (isAuth) {
      store
        // @ts-ignore
        .dispatch(initApp())
        // @ts-ignore
        .then(() => this.setState({ app: this.renderMainApp() }))
    } else {
      this.setState({
        app: this.renderMainApp()
      })
    }
  }

  componentDidMount(): void {
    this.startApp()
    this.initBranch()
    ReactPixel.init(pixelID)
  }

  render() {
    return this.state.app
  }
}

export default App
