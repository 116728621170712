import React, { FC } from 'react'
import { DataRoomItem } from 'redux/events/types'
import Link from 'components/UI/Link'

import { Icon, IconWrapper, Text, Wrapper } from './styles'
import { SectionTitle } from 'containers/invest/InvestDetailPage/styles'
import { theme } from 'styled/theme'

interface Props {
  data: DataRoomItem[]
}

const DataRoom: FC<Props> = ({ data }) => {
  return (
    <Wrapper>
      <SectionTitle color={theme.colors.white}>Data Room</SectionTitle>
      <Text>
        View the official SEC filing and all related documents and updates for
        this securities offering:
      </Text>
      {data.map(item => (
        <Link key={item.id} variant={'text'} to={item.value} target={'__blank'}>
          <IconWrapper>
            <Icon src={item.icon} />
            <span>{item.name}</span>
          </IconWrapper>
        </Link>
      ))}
    </Wrapper>
  )
}

export default DataRoom
