import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export const Wrapper = styled.div`
  margin: 0 auto;
  margin-bottom: 30px;

  ${media.md`
     margin-bottom: 50px !important;
  `}
  > div,
  button {
    max-width: 330px;
    margin: 0 auto;
  }

  > div {
    margin-bottom: 20px;
  }

  input {
    font-size: 1.5rem;
  }

  button {
    display: block;
    width: 100%;
  }
`
export const Subtitle = styled.p`
  font-family: Inter;
  font-size: 1.063rem;
  font-weight: 400;
  line-height: 1.625rem;
  text-align: center;

  width: 100%;
  margin-bottom: 29px;

  ${media.lg`
     font-size: 1.625rem;
     font-weight: 300;
     line-height: 2.375rem;
     
     margin-bottom: 45px;
  `}
`
