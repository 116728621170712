import React from 'react'

const CircledCrossIcon = props => (
  <svg
    width={62}
    height={62}
    viewBox="0 0 62 62"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M31 61c16.569 0 30-13.431 30-30C61 14.431 47.569 1 31 1 14.431 1 1 14.431 1 31c0 16.569 13.431 30 30 30zM37.67 24.333L24.335 37.667m0-13.334l13.333 13.334-13.333-13.334z"
      stroke="#fff"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default CircledCrossIcon
