import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { MainTitle } from 'containers/UserSettingsPage/styles'
import { Tab } from './Tab'
import { TabsNavigation, TabName } from './styles'

interface Props {
  tabContainer?: React.ReactNode
  children?: React.ReactNode
  tabs: any
  activeTab?: string
  onClickTab?: any
  hiddenSlider?: boolean
  location: Location
}

class TabsNav extends Component<Props, { activeTab: number | string }> {
  onClickTabItem = (tab: any) => {
    const { onClickTab } = this.props

    if (onClickTab) {
      onClickTab(tab.currentTarget.id)
    }
  }

  render() {
    const {
      props: { children, tabContainer, tabs, location }
    } = this
    const path = location.pathname.split('/')
    const isFAQ = path.includes('faq')
    const isNotifications = path.includes('notifications')
    const TabNav: any = tabContainer ? tabContainer : Tab
    const tabNames = Object.keys(tabs)

    return (
      <TabsNavigation>
        {React.Children.map(children, (child: React.ReactNode) => {
          if (!child) {
            return null
          }
          // @ts-ignore
          const { ...restProps } = child.props
          // @ts-ignore
          const currentId = child.key
          // @ts-ignore
          const currentTab = tabs[currentId] || tabs[Number(currentId)]
          const index = tabNames.indexOf(currentId)

          return (
            <TabNav
              {...restProps}
              exact={true}
              key={currentId}
              to={currentTab.url}
              id={currentId}
              index={index}
              num={Object.keys(tabs).length}
              onClick={this.onClickTabItem}
              style={currentTab.isSubTab && { width: '100%' }}
            >
              {!restProps.children ? (
                <>
                  {isNotifications ? (
                    <MainTitle fullWidth={true}>{currentTab.title}</MainTitle>
                  ) : (
                    <TabName
                      isSubTab={currentTab.isSubTab}
                      isFAQ={
                        isFAQ &&
                        currentTab.title === 'Frequently Asked Questions'
                      }
                      style={
                        currentTab.isSubTab && !isFAQ
                          ? { display: 'none' }
                          : { display: 'block' }
                      }
                    >
                      {currentTab.title}
                    </TabName>
                  )}
                </>
              ) : (
                restProps.children
              )}
            </TabNav>
          )
        })}
      </TabsNavigation>
    )
  }
}

// @ts-ignore
export default withRouter(TabsNav)

export { TabsNav }
