import React from 'react'

const ReportIcon = props => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.99996 0.666504C13.6025 0.666504 17.3333 4.39817 17.3333 8.99984C17.3333 13.6015 13.6025 17.3332 8.99996 17.3332C4.39746 17.3332 0.666626 13.6015 0.666626 8.99984C0.666626 4.39817 4.39746 0.666504 8.99996 0.666504ZM8.99996 2.05567C5.17079 2.05567 2.05579 5.17067 2.05579 8.99984C2.05579 12.829 5.17079 15.944 8.99996 15.944C12.8291 15.944 15.9441 12.829 15.9441 8.99984C15.9441 5.17067 12.8291 2.05567 8.99996 2.05567ZM8.99885 11.085C9.45847 11.085 9.83107 11.4576 9.83107 11.9173C9.83107 12.3769 9.45847 12.7495 8.99885 12.7495C8.53922 12.7495 8.16663 12.3769 8.16663 11.9173C8.16663 11.4576 8.53922 11.085 8.99885 11.085ZM8.99535 4.83317C9.31176 4.83292 9.57344 5.06784 9.61507 5.37287L9.62085 5.45767L9.62385 9.209C9.62412 9.55418 9.34452 9.83422 8.99935 9.8345C8.68293 9.83475 8.42125 9.59983 8.37962 9.2948L8.37385 9.21L8.37085 5.45867C8.37057 5.11349 8.65017 4.83345 8.99535 4.83317Z"
      fill="white"
      {...props}
    />
  </svg>
)

export default ReportIcon
