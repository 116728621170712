import styled from 'styled-components'
import { ReactNode } from 'react'
import { media } from 'styled-bootstrap-grid'

export const Wrapper = styled.div<{ isSelected: boolean }>`
  width: 100%;
  margin-bottom: 2rem;
  z-index: ${p => p.isSelected && 1};
`

export const Menu = styled.div`
  position: absolute;
  top: 58px;
  left: 0;
  right: 0;
  outline: 0;
  background-color: #000;
  margin-top: 4px;
  z-index: 2;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.25);
`
export const MainButton = styled.button<{
  isSelected: boolean
  isDisabled: boolean
  isErrored: boolean
  icon?: ReactNode
}>`
  cursor: pointer;
  font-size: 1rem;
  color: ${p => (p.isDisabled ? '#383838' : p.theme.colors.white)};
  font-weight: 400;
  line-height: 1.5rem;
  text-align: left;
  border: none;
  border-bottom: 1px solid ${p => p.theme.colors.lightGrey};
  background-color: transparent;
  margin: 0;
  padding: 1rem 2.5rem;
  padding-left: ${p => (p.icon ? '3.625rem' : 0)};
  text-decoration: none;
  width: 100%;
  border-color: ${p =>
    p.isErrored
      ? '#D82C2C'
      : p.isSelected
      ? p.theme.colors.white
      : p.isDisabled && '#383838'};
  outline: 0;
  ${p => p.isSelected && 'svg{transform: rotate(180deg)}'}

  ${media.lg`
    font-size: 1.25rem;
  `}
`

export const StyledDropdown = styled.div`
  position: relative;
  width: 100%;
`
export const StyledIndicator = styled.div`
  color: gray;
  height: 24px;
  width: 32px;
  display: ${props => (props.hidden ? 'inline-block' : 'none')};
`
export const ArrowBlock = styled.div`
  position: absolute;
  right: 1.375rem;
  top: 50%;
  transform: translateY(-50%);

  svg {
    display: block;
  }
`
export const SelectedOption = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

export const Placeholder = styled(SelectedOption)`
  color: #808080;
  opacity: 0.4;
  pointer-events: none;
  font-size: 0.75rem;

  ${media.md`
    font-size: 1rem;
  `}

  ${media.lg`
    font-size: 1.25rem;
  `}
`
export const LabelWrap = styled.div`
  display: flex;
  align-content: space-between;
  width: 100%;
`
export const IconWrapper = styled(ArrowBlock)`
  right: 0;
  left: 2rem;
`
