import React, { Component, ReactNode, createRef } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import SlickStyles from 'styled/slick'
import { Arrow } from './styles'

interface Props {
  children: ReactNode
  settings?: any
  className?: string
  setRef?: any
  onSwipe?: (direction: string) => void
  customArrow?: ReactNode
  arrowStyles?: (props: any) => any
  asNavFor?: any
}

const CustomArrow = (props: any) => {
  let CustomSliderArrow

  if (props.customArrow) {
    CustomSliderArrow = props.customArrow
  } else {
    CustomSliderArrow = Arrow
  }
  return (
    <CustomSliderArrow
      onClick={props.onClick}
      className={props.className}
      type={props.type}
      style={props.arrowStyles && props.arrowStyles(props)}
      data-testid={`${props.type}-arrow`}
    />
  )
}

class DefaultSlider extends Component<Props> {
  ref = createRef()

  settings = {
    speed: 500,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: (
      <CustomArrow
        type={'next'}
        customArrow={this.props.customArrow}
        arrowStyles={this.props.arrowStyles}
      />
    ),
    prevArrow: (
      <CustomArrow
        type={'prev'}
        customArrow={this.props.customArrow}
        arrowStyles={this.props.arrowStyles}
      />
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ],
    ...this.props.settings
  }

  componentDidMount = (): void => {
    if (this.props.setRef) {
      this.props.setRef.current = this.ref.current
    }
  }

  handleSwipe = (direction: string) => {
    if (this.props.onSwipe) {
      this.props.onSwipe(direction)
    }
  }

  render() {
    const { children, className } = this.props

    return (
      <>
        <Slider
          {...this.settings}
          className={className}
          ref={this.ref}
          onSwipe={this.handleSwipe}
          asNavFor={this.props.asNavFor}
        >
          {children}
        </Slider>
        <SlickStyles />
      </>
    )
  }
}

export default DefaultSlider
