import React, { FC, useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { routePath } from 'routes'
import { TokenStorage } from 'services/tokenService'
import { getError, selectAuth, selectAuthErrors } from 'redux/auth/selectors'
import { clearAuthErrors } from 'redux/auth/actions'
import TextInput from 'src/components/UI/TextInput'
import Button from 'src/components/UI/Button'

import {
  ContentWrapper,
  Form
} from 'components/profile/settings/AddressForm/styles'
import { changePassword } from 'redux/auth/operations'
import { Title } from 'components/UI/Tabs/styles'

const PasswordForm: FC = () => {
  const initialForm = {
    current_password: '',
    password: '',
    password_confirmation: ''
  }
  const [form, setForm] = useState(initialForm)
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const auth = useSelector(selectAuth)
  const errors = useSelector(selectAuthErrors)
  const serverErrors = getError(errors)
  const socialAccount = !!TokenStorage.getSocialToken()
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(clearAuthErrors())
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (auth.showModal) {
      setForm(initialForm)
    }
    // eslint-disable-next-line
  }, [auth.showModal])

  const { current_password, password, password_confirmation } = form

  const allFieldsEmpty =
    !current_password && !password && !password_confirmation

  if (socialAccount) {
    return <Redirect to={routePath.PROFILE_SETTINGS} />
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsDisabled(true)
      await dispatch(changePassword(form))
    } catch (e) {
      setIsDisabled(false)
    } finally {
      setIsDisabled(false)
    }
  }

  const handleChange = (name: string, value: string) => {
    setForm({
      ...form,
      [name]: value
    })

    if (Object.values(form).every(f => f) && isDisabled) {
      setIsDisabled(false)
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Title>Current Password</Title>
      <ContentWrapper>
        <TextInput
          label={'Enter Current Password'}
          name={'current_password'}
          handleChange={handleChange}
          errorLabel={
            serverErrors
              ? 'Current password is incorrect'
              : errors.current_password || ''
          }
          type={'password'}
          value={current_password}
          wrapperClassName={'form__single-field'}
        />
        <Title style={{ marginTop: '22px' }}>New Password</Title>
        <TextInput
          label={'Enter New Password'}
          name={'password'}
          handleChange={handleChange}
          errorLabel={errors.password || ''}
          type={'password'}
          value={password}
          wrapperClassName={'form__first-field'}
        />
        <TextInput
          label={'Confirm New Password'}
          name={'password_confirmation'}
          handleChange={handleChange}
          errorLabel={errors.password_confirmation || ''}
          type={'password'}
          value={password_confirmation}
          wrapperClassName={'form__last-field'}
        />
      </ContentWrapper>

      <Button
        variant={'default'}
        type={'submit'}
        disabled={allFieldsEmpty || isDisabled}
      >
        Update
      </Button>
    </Form>
  )
}

export default PasswordForm
