import React, { FC, useState } from 'react'
import { ITierDetails } from 'redux/events/types'
import { formatDateWithTime, formatMoney } from 'utils/dateFormats'

import {
  Date,
  DetailsButton,
  Info,
  TierTitle,
  Wrapper,
  WrapperBlock,
  WrapperCard,
  WrapperTitle
} from 'components/profile/dashboard/styles'
import Index from '../PurchaseDetail'

interface Props {
  tier: ITierDetails
}

const EventTier: FC<Props> = ({ tier }) => {
  const { influencer, date, amount, payment_status, id } = tier
  const [isOrderListShown, setIsOrderListShown] = useState(false)

  const openDetails = () => setIsOrderListShown(true)
  const closeDetails = () => setIsOrderListShown(false)

  return (
    <>
      {isOrderListShown && (
        <Index tier={tier} isOpen={isOrderListShown} onClose={closeDetails} />
      )}
      <WrapperBlock>
        <WrapperCard>
          <WrapperTitle>
            <TierTitle>{influencer}</TierTitle>
            <Date>{formatDateWithTime(date)}</Date>
          </WrapperTitle>
          <Wrapper>
            <Info>Order #{id}</Info>
            <Date id={'status'}>{payment_status}</Date>
            <DetailsButton onClick={openDetails}>Order details</DetailsButton>
          </Wrapper>
          <Wrapper>
            <Info>
              Total: <b>${formatMoney(amount, 2)}</b>
            </Info>
          </Wrapper>
        </WrapperCard>
      </WrapperBlock>
    </>
  )
}

export default EventTier
