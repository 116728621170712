import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import TextInput from 'src/components/UI/TextInput'
import Button from 'src/components/UI/Button'
import { isValidEmail } from 'utils/helpers'

interface IFormProps {
  onSubmit: (email: string) => {}
  clearErrors: () => void
  serverErrors?: any
  isFetching?: boolean
}

const EnterEmailForm: FC<IFormProps> = ({
  onSubmit,
  clearErrors,
  serverErrors,
  isFetching
}) => {
  const [email, setEmail] = useState<string>('')
  const [error, setError] = useState<string>('')

  useEffect(() => {
    if (serverErrors) {
      setError(serverErrors)
    }
    // eslint-disable-next-line
  }, [serverErrors])

  useEffect(() => {
    clearErrors()
    // eslint-disable-next-line
  }, [email, error])

  const isValid = (): boolean => {
    if (!isValidEmail(email)) {
      setError('You must enter valid email')
    }

    return isValidEmail(email)
  }

  const onUpdate = (name: string, value: string) => {
    setEmail(value)
    setError('')
  }

  const handleSubmit = async () => {
    if (isValid()) {
      onSubmit(email)
    }
  }

  return (
    <>
      <div style={{ marginBottom: '10.875rem' }}>
        <Text>Please enter your e-mail address to activate account</Text>
        <TextInput
          name={'email'}
          label={'Email'}
          errorLabel={error || ''}
          value={email}
          isLabelHidden={!email}
          placeholder={'Email'}
          handleChange={onUpdate}
        />
      </div>
      <Wrapper>
        <Button
          variant={'default'}
          type={'submit'}
          onClick={handleSubmit}
          isLoading={isFetching}
        >
          Send
        </Button>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  margin-bottom: 2.375rem;

  button {
    width: 100%;
    color: #fff;
    font-size: 1.125rem;
  }
`
const Text = styled.p`
  width: 324px;
  color: #808080;
  margin: 0 auto 80px;
  text-align: center;
`

export default EnterEmailForm
