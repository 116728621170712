import React, { useEffect } from 'react'
import { Col, Container, Row } from 'styled-bootstrap-grid'
import { useDispatch, useSelector } from 'react-redux'
import { EventType, IEventDetail } from 'redux/events/types'
import { selectIsNFTsFetching, selectNFTs } from 'redux/events/selectors'
import { loadAllEvents, loadMoreAllEvents } from 'redux/events/operations'
import { selectEventType, selectViewMode } from 'redux/investment/selectors'
import { Statuses } from 'redux/commonTypes'
import { IEventType, IViewMode } from 'redux/investment/types'
import NFTsCard from 'components/NFTs/NFTsCard/NFTsCardList'
import Loader from 'src/components/UI/Loader'

import ComingSoon from 'components/common/ComingSoon'
import InfiniteScroll from 'components/common/InfiniteScroll'
import NFTCardMosaic from 'components/NFTs/NFTsCard/NFTsCardMosaic'

import { InvestmentList, WrapBlock, Wrapper } from './styles'

const Offers: React.FC = () => {
  const dispatch = useDispatch()
  const { data: events, links } = useSelector(selectNFTs)
  const eventsLoading = useSelector(selectIsNFTsFetching)
  const viewMode = useSelector(selectViewMode)
  const eventType = useSelector(selectEventType)

  useEffect(() => {
    dispatch(loadAllEvents(EventType.NFT))

    window.scrollTo({
      top: 0,
      behavior: 'auto'
    })
    // eslint-disable-next-line
  }, [])

  const noItems = () => (
    <Wrapper>
      <ComingSoon text={'No events available'} />
    </Wrapper>
  )

  const renderCardList = (events: IEventDetail[]) => {
    let selectedEvents
    const currentEvents = events.filter(
      ({ status: eventStatus }) =>
        eventStatus === Statuses.LIVE || eventStatus === Statuses.COMING_SOON
    )
    const historyEvents = events.filter(
      ({ status: eventStatus }) =>
        eventStatus === Statuses.ENDED || eventStatus === Statuses.STOPPED
    )

    if (eventType === IEventType.CURRENT) {
      selectedEvents = currentEvents
    } else {
      selectedEvents = historyEvents
    }

    if (!currentEvents.length && !eventsLoading) {
      return noItems()
    }

    return selectedEvents.map((event: IEventDetail) => {
      return viewMode === IViewMode.GRID ? (
        <Col col={12} lg={6} key={event.event_id}>
          <NFTCardMosaic event={event} />
        </Col>
      ) : (
        <Col key={event.event_id}>
          <NFTsCard event={event} />
        </Col>
      )
    })
  }

  const onLoadMore = () => {
    const { next } = links

    if (!eventsLoading && next) {
      dispatch(loadMoreAllEvents(EventType.NFT))
    }
  }

  const recommended = events || []
  return (
    <WrapBlock>
      <Container className={'offers-container'}>
        <InfiniteScroll onLoadMore={onLoadMore}>
          <InvestmentList>
            <Row>{renderCardList(recommended)}</Row>
          </InvestmentList>
        </InfiniteScroll>

        {eventsLoading && <Loader />}
      </Container>
    </WrapBlock>
  )
}

export default Offers
