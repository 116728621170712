import React, { FC, useState } from 'react'
import { formatDateWithTime, formatMoney } from 'utils/dateFormats'
import { UserInvestment } from 'redux/profile/types'
import InvestmentDetails from './InvestmentDetails'

import {
  Date,
  DetailsButton,
  Info,
  TierTitle,
  Wrapper,
  WrapperBlock,
  WrapperCard,
  WrapperTitle
} from 'components/profile/dashboard/styles'

interface Props {
  investment: UserInvestment
}

const InvestListItem: FC<Props> = ({ investment }) => {
  const {
    id,
    created_at,
    amount,
    internal_status,
    tier: {
      event: {
        title: eventTitle = '',
        influencer: { influencer_data: { influencer_name = '' } = {} } = {}
      } = {}
    }
  } = investment

  const [isOpen, setIsOpen] = useState(false)

  const openDetails = () => setIsOpen(true)
  const closeDetails = () => setIsOpen(false)

  const investmentAmount = formatMoney(+amount, 2)

  return (
    <>
      <InvestmentDetails
        investment={investment}
        isOpen={isOpen}
        onClose={closeDetails}
      />
      <WrapperBlock>
        <WrapperCard>
          <WrapperTitle>
            <TierTitle>
              {influencer_name} / {eventTitle}
            </TierTitle>
            <Date>{formatDateWithTime(created_at)}</Date>
          </WrapperTitle>
          <Wrapper>
            <Info>Transaction #{id}</Info>
            <Date style={{ textTransform: 'capitalize' }}>
              {internal_status}
            </Date>
            <DetailsButton onClick={openDetails}>
              Investment details
            </DetailsButton>
          </Wrapper>
          <Wrapper>
            <Info>
              Amount: <b>${investmentAmount}</b>
            </Info>
          </Wrapper>
        </WrapperCard>
      </WrapperBlock>
    </>
  )
}

export default InvestListItem
