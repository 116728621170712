import styled from 'styled-components'
import Button from 'src/components/UI/Button'
import { media } from 'styled-bootstrap-grid'

export const BackWrapper = styled(Button)`
  width: 42px;
  height: 42px;
  margin-right: 10px;
  background: ${p => p.theme.colors.lightBlue};
  border-radius: 50%;
  padding: 0;

  ${media.md`
  display: none;
  `}
  svg {
    transform: rotate(90deg);
  }
`
