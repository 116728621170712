import React, { FC, useState } from 'react'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import Modal from 'components/UI/Modal'
import Img from 'components/UI/Img'
import { CircledCrossIcon } from 'components/icons'

import {
  ZoomContainer,
  CancelButton,
  ZoomImageWrapper,
  ZoomImage,
  ZoomButtonWrapper,
  ZoomButton
} from './styles'

interface Props {
  src: string
  alt: string
  className?: string
}

interface ZoomProps {
  zoomIn: (e: MouseEvent) => void
  zoomOut: (e: MouseEvent) => void
}

const ImageZoom: FC<Props> = props => {
  const { src, alt, className } = props

  const [isOpen, setIsOpen] = useState(false)

  const openModal = () => setIsOpen(true)
  const closeModal = () => setIsOpen(false)

  return (
    <>
      <div className={'zoom__cursor'} onClick={openModal} />
      <Img src={src} alt={alt} className={className} />
      <Modal
        className={'zoom__modal'}
        closeTimeout={500}
        isOpen={isOpen}
        onClose={closeModal}
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: '#000'
        }}
      >
        <ZoomContainer>
          <CancelButton variant="icon" onClick={closeModal}>
            <CircledCrossIcon width={60} height={60} />
          </CancelButton>

          <TransformWrapper
            wheel={{ wheelEnabled: false }}
            doubleClick={{ disabled: true }}
          >
            {({ zoomIn, zoomOut }: ZoomProps) => (
              <ZoomImageWrapper>
                <TransformComponent>
                  <ZoomImage src={src} alt={alt} />
                </TransformComponent>
                <ZoomButtonWrapper>
                  <ZoomButton onClick={zoomIn}>+</ZoomButton>
                  <ZoomButton onClick={zoomOut}>&#8722;</ZoomButton>
                </ZoomButtonWrapper>
              </ZoomImageWrapper>
            )}
          </TransformWrapper>
        </ZoomContainer>
      </Modal>
    </>
  )
}

export default ImageZoom
