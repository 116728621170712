import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { routePath } from 'routes'
import { selectCartItemsCount } from 'redux/checkout/selectors'
import { selectIsAuth } from 'redux/auth/selectors'
import Button from 'components/UI/Button'
import ShoppingCart from 'components/checkout/ShoppingCart'
import { CartIcon } from 'components/icons'

import { CartItemCounter, ShopIconWrapper } from './styles'

interface Props {
  hideMenu: () => void
  isCartOpened: boolean
  setIsCartOpened: (arg: boolean) => void
  location?: Location
}

const ShopIcon: FC<Props> = props => {
  const {
    hideMenu,
    isCartOpened,
    setIsCartOpened,
    location: { pathname = '' } = {} as Location
  } = props
  const itemsInShoppingCart = useSelector(selectCartItemsCount)
  const isAuthenticated = useSelector(selectIsAuth)

  const itemsCount =
    itemsInShoppingCart > 999
      ? `${Math.floor(itemsInShoppingCart / 1000)}k`
      : itemsInShoppingCart

  const handleOpenShoppingCart = () => {
    if (!isCartOpened && pathname !== routePath.CHECKOUT_CART) {
      setIsCartOpened(true)
    }
    hideMenu()
  }

  const handleCloseShoppingCart = () => setIsCartOpened(false)

  return (
    <>
      {isAuthenticated && (
        <div style={{ marginRight: 16 }}>
          <Button variant="icon" onClick={handleOpenShoppingCart}>
            <ShopIconWrapper
              isOpen={isCartOpened}
              isShopping={pathname === routePath.CHECKOUT_CART}
            >
              {!!itemsInShoppingCart && (
                <>
                  <CartIcon style={{ marginRight: 8 }} />
                  <CartItemCounter>{itemsCount}</CartItemCounter>
                </>
              )}
            </ShopIconWrapper>
          </Button>
          {isCartOpened && <ShoppingCart onClose={handleCloseShoppingCart} />}
        </div>
      )}
    </>
  )
}

export default ShopIcon
