import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setCreditCardInfo,
  setErrors,
  setInvestmentStep
} from 'redux/investment/actions'
import {
  AddressFields,
  CardFields,
  ICreditCard,
  IPaymentLimit
} from 'redux/investment/types'
import {
  selectAddressInfo,
  selectCardInfo,
  selectCurrentStep,
  selectInvestmentErrors,
  selectPersonInfo
} from 'redux/investment/selectors'
import { selectUser } from 'redux/auth/selectors'
import { selectUserAddress } from 'redux/profile/selectors'
import { showModal } from 'redux/auth/actions'
import {
  convertValueToPrice,
  noFirstSpaceRegex,
  noSpaceRegex,
  noTwoSpacesRegex
} from 'utils/helpers'
import { EXP_DATE_FORMAT_LONG, EXP_DATE_FORMAT_SHORT } from 'utils/dateFormats'
import { getCreditCardErrors } from 'redux/investment/operations'
import TextInput from 'src/components/UI/TextInput'
import { Controls } from 'src/components/investment/steps'
import { useInvestNowContext } from 'src/pages/investment/InvestNowPage'

import { Form, PaymentWrapper } from 'src/components/investment/steps/styles'
import { AchButton, PaymentTextWrapper } from '../USBank/styles'
import Checkbox, { CheckboxVariant } from 'components/UI/Checkbox'
import { ButtonVariant } from 'src/components/UI/Button'
import { PaymentAgreementModal } from '../PaymentAgreementModal'

const CreditCard: FC<{ isMobile?: boolean }> = ({ isMobile = false }) => {
  const dispatch = useDispatch()
  const currentStep = useSelector(selectCurrentStep)
  const creditCard = useSelector(selectCardInfo)
  const contact = useSelector(selectPersonInfo)
  const address = useSelector(selectAddressInfo)
  const errors = useSelector(selectInvestmentErrors)
  const user = useSelector(selectUser)
  const userAddress = useSelector(selectUserAddress)

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [cardNumber, setCardNumber] = useState<string>('')
  const [cardValue, setCardValue] = useState<string>('')

  const {
    setSelectedPaymentState: setNewCreditCardInfo,
    selectedPaymentState,
    isCreditCardConfirmed,
    setIsCreditCardConfirmed,
    amount
  } = useInvestNowContext()

  const newCreditCardInfo = selectedPaymentState as ICreditCard
  const maskCardValue = (cardValue: string) => {
    const regex = /.(?=.{4,}$)/g
    const subst = '*'
    const maskedCardNumber = cardValue.replace(regex, subst)
    const cardNumberArray = maskedCardNumber.match(/.{1,4}/g) || []
    return cardNumberArray.join(' ') || ''
  }
  useEffect(() => {
    return () => {
      dispatch(setErrors({}))
    }
  }, [dispatch])

  useEffect(() => {
    if (amount > IPaymentLimit.CARD) {
      dispatch(
        showModal(
          `Sorry, the limit on credit cards is ${convertValueToPrice(
            String(IPaymentLimit.CARD)
          )}.`
        )
      )
    }
  }, [amount, dispatch])

  useEffect(() => {
    const cardNumberWithSpaces = maskCardValue(creditCard.card_number)
    setNewCreditCardInfo({
      ...creditCard
    })
    setCardNumber(creditCard.card_number)
    setCardValue(cardNumberWithSpaces)

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!contact.id) {
      const cardholder = `${user.first_name} ${user.last_name}`
      const zip = userAddress.zip

      setNewCreditCardInfo({
        ...newCreditCardInfo,
        cardholder,
        zip
      })
      dispatch(
        setCreditCardInfo({
          ...creditCard,
          cardholder,
          zip
        })
      )
    }
    if (contact.id && !cardNumber) {
      // show cardholder and zip when data is received
      const cardholder = `${contact.first_name} ${contact.last_name}`

      setNewCreditCardInfo({
        ...creditCard,
        cardholder,
        zip: address.postal_code
      })
      dispatch(
        setCreditCardInfo({
          ...creditCard,
          cardholder,
          zip: address.postal_code
        })
      )
    }
    // eslint-disable-next-line
  }, [contact.first_name, contact.last_name, address.postal_code])

  const openCreditCardPolicy = () => setIsOpen(true)
  const closeCreditCardPolicy = () => setIsOpen(false)

  const onCardNumberChange = (name: string, value: string) => {
    let newCardNumber = cardNumber

    const currentValue = value.replace(noSpaceRegex, '')
    const newValue = cardValue.replace(noSpaceRegex, '')
    const currentValueLength = currentValue.length
    const newValueLength = newValue.length
    const lastSymbol = currentValue[currentValueLength - 1] || null

    if (isNaN(Number(lastSymbol))) {
      return
    }

    if (currentValueLength > newValueLength) {
      newCardNumber = cardNumber + lastSymbol
    }

    if (currentValueLength < newValueLength) {
      newCardNumber = cardNumber.slice(0, currentValueLength)
    }

    const cardNumberArray = newCardNumber.match(/.{1,4}/g) || []
    const cardNumberWithSpaces = cardNumberArray.join(' ') || ''

    setCardNumber(newCardNumber)
    setCardValue(cardNumberWithSpaces)
    onChange(name, newCardNumber)
  }

  const onCardNumberFocus = () => {
    const cardNumberArray = cardNumber.match(/.{1,4}/g) || []
    const cardNumberWithSpaces = cardNumberArray.join(' ') || ''
    setCardValue(cardNumberWithSpaces)
  }

  const onCardNumberBlur = () => {
    const cardNumberWithSpaces = maskCardValue(cardNumber)
    setCardValue(cardNumberWithSpaces)
    dispatch(setCreditCardInfo({ ...creditCard, card_number: cardNumber }))
  }

  const onChange = (name: string, value: string) => {
    let newValue = value
      .replace(noFirstSpaceRegex, '')
      .replace(noTwoSpacesRegex, ' ')

    if (name === 'cvv' && value.length > 3) {
      return
    }
    if (name === 'cardholder') {
      newValue = newValue.replace(/[0-9]/g, '')
    }
    if (name === 'expiration_date') {
      const firstSymbol = newValue[0]
      const secondSymbol = newValue[1]
      if (Number(firstSymbol) > 1) {
        newValue = ''
      }
      if (Number(`${firstSymbol}${secondSymbol}`) > 12) {
        newValue = firstSymbol
      }

      if (isMobile) {
        switch (value.length) {
          case 2:
            newValue =
              value.length > newCreditCardInfo.expiration_date.length
                ? `${value}/`
                : value
            break
          case 6:
            return
        }
      }
    }
    const creditCardInfo = {
      ...newCreditCardInfo,
      [name]: newValue
    }

    setNewCreditCardInfo(creditCardInfo)
    dispatch(setCreditCardInfo(creditCardInfo))
    if (errors[name]) {
      dispatch(setErrors({ ...errors, [name]: '' }))
    }
  }
  const confirmDisclaimer = () => {
    setIsCreditCardConfirmed(!isCreditCardConfirmed)
  }

  const onClick = () => {
    // dispatch(setCreditCardInfo(newCreditCardInfo))
    const errors = getCreditCardErrors(newCreditCardInfo)
    dispatch(setErrors(errors))
    const isValidCard = !Object.keys(errors).length
    if (isValidCard) {
      dispatch(setInvestmentStep(currentStep + 1))
    }
  }

  const expDateMask = {
    maskConfig: {
      mask: '99/9999',
      maskChar: null
    }
  }

  const cvvMask = {
    maskConfig: {
      mask: '999',
      maskChar: null
    }
  }

  return (
    <>
      <PaymentAgreementModal isOpen={isOpen} onClose={closeCreditCardPolicy} />
      <PaymentWrapper>
        <Form>
          <TextInput
            type={'tel'}
            name={'card_number'}
            value={cardValue}
            label={CardFields.card_number}
            handleChange={onCardNumberChange}
            onBlur={onCardNumberBlur}
            onFocus={onCardNumberFocus}
            maxLength={19}
            wrapperClassName={'credit-card__first-field'}
            errorLabel={errors.card_number}
          />
          <TextInput
            type={'tel'}
            name={'expiration_date'}
            value={newCreditCardInfo.expiration_date}
            label={CardFields.expiration_date}
            wrapperClassName={'credit-card__second-field'}
            placeholder={
              isMobile ? EXP_DATE_FORMAT_SHORT : EXP_DATE_FORMAT_LONG
            }
            handleChange={onChange}
            errorLabel={errors.expiration_date}
            {...expDateMask}
          />
          <TextInput
            type={'tel'}
            name={'cvv'}
            value={newCreditCardInfo.cvv}
            label={CardFields.cvv}
            wrapperClassName={'credit-card__third-field'}
            handleChange={onChange}
            errorLabel={errors.cvv}
            {...cvvMask}
          />
          <TextInput
            name={'cardholder'}
            value={newCreditCardInfo.cardholder}
            label={CardFields.cardholder}
            handleChange={onChange}
            maxLength={128}
            errorLabel={errors.cardholder}
            wrapperClassName={'credit-card__second-field'}
            wrapperStyle={{ borderBottomLeftRadius: 12 }}
          />
          <TextInput
            name={'zip'}
            value={newCreditCardInfo.zip}
            label={AddressFields.postal_code}
            handleChange={onChange}
            wrapperClassName={'credit-card__zip-field'}
            errorLabel={errors.zip}
            maxLength={5}
          />
          <div style={{ marginTop: '2.875rem' }}>
            <PaymentTextWrapper>
              <div style={{ marginRight: 16, paddingTop: 6 }}>
                <Checkbox
                  name={'ACH'}
                  variant={CheckboxVariant.Blue}
                  checked={isCreditCardConfirmed}
                  handleChange={confirmDisclaimer}
                />
              </div>
              <p>
                I hereby authorize FanVestor's escrow agent, North Capital
                Private Securities Corporation to charge my credit card. I
                acknowledge that I have read and agree to the full{' '}
                <AchButton
                  variant={ButtonVariant.Text}
                  onClick={openCreditCardPolicy}
                >
                  Electronic Funds Transfers Policy
                </AchButton>
              </p>
            </PaymentTextWrapper>
          </div>
        </Form>
        <Controls
          onClick={onClick}
          disabled={amount > IPaymentLimit.CARD || !isCreditCardConfirmed}
        />
      </PaymentWrapper>
    </>
  )
}

export default CreditCard
