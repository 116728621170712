import React, { Component, MouseEvent } from 'react'
import { connect } from 'react-redux'
import { Col, Container, Row } from 'styled-bootstrap-grid'
import { bindActionCreators } from 'redux'

import { routePath } from 'routes'
import {
  isBrandSelector,
  selectIsAuth,
  selectUserId
} from 'redux/auth/selectors'
import { likeEvent } from 'redux/events/operations'

import { CommentIcon, HeartIcon, SocialShareIcon } from 'components/icons'
import Button from 'components/UI/Button'
import DetailShareButton from 'components/common/DetailShareButton'
import { Loader } from 'components/UI/Loader'

import {
  BlockWrapper,
  Counters,
  EventInfo,
  EventTextBlock,
  EventTitle,
  EventWrap,
  ImgEvent,
  Left,
  Right,
  SocialWrapper,
  UpperWrapper
} from './styles'

import { AppState, Dispatch } from 'store/types'
import { EventType, IEventDetail } from 'redux/events/types'
import { Statuses } from 'redux/commonTypes'
import { RouteComponentProps, withRouter } from 'react-router-dom'

interface State {
  likes: number
  isLiked: boolean
}

class EventHeader extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      likes: props.event.likes_count,
      isLiked: props.event.is_liked
    }
  }

  componentDidUpdate = (prevProps: Readonly<Props>): void => {
    const { event } = this.props
    if (event.likes_count !== prevProps.event.likes_count) {
      this.setState({ likes: event.likes_count })
    }
  }

  handleLike = () => {
    const {
      likeEvent,
      event: { event_id },
      isAuthenticated,
      history
    } = this.props

    if (!isAuthenticated) {
      history.push({
        pathname: routePath.SIGN_IN,
        state: {
          from: history.location.pathname
        }
      })
    }

    this.setState(
      ({ likes, isLiked }) => ({
        likes: isLiked ? likes - 1 : likes + 1,
        isLiked: !isLiked
      }),
      () => likeEvent(event_id)
    )
  }

  handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()

    const anchor: string =
      (event.target as HTMLAnchorElement).getAttribute('href') || ''
    const anchorTarget = anchor ? document.querySelector(anchor) : null

    if (anchorTarget) {
      anchorTarget.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  render() {
    const { event, userId, isAuthenticated } = this.props
    const { likes, isLiked } = this.state

    if (!Object.keys(event).length) {
      return <Loader />
    }
    const {
      influencer_name,
      influencer_profile_photo
    } = event.event_influencer[0]

    const socialButtons = () => (
      <>
        <Button variant={'clear'} onClick={this.handleLike}>
          <HeartIcon fill={isLiked ? '#D4AB72' : '#fff'} />
          <span style={{ height: '10px' }}>{likes > 0 ? likes : ''}</span>
        </Button>
        <a href="#conversation" onClick={this.handleClick}>
          <CommentIcon style={{ pointerEvents: 'none' }} />
          <span style={{ height: '10px' }}>
            {event.comments_count > 0 ? event.comments_count : ''}{' '}
          </span>
        </a>
        {![Statuses.ENDED, Statuses.STOPPED].includes(event.status) && (
          <DetailShareButton
            event={event}
            children={<SocialShareIcon />}
            userId={userId}
            isAuthenticated={isAuthenticated}
          />
        )}
      </>
    )

    return (
      <>
        {event.type === EventType.INVESTMENT ? (
          <SocialWrapper>{socialButtons()}</SocialWrapper>
        ) : (
          <BlockWrapper>
            <Container>
              <Row justifyContent={'center'}>
                <Col xs={12} col={9}>
                  <UpperWrapper>
                    <Left>
                      <EventWrap>
                        <ImgEvent src={influencer_profile_photo} />
                      </EventWrap>
                    </Left>
                    <Right>
                      <EventInfo>
                        <EventTextBlock>
                          <h1>{influencer_name}</h1>
                          <EventTitle>{event.event_title}</EventTitle>
                        </EventTextBlock>
                        <Counters>{socialButtons()}</Counters>
                      </EventInfo>
                    </Right>
                  </UpperWrapper>
                </Col>
              </Row>
            </Container>
          </BlockWrapper>
        )}
      </>
    )
  }
}

interface ReceivedProps {
  event: IEventDetail
}
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = ReceivedProps & StateProps & DispatchProps & RouteComponentProps

const mapStateToProps = (state: AppState) => ({
  userId: selectUserId(state),
  isBrand: isBrandSelector(state),
  isAuthenticated: selectIsAuth(state)
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ likeEvent }, dispatch)

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EventHeader)
)
