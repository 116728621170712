import React, { Component, ReactNode } from 'react'
import Select, { components } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { CSSObject } from 'styled-components'
import ReactTooltip from 'react-tooltip'
import { Label } from 'src/components/UI/Label'
import { CrossIcon } from 'components/icons'
import { debounce } from 'utils/debounce'
import { AlertIcon } from 'src/components/icons'
import ChevronDownIcon from 'src/components/icons/ChevronDownIcon'
import OutsideClick from 'components/UI/OutsideClick'
import selectStyles from './select.styles'
import {
  AlertWrapper,
  ArrowBlock,
  IconWrapper,
  LabelWrap,
  MainButton,
  Menu,
  Placeholder,
  SelectedOption,
  StyledDropdown,
  StyledIndicator,
  Wrapper
} from './styles'

export interface Option {
  label?: string
  value?: any
  code?: string
}

export interface DropdownProps {
  id?: string
  name: string
  options?: Option[]
  label?: string
  placeholder?: string
  hideControl?: boolean
  onInputChange?: (value: string) => void
  value?: any
  onSelect?: (name: string, value: any) => void
  forceUpdate: boolean
  isDisabled: boolean
  isCreatable: boolean
  isReadOnly: boolean
  isAsync: boolean
  errorLabel: string
  icon?: ReactNode
  valid?: boolean
  wrapperClassName?: string
  customStyles?: CustomStyles
  buttonClassName?: string
}

export interface CustomStyles {
  control?: CSSObject
  input?: CSSObject
  firstOption?: CSSObject
  option?: CSSObject
  menu?: CSSObject
}

interface DropdownState {
  isOpen: boolean
  option: Option
  prevPropsOption: any
}

// @ts-ignore
const DropdownIndicator = props => (
  <StyledIndicator hidden={props.selectProps.inputValue}>
    <components.DropdownIndicator {...props}>
      <CrossIcon width={'16px'} heigh={'16px'} fill={'#e3e7ed'} />
    </components.DropdownIndicator>
  </StyledIndicator>
)

const Dropdown = ({ children, isOpen, target, onClose, isReadOnly }: any) => (
  <StyledDropdown>
    <OutsideClick onOutsideClick={onClose}>
      {target}
      {isOpen && !isReadOnly ? <Menu id="menu">{children}</Menu> : null}
    </OutsideClick>
  </StyledDropdown>
)

const DropdownSelectComponent = ({ isCreatable, ...props }: any) =>
  isCreatable ? <CreatableSelect {...props} /> : <Select {...props} />

class DropdownSelect extends Component<DropdownProps, DropdownState> {
  public static defaultProps = {
    hideControl: false,
    forceUpdate: false,
    isCreatable: false,
    isDisabled: false,
    isReadOnly: false,
    isAsync: false,
    errorLabel: ''
  }

  static getDerivedStateFromProps(props: DropdownProps, state: DropdownState) {
    if (
      !state.prevPropsOption ||
      (props.value && props.value.value !== state.prevPropsOption.value)
    ) {
      return {
        prevPropsOption: props.value,
        option: props.value
      }
    }
    return null
  }

  constructor(props: DropdownProps) {
    super(props)

    this.state = {
      isOpen: false,
      option: props.value ? props.value : { label: '' },
      prevPropsOption: null
    }

    this.onInputChange = debounce(this.onInputChange.bind(this), 400)
  }

  componentDidMount(): void {
    const { forceUpdate, value } = this.props
    if (forceUpdate && value) {
      this.onSelectChange(value)
    }
  }

  componentDidUpdate(
    prevProps: Readonly<DropdownProps>,
    prevState: Readonly<DropdownState>
  ): void {
    const { forceUpdate, value } = this.props

    if (forceUpdate && prevProps.value.value !== this.props.value.value) {
      this.onSelectChange(value)
    }
  }

  // @ts-ignore
  filterOption = ({ label, value, data }, query: string): boolean =>
    this.props.isAsync ||
    !query ||
    (value !== 'all' &&
      !data.isDisabled &&
      label.toLowerCase().includes(query.toLowerCase()))

  toggleOpen = () => this.setState(state => ({ isOpen: !state.isOpen }))
  closeOpen = () => this.setState({ isOpen: false })

  onSelectChange = (option: Option) => {
    const { onSelect, name } = this.props
    this.closeOpen()

    this.setState({ option }, () => {
      if (onSelect) {
        // @ts-ignore
        onSelect(name, option)
      }
    })
  }

  // @ts-ignore
  onInputChange = (query: string, { action }) => {
    const { onInputChange: onChange } = this.props
    // Prevents resetting our input after option has been selected
    if (action !== 'set-value') {
      if (onChange) {
        onChange(query)
      }
      // this.setState({inputValue: query});
    }
  }

  render() {
    const {
      placeholder,
      options,
      label,
      name,
      id,
      hideControl,
      isCreatable,
      isDisabled,
      isReadOnly,
      errorLabel,
      icon,
      wrapperClassName = '',
      buttonClassName,
      valid = true,
      customStyles = {} as CustomStyles
    } = this.props
    const { isOpen, option } = this.state

    const styles = selectStyles({ hideControl, customStyles })

    return (
      <Wrapper
        isSelected={isOpen}
        data-testid={`${name}-dropdown`}
        className={wrapperClassName}
      >
        <LabelWrap>
          {!!label && (
            <div>
              <Label htmlFor={id}>{label}</Label>
            </div>
          )}
        </LabelWrap>
        <IconWrapper>{icon}</IconWrapper>
        <Dropdown
          isOpen={isOpen}
          isDisabled={isDisabled}
          isReadOnly={isReadOnly}
          onClose={this.closeOpen}
          target={
            // @ts-ignore
            <MainButton
              type="button"
              onClick={this.toggleOpen}
              isSelected={isOpen}
              isDisabled={isDisabled}
              isErrored={!!errorLabel}
              icon={icon}
              className={buttonClassName}
            >
              {option && option.label ? (
                <SelectedOption>{option.label}</SelectedOption>
              ) : (
                <Placeholder>{placeholder}</Placeholder>
              )}
              <ArrowBlock>
                {valid && <ChevronDownIcon width={'20'} height={'11'} />}
              </ArrowBlock>
            </MainButton>
          }
        >
          <DropdownSelectComponent
            isCreatable={isCreatable}
            autoFocus={true}
            components={{
              DropdownIndicator,
              IndicatorSeparator: null
            }}
            isClearable={false}
            menuIsOpen={true}
            openMenuOnClick={false}
            openOnClick={false}
            openAfterFocus={false}
            openOnFocus={false}
            closeMenuOnSelect={true}
            onSelectResetsInput={true}
            controlShouldRenderValue={false}
            backspaceRemovesValue={false}
            // @ts-ignore
            onChange={this.onSelectChange}
            onInputChange={this.onInputChange}
            options={options}
            placeholder={' '}
            styles={styles}
            tabSelectsValue={false}
            value={option}
            filterOption={this.filterOption}
          />
        </Dropdown>
        {(!valid || errorLabel) && (
          <AlertWrapper
            data-tip={errorLabel}
            data-for={`error-tooltip__${name}`}
            style={{ cursor: 'pointer' }}
          >
            <AlertIcon />
          </AlertWrapper>
        )}
        <ReactTooltip
          type={'error'}
          effect={'solid'}
          clickable={true}
          id={`error-tooltip__${name}`}
          backgroundColor={'#FF3A44'}
        />
      </Wrapper>
    )
  }
}

export default DropdownSelect
