import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export const TextWrapper = styled.div`
  padding: 1.875rem 1rem 5.875rem;
  text-align: center;
`

export const Text = styled.p`
  margin: 0 auto 2rem;
  color: #8c8c8c;
  font-size: 1.25rem;
  letter-spacing: 0.8px;
  line-height: 2.5rem;
`

export const IconWrapper = styled.div`
  position: relative;
`

export const ContentWrapper = styled.div`
  padding-top: 2.75rem;

  ${media.md`
  padding-top: 4rem;
  `}
`
