import React, { FC, useState } from 'react'
import {
  ReportHarassment,
  ReportHarassmentLabels,
  ReportReason,
  ReportReasonLabel,
  ReportReasonType,
  ReportViolence,
  ReportViolenceLabels
} from 'redux/events/comments/types'
import EnumHelpers from 'services/EnumHelpers'
import Button from 'src/components/UI/Button'
import Dropdown from 'src/components/UI/Dropdown'

import {
  ButtonWrapper,
  ContentWrapper,
  ReportTypesWrapper,
  Title
} from './styles'
import Modal from 'src/components/UI/Modal'
import { theme } from 'styled/theme'
import OutsideClick from 'components/UI/OutsideClick'

interface Props {
  report: (
    reason: ReportReason,
    reasonType?: ReportReasonType,
    reasonTypeDetails?: ReportViolence | ReportHarassment
  ) => void
  hide: (isVisible: boolean) => void
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

const ReportDropdown: FC<Props> = props => {
  const { isOpen, setIsOpen } = props

  const [isSent, setIsSent] = useState(false)
  const [reason, setReason] = useState(0)
  const [reasonType, setReasonType] = useState(0)
  const [reasonTypeDetails, setReasonTypeDetails] = useState(0)

  const reportMessage = reason === ReportReason.SPAM ? 'Report Spam' : ''
  const hasSubtypes = [
    ReportReasonType.VIOLENCE,
    ReportReasonType.HARASSMENT
  ].includes(reasonType)

  const reportSpam = () => setReason(ReportReason.SPAM)

  const reportInap = () => setReason(ReportReason.INAPPROPRIATE)

  const closeModal = () => {
    setIsOpen(false)
    setIsSent(false)
    setReason(0)
    setReasonType(0)
    setReasonTypeDetails(0)
    props.hide(false)
  }

  const sendReport = () => {
    setIsSent(true)
    props.report(reason, reasonType, reasonTypeDetails)
  }

  const selectReportType = (
    name: string,
    option: { label: string; value: number }
  ) => setReasonType(option.value)

  const selectReportTypeDetails = (
    name: string,
    option: { label: string; value: number }
  ) => setReasonTypeDetails(option.value)

  const renderButtons = (hasSubtypes?: boolean) => {
    if (isSent) {
      return (
        <ButtonWrapper>
          <Button onClick={closeModal} variant={'default'}>
            Continue
          </Button>
        </ButtonWrapper>
      )
    }

    return !isSent && reason !== 0 ? (
      <ButtonWrapper>
        <Button
          onClick={sendReport}
          variant={'default'}
          disabled={
            reason === ReportReason.INAPPROPRIATE &&
            (hasSubtypes ? !reasonTypeDetails : !reasonType)
          }
        >
          Submit
        </Button>
      </ButtonWrapper>
    ) : (
      <></>
    )
  }

  const renderDropdowns = (hasSubtypes?: boolean) => {
    const reportReasonList = EnumHelpers.toKeyValueArray(ReportReasonType)
    const violenceReasonList = EnumHelpers.toKeyValueArray(ReportViolence)
    const harassmentReasonList = EnumHelpers.toKeyValueArray(ReportHarassment)

    const reportReasonOptions = reportReasonList.map(({ value }) => ({
      value,
      label: ReportReasonLabel[value]
    }))

    const violenceTypesOptions = violenceReasonList.map(({ value }) => ({
      value,
      label: ReportViolenceLabels[value]
    }))

    const harassmentTypesOptions = harassmentReasonList.map(({ value }) => ({
      value,
      label: ReportHarassmentLabels[value]
    }))

    return (
      <>
        {reason === ReportReason.INAPPROPRIATE && (
          <Dropdown
            options={reportReasonOptions}
            name={'report'}
            placeholder={'Type of violation'}
            onSelect={selectReportType}
            value={reportReasonOptions[reasonType - 1]}
            hideControl={true}
          />
        )}
        {hasSubtypes && (
          <Dropdown
            options={
              reasonType === ReportReasonType.VIOLENCE
                ? violenceTypesOptions
                : harassmentTypesOptions
            }
            name={'report'}
            placeholder={'Type of violation'}
            onSelect={selectReportTypeDetails}
            value={
              reasonType === ReportReasonType.VIOLENCE
                ? violenceTypesOptions[reasonTypeDetails - 1]
                : harassmentTypesOptions[reasonTypeDetails - 1]
            }
            hideControl={true}
          />
        )}
      </>
    )
  }

  return (
    <>
      {reason !== 0 && (
        <Modal
          style={{ backgroundColor: `${theme.colors.white}` }}
          onClose={closeModal}
          isOpen={isOpen}
          header={
            <Title>
              {reason === ReportReason.INAPPROPRIATE ? 'Report' : 'Report Spam'}
            </Title>
          }
          footer={renderButtons(hasSubtypes)}
          bodyOverflow={true}
          closeButtonColor={'#eee'}
        >
          {' '}
          <ContentWrapper>
            <h2>{isSent ? 'Thanks for letting us know' : reportMessage}</h2>

            {!isSent && renderDropdowns(hasSubtypes)}
          </ContentWrapper>
        </Modal>
      )}
      {isOpen && reason === 0 && (
        <OutsideClick onOutsideClick={closeModal}>
          <ReportTypesWrapper>
            <Button variant={'text'} onClick={reportSpam}>
              It's spam
            </Button>
            <Button variant={'text'} onClick={reportInap}>
              It's inappropriate
            </Button>
          </ReportTypesWrapper>
        </OutsideClick>
      )}
    </>
  )
}

export default ReportDropdown
