import React from 'react'

const CogIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <path
      fill="#ecc576"
      d="M19.202 8.046c.44.042.777.4.798.84v2.164c0 .442-.315.82-.756.862l-1.492.168c-.42.042-.756.315-.924.714-.19.42-.126.861.126 1.177l.987 1.24a.867.867 0 01-.063 1.154L16.345 17.9a.869.869 0 01-1.156.063l-1.155-.945a1.138 1.138 0 00-1.156-.147c-.42.168-.693.525-.735.924l-.168 1.492a.88.88 0 01-.862.756H8.93c-.442 0-.82-.315-.862-.756L7.9 17.794c-.042-.42-.315-.756-.714-.924-.42-.19-.861-.126-1.177.126l-1.155.945a.868.868 0 01-1.156-.063l-1.533-1.534A.869.869 0 012.1 15.19l.945-1.155a1.14 1.14 0 00.147-1.156c-.168-.42-.525-.693-.924-.735l-1.492-.168c-.44-.063-.777-.42-.777-.862V8.93c0-.442.315-.82.756-.862L2.248 7.9c.42-.042.756-.315.924-.714.19-.42.126-.861-.126-1.177l-.945-1.155a.868.868 0 01.063-1.156l1.533-1.533A.869.869 0 014.853 2.1l1.155.924a1.14 1.14 0 001.156.147c.42-.168.693-.525.735-.924L8.046.756A.88.88 0 018.908 0h2.163c.442 0 .82.315.862.756l.168 1.492c.042.378.315.735.714.903.42.19.862.126 1.177-.126l1.155-.945a.867.867 0 011.156.063l1.533 1.533a.87.87 0 01.063 1.156l-.945 1.155a1.138 1.138 0 00-.147 1.156c.168.42.525.693.924.735zm-.042.883l-1.534-.21A1.966 1.966 0 0116.05 7.5c-.294-.693-.189-1.45.252-1.996l.946-1.155-1.534-1.597-1.218.924c-.546.442-1.303.526-1.975.253-.693-.295-1.156-.904-1.24-1.597L11.113.84 8.887.798l-.21 1.513a1.966 1.966 0 01-1.219 1.576c-.693.294-1.45.189-1.996-.253L4.307 2.69 2.71 4.223l.945 1.218c.442.546.547 1.303.274 1.975-.295.693-.904 1.155-1.597 1.24L.84 8.823.798 11.05l1.534.21a1.966 1.966 0 011.576 1.219c.294.693.189 1.45-.253 1.996L2.71 15.63l1.534 1.597 1.218-.945a1.89 1.89 0 011.198-.42c.273 0 .525.041.777.146.693.295 1.155.904 1.24 1.597l.168 1.492 2.226.042.21-1.534a1.966 1.966 0 011.219-1.576c.693-.294 1.45-.189 1.996.253l1.155.966 1.597-1.534-.946-1.218a1.963 1.963 0 01-.273-1.975c.294-.693.904-1.155 1.597-1.24l1.534-.21z"
    />
    <path
      fill="#ecc576"
      d="M13.739 9.98a3.748 3.748 0 01-3.74 3.739 3.748 3.748 0 01-3.739-3.74A3.748 3.748 0 0110 6.24a3.748 3.748 0 013.739 3.74zm-.82 0A2.927 2.927 0 0010 7.06a2.927 2.927 0 00-2.92 2.92A2.927 2.927 0 0010 12.9a2.927 2.927 0 002.92-2.92z"
    />
  </svg>
)

export default CogIcon
