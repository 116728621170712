import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export const SearchWrapper = styled.div`
  position: relative;
  padding-top: 1rem;
  margin: 20px auto;

  div {
    max-width: 521px;
    margin: 0 auto;
  }

  ${media.lg`
    padding-top: 0;
    margin: 51px auto;
  `}
`

export const TextWrapper = styled.div`
  width: 100%;
  min-height: 12rem;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-size: 1.25rem;
    color: ${p => p.theme.colors.black};
  }
`

export const InfluencersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 830px;
  margin: 0 auto;

  ${media.md`
    flex-flow: row wrap;
    justify-content: space-between;
  `}
`
