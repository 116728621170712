import React from 'react'

const ChevronDownIcon = props => (
  <svg width="16" height="16" viewBox="0 0 14 8" fill="none" {...props}>
    <path
      d="M1.66602 1.6665L6.99935 6.99984L12.3327 1.6665"
      stroke="#D4AB72"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ChevronDownIcon
