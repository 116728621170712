import React, { ReactNode, Fragment } from 'react'
import Media from 'react-media'
import { generatePath, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectIsAuth } from 'redux/auth/selectors'
import { DataRoomLabels, IEventDetail } from 'redux/events/types'
import { Statuses } from 'redux/commonTypes'
import { routePath } from 'routes'
import { ButtonVariant } from 'src/components/UI/Button'
import DaysLeft from '../DaysLeft'
import ProgressBar from 'src/components/UI/ProgressBar'
import { formatMoney, formatPrice } from 'utils/dateFormats'
import SocialButtons from 'src/components/UI/SocialButtons'
import WebsiteIcon from 'components/icons/events/WebsiteIcon'
import CircularIcon from 'components/icons/events/CircularIcon'
import DownloadIcon from 'components/icons/events/DownloadIcon'
import {
  Amount,
  AmountRow,
  AmountStart,
  AmountStrong,
  AmountTitle,
  AmountTotal,
  AmountWrapper,
  CardBottom,
  CardInfo,
  DataRoomWrapper,
  DaysLeftContainer,
  Description,
  FileLink,
  InfoWrapper,
  InvestNowButton,
  InvestNowLink,
  InvestNowWrapper,
  LinkName,
  Name,
  OfferingWrapper,
  SocialButtonsWrapper,
  SocialWrapper,
  Title,
  TitleWrapper,
  Wrapper
} from '../styles'

interface Props {
  event: IEventDetail
  showHours?: boolean
  isPlaying: boolean
  children: ReactNode
  onClick: () => void
}

const InvestHeaderContent: React.FC<Props> = ({
  event,
  showHours,
  isPlaying,
  children,
  onClick
}): React.ReactElement => {
  const {
    data_room,
    event_description,
    event_end_in_hours,
    event_influencer,
    event_end_at,
    event_title,
    event_id,
    investmentTerms,
    status
  } = event
  const {
    amount_raised,
    total_offering,
    min_investment,
    investors
  } = investmentTerms
  const { pathname } = useLocation()
  const isAuthenticated = useSelector(selectIsAuth)
  const isEnded = status === Statuses.ENDED
  const isProfile = pathname.split('/').includes('profile')
  const dataHeader = Object.values(DataRoomLabels)
  const hideContent = isPlaying ? 'none' : 'flex'
  const isLongTitle = event_title.length > 35
  const dataRoom = data_room.map(d => {
    const name = d.name.toLowerCase()
    if (!dataHeader.includes(name)) {
      return null
    }
    const renderButton = () => {
      switch (name) {
        case DataRoomLabels.INVESTOR:
          return (
            <FileLink
              variant={ButtonVariant.Clear}
              download={true}
              target="_blank"
              to={d.value}
            >
              <DownloadIcon fill={'#EB78FF'} />
              <Media query="(min-width: 767px)">
                {matches =>
                  matches ? (
                    <LinkName>{d.name}</LinkName>
                  ) : (
                    <LinkName>{d.name.split(' ')[1]}</LinkName>
                  )
                }
              </Media>
            </FileLink>
          )
        case DataRoomLabels.WEBSITE:
          return (
            <FileLink
              variant={ButtonVariant.Clear}
              target="_blank"
              to={d.value}
            >
              <WebsiteIcon fill={'#EB78FF'} />
              <LinkName>{d.name}</LinkName>
            </FileLink>
          )
        case DataRoomLabels.OFFERS:
          return (
            <FileLink
              variant={ButtonVariant.Clear}
              target="_blank"
              to={d.value}
            >
              <CircularIcon fill={'#EB78FF'} />
              <Media query="(min-width: 767px)">
                <Media query="(min-width: 767px)">
                  {matches =>
                    matches ? (
                      <LinkName>{d.name}</LinkName>
                    ) : (
                      <LinkName>{d.name.split(' ')[1]}</LinkName>
                    )
                  }
                </Media>
              </Media>
            </FileLink>
          )
      }
    }
    return <Fragment key={d.id}>{renderButton()}</Fragment>
  })
  return (
    <Wrapper style={{ display: hideContent }}>
      <DaysLeftContainer>
        <DaysLeft
          endDate={event_end_at}
          eventStatus={status}
          hoursLeft={event_end_in_hours}
          showHours={showHours}
        />
      </DaysLeftContainer>
      <InfoWrapper>
        <CardInfo>
          <TitleWrapper>
            <Name>{event_influencer[0].influencer_name}</Name>
            <Title isLongTitle={isLongTitle}>{event_title}</Title>
            <Description
              dangerouslySetInnerHTML={{
                __html: event_description
              }}
            />
          </TitleWrapper>
          {children}
        </CardInfo>
        <CardBottom>
          <OfferingWrapper>
            <DataRoomWrapper>{dataRoom}</DataRoomWrapper>
            {amount_raised > 0 ? (
              <>
                <ProgressBar
                  total={total_offering}
                  raised={amount_raised}
                  isEndedEvent={isEnded}
                />
                <AmountRow>
                  <div>
                    <AmountWrapper>
                      <AmountStrong>{formatPrice(amount_raised)}</AmountStrong>
                      <AmountTitle>amount raised</AmountTitle>
                    </AmountWrapper>
                    <AmountWrapper>
                      <Amount>
                        {formatMoney((amount_raised / total_offering) * 100)}%
                      </Amount>
                      <AmountTitle>funded</AmountTitle>
                    </AmountWrapper>
                    {investors > 0 && (
                      <AmountWrapper hideControl={true}>
                        <Amount>{investors}</Amount>
                        <AmountTitle>investors</AmountTitle>
                      </AmountWrapper>
                    )}
                  </div>
                  <AmountWrapper>
                    <AmountTotal>{formatPrice(total_offering)}</AmountTotal>
                    <AmountTitle> total offering</AmountTitle>
                  </AmountWrapper>
                </AmountRow>
              </>
            ) : (
              <AmountRow style={{ justifyContent: 'flex-start' }}>
                <AmountWrapper>
                  <AmountTotal>{formatPrice(total_offering)}</AmountTotal>
                  <AmountTitle> total offering</AmountTitle>
                </AmountWrapper>
              </AmountRow>
            )}
          </OfferingWrapper>

          <SocialWrapper>
            <InvestNowWrapper>
              <AmountWrapper>
                {!isEnded && (
                  <>
                    <AmountStart>
                      starting <br /> from {formatPrice(min_investment)}
                    </AmountStart>

                    {isAuthenticated ? (
                      <InvestNowButton onClick={onClick}>
                        {isProfile ? 'Invest More' : 'Invest Now'}
                      </InvestNowButton>
                    ) : (
                      <InvestNowLink
                        to={{
                          pathname: routePath.SIGN_IN,
                          state: {
                            from: generatePath(routePath.INVESTMENT, {
                              id: event_id
                            })
                          }
                        }}
                      >
                        Invest Now
                      </InvestNowLink>
                    )}
                  </>
                )}
              </AmountWrapper>
            </InvestNowWrapper>
            <SocialButtonsWrapper>
              <SocialButtons path={routePath.INVESTMENT} event={event} />
            </SocialButtonsWrapper>
          </SocialWrapper>
        </CardBottom>
      </InfoWrapper>
    </Wrapper>
  )
}

export default InvestHeaderContent
