export const geocodeByPlaceId = (placeId: string) => {
  const geoCoder = new window.google.maps.Geocoder()
  const { OK } = window.google.maps.GeocoderStatus

  return new Promise((resolve, reject) => {
    geoCoder.geocode({ placeId }, (results, status) => {
      if (status !== OK) {
        return reject(status)
      }
      return resolve(results)
    })
  })
}

export const extractFieldFromAddress = (place: any, addressType: string) => {
  // tslint:disable-next-line:prefer-for-of
  for (let i = 0; i < place.address_components.length; i++) {
    if (place.address_components[i].types.includes(addressType)) {
      return place.address_components[i].long_name || ''
    }
  }
}

export const extractLabelAndCodeFromAddress = (
  place: any,
  addressType: string
) => {
  // tslint:disable-next-line:prefer-for-of
  for (let i = 0; i < place.address_components.length; i++) {
    if (place.address_components[i].types.includes(addressType)) {
      return {
        label: place.address_components[i].long_name || '',
        code: place.address_components[i].short_name || ''
      }
    }
  }
}

export const geocodeByAddress = (address: any) => {
  const geocoder = new window.google.maps.Geocoder()
  const { OK } = window.google.maps.GeocoderStatus

  return new Promise((resolve, reject) => {
    geocoder.geocode({ address }, (results, status) => {
      if (status !== OK) {
        return reject(status)
      }
      return resolve(results)
    })
  })
}

export const getLatLng = (result: any) =>
  new Promise((resolve, reject) => {
    try {
      const latLng = {
        lat: result.geometry.location.lat(),
        lng: result.geometry.location.lng()
      }
      return resolve(latLng)
    } catch (e) {
      return reject(e)
    }
  })
