import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export const ButtonWrapper = styled.div`
  max-width: 330px !important;
  margin: 0 auto;

  button {
    width: 100%;
    margin-bottom: 30px !important;
    ${media.md`
     margin-bottom: 50px !important;
  `}
  }
`
export const CheckBoxWrapper = styled.div`
  width: 100%;
  > div {
    margin-bottom: 20px;
    min-height: 50px;

    ${media.md`
     min-height: 40px;
  `}
  }

  input:not(:checked) + span,
  input:checked + span {
    padding-left: 25px;
  }

  span {
    font-family: Inter;
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 1.125rem;

    width: 295px;

    ${media.sm`
      width: 330px;
  `}
  }
`
export const FieldsWrapper = styled.div`
  > div {
    max-width: 330px;
    margin: 0 auto;
  }
  .form {
    &__last-field {
      margin-bottom: 15px;
    }
  }
`

export const Label = styled.label<{
  empty: string
}>`
  font-family: Poppins;
  font-size: 1rem;
  color: #000;
  opacity: ${p => (p.empty ? 0.6 : 0)};
  transition: opacity 0.2s;
`
