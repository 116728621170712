import styled from 'styled-components'
import { media, Col } from 'styled-bootstrap-grid'
import { UploadButton } from './VerificationForm/styles'
import Link from 'src/components/UI/Link'

export const Title = styled.h2<{
  hideControl?: boolean
}>`
  display: ${p => (p.hideControl ? 'none' : 'inline-block')};
  font-family: Inter;
  font-size: 1.375rem;
  font-weight: 900;
  line-height: 1.75rem;
  ${media.md`
    display: inline-block;
  `}
`

export const TextWrapper = styled.div`
  margin-bottom: 0.75rem;
`

export const TitleWrapper = styled(TextWrapper)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: calc(100vw - 30px);
  height: fit-content;
  margin-bottom: 1rem;

  ${media.md`
    width: unset;
    margin-bottom: 2.875rem;
  `}
`

export const DropDownWrapper = styled.div`
  width: 100%;
`

export const ControlsWrapper = styled.div`
  margin-top: auto;
  button {
    width: 100%;
  }

  ${media.md`
    display: none;
  `}
`

export const DotsWrapper = styled.div`
  padding: 1.75rem 0 1.25rem;
  display: flex;
  justify-content: center;
`

export const Dot = styled.div<{ active?: boolean }>`
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: ${p => p.theme.colors.white};
  opacity: ${p => (p.active ? 1 : 0.2)};

  :not(:last-child) {
    margin-right: 0.375rem;
  }
`

export const Navigation = styled(TitleWrapper)`
  ${media.md`
    display: block;
  `}
`

export const ErrorWrapper = styled.div`
  padding-top: 1.875rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.md`
    padding-top: 0;
  `}
`

export const BackButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`

export const PaymentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
`

export const Step = styled.div`
  display: none;
  width: 2.75rem;
  height: 2.75rem;
  position: absolute;
  top: 5px;
  right: calc(100% + 28px);
  font-family: Inter;
  font-weight: 400;
  font-size: 1.5rem;
  color: ${p => p.theme.colors.white};
  background-color: ${p => p.theme.colors.lightBlue};
  border-radius: 50%;

  ${media.md`
  display: flex;
  align-items: center;
  justify-content: center;
  `}
`

export const UploadWrapper = styled.div`
  width: 100%;
  background-color: ${p => p.theme.colors.lightBlue};
  border-radius: 0.75rem;
  padding: 0.625rem;
  ${media.md`
    margin-bottom: 2.875rem;
    padding: 0.625rem 0.875rem;
  `}

  ${UploadButton} {
    min-width: 168px;
  }
`

export const FileTitle = styled.p`
  font-family: Inter, sans-serif;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.625rem;
  margin-bottom: 0.625rem;
`

export const FileName = styled(FileTitle)`
  margin-bottom: 0;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.125rem;
`

export const FileWrapper = styled.div`
  padding: 0.5rem;
  border: 0.125rem solid ${p => p.theme.colors.white};
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
`

export const FileCol = styled(Col)`
  margin-bottom: 1rem;

  :last-child {
    margin-bottom: 2rem;
  }
`

export const LimitMessage = styled.div`
  font-size: 1.125rem;
  font-family: Inter;
  color: ${p => p.theme.colors.black};
  margin-bottom: 2rem;
  span {
    font-size: 1.125rem;
    color: ${p => p.theme.colors.black};
  }
`

export const ModalLink = styled(Link)`
  display: block;
  width: 150px;
  margin: 0 auto 17px;
  ${media.md`
    margin-bottom: 25px;
  `}
`
