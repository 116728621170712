import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { selectAutocompleteAddresses } from 'redux/checkout/selectors'
import { AddressFields } from 'redux/investment/types'
import { INewAddress } from 'redux/profile/types'
import {
  fetchAddress,
  updateUserAddress,
  fetchUserAddress
} from 'redux/profile/operations'
import {
  selectFormattedAddress,
  selectProfile,
  selectProfileAddressErrors
} from 'redux/profile/selectors'
import { changeAddress, clearAddressErrors } from 'redux/profile/actions'
import { extractFieldFromAddress, geocodeByPlaceId } from 'utils/googleHelpers'

import { Title } from 'components/UI/Tabs/styles'
import Dropdown from 'src/components/UI/Dropdown'
import TextInput from 'src/components/UI/TextInput'
import Button from 'src/components/UI/Button'

import { ContentWrapper, Form } from './styles'

const AddressForm: FC = () => {
  const [form, setForm] = useState<INewAddress>({
    country: '',
    state: '',
    city: '',
    street: '',
    zip: '',
    place_id: '',
    room: ''
  })
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const dispatch = useDispatch()
  const isFetchingAddress = useSelector(selectProfile)
  const currentAddress = useSelector(selectFormattedAddress)
  const suggestAddresses = useSelector(selectAutocompleteAddresses)
  const addressErrors = useSelector(selectProfileAddressErrors)

  useEffect(() => {
    dispatch(fetchUserAddress())

    return () => {
      dispatch(clearAddressErrors())
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    checkChanges()
    // eslint-disable-next-line
  }, [form])

  useEffect(() => {
    if (!isFetchingAddress.isFetchingAddress) {
      setForm({
        ...form,
        ...currentAddress
      })
    }
    // eslint-disable-next-line
  }, [currentAddress])

  const handleChange = async (name: string, value: any) => {
    let newFormValues = { [name]: value }

    if (name === 'street') {
      // @ts-ignore
      const [place] = await geocodeByPlaceId(value.value)
      let cityName = extractFieldFromAddress(place, 'locality')
      if (!cityName) {
        cityName = extractFieldFromAddress(place, 'sublocality_level_1')
      }

      const country = extractFieldFromAddress(place, 'country')
      const state = extractFieldFromAddress(
        place,
        'administrative_area_level_1'
      )
      const zip = extractFieldFromAddress(place, 'postal_code') || ''

      newFormValues = {
        ...newFormValues,
        city: cityName,
        street: value.label,
        place_id: value.value,
        country,
        state,
        zip
      }
    }

    setForm({
      ...form,
      ...newFormValues
    })

    Object.keys(newFormValues).map(key => {
      // @ts-ignore
      return dispatch(changeAddress(key, newFormValues[key]))
    })

    checkChanges()
  }

  const onChangeAddress = (value: any) => {
    if (value) {
      dispatch(
        fetchAddress({
          input: value,
          types: ['address']
        })
      )
    }
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    try {
      await dispatch(
        updateUserAddress({
          ...form,
          country: form.country || '',
          state: form.state || ''
        })
      )
      setIsDisabled(true)
    } catch (e) {}
  }

  const checkChanges = (): void => {
    const userDataList = Object.keys(form)

    const isSame = userDataList.every((item: string) => {
      // @ts-ignore
      return currentAddress[item] === form[item]
    })
    setIsDisabled(isSame)
  }

  const { country, state, street, zip, place_id, room, city } = form

  return (
    <Form onSubmit={handleSubmit}>
      <Title>Address</Title>
      <ContentWrapper>
        <Dropdown
          label={'Address 1'}
          name={'street'}
          placeholder={'185 Berry Street'}
          value={{
            label: street,
            value: place_id
          }}
          options={suggestAddresses}
          onSelect={handleChange}
          onInputChange={onChangeAddress}
          errorLabel={
            addressErrors.place_id ||
            addressErrors.street ||
            addressErrors.city ||
            ''
          }
          isAsync={true}
          wrapperClassName={'address-form__street profile-address__first-field'}
          forceUpdate={true}
          buttonClassName={'address-form__select'}
          customStyles={{
            control: { background: '#fff' },
            input: { background: '#fff' },
            firstOption: { borderRadius: '0' },
            option: { backgroundColor: '#fff' }
          }}
        />
        <TextInput
          label={'Address 2 (opt.)'}
          placeholder={' '}
          name={'room'}
          value={room}
          handleChange={handleChange}
          errorLabel={addressErrors.room || ''}
          wrapperClassName={'profile-address__second-field'}
        />
        <TextInput
          label={AddressFields.city}
          placeholder={' '}
          name={'city'}
          value={city}
          disabled={true}
          wrapperClassName={'profile-address__third-field'}
        />
        <TextInput
          label={AddressFields.state}
          placeholder={' '}
          name={'state'}
          value={state}
          disabled={true}
          wrapperClassName={'profile-address__fourth-field'}
        />
        <TextInput
          name={'country'}
          value={country}
          label={AddressFields.country}
          readOnly={true}
          wrapperClassName={'profile-address__fifth-field'}
        />
        <TextInput
          label={AddressFields.postal_code}
          placeholder={' '}
          name={'zip'}
          value={zip}
          handleChange={handleChange}
          errorLabel={addressErrors.zip || ''}
          disabled={true}
          wrapperClassName={'profile-address__sixth-field'}
        />
      </ContentWrapper>
      <Button
        variant={'default'}
        type={'submit'}
        disabled={isFetchingAddress.isFetchingAddress || isDisabled}
      >
        Update
      </Button>
    </Form>
  )
}

export default AddressForm
