import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export const SectionTitle = styled.h3`
  font-family: Inter;
  font-size: 0.9375rem;
  font-weight: 900;
  color: ${p => p.theme.colors.black};
  margin-bottom: 0.5rem;
  line-height: 1.125rem;

  ${media.md`
    font-size: 1.5rem;
    margin-bottom: 0.75rem;
    line-height: 1.8125rem;
  `};
`

export const IncomingHTMLLayout = styled.div`
  width: 100%;

  ${media.md`
    width: 65%;
  `}
`

export const ContentTitle = styled.div`
  font-family: Inter !important;
  font-size: 0.75rem !important;
  font-weight: 900;
  margin-bottom: 0.5rem;

  ${media.md`
    margin-bottom: 0.75rem;
    font-weight: 600;
  `}
`

export const ContentDescription = styled.div`
  p,
  span,
  strong {
    font-family: Inter !important;
    font-size: 0.625rem !important;
    line-height: 0.9375rem;
    color: ${p => p.theme.colors.black} !important;
  }

  font-weight: 300;
  text-transform: uppercase;

  ${media.md`
    p, 
    span,
    strong {
      font-size: 0.75rem !important;
      line-height: 1.1875rem;
    }
  `}
`
