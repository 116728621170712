import styled, { css } from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export const Link = styled.a<{ active: boolean }>`
  color: ${p => p.theme.colors.white} !important;
  background-color: ${p => (p.active ? p.theme.colors.blue : 'transparent')};
  border-radius: 25px;
  transition: all 0.3s;
`

export const SiteMap = styled.div<{ isStickySitemap: boolean }>`
  z-index: 998;
  background-color: ${p => p.theme.colors.navy};
  width: 100%;

  ${p =>
    p.isStickySitemap
      ? css`
          position: fixed;
          top: 74px;

          > div:first-child {
            max-width: 100%;
          }
        `
      : css`
          position: static;
        `};
`

export const Wrapper = styled.div`
  max-width: 1500px;
  width: 100%;
  margin: 0 auto;
  padding: 15px;
  cursor: grab;

  :active {
    cursor: grabbing;
  }

  ${media.md`
    padding: 15px 0;
  `}

  ${Link} {
    text-transform: uppercase;
    color: ${p => p.theme.colors.white};
    font-family: Inter;
    font-size: 0.875rem;
    font-weight: 300;
    letter-spacing: 2px;
    text-decoration: none;
    transition: all 0.3s;
    padding: 10px 20px;
    :hover {
      background-color: ${p => p.theme.colors.blue};
      border-radius: 25px;
      transition: all 0.3s;
    }
  }

  .slick-arrow {
    display: none;
  }

  .slick-slide {
    padding: 10px;
  }
`
