import { generatePath } from 'react-router-dom'
import { ProfileSectionTypes } from 'redux/profile/types'
import { routePath } from 'routes'

const tabs = () => ({
  [ProfileSectionTypes.INVESTMENTS]: {
    title: 'Investments',
    url: generatePath(routePath.PROFILE_SECTION, {
      type: ProfileSectionTypes.INVESTMENTS
    })
  },
  [ProfileSectionTypes.EXPERIENCES]: {
    title: 'Purchases',
    url: generatePath(routePath.PROFILE_SECTION, {
      type: ProfileSectionTypes.EXPERIENCES
    })
  }
})

export default tabs
