import { ProfileSettingTypes } from 'redux/profile/types'

const START_PAGE = '/investments'
const INDEX = '/'
const AUTH = '/auth'
const SIGN_IN_BETABLOCK = '/login/betablock'
const SIGN_UP_BETABLOCK = '/signup/betablock'
const SIGN_IN = '/auth/sign-in'
const SIGN_IN_GUEST = '/auth/guest'
const SIGN_UP = '/auth/sign-up'
const FORGOT = '/auth/forgot'
const FORGOT_BETABLOCK = '/forgot/betablock'
const EVENTS_PAGE = '/experiences'
const EVENT = '/experiences/:id'
const CHECKOUT = '/experiences/:id/checkout/:tier'
const CHECKOUT_CART = '/checkout/cart'
const CHECKOUT_SUCCESS = '/checkout/success'
const PAYMENT = '/payment'
const CHECKOUT_FINISH = PAYMENT + '/finish'
const CHECKOUT_CANCEL = PAYMENT + '/cancel'
const TERMS = '/' + ProfileSettingTypes.TERMS
const PRIVACY = '/' + ProfileSettingTypes.PRIVACY
const PROFILE = '/profile'
const PROFILE_SECTION = '/profile/:type'
const PROFILE_NOTIFICATIONS = '/profile/notifications'
const PROFILE_SETTINGS = '/profile/settings'
const PROFILE_SETTINGS_OPTION = '/profile/settings/:type'
const PROFILE_SETTINGS_SUBOPTION = '/profile/settings/:type/:id'
const PROFILE_SETTINGS_FAQ = '/profile/settings/help/faq'
const PROFILE_SETTINGS_FAQ_TYPE = '/profile/settings/:type/:id/:faq'
const INFLUENCER = '/creators'
const INFLUENCER_PAGE = INFLUENCER + '/:id'
const INVEST_PAGE = '/investments'
const INVESTMENT = '/investments/:id'
const INVEST_NOW = '/invest-now'
const INVEST_TIER_PAGE = PROFILE + INVESTMENT + '/item/:item_id'
const COMMENT_DETAILS = '/comment/details'
const NFT_PAGE = '/nft'
const NFT_DETAIL_PAGE = '/nft/:id'

export default {
  START_PAGE,
  INDEX,
  AUTH,
  SIGN_IN,
  SIGN_IN_GUEST,
  SIGN_IN_BETABLOCK,
  SIGN_UP,
  SIGN_UP_BETABLOCK,
  FORGOT,
  EVENTS_PAGE,
  EVENT,
  CHECKOUT,
  CHECKOUT_CART,
  CHECKOUT_SUCCESS,
  CHECKOUT_FINISH,
  CHECKOUT_CANCEL,
  FORGOT_BETABLOCK,
  NFT_PAGE,
  NFT_DETAIL_PAGE,
  TERMS,
  PAYMENT,
  PRIVACY,
  PROFILE,
  PROFILE_SECTION,
  PROFILE_NOTIFICATIONS,
  PROFILE_SETTINGS,
  PROFILE_SETTINGS_OPTION,
  PROFILE_SETTINGS_SUBOPTION,
  PROFILE_SETTINGS_FAQ,
  PROFILE_SETTINGS_FAQ_TYPE,
  INFLUENCER,
  INFLUENCER_PAGE,
  INVEST_PAGE,
  INVESTMENT,
  INVEST_TIER_PAGE,
  INVEST_NOW,
  COMMENT_DETAILS
}
