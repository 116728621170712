import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setErrors,
  setInvestmentStep,
  setUSBankInfo
} from 'redux/investment/actions'
import {
  AccountType,
  IPaymentLimit,
  IUSBank,
  USBankFields
} from 'redux/investment/types'
import {
  selectCurrentStep,
  selectInvestmentErrors,
  selectUSBankInfo
} from 'redux/investment/selectors'
import { getUSBankErrors } from 'redux/investment/operations'
import { showModal } from 'redux/auth/actions'
import {
  convertValueToPrice,
  noFirstSpaceRegex,
  noSpaceRegex,
  noTwoSpacesRegex,
  onlyNumbersRegex,
  validNameRegex
} from 'utils/helpers'
import TextInput from 'src/components/UI/TextInput'
import { Controls } from 'src/components/investment/steps'
import Dropdown from 'src/components/UI/Dropdown'
import { useInvestNowContext } from 'src/pages/investment/InvestNowPage'
import Checkbox, { CheckboxVariant } from 'components/UI/Checkbox'
import { ButtonVariant } from 'src/components/UI/Button'

import { Form, PaymentWrapper } from 'src/components/investment/steps/styles'
import { AchButton, PaymentTextWrapper } from './styles'
import { PaymentAgreementModal } from '../PaymentAgreementModal'

const USBank: FC = () => {
  const dispatch = useDispatch()
  const currentStep = useSelector(selectCurrentStep)
  const USBank = useSelector(selectUSBankInfo)
  const errors = useSelector(selectInvestmentErrors)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [displayedAccountNumber, setDisplayedAccountNumber] = useState<string>(
    ''
  )
  const {
    setSelectedPaymentState: setNewUSBankInfo,
    selectedPaymentState,
    amount,
    isACHConfirmed,
    setIsACHConfirmed,
    selectedContactForm
  } = useInvestNowContext()
  const { first_name, last_name } = selectedContactForm
  const newUSBankInfo = selectedPaymentState as IUSBank
  const accountTypes = [
    { label: AccountType.CHECKING, value: AccountType.CHECKING },
    { label: AccountType.SAVINGS, value: AccountType.SAVINGS }
  ]

  const selectedAccountType = accountTypes.find(
    type => type.value === newUSBankInfo.account_type
  )

  const maskAccountNumber = (routingNumber: string) => {
    const regex = /\d(?=\d{4})/gm
    const subst = '*'

    return routingNumber.replace(regex, subst)
  }

  useEffect(() => {
    if (amount > IPaymentLimit.US_BANK) {
      dispatch(
        showModal(
          `Sorry, the limit on ACH transfers is ${convertValueToPrice(
            String(IPaymentLimit.US_BANK)
          )}.`
        )
      )
    }
  }, [amount, dispatch])

  useEffect(() => {
    let initialData = { ...USBank }

    if (!initialData.name_on_account) {
      initialData = {
        ...USBank,
        name_on_account: `${first_name} ${last_name}`
      }
      dispatch(
        setUSBankInfo({
          ...USBank,
          name_on_account: `${first_name} ${last_name}`
        })
      )
    }
    setNewUSBankInfo(initialData)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    return () => {
      dispatch(setErrors({}))
    }
  }, [dispatch])

  useEffect(() => {
    const currentNumber = maskAccountNumber(USBank.account_number)
    setDisplayedAccountNumber(currentNumber)
    // eslint-disable-next-line
  }, [])

  const openACHModal = () => setIsOpen(true)
  const closeACHModal = () => setIsOpen(false)

  const onCardNumberChange = (name: string, value: string) => {
    if (isNaN(Number(value)) || value.includes('-')) {
      return
    }
    onChange(name, value.replace(noSpaceRegex, ''))
  }

  const onChangeNumberField = (name: string, value: string) => {
    if (isNaN(Number(value))) {
      return
    }
    const currentValue = value
      .replace(noFirstSpaceRegex, '')
      .replace(onlyNumbersRegex, '')
      .replace(/\s+/g, '')

    setDisplayedAccountNumber(currentValue)

    onChange(name, currentValue)
  }

  const onChange = (name: string, value: string) => {
    let currentValue = value

    if (name === 'name_on_account') {
      currentValue = value
        .replace(noFirstSpaceRegex, '')
        .replace(validNameRegex, '')
        .replace(noTwoSpacesRegex, ' ')
    }

    const newData = {
      ...newUSBankInfo,
      [name]: currentValue
    }

    setNewUSBankInfo(newData)
    dispatch(setUSBankInfo(newData))
    if (errors[name]) {
      dispatch(setErrors({ ...errors, [name]: '' }))
    }
  }

  const onClick = () => {
    const errors = getUSBankErrors(newUSBankInfo)
    dispatch(setErrors(errors))
    const isValid = !Object.keys(errors).length
    if (isValid) {
      dispatch(setUSBankInfo(newUSBankInfo))
      dispatch(setInvestmentStep(currentStep + 1))
    }
  }

  const selectAccountType = (name: string, value: any) => {
    onChange(name, value.value)
  }

  const confirmDisclaimer = () => {
    setIsACHConfirmed(!isACHConfirmed)
  }

  const onBlur = () => {
    const currentValue = maskAccountNumber(USBank.account_number)
    setDisplayedAccountNumber(currentValue)
  }

  const onFocus = () => {
    setDisplayedAccountNumber(USBank.account_number)
  }

  return (
    <>
      <PaymentAgreementModal isOpen={isOpen} onClose={closeACHModal} />
      <PaymentWrapper>
        <div>
          <Form>
            <TextInput
              name={'routing_number'}
              value={newUSBankInfo.routing_number}
              label={USBankFields.routing_number}
              handleChange={onCardNumberChange}
              maxLength={9}
              errorLabel={errors.routing_number}
              wrapperClassName={'credit-card__second-field'}
              wrapperStyle={{
                borderTopLeftRadius: 12,
                borderRight: '1px solid rgba(0, 0, 0, 0.15)'
              }}
            />
            <Dropdown
              label={USBankFields.account_type}
              name={'account_type'}
              options={accountTypes}
              hideControl={true}
              value={selectedAccountType}
              onSelect={selectAccountType}
              wrapperClassName={
                'us-bank__account-type credit-card__third-field'
              }
              buttonClassName={'address-form__select'}
              customStyles={{
                firstOption: {
                  borderRadius: '0.75rem 0.75rem 0 0'
                },
                option: { backgroundColor: '#fff' },
                menu: { textTransform: 'capitalize' }
              }}
              errorLabel={errors.account_type}
            />
            <TextInput
              name={'account_number'}
              value={displayedAccountNumber}
              label={USBankFields.account_number}
              handleChange={onChangeNumberField}
              errorLabel={errors.account_number}
              onBlur={onBlur}
              onFocus={onFocus}
              maxLength={17}
            />
            <TextInput
              name={'name_on_account'}
              value={newUSBankInfo.name_on_account}
              label={USBankFields.name_on_account}
              handleChange={onChange}
              maxLength={128}
              errorLabel={errors.name_on_account}
              wrapperClassName={'form__last-field'}
            />
          </Form>
          <PaymentTextWrapper>
            <div style={{ marginRight: 16, paddingTop: 6 }}>
              <Checkbox
                name={'ACH'}
                variant={CheckboxVariant.Blue}
                checked={isACHConfirmed}
                handleChange={confirmDisclaimer}
              />
            </div>
            <p>
              I hereby authorize FanVestor's escrow agent, North Capital Private
              Securities Corporation to electronically debit my bank account. I
              acknowledge that I have read and agree to the full{' '}
              <AchButton variant={ButtonVariant.Text} onClick={openACHModal}>
                Electronic Funds Transfers Policy
              </AchButton>
              .
            </p>
          </PaymentTextWrapper>
        </div>
        <Controls
          onClick={onClick}
          disabled={amount > IPaymentLimit.US_BANK || !isACHConfirmed}
        />
      </PaymentWrapper>
    </>
  )
}

export default USBank
