import React from 'react'

const ChevronDownIcon = props => (
  <svg
    width="21"
    height="12"
    viewBox="0 0 21 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.2"
      d="M10.3672 9.45357L1.6402 0.962456C1.34333 0.673602 0.868497 0.680106 0.579643 0.976984C0.290789 1.27386 0.297294 1.74869 0.594171 2.03754L9.84417 11.0375C10.1353 11.3208 10.5991 11.3208 10.8902 11.0375L20.1402 2.03754C20.4371 1.74869 20.4436 1.27386 20.1547 0.976983C19.8659 0.680105 19.391 0.673601 19.0942 0.962455L10.3672 9.45357Z"
      fill="#212121"
      {...props}
    />
  </svg>
)

export default ChevronDownIcon
