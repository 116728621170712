import React, { FC } from 'react'
import { formatDateWithTime, formatMoney } from 'utils/dateFormats'
import { ITierDetails } from 'redux/events/types'

import GenericModal from 'src/components/UI/GenericModal'
import CloseIcon from 'src/components/icons/CloseIcon'
import SetList from './SetList'

import {
  Block,
  ButtonWrapper,
  CloseButton,
  Date,
  Details,
  InfoDetails,
  InfoPrice,
  InfoTitle,
  SetsWrapper,
  TierTitle,
  Wrapper,
  WrapperCol,
  WrapperTitle
} from 'components/profile/dashboard/styles'

interface Props {
  tier: ITierDetails
  isOpen: boolean
  onClose: () => void
}

const Index: FC<Props> = ({ tier, isOpen, onClose }) => {
  const {
    id,
    date,
    user_name,
    payment_system,
    payment_status,
    items,
    amount_without_taxes,
    shipping_fee,
    tax_amount,
    amount,
    shipping_address: { street, room, city, zip, state, country }
  } = tier

  return (
    <GenericModal
      isOpen={isOpen}
      overlayStyle={{ background: 'rgba(0,0,0,0.8)' }}
      style={{ maxWidth: '700px', background: 'none' }}
    >
      <Details>
        <WrapperTitle>
          <TierTitle>Order #{id}</TierTitle>
          <Date>Placed on {formatDateWithTime(date)}</Date>
        </WrapperTitle>
        <Wrapper style={{ alignItems: 'flex-start' }}>
          <WrapperCol>
            <InfoTitle>Shipping Address</InfoTitle>
            <InfoDetails>{user_name}</InfoDetails>
            <InfoDetails>
              {street}
              {room && ', ' + room}
            </InfoDetails>
            <InfoDetails>
              {city}, {state}, {zip}
            </InfoDetails>
            <InfoDetails>{country}</InfoDetails>
          </WrapperCol>
          <WrapperCol>
            <InfoTitle>Order Summary</InfoTitle>
            <Block>
              <InfoDetails>Subtotal</InfoDetails>
              <InfoPrice>${formatMoney(amount_without_taxes, 2)}</InfoPrice>
            </Block>
            <Block>
              <InfoDetails>Shipping</InfoDetails>
              <InfoPrice>${formatMoney(shipping_fee, 2)}</InfoPrice>
            </Block>
            <Block>
              <InfoDetails>Taxes</InfoDetails>
              <InfoPrice>${formatMoney(tax_amount, 2)}</InfoPrice>
            </Block>
            <Block>
              <InfoDetails style={{ fontWeight: 700 }}>Total</InfoDetails>
              <InfoPrice style={{ fontWeight: 700 }}>
                ${formatMoney(amount, 2)}
              </InfoPrice>
            </Block>
          </WrapperCol>
        </Wrapper>
        <Wrapper>
          <WrapperCol>
            <InfoTitle>Payment Method</InfoTitle>
            <InfoDetails>{payment_system}</InfoDetails>
          </WrapperCol>
          <WrapperCol>
            <InfoTitle>Order Status</InfoTitle>
            <InfoDetails>{payment_status}</InfoDetails>
          </WrapperCol>
        </Wrapper>

        <InfoTitle style={{ marginTop: '10px' }}>Order Details</InfoTitle>
        <SetsWrapper>
          {items.map((item, i) => {
            return <SetList tier={item} key={`${i}/${item.id}`} />
          })}
        </SetsWrapper>
        <ButtonWrapper>
          <CloseButton onClick={onClose}>
            <CloseIcon /> Close{' '}
          </CloseButton>
        </ButtonWrapper>
      </Details>
    </GenericModal>
  )
}

export default Index
