import React, { Component } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Container } from 'styled-bootstrap-grid'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { selectSupportEvents } from 'redux/profile/selectors'
import { fetchPurchased } from 'redux/events/operations'
import { loadInfluencer } from 'redux/influencer/operations'
import { fetchAnalytics, sendMessage } from 'redux/profile/operations'
import {
  selectInfluencerErrors,
  selectUserInfo
} from 'redux/influencer/selectors'
import { selectUserId } from 'redux/auth/selectors'
import { routePath } from 'routes'

import BrandHeader from 'components/content/BrandHeader'
import { NewTabs as Tabs } from 'components/UI/Tabs'
import InfluencerPage from 'containers/influencer/InfluencerDetailPage'
import EditProfileForm from 'components/influencer/EditProfileForm'
import { MessagingForm } from 'components/influencer/messaging'
import AnalyticsBlock from 'components/influencer/AnalyticsBlock'
import PopUpMessage from 'components/common/PopUpMessage'
import Link from 'components/UI/Link'
import tabs from './tabs'

import { WrapperBlock } from './styles'

import { AppState, Dispatch } from 'store/types'
import { BrandProfileSectionTypes } from 'redux/profile/types'

const FanView = (route: any) => (
  <InfluencerPage {...route} isInfluencerProfile={true} />
)

class BrandProfilePage extends Component<Props> {
  componentDidMount = (): void => {
    this.props.loadInfluencer(this.props.userId.toString(), true)
    this.props.fetchAnalytics()
  }

  messagingForm = () => {
    const { sendMessage, supportEvents } = this.props

    return (
      <MessagingForm supportEvents={supportEvents} onSubmit={sendMessage} />
    )
  }

  render() {
    const { user } = this.props

    if (!Object.keys(user).length) {
      return null
    }

    return (
      <div className={'wrapper'}>
        <div className={'content'}>
          <PopUpMessage />
          <BrandHeader photo={user.profile_photo} />
          <Container>
            <WrapperBlock>
              <Tabs tabs={tabs}>
                {Object.keys(tabs).map(category => (
                  // @ts-ignore
                  <Link key={category} to={tabs[category].url} />
                ))}
              </Tabs>

              <Switch>
                <Route
                  exact={true}
                  path={tabs[BrandProfileSectionTypes.FAN_VIEW].url}
                  render={FanView}
                />
                <Route
                  exact={true}
                  path={tabs[BrandProfileSectionTypes.EDIT_PROFILE].url}
                  component={EditProfileForm}
                />
                <Route
                  exact={true}
                  path={tabs[BrandProfileSectionTypes.ANALYTICS].url}
                  component={AnalyticsBlock}
                />
                <Route
                  exact={true}
                  path={tabs[BrandProfileSectionTypes.MESSAGING].url}
                  render={this.messagingForm}
                />
                <Redirect
                  from={routePath.PROFILE}
                  to={tabs[BrandProfileSectionTypes.FAN_VIEW].url}
                />
              </Switch>
            </WrapperBlock>
          </Container>
        </div>
      </div>
    )
  }
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
export type Props = StateProps & DispatchProps

const mapStateToProps = (state: AppState) => ({
  user: selectUserInfo(state),
  userId: selectUserId(state),
  supportEvents: selectSupportEvents(state),
  error: selectInfluencerErrors(state)
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      fetchPurchased,
      loadInfluencer,
      sendMessage,
      fetchAnalytics
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BrandProfilePage)
