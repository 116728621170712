import styled, { css } from 'styled-components'
import Button from 'components/UI/Button'
import { TierTitle } from 'components/profile/dashboard/styles'

export const Notification = styled.div`
  display: flex;
  margin-bottom: 30px;
  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
`

export const ShowMoreButton = styled(Button).attrs(() => ({
  variant: 'clear'
}))`
  color: ${p => p.theme.colors.gold};
`
export const SenderName = styled.h3<{ isNew: boolean }>`
  font-weight: 700;
  font-size: 1rem;
  ${p =>
    p.isNew &&
    css`
      position: relative;

      :before {
        content: '';
        width: 0.5rem;
        height: 0.5rem;
        background: ${p => p.theme.colors.fuchsia};
        position: absolute;
        left: -1rem;
        top: 30%;
        border-radius: 50%;
      }
    `}
`

export const Subject = styled(TierTitle)`
  color: ${p => p.theme.colors.white};
  font-weight: 500;
`

export const Text = styled.p`
  color: ${p => p.theme.colors.white};
  font-size: 1rem;
  margin-bottom: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const DateAndDeleteWrapper = styled.div`
  display: flex;
  align-items: center;
  opacity: 0.8;

  svg {
    pointer-events: none;
    margin-right: 5px;
  }
  span {
    margin: 0 10px;
    color: ${p => p.theme.colors.wetWhite};
  }
  button {
    font-size: 0.75rem;
    font-weight: 400;
    color: ${p => p.theme.colors.wetWhite};
  }
`

export const Date = styled.p`
  color: ${p => p.theme.colors.wetWhite};
  font-size: 0.75rem;
  text-transform: capitalize;
  opacity: 0.8;
  display: flex;
  align-items: center;

  svg {
    width: 14px;
    height: 14px;
  }
`
