import styled, { css } from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { ButtonProps } from './index'

export const ClearButton = styled.button<ButtonProps>`
  cursor: pointer;
  text-decoration: none;
  background: none;
  outline: 0;
  border: 0;
  color: ${p => p.theme.colors.black};
  font-weight: 500;
  font-size: 14px;
  width: ${p => (p.fullwidth ? '100%' : 'auto')};
  transition: 0.1s opacity linear;
`

export const DefaultButton = styled(ClearButton)`
  cursor: pointer;
  display: block;
  font-size: 0.875rem;
  font-weight: bold;
  border-radius: 0.4rem;
  border: 0;
  outline: none;
  background: ${p => p.theme.colors.gold};
  padding: 1.375rem 2.375rem;
  transition: 0.1s opacity linear;
  text-align: center;

  &:hover {
    opacity: 0.8;
  }

  ${props =>
    props.disabled &&
    css`
      cursor: auto;
      background: ${p => p.theme.button.default.disabled};
      color: #423e3e;

      :hover {
        background: ${p => p.theme.button.default.disabled};
        box-shadow: unset;
        color: #423e3e;
      }
    `}
`

export const SecondaryButton = styled(DefaultButton)`
  padding: 1.5rem 2rem;
  background: ${p => p.theme.colors.darkGrey};
  color: ${p => p.theme.colors.white};

  &:hover {
    opacity: 0.8;
  }

  &:active {
    background: ${p => p.theme.colors.black};
  }

  ${props =>
    props.disabled &&
    css`
      background: ${p => p.theme.button.default.disabled};

      &:hover {
        opacity: 1;
        color: ${p => p.theme.colors.white};
      }
    `}
`

export const TransparentButton = styled(DefaultButton)`
  color: #999696;
  background: #191919;
  border: 2px solid #999696;
  padding: 0.75rem 2.5rem;

  &:hover {
    background: #2d2d2d;
  }

  ${props =>
    props.disabled &&
    css`
      background: #191919;

      &:hover {
        background: #191919;
      }
    `}
`

export const TextButton = styled(DefaultButton)`
  color: ${p => p.theme.colors.gold};
  font-size: 1rem;
  font-weight: 600;
  background: transparent;
  border: none;
  box-shadow: none;
  padding-left: 0;
  padding-right: 0;

  &:hover {
    color: ${p => p.theme.button.default.hover};
    background: transparent;
  }

  ${props =>
    props.disabled &&
    css`
      background-color: transparent;

      &:hover {
        background-color: transparent;
      }
    `}
`

export const TextDangerButton = styled(TextButton)`
  color: #d83742;

  &:hover {
    color: #d83742;
  }
`

export const PlayVideoButton = styled(ClearButton)`
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.36);
  transition: all 0.2s ease-in;

  :before {
    content: '';
    border-left: 15px solid #000;
    border-top: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid transparent;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-25%, -50%);
  }

  :hover:before {
    border-left: 15px solid ${p => p.theme.button.default.hover};
  }

  :active {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.36);

    :before {
      border-left: 15px solid ${p => p.theme.button.default.active};
    }
  }
`

export const IconButton = styled(ClearButton)`
  color: #d83742;
  display: flex;
  &:hover {
    color: #d83742;
  }
`

export const ShopBlackOutlinedButton = styled(ClearButton)`
  max-width: ${p => (p.fullwidth ? undefined : '225px')};
  width: 100%;
  font-size: 1.1rem;
  font-family: 'Acumin Pro';
  letter-spacing: 0.2px;
  line-height: 1.5rem;
  padding: 1.125rem 0;
  background-color: ${p => p.theme.colors.fadedBlack};
  color: ${p => p.theme.colors.darkenGold};
  border: 1px solid ${p => p.theme.colors.darkenGold};
  transition: 0.1s linear;

  &:hover,
  &:active {
    background-color: ${p => p.theme.colors.darkenGold};
    color: ${p => p.theme.colors.fadedBlack};
  }

  ${media.lg`
    font-size: 1.25rem;
  `}

  ${props =>
    props.disabled &&
    css`
      cursor: auto;
      opacity: 0.8;

      :hover {
        cursor: auto;
        opacity: 0.8;
      }
    `}
`

export const ShopBlackContainedButton = styled(ClearButton)`
  font-size: 1.25rem;
  font-weight: 400;
  border: 1px solid #b19062;
  outline: none;
  color: ${p => p.theme.colors.gold};
  font-family: 'Poppins';
  letter-spacing: 0.2px;
  line-height: 1.5rem;
  padding: 1.125rem 0;
  background-color: ${p => p.theme.colors.fadedBlack};
  transition: 0.1s linear;

  &:hover,
  &:active {
    box-shadow: 4px 5px 15px rgba(0, 0, 0, 0.2);
  }
  &:disabled {
    background-color: #725d40;
    &:hover {
      box-shadow: unset;
      cursor: auto;
    }
  }
`

export const ShopGoldContainedButton = styled(ClearButton)`
  max-width: ${p => (p.fullwidth ? undefined : '225px')};
  width: 100%;
  font-size: 1.1rem;
  font-family: 'Acumin Pro';
  letter-spacing: 0.2px;
  line-height: 1.5rem;
  padding: 1.125rem 0;
  background-color: ${p => p.theme.colors.darkenGold};
  color: ${p => p.theme.colors.fadedBlack};
  border: 1px solid ${p => p.theme.colors.darkenGold};
  transition: 0.1s linear;

  &:hover {
    box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.3);
  }

  a {
    font-size: 1.25rem;
    font-family: 'Acumin Pro';
    letter-spacing: 0.2px;
    line-height: 1.5rem;
  }

  ${media.lg`
    font-size: 1.25rem;
  `}

  ${props =>
    props.disabled &&
    css`
      cursor: auto;
      opacity: 0.8;

      :hover {
        cursor: auto;
        opacity: 0.8;
      }
    `}
`
