import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import Button from 'components/UI/Button'
import Img from 'components/UI/Img'

export const FlexDiv = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const ZoomContainer = styled(FlexDiv)`
  height: 100%;
  position: relative;

  ${media.md`
    max-width: 90%;
  `}
`

export const CancelButton = styled(Button)`
  position: absolute;
  top: 0.625rem;
  right: 0.625rem;

  ${media.md`
    top: 2.5rem;
  `}

  ${media.xl`
    right: 2.5rem;
  `}
`

export const ZoomImageWrapper = styled(FlexDiv)`
  max-width: 90%;
  height: auto;
  max-height: 80%;
  overflow: hidden;
  cursor: url(https://ssl.gstatic.com/ui/v1/icons/mail/images/2/openhand.cur),
    default;

  &:active {
    cursor: grabbing;
  }

  ${media.md`
    max-width: 80%;
  `}
`

export const ZoomImage = styled(Img)`
  max-height: 65vh;
  width: auto;
  max-width: 100%;
  transition: all 500ms ease-in-out;
  object-fit: contain;

  ${media.xs`
    max-height: 45vh;
  `}
`

export const ZoomButtonWrapper = styled.div`
  padding-top: 2.875rem;
  display: flex;
`

export const ZoomButton = styled(Button).attrs(() => ({
  variant: 'shop-black-outlined'
}))`
  width: 4.5rem;
  padding: 0.375rem;
  background: transparent;
  border-radius: 0.875rem;
  font-size: 1rem;

  &:hover {
    background: rgba(177, 144, 98, 0.3);
    color: ${p => p.theme.colors.gold};
  }

  &:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`
