import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactPixel from 'react-facebook-pixel'
import { useLocation } from 'react-router-dom'
import { Col, Row } from 'styled-bootstrap-grid'

import { PayPalButton } from 'react-paypal-button-v2'

import { CheckoutApi } from 'api/checkout.api'
import AnalyticsManager from 'services/analyticService'
import { setLastActivity } from 'redux/checkout/actions'
import {
  checkoutDonate as checkout,
  confirmCheckout,
  isValidAddressInfo,
  shoppingCartInitialization
} from 'redux/checkout/operations'
import { selectEventsDetail } from 'redux/events/selectors'
import {
  isValidProductSpecificSelector,
  selectBuyNowGuestCheckout,
  selectBuyNowTier,
  selectBuyNowTierQuantity,
  selectCustomFields,
  selectShipping
} from 'redux/checkout/selectors'
import { selectIsAuth, selectUser } from 'redux/auth/selectors'
import { ProfileSectionTypes } from 'redux/profile/types'
import { history, routePath } from 'routes'
import { getQueryParam, useDevice } from 'utils/helpers'

import { CustomErrorLabel, PaypalButtonWrap } from './styles'

import {
  CheckoutMethod,
  Currency,
  IConfirmPayPal,
  ICustomField,
  Intent,
  PaymentSystem
} from 'redux/checkout/types'
import { EventSubType } from 'redux/events/types'
import { CurrencyLabels } from 'redux/commonTypes'

const payPalClientId = process.env.REACT_APP_PAYPAL_CLIENT_ID

interface PayPalCheckoutProps {
  isShopping: boolean
}
const PayPalCheckout: React.FC<PayPalCheckoutProps> = ({
  isShopping
}): React.ReactElement | null => {
  const dispatch = useDispatch()
  const location = useLocation()
  const [errors, setErrors] = useState('')
  const userDevice = useDevice()

  const event = useSelector(selectEventsDetail)
  const activeTier = useSelector(selectBuyNowTier)
  const activeTierQuantity = useSelector(selectBuyNowTierQuantity)
  const isAuthenticated = useSelector(selectIsAuth)
  const isValidProductSpecific = useSelector(isValidProductSpecificSelector)
  const guestCheckout = useSelector(selectBuyNowGuestCheckout)
  const shipping = useSelector(selectShipping)
  const customFields = useSelector(selectCustomFields)
  const user = useSelector(selectUser)

  useEffect(() => {
    setErrors('')
  }, [location])

  const onApprove = async (data: any) => {
    const { orderID, payerID, paymentID } = data

    try {
      await confirmCheckout<IConfirmPayPal>({
        PayerID: payerID,
        paymentId: paymentID,
        token: orderID,
        payment_system: PaymentSystem.PAY_PAL,
        intent: Intent.SALE
      })

      if (activeTier) {
        AnalyticsManager.trackTierCheckoutSuccess(
          activeTier.id,
          event.event_id,
          PaymentSystem.PAY_PAL
        )

        ReactPixel.track('Purchase', {
          eventId: event.event_id,
          paymentSystem: PaymentSystem.PAY_PAL,
          tierId: activeTier.id,
          currency: CurrencyLabels[Currency.USD],
          value: activeTier.value
        })

        history.push({
          pathname: routePath.CHECKOUT_SUCCESS,
          state: {
            eventId: event.event_id,
            checkoutMethod: isShopping
              ? CheckoutMethod.SHOPPING
              : CheckoutMethod.BUY_NOW
          }
        })

        dispatch(setLastActivity(ProfileSectionTypes.EXPERIENCES))
      }
    } catch (e) {
      setErrors(e.message)
    }
  }

  const onError = () => {
    if (isShopping) {
      dispatch(shoppingCartInitialization())
    }
  }

  const createOrder = async (data: any) => {
    const isValidAddress = await dispatch(isValidAddressInfo())
    const userName = shipping.recipient_name
      ? shipping.recipient_name
      : user.first_name + ' ' + user.last_name

    if (!isValidAddress && isValidProductSpecific) {
      return
    }

    let deliveryInfo = {}

    if (activeTier.required_shipping_address || isShopping) {
      deliveryInfo = {
        city: shipping.city || '',
        country: shipping.country || '',
        state: shipping.state || '',
        street: shipping.streetName || shipping.street.label || '',
        place_id: shipping.street.value || '',
        room: shipping.room || '',
        use_as_default: shipping.use_as_default,
        recipient_name: userName,
        zip: shipping.zip
      }
    }
    if (activeTier.custom_fields) {
      deliveryInfo = {
        ...deliveryInfo,
        custom_fields: Object.values(customFields).reduce(
          (acc: ICustomField[], item: ICustomField[]) => [
            ...Object.values(acc),
            ...Object.values(item)
          ],
          [] as ICustomField[]
        )
      }
    }

    try {
      if (isShopping) {
        // buy from cart order
        const { data } = await CheckoutApi.checkoutShoppingCart({
          ...deliveryInfo,
          payment_system: PaymentSystem.PAY_PAL,
          token: null
        })
        return getQueryParam(data.approval_link, 'token')
      } else if (activeTier) {
        // buy from BuyNow
        if (event.sub_type !== EventSubType.SWEEPSTAKES) {
          let isAuthDelivery

          if (isAuthenticated) {
            // @ts-ignore
            const { tierSetId } = history.location.state
            const [activeSet] = activeTier.sets.filter(
              s => s.tier_set_id === tierSetId
            )
            isAuthDelivery = { tier_set_id: activeSet.tier_set_id }
          } else {
            isAuthDelivery = {
              tier_set_id: guestCheckout.tierSetId,
              email: shipping.email,
              device_id: userDevice.browserId
            }
          }

          const { data } = await CheckoutApi.checkoutBuyNow({
            payment_system: PaymentSystem.PAY_PAL,
            token: null,
            quantity: activeTierQuantity,
            ...isAuthDelivery,
            ...deliveryInfo
          })
          return getQueryParam(data.approval_link, 'token')
        } else {
          AnalyticsManager.trackTierCheckoutIntent(
            activeTier.id,
            event.event_id,
            PaymentSystem.PAY_PAL
          )

          // buy from old way
          const approvalLink = await checkout({
            payment_system: PaymentSystem.PAY_PAL,
            intent: Intent.SALE,
            currency: Currency.USD,
            amount: activeTier.value,
            quantity: activeTierQuantity,
            event_id: event.event_id,
            event_title: event.event_title,
            tier_id: activeTier.id,
            tier_title: activeTier.title,
            tier_picture: activeTier.picture,
            token: null,
            payerID: data.payerID,
            ...deliveryInfo
          })

          return getQueryParam(approvalLink, 'token')
        }
      }
    } catch (e) {
      setErrors(e.response.data.message || e.response.data.reason)
    }

    return null
  }

  if ((!Object.keys(event).length || !activeTier) && !isShopping) {
    return null
  }

  // @ts-ignore
  return (
    <Row>
      <Col sm={12} md={12} lg={6}>
        <PaypalButtonWrap isDisabled={isValidProductSpecific}>
          {errors && <CustomErrorLabel label={String(errors)} />}
          {/*
 // @ts-ignore*/}
          <PayPalButton
            amount={activeTier.value}
            onApprove={onApprove}
            onError={onError}
            onCancel={onError}
            createOrder={createOrder}
            options={{
              clientId: String(payPalClientId),
              disableFunding: 'card,credit,sepa,bancontact'
              // disableCard: 'card,amex,jcb' // button don't appear with this option
            }}
            style={{
              height: 55,
              width: 160,
              layout: 'horizontal',
              color: 'gold',
              shape: 'rect',
              tagline: false
            }}
          />
        </PaypalButtonWrap>
      </Col>
    </Row>
  )
}

export default PayPalCheckout
