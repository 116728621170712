import { action, createAsyncAction, createCustomAction } from 'typesafe-actions'
import {
  UserActionTypes,
  IProfileFollowing,
  IBalance,
  IProfileNotifications,
  INewAddress,
  ICurrentAddress,
  IUserEventDetails,
  IProfile
} from './types'
import { IErrors, IStatus } from '../commonTypes'
import { AllUserInvestments, UserInvestment } from './types'

export const profileExportAsync = createAsyncAction(
  UserActionTypes.PROFILE_EXPORT_REQUEST,
  UserActionTypes.PROFILE_EXPORT_SUCCESS,
  UserActionTypes.PROFILE_EXPORT_FAIL
)<void, IStatus, Error>()

export const sendMessageAsync = createAsyncAction(
  UserActionTypes.SEND_MESSAGE_REQUEST,
  UserActionTypes.SEND_MESSAGE_SUCCESS,
  UserActionTypes.SEND_MESSAGE_FAIL
)<void, IStatus, Error>()

export const profileSettingsUpdateAsync = createAsyncAction(
  UserActionTypes.PROFILE_SETTINGS_UPDATE_REQUEST,
  UserActionTypes.PROFILE_SETTINGS_UPDATE_SUCCESS,
  UserActionTypes.PROFILE_SETTINGS_UPDATE_FAIL
)<void, IStatus, Error>()

export const profilePhotoUpdateAsync = createAsyncAction(
  UserActionTypes.PROFILE_PHOTO_UPDATE_REQUEST,
  UserActionTypes.PROFILE_PHOTO_UPDATE_SUCCESS,
  UserActionTypes.PROFILE_PHOTO_UPDATE_FAIL
)<void, IStatus, { message: string }>()

export const profilePhotoDeleteAsync = createAsyncAction(
  UserActionTypes.PROFILE_PHOTO_DELETE_REQUEST,
  UserActionTypes.PROFILE_PHOTO_DELETE_SUCCESS,
  UserActionTypes.PROFILE_PHOTO_DELETE_FAIL
)<void, IStatus, Error>()

export const profileFollowingAsync = createAsyncAction(
  UserActionTypes.PROFILE_FOLLOWING_REQUEST,
  UserActionTypes.PROFILE_FOLLOWING_SUCCESS,
  UserActionTypes.PROFILE_FOLLOWING_FAIL
)<void, IProfileFollowing, Error>()

export const profileBalanceAsync = createAsyncAction(
  UserActionTypes.PROFILE_BALANCE_REQUEST,
  UserActionTypes.PROFILE_BALANCE_SUCCESS,
  UserActionTypes.PROFILE_BALANCE_FAIL
)<void, IBalance, Error>()

export const profileNotificationsAsync = createAsyncAction(
  UserActionTypes.PROFILE_NOTIFICATIONS_REQUEST,
  UserActionTypes.PROFILE_NOTIFICATIONS_SUCCESS,
  UserActionTypes.PROFILE_NOTIFICATIONS_FAIL
)<void, IProfileNotifications, Error>()

export const profileNotificationsReadAsync = createAsyncAction(
  UserActionTypes.PROFILE_NOTIFICATIONS_READ_REQUEST,
  UserActionTypes.PROFILE_NOTIFICATIONS_READ_SUCCESS,
  UserActionTypes.PROFILE_NOTIFICATIONS_READ_FAIL
)<void, { ids: number[] }, Error>()

export const deleteNotificationAsync = createAsyncAction(
  UserActionTypes.DELETE_NOTIFICATION_REQUEST,
  UserActionTypes.DELETE_NOTIFICATION_SUCCESS,
  UserActionTypes.DELETE_NOTIFICATION_FAIL
)<void, { id: number }, Error>()

export const profileFetchAboutApplicationAsync = createAsyncAction(
  UserActionTypes.ABOUT_APPLICATION_REQUEST,
  UserActionTypes.ABOUT_APPLICATION_SUCCESS,
  UserActionTypes.ABOUT_APPLICATION_FAIL
)<void, { info: string }, Error>()

export const fetchUserAddressAsync = createAsyncAction(
  UserActionTypes.FETCH_USER_ADDRESS_REQUEST,
  UserActionTypes.FETCH_USER_ADDRESS_SUCCESS,
  UserActionTypes.FETCH_USER_ADDRESS_FAIL
)<void, ICurrentAddress, Error>()

export const updateUserAddressAsync = createAsyncAction(
  UserActionTypes.UPDATE_USER_ADDRESS_REQUEST,
  UserActionTypes.UPDATE_USER_ADDRESS_SUCCESS,
  UserActionTypes.UPDATE_USER_ADDRESS_FAIL
)<void, INewAddress, Error>()

export const sendReportAsync = createAsyncAction(
  UserActionTypes.SEND_REPORT_REQUEST,
  UserActionTypes.SEND_REPORT_SUCCESS,
  UserActionTypes.SEND_REPORT_FAIL
)<void, IStatus, Error>()

export const changeAddress = createCustomAction(
  UserActionTypes.CHANGE_ADDRESS,
  (name, value) => ({
    payload: { name, value }
  })
)

export const setAddressErrors = createCustomAction(
  UserActionTypes.SET_ADDRESS_ERRORS,
  (fields: IErrors<INewAddress>) => ({
    payload: { fields }
  })
)

export const setProfileDetailsErrors = createCustomAction(
  UserActionTypes.SET_PROFILE_DETAILS_ERRORS,
  (fields: IErrors<IProfile>) => ({
    payload: { fields }
  })
)

export const fetchAnalyticsAsync = createAsyncAction(
  UserActionTypes.FETCH_ANALYTICS_REQUEST,
  UserActionTypes.FETCH_ANALYTICS_SUCCESS,
  UserActionTypes.FETCH_ANALYTICS_FAIL
)<void, { events: IUserEventDetails[] }, Error>()

export const fetchInvestmentsAsync = createAsyncAction(
  UserActionTypes.FETCH_INVESTMENTS_REQUEST,
  UserActionTypes.FETCH_INVESTMENTS_SUCCESS,
  UserActionTypes.FETCH_INVESTMENTS_FAIL
)<void, AllUserInvestments, Error>()

export const fetchInvestmentDetailsAsync = createAsyncAction(
  UserActionTypes.FETCH_INVESTMENT_DETAILS_REQUEST,
  UserActionTypes.FETCH_INVESTMENT_DETAILS_SUCCESS,
  UserActionTypes.FETCH_INVESTMENT_DETAILS_FAIL
)<void, { data: UserInvestment }, Error>()

export const cancelInvestmentAsync = createAsyncAction(
  UserActionTypes.CANCEL_INVESTMENT_REQUEST,
  UserActionTypes.CANCEL_INVESTMENT_SUCCESS,
  UserActionTypes.CANCEL_INVESTMENT_FAIL
)<void, IStatus & { id: number }, Error>()

export const clearProfileErrors = () => action(UserActionTypes.CLEAR_ERRORS)

export const clearFollowing = () => action(UserActionTypes.CLEAR_FOLLOWING)

export const clearAddressErrors = () =>
  action(UserActionTypes.CLEAR_ADDRESS_ERRORS)

export const clearNotifications = () =>
  action(UserActionTypes.CLEAR_NOTIFICATIONS)

export const clearInvestments = () => action(UserActionTypes.CLEAR_INVESTMENTS)
