import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export const Description = styled.div`
  font-size: 1.125rem !important;
  font-family: Inter;
  color: ${p => p.theme.colors.white};
  margin-bottom: 1.5rem;

  ${media.md`
    font-size: 1.375rem !important;
    margin-bottom: 2.25rem;
  `}
`
