import axios from 'axios'
import { TokenStorage } from 'services/tokenService'
import { history, routePath } from 'routes'
import { store } from '../App'
import { logout } from 'redux/auth/operations'

export default () => {
  axios.interceptors.response.use(
    response => response,
    error => {
      // Return any error which is not due to authentication back to the calling service
      if (
        error.response.status !== 401 ||
        error.response.config.url === `${axios.defaults.baseURL}/login`
      ) {
        return new Promise((resolve, reject) => {
          reject(error)
        })
      }
      const path = window.location.pathname
      if (error.response.status === 401) {
        TokenStorage.clear()
        // @ts-ignore
        store.dispatch(logout())
        if (path !== routePath.SIGN_IN_BETABLOCK) {
          history.push(routePath.SIGN_IN)
        }
        return new Promise((resolve, reject) => {
          reject(error)
        })
      }
    }
  )
}
