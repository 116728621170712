import React from 'react'
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { AppState } from 'store'
import { isBrandSelector } from 'redux/auth/selectors'
import UserProfilePage from 'containers/profile/UserProfilePage'
import BrandProfilePage from 'containers/profile/BrandProfilePage'
import { UserSettingsPage } from 'containers/UserSettingsPage'
import InvestDetailPage from 'containers/profile/InvestDetailPage'
import UserNotificationsPage from 'containers/profile/UserNotificationsPage'
import { routePath } from 'routes'

const UserSettingsOption = (route: any) => <UserSettingsPage {...route} />

const UserRoutes = ({ isAuthenticated, isBrand }: Props) => {
  const ProfilePage = isBrand ? BrandProfilePage : UserProfilePage

  return !isAuthenticated ? (
    <Redirect to={routePath.SIGN_IN} />
  ) : (
    <Switch>
      <Route
        exact={true}
        path={routePath.PROFILE_NOTIFICATIONS}
        component={UserNotificationsPage}
      />
      <Route
        exact={true}
        path={routePath.PROFILE_SETTINGS}
        component={UserSettingsPage}
      />
      <Route
        exact={true}
        path={routePath.PROFILE_SETTINGS_OPTION}
        component={UserSettingsOption}
      />
      <Route
        exact={true}
        path={routePath.PROFILE_SETTINGS_SUBOPTION}
        component={UserSettingsOption}
      />
      <Route
        exact={true}
        path={routePath.PROFILE_SETTINGS_FAQ_TYPE}
        component={UserSettingsOption}
      />
      <Route
        exact={true}
        path={routePath.INVEST_TIER_PAGE}
        component={InvestDetailPage}
      />
      <Route path={routePath.PROFILE} component={ProfilePage} />
      <Route exact={true} path={routePath.PROFILE} component={ProfilePage} />
    </Switch>
  )
}

// tslint:disable-next-line:no-empty-interface
interface ReceivedProps {}

type StateProps = ReturnType<typeof mapStateToProps>
export type Props = StateProps & ReceivedProps & RouteProps

const mapStateToProps = (state: AppState) => ({
  isAuthenticated:
    state.auth.isAuthenticated && !state.auth.isForceChangePassword,
  isBrand: isBrandSelector(state)
})

export default connect(mapStateToProps)(UserRoutes)
