import styled from 'styled-components'
import { Col, media, Row } from 'styled-bootstrap-grid'

export const Wrapper = styled.div`
  .content-container {
    ${media.lg`
      padding: 0 1rem;
    `}

    ${media.xl`
      max-width: none;   
      padding: 0 2.5rem;
    `}
  }
`

export const MainContent = styled.div<{
  sectionBackground?: string
  investorDeck?: boolean
}>`
  background: ${p => p.sectionBackground};
  padding: 0 0 3.125rem;

  > div {
    ${media.md`
      max-width: 960px;
    `}
  }

  .full-container {
    flex: 0 0 90%;
    max-width: 90%;
  }

  .content-container {
    padding-left: 3vw;
    padding-right: 3vw;
    max-width: unset !important;
  }
  &.investor-deck {
    margin-bottom: -145px;
    &__empty {
      height: 90px;
    }
    ${media.md`
        margin-bottom: -325px;
        &__empty {
          height: 325px;
        }
      `}
  }

  #faq {
    ul {
      ${media.md`
        width: 65%;
        margin: unset;
    `}
      li {
        > div {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
`

export const FAQList = styled.ul`
  width: 100%;
  list-style: none;
`

export const SectionWrapper = styled.div<{ id?: string }>`
  margin: 0 auto;
  max-width: 1500px;
  padding-top: 2.5rem;

  ${p => {
    switch (p.id) {
      case 'story': {
        return `
          display: flex;

          div:last-child {
            display: flex;
          }

          div:last-child p {
            flex: 1 1 33.333%;
            margin-left: 25px;
          }

          div:last-child p span {
            color: #000;
          }   
        `
      }

      case 'faq': {
        return `
            padding-top: 16px;
        `
      }
    }
  }}

  > div div div.tiers-block {
    padding: 0;
  }

  ${media.lg`    
    > div div:nth-last-child(-n + 2) [data-testid='tier-block'] {
      margin-bottom: 0;
    }
  `}
  #conversation {
    padding-top: 39px;
    ${media.md`    
        padding-top: 64px;    
  `}
  }
`

export const SectionWrapperColumn = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1500px;
  margin: 0 auto;
  padding: 4rem 0;

  ${media.lg`
    flex-direction: row;
  `}
`

export const SectionTitleColumn = styled.div<{ color: string }>`
  flex: 1 1 25%;
  font-family: Inter;
  font-size: 1.5rem;
  font-weight: 900;
  color: ${p => p.color};
  margin-bottom: 1.6875rem;

  ${media.lg`
    margin-bottom: 0;
  `}

  ${media.md`
    font-size: 2.25rem;
  `}
`

export const SectionTitle = styled.h2<{ color?: string }>`
  width: 100%;
  font-size: 1.5rem;
  font-family: Inter;
  margin-bottom: 1rem;
  color: ${p => p.color || p.theme.colors.black};
  font-weight: 800;

  ${media.md`
     font-size: 2.25rem;
     margin-bottom: 41px;
   `}
`

export const SectionSubtitle = styled.h3`
  color: ${p => p.theme.colors.black};
`

export const ButtonAfterIcon = styled.a`
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 2rem;
  padding-bottom: 10px;

  span {
    font-size: 0.75rem;
    color: ${p => p.theme.colors.fuchsia};
    margin-left: 0.8rem;
    letter-spacing: 2.5px;
    font-size: 0.9375rem;
  }

  ${media.md`
    justify-content: flex-end;
  `}

  @media (max-width: 767px) {
    svg {
      width: 15px;
      height: 15px;
    }

    span {
      font-size: 0.75rem;
    }
  }

  ${media.md`
    justify-content: flex-end;

    span {
      font-size: 0.9375rem;
    }
  `}
`

export const DescriptionColumn = styled.div<{ color?: string }>`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex: 1 1 75%;
  overflow-y: auto;
  > * {
    padding-bottom: 1rem;
    ${media.md`
    max-width: 33%;
   `}
  }

  * {
    font-size: 0.9375rem !important;
    font-family: Inter;
    line-height: 1.5rem;
    color: ${p => (p.color ? p.color : p.theme.colors.black)};
  }
  p,
  span {
    font-weight: 300;
  }
  ${media.md`
    flex-direction: row;

    > * {
      padding-right: 30px;
    }

    * {
      font-size: 1.125rem !important;
      line-height: 1.75rem;
    }
  `}
`

export const SectionWrapperAbsolute = styled(Row as any)`
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  padding: 0;
  background: ${p => p.theme.colors.white};
  border-radius: 1rem;
  box-shadow: -1px 45px 100px rgba(21, 21, 18, 0.2);
`

export const TiersLeftBlock = styled(Col as any).attrs(() => ({
  col: 12,
  md: 4,
  lg: 3,
  xl: 2
}))`
  padding: 2rem;
  background: #fbfbfb;
  border-right: 1px solid #e6e6e6;
  border-top-left-radius: 1rem;
  border-radius: 1rem;

  ${media.md`
    border-top-right-radius: 0rem;
    border-bottom-right-radius: 0rem;
  `}

  > h2 {
    font-family: Inter;
    font-weight: 800;
    color: ${p => p.theme.colors.black};
  }
`

export const TiersRightBlock = styled(Col as any).attrs(() => ({
  col: 12,
  md: 8,
  lg: 9,
  xl: 10
}))`
  padding: 0;

  .tiers-block {
    border-left: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
  }

  .tiers-block:last-child {
    border-bottom: none;
  }
`

export const Description = styled.div`
  width: 100%;

  p,
  span,
  div,
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: ${p => p.theme.colors.black} !important;
  }
  div {
    text-align: justify;
    p {
      text-align: justify;
      span {
        font-family: Inter;
        font-size: 0.9375rem !important;
        line-height: 1.5rem;
        font-weight: 300;
        text-align: justify;
      }
    }
  }

  ${media.md`
    width: 65%;
    
    div { 
      p { 
        span { 
          font-size: 1.125rem !important;
          line-height: 1.75rem;
        }
      }
    }
  `}

  span {
    display: block;
    margin-top: 10px;
    text-align: justify;
  }

  div:not(:last-child) {
    margin-bottom: 1.875rem;
  }

  ul,
  ol {
    margin-left: 1.875rem;
    li {
      span {
        margin-top: 0;
      }
    }
  }

  h1 {
    margin: 1rem 0;
    font-size: 1.125rem;
    line-height: 1.625rem;
    text-align: left;

    ${media.md`
      font-size: 40px;
    `}

    ${media.lg`
      font-size: 1.375rem; 
      line-height: 2rem;
    `}
  }

  h2 {
    text-align: left;
  }

  h3 {
    font-size: 1.5rem;
    text-align: left;
    ${media.md`
      font-size: 2rem;
    `}
  }

  h4 {
    font-weight: bold;
    text-align: left;
  }

  a {
    color: ${p => p.theme.colors.gold};
  }

  table {
    width: 100% !important;
    table-layout: fixed;
    border: 0.0625rem solid;
    span {
      margin-top: 0;
      text-align: center;
    }

    td {
      padding: 0.625rem;
      border: 0.0625rem solid;
    }
    th {
      text-align: center;
      vertical-align: center;
      span {
        margin-top: 0;
        text-align: center;
      }
    }
  }

  ${media.xs`
  table {
    span {
      font-size: 0.75rem !important;
    }
  }
`}

  ${media.md`
  table {
    span {
      font-size: 0.875rem !important;
    }
  }
`}
`
