import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import Img from '../../../UI/Img'

export const BlockWrapper = styled.div`
  background: ${p => p.theme.colors.white};
`
export const EventTitle = styled.p`
  text-transform: uppercase;
  color: ${p => p.theme.colors.gold};
`
export const EventWrap = styled.div<{ img?: string }>`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 30vh;
  margin: 0 auto;
  overflow: hidden;

  &:before {
    content: '';
    display: block;
    position: absolute;
    background-color: ${p => p.theme.colors.gold};
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  > div {
    position: relative;
    z-index: 1;
    background: none;
  }

  ${media.md`
  height: 28.4vw;
  `}

  ${media.lg`
    width: 140%;
    left: -70%;
  `}
`
export const UpperWrapper = styled.div`
  padding: 6.6vmax 0;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
`
export const Left = styled.div`
  position: relative;
  width: 90%;

  ${media.lg`
    width: 50%;
  `}
`
export const ImgEvent = styled(Img)`
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: 1;
`

export const EventInfo = styled.div`
  display: flex;
  width: 100%;
  margin-right: 0%;
  background: ${p => p.theme.colors.black};
  padding: 4%;

  h2 {
    font-size: 0.75rem;
  }

  p {
    color: ${p => p.theme.colors.gold};
    font-size: 0.625rem;
  }
  ${media.sm`
    width: 100%;
  `}
  
  ${media.md`
    width: 100%;
  `}
 
  ${media.lg`
    width: 100%;
    padding: 6% 5%;
    
    h2 {
      font-size: calc(2.16vw + 1rem) !important;
    }

    p {
      font-size: 1rem;
    }
  `}

  ${media.xl`
    h2 {
      font-size: 2.8rem;
    }
  `}
`
export const Right = styled.div`
  position: relative;
  display: block;
  float: right;
  width: 90%;
  align-items: center;
  z-index: 2;
  margin-top: 0;
  left: -10%;
  transform: translateY(-15%);

  ${media.sm`
    margin-top: 0;
  `}
  
  ${media.md`
    transform: translateY(-50%);
  `}

  ${media.lg`
    position: relative;
    left: -15%;
    width: 50%;
    display: flex;
    margin-top: 0;
    transform: translateY(0);
  `}
`

export const EventTextBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  flex: 1;
  padding-right: 1rem;
  padding-top: 1rem;

  h1 {
    font-size: 1.75rem;
    font-weight: 700;
    color: #fff;
  }

  h1,
  p {
    text-transform: uppercase;
  }

  p {
    font-size: 1rem;
    font-weight: 400;
    text-transform: uppercase;
  }

  ${media.xl`
    h1 {
    font-size: 2.25rem;
    }
  
    p {
      font-size: 1.2rem;
    }
  `}

  ${media.lg`
    h1 {
    font-size: 2rem;
    }
  
    p {
      font-size: 1rem;
    }
  `}
`

export const Counters = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  * {
    margin: 3px auto;
    padding: 0;
    display: block;
  }

  svg {
    width: 1rem;
  }

  span {
    width: fit-content;
    font-family: 'Acumin Pro';
    font-size: 0.725rem;
    font-weight: bold;
  }

  a {
    text-decoration: none;
  }

  ${media.lg`
    svg {
     width: auto;
     margin-bottom: 0.75rem;
    }
  `}
`
export const SocialWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding-top: 10px;
  padding-right: 10px;
  width: 100%;
  z-index: 2;

  ${media.sm`
    padding-top: 20px;
    padding-right: 20px;
  `}

  * {
    margin: 3px 0;
    padding: 0;
    display: block;
    text-decoration: none;
  }

  svg {
    width: 2rem;
    ${media.md`
   height: 3rem;
  `}
  }

  span {
    margin: 3px auto;
    width: fit-content;
    font-family: 'Acumin Pro';
    font-size: 0.725rem;
    font-weight: bold;
  }
`
