import styled from 'styled-components'
import { Email } from 'containers/UserSettingsPage/styles'
import { media } from 'styled-bootstrap-grid'

export const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  
  margin-bottom: 40px;
  
  input {
    filter: none;
  }
  input:-internal-autofill-selected  {
  background-color: transparent !important;
  }    
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus
  input:-webkit-autofill, 
  input:-internal-autofill-selected,
  input:-internal-autofill-selected:focus,
  input:-internal-autofill-selected:hover,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
  -webkit-text-fill-color: #ссс !important;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  transition: background-color 5000s ease-in-out 0s !important;
  background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(0,174,255,0.04) 50%,rgba(255,255,255,0) 51%,rgba(0,174,255,0.03) 100%) !important;  
`
export const Form = styled.form`
  margin: 0 auto;
  max-width: 550px;
  button[type='submit'] {
    width: 100%;
  }
  padding-bottom: 100px;

  ${Email} {
    ::before {
      display: block;
      content: '';
      width: 26px;
      height: 1px;
      background: #fff;
      opacity: 0.5;
      margin: 35px 0;
    }
    ${media.lg`
     display: none;
  `}
  }
`
