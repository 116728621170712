import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { Container } from 'styled-bootstrap-grid'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Location } from 'history'

import { AppState } from 'store'
import { routePath } from 'routes'
import { fetchCommentThread } from 'redux/events/operations'
import {
  selectEventsComment,
  selectEventsErrors,
  selectIsFetchingComments
} from 'redux/events/selectors'
import { clearErrors, clearComments } from 'redux/events/actions'

import { CommentCard } from 'components/invest/comments'
import Loader from 'src/components/UI/Loader'
import Link from 'components/UI/Link'
import { WEBSITE } from 'utils/constants'

import {
  ThreadWrapper,
  Wrapper
} from 'components/invest/comments/Conversation/styles'

import { Dispatch } from 'store/types'

class CommentDetailPage extends Component<Props> {
  componentDidMount() {
    const {
      // @ts-ignore
      location: { state: { route = '' } = {} } = {},
      fetchCommentThread
    } = this.props

    if (route) {
      fetchCommentThread(route)
    }
  }

  componentWillUnmount() {
    const { clearComments, clearErrors } = this.props
    clearComments()
    clearErrors()
  }

  render() {
    const {
      // @ts-ignore
      location: { state: { route = '' } = {} } = {},
      comment,
      isFetching,
      error
    } = this.props

    if (!route) {
      return <Redirect to={routePath.PROFILE_NOTIFICATIONS} />
    }

    const eventId = Number(route.match(/events\/(\d+)/)[1])

    return (
      <Container>
        <Wrapper style={{ padding: '6rem 0' }}>
          {comment && (
            <ul>
              <ThreadWrapper>
                <CommentCard comment={comment} eventId={eventId} />
              </ThreadWrapper>
            </ul>
          )}
          {isFetching && <Loader />}
          {error && (
            <p style={{ textAlign: 'center' }}>
              We’re sorry, our Trust and Safety team has removed this post
              because it was flagged as violating our{' '}
              <Link to={WEBSITE + 'terms-of-use'}>Terms & Conditions</Link>.
            </p>
          )}
        </Wrapper>
      </Container>
    )
  }
}

interface ReceivedProps {
  location: Location
  route: any
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & ReceivedProps

const mapStateToProps = (state: AppState) => ({
  comment: selectEventsComment(state).data,
  isFetching: selectIsFetchingComments(state),
  error: selectEventsErrors(state)
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { fetchCommentThread, clearErrors, clearComments },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommentDetailPage)
