import React from 'react'
import Offers from 'components/experiences/Offers'
import CustomTemplate from 'components/content/CustomTemplate'
import EventPageHeader from 'components/common/EventPageHeader'

const ExperiencesPage = () => (
  <CustomTemplate
    header={
      <EventPageHeader
        title={'Shop & Charity'}
        subtitle={
          'Discover unique creator-branded merchandise and charitable initiatives.'
        }
      />
    }
    inFlow={false}
  >
    <Offers />
  </CustomTemplate>
)

export default ExperiencesPage
