import React, { FC } from 'react'
import { WrapperModal } from './styles'

export interface ModalProps {
  children?: any
  isOpen: boolean
  onClose?: () => void
  style?: object
  id?: string
  className?: string
  closeTimeout?: number
  overlayStyle?: object
}

const Modal: FC<ModalProps> = ({
  children,
  isOpen,
  onClose,
  style = {},
  id,
  className,
  closeTimeout
}) => (
  <WrapperModal
    isOpen={isOpen}
    onRequestClose={onClose}
    id={id}
    closeTimeoutMS={closeTimeout}
    overlayClassName={className}
    style={{
      overlay: {
        position: 'fixed',
        height: '100vh',
        overflowY: 'hidden',
        width: '100%',
        zIndex: 999,
        backgroundColor: 'rgba(25,25,25,0.5)'
      },
      content: {
        width: 'fit-content',
        padding: '2.5rem 1.625rem',
        position: 'relative',
        overflow: 'unset',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        boxShadow: '0 0.125rem 0.625rem 0 rgba(0, 0, 0, 0.25)',
        borderRadius: '0.375rem',
        borderColor: 'transparent',
        backgroundColor: '#313131',
        ...style
      }
    }}
    appElement={document.getElementById('root') || {}}
  >
    {children}
  </WrapperModal>
)

export default Modal
