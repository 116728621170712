import React from 'react'
import CSS from 'csstype'
import { LoadingIcon } from 'components/icons'
import styled from 'styled-components'

interface IProps {
  style?: CSS.Properties
}
const Spin: React.FC<IProps> = ({ style }) => (
  <Loader style={style}>
    <LoadingIcon style={{ fill: style ? style.stroke : '#4f4458' }} />
  </Loader>
)
export default Spin

export const Loader = styled.div`
  margin: 0 auto;
  width: 3rem;
  height: 3rem;
`
