import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 3.3vmax 3vw;
  .offers-container {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
    @media (min-width: 1346px) {
      max-width: 1465px;
    }
  }
`
