import React, { FC, RefObject, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {
  likeComment,
  reportComment,
  replyToComment,
  fetchReplies
} from 'redux/events/operations'
import { setActiveCommentId } from 'redux/events/actions'
import {
  getCommentsLeftNumber,
  selectActiveCommentId,
  selectEvents,
  selectIsFetchingComments
} from 'redux/events/selectors'
import { selectIsAuth, selectUserId } from 'redux/auth/selectors'
import { theme } from 'styled/theme'
import ReportDropdown from '../ReportDropdown'
import ReplyField from '../ReplyField'
import OutsideClick from 'components/UI/OutsideClick'
import ReplyCard from '../ReplyCard'
import Loader from 'components/UI/Loader'

import {
  Comment,
  CommentWrapper,
  CommentContent,
  ReportBlock,
  ReplyWrapper,
  UserName,
  AdditionalInfo,
  AdditionalItem
} from './styles'
import { LoadMoreButton } from '../Conversation/styles'

import { AppState, Dispatch } from 'store/types'
import {
  IComment,
  ReportHarassment,
  ReportReason,
  ReportReasonType,
  ReportViolence
} from 'redux/events/comments/types'
import Avatar from 'components/common/Avatar'
import {
  LikeIcon,
  CommentClockIcon,
  ReplyIcon,
  ReportIcon
} from 'components/icons'
import { Loading } from '../CommentField/styles'

const CommentCard: FC<Props> = props => {
  const {
    comment,
    comment: {
      total_likes = 0,
      is_liked = false,
      replies = [],
      total_replies = 0
    } = {},
    eventId,
    setActiveCommentId,
    isAuthenticated,
    userId,
    activeCommentId,
    isFetching,
    isFetchingReplies
  } = props

  const ref: RefObject<HTMLDivElement> = useRef(null)

  const [likes, setLikes] = useState(total_likes)
  const [isLiked, setIsLiked] = useState(is_liked)
  const [isReplyVisible, setIsReplyVisible] = useState(false)

  const [openModal, setOpenModal] = useState(false)

  const canReport = isAuthenticated && comment.user_id !== userId
  const isActiveComment = activeCommentId === comment.id
  const isOpen = openModal && isActiveComment
  const repliesLeft = getCommentsLeftNumber(total_replies, replies)

  const loadMore = async () => {
    const { isFetchingReplies, fetchReplies } = props

    if (!isFetchingReplies) {
      fetchReplies(eventId, comment.id)
    }
  }

  const handleLike = () => {
    const { likeComment } = props

    setLikes(isLiked ? likes - 1 : likes + 1)
    setIsLiked(!isLiked)
    likeComment(comment.id, eventId)
  }

  const handleReport = (
    reason: ReportReason,
    reasonType?: ReportReasonType,
    reasonTypeDetails?: ReportViolence | ReportHarassment
  ) => {
    const { reportComment } = props

    reportComment(comment.id, eventId, reason, reasonType, reasonTypeDetails)
  }

  const toggleReportList = () => {
    if (comment.id !== activeCommentId) {
      setActiveCommentId(comment.id)
    }
    setOpenModal(true)
  }

  const hideReportList = (isVisible: boolean = openModal) => {
    if (!isActiveComment || isVisible) {
      return
    }
    setOpenModal(false)
    setActiveCommentId(null)
  }

  const handlePostComment = (content: string) => {
    const { replyToComment } = props
    const elem = ref.current

    try {
      replyToComment(eventId, comment.id, content)
      hideReplyField()
    } catch (e) {}

    if (elem && elem.parentElement) {
      elem.parentElement.scrollIntoView()
    }
  }

  const showReplyField = () => setIsReplyVisible(true)

  const hideReplyField = () => setIsReplyVisible(false)

  return (
    <>
      <CommentWrapper ref={ref}>
        <Avatar name={comment.name} src={comment.icon} size={40} />
        <CommentContent>
          <div>
            <UserName>
              {comment.name}
              &nbsp;&#8226;&nbsp;
              {comment.title}
            </UserName>
            <Comment>{comment.content}</Comment>
            <AdditionalInfo>
              <AdditionalItem>
                <CommentClockIcon />
                {comment.left}
              </AdditionalItem>

              <AdditionalItem onClick={handleLike} disabled={!isAuthenticated}>
                <LikeIcon
                  fill={isLiked ? theme.colors.fuchsia : theme.colors.white}
                />
                Like{likes > 0 && ` • ${likes}`}
              </AdditionalItem>

              {isAuthenticated && (
                <AdditionalItem onClick={showReplyField}>
                  <ReplyIcon />
                  Reply
                </AdditionalItem>
              )}
              {canReport && (
                <ReportBlock>
                  <OutsideClick onOutsideClick={hideReportList}>
                    <AdditionalItem id={'report'} onClick={toggleReportList}>
                      <ReportIcon />
                      Report
                    </AdditionalItem>
                    {isOpen && (
                      <ReportDropdown
                        report={handleReport}
                        hide={hideReportList}
                        isOpen={openModal}
                        setIsOpen={setOpenModal}
                      />
                    )}
                  </OutsideClick>
                </ReportBlock>
              )}
            </AdditionalInfo>
            {isAuthenticated && isReplyVisible && (
              <ReplyWrapper>
                <OutsideClick onOutsideClick={hideReplyField}>
                  <ReplyField
                    userName={comment.name}
                    isFetching={isFetching}
                    post={handlePostComment}
                  />
                </OutsideClick>
              </ReplyWrapper>
            )}
          </div>
          {replies.map(reply => (
            <ReplyCard reply={reply} eventId={eventId} key={reply.id} />
          ))}
        </CommentContent>
      </CommentWrapper>
      {replies && replies.length > 0 && (
        <>
          {repliesLeft > 0 && (
            <CommentWrapper secondary={true}>
              <LoadMoreButton
                style={{ minWidth: '317px' }}
                onClick={loadMore}
                disabled={isFetchingReplies}
              >
                {!isFetchingReplies ? (
                  `View previous replies (${repliesLeft})`
                ) : (
                  <Loading>
                    <span>&bull;</span>
                    <span>&bull;</span>
                    <span>&bull;</span>
                  </Loading>
                )}
              </LoadMoreButton>
            </CommentWrapper>
          )}
          {isFetchingReplies && isActiveComment && <Loader />}
        </>
      )}
    </>
  )
}

interface ReceivedProps {
  comment: IComment
  eventId: number
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type Props = StateProps & DispatchProps & ReceivedProps

const mapStateToProps = (state: AppState) => ({
  isAuthenticated: selectIsAuth(state),
  userId: selectUserId(state),
  isFetching: selectIsFetchingComments(state),
  activeCommentId: selectActiveCommentId(state),
  isFetchingReplies: selectEvents(state).isFetchingReplies
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      likeComment,
      reportComment,
      replyToComment,
      setActiveCommentId,
      fetchReplies
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommentCard)
