import { createReducer } from 'typesafe-actions'
import { convertValueToTaxId } from 'utils/helpers'
import {
  AccountType,
  ERROR_MESSAGE,
  IEventType,
  InvestmentAction,
  InvestmentActionTypes,
  InvestmentState,
  IPaymentMethods,
  ISubscriptionAgreementResp,
  IViewMode
} from './types'

export const initialState: InvestmentState = {
  entity_id: '',
  investor_id: '',
  annual_income: '',
  net_worth: '',
  tierDetails: {
    isFetching: false,
    isLoaded: false,
    tier_name: '',
    tier_image_url: '',
    influencer_name: '',
    minimum_amount: 0,
    most_popular_amount: 0,
    maximum_amount: 0,
    price_per_share: 0,
    security_type: 0
  },
  currentStep: 1,
  tierId: 0,
  investmentAmount: 0,
  contact: {
    isFetching: false,
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    phone_code: '+1',
    name: '',
    id: '',
    investor_id: ''
  },
  address: {
    street: '',
    additional_address: '',
    country: '',
    city: '',
    state: '',
    postal_code: '',
    country_label: '',
    is_country_restricted: false
  },
  payment: {
    selectedMethod: IPaymentMethods.CARD,
    token: '',
    creditCard: {
      card_number: '',
      expiration_date: '',
      cvv: '',
      cardholder: '',
      zip: ''
    },
    USBank: {
      routing_number: '',
      account_number: '',
      name_on_account: '',
      account_type: AccountType.CHECKING
    }
  },
  verification: {
    date_of_birth: '',
    citizenUSA: true,
    ssn: '',
    passport: undefined
  },
  trustInfo: {
    trust_name: '',
    trust_tax_id: ''
  },
  iraInfo: {
    vesting_name: '',
    ira_tax_id: ''
  },
  companyInfo: {
    entity_name: '',
    entity_tax_id: '',
    state: ''
  },
  entityContactInfo: {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    phone_code: '+1'
  },
  entityAddressInfo: {
    country: '',
    street: '',
    additional_address: '',
    city: '',
    state: '',
    postal_code: ''
  },
  userSignature: '',
  companiesList: {},
  viewMode: IViewMode.LIST,
  eventType: IEventType.CURRENT,
  subscriptionAgreement: {} as ISubscriptionAgreementResp,
  errors: {},
  payment_error: ''
}

export const investmentReducer = createReducer<
  InvestmentState,
  InvestmentAction
>(initialState, {
  [InvestmentActionTypes.SET_INVESTMENT_TIER_ID]: (state, action) => ({
    ...state,
    tierId: action.payload
  }),
  [InvestmentActionTypes.CHANGE_INVESTMENT_AMOUNT]: (state, action) => ({
    ...state,
    investmentAmount: action.payload
  }),
  [InvestmentActionTypes.SET_INVESTMENT_TIER_ID]: (state, action) => ({
    ...state,
    tierId: action.payload
  }),
  [InvestmentActionTypes.CLEAR_INVESTMENT_AMOUNT_AND_TIER]: state => ({
    ...state,
    tierId: initialState.tierId,
    investmentAmount: initialState.investmentAmount
  }),
  [InvestmentActionTypes.FETCH_TIER_DETAILS_REQUEST]: state => ({
    ...state,
    tierDetails: {
      ...state.tierDetails,
      isFetching: true,
      isLoaded: false
    }
  }),
  [InvestmentActionTypes.FETCH_TIER_DETAILS_SUCCESS]: (state, action) => ({
    ...state,
    tierDetails: {
      ...action.payload,
      isFetching: false,
      isLoaded: true
    }
  }),
  [InvestmentActionTypes.FETCH_TIER_DETAILS_FAIL]: state => ({
    ...state,
    tierDetails: {
      ...state.tierDetails,
      isFetching: false,
      isLoaded: false
    }
  }),
  [InvestmentActionTypes.FETCH_ENTITY_INFORMATION_REQUEST]: state => ({
    ...state,
    companiesList: {}
  }),
  [InvestmentActionTypes.FETCH_ENTITY_INFORMATION_SUCCESS]: (
    state,
    action
  ) => ({
    ...state,
    companiesList: action.payload.reduce(
      (acc, c) => ({ ...acc, [c.id]: c }),
      {}
    )
  }),
  [InvestmentActionTypes.FETCH_ENTITY_INFO_DETAILS_SUCCESS]: (
    state,
    action
  ) => {
    // @ts-ignore
    const { annual_income, net_worth, ...newCompany } = action.payload
    return {
      ...state,
      companiesList: {
        ...state.companiesList,
        [newCompany.id]: {
          ...state.companiesList[newCompany.id],
          details: newCompany
        }
      }
    }
  },
  [InvestmentActionTypes.SEND_ENTITY_INFORMATION_FAIL]: state => ({
    ...state,
    payment_error: 'Request failed with an error'
  }),
  [InvestmentActionTypes.FETCH_PERSONAL_INFORMATION_REQUEST]: state => ({
    ...state,
    contact: {
      ...state.contact,
      isFetching: true
    }
  }),
  [InvestmentActionTypes.FETCH_PERSONAL_INFORMATION_SUCCESS]: (
    state,
    action
  ) => ({
    ...state,
    investor_id: action.payload.investor_id,
    annual_income: action.payload.annual_income,
    net_worth: action.payload.net_worth,
    contact: {
      ...state.contact,
      isFetching: false,
      name: action.payload.name,
      phone: action.payload.phone,
      email: action.payload.email,
      id: action.payload.id
    },
    address: {
      ...state.address,
      ...action.payload.userAddress,
      street: action.payload.userAddress.address
    },
    verification: {
      ...state.verification,
      date_of_birth: action.payload.date_of_birth,
      ssn:
        action.payload.tax_id_number !== '999999999'
          ? action.payload.tax_id_number
          : '',
      citizenUSA: action.payload.tax_id_number !== '999999999'
    }
  }),
  [InvestmentActionTypes.FETCH_PERSONAL_INFORMATION_FAIL]: state => ({
    ...state,
    contact: {
      ...state.contact,
      isFetching: false
    }
  }),
  [InvestmentActionTypes.SEND_PERSONAL_INFORMATION_FAIL]: state => ({
    ...state,
    payment_error: ERROR_MESSAGE
  }),
  [InvestmentActionTypes.FETCH_TRUST_INFORMATION_REQUEST]: state => ({
    ...state,
    trustInfo: {
      ...state.trustInfo,
      isFetching: true
    }
  }),
  [InvestmentActionTypes.FETCH_TRUST_INFORMATION_SUCCESS]: (state, action) => ({
    ...state,
    entity_id: action.payload.id,
    investor_id: action.payload.investor_id,
    trustInfo: {
      isFetching: false,
      ...state.trustInfo,
      trust_name: action.payload.trust_name,
      trust_tax_id: convertValueToTaxId(action.payload.trust_tax_id)
    },
    entityContactInfo: {
      ...state.entityContactInfo,
      ...action.payload.custodian
    },
    entityAddressInfo: {
      ...state.entityAddressInfo,
      ...action.payload.trustAddress,
      street: action.payload.trustAddress.address,
      additional_address: action.payload.trustAddress.additional_address || ''
    }
  }),
  [InvestmentActionTypes.FETCH_TRUST_INFORMATION_FAIL]: state => ({
    ...state,
    trustInfo: {
      ...state.trustInfo,
      isFetching: false
    }
  }),
  [InvestmentActionTypes.SEND_TRUST_INFORMATION_FAIL]: state => ({
    ...state,
    payment_error: ERROR_MESSAGE
  }),
  [InvestmentActionTypes.FETCH_IRA_INFORMATION_REQUEST]: state => ({
    ...state,
    iraInfo: {
      ...state.iraInfo,
      isFetching: true
    }
  }),
  [InvestmentActionTypes.FETCH_IRA_INFORMATION_SUCCESS]: (state, action) => ({
    ...state,
    entity_id: action.payload.id,
    investor_id: action.payload.investor_id,
    iraInfo: {
      isFetching: false,
      ...state.iraInfo,
      vesting_name: action.payload.vesting_name,
      ira_tax_id: action.payload.ira_tax_id,
      id: action.payload.id,
      investor_id: action.payload.investor_id
    },
    entityContactInfo: {
      ...state.entityContactInfo,
      ...action.payload.contact
    },
    entityAddressInfo: {
      ...state.entityAddressInfo,
      ...action.payload.iraAddress,
      street: action.payload.iraAddress.address
    }
  }),
  [InvestmentActionTypes.FETCH_IRA_INFORMATION_FAIL]: state => ({
    ...state,
    iraInfo: {
      ...state.iraInfo,
      isFetching: false
    }
  }),
  [InvestmentActionTypes.SEND_IRA_INFORMATION_FAIL]: state => ({
    ...state,
    payment_error: ERROR_MESSAGE
  }),
  [InvestmentActionTypes.SET_INVESTMENT_STEP]: (state, action) => ({
    ...state,
    currentStep: action.payload
  }),
  [InvestmentActionTypes.SET_PAYMENT_METHOD]: (state, action) => ({
    ...state,
    payment: {
      ...state.payment,
      selectedMethod: action.payload
    }
  }),
  [InvestmentActionTypes.SET_CREDIT_CARD_INFO]: (state, action) => ({
    ...state,
    payment: {
      ...state.payment,
      creditCard: { ...state.payment.creditCard, ...action.payload }
    }
  }),
  [InvestmentActionTypes.SET_US_BANK_INFO]: (state, action) => ({
    ...state,
    payment: {
      ...state.payment,
      USBank: { ...state.payment.USBank, ...action.payload }
    }
  }),
  [InvestmentActionTypes.SET_ERRORS]: (state, action) => ({
    ...state,
    errors: action.payload
  }),
  [InvestmentActionTypes.CHANGE_INVESTMENT_CONTACT]: (state, action) => ({
    ...state,
    contact: { ...state.contact, ...action.payload }
  }),
  [InvestmentActionTypes.CHANGE_INVESTMENT_ADDRESS]: (state, action) => ({
    ...state,
    address: { ...state.address, ...action.payload }
  }),
  [InvestmentActionTypes.CHECK_PROHIBITED_COUNTRY_SUCCESS]: (state, action) =>
    action.payload.isEntity
      ? {
          ...state,
          entityAddressInfo: {
            ...state.entityAddressInfo,
            is_country_restricted: action.payload.restricted
          }
        }
      : {
          ...state,
          address: {
            ...state.address,
            is_country_restricted: action.payload.restricted
          }
        },
  [InvestmentActionTypes.SET_VERIFICATION]: (state, action) => ({
    ...state,
    verification: { ...state.verification, ...action.payload }
  }),
  [InvestmentActionTypes.CLEAR_INVESTMENT_DATA]: state => ({
    ...initialState,
    tierId: state.tierId,
    investmentAmount: state.investmentAmount
  }),
  [InvestmentActionTypes.SET_TRUST_INFO]: (state, action) => ({
    ...state,
    trustInfo: { ...state.trustInfo, ...action.payload }
  }),
  [InvestmentActionTypes.SET_IRA_INFO]: (state, action) => ({
    ...state,
    iraInfo: { ...state.iraInfo, ...action.payload }
  }),
  [InvestmentActionTypes.SET_COMPANY_INFO]: (state, action) => ({
    ...state,
    companyInfo: { ...state.companyInfo, ...action.payload }
  }),
  [InvestmentActionTypes.SET_ENTITY_CONTACT_INFO]: (state, action) => ({
    ...state,
    entityContactInfo: {
      ...state.entityContactInfo,
      ...action.payload
    }
  }),
  [InvestmentActionTypes.SET_ENTITY_ADDRESS_INFO]: (state, action) => ({
    ...state,
    entityAddressInfo: {
      ...state.entityAddressInfo,
      ...action.payload
    }
  }),
  [InvestmentActionTypes.CHANGE_USER_SIGNATURE]: (state, action) => ({
    ...state,
    userSignature: action.payload
  }),
  [InvestmentActionTypes.SWITCH_VIEW_MODE]: state => ({
    ...state,
    viewMode:
      state.viewMode === IViewMode.LIST ? IViewMode.GRID : IViewMode.LIST
  }),
  [InvestmentActionTypes.SWITCH_EVENT_TYPE]: state => ({
    ...state,
    eventType:
      state.eventType === IEventType.CURRENT
        ? IEventType.PAST
        : IEventType.CURRENT
  }),
  [InvestmentActionTypes.CREATE_SUBSCRIPTION_AGREEMENT_SUCCESS]: (
    state,
    action
  ) => ({
    ...state,
    subscriptionAgreement: action.payload
  }),
  [InvestmentActionTypes.INVEST_PAYMENT_CREDIT_CARD_FAIL]: state => ({
    ...state,
    payment_error: ERROR_MESSAGE
  }),
  [InvestmentActionTypes.INVEST_PAYMENT_ACH_FAIL]: state => ({
    ...state,
    payment_error: ERROR_MESSAGE
  }),
  [InvestmentActionTypes.INVEST_PAYMENT_WIRE_FAIL]: state => ({
    ...state,
    payment_error: ERROR_MESSAGE
  }),
  [InvestmentActionTypes.CLEAR_INVESTMENT_PAYMENT_ERROR]: state => ({
    ...state,
    payment_error: initialState.payment_error
  })
})
