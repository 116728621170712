import { ActionType } from 'typesafe-actions'
import { Option } from 'components/UI/Dropdown'
import * as actions from './actions'
import { IErrors } from '../commonTypes'
import { ProfileSectionTypes } from 'redux/profile/types'
import { ITier } from 'redux/events/types'

export type CheckoutAction =
  | ActionType<typeof actions.paymentDonate>
  | ActionType<typeof actions.fetchShippingCountries>
  | ActionType<typeof actions.fetchCheckoutInfoAsync>
  | ActionType<typeof actions.braintreeTokenAsync>
  | ActionType<typeof actions.fetchAddressAsync>
  | ActionType<typeof actions.confirmPayPalAsync>
  | ActionType<typeof actions.clearCheckoutErrors>
  | ActionType<typeof actions.changeShipping>
  | ActionType<typeof actions.setShippingErrors>
  | ActionType<typeof actions.setShippingAddressFromProfile>
  | ActionType<typeof actions.setCheckoutStep>
  | ActionType<typeof actions.setCustomField>
  | ActionType<typeof actions.setInitialCheckout>
  | ActionType<typeof actions.setLastActivity>
  | ActionType<typeof actions.addItemToCartAsync>
  | ActionType<typeof actions.deleteItemFromCartAsync>
  | ActionType<typeof actions.increaseCartItemCountAsync>
  | ActionType<typeof actions.decreaseCartItemCountAsync>
  | ActionType<typeof actions.shoppingCartInitializationAction>
  | ActionType<typeof actions.fetchShoppingCartAsync>
  | ActionType<typeof actions.increaseQuantityBuyNowItemAction>
  | ActionType<typeof actions.decreaseQuantityBuyNowItemAction>
  | ActionType<typeof actions.deleteQuantityBuyNowItemAction>
  | ActionType<typeof actions.changeQuantityBuyNowItemAction>
  | ActionType<typeof actions.setGuestBuyNow>
  | ActionType<typeof actions.resetGuestBuyNow>
  | ActionType<typeof actions.fetchTierAsync>
  | ActionType<typeof actions.startPaymentProcessing>
  | ActionType<typeof actions.finishPaymentProcessing>

export interface ICustomFields {
  [key: string]: ICustomField[]
}

export interface ICustomField {
  id: number
  selected: number
}

export interface ICart {
  items: ICartItem[]
}
export interface BuyNowGuest {
  eventId: number
  tierId: number
  tierSetId: number
}
export interface ICartItem {
  id: number
  tier_id: number
  tier_image: string
  tier_influencer: string
  tier_name: string
  tier_price: number
  sets: ICartItemSets[]
  // for old tiers
  // TODO Remove quantity
  quantity: number
  fee?: number
  tax_amount?: number
  shipping_charge?: number
}
export interface ICartItemSets {
  shopping_cart_id: number
  tier_set_id: number
  color: string | null
  color_name: string | null
  size: string | null
  quantity: number
  left: number
}

export enum CheckoutStepType {
  DETAIL = 'Product Information',
  PRODUCT_SPECIFIC = 'Product specific',
  SHIPPING = 'Shipping',
  PAYMENT = 'Payment'
}

export enum PaymentSystem {
  STRIPE = 'stripe',
  PAY_PAL = 'paypal',
  BRAINTREE = 'braintree'
}

export enum Currency {
  USD = 1,
  CRYPTO = 2
}

export enum CheckoutMethod {
  // for redirect the user by clicking on the 'Continue' button on Success page (all payments system)
  SHOPPING = 'checkoutShoppingCart',
  BUY_NOW = 'checkoutBuyNow',
  INVESTMENT = 'investment'
}

// ('sale' => for regular checkout, 'authorize' => for holding)
export enum Intent {
  SALE = 'sale',
  AUTHORIZE = 'authorize'
}

export interface CheckoutState {
  isFetching: boolean
  isPaymentProcessing: boolean
  cartInfo: {
    shipping_charge: number
    subtotal: number
    tax_amount: number
    total: number
    isBlocked: boolean
    isFetching: boolean
  }
  shipping: IShipping
  currentStep: number
  checkoutSteps: Array<{ [key: string]: any }>
  customFields: ICustomFields
  countries: ShippingCountry[]
  suggestAddresses: any[]
  braintreeToken: string
  errorsShipping: IErrors<IShipping>
  error: string
  lastActivity: ProfileSectionTypes
  cart: ICart
  buyNowCart: {
    tier: ITier
    quantity: number
    guestCheckout: BuyNowGuest
  }
}

export enum CheckoutActionTypes {
  FETCH_CHECKOUT_INFO_REQUEST = '@@checkout/FETCH_CHECKOUT_INFO_REQUEST',
  FETCH_CHECKOUT_INFO_SUCCESS = '@@checkout/FETCH_CHECKOUT_INFO_SUCCESS',
  FETCH_CHECKOUT_INFO_FAIL = '@@checkout/FETCH_CHECKOUT_INFO_FAIL',
  BRAINTREE_TOKEN_REQUEST = '@@checkout/BRAINTREE_TOKEN_REQUEST',
  BRAINTREE_TOKEN_SUCCESS = '@@checkout/BRAINTREE_TOKEN_SUCCESS',
  BRAINTREE_TOKEN_FAIL = '@@checkout/BRAINTREE_TOKEN_FAIL',
  PAY_DONATE_REQUEST = '@@checkout/PAY_DONATE_REQUEST',
  PAY_DONATE_SUCCESS = '@@checkout/PAY_DONATE_SUCCESS',
  PAY_DONATE_FAIL = '@@checkout/PAY_DONATE_FAIL',
  CHANGE_SHIPPING = '@@checkout/CHANGE_SHIPPING',
  SET_SHIPPING_ERRORS = '@@checkout/SET_SHIPPING_ERRORS',
  SET_SHIPPING_ADDRESS_FROM_PROFILE = '@@checkout/SET_SHIPPING_ADDRESS_FROM_PROFILE',
  CLEAR_ERRORS = '@@checkout/CLEAR_ERRORS',
  INITIAL_CHECKOUT = '@@checkout/INITIAL_CHECKOUT',
  SET_CHECKOUT_STEP = '@@checkout/SET_CHECKOUT_STEP',
  SET_CUSTOM_FIELD = '@@checkout/SET_CUSTOM_FIELD',
  FETCH_COUNTRIES_REQUEST = '@@checkout/FETCH_COUNTRIES_REQUEST',
  FETCH_COUNTRIES_SUCCESS = '@@checkout/FETCH_COUNTRIES_SUCCESS',
  FETCH_COUNTRIES_FAIL = '@@checkout/FETCH_COUNTRIES_FAIL',
  FETCH_ADDRESS_REQUEST = '@@checkout/FETCH_ADDRESS_REQUEST',
  FETCH_ADDRESS_SUCCESS = '@@checkout/FETCH_ADDRESS_SUCCESS',
  FETCH_ADDRESS_FAIL = '@@checkout/FETCH_ADDRESS_FAIL',
  CONFIRM_PAY_PAL_REQUEST = '@@checkout/CONFIRM_PAY_PAL_REQUEST',
  CONFIRM_PAY_PAL_SUCCESS = '@@checkout/CONFIRM_PAY_PAL_SUCCESS',
  CONFIRM_PAY_PAL_FAIL = '@@checkout/CONFIRM_PAY_PAL_FAIL',
  SET_LAST_ACTIVITY = '@@checkout/SET_LAST_ACTIVITY',
  ADD_ITEM_TO_CART_REQUEST = '@@checkout/ADD_ITEM_TO_CART_REQUEST',
  ADD_ITEM_TO_CART_SUCCESS = '@@checkout/ADD_ITEM_TO_CART_SUCCESS',
  ADD_ITEM_TO_CART_FAIL = '@@checkout/ADD_ITEM_TO_CART_FAIL',
  DELETE_ITEM_FROM_CART_REQUEST = '@@checkout/DELETE_ITEM_FROM_CART_REQUEST',
  DELETE_ITEM_FROM_CART_SUCCESS = '@@checkout/DELETE_ITEM_FROM_CART_SUCCESS',
  DELETE_ITEM_FROM_CART_FAIL = '@@checkout/DELETE_ITEM_FROM_CART_FAIL',
  INCREASE_CART_ITEM_COUNT_REQUEST = '@@checkout/INCREASE_CART_ITEM_COUNT_REQUEST',
  INCREASE_CART_ITEM_COUNT_SUCCESS = '@@checkout/INCREASE_CART_ITEM_COUNT_SUCCESS',
  INCREASE_CART_ITEM_COUNT_FAIL = '@@checkout/INCREASE_CART_ITEM_COUNT_FAIL',
  DECREASE_CART_ITEM_COUNT_REQUEST = '@@checkout/DECREASE_CART_ITEM_COUNT_REQUEST',
  DECREASE_CART_ITEM_COUNT_SUCCESS = '@@checkout/DECREASE_CART_ITEM_COUNT_SUCCESS',
  DECREASE_CART_ITEM_COUNT_FAIL = '@@checkout/DECREASE_CART_ITEM_COUNT_FAIL',
  SHOPPING_CART_INITIALIZED = '@@checkout/SHOPPING_CART_INITIALIZED',
  FETCH_SHOPPING_CART_REQUEST = '@@checkout/FETCH_SHOPPING_CART_REQUEST',
  FETCH_SHOPPING_CART_SUCCESS = '@@checkout/FETCH_SHOPPING_CART_SUCCESS',
  FETCH_SHOPPING_CART_FAIL = '@@checkout/FETCH_SHOPPING_CART_FAIL',
  INCREASE_BUYNOW_ITEM_QUANTITY = '@@checkout/INCREASE_BUYNOW_ITEM_QUANTITY',
  DECREASE_BUYNOW_ITEM_QUANTITY = '@@checkout/DECREASE_BUYNOW_ITEM_QUANTITY',
  CHANGE_BUYNOW_ITEM_QUANTITY = '@@checkout/CHANGE_BUYNOW_ITEM_QUANTITY',
  SET_GUEST_BUYNOW = '@@checkout/SET_GUEST_BUYNOW',
  RESET_GUEST_BUYNOW = '@@checkout/RESET_GUEST_BUYNOW',
  DELETE_BUYNOW_ITEM_QUANTITY = '@@checkout/DELETE_BUYNOW_ITEM_QUANTITY',
  FETCH_TIER_REQUEST = '@@checkout/FETCH_TIER_REQUEST',
  FETCH_TIER_SUCCESS = '@@checkout/FETCH_TIER_SUCCESS',
  FETCH_TIER_FAIL = '@@checkout/FETCH_TIER_FAIL',
  START_PAYMENT_PROCESSING = '@@checkout/START_PAYMENT_PROCESSING',
  FINISH_PAYMENT_PROCESSING = '@@checkout/FINISH_PAYMENT_PROCESSING'
}

export interface IShipping {
  country: string
  state: string
  city: string
  zip: string
  street: Option
  streetName: string
  email?: string
  use_as_default?: boolean
  room?: string
  recipient_name: string
}

export interface IConfirm {
  PayerID: string
  paymentId: number
  token: string
}

export interface IConfirmPayPal extends IConfirm {
  payment_system: PaymentSystem
  intent: Intent
}
export interface ICheckoutPayload {
  payment_system: PaymentSystem
  intent: Intent
  currency: Currency
  amount: number
  quantity: number
  event_id: number
  event_title: string
  tier_id: number
  tier_title: string
  tier_picture: string
  device_data?: string
  token: string | null
  payerID?: string | null
  country?: string
  city?: string
  state?: string
  zip?: string
  street?: string
  room?: string
  place_id?: string
  use_as_default?: boolean
  custom_fields?: ICustomField[]
  redirect_url?: string
  email?: string
  device_id?: string
  recipient_name?: string
}

export type IBuyNowPayload = Pick<
  ICheckoutPayload,
  'payment_system' | 'currency' | 'tier_id' | 'token'
>

interface ShippingState {
  id: number
  shipping_country_id: number
  state_name: string
  available: 0 | 1
  created_at: string // '2020-02-05 09:03:55'
  updated_at: string // '2020-02-05 09:03:55'
}

interface ShippingCountry {
  id: number
  code: string
  country_name: string
  available: 0 | 1
  created_at: string // '2020-02-05 09:03:37'
  updated_at: string // '2020-02-05 09:03:37'
  available_states: ShippingState[]
}

export interface IShippingCountries {
  shipping_countries: ShippingCountry[]
}
