import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { AppState, Dispatch } from 'store/types'
import { hideModal } from 'redux/auth/actions'
import Modal from 'src/components/UI/Modal'
import Button from 'src/components/UI/Button'

import { PopUpBlock, PopUpText } from './styles'

class PopUpMessage extends Component<Props> {
  onClosePopUp = () => {
    const { hideModal, onClose } = this.props
    hideModal()
    if (onClose) {
      onClose()
    }
  }

  render() {
    const { isOpen, message, hideControl } = this.props
    return (
      <Modal
        isOpen={isOpen}
        hideControl={hideControl}
        onClose={this.onClosePopUp}
      >
        <PopUpBlock>
          <PopUpText
            dangerouslySetInnerHTML={{
              __html: message
            }}
          />
          <Button onClick={this.onClosePopUp}>OK</Button>
        </PopUpBlock>
      </Modal>
    )
  }
}

interface ReceivedProps {
  onClose?: () => void
  hideControl?: boolean
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
export type Props = StateProps & DispatchProps & ReceivedProps

const mapStateToProps = (state: AppState) => ({
  isOpen: state.auth.showModal,
  message: state.auth.modalMessage
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ hideModal }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PopUpMessage)
