import React from 'react'

const CloseIcon = props => (
  <svg
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity="0.5"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.4967 0.256793C1.15431 -0.0855977 0.599183 -0.0855975 0.256793 0.256793C-0.0855975 0.599183 -0.0855976 1.15431 0.256793 1.4967L5.81811 7.05801L0.37283 12.5033C0.0304398 12.8457 0.0304399 13.4008 0.37283 13.7432C0.715221 14.0856 1.27035 14.0856 1.61274 13.7432L7.05801 8.29792L12.4506 13.6905C12.793 14.0329 13.3481 14.0329 13.6905 13.6905C14.0329 13.3481 14.0329 12.793 13.6905 12.4506L8.29792 7.05801L13.8066 1.54937C14.1489 1.20698 14.1489 0.65186 13.8066 0.309469C13.4642 -0.0329208 12.909 -0.032921 12.5667 0.309469L7.05801 5.81811L1.4967 0.256793Z"
      fill="black"
    />
  </svg>
)

export default CloseIcon
