import React from 'react'

const SupportIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <defs />
    <defs>
      <linearGradient
        id="gq7ba"
        x1="-7.25"
        x2="16.47"
        y1="15.27"
        y2=".89"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#bc9465" />
        <stop offset="1" stopColor="#ecc576" />
      </linearGradient>
      <linearGradient
        id="gq7bc"
        x1="6.63"
        x2="8.01"
        y1="11.48"
        y2="10.64"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#bc9465" />
        <stop offset="1" stopColor="#ecc576" />
      </linearGradient>
      <linearGradient
        id="gq7be"
        x1="3.59"
        x2="9.15"
        y1="8.03"
        y2="3.78"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#bc9465" />
        <stop offset="1" stopColor="#ecc576" />
      </linearGradient>
      <clipPath id="gq7bb">
        <path
          fill="#fff"
          d="M-.115 20.108V-.108h20.23v20.216zM20 13.535a6.48 6.48 0 00-4.734-6.23C15.092 3.248 11.736 0 7.636 0 3.427 0 0 3.426 0 7.637c0 1.372.365 2.708 1.059 3.881l-1.03 3.727 3.726-1.03a7.61 7.61 0 003.55 1.05A6.48 6.48 0 0013.535 20c1.164 0 2.295-.31 3.29-.899l3.147.87-.87-3.146a6.442 6.442 0 00.898-3.29zm-16.06-.588l-2.231.618.617-2.231-.14-.22a6.443 6.443 0 01-1.014-3.477c0-3.565 2.9-6.465 6.465-6.465 3.564 0 6.465 2.9 6.465 6.465s-2.9 6.465-6.465 6.465c-1.237 0-2.44-.35-3.477-1.014zm14.351 5.344l-1.655-.458-.221.144a5.273 5.273 0 01-2.88.851 5.306 5.306 0 01-5.018-3.606 7.651 7.651 0 006.706-6.705 5.307 5.307 0 013.605 5.018 5.273 5.273 0 01-.851 2.88l-.144.22z"
        />
      </clipPath>
      <clipPath id="gq7bd">
        <path fill="#fff" d="M7.05 10.59h1.172v1.172H7.05z" />
      </clipPath>
      <clipPath id="gq7bf">
        <path
          fill="#fff"
          d="M8.806 5.864c0 .333-.136.64-.382.865l-1.376 1.26v1.43H8.22v-.914l.995-.912a2.35 2.35 0 00.762-1.73A2.346 2.346 0 007.634 3.52 2.346 2.346 0 005.29 5.864h1.172a1.173 1.173 0 012.344 0z"
        />
      </clipPath>
    </defs>
    <path
      fill="url(#gq7ba)"
      d="M19.101 16.825l.87 3.147-3.146-.87a6.442 6.442 0 01-3.29.898 6.48 6.48 0 01-6.23-4.734 7.61 7.61 0 01-3.55-1.051l-3.727 1.03 1.031-3.727A7.613 7.613 0 010 7.637C0 3.426 3.426 0 7.637 0c4.1 0 7.455 3.248 7.629 7.305A6.48 6.48 0 0120 13.535c0 1.164-.31 2.295-.899 3.29zM4.16 13.088a6.443 6.443 0 003.477 1.014c3.565 0 6.465-2.9 6.465-6.465s-2.9-6.465-6.465-6.465a6.472 6.472 0 00-6.465 6.465c0 1.237.35 2.44 1.013 3.476l.141.22-.617 2.232 2.231-.618zm13.673 3.548l.144-.221a5.273 5.273 0 00.851-2.88 5.307 5.307 0 00-3.606-5.018 7.651 7.651 0 01-6.705 6.705 5.306 5.306 0 005.018 3.606 5.273 5.273 0 002.88-.851l.22-.144 1.656.458z"
    />
    <path
      fill="none"
      strokeMiterlimit="20"
      strokeWidth=".2"
      d="M19.101 16.825l.87 3.147-3.146-.87a6.442 6.442 0 01-3.29.898 6.48 6.48 0 01-6.23-4.734 7.61 7.61 0 01-3.55-1.051l-3.727 1.03 1.031-3.727A7.613 7.613 0 010 7.637C0 3.426 3.426 0 7.637 0c4.1 0 7.455 3.248 7.629 7.305A6.48 6.48 0 0120 13.535c0 1.164-.31 2.295-.899 3.29zM4.16 13.088a6.443 6.443 0 003.477 1.014c3.565 0 6.465-2.9 6.465-6.465s-2.9-6.465-6.465-6.465a6.472 6.472 0 00-6.465 6.465c0 1.237.35 2.44 1.013 3.476l.141.22-.617 2.232 2.231-.618zm13.673 3.548l.144-.221a5.273 5.273 0 00.851-2.88 5.307 5.307 0 00-3.606-5.018 7.651 7.651 0 01-6.705 6.705 5.306 5.306 0 005.018 3.606 5.273 5.273 0 002.88-.851l.22-.144 1.656.458z"
      clipPath='url("#gq7bb")'
    />
    <path fill="url(#gq7bc)" d="M7.05 10.59h1.172v1.172H7.05z" />
    <path
      fill="none"
      strokeMiterlimit="20"
      strokeWidth="2"
      d="M7.05 10.59v0h1.172v1.172H7.05v0z"
      clipPath='url("#gq7bd")'
    />
    <g>
      <path
        fill="url(#gq7be)"
        d="M8.806 5.864c0 .333-.136.64-.382.865l-1.376 1.26v1.43H8.22v-.914l.995-.912a2.35 2.35 0 00.762-1.73A2.346 2.346 0 007.634 3.52 2.346 2.346 0 005.29 5.864h1.172a1.173 1.173 0 012.344 0z"
      />
      <path
        fill="none"
        strokeMiterlimit="20"
        strokeWidth="2"
        d="M8.806 5.864c0 .333-.136.64-.382.865l-1.376 1.26v1.43H8.22v0-.914 0l.995-.912a2.35 2.35 0 00.762-1.73A2.346 2.346 0 007.634 3.52 2.346 2.346 0 005.29 5.864h1.172a1.173 1.173 0 012.344 0z"
        clipPath='url("#gq7bf")'
      />
    </g>
  </svg>
)

export default SupportIcon
