import { ActionType } from 'typesafe-actions'
import * as actions from './actions'
import EnumHelpers from 'services/EnumHelpers'

export type InvestmentAction =
  | ActionType<typeof actions.setInvestmentTierId>
  | ActionType<typeof actions.changeInvestmentAmount>
  | ActionType<typeof actions.clearInvestmentAmountAndTier>
  | ActionType<typeof actions.checkProhibitedCountryForInvestment>
  | ActionType<typeof actions.fetchTierDetailsAsync>
  | ActionType<typeof actions.fetchPersonalInformationAsync>
  | ActionType<typeof actions.sendPersonalInformationAsync>
  | ActionType<typeof actions.sendVerificationAsync>
  | ActionType<typeof actions.fetchTrustInformationAsync>
  | ActionType<typeof actions.sendTrustInformationAsync>
  | ActionType<typeof actions.fetchEntityInformationAsync>
  | ActionType<typeof actions.fetchEntityInfoDetailsAsync>
  | ActionType<typeof actions.sendEntityInformationAsync>
  | ActionType<typeof actions.fetchIraInformationAsync>
  | ActionType<typeof actions.sendIraInformationAsync>
  | ActionType<typeof actions.setInvestmentStep>
  | ActionType<typeof actions.setPaymentMethod>
  | ActionType<typeof actions.setCreditCardInfo>
  | ActionType<typeof actions.setUSBankInfo>
  | ActionType<typeof actions.setErrors>
  | ActionType<typeof actions.changeInvestmentContact>
  | ActionType<typeof actions.changeInvestmentAddress>
  | ActionType<typeof actions.setVerification>
  | ActionType<typeof actions.setTrustInfo>
  | ActionType<typeof actions.setIRAInfo>
  | ActionType<typeof actions.setCompanyInfo>
  | ActionType<typeof actions.setEntityContactInfo>
  | ActionType<typeof actions.setEntityAddressInfo>
  | ActionType<typeof actions.clearInvestmentData>
  | ActionType<typeof actions.clearInvestmentPaymentError>
  | ActionType<typeof actions.changeUserSignature>
  | ActionType<typeof actions.switchViewMode>
  | ActionType<typeof actions.switchEventType>
  | ActionType<typeof actions.createSubscriptionAgreementAsync>
  | ActionType<typeof actions.sendEntityDocAsync>
  | ActionType<typeof actions.investPaymentCreditCardAsync>
  | ActionType<typeof actions.investPaymentACHAsync>
  | ActionType<typeof actions.investPaymentWireAsync>

export enum InvestmentActionTypes {
  SET_INVESTMENT_TIER_ID = '@@investment/SET_INVESTMENT_TIER_ID',
  CHANGE_INVESTMENT_AMOUNT = '@@investment/CHANGE_INVESTMENT_AMOUNT',
  CLEAR_INVESTMENT_AMOUNT_AND_TIER = '@@investment/CLEAR_INVESTMENT_AMOUNT_AND_TIER',
  CHECK_PROHIBITED_COUNTRY_REQUEST = '@@investment/CHECK_PROHIBITED_COUNTRY_REQUEST',
  CHECK_PROHIBITED_COUNTRY_SUCCESS = '@@investment/CHECK_PROHIBITED_COUNTRY_SUCCESS',
  CHECK_PROHIBITED_COUNTRY_FAIL = '@@investment/CHECK_PROHIBITED_COUNTRY_FAIL',
  FETCH_ENTITY_INFO_DETAILS_REQUEST = '@@investment/FETCH_ENTITY_INFO_DETAILS_REQUEST',
  FETCH_ENTITY_INFO_DETAILS_SUCCESS = '@@investment/FETCH_ENTITY_INFO_DETAILS_SUCCESS',
  FETCH_ENTITY_INFO_DETAILS_FAIL = '@@investment/FETCH_ENTITY_INFO_DETAILS_FAIL',
  FETCH_TIER_DETAILS_REQUEST = '@@investment/FETCH_TIER_DETAILS_REQUEST',
  FETCH_TIER_DETAILS_SUCCESS = '@@investment/FETCH_TIER_DETAILS_SUCCESS',
  FETCH_TIER_DETAILS_FAIL = '@@investment/FETCH_TIER_DETAILS_FAIL',
  FETCH_PERSONAL_INFORMATION_REQUEST = '@@investment/FETCH_PERSONAL_INFORMATION_REQUEST',
  FETCH_PERSONAL_INFORMATION_SUCCESS = '@@investment/FETCH_PERSONAL_INFORMATION_SUCCESS',
  FETCH_PERSONAL_INFORMATION_FAIL = '@@investment/FETCH_PERSONAL_INFORMATION_FAIL',
  SEND_VERIFICATION_REQUEST = '@@investment/SEND_VERIFICATION_REQUEST',
  SEND_VERIFICATION_SUCCESS = '@@investment/SEND_VERIFICATION_SUCCESS',
  SEND_VERIFICATION_FAIL = '@@investment/SEND_VERIFICATION_FAIL',
  SEND_PERSONAL_INFORMATION_REQUEST = '@@investment/SEND_PERSONAL_INFORMATION_REQUEST',
  SEND_PERSONAL_INFORMATION_SUCCESS = '@@investment/SEND_PERSONAL_INFORMATION_SUCCESS',
  SEND_PERSONAL_INFORMATION_FAIL = '@@investment/SEND_PERSONAL_INFORMATION_FAIL',
  FETCH_TRUST_INFORMATION_REQUEST = '@@investment/FETCH_TRUST_INFORMATION_REQUEST',
  FETCH_TRUST_INFORMATION_SUCCESS = '@@investment/FETCH_TRUST_INFORMATION_SUCCESS',
  FETCH_TRUST_INFORMATION_FAIL = '@@investment/FETCH_TRUST_INFORMATION_FAIL',
  SEND_TRUST_INFORMATION_REQUEST = '@@investment/SEND_TRUST_INFORMATION_REQUEST',
  SEND_TRUST_INFORMATION_SUCCESS = '@@investment/SEND_TRUST_INFORMATION_SUCCESS',
  SEND_TRUST_INFORMATION_FAIL = '@@investment/SEND_TRUST_INFORMATION_FAIL',
  FETCH_ENTITY_INFORMATION_REQUEST = '@@investment/FETCH_ENTITY_INFORMATION_REQUEST',
  FETCH_ENTITY_INFORMATION_SUCCESS = '@@investment/FETCH_ENTITY_INFORMATION_SUCCESS',
  FETCH_ENTITY_INFORMATION_FAIL = '@@investment/FETCH_ENTITY_INFORMATION_FAIL',
  SEND_ENTITY_INFORMATION_REQUEST = '@@investment/SEND_ENTITY_INFORMATION_REQUEST',
  SEND_ENTITY_INFORMATION_SUCCESS = '@@investment/SEND_ENTITY_INFORMATION_SUCCESS',
  SEND_ENTITY_INFORMATION_FAIL = '@@investment/SEND_ENTITY_INFORMATION_FAIL',
  FETCH_IRA_INFORMATION_REQUEST = '@@investment/FETCH_IRA_INFORMATION_REQUEST',
  FETCH_IRA_INFORMATION_SUCCESS = '@@investment/FETCH_IRA_INFORMATION_SUCCESS',
  FETCH_IRA_INFORMATION_FAIL = '@@investment/FETCH_IRA_INFORMATION_FAIL',
  SEND_IRA_INFORMATION_REQUEST = '@@investment/SEND_IRA_INFORMATION_REQUEST',
  SEND_IRA_INFORMATION_SUCCESS = '@@investment/SEND_IRA_INFORMATION_SUCCESS',
  SEND_IRA_INFORMATION_FAIL = '@@investment/SEND_IRA_INFORMATION_FAIL',
  SET_INVESTMENT_STEP = '@@investment/SET_INVESTMENT_STEP',
  SET_PAYMENT_METHOD = '@@investment/SET_PAYMENT_METHOD',
  SET_CREDIT_CARD_INFO = '@@investment/SET_CREDIT_CARD_INFO',
  SET_US_BANK_INFO = '@@investment/SET_US_BANK_INFO',
  SET_ERRORS = '@@investment/SET_ERRORS',
  CHANGE_INVESTMENT_CONTACT = '@@investment/CHANGE_INVESTMENT_CONTACT',
  CHANGE_INVESTMENT_ADDRESS = '@@investment/CHANGE_INVESTMENT_ADDRESS',
  SET_VERIFICATION = '@@investment/SET_VERIFICATION',
  SET_TRUST_INFO = '@@investment/SET_TRUST_INFO',
  SET_IRA_INFO = '@@investment/SET_IRA_INFO',
  SET_COMPANY_INFO = '@@investment/SET_COMPANY_INFO',
  SET_ENTITY_CONTACT_INFO = '@@investment/SET_ENTITY_CONTACT_INFO',
  SET_ENTITY_ADDRESS_INFO = '@@investment/SET_ENTITY_ADDRESS_INFO',
  CLEAR_INVESTMENT_DATA = '@@investment/CLEAR_INVESTMENT_DATA',
  CLEAR_INVESTMENT_PAYMENT_ERROR = '@@investment/CLEAR_INVESTMENT_PAYMENT_ERROR',
  CHANGE_USER_SIGNATURE = '@@investment/CHANGE_USER_SIGNATURE',
  SWITCH_VIEW_MODE = '@@investment/SWITCH_VIEW_MODE',
  SWITCH_EVENT_TYPE = '@@investment/SWITCH_EVENT_TYPE',
  CREATE_SUBSCRIPTION_AGREEMENT_REQUEST = '@@investment/CREATE_SUBSCRIPTION_AGREEMENT_REQUEST',
  CREATE_SUBSCRIPTION_AGREEMENT_SUCCESS = '@@investment/CREATE_SUBSCRIPTION_AGREEMENT_SUCCESS',
  CREATE_SUBSCRIPTION_AGREEMENT_FAIL = '@@investment/CREATE_SUBSCRIPTION_AGREEMENT_FAIL',
  CREATE_SIGNATURE_REQUEST = '@@investment/CREATE_SIGNATURE_REQUEST',
  CREATE_SIGNATURE_SUCCESS = '@@investment/CREATE_SIGNATURE_SUCCESS',
  CREATE_SIGNATURE_FAIL = '@@investment/CREATE_SIGNATURE_FAIL',
  SEND_ENTITY_DOC_REQUEST = '@@investment/SEND_ENTITY_DOC_REQUEST',
  SEND_ENTITY_DOC_SUCCESS = '@@investment/SEND_ENTITY_DOC_SUCCESS',
  SEND_ENTITY_DOC_FAIL = '@@investment/SEND_ENTITY_DOC_FAIL',
  INVEST_PAYMENT_CREDIT_CARD_REQUEST = '@@investment/INVEST_PAYMENT_CREDIT_CARD_REQUEST',
  INVEST_PAYMENT_CREDIT_CARD_SUCCESS = '@@investment/INVEST_PAYMENT_CREDIT_CARD_SUCCESS',
  INVEST_PAYMENT_CREDIT_CARD_FAIL = '@@investment/INVEST_PAYMENT_CREDIT_CARD_FAIL',
  INVEST_PAYMENT_ACH_REQUEST = '@@investment/INVEST_PAYMENT_ACH_REQUEST',
  INVEST_PAYMENT_ACH_SUCCESS = '@@investment/INVEST_PAYMENT_ACH_SUCCESS',
  INVEST_PAYMENT_ACH_FAIL = '@@investment/INVEST_PAYMENT_ACH_FAIL',
  INVEST_PAYMENT_WIRE_REQUEST = '@@investment/INVEST_PAYMENT_WIRE_REQUEST',
  INVEST_PAYMENT_WIRE_SUCCESS = '@@investment/INVEST_PAYMENT_WIRE_SUCCESS',
  INVEST_PAYMENT_WIRE_FAIL = '@@investment/INVEST_PAYMENT_WIRE_FAIL'
}

export interface InvestmentState {
  entity_id: string
  investor_id: string
  annual_income: string
  net_worth: string
  tierDetails: ITierAmountDetails
  currentStep: number
  tierId: number
  investmentAmount: number
  errors: {
    [k: string]: string
  }
  contact: IContact & { isFetching: boolean }
  address: IAddress
  payment: {
    selectedMethod: IPaymentMethods
    token?: string
    creditCard: ICreditCard
    USBank: IUSBank
  }
  verification: IVerification
  trustInfo: ITrust
  iraInfo: IIRA
  companyInfo: ICompany
  entityContactInfo: IContact
  entityAddressInfo: IAddress
  companiesList: {
    [key: string]: IEntityInfoResp & { details?: IEntityInfoDetails }
  }
  userSignature: string
  subscriptionAgreement: ISubscriptionAgreementResp
  viewMode: IViewMode
  eventType: IEventType
  payment_error: string
}

export enum EntityType {
  INDIVIDUAL = 'Individual',
  TRUST = 'Trust',
  ENTITY = 'Entity', // ICompany
  IRA = 'IRA'
}

export interface IIncome {
  annual_income: string
  net_worth: string
  [k: string]: any
}

export interface ITierAmountDetails {
  tier_name: string
  tier_image_url: string
  influencer_name: string
  minimum_amount: number
  most_popular_amount: number
  maximum_amount: number
  price_per_share: number
  security_type: number
  [k: string]: any
}

export interface IPersonalInfo {
  first_name: string
  date_of_birth: string
  tax_id_number: string
  last_name: string
  email: string
  phone: string
  userAddress: IEntityAddress
  changed_id?: string
}

export interface IPersonalInfoResp extends IPersonalInfo {
  id: string
  investor_id: string
  name: string
  annual_income: string
  net_worth: string
}

export interface IProhibitedCountry {
  iso_code: string
  city_name: string
}

export interface IProhibitedCountryResp {
  restricted: boolean
  type: string
  location: boolean
  isEntity: boolean
}

export interface IEntityInfoDetails {
  id: string
  entity_name: string
  entity_tax_id: string
  state: string
  entityAddress: IEntityAddress
  contact: IContactPerson
  investor_id: string
}

export interface IEntityInfoResp {
  id: string
  name: string
}

export interface IVerificationInfo {
  entity_id: string
  date_of_birth: string // "1980-01-01"
}

export interface ITrustInfo {
  person_id: string
  trust_name: string
  trust_tax_id: string
  state: string
  custodian: IContactPerson
  trustAddress: IEntityAddress
  changed_id?: string
}

export interface ITrustInfoResp extends ITrustInfo {
  id: string
  investor_id: string
}

export interface IEntityInfo {
  person_id: string
  entity_name: string
  entity_tax_id: string
  state: string
  entityAddress: IEntityAddress
  contact: IContactPerson
  changed_id?: string
}

export interface IIRAInfo {
  person_id: string
  vesting_name: string
  ira_tax_id: string
  iraAddress: IEntityAddress
  state: string
  contact: IContactPerson
  changed_id?: string
}

export interface IIRAInfoResp extends IIRAInfo {
  id: string
  investor_id: string
}

export interface IContactPerson {
  full_name: string
  email: string
  phone: string
}

export interface IEntityAddress {
  address: string
  additional_address: string
  city: string
  state: string
  postal_code: string
  country: string
}

export interface IContact {
  first_name: string
  last_name: string
  email: string
  phone: string
  phone_code: string
  changed_fields?: string[]
  name?: string
  full_name?: string
  id?: string
  investor_id?: string
  [k: string]: any
}

export interface IAddress {
  country: string
  street: string
  additional_address: string
  city: string
  state: string
  postal_code: string
  [k: string]: any
}

export interface ICreditCard {
  card_number: string
  expiration_date: string
  cvv: string
  cardholder: string
  zip: string
  token?: string
  [k: string]: any
}

export interface IPayment {
  tier_id: string
  nc_account_id: string // id from responses personal/trust/company/ira
  amount: string
  ip_address: string
}

export interface ICreditCardToken extends IPayment {
  credit_card_number: string
  credit_card_exp_month: string
  credit_card_exp_year: string
  credit_card_cvv: string
  billing_name: string
}

export interface IPaymentACH extends IPayment {
  routing_number: string
  account_number: string
  name_on_account: string
}

export interface IUSBank {
  routing_number: string
  account_number: string
  name_on_account: string
  account_type: AccountType.CHECKING | AccountType.SAVINGS | null
  [k: string]: any
}

export interface IACHBank extends IUSBank {
  entity_id: string
  ip_address: string
  literal: string
  id?: string
}

export interface IVerification {
  date_of_birth: string
  citizenUSA: boolean
  ssn?: string
  passport?: any // File | FileList | never[]
  [k: string]: any
}

export interface ITrust {
  trust_name: string
  trust_tax_id: string
  [k: string]: any
}

export interface IIRA {
  vesting_name: string
  ira_tax_id: string
  [k: string]: any
}

export interface ICompanyListItem {
  id: string
  name: string
}

export interface ICompany {
  entity_name: string
  entity_tax_id: string
  state?: string
  [k: string]: any
}

export interface IEntityDocument {
  entity_document: any
  name?: string
}

export enum AccountType {
  CHECKING = 'checking',
  SAVINGS = 'savings'
}

export interface ISubscriptionAgreement {
  tier_id: string
}

export interface ISubscriptionAgreementResp {
  templateName: string
  templateUrl: string
  archived_pdf_url: null
  body_html: string
  created_at: string
  data: object
  electronic_signatures: Array<{
    anchor_id: string
    company: null
    data: []
    document_url: string
    email: null
    id: string
    literal: null
    name: null
    object: string
    signable: boolean
    signed: boolean
    signed_at: null
    title: null
    url: string
  }>
  id: string
  investment: null
  issuer_signed: boolean
  object: string
  offering_url: string
  signed: boolean
  signing_links: object
  subscriber_signed: boolean
  template_url: string
  updated_at: string
  url: string
}

export interface IElectronicSignature {
  id: string
  email: string
  ip_address: string
  literal: string
  name: string
  user_agent: string
}

export const CardFields: { [k: string]: string } = {
  card_number: 'Card Number',
  expiration_date: 'Expiration Date',
  cvv: 'CVV',
  cardholder: 'Cardholder Name'
}

export const USBankFields: { [k: string]: string } = {
  routing_number: 'Routing Number',
  account_number: 'Account Number',
  account_type: 'Account Type',
  name_on_account: 'Name on Account'
}

export const ContactFields: any = {
  first_name: 'First Name',
  last_name: 'Last Name',
  email: 'Email',
  phone: 'Phone',
  phone_code: 'Phone Code'
}

export const AddressFields: { [k: string]: string } = {
  country: 'Country',
  state: 'State/Region',
  city: 'City',
  street: 'Street',
  additional_address: 'Room',
  postal_code: 'Postal Code'
}

export const IncomeFields: { [k: string]: string } = {
  annual_income: 'Annual Income',
  net_worth: 'Net worth'
}

export const TrustFields: { [k: string]: string } = {
  trust_name: 'Trust Name',
  trust_tax_id: 'Trust Tax ID',
  state: 'State of Formation (if US based)'
}

export const IRAFields: { [k: string]: string } = {
  vesting_name: 'Vesting Name (IRA company, etc.)',
  ira_tax_id: 'IRA Tax ID',
  state: 'State of Formation (if US based)'
}

export const CompanyFields: { [k: string]: string } = {
  entity_name: 'Entity Name',
  entity_tax_id: 'Entity Tax ID (EIN, etc.)',
  state: 'State of Formation (if US based)'
}

export const AnnualIncomeTooltip: { [k: string]: string } = {
  annual_income:
    'Annual income is the amount of income you earn in one fiscal year. Your annual income includes everything from your yearly salary to bonuses, commissions, overtime, and tips earned.',
  net_worth:
    'Your net worth is what you own minus what you owe. It`s the total value of all your assets—including your house, cars, investments, and cash—minus your liabilities (things like credit card debt, student loans, and what you still owe on your mortgage).'
}

export enum IPaymentMethods {
  CARD = 'Сard',
  US_BANK = 'ACH',
  WIRE_TRANSFER = 'Wire Transfer'
}

export enum IPaymentLimit {
  CARD = 5000,
  US_BANK = 100000,
  WIRE_TRANSFER = 750
}

export const VerificationFields: { [k: string]: string } = {
  ssn: 'Social Security Number (SSN)',
  date_of_birth: 'Date of Birth',
  passport: 'Passport'
}

export const paymentMethods = EnumHelpers.values(IPaymentMethods)

export enum IViewMode {
  LIST = 'list',
  GRID = 'grid'
}

export enum IEventType {
  CURRENT = 'current',
  PAST = 'past'
}

export const ERROR_MESSAGE = 'Something went wrong. Please try again.'
