import React, { FC, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  loadMoreInfluencers,
  searchInfluencers,
  handleSearchFieldChange
} from 'redux/events/operations'
import {
  selectEvents,
  selectInfluencers,
  selectInfluencerSearchField
} from 'redux/events/selectors'
import { clearInfluencerList } from 'redux/events/actions'
import { debounce } from 'utils/debounce'

import Search, { SearchVariant } from 'components/UI/Search'
import Loader from 'src/components/UI/Loader'
import InfiniteScroll from 'components/common/InfiniteScroll'
import InfluencerCard from '../InfluencerCard'

import { SearchWrapper, TextWrapper, InfluencersWrapper } from './styles'

const InfluencerSearch: FC = () => {
  const dispatch = useDispatch()
  const searchFieldValue = useSelector(selectInfluencerSearchField)
  const { data: influencerList } = useSelector(selectInfluencers)
  const { isFetchingInfluencers } = useSelector(selectEvents)

  useEffect(() => {
    if (!influencerList.length) {
      dispatch(searchInfluencers(searchFieldValue))
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    return () => {
      dispatch(clearInfluencerList())
    }
  }, [dispatch])

  const handleSearch = useRef(
    debounce((value: string) => dispatch(handleSearchFieldChange(value)), 500)
  )

  const handleClear = () => handleSearch.current('')

  const handleOnChange = (name: string, value: string) =>
    handleSearch.current(value)

  const onLoadMore = () =>
    !isFetchingInfluencers && dispatch(loadMoreInfluencers(searchFieldValue))

  const renderMessage = () => {
    return (
      <TextWrapper>
        {isFetchingInfluencers ? (
          <Loader />
        ) : (
          <p>No results found. Try another request.</p>
        )}
      </TextWrapper>
    )
  }

  return (
    <>
      <SearchWrapper>
        <Search
          variant={SearchVariant.Light}
          value={searchFieldValue}
          onChange={handleOnChange}
          onClear={handleClear}
          placeholder={'Search'}
        />
      </SearchWrapper>
      {influencerList.length ? (
        <>
          <InfiniteScroll onLoadMore={onLoadMore}>
            <InfluencersWrapper style={{ width: '100%' }}>
              {influencerList.map(influencer => (
                <InfluencerCard influencer={influencer} key={influencer.id} />
              ))}
            </InfluencersWrapper>
          </InfiniteScroll>
          {isFetchingInfluencers && <Loader />}
        </>
      ) : (
        renderMessage()
      )}
    </>
  )
}

export default InfluencerSearch
