import styled from 'styled-components'
import { Button } from 'components/UI/Button'
import { media } from 'styled-bootstrap-grid'

export const AmountWrapper = styled.div`
  .slick-next,
  .slick-prev {
    background: unset;
    width: 2rem;
    height: 2rem;
    ::before {
      width: 10px;
      height: 10px;
      top: 10px;
      border-right: 2px solid ${p => p.theme.colors.fuchsia};
      border-top: 2px solid ${p => p.theme.colors.fuchsia};
    }
    ::after {
      content: '';
      width: 2rem;
      height: 2rem;
      position: absolute;
      top: 50%;
      right: -20px;
      border: 2px solid ${p => p.theme.colors.grey};
      border-radius: 50%;
      -webkit-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
      transform: translate(0, -50%);
      background-color: #fff;
    }
    &.slick-disabled {
      ::after {
        opacity: 0;
      }
    }
  }
  .slick-next {
    ::before {
      right: -7px;
    }
  }
  .slick-prev {
    ::before {
      left: -7px;
    }
    ::after {
      left: -20px;
    }
  }
  .slick-slide {
    padding: 5px;
    > div {
      height: 100%;
    }
  }
  .slick-track {
    display: flex !important;
    margin: unset;
  }

  .slick-slide {
    height: auto;
  }
`

export const AmountButton = styled(Button).attrs(() => ({
  variant: 'clear'
}))<{
  isActive?: boolean
}>`
  display: flex !important;
  flex-direction: column;
  min-height: 120px;
  height: 100%;
  border-radius: 12px;
  border: 1px solid
    ${p => (p.isActive ? '#ac51bc' : 'rgba(255, 255, 255, 0.5)')};
  color: ${p => (p.isActive ? '#ac51bc' : p.theme.colors.black)};
  font-family: Inter;
  background: rgba(255, 255, 255, 0.5);
  min-width: 126px;
  margin-right: 10px;
  padding: 10px;
  &:last-of-type {
    margin-right: 0;
  }
  ${media.md`
    margin-right: 15px;
  `}
  transition: border, color 0.2s ease;
`

export const AmountText = styled.span`
  color: ${p => p.theme.colors.black};
  font-family: Inter;
  font-size: 0.875rem;
  font-weight: 500;
  text-align: left;
`

export const AmountDescription = styled(AmountText)`
  margin-top: 10px;
  display: -webkit-box;
  font-weight: 300;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
`

export const Amount = styled(AmountText)<{
  amount: number
}>`
  font-weight: 900;
  font-size: ${p =>
    String(p.amount).length >= 9
      ? '0.9rem'
      : String(p.amount).length >= 7
      ? '1rem'
      : String(p.amount).length >= 5
      ? '1.3rem'
      : '1.75rem'};

  padding-bottom: 15px;
`
export const InputWrapper = styled.div<{
  isShown: string
}>`
  position: relative;
  margin-top: 25px;

  display: flex;
  flex-wrap: wrap;

  span {
    display: ${p => (p.isShown ? 'none' : 'block')};

    font-size: 3.125rem;
    font-weight: 700;
    font-family: Inter;
    color: ${p => p.theme.colors.grey};

    ${media.md`
    font-size: 4.063rem;
  `}
  }
  > div {
    width: 90%;
  }
  label {
    width: 100%;
  }
`

export const InputPlaceholder = styled.div`
  display: flex;
`
export const Label = styled.label`
  color: ${p => p.theme.colors.black};
  font-size: 0.938rem;
  font-weight: 900;
  font-family: Inter;

  ${media.md`
    font-size: 1.125rem;
    font-weight: 600;
  `}
`
export const DropdownWrapper = styled.div`
  width: 100%;
  padding: 15px 0;
  ${media.md`
     padding: 29px 0;
  `}
`
export const FooterWrapper = styled.div`
  padding: 17px 20px;

  ${media.md`
    padding: 0 35px 25px;
  `}
`

export const Title = styled.h2`
  font-weight: 900;
  font-size: 1.875rem;
  font-family: Inter;
  color: ${p => p.theme.colors.black};
  ${media.md`
    font-weight: 800;
    font-size: 2.875rem;
  `}
`
export const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 230px;
  ${media.md`
    height: 250px;
  `}
`

export const Message = styled.p`
  color: ${p => p.theme.colors.black};
  font-size: 0.938rem;
  font-family: Inter;
  min-height: 40px;
  margin-bottom: 12px;
  ${media.md`
     margin-bottom: 25px;
  `}
`

export const ErrorMessage = styled.p`
  color: #ff3a44;
  font-size: 0.813rem;
  font-family: Inter;
  min-height: 1rem; //prevent modal jumping
`
