import React, { FC } from 'react'
import { Col, Container, Row } from 'styled-bootstrap-grid'
import { useDispatch, useSelector } from 'react-redux'
import { selectEventType, selectViewMode } from 'redux/investment/selectors'
import { IEventType, IViewMode } from 'redux/investment/types'
import { switchEventType, switchViewMode } from 'redux/investment/actions'
import { GridIcon, ListIcon } from 'src/components/icons'

import {
  HeaderWrapper,
  OutlineButton,
  Text,
  Title,
  ViewSwitchWrapper
} from './styles'

interface EventHeaderProps {
  title: string
  subtitle: string
  viewControl?: boolean
}
const EventPageHeader: FC<EventHeaderProps> = ({
  title,
  subtitle,
  viewControl = true
}) => {
  const dispatch = useDispatch()
  const viewMode = useSelector(selectViewMode)
  const eventType = useSelector(selectEventType)

  const switchEventList = () => {
    dispatch(switchEventType())
  }

  const changeViewMode = () => {
    dispatch(switchViewMode())
  }

  return (
    <HeaderWrapper>
      <Title dangerouslySetInnerHTML={{ __html: title }} />
      <Text>{subtitle}</Text>
      {viewControl && (
        <Container>
          <Row>
            <Col md={3} lg={4} />
            <Col sm={12} md={6} lg={4}>
              <OutlineButton
                onClick={switchEventList}
                name={IEventType.CURRENT}
                active={eventType === IEventType.CURRENT}
              >
                {IEventType.CURRENT}
              </OutlineButton>
              <OutlineButton
                onClick={switchEventList}
                name={IEventType.PAST}
                active={eventType === IEventType.PAST}
              >
                {IEventType.PAST}
              </OutlineButton>
            </Col>
            <Col md={3} lg={4}>
              <ViewSwitchWrapper>
                <OutlineButton
                  onClick={changeViewMode}
                  name={IViewMode.LIST}
                  active={viewMode === IViewMode.LIST}
                >
                  <ListIcon />
                </OutlineButton>
                <OutlineButton
                  onClick={changeViewMode}
                  name={IViewMode.GRID}
                  active={viewMode === IViewMode.GRID}
                >
                  <GridIcon />
                </OutlineButton>
              </ViewSwitchWrapper>
            </Col>
          </Row>
        </Container>
      )}
    </HeaderWrapper>
  )
}

export default EventPageHeader
