import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import Button from 'components/UI/Button'

export const Form = styled.form`
  width: 100%;
  max-width: 28.75rem;
  margin: 0 auto;
  padding: 2.5rem 0;

  label,
  span {
    font-size: 0.75rem;
  }

  span {
    color: #8c8c8c;
  }

  ${media.md`  
    padding: 3.75rem 0;`}

  ${media.lg`  
    padding: 8.375rem 0;`}
`
export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  input {
    padding-right: 2.5rem;
    text-overflow: ellipsis;
  }

  button {
    position: absolute;
    right: 1.25rem;
    bottom: 2.5rem;
  }
`
export const SubmitButton = styled(Button).attrs(p => ({
  variant: 'default',
  type: 'submit'
}))`
  margin: 3.75rem auto 0;
  padding: 1.125rem 4.125rem;
  font-size: 0.875rem;
`
