import React from 'react'

const CommentIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="22"
    viewBox="0 0 23 22"
    {...props}
  >
    <path
      fill="#fff"
      d="M23 9.957c0 5.171-5.103 9.365-11.396 9.365-6.295 0-11.398-4.194-11.398-9.366S5.309.59 11.604.59C17.897.59 23 4.784 23 9.956z"
    />
    <path
      fill="#fff"
      d="M11.976 19.071s-.756 3.392 1.647 1.975c2.403-1.417 6.412-4.319 6.594-5.109.183-.79-8.241 3.134-8.241 3.134z"
    />
    <path d="M7.854 9.734a1.747 1.747 0 11-3.493 0 1.747 1.747 0 013.494 0z" />
    <path
      fill="none"
      stroke="#000"
      strokeMiterlimit="20"
      strokeWidth=".25"
      d="M7.854 9.734a1.747 1.747 0 11-3.493 0 1.747 1.747 0 013.494 0z"
    />
    <path d="M13.507 9.734a1.747 1.747 0 11-3.494 0 1.747 1.747 0 013.494 0z" />
    <path
      fill="none"
      stroke="#000"
      strokeMiterlimit="20"
      strokeWidth=".25"
      d="M13.507 9.734a1.747 1.747 0 11-3.494 0 1.747 1.747 0 013.494 0z"
    />
    <path d="M18.845 9.734a1.746 1.746 0 11-3.492.003 1.746 1.746 0 013.492-.003z" />
    <path
      fill="none"
      stroke="#000"
      strokeMiterlimit="20"
      strokeWidth=".25"
      d="M18.845 9.734a1.746 1.746 0 11-3.492.003 1.746 1.746 0 013.492-.003z"
    />
  </svg>
)

export default CommentIcon
