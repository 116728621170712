import React, { FC, useState } from 'react'
import { internationalPhoneCodes } from 'utils/countryCodes'
import { parsePhone } from 'utils/helpers'
import OutsideClick from 'components/UI/OutsideClick'
import Flag from './Flag'
import {
  Control,
  SelectedValue,
  Option,
  Options,
  Search,
  DropdownWrapper,
  FilterSearch,
  NoOption
} from './styles'

interface Props {
  value: string
  onChange: any
  optionsList?: any
  isValid?: boolean
}

interface IPhoneCodes {
  calling_code: string
  code: string
  name: string
}

export function getPhoneCountryInfo(
  phone: string,
  countryCodes: IPhoneCodes[] = [],
  currentCountry = ''
): IPhoneCodes {
  const defaultCode = {
    name: 'United States',
    code: 'US',
    calling_code: '+1'
  }
  const phoneParsed = parsePhone(phone)
  const countryCodeFromParsed = phoneParsed && phoneParsed.country
  const c = countryCodeFromParsed || currentCountry
  if (countryCodeFromParsed) {
    return countryCodes.find(country => country.code === c) || defaultCode
  }

  const countryFromPhone = countryCodes.find(country =>
    currentCountry
      ? phone.includes(country.calling_code) && country.code === currentCountry
      : phone.includes(country.calling_code)
  )

  if (countryFromPhone) {
    return countryFromPhone
  }

  return defaultCode
}

export const DropDownPhone: FC<Props> = ({
  value,
  onChange,
  children,
  isValid = true,
  optionsList = internationalPhoneCodes
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [options, setOptions] = useState<IPhoneCodes[]>(optionsList)
  const [query, setQuery] = useState<string>('')
  const [currentCountry, setCurrentCountry] = useState('US')
  const { code, calling_code } = getPhoneCountryInfo(
    value,
    optionsList,
    currentCountry
  )

  const toggleList = (e: any) => {
    setIsOpen(!isOpen)
  }
  const changeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const targetValue = e.target.value
    setIsOpen(true)
    onChange(targetValue)
  }

  const handleClose = () => {
    if (isOpen) {
      setIsOpen(false)
      setOptions(optionsList)
      setQuery('')
    }
  }

  const filterOption = (e: React.ChangeEvent<HTMLInputElement>) => {
    const targetValue = e.target.value

    setQuery(targetValue)
    setOptions(
      optionsList.filter(
        (option: IPhoneCodes) =>
          option.calling_code.includes(targetValue.trim().toLowerCase()) ||
          option.name.toLowerCase().includes(targetValue.trim().toLowerCase())
      )
    )
  }

  return (
    <div style={{ display: 'flex' }}>
      <OutsideClick onOutsideClick={handleClose}>
        <Control isValid={isValid}>
          <SelectedValue onClick={toggleList}>
            <Flag className={`flag-${code.toLowerCase()}`} standalone={true} />
            <Search
              name={'phone_code'}
              type={'text'}
              value={calling_code}
              onChange={changeSearch}
              maxLength={5}
              readOnly={true}
            />
          </SelectedValue>
        </Control>
        <DropdownWrapper isOpen={isOpen}>
          <FilterSearch
            name={'filter_search'}
            type={'text'}
            onChange={filterOption}
            value={query}
          />
          <Options>
            {options.map((option: IPhoneCodes) => {
              const { calling_code, name, code } = option
              const handleSelect = () => {
                setCurrentCountry(code)
                onChange(calling_code)
                handleClose()
              }
              return (
                <Option key={code} data-code={code} onClick={handleSelect}>
                  <Flag
                    className={`flag-${code.toLowerCase()}`}
                    standalone={true}
                  />{' '}
                  {calling_code} {name}
                </Option>
              )
            })}
            {!options.length && <NoOption>No options</NoOption>}
          </Options>
        </DropdownWrapper>
      </OutsideClick>
      {children}
    </div>
  )
}
