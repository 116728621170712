import React, { FC } from 'react'

import logoP from './logoP.png'
import logoB from './logoB.png'

export interface LogoIconProps {
  width: number | string
  height: number | string
  marginBottom: number | string
  light: boolean
}

const LogoIcon: FC<LogoIconProps> = ({
  width,
  height,
  marginBottom = 0,
  light = true
}) => (
  <img
    src={light ? logoP : logoB}
    alt="logo"
    style={{ width, height, marginBottom, objectFit: 'contain' }}
  />
)

export default LogoIcon
