import styled from 'styled-components'
import { theme } from 'styled/theme'

export const Wrapper = styled.div`
  .slick-list {
    border-radius: 1rem;
  }

  .slick-slide {
    padding: 0 1rem;
  }

  .slick-active {
    padding-left: 0;
  }

  .slick-slide div img {
    border-radius: 1rem;
  }
`

export const Slide = styled.img`
  max-height: 55rem;
  height: auto;
`

export const configArrowStyles = (props: any) => ({
  width: '3.5rem',
  height: '3.5rem',
  top: '50%',
  marginTop: '-1.75rem',
  padding: '1rem',
  backgroundColor: theme.colors.fuchsia,
  borderRadius: '50%',
  fill: props.className.includes('slick-disabled') && '#909293',
  transition: 'transform 0.3s',
  overflow: 'initial',
  transform:
    props.type === 'prev'
      ? props.currentSlide === 0
        ? 'translateX(calc(-50% + 1rem)) scale(0)'
        : 'translateX(-50%) scale(1)'
      : 'translateX(calc(50% - 1rem)) rotate(180deg)',
  boxShadow:
    props.type === 'prev'
      ? '3.5px 3.5px 15px rgba(0, 0, 0, 0.5)'
      : '3.5px -3.5px 15px rgba(0, 0, 0, 0.5)'
})
