import '@firebase/messaging'
import firebase from 'firebase/app'
import 'firebase/analytics'
import { saveSubscriptionToken } from 'redux/auth/operations'
import { loadNotifications } from 'redux/profile/operations'
import { store } from '../App'

// duplicated in firebase-messaging-sw.js
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}

class Firebase {
  private firebaseAnalytics: any
  private firebaseMessaging: any

  constructor() {
    this.initFirebase()
  }

  public logEvent(action: string, params?: { [key: string]: string | number }) {
    if (this.firebaseAnalytics) {
      this.firebaseAnalytics.logEvent(action, params)
    }
  }

  public init() {
    if ('Notification' in window && Notification.permission === 'denied') {
      return null
    }

    if (firebase.apps.length === 0) {
      return null
    }

    if (firebase.messaging.isSupported() && 'Notification' in window) {
      this.firebaseMessaging = firebase.messaging()
      return this.onRequestPermission()
    }
  }

  public deleteToken = () => {
    this.firebaseMessaging
      .getToken()
      .then((currentToken: any) => {
        this.firebaseMessaging
          .deleteToken(currentToken)
          .then(() => {
            console.log('Token deleted.')
            // @ts-ignore
            store.dispatch(saveSubscriptionToken(''))
          })
          .catch((err: any) => {
            console.log('Unable to delete token. ', err)
          })
      })
      .catch((err: any) => {
        console.log('Error retrieving Instance ID token. ', err)
      })
  }

  public setUserId = (id: number) => {
    this.firebaseAnalytics.setUserId(id)
  }

  private initFirebase() {
    if (
      process.env.REACT_APP_FIREBASE_PROJECT_ID &&
      firebase.apps.length === 0
    ) {
      firebase.initializeApp(firebaseConfig)
      this.firebaseAnalytics = firebase.analytics()
    }
  }

  private onRequestPermission = () => {
    Notification.requestPermission()
      .then((permission: string) => {
        if (permission === 'denied') {
          return null
        }

        this.saveToken().catch((error: any) => {
          console.error('save token error', error)

          this.saveToken().catch((err: any) => {
            console.error('save token second time', err)
          })
        })
      })
      .catch((error: any) => {
        console.error('Notification.requestPermission', error)
      })

    this.firebaseMessaging.onMessage((response: any) => {
      console.info('messaging.onMessage', response)
      // @ts-ignore
      store.dispatch(loadNotifications())
    })

    this.firebaseMessaging.onTokenRefresh(() => {
      this.saveToken().catch((error: any) => {
        console.error('Unable to retrieve refreshed token', error)
      })
    })
  }

  private saveToken = async () => {
    if (Notification.permission !== 'granted') {
      return null
    }

    const token = await this.firebaseMessaging.getToken()
    if (!token) {
      return console.error('Empty token')
    }
    // @ts-ignore
    await store.dispatch(saveSubscriptionToken(token))
  }
}

export default new Firebase()
