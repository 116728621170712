import { ActionType } from 'typesafe-actions'
import { MetaAndLinks } from 'redux/commonTypes'
import { IUser } from 'redux/auth/types'
import * as actions from './actions'

export type CommentsAction =
  | ActionType<typeof actions.fetchCommentsAsync>
  | ActionType<typeof actions.fetchCommentThreadAsync>
  | ActionType<typeof actions.postCommentAsync>
  | ActionType<typeof actions.replyToCommentAsync>
  | ActionType<typeof actions.likeCommentAsync>
  | ActionType<typeof actions.reportCommentAsync>
  | ActionType<typeof actions.setActiveCommentId>
  | ActionType<typeof actions.updateShownComments>
  | ActionType<typeof actions.showMoreCommentsAsync>
  | ActionType<typeof actions.clearComments>
  | ActionType<typeof actions.fetchRepliesAsync>

export enum CommentsActionTypes {
  FETCH_COMMENTS_REQUEST = '@@events/FETCH_COMMENTS_REQUEST',
  FETCH_COMMENTS_SUCCESS = '@@events/FETCH_COMMENTS_SUCCESS',
  FETCH_COMMENTS_FAIL = '@@events/FETCH_COMMENTS_FAIL',
  POST_COMMENT_REQUEST = '@@events/POST_COMMENT_REQUEST',
  POST_COMMENT_SUCCESS = '@@events/POST_COMMENT_SUCCESS',
  POST_COMMENT_FAIL = '@@events/POST_COMMENT_FAIL',
  REPLY_TO_COMMENT_REQUEST = '@@events/REPLY_TO_COMMENT_REQUEST',
  REPLY_TO_COMMENT_SUCCESS = '@@events/REPLY_TO_COMMENT_SUCCESS',
  REPLY_TO_COMMENT_FAIL = '@@events/REPLY_TO_COMMENT_FAIL',
  CLEAR_COMMENTS = '@@events/CLEAR_COMMENTS',
  LIKE_COMMENT_REQUEST = '@@events/LIKE_COMMENT_REQUEST',
  LIKE_COMMENT_SUCCESS = '@@events/LIKE_COMMENT_SUCCESS',
  LIKE_COMMENT_FAIL = '@@events/LIKE_COMMENT_FAIL',
  REPORT_COMMENT_REQUEST = '@@events/REPORT_COMMENT_REQUEST',
  REPORT_COMMENT_SUCCESS = '@@events/REPORT_COMMENT_SUCCESS',
  REPORT_COMMENT_FAIL = '@@events/REPORT_COMMENT_FAIL',
  SET_ACTIVE_COMMENT = '@@events/SET_ACTIVE_COMMENT',
  UPDATE_SHOWN_COMMENTS = '@@events/UPDATE_SHOWN_COMMENTS',
  SHOW_MORE_COMMENTS_REQUEST = '@@events/SHOW_MORE_COMMENTS_REQUEST',
  SHOW_MORE_COMMENTS_SUCCESS = '@@events/SHOW_MORE_COMMENTS_SUCCESS',
  SHOW_MORE_COMMENTS_FAIL = '@@events/SHOW_MORE_COMMENTS_FAIL',
  FETCH_COMMENT_THREAD_REQUEST = '@@events/FETCH_COMMENT_THREAD_REQUEST',
  FETCH_COMMENT_THREAD_SUCCESS = '@@events/FETCH_COMMENT_THREAD_SUCCESS',
  FETCH_COMMENT_THREAD_FAIL = '@@events/FETCH_COMMENT_THREAD_FAIL',
  FETCH_REPLIES_REQUEST = '@@events/FETCH_REPLIES_REQUEST',
  FETCH_REPLIES_SUCCESS = '@@events/FETCH_REPLIES_SUCCESS',
  FETCH_REPLIES_FAIL = '@@events/FETCH_REPLIES_FAIL'
}

export interface IReply {
  id: number
  user_id: number
  name: string
  icon: string
  content: string
  total_likes: number
  left: string
  is_liked: boolean
  parent_id: number
  title: string
  hasMorePages: boolean
}

export interface ReplyList extends MetaAndLinks {
  data: IReply[]
}

export interface IComment extends IReply {
  total_replies: number
  replies: IReply[]
}

export interface IEventComments extends MetaAndLinks {
  data: IComment[]
}

export interface ICommentThread {
  data: IComment
  reply_id: number
}

export interface ICommentData {
  isFetching: boolean
  isSending: boolean
  data: IEventComments
  activeCommentId: number | null
  shownComments: IComment[]
  singleCommentThread: ICommentThread
}

export enum ReportReason {
  SPAM = 1,
  INAPPROPRIATE = 2
}

export enum ReportReasonType {
  NUDITY = 1,
  HATE_SPEECH = 2,
  VIOLENCE = 3,
  HARASSMENT = 4,
  SCAM = 5,
  FALSE_INFO = 6
}

export enum ReportViolence {
  THREAT = 1,
  ANIMAL_ABUSE = 2,
  INJURY = 3,
  DANGER = 4
}

export const ReportViolenceLabels: { [key: number]: string } = {
  [ReportViolence.THREAT]: 'Violent threat',
  [ReportViolence.ANIMAL_ABUSE]: 'Animal abuse',
  [ReportViolence.INJURY]: 'Death or severe injury',
  [ReportViolence.DANGER]: 'Dangerous organizations or individuals'
}

export enum ReportHarassment {
  ME = 1,
  SB_I_KNOW = 2,
  SB_ELSE = 3
}

export const ReportHarassmentLabels: { [key: number]: string } = {
  [ReportHarassment.ME]: 'Me',
  [ReportHarassment.SB_I_KNOW]: 'Someone I know',
  [ReportHarassment.SB_ELSE]: 'Someone else'
}

export const ReportReasonLabel: { [key: number]: string } = {
  [ReportReasonType.NUDITY]: 'Nudity or sexual activity',
  [ReportReasonType.HATE_SPEECH]: 'Hate speech or symbols',
  [ReportReasonType.VIOLENCE]: 'Violence or dangerous organisations',
  [ReportReasonType.HARASSMENT]: 'Bullying or harassment',
  [ReportReasonType.SCAM]: 'Scam or fraud',
  [ReportReasonType.FALSE_INFO]: 'False information'
}

export interface ReportedComment {
  comment: IComment
  created_at: string
  id: number
  reason: ReportReason
  reason_type: ReportReasonType | null
  reported_by: IUser
  updated_at: string
}
