import { media } from 'styled-bootstrap-grid'
import styled from 'styled-components'

export const DotsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  position: relative;

  .dots-pagination-slider {
    width: 70px;
    height: 15px;
    position: static;
  }

  .dots-pagination-slider .slick-track {
    display: flex;
  }

  .dots-pagination-slider .slick-slide {
    margin: 0 0.25rem;
    background-color: ${p => p.theme.colors.white};
    opacity: 0.2;
    border-radius: 50%;
    cursor: pointer;
  }

  .dots-pagination-slider .slick-slide div {
    font-size: 0;
    width: 12px;
    height: 12px;
    transition: 0.5s;
  }

  .dots-pagination-slider .slick-active.slick-center.slick-current {
    opacity: 1;
    transition: 0.5s;
  }

  ${media.md`
    justify-content: flex-end;

    .dots-pagination-slider {
      width: 100px;
      position: absolute;
      bottom: 0;
      top: -55px;
      right: 0;
    }
  `}
`

export const Wrapper = styled.div`
  margin-right: 15%;

  /* Photo Gallery Slider */
  .photo-gallery-slider .slick-list {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    overflow: visible;
    margin-top: 1.375rem;
  }

  .photo-gallery-slider .slick-slide {
    padding-right: 1.25rem;
  }

  .photo-gallery-slider .slick-active {
    padding-left: 0;
  }

  .photo-gallery-slider .slick-slide div img {
    border-radius: 1rem;
  }

  ${media.md`
    .photo-gallery-slider .slick-list {
      margin-top: 0;
    }
    .photo-gallery-slider .slick-slide {
      padding-right: 2.25rem;
    }
  `}
`

export const configArrowStyles = (props: any) => {
  return {
    width: '3.5rem',
    height: '3.5rem',
    top: '50%',
    marginTop: '-1.75rem',
    padding: '1rem',
    backgroundColor: '#BC6ACA',
    borderRadius: '50%',
    fill: props.className.includes('slick-disabled') && '#909293',
    transition: 'transform 0.3s',
    overflow: 'initial',
    transform:
      props.type === 'prev'
        ? 'translateX(-50%)'
        : 'translateX(240%) rotate(180deg)',
    boxShadow:
      props.type === 'prev'
        ? '3.5px 3.5px 15px rgba(0, 0, 0, 0.5)'
        : '3.5px -3.5px 15px rgba(0, 0, 0, 0.5)'
  }
}
