import Firebase from 'services/firebaseService'
import { AuthProviders } from 'redux/auth/types'
import { IEventsByInfluencer } from 'redux/events/types'
import { PaymentSystem } from '../redux/checkout/types'

enum AnalyticsActions {
  SIGN_UP = 'sign_up',
  SIGN_IN = 'login',
  REGISTRATION_COMPLETE = 'RegistrationComplete',
  EVENT_SEARCH = 'search',
  TRACK_SHARE_INFLUENCER = 'ShareInfluencerPressed',
  SHARE_APP_PRESSED = 'ShareAppPressed', // -
  SHARE_EVENT_PRESSED = 'ShareEventPressed',
  FOLLOW = 'StartFollowing',
  UN_FOLLOW = 'Unfollow',
  LIVE_EVENTS_OPEN = 'LiveEventsScreenOpened',
  HISTORY_EVENTS_OPEN = 'HistoryEventsScreenOpened',
  ALL_EVENTS_OPEN = 'AllEventsScreenOpened',
  YOUTUBE_SECTION_OPENED = 'YoutubeTwitterSectionOpened', // +-
  REGULAR_CHECKOUT_OPENED = 'RegularCheckoutScreenOpened',
  AUCTION_OPENED = 'AuctionScreenOpened',
  TRENDING_SECTION_EVENT = 'TrendingSectionEvent', // -
  RECOMMENDED_SECTION_EVENT = 'EnterEvent', // -
  INFLUENCER_OPENED_FROM_EVENT = 'InfluencerScreenOpenedFromEvent',
  TIER_SELECTED_ON_EVENT = 'TierSelectedOnEventScreen', // -
  TIER_CHECKOUT_ON_EVENT = 'TierCheckoutOnEventScreen',
  TIER_CHECKOUT_SUCCESS = 'TierPurchase',
  TIER_CHECKOUT_INTENT = 'PurchaseIntent'
}

type NorthCapitalEvents = 'Open' | 'Close' | 'Success'

class AnalyticsManager {
  public trackSignUp(method: AuthProviders) {
    Firebase.logEvent(AnalyticsActions.SIGN_UP, { method })
  }

  public trackSignIn(method: AuthProviders) {
    Firebase.logEvent(AnalyticsActions.SIGN_IN, { method })
  }
  public trackRegistrationComplete() {
    Firebase.logEvent(AnalyticsActions.REGISTRATION_COMPLETE)
  }

  public trackSearch(searchText: string) {
    Firebase.logEvent(AnalyticsActions.EVENT_SEARCH, {
      search_term: searchText
    })
  }

  // MARK: - Share tracking
  public trackShareInfluencer(influencerId: number) {
    Firebase.logEvent(AnalyticsActions.TRACK_SHARE_INFLUENCER, {
      InluencerId: String(influencerId)
    })
  }

  public trackShareApp() {
    Firebase.logEvent(AnalyticsActions.SHARE_APP_PRESSED)
  }

  public trackShareEvent(eventId: number) {
    Firebase.logEvent(AnalyticsActions.SHARE_EVENT_PRESSED, {
      EventId: String(eventId)
    })
  }

  // MARK: - Brand events
  public trackFollowAction(startFollowing: boolean, influencerId: number) {
    const message = startFollowing
      ? AnalyticsActions.FOLLOW
      : AnalyticsActions.UN_FOLLOW

    Firebase.logEvent(message, {
      InluencerId: String(influencerId)
    })
  }

  public trackLiveHistoryEvents(
    type: IEventsByInfluencer,
    influencerId: number
  ) {
    const message = {
      [IEventsByInfluencer.LIVE_EVENTS]: AnalyticsActions.LIVE_EVENTS_OPEN,
      [IEventsByInfluencer.HISTORY_EVENTS]:
        AnalyticsActions.HISTORY_EVENTS_OPEN,
      [IEventsByInfluencer.ALL_EVENTS]: AnalyticsActions.ALL_EVENTS_OPEN
    }

    Firebase.logEvent(message[type], {
      InluencerId: String(influencerId)
    })
  }

  public trackYoutubeTwitterSection(influencerId: number) {
    Firebase.logEvent(AnalyticsActions.YOUTUBE_SECTION_OPENED, {
      InfluencerId: String(influencerId)
    })
  }

  // MARK: - Checkout tracking
  public trackCheckoutScreen(isRegular: boolean, tierId: number) {
    const message = isRegular
      ? AnalyticsActions.REGULAR_CHECKOUT_OPENED
      : AnalyticsActions.AUCTION_OPENED

    Firebase.logEvent(message, {
      TierId: tierId
    })
  }

  // MARK: - Homepage tracking
  public trackTrendingSectionEvent(eventId: number) {
    Firebase.logEvent(AnalyticsActions.TRENDING_SECTION_EVENT, {
      EventId: String(eventId)
    })
  }

  public trackRecommendedSectionEvent(eventId: number) {
    Firebase.logEvent(AnalyticsActions.RECOMMENDED_SECTION_EVENT, {
      EventId: String(eventId)
    })
  }

  // MARK: - Event tracking
  public trackInfluencerScreenOpen(eventId: number, influencerId: number) {
    Firebase.logEvent(AnalyticsActions.INFLUENCER_OPENED_FROM_EVENT, {
      EventId: eventId,
      InfluencerId: influencerId
    })
  }

  public trackTierSelectionFromEvent(eventId: number, tierId: number) {
    Firebase.logEvent(AnalyticsActions.TIER_SELECTED_ON_EVENT, {
      EventId: eventId,
      TierId: tierId
    })
  }

  public trackTierCheckoutFromEvent(tierId: number) {
    Firebase.logEvent(AnalyticsActions.TIER_CHECKOUT_ON_EVENT, {
      TierId: tierId
    })
  }

  public trackTierCheckoutSuccess(
    tierId: number,
    eventId: number,
    paymentSystem: PaymentSystem
  ) {
    Firebase.logEvent(AnalyticsActions.TIER_CHECKOUT_SUCCESS, {
      TierId: tierId,
      EventId: eventId,
      PaymentSystem: paymentSystem
    })
  }

  public trackTierCheckoutIntent(
    tierId: number,
    eventId: number,
    paymentSystem: PaymentSystem
  ) {
    Firebase.logEvent(AnalyticsActions.TIER_CHECKOUT_INTENT, {
      TierId: tierId,
      EventId: eventId,
      PaymentSystem: paymentSystem
    })
  }

  public trackNorthCapitalEvent(eventName: NorthCapitalEvents, tierId: number) {
    Firebase.logEvent(eventName, {
      TierId: tierId
    })
  }
}

export default new AnalyticsManager()
