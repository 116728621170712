import {
  InvestmentTerms,
  InvestmentTermsItems,
  ITLabels,
  SecurityLabels
} from 'redux/events/types'
import { formatMoney } from 'utils/dateFormats'

const createList = (terms: InvestmentTerms) =>
  terms.display_settings.reduce((acc: any[], item: any) => {
    let value: any = terms[item]

    if (
      [
        ITLabels.AMOUNT_RAISED,
        ITLabels.TOTAL,
        ITLabels.MIN_INVESTMENT,
        ITLabels.VALUATION,
        ITLabels.MIN_RAISE
      ].includes(item)
    ) {
      value = '$' + formatMoney(value)
    } else if (
      [ITLabels.FUNDED, ITLabels.DISCOUNT, ITLabels.INTEREST_RATE].includes(
        item
      )
    ) {
      value = value.toFixed(1) + '%'
    } else if (item === ITLabels.SECURITY_TYPE) {
      value = SecurityLabels[value]
    } else if (item === ITLabels.PRICE_PER_SHARE) {
      value = '$' + value // this is a string
    }
    acc.push({
      value,
      item: InvestmentTermsItems[item]
    })
    return acc
  }, [])

export default createList
