import React from 'react'

const SocialShareIcon = styles => (
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...styles}
  >
    <path
      d="M4.74658 2.00003H8.21079C8.625 2.00003 8.96079 2.33582 8.96079 2.75003C8.96079 3.12972 8.67863 3.44352 8.31256 3.49318L8.21079 3.50003H4.74658C3.55572 3.50003 2.58094 4.42519 2.50177 5.59598L2.49658 5.75003V15.25C2.49658 16.4409 3.42174 17.4157 4.59253 17.4948L4.74658 17.5H14.2473C15.4382 17.5 16.413 16.5749 16.4921 15.4041L16.4973 15.25V14.7522C16.4973 14.338 16.8331 14.0022 17.2473 14.0022C17.627 14.0022 17.9408 14.2844 17.9905 14.6505L17.9973 14.7522V15.25C17.9973 17.2543 16.425 18.8913 14.4465 18.9948L14.2473 19H4.74658C2.74232 19 1.10531 17.4277 1.00178 15.4492L0.996582 15.25V5.75003C0.996582 3.74577 2.56894 2.10876 4.54742 2.00523L4.74658 2.00003H8.21079H4.74658ZM12.5006 4.51988V1.75003C12.5006 1.12606 13.2074 0.789976 13.6876 1.13983L13.7697 1.20877L19.7643 6.95877C20.0441 7.22712 20.0696 7.65814 19.8407 7.9561L19.7644 8.0412L13.7698 13.7931C13.3196 14.2251 12.5877 13.9477 12.5077 13.3589L12.5006 13.2519V10.5266L12.1571 10.5567C9.75739 10.807 7.45735 11.8879 5.24253 13.8174C4.72342 14.2696 3.92029 13.842 4.00567 13.1589C4.67046 7.83933 7.45233 4.90733 12.2012 4.53953L12.5006 4.51988V1.75003V4.51988ZM14.0006 3.50867V5.25003C14.0006 5.66424 13.6648 6.00003 13.2506 6.00003C9.37717 6.00003 6.97655 7.67616 5.93931 11.1572L5.86025 11.4358L6.21244 11.199C8.44892 9.73722 10.7983 9.00003 13.2506 9.00003C13.6303 9.00003 13.9441 9.28218 13.9937 9.64826L14.0006 9.75003V11.4928L18.1618 7.50012L14.0006 3.50867Z"
      fill="#EB78FF"
    />
  </svg>
)

export default SocialShareIcon
