import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export const MainWrapper = styled.div`
  margin-bottom: 3rem;
  li {
    padding-bottom: 0;
  }
`
export const DateBlock = styled.div`
  div,
  p {
    font-family: Inter;
    color: ${p => p.theme.colors.white};
  }
  div {
    font-size: 1.25rem;
  }
  p {
    font-size: 1.5rem;
  }
`

export const ButtonBlock = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-evenly;

  button {
    min-width: 100px;
    ${media.md`
      min-width: 150px;
  `}
  }
`

export const TextMessage = styled.p`
  color: ${p => p.theme.colors.black};
  font-family: Inter;
`
export const CancelInvestment = styled.div`
  background: rgb(38, 18, 52);
  background: linear-gradient(
    180deg,
    rgba(38, 18, 52, 1) 0%,
    rgba(67, 35, 85, 1) 50%,
    rgba(38, 18, 52, 1) 100%
  );
`

export const CardInfoWrapper = styled.div`
  width: 100%;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${media.md`
    flex-direction: row;
    align-items: center;
    
    button {
      width: 18.125rem;
    }
    
    ul {
      margin-bottom: 0;
      margin-right: 2rem;
    }
  `}
`

export const InvestmentList = styled.ul`
  width: 70%;
  li {
    list-style-type: none;
    font-family: Inter;
    font-size: 1.5rem;
    font-weight: 800;
    color: ${p => p.theme.colors.white};
    text-transform: capitalize;
    span {
      display: block;
      font-weight: 400;
      font-size: 1rem;
      padding: 10px 0;
      margin-bottom: 10px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.6);
    }
  }
`
