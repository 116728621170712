import { createGlobalStyle } from 'styled-components'
import { media } from 'styled-bootstrap-grid'

const SlickStyles = createGlobalStyle`
  .slick-loading .slick-list {
    background: #fff url('./ajax-loader.gif') center center no-repeat;
  }

  /* Arrows */
  .slick-prev,
  .slick-next {
    width: 140px;
  ${media.lg`width: 272px;`}
  height: 124px;
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    z-index: 1;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
  }
  .slick-prev:hover,
  .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
  }

  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0;
    background: transparent;
    cursor: auto;
  }

  .slick-prev.slick-disabled,
  .slick-next.slick-disabled {
    cursor: auto;
  }

  .slick-prev:before,
  .slick-next:before {
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;

    opacity: 0.75;
    color: white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .slick-prev {
    left: 0;
  }
  [dir='rtl'] .slick-prev {
    right: -25px;
    left: auto;
  }
  .slick-prev:before {
    content: '';
  }
  [dir='rtl'] .slick-prev:before {
    content: '';
  }

  .slick-next {
    right: 0;
  }
  [dir='rtl'] .slick-next {
    right: auto;
    left: -25px;
  }
  .slick-next:before {
    content: '';
  }
  [dir='rtl'] .slick-next:before {
    content: '';
  }

  /* Dots */
  .slick-dotted.slick-slider {
    margin-bottom: 30px;
  }

  .slick-dots {
    position: absolute;
    bottom: -25px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
  }
  .slick-dots li {
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
  }
  .slick-dots li button {
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
  }
  .slick-dots li button:hover,
  .slick-dots li button:focus {
    outline: none;
  }
  .slick-dots li button:hover:before,
  .slick-dots li button:focus:before {
    opacity: 1;
  }
  .slick-dots li button:before {
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 12px;
    height: 12px;

    content: '•';
    text-align: center;

    opacity: 0.25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: black;
  }
  
    .slick-dots li {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #fff;
    opacity: 0.5;
  }

  .slick-dots li.slick-active {
    opacity: 1;
  }

  //trended

.slick-center {
max-height: 366px;
margin-bottom: 16px;

 .slick-list {
max-height: 366px;
}

.slick-slide {
  width: 300px !important;
  height: 366px !important;
  transition: transform .4s;
  position: relative; 
  ${media.lg`
    width: 366px !important;
  `}
}

.slick-slide:after {
  content:'';
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: transform .4s;
}

    .slick-slide {
     transform: scale(0.8) translate(18px, 10px);
    filter: brightness(0.6);
    
      ${media.xs`
        transform: scale(0.8) translate(-24px, 10px);
  `}
    
    div {
      border-color: #272727;
    }
    }
    
    .slick-slide.slick-center + .slick-slide {
    transform: scale(0.8) translate(-18px, 10px);
    
      ${media.xs`
        transform: scale(0.8) translate(24px, 10px);
  `}
    }
    
    .slick-slide.slick-active {
      //transform: scale(0.8) translate(220px);
      filter: none;
      
      div {
        border-color: #171717;
      }
    
      a {
        z-index: 31
      }
    }
    
    .slick-slide.slick-center {
      transform: scale(1);
      z-index: 30;
    }
    
    .slick-center:after {
      opacity: 0;
    }
    
    .slick-prev, .slick-next {
      width: auto;
      height: 310px;
      background: none;
      
       ${media.md`
          width: 180px;
      `}
      
      ${media.lg`
          width: 366px;
      `}
      
      :before {   
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
      }
    } 

    .slick-prev { 
      :before{
        transform: translateY(-50%) rotate(-135deg);
      }  
    }
  }`

export default SlickStyles
