import axios from 'axios'
import {
  Currency,
  ICustomField,
  Intent,
  PaymentSystem,
  ICartItem,
  ICartItemSets
} from 'redux/checkout/types'

export interface CheckoutShoppingCartRequest {
  payment_system: PaymentSystem
  device_data?: string
  token: string | null
  country?: string
  city?: string
  state?: string
  zip?: string
  street?: string
  room?: string
  place_id?: string
  recipient_name?: string
  use_as_default?: boolean
  redirect_url?: string
}
export interface BuyNowRequest extends CheckoutShoppingCartRequest {
  tier_set_id: number
  quantity: number
  recipient_name?: string
  email?: string
  device_id?: string
}
export const CheckoutApi = {
  confirmCheckout: async <D>(params: D, baseUrl: string) =>
    await axios.get<unknown>(`${baseUrl}/payments/execute`, { params }),

  fetchCartInfo: async <D>(params?: D, tierSetId?: number) =>
    await axios.get(`/checkout${tierSetId ? `/${tierSetId}` : ''}`, { params }),

  checkoutDonate: async (data: PayPalCheckoutRequest) =>
    await axios.put<CheckoutResponse>('/payments/donate', data),

  checkoutShoppingCart: async (data: CheckoutShoppingCartRequest) =>
    await axios.post<CheckoutShoppingCartResponse>('/cart/order', {
      ...data
    }),

  checkoutBuyNow: async (data: BuyNowRequest) =>
    await axios.post<CheckoutShoppingCartResponse>('/payments/buy', data),

  fetchBraintreeToken: async (tierId: number) =>
    await axios.get<FetchBraintreeTokenResponse>(
      `/profile/tiers/${tierId}/checkout`
    ),

  fetchBraintreeCartToken: async () =>
    await axios.get<FetchBraintreeCartTokenResponse>(
      `/payments/braintree/token`
    ),

  checkout: async (data: CheckoutRequest) =>
    await axios.put<CheckoutResponse>('/payments/donate', data),

  getUserCart: async (userId?: number) => {
    const path = userId ? `/cart/${userId}` : '/cart'
    return await axios.get<GetUserCartResponse>(path)
  },

  addProductToCart: async (tierSetId: number, quantity: number) =>
    await axios.post<AddProductToCartResponse>(`/cart`, {
      tier_set_id: tierSetId,
      quantity
    }),

  editProductInCart: async (shoppingCartId: number, quantity: number) =>
    await axios.patch<EditProductInCartResponse>(`/cart/${shoppingCartId}`, {
      quantity
    }),

  deleteProductFromCart: async (itemId: number) =>
    await axios.delete<any>(`/cart/${itemId}`),

  clearTheCart: async () => await axios.delete<any>(`/cart`)
}

interface CheckoutRequest extends PayPalCheckoutRequest {
  recipient_name: string
}

interface PayPalCheckoutRequest {
  payment_system: PaymentSystem
  intent: Intent
  currency: Currency
  amount: number
  quantity: number
  event_id: number
  event_title: string
  tier_id: number
  tier_title: string
  device_data?: string
  token: string | null
  country?: string
  city?: string
  state?: string
  zip?: string
  street?: string
  room?: string
  place_id?: string
  use_as_default?: boolean
  custom_fields?: ICustomField[]
  redirect_url?: string
  tier_picture?: string
}

export interface CheckoutResponse {
  approval_link: string
  payment_id: number
  payment_type: Intent
}

interface FetchBraintreeTokenResponse {
  braintree_client_token: string
}
interface FetchBraintreeCartTokenResponse {
  braintree_client_token: string
}

interface AddProductToCartResponse {
  shopping_cart_id: number
  tier_set_id: number
  color: string | null
  color_name: string | null
  size: string | null
  quantity: number
  left: number
}
type EditProductInCartResponse = ICartItemSets

interface GetUserCartResponse {
  data: {
    items: ICartItem[]
    count: number
  }
}

interface CheckoutShoppingCartResponse {
  approval_link: string
  order_id: number
}
