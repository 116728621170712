import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, useLocation } from 'react-router-dom'
import { routePath } from 'routes'
import debounce from 'utils/debounce'
import { changeInvestmentAmount, setErrors } from 'redux/investment/actions'
import {
  selectInvestmentErrors,
  selectInvestmentState
} from 'redux/investment/selectors'
// import { useInvestNowContext } from 'src/pages/investment/InvestNowPage/context'
import { AnnualIncomeTooltip, IIncome } from 'redux/investment/types'
import { SecurityType } from 'redux/events/types'
import {
  convertValueToPrice,
  convertValueToPriceWithDecimal
} from 'utils/helpers'
import { ButtonVariant } from 'src/components/UI/Button'
import Modal from 'src/components/UI/Modal'
import { BackButton } from 'src/components/investment/steps'
import TextInput from 'src/components/UI/TextInput'

import {
  BackButtonWrapper,
  Form,
  LimitMessage,
  ModalLink,
  Navigation,
  Step,
  Title
} from 'src/components/investment/steps/styles'

interface LocationState {
  price_per_share: string
  security_type: number
  amount: number
  eventId: number
  activeTierId: number
}

const AnnualIncomeForm: FC<{ [key: string]: any }> = props => {
  const dispatch = useDispatch()
  const location = useLocation<LocationState>()
  const { setAmount, selectedAnnualIncome, setSelectedAnnualIncome } = props
  const { price_per_share, security_type, amount, eventId } = location.state
  const isEquity = security_type === SecurityType.EQUITY
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [roundedAmount, setRoundedAmount] = useState<number>(0)
  const [investmentLimit, setInvestmentLimit] = useState<number>(0)
  const [annualForm, setAnnualForm] = useState<IIncome>(selectedAnnualIncome)
  const errors = useSelector(selectInvestmentErrors)
  const { annual_income: annualIncome, net_worth: netWorth } = useSelector(
    selectInvestmentState
  )
  const { annual_income, net_worth } = annualForm

  const onChange = (name: string, value: string) => {
    setAnnualForm({
      ...annualForm,
      [name]: convertValueToPrice(value)
    })

    if (errors[name]) {
      dispatch(setErrors({ ...errors, [name]: '' }))
    }
  }

  useEffect(() => {
    if (annualIncome && netWorth) {
      setAnnualForm({
        ...annualForm,
        annual_income: convertValueToPrice(annualIncome),
        net_worth: convertValueToPrice(netWorth)
      })
    }
    // eslint-disable-next-line
  }, [annualIncome, netWorth])

  useEffect(() => {
    setToContext(annualForm)
    // eslint-disable-next-line
  }, [annualForm])

  const setToContext = useCallback(debounce(setSelectedAnnualIncome, 500), [])

  useEffect(() => {
    setAmount(amount)
    // eslint-disable-next-line
  }, [])

  const onBlur = () => {
    const base = 107000
    if (annual_income.length && net_worth.length) {
      const annualIncome = Number(annual_income.replace(/[^0-9]/g, ''))
      const netWorth = Number(net_worth.replace(/[^0-9]/g, ''))

      const limit =
        annualIncome < base || netWorth < base
          ? Math.max(2200, 0.05 * Math.min(annualIncome, netWorth))
          : 0.1 * Math.min(annualIncome, netWorth)
      const investLimit = Number(limit.toFixed(2))
      if (amount > investLimit) {
        setAmount(investLimit)
        setInvestmentLimit(investLimit)
        setIsModalOpen(true)
        if (isEquity) {
          const rounded =
            Math.floor(investLimit / Number(price_per_share)) *
            Number(price_per_share)

          const roundedInvestmentAmount = parseFloat(rounded.toFixed(2))

          setRoundedAmount(roundedInvestmentAmount)
          dispatch(changeInvestmentAmount(roundedInvestmentAmount))
        } else {
          dispatch(changeInvestmentAmount(investLimit))
        }
      }
    }
  }

  return (
    <div>
      <Modal hideControl={true} isOpen={isModalOpen}>
        <LimitMessage>
          Based on the income and net worth you entered, the maximum amount you
          can invest according to federal law is
          <b> {convertValueToPrice(String(investmentLimit))}</b>.{' '}
          {isEquity && investmentLimit !== roundedAmount && (
            <span>
              This amount will be rounded off to
              <b> {convertValueToPriceWithDecimal(roundedAmount)}</b> to be a
              multiple of the share price.
            </span>
          )}
        </LimitMessage>
        <ModalLink
          variant={ButtonVariant.Default}
          to={{
            pathname: generatePath(routePath.INVESTMENT, {
              id: eventId
            }),
            state: {
              amount:
                isEquity && investmentLimit !== roundedAmount
                  ? roundedAmount
                  : amount
            }
          }}
        >
          Ok
        </ModalLink>
      </Modal>
      <Navigation>
        <BackButtonWrapper>
          <Step>1</Step>
          <BackButton />
          <Title>Personal Information</Title>
        </BackButtonWrapper>
      </Navigation>
      <Form>
        <TextInput
          name={'annual_income'}
          value={annual_income}
          label={'Annual Income'}
          handleChange={onChange}
          onBlur={onBlur}
          wrapperClassName={'form__first-field'}
          infoLabel={AnnualIncomeTooltip.annual_income}
          errorLabel={errors.annual_income}
          maxLength={12}
        />
        <TextInput
          name={'net_worth'}
          value={net_worth}
          label={'Net Worth'}
          handleChange={onChange}
          onBlur={onBlur}
          wrapperClassName={'form__last-field'}
          infoLabel={AnnualIncomeTooltip.net_worth}
          errorLabel={errors.net_worth}
          maxLength={13}
        />
      </Form>
    </div>
  )
}

export default React.memo(AnnualIncomeForm)
