import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectProfile, selectReportErrors } from 'redux/profile/selectors'
import { sendReport } from 'redux/profile/operations'
import { clearProfileErrors } from 'redux/profile/actions'

import TextInput from 'src/components/UI/TextInput'
import Button from 'src/components/UI/Button'
import DropdownSelect from 'src/components/UI/Dropdown'

import { ReportType } from 'redux/profile/types'
import { Title } from 'components/UI/Tabs/styles'
import { DropdownWrapper } from 'src/components/investment/InvestNowModal/styles'
import { Email } from 'containers/UserSettingsPage/styles'
import { Form } from 'components/profile/settings/AddressForm/styles'
import { ReportWrapper } from './styles'

const ReportForm: FC = () => {
  const [message, setMessage] = useState<string>('')
  const [type, setType] = useState<{ label: string; value: ReportType }>({
    value: ReportType.SUPPORT,
    label: 'Contact Support'
  })
  const errors = useSelector(selectReportErrors)
  const { isSendingReport } = useSelector(selectProfile)
  const dispatch = useDispatch()
  const options = [
    {
      value: ReportType.BUG,
      label: 'Report Problem'
    },
    {
      value: ReportType.SUPPORT,
      label: 'Contact Support'
    }
  ]

  useEffect(() => {
    if (errors) {
      dispatch(clearProfileErrors())
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (errors) {
      dispatch(clearProfileErrors())
    }
    // eslint-disable-next-line
  }, [type])

  const handleChange = (name: string, value: string): void => {
    setMessage(value)
    // this.setState({ message: value }, () => this.props.clearProfileErrors())
  }

  const handleSendReport = async (e: any) => {
    e.preventDefault()

    try {
      await dispatch(sendReport(type.value, message))
      setMessage('')
      if (errors) {
        dispatch(clearProfileErrors())
      }
    } catch (e) {}
  }
  const selectType = (
    name: string,
    option: { label: string; value: ReportType }
  ) => setType(option)
  return (
    <Form>
      <ReportWrapper>
        <Title>Message</Title>
        <TextInput
          as={'textarea'}
          name={'textarea'}
          handleChange={handleChange}
          value={message}
          placeholder={'Enter message here'}
          errorLabel={errors}
          wrapperClassName={'form__single-field'}
        />
        <DropdownWrapper>
          <DropdownSelect
            options={options}
            name={'reportType'}
            onSelect={selectType}
            value={type}
            hideControl={true}
          />
        </DropdownWrapper>
      </ReportWrapper>
      <Button
        variant={'default'}
        type={'submit'}
        onClick={handleSendReport}
        disabled={message.length < 4 || isSendingReport}
      >
        Submit
      </Button>
      <Email>
        <p>Tech Support</p>
        <p>tech@fanvestor.com </p>
      </Email>
    </Form>
  )
}

export default ReportForm
