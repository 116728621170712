import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAddress } from 'redux/checkout/operations'
import {
  checkProhibitedCountry,
  getAddressErrors,
  getAnnualIncomeErrors,
  getContactErrors
} from 'redux/investment/operations'
import {
  changeInvestmentAddress,
  setErrors,
  setInvestmentStep
} from 'redux/investment/actions'
import {
  selectAddressInfo,
  selectCurrentStep,
  selectInvestmentErrors
} from 'redux/investment/selectors'
import { selectAutocompleteAddresses } from 'redux/checkout/selectors'
import {
  extractFieldFromAddress,
  extractLabelAndCodeFromAddress,
  geocodeByPlaceId
} from 'utils/googleHelpers'
import { noFirstSpaceRegex, noTwoSpacesRegex } from 'utils/helpers'
import { AddressFields } from 'redux/investment/types'
import { Controls } from 'src/components/investment/steps'
import TextInput from 'src/components/UI/TextInput'
import Dropdown from 'src/components/UI/Dropdown'

import { Form, Navigation } from 'src/components/investment/steps/styles'

const AddressForm: FC<{ [key: string]: any }> = props => {
  const dispatch = useDispatch()
  const currentStep = useSelector(selectCurrentStep)
  const suggestAddresses = useSelector(selectAutocompleteAddresses)
  const { selectedContactForm, selectedAnnualIncome } = props
  const address = useSelector(selectAddressInfo)
  const errors = useSelector(selectInvestmentErrors)

  const onChangeStreet = async (name: string, value: { value: string }) => {
    if (!value.value) {
      return
    }

    // @ts-ignore
    const [place] = await geocodeByPlaceId(value.value)
    let city = extractFieldFromAddress(place, 'locality')
    if (!city) {
      city = extractFieldFromAddress(place, 'sublocality_level_1')
    }
    const countryName = extractLabelAndCodeFromAddress(place, 'country')
    const stateLabel = extractFieldFromAddress(
      place,
      'administrative_area_level_1'
    )
    const stateCode = extractLabelAndCodeFromAddress(
      place,
      'administrative_area_level_1'
    )
    const state = stateCode ? stateCode.code : stateLabel
    // tslint:disable-next-line:variable-name
    const postal_code = extractFieldFromAddress(place, 'postal_code') || ''
    const fullStreet = extractFieldFromAddress(place, 'route')
    const streetNumber = extractFieldFromAddress(place, 'street_number') || ''
    const street = `${streetNumber && streetNumber} ${fullStreet}`
    const country = countryName ? countryName.code || countryName.label : ''
    const newAddress = {
      country_label: countryName && countryName.label,
      state_label: stateLabel.replace(/'/g, ''),
      country,
      state: state.replace(/'/g, ''),
      city: city.replace(/'/g, ''),
      street,
      postal_code
    }

    dispatch(
      changeInvestmentAddress({
        ...address,
        ...newAddress
      })
    )
    dispatch(
      setErrors({
        ...errors,
        country: '',
        state: ''
      })
    )
  }

  const checkRestrictedCountry = async (
    country: string,
    cityName: string,
    countryLabel: string,
    isEntity: boolean
  ) => {
    let data
    let countryError
    let field
    try {
      data = await dispatch(
        checkProhibitedCountry(
          { iso_code: country, city_name: cityName },
          isEntity
        )
      )
      // @ts-ignore
      const fieldName = data.type === 'city' ? 'state' : 'country'
      field = isEntity ? `entity_${fieldName}` : fieldName
    } catch (e) {
      console.error(e)
    } finally {
      countryError = {
        // @ts-ignore
        [field]: data.restricted
          ? `We are sorry, we cannot accept investments from ${
              // @ts-ignore
              data.type === 'country' ? countryLabel : cityName
            }`
          : ''
      }
    }
    return countryError
  }

  const onClick = async () => {
    const contactErrors = getContactErrors(selectedContactForm)
    const addressErrors = getAddressErrors(address, '')
    const incomeErrors = getAnnualIncomeErrors(selectedAnnualIncome)
    const restrictedCountry = await checkRestrictedCountry(
      address.country,
      address.state_label || address.state,
      address.country_label,
      false
    )

    dispatch(
      setErrors({
        ...contactErrors,
        ...addressErrors,
        ...restrictedCountry,
        ...incomeErrors
      })
    )
    const isValidContact = !Object.keys(contactErrors).length
    const isValidAddress =
      !Object.keys(addressErrors).length &&
      // @ts-ignore
      !restrictedCountry.country &&
      // @ts-ignore
      !restrictedCountry.state
    const isValidIncome = !Object.keys(incomeErrors).length
    if (isValidContact && isValidAddress && isValidIncome) {
      dispatch(setInvestmentStep(currentStep + 1))
    }
  }

  const handleChangeInputAddress = (value: string) => {
    if (value) {
      dispatch(fetchAddress(value))
    }

    if (errors.street) {
      dispatch(setErrors({ ...errors, street: '' }))
    }
  }

  const handleChangeRoom = (name: string, value: string) => {
    const room = value
      .replace(noFirstSpaceRegex, '')
      .replace(noTwoSpacesRegex, ' ')
    dispatch(
      changeInvestmentAddress({
        ...address,
        additional_address: room
      })
    )
  }

  return (
    <>
      <div>
        <Navigation />
        <Form>
          <Dropdown
            label={'Home Address'}
            name={'street'}
            options={suggestAddresses}
            value={{
              label: address.street,
              value: address.street
            }}
            placeholder={'185 Berry st'}
            onSelect={onChangeStreet}
            onInputChange={handleChangeInputAddress}
            isAsync={true}
            wrapperClassName={'address-form__street'}
            forceUpdate={true}
            buttonClassName={'address-form__select'}
            customStyles={{
              control: { background: '#fff' },
              input: { background: '#fff' },
              firstOption: { borderRadius: '0' },
              option: { backgroundColor: '#fff' }
            }}
            valid={!errors.street}
            errorLabel={errors.street}
          />
          <TextInput
            name={'additional_address'}
            value={address.additional_address || ''}
            label={'Address Line 2 (optional)'}
            handleChange={handleChangeRoom}
            maxLength={128}
          />
          <TextInput
            name={'city'}
            value={address.city}
            label={'City'}
            wrapperClassName={'address-form__city'}
            readOnly={true}
          />
          <TextInput
            name={'state'}
            value={address.state}
            label={AddressFields.state}
            wrapperClassName={'address-form__state'}
            errorLabel={errors.state}
            readOnly={true}
          />
          <TextInput
            name={'country'}
            value={address.country}
            label={AddressFields.country}
            wrapperClassName={'address-form__country'}
            errorLabel={errors.country}
            readOnly={true}
          />
          <TextInput
            name={'postal_code'}
            value={address.postal_code}
            label={AddressFields.postal_code}
            wrapperClassName={'address-form__zip'}
            readOnly={true}
          />
        </Form>
      </div>
      <Controls onClick={onClick} />
    </>
  )
}

export default React.memo(AddressForm)
