import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export const DescriptionSectionContentWithPicture = styled.div<{
  reverse?: string
}>`
  display: flex;
  align-items: center;
  flex-direction: ${p =>
    p.reverse === 'above'
      ? 'column-reverse'
      : p.reverse === 'left'
      ? 'row-reverse'
      : 'row'};
  justify-content: ${p =>
    p.reverse === 'right'
      ? 'flex-start'
      : p.reverse === 'left'
      ? 'flex-end'
      : 'center'};
  margin-top: ${p => (p.reverse === 'above' ? '30px' : 'unset')};
  ${media.xs`
    ul:last-child {
      padding-bottom: 20px;
     }
   `}

  ${media.md`
    ul:last-child {
      padding-bottom: 20px;
     }
 `}
 div {
    width: ${p => (p.reverse === 'above' ? '100%' : null)};
    max-width: ${p => (p.reverse === 'above' ? '100%' : null)};
    margin-bottom: 0 !important;
    p {
      padding-top: ${p => (p.reverse === 'above' ? '30px' : null)};
      padding-bottom: ${p => (p.reverse !== 'above' ? '30px' : null)};
      span {
        margin-top: 0;
      }
    }
  }
  @media (max-width: 991px) {
    flex-direction: column-reverse;
    justify-content: center;
    margin-top: ${p => (p.reverse === 'above' ? '30px' : 'unset')};
  }

  a {
    color: ${p => p.theme.colors.fuchsia} !important;
  }
`

export const DescriptionSectionPicture = styled.img<{
  size?: 'left' | 'right' | 'above'
}>`
  width: ${p => (p.size === 'above' ? '100% !important' : '200px')};
  height: ${p => (p.size === 'above' ? '100% !important' : '200px')};
  object-fit: cover;

  ${media.xs`
   width: 100%;
   height: initial;
 `}

  ${media.sm`
   width: 100%;
   height: initial;
 `}

 ${media.md`
   width: 100%;
 `}

 ${media.lg`
   width: 280px;
 `}
   
  @media (min-width: 992px) {
  /* This breakpoint helps to change size if picture has above parameter */
  width: ${p => (p.size === 'above' ? '100% !important' : '280px')};
  height: ${p => (p.size === 'above' ? '100% !important' : '280px')};
  margin-right: ${p => (p.size === 'left' ? '20px' : 'none')};
  margin-left: ${p => (p.size === 'right' ? '20px' : 'none')};
  }
  
`
