import React, { Component, ReactNode } from 'react'
import InputMask from 'react-input-mask'
import { Label } from 'components/UI/Label'
import { AlertIcon } from 'components/icons'
import { dateMaskConfig, DEFAULT_DATE_FORMAT } from 'utils/dateFormats'
import {
  LightInput,
  DarkInput,
  DarkInputCheckbox,
  Wrapper,
  LabelWrap,
  SErrorLabel,
  IconWrapper,
  InfoIconWrapper
} from './styles'

export enum InputVariant {
  Light = 'light',
  Dark = 'dark'
}

export enum InputSpecificity {
  Default = 'default',
  Checkout = 'checkout'
}

export interface TextInputProps {
  id?: string
  type?: 'text' | 'password' | 'submit' | 'button' | 'date' | 'tel'
  variant: 'light' | 'dark'
  as?: 'input' | 'textarea'
  specificity: 'default' | 'checkout'
  name: string
  value: string
  label?: string
  placeholder?: string
  errorLabel?: string | string[]
  disabled?: boolean
  readOnly?: boolean
  maxLength?: number
  maskConfig?: object
  handleChange?: (name: string, value: string) => void
  handleKeyUp?: (e: any) => void
  icon?: ReactNode
  rightSideIcon?: ReactNode
  withoutAlert?: boolean
  onBlur?: any
  onFocus?: any
  style?: any
}

export interface TextInputState {
  value: string
  prevPropsValue: string
}

class TextInput extends Component<TextInputProps, TextInputState> {
  public static defaultProps = {
    name: '',
    type: 'text',
    variant: 'light',
    specificity: 'default',
    value: '',
    placeholder: '',
    errorLabel: '',
    label: '',
    handleChange: () => {},
    disabled: false,
    readOnly: false,
    withoutAlert: false
  }

  static getDerivedStateFromProps(
    props: TextInputProps,
    state: TextInputState
  ) {
    if (props.value !== state.prevPropsValue) {
      return {
        prevPropsValue: props.value,
        value: props.value
      }
    }
    return null
  }

  constructor(props: TextInputProps) {
    super(props)

    this.state = {
      value: props.value,
      prevPropsValue: ''
    }
  }

  onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { handleChange } = this.props
    const { name, value } = e.currentTarget

    this.setState({ value }, () => {
      if (handleChange) {
        handleChange(name, value)
      }
    })
  }

  onKeyUp = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    const { handleKeyUp } = this.props

    if (handleKeyUp) {
      handleKeyUp(event)
    }
  }

  renderInput() {
    const {
      label,
      name,
      type,
      placeholder,
      disabled,
      readOnly,
      maskConfig,
      variant,
      specificity,
      ...rest
    } = this.props

    const { value } = this.state

    const components = {
      [InputSpecificity.Default]: {
        [InputVariant.Light]: LightInput,
        [InputVariant.Dark]: DarkInput
      },
      [InputSpecificity.Checkout]: {
        [InputVariant.Light]: LightInput,
        [InputVariant.Dark]: DarkInputCheckbox
      }
    }

    const InputComponent = components[specificity][variant]

    if (maskConfig || type === 'date') {
      const mask = !maskConfig ? dateMaskConfig : maskConfig
      return (
        // @ts-ignore
        <InputMask
          {...mask}
          name={name}
          onChange={this.onChange}
          {...rest}
          value={value}
          disabled={disabled}
          readOnly={readOnly}
        >
          {(inputProps: TextInputProps) => (
            // @ts-ignore
            <InputComponent
              {...inputProps}
              {...rest}
              name={name}
              type={'text'}
              value={value}
              onKeyUp={this.onKeyUp}
              placeholder={placeholder ? placeholder : DEFAULT_DATE_FORMAT}
              disabled={disabled}
              readOnly={readOnly}
            />
          )}
        </InputMask>
      )
    }

    return (
      // @ts-ignore
      <InputComponent
        {...rest}
        name={name}
        type={type}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        readOnly={readOnly}
        onChange={this.onChange}
        onKeyUp={this.onKeyUp}
      />
    )
  }

  render() {
    const {
      id,
      label,
      errorLabel,
      icon,
      rightSideIcon,
      withoutAlert
    } = this.props

    return (
      <Wrapper>
        <LabelWrap>
          {!!label && (
            <div>
              <Label htmlFor={id}>{label}</Label>
            </div>
          )}
        </LabelWrap>
        {icon && <IconWrapper>{icon}</IconWrapper>}
        {this.renderInput()}
        {errorLabel ? (
          <InfoIconWrapper withoutAlert={withoutAlert}>
            <AlertIcon />
          </InfoIconWrapper>
        ) : (
          <InfoIconWrapper>{rightSideIcon}</InfoIconWrapper>
        )}
        {errorLabel && <SErrorLabel label={errorLabel} />}
      </Wrapper>
    )
  }
}

export default TextInput
