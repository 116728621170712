import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export const VerificationBlock = styled.div`
  margin-bottom: 2rem;
`

export const Block = styled.div`
  width: 100%;
  background-color: ${p => p.theme.colors.lightBlue};
`

export const SSNBlock = styled(Block)`
  min-height: 9.375rem;
  margin-bottom: 2rem;
  padding: 0.625rem 0.875rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0.75rem;
  overflow: hidden;

  ${media.lg`
    min-height: 7.875rem;
  `}
`

export const PassportBlock = styled(Block)`
  padding: 0.125rem 0.75rem 0.875rem;
  border-radius: 0 0 0.75rem 0.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const UploadButton = styled.label`
  padding: 1rem 1.5rem;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 0.125rem;
  background: ${p => p.theme.colors.fuchsia};
  border-radius: 0.75rem;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.375rem;
  color: ${p => p.theme.colors.white};
  text-align: center;
  cursor: pointer;
`

export const SSNText = styled.p`
  font-family: Inter, sans-serif;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5rem;
`

export const SwitchBlock = styled.div`
  max-width: 4rem;
  width: 100%;
  margin-left: auto;
  padding-top: 0.125rem;
`
