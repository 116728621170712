import styled from 'styled-components'
import { ContentWrapper } from 'components/profile/settings/AddressForm/styles'
import { media } from 'styled-bootstrap-grid'

export const ReportWrapper = styled(ContentWrapper)`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #8c8c8c;
  font-size: 0.75rem;
  position: relative;

  span {
    font-size: 1rem !important;
  }

  textarea {
    border: none !important;
    height: 240px;
    padding: 10px !important;
    width: 100%;

    ${media.lg`
     width: 550px;   
     height: 300px;
  `}
  }
`
