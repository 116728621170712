import React, { FC, useEffect, useState } from 'react'
import { Col, Container, Row } from 'styled-bootstrap-grid'
import { useDispatch, useSelector } from 'react-redux'
import Media from 'react-media'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import { CheckoutMethod } from 'redux/checkout/types'
import { routePath } from 'routes'
import {
  selectAddressInfo,
  selectCurrentStep,
  selectEntityAddressInfo,
  selectInvestmentErrors,
  selectInvestmentPaymentError,
  selectPaymentMethod,
  selectPersonInfo,
  selectUserSignature
} from 'redux/investment/selectors'
import {
  EntityType,
  IAddress,
  ICompany,
  IContact,
  ICreditCard,
  IIRA,
  IPaymentLimit,
  IPaymentMethods,
  ITrust,
  IUSBank
} from 'redux/investment/types'
import { fetchUserAddress } from 'redux/profile/operations'
import {
  checkProhibitedCountry,
  fetchEntityInfo,
  fetchIRAInfo,
  fetchPersonalInfo,
  fetchTrustInfo,
  getAddressErrors,
  getAnnualIncomeErrors,
  getCompanyInfoErrors,
  getContactErrors,
  getCreditCardErrors,
  getEntityDocumentErrors,
  getIraErrors,
  getTrustErrors,
  getUSBankErrors,
  getUserSignatureErrors,
  getVerificationErrors
} from 'redux/investment/operations'
import {
  clearInvestmentData,
  clearInvestmentPaymentError,
  setErrors
} from 'redux/investment/actions'
import { selectEventsDetail } from 'redux/events/selectors'
import { fetchEvent } from 'redux/events/operations'
import withNorthCapitalRequest from 'src/components/investment/steps/withNorthCapitalRequest'
import Button, { ButtonVariant } from 'src/components/UI/Button'
import Sidebar from 'src/components/investment/Sidebar'
import { useInvestNowContext } from 'src/pages/investment/InvestNowPage'
import InvestmentSteps from './steps'
import PopUpMessage from 'components/common/PopUpMessage'
import { AlertIcon } from 'src/components/icons'
import Loader from 'src/components/UI/Loader'
import { ErrorMessage, MainContent, Wrapper } from './styles'

interface Props {
  request: () => void
  paymentProcessing: boolean
  paymentSuccess: boolean
  location: {
    state: {
      amount: number
      entity: EntityType
      eventId: number
      activeTierId: number
      title: string
    }
  }
}

const InvestNowPage: FC<Props> = ({ ...props }) => {
  const { state } = useLocation()
  const dispatch = useDispatch()
  const history = useHistory()
  const [error, setError] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const currentStep = useSelector(selectCurrentStep)
  const paymentMethod = useSelector(selectPaymentMethod)
  const address = useSelector(selectAddressInfo)
  const userSignature = useSelector(selectUserSignature)
  const personInfo = useSelector(selectPersonInfo)
  const entityAddress = useSelector(selectEntityAddressInfo)
  const paymentError = useSelector(selectInvestmentPaymentError)
  const errors = useSelector(selectInvestmentErrors)
  const event = useSelector(selectEventsDetail)
  const {
    selectedPaymentState,
    selectedContactForm,
    setIsOpenModal,
    verificationInfo,
    setSelectedEntity,
    selectedEntityInfo,
    selectedEntity,
    selectedEntityContactInfo,
    setEventId,
    selectedEntityDocument,
    setAmount,
    setTierId,
    isACHConfirmed,
    isCreditCardConfirmed,
    amount,
    eventId,
    selectedAnnualIncome: { annual_income, net_worth }
  } = useInvestNowContext()
  const paymentLimits: { [k: string]: boolean } = {
    [IPaymentMethods.CARD]:
      !isCreditCardConfirmed || amount > IPaymentLimit.CARD,
    [IPaymentMethods.US_BANK]:
      !isACHConfirmed || amount > IPaymentLimit.US_BANK,
    [IPaymentMethods.WIRE_TRANSFER]: amount < IPaymentLimit.WIRE_TRANSFER
  }
  useEffect(() => {
    const fetchUserInfo = async () => {
      await dispatch(fetchUserAddress())
      dispatch(fetchPersonalInfo())
    }
    fetchUserInfo()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!Object.keys(event).length && eventId) {
      dispatch(fetchEvent(eventId))
    }
    // eslint-disable-next-line
  }, [event, eventId])

  useEffect(() => {
    if (personInfo.id && !props.paymentProcessing) {
      // @ts-ignore
      switch (state.entity) {
        // should be state.entity instead of selectedEntity
        case EntityType.ENTITY:
          dispatch(fetchEntityInfo())
          break
        case EntityType.TRUST:
          dispatch(fetchTrustInfo())
          break
        case EntityType.IRA:
          dispatch(fetchIRAInfo())
          break
      }
    }
    // eslint-disable-next-line
  }, [personInfo.id])

  useEffect(() => {
    setSelectedEntity(state.entity)
    setEventId(state.eventId)
    setAmount(state.amount)
    setTierId(state.activeTierId)
  }, [state, setSelectedEntity, setEventId, setAmount, setTierId])

  useEffect(() => {
    return () => {
      dispatch(clearInvestmentData())
    }
  }, [dispatch])

  useEffect(() => {
    // scroll up mobile forms
    window.scrollTo(0, 0)
  }, [currentStep])

  useEffect(() => {
    if (!props.paymentProcessing && !paymentError && props.paymentSuccess) {
      history.push({
        pathname: routePath.CHECKOUT_SUCCESS,
        state: {
          ...state,
          checkoutMethod: CheckoutMethod.INVESTMENT,
          userName: selectedContactForm.first_name
        }
      })
    }
    // eslint-disable-next-line
  }, [props.paymentSuccess])

  const checkRestrictedCountry = async (
    country: string,
    cityName: string,
    countryLabel: string,
    isEntity: boolean
  ) => {
    let data
    let countryError
    let field
    try {
      setIsLoading(true)
      data = await dispatch(
        checkProhibitedCountry(
          { iso_code: country, city_name: cityName },
          isEntity
        )
      )
      // @ts-ignore
      const fieldName = data.type === 'city' ? 'state' : 'country'
      field = isEntity ? `entity_${fieldName}` : fieldName
      setIsLoading(false)
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
      countryError = {
        // @ts-ignore
        [field]: data.restricted
          ? `We are sorry, we cannot accept investments from ${
              // @ts-ignore
              data.type === 'country' ? countryLabel : cityName
            }`
          : ''
      }
    }
    return countryError
  }

  const onNext = async () => {
    await dispatch(clearInvestmentPaymentError())
    let paymentErrors: object = {}
    let entityErrors: object = {}
    let entityDocumentErrors: object = {}

    const restrictedCountry = await checkRestrictedCountry(
      address.country,
      address.state_label || address.state,
      address.country_label,
      false
    )
    const contactErrors = getContactErrors(selectedContactForm as IContact)
    const addressErrors = getAddressErrors(address, 'Home')
    const verificationErrors = getVerificationErrors(
      verificationInfo,
      !!selectedContactForm.id
    )
    const userSignatureErrors = getUserSignatureErrors(userSignature)
    const incomeErrors = getAnnualIncomeErrors({ annual_income, net_worth })
    switch (paymentMethod) {
      case IPaymentMethods.CARD:
        paymentErrors = getCreditCardErrors(selectedPaymentState as ICreditCard)
        break
      case IPaymentMethods.US_BANK:
        paymentErrors = getUSBankErrors(selectedPaymentState as IUSBank)
        break
    }

    switch (selectedEntity) {
      case EntityType.ENTITY:
        entityErrors = getCompanyInfoErrors(selectedEntityInfo as ICompany)
        entityDocumentErrors = getEntityDocumentErrors(
          selectedEntityDocument,
          selectedEntity,
          !!selectedContactForm.id
        )
        break
      case EntityType.TRUST:
        entityErrors = getTrustErrors(selectedEntityInfo as ITrust)
        entityDocumentErrors = getEntityDocumentErrors(
          selectedEntityDocument,
          selectedEntity,
          !!selectedContactForm.id
        )
        break
      case EntityType.IRA:
        entityErrors = getIraErrors(selectedEntityInfo as IIRA)
        break
    }

    if (selectedEntity !== EntityType.INDIVIDUAL) {
      const representative =
        selectedEntity === EntityType.TRUST ? 'Custodian' : 'Contact'
      const entityContactErrors = getContactErrors(
        selectedEntityContactInfo,
        representative
      )
      const updatedEntityContactErrors: object = Object.keys(
        entityContactErrors
      ).reduce(
        (acc, fieldName) => ({
          ...acc,
          [`entity_${fieldName}`]: entityContactErrors[
            fieldName as keyof IContact
          ]
        }),
        {}
      )

      const entityAddressErrors = getAddressErrors(entityAddress, 'Mailing')
      const entityRestrictedError = await checkRestrictedCountry(
        entityAddress.country,
        entityAddress.state_label || entityAddress.state,
        entityAddress.country_label,
        true
      )
      const updatedEntityAddressErrors: object = Object.keys(
        entityAddressErrors
      ).reduce(
        (acc, fieldName) => ({
          ...acc,
          [`entity_${fieldName}`]: entityAddressErrors[
            fieldName as keyof IAddress
          ]
        }),
        {}
      )

      entityErrors = {
        ...entityRestrictedError,
        ...entityErrors,
        ...updatedEntityContactErrors,
        ...updatedEntityAddressErrors,
        ...entityDocumentErrors
      }
    }

    const formErrors = {
      ...errors,
      ...contactErrors,
      ...addressErrors,
      ...restrictedCountry,
      ...paymentErrors,
      ...entityErrors,
      ...verificationErrors,
      ...userSignatureErrors,
      ...incomeErrors
    }

    await dispatch(setErrors(formErrors))
    const isValid = Object.values(formErrors).every(e => !e)

    if (isValid) {
      props.request()
      setError(false)
    } else {
      setError(true)
    }
  }

  const closeModal = () => {
    setIsOpenModal(false)
  }

  const renderBottomBlock = () => (
    <div>
      {props.paymentProcessing && <Loader />}
      {isLoading && <Loader />}
      {paymentError && (
        <ErrorMessage>
          <AlertIcon />
          {paymentError && paymentError}
        </ErrorMessage>
      )}
      {error && (
        <ErrorMessage>
          <AlertIcon />
          {paymentError && paymentError}
          {error && 'Errors detected. Please check your entries.'}
        </ErrorMessage>
      )}

      <Button
        variant={ButtonVariant.Default}
        onClick={onNext}
        style={{ width: '100%' }}
        disabled={
          !userSignature ||
          paymentLimits[paymentMethod] ||
          props.paymentProcessing ||
          isLoading
        }
      >
        complete investment
      </Button>
    </div>
  )

  if (!state || !state.amount) {
    return <Redirect to={routePath.START_PAGE} />
  }

  const renderSteps = (matches: { small: boolean }) => (
    <InvestmentSteps matches={matches} />
  )

  const renderSidebar = () => (
    <Col md={5}>
      <Sidebar amount={amount} title={state.title} />
    </Col>
  )

  return (
    <Wrapper>
      <PopUpMessage onClose={closeModal} hideControl={true} />
      <Container style={{ height: '100%' }}>
        <Row style={{ height: '100%', minHeight: 'calc(100vh - 74px)' }}>
          <Media query={'(min-width: 768px)'} render={renderSidebar} />
          <Col col={12} md={6}>
            <MainContent>
              <Media
                queries={{
                  small: '(max-width: 767px)'
                }}
              >
                {renderSteps}
              </Media>
            </MainContent>
            <Media query={'(min-width: 768px)'} render={renderBottomBlock} />
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}

export default withNorthCapitalRequest(InvestNowPage)
