import React, { Component } from 'react'
import { Col } from 'styled-bootstrap-grid'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { fetchPurchased, loadPurchasedItems } from 'redux/events/operations'
import { selectEventsIsFetching, selectPurchased } from 'redux/events/selectors'
import { fetchBalance } from 'redux/profile/operations'
import { selectProfileBalance } from 'redux/profile/selectors'
import { routePath } from 'routes'

import InfiniteScroll from 'components/common/InfiniteScroll'
import Loader from 'src/components/UI/Loader'
import EventTier from 'components/profile/dashboard/purchases/PurchaseItem'

import {
  EventsLink,
  Text,
  TextWrapper
} from 'components/profile/dashboard/styles'

import { AppState, Dispatch } from 'store/types'

class PurchasesList extends Component<Props> {
  componentDidMount = () => this.props.loadPurchasedItems()

  renderPurchased = (purchased: any) =>
    purchased.map((item: any) => (
      <div key={item.id}>
        <Col style={{ padding: 0 }}>
          <EventTier tier={item} />
        </Col>
      </div>
    ))

  renderSelectedItems = () => {
    const { purchased } = this.props

    if (purchased.data && purchased.data.length > 0) {
      return this.renderPurchased(purchased.data)
    }

    return this.noItems()
  }

  noItems = () => (
    <TextWrapper>
      <Text>You are not supporting any events. Let's pick something</Text>
      <EventsLink to={routePath.EVENTS_PAGE}>See events</EventsLink>
    </TextWrapper>
  )

  onLoadMoreTiers = () => {
    const { purchased, fetchPurchased, tiersLoading } = this.props

    if (
      !tiersLoading &&
      purchased.meta &&
      purchased.meta.current_page !== purchased.meta.last_page
    ) {
      fetchPurchased()
    }
  }

  render() {
    const { purchased, tiersLoading } = this.props

    return (
      <InfiniteScroll onLoadMore={this.onLoadMoreTiers}>
        {tiersLoading && purchased.data.length === 0 ? (
          <Loader />
        ) : (
          this.renderSelectedItems()
        )}
        {tiersLoading && purchased.data.length > 0 && <Loader />}
      </InfiniteScroll>
    )
  }
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
export type Props = StateProps & DispatchProps

const mapStateToProps = (state: AppState) => ({
  purchased: selectPurchased(state),
  balance: selectProfileBalance(state),
  tiersLoading: selectEventsIsFetching(state),
  timeUntilVoting: state.votes.checking.time_until
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      fetchPurchased,
      fetchBalance,
      loadPurchasedItems
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PurchasesList)
