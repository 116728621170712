import axios from 'axios'

import { IStatus } from 'redux/commonTypes'
import { ReportType } from 'redux/profile/types'

export const ReportApi = {
  send: async (type: ReportType, report: ReportData) =>
    await axios.put<IStatus>(`/report/${type}`, report)
}

interface ReportData {
  message: string
  device: string
  version: string
}
