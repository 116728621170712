import styled from 'styled-components'
import ReactModal from 'react-modal'

export const WrapperModal = styled(ReactModal)<{ id?: string }>`
  outline: none;
  scrollbar-color: #fff #272727;
  scrollbar-width: thin;
  width: -moz-max-content;
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #272727;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #fff;
  }
`
