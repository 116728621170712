import axios from 'axios'

export const NotificationApi = {
  send: async (data: NotificationSendRequest) =>
    await axios.post<any>('/notifications/send', data),

  sync: async (token: string) =>
    await axios.put<any>('/notifications/sync', {
      token
    })
}

interface NotificationSendRequest {
  channels: string[]
  message: any
  subject: any
  filter: {
    amount_to: any
    amount_from: any
    ages: any
    events: any
  }
}
