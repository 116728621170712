import React from 'react'
import CSS from 'csstype'
import LoaderIcon from 'src/components/icons/LoaderIcon'
import styled from 'styled-components'

interface IProps {
  style?: CSS.Properties
}
const Loader: React.FC<IProps> = ({ style }) => (
  <LoaderWrapper style={style}>
    <LoaderIcon />
  </LoaderWrapper>
)
export default Loader

export const LoaderWrapper = styled.div`
  margin: 0 auto;
  width: 3rem;
  height: 3rem;
  svg {
    filter: drop-shadow(2px 3px 2px rgb(0 0 0 / 0.2));
  }
`

export const FullLoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`
