import styled from 'styled-components'

export const SwitchWrapper = styled.div`
  width: 4rem;
  height: 2rem;
  position: relative;
  display: inline-block;
`

export const SwitchLabel = styled.label`
  width: 100%;
  height: 100%;
  display: block;
  overflow: hidden;
  cursor: pointer;
  border-radius: 1.25rem;
  background: rgba(120, 120, 128, 0.32);

  svg {
    width: 15px;
    height: 15px;
    position: absolute;
    top: 8px;
    left: 12px;
    transition: 3s ease-in 0s;
  }
`

export const SwitchInner = styled.span`
  width: 200%;
  display: block;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
  &:before,
  &:after {
    display: block;
    width: 50%;
    height: 2rem;
  }
  &:before {
    content: '';
    background-color: ${p => p.theme.colors.fuchsia};
  }
`

export const Switcher = styled.span`
  width: 1.75rem;
  height: 1.75rem;
  margin: 0.125rem;
  background: ${p => p.theme.colors.white};
  position: absolute;
  top: 0;
  bottom: 0;
  right: 2rem;
  border-radius: 50%;
  transition: all 0.3s ease-in 0s;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.15);
  border: 0.5px solid rgba(0, 0, 0, 0.04);
`

export const SwitchCheckbox = styled.input.attrs(() => ({
  type: 'checkbox'
}))`
  display: none;

  &:checked + ${SwitchLabel} {
    ${SwitchInner} {
      margin-left: 0;
    }
    ${Switcher} {
      right: 0;
    }
  }
`
