import { ActionType } from 'typesafe-actions'
import * as actions from './actions'
import { ISocial, IVideo, Statuses } from '../commonTypes'
import { ROLES } from '../auth/types'

export type InfluencerAction =
  | ActionType<typeof actions.fetchInfluencerAsync>
  | ActionType<typeof actions.updateInfluencerAsync>
  | ActionType<typeof actions.uploadYoutubeVideoAsync>
  | ActionType<typeof actions.deleteYoutubeVideoAsync>
  | ActionType<typeof actions.clearInfluencer>
  | ActionType<typeof actions.clearErrors>
  | ActionType<typeof actions.followInfluencerAsync>
  | ActionType<typeof actions.unfollowInfluencerAsync>

export interface InfluencerState {
  isFetching: boolean
  user: { data: IInfluencer }
  errors: Error
}

export enum InfluencerActionTypes {
  FETCH_INFLUENCER_REQUEST = '@@influencer/FETCH_INFLUENCER_REQUEST',
  FETCH_INFLUENCER_SUCCESS = '@@influencer/FETCH_INFLUENCER_SUCCESS',
  FETCH_INFLUENCER_FAIL = '@@influencer/FETCH_INFLUENCER_FAIL',
  UPDATE_INFLUENCER_REQUEST = '@@influencer/UPDATE_INFLUENCER_REQUEST',
  UPDATE_INFLUENCER_SUCCESS = '@@influencer/UPDATE_INFLUENCER_SUCCESS',
  UPDATE_INFLUENCER_FAIL = '@@influencer/UPDATE_INFLUENCER_FAIL',
  UPLOAD_YOUTUBE_VIDEO_REQUEST = '@@influencer/UPLOAD_YOUTUBE_VIDEO_REQUEST',
  UPLOAD_YOUTUBE_VIDEO_SUCCESS = '@@influencer/UPLOAD_YOUTUBE_VIDEO_SUCCESS',
  UPLOAD_YOUTUBE_VIDEO_FAIL = '@@influencer/UPLOAD_YOUTUBE_VIDEO_FAIL',
  DELETE_YOUTUBE_VIDEO_REQUEST = '@@influencer/DELETE_YOUTUBE_VIDEO_REQUEST',
  DELETE_YOUTUBE_VIDEO_SUCCESS = '@@influencer/DELETE_YOUTUBE_VIDEO_SUCCESS',
  DELETE_YOUTUBE_VIDEO_FAIL = '@@influencer/DELETE_YOUTUBE_VIDEO_FAIL',
  CLEAR_INFLUENCER = '@@influencer/CLEAR_INFLUENCER',
  CLEAR_ERRORS = '@@influencer/CLEAR_ERRORS',
  FOLLOW_INFLUENCER_REQUEST = '@@profile/FOLLOW_INFLUENCER_REQUEST',
  FOLLOW_INFLUENCER_SUCCESS = '@@profile/FOLLOW_INFLUENCER_SUCCESS',
  FOLLOW_INFLUENCER_FAIL = '@@profile/FOLLOW_INFLUENCER_FAIL',
  UNFOLLOW_INFLUENCER_REQUEST = '@@profile/UNFOLLOW_INFLUENCER_REQUEST',
  UNFOLLOW_INFLUENCER_SUCCESS = '@@profile/UNFOLLOW_INFLUENCER_SUCCESS',
  UNFOLLOW_INFLUENCER_FAIL = '@@profile/UNFOLLOW_INFLUENCER_FAIL'
}

export interface IInfluencer {
  id: number
  first_name: string
  last_name: string
  influencer_name: string
  influencer_description: string
  influencer_status: string
  birth_date: string
  followers: number
  following: boolean
  call_prefix: number
  role: ROLES
  phone: string
  phone_verified: boolean
  email: string
  profile_photo: string
  social_links: ISocial[]
  tweets: ITweet[]
  videos: IVideo[]
  status: Statuses
}

export enum SocialTabs {
  YOUTUBE = 'youtube',
  TWITTER = 'twitter',
  INSTAGRAM = 'instagram',
  TWITCH = 'twitch'
}

export enum AnalyticsTabs {
  FOLLOWERS = 'followers',
  SUPPORTERS = 'supporters',
  AMOUNT_RAISED = 'amount-raised',
  REGIONS = 'regions'
}

export interface ITweet {
  id: number
  user_id: number
  tweeter_system_id: string
  tweet_text: string
  like_count: number
  image: string
  tweet_user_name: string
  retweet_count: number
  created_at: string
  updated_at: string
}

export interface IInfluencerData {
  influencer_name: string
  influencer_status: string
  description: string
}

export interface IUpdateInfluencer {
  first_name: string
  last_name: string
  email?: string
  social: { [k: string]: ISocial }
  influencer_data: IInfluencerData
}
