import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export const WrapperBlock = styled.div.attrs(() => ({
  'data-testid': 'tier-block'
}))`
  height: 100%;
  text-align: left;
  line-height: normal;
  position: relative;
  overflow: hidden;
  display: flex;
`

export const Card = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${p => p.theme.colors.fadedBlack};
`

export const CardBlock = styled.div<{ footer?: boolean; soldOut?: boolean }>`
  display: flex;
  width: ${p => (p.soldOut ? '50vw' : undefined)};
  justify-content: ${p => (p.footer ? 'space-between' : undefined)};
  padding: ${p => (p.soldOut ? '2.75rem 0' : '1.5625rem')};
  border-top: 1px solid #252321;

  h4 {
    color: ${p => p.theme.colors.darkenGold};
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1.5rem;
    text-transform: uppercase;
  }
`

export const CardBlockFooter = styled(CardBlock)`
  flex-direction: column;

  ${media.md`
    flex-direction: row;
  `};
`

export const CardBlockSoldOut = styled(CardBlock)`
  width: 100%;
  padding-right: 25px;
  justify-content: flex-end;
`

export const CardBlockContent = styled(CardBlock)`
  flex-direction: column;

  ${media.xl`
    flex-direction: row;
    justify-content: space-between;
  `};
`

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.md`
    flex: 1;
  `}
`

export const TierImageWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 300px;
  flex: 0 0 270px;
  margin-bottom: 1rem;
  background-color: #272727;
  position: relative;

  div {
    img {
      width: 100px !important;
      height: 100px !important;
    }
  }

  ${media.md`
    justify-content: center;
    overflow: hidden;
    width: 150px;
    height: 150px;
    flex: 0 0 150px;
    margin: 0;
  `}

  ${media.xl`
    width: 250px;
    height: 250px;
    flex: 0 0 250px;
  `}
`

export const Actions = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;

  button {
    margin-right: 1.25rem;

    &:last-child {
      margin-right: 0;
    }
  }

  ${media.md`
    margin-left: 1.5rem;
  `}
`

export const DescText = styled.p`
  margin-bottom: 1.5rem;
  font-weight: 300;
  color: #898888;
  font-size: 1rem;
`

export const TierInfo = styled.div.attrs(() => ({
  'data-testid': 'tier-info-block'
}))`
  margin-right: 3px;

  ${media.md`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 1rem;
  `}

  ${media.lg`
    display: flex;
    flex-direction: row;
  `}

  ${media.xl`
    padding-bottom: 0;
    display: block;
  `}
`

export const TierTitle = styled.h3`
  font-size: 1.375rem;
  color: ${p => p.theme.colors.wetWhite};
  font-weight: 400;
  margin-bottom: 0.875rem;

  ${media.md`
    font-size: 1.5625rem;
    line-height: 3rem;
  `}
  ${media.lg`
    font-size: 2.1875rem;
  `}
`
