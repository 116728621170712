import styled from 'styled-components'

export const Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
  background: #272727;
`
