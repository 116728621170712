import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export const Form = styled.form`
  max-width: 546px;
  width: 100%;
  margin-left: 15px;

  > div {
    width: 100%;
    max-height: 6.375rem;
    margin-right: 1.125rem;
  }

  textarea {
    width: 100%;
    height: 8.563rem;
    padding: 7px 12px;
    font-size: 0.938rem;
    font-weight: 300;
    line-height: 1.5rem;
    background: #1d2341;
    border-radius: 8px;
    border: none;
    color: rgba(255, 255, 255, 0.7);
    resize: none;
    margin-bottom: 10px;

    :focus {
      outline: none;
    }

    ::placeholder {
      border: none;
      opacity: 0.5;
      color: ${p => p.theme.colors.white};
    }

    ${media.md`
        height: 4.375rem;
        font-size: 1.125rem;
        line-height: 1.75rem;
    `}
  }

  button {
    margin-top: 45px;
    padding: 0.625rem;
    text-transform: uppercase;
    color: ${p => p.theme.colors.fuchsia};
    font-size: 1rem;
    background: transparent;
    font-weight: 400;
    border: 1px solid rgba(188, 106, 202, 0.5);
    border-radius: 12px;
    letter-spacing: 2px;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
    min-width: 79px;
    line-height: 19px;
    ${media.md`
       margin-top: unset;
    `}
    :hover {
      opacity: 0.8;
    }

    :disabled {
      color: ${p => p.theme.colors.fuchsia};
      :hover {
        background-color: inherit;
        color: ${p => p.theme.colors.fuchsia};
      }
    }
  }
`

export const Loading = styled.span`
  text-align: center;
  * {
    color: ${p => p.theme.colors.fuchsia};
  }
  @keyframes opacity {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  span:not(:last-child) {
    margin-right: 5px;
  }

  span {
    animation-name: opacity;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }

  span:nth-child(2) {
    animation-delay: 100ms;
  }

  span:nth-child(3) {
    animation-delay: 300ms;
  }
`
