import axios from 'axios'
import errorHandler from './errorHandler'
import { TokenStorage } from 'services/tokenService'

axios.defaults.baseURL = process.env.REACT_APP_API_URL
// axios.defaults.baseURL = 'https://sandbox.edenledger.com/api/v1'

/* tslint no-param-reassign: 0 */
axios.interceptors.request.use(
  async config => {
    const token = await TokenStorage.getToken()

    if (token) {
      config.headers.common.Authorization = `Bearer ${token}`
    }

    if (config.params) {
      config.params = Object.keys(config.params).reduce((acc, key) => {
        if (config.params[key] !== undefined) {
          return { ...acc, [key]: config.params[key] }
        }

        return acc
      }, {})
    }
    return config
  },
  error => Promise.reject(error.response)
)

errorHandler()
