import React from 'react'
import appstore from './images/App_Store_Badge_Black.png'
import googleplay from './images/Google_Play_Badge_Black.png'

import { AppImage, AppLink, AppWrapper } from './styles'

const Apps = () => (
  <AppWrapper>
    <AppLink to={'https://apps.apple.com/us/app/fanvestor/id1484304835'}>
      <AppImage src={appstore} alt={'apple-store-image'} />
    </AppLink>
    <AppLink
      to={
        'https://play.google.com/store/apps/details?id=com.edenledger.fanvestor'
      }
    >
      <AppImage src={googleplay} alt={'google-play-image'} />
    </AppLink>
  </AppWrapper>
)

export default Apps
