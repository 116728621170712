import React, { useEffect, FC } from 'react'
import { Container } from 'styled-bootstrap-grid'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  fetchInvestmentDetails,
  loadMoreInvestments
} from 'redux/profile/operations'
import { selectEvents, selectEventsDetail } from 'redux/events/selectors'

import { theme } from 'styled/theme'
import Loader from 'components/UI/Loader'
import InfiniteScroll from 'components/common/InfiniteScroll'
import ShareCard from 'components/invest/ShareCard'
import { CancelInvestment } from 'components/invest/ShareCard/styles'
import InvestDetailPage from 'containers/invest/InvestDetailPage'
import { SectionTitleColumn } from 'containers/invest/InvestDetailPage/styles'

interface UrlParams {
  item_id: string
}

const EventPage: FC = () => {
  const dispatch = useDispatch()
  const { item_id } = useParams<UrlParams>()

  const event = useSelector(selectEventsDetail)
  const tiersLoading = useSelector(selectEvents)

  useEffect(() => {
    if (item_id) {
      dispatch(fetchInvestmentDetails(item_id))
    }
  }, [dispatch, item_id])

  const loadInvestments = () => {
    dispatch(loadMoreInvestments())
  }

  return (
    <>
      <CancelInvestment
        style={{
          backgroundColor: `${theme.colors.navy}`,
          marginTop: '-74px',
          paddingTop: '94px'
        }}
        className={'content-container'}
      >
        <Container>
          <SectionTitleColumn
            color={theme.colors.white}
            style={{ marginBottom: '25px' }}
          >
            Your investment details
          </SectionTitleColumn>

          <InfiniteScroll onLoadMore={loadInvestments}>
            <ShareCard
              eventEnd={event.event_end_in_hours}
              investmentTerms={event.investmentTerms}
            />
          </InfiniteScroll>
          {tiersLoading.isFetchingTier && <Loader />}
        </Container>
      </CancelInvestment>
      <InvestDetailPage />
    </>
  )
}

export default EventPage
