import React from 'react'

const LikeIcon = props => (
  <svg
    width="16"
    height="19"
    viewBox="0 0 16 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.7513 4.33533C11.7513 2.30038 10.8012 0.836426 9.246 0.836426C8.39082 0.836426 8.0974 1.33756 7.79081 2.50274C7.72827 2.74345 7.69748 2.86064 7.66498 2.97615C7.58086 3.2751 7.43437 3.78406 7.22579 4.50199C7.22022 4.52122 7.212 4.53935 7.20131 4.55608L4.81235 8.29413C4.2473 9.17826 3.41358 9.85784 2.43368 10.233L2.03928 10.384C1.00034 10.7818 0.397146 11.8683 0.608916 12.9604L0.945997 14.6988C1.14719 15.7364 1.93357 16.5622 2.96009 16.8137L9.31651 18.3717C11.4259 18.8887 13.5578 17.6096 14.0944 15.5051L15.2735 10.8808C15.6431 9.43143 14.7677 7.95688 13.3183 7.58732C13.0996 7.53156 12.8748 7.50335 12.6491 7.50335H11.1297C11.5431 6.14261 11.7513 5.09279 11.7513 4.33533ZM1.83606 12.7225C1.7398 12.226 2.01398 11.7322 2.48622 11.5514L2.88062 11.4004C4.1103 10.9296 5.15655 10.0768 5.86562 8.96727L8.25458 5.22922C8.32961 5.11181 8.38743 4.98426 8.42624 4.85046C8.63566 4.12962 8.78293 3.61796 8.86825 3.31472C8.90419 3.18701 8.93822 3.05747 8.99966 2.82082C9.15791 2.21941 9.23577 2.08643 9.246 2.08643C9.96912 2.08643 10.5013 2.90648 10.5013 4.33533C10.5013 5.0724 10.2298 6.28153 9.68147 7.93122C9.54699 8.33583 9.8482 8.75335 10.2746 8.75335H12.6491C12.7706 8.75335 12.8917 8.76854 13.0094 8.79857C13.7899 8.99756 14.2612 9.79155 14.0622 10.572L12.8832 15.1963C12.516 16.6362 11.0573 17.5114 9.61408 17.1576L3.25765 15.5997C2.70491 15.4642 2.28147 15.0196 2.17314 14.4609L1.83606 12.7225Z"
      fill="white"
      {...props}
    />
  </svg>
)

export default LikeIcon
