import React, { FC } from 'react'
import ReactHintFactory from 'react-hint'
import { InvestmentTerms, InvestmentTermsItems } from 'redux/events/types'
import createList from './termsList'
import { SectionTitleColumn } from 'containers/invest/InvestDetailPage/styles'
import { theme } from 'styled/theme'
import { SummaryList, ListItem, AmountInvest, AmountTitle } from './styles'

interface Props {
  terms: InvestmentTerms
  rowAmount?: number
}
const ReactHint = ReactHintFactory(React)
const Summary: FC<Props> = ({ terms, rowAmount }) => {
  if (!terms) {
    return null
  }

  const termsList = createList(terms)

  const allowed = [
    InvestmentTermsItems.min_investment.title,
    InvestmentTermsItems.price_per_share.title,
    InvestmentTermsItems.valuation.title,
    InvestmentTermsItems.security_type.title
  ]

  const filterTermsList = termsList.filter(el =>
    allowed.includes(el.item.title)
  )

  return (
    <>
      <SectionTitleColumn
        color={theme.colors.black}
        style={{ paddingBottom: '1.1rem' }}
      >
        Invest Now
      </SectionTitleColumn>
      <SummaryList>
        <ReactHint autoPosition={true} events={true} persist={true} />
        {filterTermsList.map(({ value, item: { title } }) => (
          <ListItem key={title} rowAmount={rowAmount}>
            <AmountInvest>{value}</AmountInvest>
            <AmountTitle>{title}</AmountTitle>
          </ListItem>
        ))}
      </SummaryList>
    </>
  )
}

export default Summary
