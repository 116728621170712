import { action, createAsyncAction, createCustomAction } from 'typesafe-actions'
import {
  EventsActionTypes,
  IEventDetail,
  IEventRecommendActionData,
  IPurchased,
  EventCategories,
  EventCategoryFolder,
  EventType,
  IInfluencerList,
  IInfluencerEventList,
  IEventsDataWithType
} from './types'
import { IStatus } from 'redux/commonTypes'
export * from './comments/actions'

export const fetchEventsAsync = createAsyncAction(
  EventsActionTypes.FETCH_EVENTS_REQUEST,
  EventsActionTypes.FETCH_EVENTS_SUCCESS,
  EventsActionTypes.FETCH_EVENTS_FAIL
)<EventType, IEventsDataWithType, Error>()

export const fetchEventsMenuAsync = createAsyncAction(
  EventsActionTypes.FETCH_EVENTS_MENU_REQUEST,
  EventsActionTypes.FETCH_EVENTS_MENU_SUCCESS,
  EventsActionTypes.FETCH_EVENTS_MENU_FAIL
)<void, any, Error>()

export const fetchEventsHomeAsync = createAsyncAction(
  EventsActionTypes.FETCH_EVENTS_HOME_REQUEST,
  EventsActionTypes.FETCH_EVENTS_HOME_SUCCESS,
  EventsActionTypes.FETCH_EVENTS_HOME_FAIL
)<
  { category: EventCategories },
  IEventRecommendActionData,
  { category: EventCategories; errorMessage: string }
>()

export const fetchDetailEventsAsync = createAsyncAction(
  EventsActionTypes.FETCH_DETAIL_EVENT_REQUEST,
  EventsActionTypes.FETCH_DETAIL_EVENT_SUCCESS,
  EventsActionTypes.FETCH_DETAIL_EVENT_FAIL
)<void, { event: IEventDetail }, Error>()

export const fetchPurchasedAsync = createAsyncAction(
  EventsActionTypes.FETCH_PURCHASED_REQUEST,
  EventsActionTypes.FETCH_PURCHASED_SUCCESS,
  EventsActionTypes.FETCH_PURCHASED_FAIL
)<void, IPurchased, Error>()

export const fetchEventsByInfluencerAsync = createAsyncAction(
  EventsActionTypes.FETCH_EVENTS_BY_INFLUENCER_REQUEST,
  EventsActionTypes.FETCH_EVENTS_BY_INFLUENCER_SUCCESS,
  EventsActionTypes.FETCH_EVENTS_BY_INFLUENCER_FAIL
)<
  { key: string },
  { key: string; events: IInfluencerEventList },
  { key: string; err: Error }
>()

export const searchPurchasedAsync = createAsyncAction(
  EventsActionTypes.SEARCH_PURCHASED_REQUEST,
  EventsActionTypes.SEARCH_PURCHASED_SUCCESS,
  EventsActionTypes.SEARCH_PURCHASED_FAIL
)<void, IPurchased, Error>()

export const searchInfluencersAsync = createAsyncAction(
  EventsActionTypes.SEARCH_INFLUENCERS_REQUEST,
  EventsActionTypes.SEARCH_INFLUENCERS_SUCCESS,
  EventsActionTypes.SEARCH_INFLUENCERS_FAIL
)<void, IInfluencerList, Error>()

export const likeEventAsync = createAsyncAction(
  EventsActionTypes.LIKE_EVENT_REQUEST,
  EventsActionTypes.LIKE_EVENT_SUCCESS,
  EventsActionTypes.LIKE_EVENT_FAIL
)<void, IStatus, Error>()

export const changeEventsHomePage = createCustomAction(
  EventsActionTypes.CHANGE_PAGE_EVENTS_HOME,
  (category: EventCategories, type: EventCategoryFolder) => ({
    payload: { category, type }
  })
)

export const changeAllEventsHomePage = createCustomAction(
  EventsActionTypes.CHANGE_PAGE_ALL_EVENTS_HOME,
  (type: EventType) => ({
    payload: type
  })
)

export const clearEventsHomePage = createCustomAction(
  EventsActionTypes.CLEAR_EVENTS_HOME_PAGE,
  (type: EventType) => ({
    payload: type
  })
)

export const clearEventsByInfluencer = (key: string) =>
  action(EventsActionTypes.CLEAR_EVENTS_BY_INFLUENCER, { key })

export const clearPurchasedItems = () =>
  action(EventsActionTypes.CLEAR_PURCHASED)

export const clearSearchedItems = () => action(EventsActionTypes.CLEAR_SEARCHED)

export const clearInfluencerList = () =>
  action(EventsActionTypes.CLEAR_INFLUENCER_LIST)

export const clearErrors = () => action(EventsActionTypes.CLEAR_ERRORS)

export const changeInfluencerSearchField = (text: string) =>
  action(EventsActionTypes.CHANGE_INFLUENCER_SEARCH_FIELD, text)

export const clearInfluencerSearchField = () =>
  action(EventsActionTypes.CLEAR_INFLUENCER_SEARCH_FIELD)

export const clearDetailEvent = () =>
  action(EventsActionTypes.CLEAR_DETAIL_EVENT)
