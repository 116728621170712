import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export const Wrapper = styled.div`
  max-width: 330px;
  margin: 0 auto;
`

export const ErrorWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  min-height: 40px;

  button {
    padding: 0.375rem 0;
    color: #000;
  }
`

export const Subtitle = styled.p`
  font-family: Inter;
  font-size: 1.063rem;
  font-weight: 400;
  line-height: 1.625rem;
  text-align: center;

  width: 100%;
  margin-bottom: 29px;

  ${media.lg`
     font-size: 1.625rem;
     font-weight: 300;
     line-height: 2.375rem;
     
     margin-bottom: 45px;
  `}
`
