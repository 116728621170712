import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row } from 'styled-bootstrap-grid'

import { extractFieldFromAddress, geocodeByPlaceId } from 'utils/googleHelpers'
import {
  isValidEmail,
  isValidFullName,
  showValidationError
} from 'utils/helpers'
import debounce from 'utils/debounce'
import {
  selectAutocompleteAddresses,
  selectShipping,
  selectShippingErrors
} from 'redux/checkout/selectors'
import { fetchAddress } from 'redux/checkout/operations'
import { selectIsAuth, selectUser } from 'redux/auth/selectors'
import { changeShipping } from 'redux/checkout/actions'

import Checkbox from 'components/UI/Checkbox'
import Dropdown from 'components/UI/Dropdown'
import TextInput from 'components/UI/TextInput'

import {
  BlockedPayments,
  FormTitle,
  FormTitleEmail,
  Login,
  ShippingFormWrapper
} from './styles'

export const ShippingForm: FC<{ isBlockedPayments?: boolean }> = ({
  isBlockedPayments
}) => {
  const dispatch = useDispatch()

  const [errorMessage, setErrorMessage] = useState({
    email: '',
    recipient_name: ''
  })

  const isAuthenticated = useSelector(selectIsAuth)
  const shipping = useSelector(selectShipping)
  const errors = useSelector(selectShippingErrors)
  const suggestAddresses = useSelector(selectAutocompleteAddresses)
  const user = useSelector(selectUser)

  const handleChangeAddress = (value: string) => {
    if (value) {
      dispatch(fetchAddress(value))
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(
        changeShipping('recipient_name', `${user.first_name} ${user.last_name}`)
      )
    }
  }, [isAuthenticated, dispatch, user.first_name, user.last_name])

  const onChangeAddress = (name: string, value: string) => {
    dispatch(changeShipping(name, value))

    if (name === 'email') {
      if (!isValidEmail(value)) {
        setErrorMessage({
          ...errorMessage,
          email: showValidationError('email address', 'correct email', value)
        })
      } else {
        setErrorMessage({ ...errorMessage, email: '' })
      }
    }
    if (name === 'recipient_name') {
      if (!isValidFullName(value)) {
        setErrorMessage({
          ...errorMessage,
          recipient_name: showValidationError(
            'recipient name',
            'full name',
            value
          )
        })
      } else {
        setErrorMessage({ ...errorMessage, recipient_name: '' })
      }
    }
  }

  const onChangeStreet = async (name: string, value: string) => {
    // @ts-ignore
    const [place] = await geocodeByPlaceId(value.value)
    let cityName = extractFieldFromAddress(place, 'locality')
    if (!cityName) {
      cityName = extractFieldFromAddress(place, 'sublocality_level_1')
    }
    const country = extractFieldFromAddress(place, 'country')
    const state = extractFieldFromAddress(place, 'administrative_area_level_1')
    const zip = extractFieldFromAddress(place, 'postal_code') || ''
    const street = extractFieldFromAddress(place, 'route')
    const streetNumber = extractFieldFromAddress(place, 'street_number') || ''
    const streetName = `${streetNumber && streetNumber + ', '}${street}`

    onChangeAddress('country', country)
    onChangeAddress('state', state)
    onChangeAddress('city', cityName)
    onChangeAddress('streetName', streetName)
    await onChangeAddress('zip', zip)
    onChangeAddress(name, value)
  }

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target
    dispatch(changeShipping('use_as_default', checked))
  }

  return (
    <>
      {!isAuthenticated && (
        <ShippingFormWrapper>
          <FormTitleEmail>Email Address</FormTitleEmail>
          <Login>
            <Row>
              <Col sm={12} md={6} lg={6}>
                <TextInput
                  variant="dark"
                  name={'email'}
                  label={'Email'}
                  placeholder={'Email'}
                  value={''}
                  handleChange={debounce(onChangeAddress, 500)}
                  errorLabel={errorMessage.email || ''}
                  withoutAlert={true}
                />
              </Col>
            </Row>
          </Login>
        </ShippingFormWrapper>
      )}
      <ShippingFormWrapper>
        <FormTitle>Shipping address</FormTitle>
        <Row>
          <Col sm={12} md={6} lg={6}>
            <TextInput
              variant="dark"
              name={'recipient_name'}
              label={'Recipient Full Name'}
              placeholder={'Full Name'}
              value={shipping.recipient_name}
              handleChange={debounce(onChangeAddress, 500)}
              errorLabel={errorMessage.recipient_name || ''}
              withoutAlert={true}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6} lg={6}>
            <Dropdown
              label="Address"
              name={'street'}
              options={suggestAddresses}
              value={shipping.street}
              placeholder={'185 Berry st'}
              errorLabel={errors.street || errors.city || ''}
              onSelect={onChangeStreet}
              onInputChange={handleChangeAddress}
              isAsync={true}
            />
            <TextInput
              variant={'dark'}
              label={'City'}
              name={'city'}
              placeholder={'City'}
              value={shipping.city}
              disabled={true}
            />
            <TextInput
              variant={'dark'}
              label={'Сountry'}
              name={'country'}
              placeholder={'Сountry'}
              value={shipping.country}
              disabled={true}
            />
          </Col>
          <Col sm={12} md={6} lg={6}>
            <TextInput
              variant={'dark'}
              label={'Address 2 (optional)'}
              name={'room'}
              placeholder={'Address 2'}
              value={shipping.room}
              handleChange={onChangeAddress}
              errorLabel={errors.room || ''}
            />
            <TextInput
              variant={'dark'}
              label={'State/Region'}
              placeholder={'State/Region'}
              name={'state'}
              value={shipping.state}
              disabled={true}
            />
            <TextInput
              variant={'dark'}
              name={'zip'}
              label={'Postal Code'}
              placeholder={'Postal Code'}
              maskConfig={{
                mask: '99999',
                maskChar: null
              }}
              value={shipping.zip}
              errorLabel={errors.zip || ''}
              handleChange={onChangeAddress}
              disabled={true}
            />
          </Col>
        </Row>
        {isAuthenticated && (
          <Checkbox
            label="Use this Address by default"
            variant="dark"
            handleChange={handleChangeCheckbox}
          />
        )}
        <BlockedPayments>
          {isBlockedPayments && (
            <p>
              {showValidationError(
                'shipping address',
                'correct address',
                shipping.street.label
              )}
            </p>
          )}
        </BlockedPayments>
      </ShippingFormWrapper>
    </>
  )
}
