import { CheckoutResponse } from 'api/checkout.api'
import { action, createAsyncAction, createCustomAction } from 'typesafe-actions'
import {
  BuyNowGuest,
  CheckoutActionTypes,
  ICart,
  ICartItem,
  ICustomFields,
  IShipping,
  IShippingCountries
} from './types'
import { IErrors } from '../commonTypes'
import { ProfileSectionTypes } from '../profile/types'
import { IEventTierSets, ITier } from 'redux/events/types'

export const fetchCheckoutInfoAsync = createAsyncAction(
  CheckoutActionTypes.FETCH_CHECKOUT_INFO_REQUEST,
  CheckoutActionTypes.FETCH_CHECKOUT_INFO_SUCCESS,
  CheckoutActionTypes.FETCH_CHECKOUT_INFO_FAIL
)<void, any, Error>()

export const braintreeTokenAsync = createAsyncAction(
  CheckoutActionTypes.BRAINTREE_TOKEN_REQUEST,
  CheckoutActionTypes.BRAINTREE_TOKEN_SUCCESS,
  CheckoutActionTypes.BRAINTREE_TOKEN_FAIL
)<void, { token: string }, Error>()

export const paymentDonate = createAsyncAction(
  CheckoutActionTypes.PAY_DONATE_REQUEST,
  CheckoutActionTypes.PAY_DONATE_SUCCESS,
  CheckoutActionTypes.PAY_DONATE_FAIL
)<void, CheckoutResponse, Error>()

export const fetchShippingCountries = createAsyncAction(
  CheckoutActionTypes.FETCH_COUNTRIES_REQUEST,
  CheckoutActionTypes.FETCH_COUNTRIES_SUCCESS,
  CheckoutActionTypes.FETCH_COUNTRIES_FAIL
)<void, IShippingCountries, Error>()

export const confirmPayPalAsync = createAsyncAction(
  CheckoutActionTypes.CONFIRM_PAY_PAL_REQUEST,
  CheckoutActionTypes.CONFIRM_PAY_PAL_SUCCESS,
  CheckoutActionTypes.CONFIRM_PAY_PAL_FAIL
)<void, any, Error>()

export const fetchAddressAsync = createAsyncAction(
  CheckoutActionTypes.FETCH_ADDRESS_REQUEST,
  CheckoutActionTypes.FETCH_ADDRESS_SUCCESS,
  CheckoutActionTypes.FETCH_ADDRESS_FAIL
)<void, any, Error>()

export const fetchShoppingCartAsync = createAsyncAction(
  CheckoutActionTypes.FETCH_SHOPPING_CART_REQUEST,
  CheckoutActionTypes.FETCH_SHOPPING_CART_SUCCESS,
  CheckoutActionTypes.FETCH_SHOPPING_CART_FAIL
)<void, ICartItem[], Error>()

export const addItemToCartAsync = createAsyncAction(
  CheckoutActionTypes.ADD_ITEM_TO_CART_REQUEST,
  CheckoutActionTypes.ADD_ITEM_TO_CART_SUCCESS,
  CheckoutActionTypes.ADD_ITEM_TO_CART_FAIL
)<void, ICartItem, Error>()

export const deleteItemFromCartAsync = createAsyncAction(
  CheckoutActionTypes.DELETE_ITEM_FROM_CART_REQUEST,
  CheckoutActionTypes.DELETE_ITEM_FROM_CART_SUCCESS,
  CheckoutActionTypes.DELETE_ITEM_FROM_CART_FAIL
)<void, { itemId: number }, Error>()

export const increaseCartItemCountAsync = createAsyncAction(
  CheckoutActionTypes.INCREASE_CART_ITEM_COUNT_REQUEST,
  CheckoutActionTypes.INCREASE_CART_ITEM_COUNT_SUCCESS,
  CheckoutActionTypes.INCREASE_CART_ITEM_COUNT_FAIL
)<void, { tierSet: IEventTierSets }, Error>()

export const decreaseCartItemCountAsync = createAsyncAction(
  CheckoutActionTypes.DECREASE_CART_ITEM_COUNT_REQUEST,
  CheckoutActionTypes.DECREASE_CART_ITEM_COUNT_SUCCESS,
  CheckoutActionTypes.DECREASE_CART_ITEM_COUNT_FAIL
)<void, { tierSet: IEventTierSets }, Error>()

export const fetchTierAsync = createAsyncAction(
  CheckoutActionTypes.FETCH_TIER_REQUEST,
  CheckoutActionTypes.FETCH_TIER_SUCCESS,
  CheckoutActionTypes.FETCH_TIER_FAIL
)<void, { data: ITier }, Error>()

export const clearCheckoutErrors = () =>
  action(CheckoutActionTypes.CLEAR_ERRORS)

export const startPaymentProcessing = () =>
  action(CheckoutActionTypes.START_PAYMENT_PROCESSING)

export const finishPaymentProcessing = () =>
  action(CheckoutActionTypes.FINISH_PAYMENT_PROCESSING)

export const setInitialCheckout = () =>
  action(CheckoutActionTypes.INITIAL_CHECKOUT)

export const changeShipping = createCustomAction(
  CheckoutActionTypes.CHANGE_SHIPPING,
  (field: string, value: string | boolean) => ({
    payload: { field, value }
  })
)

export const setCheckoutStep = createCustomAction(
  CheckoutActionTypes.SET_CHECKOUT_STEP,
  (step: number) => ({
    payload: { step }
  })
)

export const setCustomField = createCustomAction(
  CheckoutActionTypes.SET_CUSTOM_FIELD,
  (fields: ICustomFields) => ({
    payload: { fields }
  })
)

export const setShippingErrors = createCustomAction(
  CheckoutActionTypes.SET_SHIPPING_ERRORS,
  (fields: IErrors<IShipping>) => ({
    payload: { fields }
  })
)

// tslint:disable-next-line:no-angle-bracket-type-assertion
// tslint:disable-next-line:no-unused-expression
export const setShippingAddressFromProfile = createCustomAction(
  CheckoutActionTypes.SET_SHIPPING_ADDRESS_FROM_PROFILE,
  (fields: { [K in keyof IShipping]: IShipping[K] }) => ({
    payload: { fields }
  })
)

export const setLastActivity = createCustomAction(
  CheckoutActionTypes.SET_LAST_ACTIVITY,
  (activity: ProfileSectionTypes) => ({
    payload: activity
  })
)

export const shoppingCartInitializationAction = createCustomAction(
  CheckoutActionTypes.SHOPPING_CART_INITIALIZED,
  (cart: ICart) => ({
    payload: cart
  })
)

export const increaseQuantityBuyNowItemAction = () =>
  action(CheckoutActionTypes.INCREASE_BUYNOW_ITEM_QUANTITY)
export const decreaseQuantityBuyNowItemAction = () =>
  action(CheckoutActionTypes.DECREASE_BUYNOW_ITEM_QUANTITY)
export const deleteQuantityBuyNowItemAction = () =>
  action(CheckoutActionTypes.DELETE_BUYNOW_ITEM_QUANTITY)
export const changeQuantityBuyNowItemAction = createCustomAction(
  CheckoutActionTypes.CHANGE_BUYNOW_ITEM_QUANTITY,
  (payload: number) => ({
    payload
  })
)
export const resetGuestBuyNow = () =>
  action(CheckoutActionTypes.RESET_GUEST_BUYNOW)

export const setGuestBuyNow = createCustomAction(
  CheckoutActionTypes.SET_GUEST_BUYNOW,
  (guestCheckout: BuyNowGuest) => ({
    payload: guestCheckout
  })
)
