import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { routePath } from 'routes'
import { RouteProps } from 'react-router-dom'
import { AppState } from '../store'
import { Dispatch } from '../store/types'
import { bindActionCreators } from 'redux'
import { logout } from '../redux/auth/operations'
import { connect } from 'react-redux'

class PrivateRoute extends React.Component<Props> {
  render() {
    const { component: Component, ...rest } = this.props

    return (
      <Route
        {...rest}
        /* tslint:disable-next-line:jsx-no-lambda */
        render={props =>
          rest.isAuthenticated ? (
            // @ts-ignore
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: routePath.SIGN_IN,
                state: { from: props.location }
              }}
            />
          )
        }
      />
    )
  }
}

// tslint:disable-next-line:no-empty-interface
interface ReceivedProps {}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
export type Props = StateProps & DispatchProps & ReceivedProps & RouteProps

const mapStateToProps = (state: AppState) => ({
  isAuthenticated:
    state.auth.isAuthenticated && !state.auth.isForceChangePassword
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ logout }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRoute)
