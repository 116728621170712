import React, { FC, ReactNode } from 'react'
import { CSSObject } from 'styled-components'
import { WrapperModal } from './styles'

export interface GenericModalProps {
  children?: ReactNode
  isOpen: boolean
  onClose?: () => void
  style?: CSSObject
  id?: string
  className?: string
  closeTimeout?: number
  overlayStyle?: CSSObject
}

const GenericModal: FC<GenericModalProps> = ({
  children,
  isOpen,
  onClose,
  style = {},
  id,
  className,
  closeTimeout,
  overlayStyle = {}
}) => (
  <WrapperModal
    isOpen={isOpen}
    onRequestClose={onClose}
    id={id}
    closeTimeoutMS={closeTimeout}
    overlayClassName={className}
    style={{
      overlay: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        overflow: 'hidden',
        width: '100%',
        zIndex: 999,
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        ...overlayStyle
      },
      content: {
        maxHeight: '100vh',
        position: 'relative',
        overflow: 'unset',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        boxShadow: '0 0.125rem 0.625rem 0 rgba(0, 0, 0, 0.25)',
        borderRadius: '12px',
        borderColor: 'transparent',
        backgroundColor: '#eeeeee',
        ...style
      }
    }}
    appElement={document.getElementById('root') || {}}
  >
    {children}
  </WrapperModal>
)

export default GenericModal
