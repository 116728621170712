import branch from 'branch-sdk'
import ReactPixel from 'react-facebook-pixel'

import { history, routePath } from 'routes'
import * as actions from './actions'
import { initialState } from './reducer'
import {
  selectBuyNowGuestCheckout,
  selectBuyNowTierQuantity,
  selectCart,
  selectCheckout,
  selectShipping,
  selectStates
} from './selectors'
import { selectFormattedAddress } from 'redux/profile/selectors'

import {
  BuyNowRequest,
  CheckoutApi,
  CheckoutShoppingCartRequest
} from 'api/checkout.api'
import AnalyticsManager from 'services/analyticService'

import { CheckoutMethod, ICartItem, ICheckoutPayload, IShipping } from './types'
import { ThunkAction } from 'store/types'
import { IEventTier } from 'redux/events/types'
import { CurrencyLabels, IErrors } from '../commonTypes'
import { selectIsAuth, selectUser } from 'redux/auth/selectors'
import { selectEvents } from 'redux/events/selectors'
import { EventsApi } from 'api/events.api'
import { getBaseUrl } from 'utils/helpers'

export const fetchCheckoutInfo = (tierSetId?: number): ThunkAction => {
  return async (dispatch, getState) => {
    dispatch(actions.fetchCheckoutInfoAsync.request())
    const shippingInfo = selectShipping(getState())
    const params = {
      country: shippingInfo.country,
      zip: shippingInfo.zip,
      street: shippingInfo.streetName || shippingInfo.street.label || '',
      place_id: shippingInfo.street.value || '',
      state: shippingInfo.state || '',
      city: shippingInfo.city,
      room: shippingInfo.room || ''
    }
    if (tierSetId) {
      const quantity = selectBuyNowTierQuantity(getState())
      // @ts-ignore
      params.quantity = quantity
    }

    try {
      const resp = await CheckoutApi.fetchCartInfo(params, tierSetId)
      dispatch(actions.fetchCheckoutInfoAsync.success(resp.data))
    } catch (e) {
      dispatch(actions.fetchCheckoutInfoAsync.failure(e))
    }
  }
}
export const confirmCheckout = async <P>(params: P): Promise<any> => {
  const baseUrl = getBaseUrl()

  try {
    return await CheckoutApi.confirmCheckout<P>(params, baseUrl)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const checkoutDonate = async (item: ICheckoutPayload) => {
  const { tier_picture, payerID, ...checkoutInfo } = item

  try {
    const resp = await CheckoutApi.checkoutDonate(checkoutInfo)

    return resp.data.approval_link
  } catch (err) {
    return Promise.reject(err)
  }
}

export const fetchBraintreeToken = (tierId: number): ThunkAction => {
  return async dispatch => {
    dispatch(actions.braintreeTokenAsync.request())

    try {
      const resp = await CheckoutApi.fetchBraintreeToken(tierId)

      dispatch(
        actions.braintreeTokenAsync.success({
          token: resp.data.braintree_client_token
        })
      )
    } catch (err) {
      return dispatch(actions.braintreeTokenAsync.failure(err))
    }
  }
}

export const fetchBraintreeCartToken = (): ThunkAction => {
  return async dispatch => {
    dispatch(actions.braintreeTokenAsync.request())

    try {
      const resp = await CheckoutApi.fetchBraintreeCartToken()

      dispatch(
        actions.braintreeTokenAsync.success({
          token: resp.data.braintree_client_token
        })
      )
    } catch (err) {
      return dispatch(actions.braintreeTokenAsync.failure(err))
    }
  }
}

export const checkoutExperience = (
  checkoutData: CheckoutShoppingCartRequest | BuyNowRequest
): ThunkAction => {
  return async (dispatch, getState) => {
    dispatch(actions.paymentDonate.request())
    const user = selectUser(getState())
    const shipping = selectShipping(getState())
    const guestCheckout = selectBuyNowGuestCheckout(getState())
    const userName = shipping.recipient_name
      ? shipping.recipient_name
      : user.first_name + ' ' + user.last_name

    try {
      let checkoutMethod = CheckoutMethod.SHOPPING
      if ('tier_set_id' in checkoutData) {
        checkoutMethod = CheckoutMethod.BUY_NOW
      }

      // @ts-ignore
      const resp = await CheckoutApi[checkoutMethod]({
        ...checkoutData,
        recipient_name: userName
      })

      dispatch(actions.paymentDonate.success({ ...resp.data }))

      dispatch(actions.clearCheckoutErrors())

      history.push({
        pathname: routePath.CHECKOUT_SUCCESS,
        state: {
          eventId: guestCheckout.eventId || '',
          checkoutMethod
        }
      })
    } catch (err) {
      return dispatch(
        actions.paymentDonate.failure(err.response.data || 'Oops')
      )
    }
  }
}

export const checkout = (item: ICheckoutPayload): ThunkAction => {
  return async (dispatch, getState) => {
    dispatch(actions.paymentDonate.request())
    const user = selectUser(getState())

    const { tier_picture, payerID, ...checkoutInfo } = item
    const userName = user.first_name + ' ' + user.last_name

    try {
      const resp = await CheckoutApi.checkout({
        ...checkoutInfo,
        recipient_name: userName
      })

      dispatch(actions.paymentDonate.success({ ...resp.data }))
      dispatch(actions.clearCheckoutErrors())

      AnalyticsManager.trackTierCheckoutSuccess(
        item.tier_id,
        item.event_id,
        item.payment_system
      )
      ReactPixel.track('Purchase', {
        eventId: item.event_id,
        paymentSystem: item.payment_system,
        tierId: item.tier_id,
        currency: CurrencyLabels[item.currency],
        value: item.amount
      })
      branch.logEvent('TierPurchasedWithLink', {
        user_who_shared: user.id,
        payment_system: item.payment_system,
        tierId: item.tier_id
      })
      history.push({
        pathname: routePath.CHECKOUT_SUCCESS,
        state: {
          checkoutMethod: CheckoutMethod.BUY_NOW,
          eventId: item.event_id,
          tierId: item.tier_id,
          amount: item.amount
        }
      })
    } catch (err) {
      return dispatch(
        actions.paymentDonate.failure(err.response.data || 'Oops')
      )
    }
  }
}

export const fetchAddress = (value: string): ThunkAction => {
  return async dispatch => {
    dispatch(actions.fetchAddressAsync.request())

    try {
      const autocomplete = new window.google.maps.places.AutocompleteService()

      autocomplete.getPlacePredictions(
        {
          // input: `${value}${state && state.label ? ` ${state.label}` : ''} `,
          // componentRestrictions: { country: country.code || '' },
          input: value,
          types: ['address']
        },
        (predictions, status) => {
          dispatch(actions.fetchAddressAsync.success(predictions))
        }
      )
    } catch (err) {
      return dispatch(actions.fetchAddressAsync.failure(err))
    }
  }
}

export const isValidAddressInfo = (): ThunkAction => {
  return async (dispatch, getState) => {
    const states = selectStates(getState())
    const checkout = selectCheckout(getState())
    const events = selectEvents(getState())

    const { shipping: shippingForm } = checkout

    const activeTier = events.tierDetail.data

    let isValid = true

    if (activeTier.required_shipping_address) {
      const errors = {} as IErrors<IShipping>

      if (shippingForm.zip && shippingForm.zip.length < 5) {
        errors.zip = 'Please enter a 5-digit Postal Code (numbers only)'
      }

      for (const field of Object.keys(shippingForm as IShipping) as Array<
        keyof IShipping
      >) {
        if (['use_as_default', 'room', 'country', 'state'].includes(field)) {
          continue
        }

        if (
          !shippingForm[field] ||
          (typeof shippingForm[field] !== 'string' &&
            shippingForm[field] &&
            // @ts-ignore
            !shippingForm[field].value)
        ) {
          errors[field] = 'This field is required'
          if (field === 'city') {
            errors[field] = 'Full address is required'
          }
        }

        if (states && states.length === 0) {
          delete errors.state
        }
      }

      isValid = !Object.keys(errors).length
      dispatch(actions.setShippingErrors(errors))
    }

    return isValid
  }
}

export const clearShippingErrors = (): ThunkAction => {
  return async dispatch => {
    dispatch(actions.setShippingErrors(initialState.errorsShipping))
  }
}

export const setShipmentFromProfile = (): ThunkAction => {
  return async (dispatch, getState) => {
    const { street, place_id, ...currentAddress } = selectFormattedAddress(
      getState()
    )

    dispatch(
      // @ts-ignore
      actions.setShippingAddressFromProfile({
        ...currentAddress,
        street: { label: street, value: place_id }
      })
    )
  }
}

export const addItemToCart = (
  tier: IEventTier,
  tierSetId: number,
  count: number,
  itemSeller: string
): ThunkAction => {
  return async dispatch => {
    dispatch(actions.addItemToCartAsync.request())
    try {
      const res = await CheckoutApi.addProductToCart(tierSetId, count)

      dispatch(
        actions.addItemToCartAsync.success({
          id: tierSetId,
          tier_name: tier.title,
          tier_id: tier.id,
          tier_price: tier.value,
          tier_image: tier.picture,
          tier_influencer: itemSeller,
          sets: [res.data],
          quantity: 0
        })
      )
      return
    } catch (err) {
      return dispatch(actions.addItemToCartAsync.failure(err.response.data))
    }
  }
}

export const deleteItemFromCart = (itemId: number): ThunkAction => {
  return async dispatch => {
    dispatch(actions.deleteItemFromCartAsync.request())
    try {
      await CheckoutApi.deleteProductFromCart(itemId)

      dispatch(actions.deleteItemFromCartAsync.success({ itemId }))
      return
    } catch (err) {
      return dispatch(
        actions.deleteItemFromCartAsync.failure(err.response.data)
      )
    }
  }
}

export const increaseCartItemCount = (
  itemId: number,
  shoppingCartId: number
): ThunkAction => {
  return async (dispatch, getState) => {
    const cart = selectCart(getState())
    const sets = cart.items.reduce(
      (acc: any, item: ICartItem) => [...acc, ...item.sets],
      []
    )
    const newCount = sets.find(item => item.tier_set_id === itemId).quantity + 1

    dispatch(actions.increaseCartItemCountAsync.request())

    try {
      const resp = await CheckoutApi.editProductInCart(shoppingCartId, newCount)

      dispatch(
        actions.increaseCartItemCountAsync.success({ tierSet: resp.data })
      )
      return
    } catch (err) {
      dispatch(actions.increaseCartItemCountAsync.failure(err.response.data))
    }
  }
}

export const decreaseCartItemCount = (
  itemId: number,
  shoppingCartId: number
): ThunkAction => {
  return async (dispatch, getState) => {
    const cart = selectCart(getState())
    const sets = cart.items.reduce(
      (acc: any, item: ICartItem) => [...acc, ...item.sets],
      []
    )

    const count = sets.find(item => item.tier_set_id === itemId).quantity

    if (count < 2) {
      return
    }

    const newCount = count - 1

    dispatch(actions.decreaseCartItemCountAsync.request())
    try {
      const resp = await CheckoutApi.editProductInCart(shoppingCartId, newCount)

      dispatch(
        actions.decreaseCartItemCountAsync.success({ tierSet: resp.data })
      )
      return
    } catch (err) {
      dispatch(actions.decreaseCartItemCountAsync.failure(err.response.data))
    }
  }
}

export const shoppingCartInitialization = (): ThunkAction => {
  return async (dispatch, getState) => {
    dispatch(actions.fetchShoppingCartAsync.request())

    const isAuth = selectIsAuth(getState())

    if (!isAuth) {
      return
    }

    try {
      const resp = await CheckoutApi.getUserCart()
      return dispatch(
        actions.fetchShoppingCartAsync.success(resp.data.data.items)
      )
    } catch (err) {
      return dispatch(actions.fetchShoppingCartAsync.failure(err.response.data))
    }
  }
}

export const fetchTier = (
  eventId: number | string,
  tierId: number | string
): ThunkAction => {
  return async dispatch => {
    dispatch(actions.fetchTierAsync.request())

    try {
      const resp = await EventsApi.getTier(eventId, tierId)

      dispatch(actions.fetchTierAsync.success(resp.data))
    } catch (err) {
      dispatch(actions.fetchTierAsync.failure(err))
    }
  }
}
